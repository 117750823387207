import { Component } from "react";
import { User } from "../../../models/api/User";
import DateHelpers from "../../../helpers/DateHelpers";
import { AccessGroup } from "../../../models/api/AccessGroup";
import { AccessGroupApiService } from "../../../services/api/AccessGroupApiService";
import { FormInfo } from "../../../components/forms/FormInfo";

interface IProps {
  user: User;
}

interface IState {
  accessGroups: AccessGroup[];
}

export class UserProfile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      accessGroups: [],
    };
  }

  componentDidMount() {
    new AccessGroupApiService()
      .list()
      .then(({ items: accessGroups }) => this.setState({ accessGroups }));
  }

  render() {
    return (
      <FormInfo
        properties={[
          {
            key: "Last Login",
            value: this.props.user.lastLogin ? (
              <p>
                <span> {DateHelpers.formatMoment(this.props.user.lastLogin)} </span>
              </p>
            ) : (
              <></>
            ),
          },
          {
            key: "Created",
            value: this.props.user.createdAt ? (
              <p>
                <span> {DateHelpers.formatMoment(this.props.user.createdAt)} </span>
              </p>
            ) : (
              <></>
            ),
          },
          {
            key: "Access Groups",
            value: (
              <p>
                <span>
                  {" "}
                  {this.state.accessGroups
                    .filter((ag) => this.props.user.accessGroupIds?.includes(ag.id))
                    .map((ag) => ag.groupName)
                    .join(", ")}{" "}
                </span>
              </p>
            ),
          },
        ]}
      />
    );
  }
}
