import { ApiService } from "./ApiService";
import { Webhook, WebhooksForDevice } from "../../models/api/Webhook";
import moment from "moment";
import { NewWebhook } from "../../models/api/NewWebhook";
import { apiEndPoint } from "../../config";
import { PagedResults } from "../../models/api/PagedResults";
import { TableFilters } from "../../models/api/TableFilters";
import { Promise } from "bluebird";

export class WebhooksApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "webhooks");

    this.list = this.list.bind(this);
  }

  createWebhook(webhook: NewWebhook): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("", webhook, cancel).done((result) => {
        if (result.success) {
          resolve(result.webhookId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateWebhook(webhookId: string, webhook: NewWebhook): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(`${webhookId}`, webhook, cancel).done((result) => {
        if (result.success) {
          resolve(result.webhookId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteWebhook(webhookId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${webhookId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  list(tableFilters?: TableFilters): Promise<PagedResults<Webhook>> {
    return new Promise<PagedResults<Webhook>>((resolve, reject, cancel) => {
      this.get(
        `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const webhooks = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                orgId: d.ORGID,
                name: d.NAME,
                uri: d.URI,
                webhookAccessGroupCount: d.WEBHOOKACCESSGROUPCOUNT,
                webhookDeviceCount: d.WEBHOOKDEVICECOUNT,
                isGlobal: d.ISGLOBAL,
                removedAt: moment.utc(d.REMOVEDAT).local(),
              }) as Webhook,
          );
          resolve({
            items: webhooks,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getWebhook(webhookId: string) {
    return new Promise<Webhook>((resolve, reject, cancel) => {
      this.get(`${webhookId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            orgId: d.ORGID,
            name: d.NAME,
            uri: d.URI,
            deviceIds: d.DEVICEIDS,
            accessGroupIds: d.ACCESSGROUPIDS,
            isGlobal: d.ISGLOBAL,
            removedAt: moment.utc(d.REMOVEDAT).local(),
          } as Webhook);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listForDevice(deviceId: string): Promise<WebhooksForDevice> {
    return new Promise<WebhooksForDevice>((resolve, reject, cancel) => {
      this.get(`${deviceId}/device`, cancel).done((result) => {
        if (result.success) {
          const webhooks = {
            fromDevice: result.fromDevice.map(
              (d: any) =>
                ({
                  id: d.ID,
                  orgId: d.ORGID,
                  name: d.NAME,
                  uri: d.URI,
                  webhookAccessGroupCount: d.WEBHOOKACCESSGROUPCOUNT,
                  webhookDeviceCount: d.WEBHOOKDEVICECOUNT,
                  isGlobal: d.ISGLOBAL,
                  removedAt: moment.utc(d.REMOVEDAT).local(),
                }) as Webhook,
            ),
            fromAccessGroup: result.fromAccessGroup.map(
              (d: any) =>
                ({
                  id: d.ID,
                  orgId: d.ORGID,
                  name: d.NAME,
                  uri: d.URI,
                  webhookAccessGroupCount: d.WEBHOOKACCESSGROUPCOUNT,
                  webhookDeviceCount: d.WEBHOOKDEVICECOUNT,
                  isGlobal: d.ISGLOBAL,
                  removedAt: moment.utc(d.REMOVEDAT).local(),
                }) as Webhook,
            ),
            fromGlobal: result.fromGlobal.map(
              (d: any) =>
                ({
                  id: d.ID,
                  orgId: d.ORGID,
                  name: d.NAME,
                  uri: d.URI,
                  webhookAccessGroupCount: d.WEBHOOKACCESSGROUPCOUNT,
                  webhookDeviceCount: d.WEBHOOKDEVICECOUNT,
                  isGlobal: d.ISGLOBAL,
                  removedAt: moment.utc(d.REMOVEDAT).local(),
                }) as Webhook,
            ),
          };
          resolve(webhooks);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
