import { ApiService } from "./ApiService";
import { Promise } from "bluebird";

import { apiEndPoint } from "../../config";
import moment from "moment";
import { Tag } from "../../models/api/Tag";
export class TagsApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "tags");
  }

  listSuggestedTags(): Promise<Tag[]> {
    return new Promise<Tag[]>((resolve, reject, cancel) => {
      this.get(`suggestion`, cancel).done((result) => {
        if (result.success) {
          const tags = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.TAGNAME,
                recordCreatedOn:
                  d.RECORDCREATEDON != undefined && d.RECORDCREATEDON != ""
                    ? moment(new Date(d.RECORDCREATEDON))
                    : undefined,
              }) as Tag,
          );
          resolve(tags);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
