import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Button, ButtonType } from "../../../components/Button";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Alert } from "../../../models/api/Alert";
import { AlertApiService } from "../../../services/api/AlertApiService";
import { AlertRecipient } from "../../../models/api/AlertRecipient";
import CreatableSelect from "react-select/creatable";
import { AlertRulesTable } from "./components/AlertRulesTable";
import { Device } from "../../../models/api/Device";
import { DeviceType } from "../../../models/api/DeviceType";
import { DeviceTypesApiService } from "../../../services/api/DeviceTypesApiService";
import { Measurement } from "../../../models/api/Measurement";
import { MeasurementsApiService } from "../../../services/api/MeasurementsApiService";
import { AlertRule } from "../../../models/api/AlertRule";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { Guid } from "js-guid";
import { newAlertUxEnabled } from "../../../config";
import { UnitPreference } from "../../../models/api/UnitPreference";
import { FormGroup } from "../../../components/forms/FormGroup";
import { FormWrapper } from "../../../components/forms/FormWrapper";
import { Input, StateType } from "../../../components/inputs/Input";
import { Dialog, DialogSize } from "../../../components/Dialog";
import { Label } from "../../../components/inputs/Label";
import PageHeaderNew from "../../../components/layout/pageHeader/PageHeaderNew";
import { Tag } from "../../../models/api/Tag";
import { TagsApiService } from "../../../services/api/TagsApiService";
import { LayoutContext, LayoutContextType } from "../../../contexts/layout/layout-context";

export interface IControllerAlertsPageMatchParams {
  alertId: string;
}

interface IProps extends RouteComponentProps<IControllerAlertsPageMatchParams> {}

interface Option {
  readonly label: string;
  readonly value: string;
}

interface IState {
  alert?: Alert;
  alertRecipients?: AlertRecipient[];
  alertRules?: AlertRule[];
  oldAlertRuleIds: string[];
  oldAlertRuleConditionIds: string[];
  alertName?: string;
  loading: boolean;
  emailRecipients: Option[];
  textRecipients: Option[];
  emailRecipientInputValue: string;
  textRecipientInputValue: string;
  devices?: Device[];
  tags: Tag[];
  deviceTypes?: DeviceType[];
  measurements?: Measurement[];
  unitPreferences?: UnitPreference[];
  deleteOnCancel: boolean;
  alertNameError: string;
  isFormInvalid: boolean;
  phoneNumberMessage: string;
  openSendTestMessageDialog: () => void;
  closeSendTestMessageDialog: () => void;
  SendTestMessageResponse?: string[];
  emailMessage: string;
  openEmailTestMessageDialog: () => void;
  closeEmailTestMessageDialog: () => void;
}

export class EditAlertPage extends Component<IProps, IState> {
  static contextType = LayoutContext;

  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: true,
      emailRecipients: [],
      textRecipients: [],
      emailRecipientInputValue: "",
      textRecipientInputValue: "",
      oldAlertRuleIds: [],
      oldAlertRuleConditionIds: [],
      deleteOnCancel: false,
      alertNameError: "",
      isFormInvalid: true,
      phoneNumberMessage: "",
      tags: [],
      openSendTestMessageDialog: () => {},
      closeSendTestMessageDialog: () => {},

      emailMessage: "",
      openEmailTestMessageDialog: () => {},
      closeEmailTestMessageDialog: () => {},
      SendTestMessageResponse: ["Send a text message to test if the number(s) is valid?"],
    };

    this.handleEmailKeyDown = this.handleEmailKeyDown.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.handleTextKeyDown = this.handleTextKeyDown.bind(this);
    this.handleTextBlur = this.handleTextBlur.bind(this);

    this.handleUpdateAlert = this.handleUpdateAlert.bind(this);
    this.areRulesValid = this.areRulesValid.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.validate = this.validate.bind(this);
    this.sendTestMessage = this.sendTestMessage.bind(this);
    this.emailTestMessage = this.emailTestMessage.bind(this);
  }

  componentDidMount() {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.removeAllButtons();
      layoutContext.removeAllExtraInfos();
    }

    const alertId = this.props.match.params.alertId;
    Promise.all([
      new AlertApiService().getById(alertId),
      new AlertApiService().listRecipients(alertId),
      new AlertApiService().listAlertRules(alertId),
      new AlertApiService().listDevicesForAlert(alertId),
      new DeviceTypesApiService().list(),
      new MeasurementsApiService().list(true),
      new MeasurementsApiService().listUnitPreferences(),
      new TagsApiService().listSuggestedTags(),
    ]).then((res) => {
      const alert = res[0];
      const alertRecipients = res[1];
      const alertRules = res[2];
      const emailRecipients = alertRecipients
        .filter((ar) => ar.type == "email")
        .map((ar) => ({
          label: ar.recipient as string,
          value: ar.recipient as string,
        }));
      const textRecipients = alertRecipients
        .filter((ar) => ar.type == "mobile")
        .map((ar) => ({
          label: ar.recipient as string,
          value: ar.recipient as string,
        }));
      const devices = res[3];
      const deviceTypes = res[4].items;
      const measurements = res[5].items;
      const unitPreferences = res[6];
      const tags = res[7];
      this.setState(
        () => {
          return {
            loading: false,
            alert: alert,
            alertName: alert.name,
            alertRecipients: alertRecipients,
            alertRules: alertRules,
            oldAlertRuleIds: alertRules.map((ar) => ar.id as string),
            emailRecipients: emailRecipients,
            textRecipients: textRecipients,
            devices: devices,
            deviceTypes: deviceTypes,
            measurements: measurements,
            unitPreferences: unitPreferences,
            tags,
          };
        },
        () => {
          if (this.state.alertRules != undefined && this.state.alertRules.length > 0) {
            const alertRules = [...this.state.alertRules];
            Promise.all(
              alertRules.map((ar) =>
                new AlertApiService()
                  .listAlertRuleConditions(ar.id)
                  .then((res) => (ar.conditions = res)),
              ),
            ).then(() =>
              this.setState(
                () => {
                  return {
                    alertRules: alertRules,
                    oldAlertRuleConditionIds: this.getOldAlertRuleConditionIds(),
                  };
                },
                () => {
                  // this.createValidators();
                  this.parseHash();
                },
              ),
            );
          } else {
            // this.createValidators();
            this.parseHash();
          }
        },
      );
    });
  }

  getOldAlertRuleConditionIds() {
    return this.state.alertRules
      ? this.state.alertRules.flatMap((ar) => ar.conditions ?? []).map((arc) => arc.id)
      : [];
  }

  validate(field: string) {
    let errorMessage = "";

    if (field === "alertName") {
      if (!this.state.alertName) errorMessage = "Please enter a name.";
    }

    this.setState((prevState) => ({
      ...prevState,
      [`${field}Error`]: errorMessage,
    }));

    this.setState((prevState) => ({
      ...prevState,
      isFormInvalid: !!prevState.alertNameError,
    }));
  }

  parseHash(): void {
    const hash = window.location.hash.substr(1);
    const splitHash = hash.split("&");
    const deviceId = splitHash.find((s) => s.indexOf("deviceId") > -1)?.split("=")[1];
    const measurement = splitHash.find((s) => s.indexOf("measurement") > -1)?.split("=")[1];
    const deleteOnCancel =
      splitHash.find((s) => s.indexOf("deleteOnCancel") > -1)?.split("=")[1] == "true";

    this.setState({
      deleteOnCancel,
    });

    if (deviceId != undefined && measurement != undefined) {
      this.addInitialRule(deviceId, measurement);
    }
  }

  addInitialRule(deviceId: string, measurement: string) {
    const measurementObj = this.state.measurements?.find((m) => m.name == measurement);
    if (!measurementObj) return;

    const alertRule: AlertRule = {
      id: new Guid().toString(),
      selectionCriteria: "devices",
      deviceIds: [deviceId],
      tagIds: [],
      deviceTypeIds: [],
      conditions: [
        {
          id: new Guid().toString(),
          measurementId: measurementObj.id as string,
          measurementFieldId:
            measurementObj.fieldCount == 1 && measurementObj.fields
              ? (measurementObj.fields[0].id as string)
              : "",
          operator: "=",
          value: "",
          isNew: true,
        },
      ],
      isNew: true,
      sortOrder: 0,
    };

    this.setState((state) => {
      return {
        alertRules: [...(state.alertRules ?? []), alertRule],
      };
    });
  }

  addNewRuleConditions(ar: AlertRule) {
    if (ar.conditions) {
      const newConditions = ar.conditions.filter((arc) => arc.isNew);
      const promises = newConditions.map((arc) => {
        const arcPromise = new AlertApiService().addAlertRuleCondition(ar.id, arc);
        arcPromise.then((alertRuleConditionId) => {
          arc.id = alertRuleConditionId;
          arc.isNew = false;
        });
        return arcPromise;
      });
      return promises;
    }
    return [];
  }

  handleUpdateAlert() {
    if (!this.state.alert || !this.state.alertRules || !this.state.alertRecipients) return;

    const thisAlert = this.state.alert;
    thisAlert.name = this.state.alertName;

    this.setState({
      alert: thisAlert,
    });

    const updateRules = this.state.alertRules
      .filter((ar) => !ar.isNew)
      .map((ar) => new AlertApiService().updateAlertRule(ar.id as string, ar));

    const newAlertRuleIds = this.state.alertRules
      .filter((ar) => !ar.isNew)
      .map((ar) => ar.id as string);
    const deleteRules = this.state.oldAlertRuleIds
      .filter((arId) => !newAlertRuleIds.includes(arId))
      .map((ar) => new AlertApiService().deleteAlertRule(ar));

    const addRuleConditions = this.state.alertRules // we can create rule conditions for alert rules that aren't new
      .filter((ar) => !ar.isNew)
      .flatMap((ar) =>
        (ar.conditions ?? [])
          .filter((arc) => arc.isNew)
          .map((arc) => {
            return new AlertApiService().addAlertRuleCondition(ar.id as string, arc).then((id) => {
              arc.id = id;
              arc.isNew = false;
            });
          }),
      );

    const updateRuleConditions = this.state.alertRules
      .flatMap((ar) => ar.conditions ?? [])
      .filter((arc) => !arc.isNew)
      .map((arc) => new AlertApiService().updateAlertRuleCondition(arc.id as string, arc));

    const newAlertRuleConditionIds = this.state.alertRules
      .flatMap((ar) => ar.conditions ?? [])
      .filter((arc) => !arc.isNew)
      .map((arc) => arc.id as string);
    const deleteRuleConditions = this.state.oldAlertRuleConditionIds
      .filter((arcId) => !newAlertRuleConditionIds.includes(arcId))
      .map((arcId) => new AlertApiService().deleteAlertRuleCondition(arcId));

    const currentEmailRecipients = this.state.alertRecipients
      .filter((ar) => ar.type == "email" && ar.recipient != undefined)
      .map((ar) => ar.recipient);
    const currentTextRecipients = this.state.alertRecipients
      .filter((ar) => ar.type == "mobile" && ar.recipient != undefined)
      .map((ar) => ar.recipient);

    const addRecipients = [
      ...this.state.emailRecipients
        .filter((e) => !currentEmailRecipients.includes(e.label))
        .map((e) =>
          new AlertApiService().addRecipients({
            alertId: thisAlert.id as string,
            recipient: e.value,
            type: "email",
          }),
        ),
      ...this.state.textRecipients
        .filter((e) => !currentTextRecipients.includes(e.label))
        .map((e) =>
          new AlertApiService().addRecipients({
            alertId: thisAlert.id as string,
            recipient: e.value,
            type: "mobile",
          }),
        ),
    ];

    const deleteRecipients = [
      ...this.state.alertRecipients
        .filter(
          (ce) =>
            ce.recipient &&
            ce.type == "email" &&
            !this.state.emailRecipients.map((e) => e.value).includes(ce.recipient),
        )
        .map((ce) => new AlertApiService().removeRecipients(ce.id as string)),
      ...this.state.alertRecipients
        .filter(
          (ce) =>
            ce.recipient &&
            ce.type == "mobile" &&
            !this.state.textRecipients.map((e) => e.value).includes(ce.recipient),
        )
        .map((ce) => new AlertApiService().removeRecipients(ce.id as string)),
    ];

    // Special case for creating new rules, since we need the id of the new rules to create their conditions
    // The following code uses dependent promises
    // That is, the alert rule conditions can only be saved after receiving the id of the new alert rule
    // The following array contains promises for each new alert rule. Each promise is resolved after all the
    // conditions have been sent to the server
    const newAlertRulePromises = new Array<Promise<string>>();
    const newAlertRules = this.state.alertRules.filter((ar) => ar.isNew);

    newAlertRules.map((ar) => {
      const newAlertRulePromise = new AlertApiService().addAlertRule(thisAlert.id as string, ar);
      newAlertRulePromises.push(
        new Promise<string>((resolve) => {
          newAlertRulePromise.then((alertRuleId) => {
            ar.id = alertRuleId;
            ar.isNew = false;
            Promise.all(this.addNewRuleConditions(ar)).then(() => resolve("done"));
          });
        }),
      );
    });

    Promise.all<any>([
      new AlertApiService().update(thisAlert),
      ...updateRules,
      ...deleteRules,
      ...addRuleConditions,
      ...updateRuleConditions,
      ...deleteRuleConditions,
      ...addRecipients,
      ...deleteRecipients,
      ...newAlertRulePromises,
    ])
      .then(() => {
        this.setState({
          oldAlertRuleConditionIds: this.getOldAlertRuleConditionIds(),
        });

        $.notification("show", {
          type: "message",
          title: undefined,
          message: "The Alert was successfully updated",
          toastOnly: true,
        });
      })
      .catch((err) => {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        });
      });
  }

  createOption(label: string) {
    return {
      label,
      value: label,
    };
  }

  handleEmailKeyDown(event: any) {
    const { emailRecipientInputValue, emailRecipients } = this.state;
    if (!emailRecipientInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
      case " ":
      case ";":
        this.setState({
          emailRecipientInputValue: "",
          emailRecipients: [...emailRecipients, this.createOption(emailRecipientInputValue)],
        });
        event.preventDefault();
    }
  }

  handleEmailBlur(event: any) {
    const { emailRecipientInputValue, emailRecipients } = this.state;
    if (!emailRecipientInputValue) return;
    this.setState({
      emailRecipientInputValue: "",
      emailRecipients: [...emailRecipients, this.createOption(emailRecipientInputValue)],
    });
    event.preventDefault();
  }

  handleTextKeyDown(event: any) {
    const { textRecipientInputValue, textRecipients } = this.state;
    if (!textRecipientInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
      case " ":
      case ";":
        {
          const isNumberValid = this.validateNumber(textRecipientInputValue);
          if (isNumberValid) {
            this.setState({
              phoneNumberMessage: "",
              textRecipientInputValue: "",
              textRecipients: [...textRecipients, this.createOption(textRecipientInputValue)],
            });
          } else {
            // show the error message
            this.setState({
              phoneNumberMessage: "The phone number you entered is invalid ",
            });
          }
        }
        event.preventDefault();
    }
  }

  handleTextBlur(event: any) {
    const { textRecipientInputValue, textRecipients } = this.state;
    if (!textRecipientInputValue) return;
    const isNumberValid = this.validateNumber(textRecipientInputValue);
    console.log(isNumberValid);
    if (isNumberValid) {
      this.setState({
        phoneNumberMessage: "",
        textRecipientInputValue: "",
        textRecipients: [...textRecipients, this.createOption(textRecipientInputValue)],
      });
    } else {
      // show the error message
      this.setState({
        phoneNumberMessage: "The phone number you entered is invalid ",
      });
    }

    event.preventDefault();
  }

  validateNumber(newNumber: string): boolean {
    const re = /^\+\d{7,}$/;
    return re.test(newNumber);
  }

  emailTestMessage(): void {
    const emails = this.state.emailRecipients.map((o) => {
      return o.value;
    });
    new AlertApiService()
      .emailTestMessage(emails)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Email has been sent.",
          toastOnly: true,
        });
      })
      .catch((err) => {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        });
      });
    this.state.closeEmailTestMessageDialog();
  }

  sendTestMessage(): void {
    const phoneNumbers = this.state.textRecipients.map((o) => {
      return o.value;
    });
    new AlertApiService()
      .sendTextMessage(phoneNumbers)
      .then((res) => {
        this.setState({
          SendTestMessageResponse: res,
        });
      })
      .catch((err) => {
        this.setState({
          SendTestMessageResponse: [err],
        });
      });
    /* this.state.closeSendTestMessageDialog();*/
  }
  areRulesValid(): boolean {
    if (!this.state.alertRules) return false;
    const alertRulesValidInvalid = this.state.alertRules
      .map(
        (ar) =>
          ar.selectionCriteria == "" ||
          (ar.selectionCriteria == "devices" && ar.deviceIds.length <= 0) ||
          (ar.selectionCriteria == "deviceTypes" && ar.deviceTypeIds.length <= 0),
      )
      .reduce((a, b) => a || b, false);

    const alertRuleConditionsInvalid = this.state.alertRules
      .flatMap((ar) => ar.conditions ?? [])
      .map(
        (arc) =>
          arc.measurementId == "" ||
          arc.measurementFieldId == "" ||
          arc.operator == "" ||
          arc.value == "",
      )
      .reduce((a, b) => a || b, false);

    return !alertRulesValidInvalid && !alertRuleConditionsInvalid;
  }

  handleDelete() {
    new AlertApiService()
      .remove(this.props.match.params.alertId)
      .then(() => {
        window.location.href = "/go/manage/alerts";
      })
      .catch((err) => {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        });
      });
  }

  render() {
    return (
      <div className="max-width-container-xl">
        <PageHeaderNew
          pageTitle="Edit Alert"
          pageTitleType="Breadcrumb"
          breadcrumbSetting={{
            backPageTitle: "Configure Alerts",
            backUrl: "/go/manage/config/alerts",
          }}
        />

        <section className="content">
          {!this.state.loading && (
            <>
              <FormWrapper>
                <FormGroup title="Alert Settings">
                  <form id="alert-form">
                    <div className="col">
                      <div className="col-xl-12 col-lg-6 col-sm-12 mb-3">
                        <div className="">
                          <Input
                            idName="alertName"
                            labelString="Name"
                            inputValue={this.state.alertName}
                            onChange={(e) =>
                              this.setState({
                                alertName: e.target.value,
                              })
                            }
                            inputState={StateType.Default}
                            isRequired={false}
                            hasTooltip={false}
                            placeholder="Please insert alert name"
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-6 col-sm-12 mb-3">
                        <div className="">
                          <Label idName="emailRecipients" labelString="Email Recipients" />
                          <CreatableSelect
                            inputId="emailRecipients"
                            classNamePrefix="react-select"
                            className="input w-100"
                            components={{ DropdownIndicator: null }}
                            inputValue={this.state.emailRecipientInputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={(value: any) => this.setState({ emailRecipients: value })}
                            onInputChange={(value: any) =>
                              this.setState({
                                emailRecipientInputValue: value,
                              })
                            }
                            onKeyDown={this.handleEmailKeyDown}
                            onBlur={this.handleEmailBlur}
                            placeholder="Enter an email address..."
                            value={this.state.emailRecipients}
                          />
                          <p className="fg-color-error text-caption input-error-msg">
                            {" "}
                            {this.state.emailMessage}{" "}
                          </p>
                          <Button
                            content="Email me a test message"
                            buttonType={ButtonType.Outline}
                            onClick={this.state.openEmailTestMessageDialog}
                            iconClass="fa-solid fa-bell-on"
                            small={true}
                            disabled={this.state.emailRecipients.length == 0}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-6 col-sm-12 mb-3">
                        <div className="">
                          <Label idName="textRecipients" labelString="Text Recipients" />
                          <CreatableSelect
                            inputId="textRecipients"
                            classNamePrefix="react-select"
                            className="input w-100"
                            components={{ DropdownIndicator: null }}
                            inputValue={this.state.textRecipientInputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={(value: any) => {
                              this.setState({ textRecipients: value });
                            }}
                            onInputChange={(value: any) => {
                              this.setState({
                                textRecipientInputValue: value,
                              });
                            }}
                            onKeyDown={this.handleTextKeyDown}
                            onBlur={this.handleTextBlur}
                            placeholder="Enter an mobile number..."
                            value={this.state.textRecipients}
                          />
                          <small>e.g. +155577899 or +6421555789</small>
                          <p className="fg-color-error text-caption input-error-msg">
                            {" "}
                            {this.state.phoneNumberMessage}{" "}
                          </p>
                          <Button
                            content="Text me a test message"
                            buttonType={ButtonType.Outline}
                            onClick={this.state.openSendTestMessageDialog}
                            iconClass="fa-solid fa-bell-on"
                            small={true}
                            disabled={this.state.textRecipients.length == 0}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </FormGroup>
              </FormWrapper>

              {newAlertUxEnabled && (
                <div className="mt-3">
                  <AlertRulesTable
                    alertRules={this.state.alertRules as AlertRule[]}
                    devices={this.state.devices as Device[]}
                    deviceTypes={this.state.deviceTypes as DeviceType[]}
                    measurements={this.state.measurements as Measurement[]}
                    unitPreferences={this.state.unitPreferences as UnitPreference[]}
                    tags={this.state.tags}
                    onAlertRulesChange={(alertRules) =>
                      this.setState({
                        alertRules,
                      })
                    }
                  />
                </div>
              )}
              <div className="d-flex flex-row mt-3" style={{ padding: "0 20px" }}>
                <span className="flex-grow-0 flex-md-grow-1"></span>
                {this.state.deleteOnCancel && (
                  <Button
                    content="Delete"
                    buttonType={ButtonType.Error}
                    onClick={this.handleDelete}
                  />
                )}
                {!this.state.deleteOnCancel && (
                  <Link to="/go/manage/alerts">
                    <Button content="Cancel" buttonType={ButtonType.Outline} />
                  </Link>
                )}
                <span className="ml-2"></span>
                <Button
                  data-tip
                  data-for="alert-update-button"
                  content="Save"
                  buttonType={ButtonType.Fill}
                  onClick={this.handleUpdateAlert}
                />
                {!this.areRulesValid() && (
                  <ReactTooltip id="alert-update-button" effect="float">
                    Rules are not valid, please complete your rules before updating alert
                  </ReactTooltip>
                )}
              </div>
            </>
          )}

          {this.state.loading && (
            <div className="row">
              <div className="col-12">
                <LoadingSpinner />
              </div>
            </div>
          )}
          <br />
          <br />
        </section>

        <Dialog
          size={DialogSize.Small}
          header="Text me a test Message"
          setOpen={(open) =>
            this.setState({
              openSendTestMessageDialog: open,
            })
          }
          setClose={(close) =>
            this.setState({
              closeSendTestMessageDialog: close,
            })
          }
          onClose={() => {
            this.setState({
              SendTestMessageResponse: ["Send a text message to test if the number(s) is valid?"],
            });
          }}
          body={
            <div>
              {this.state.SendTestMessageResponse?.map((msg) => {
                return <p>{msg}</p>;
              })}
            </div>
          }
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                buttonType={ButtonType.Transparent}
                content="Close"
                onClick={this.state.closeSendTestMessageDialog}
              />
              <Button
                content="Send"
                buttonType={ButtonType.Success}
                onClick={this.sendTestMessage}
              />
            </>
          }
        />

        <Dialog
          size={DialogSize.Small}
          header="Email me a test Message"
          setOpen={(open) =>
            this.setState({
              openEmailTestMessageDialog: open,
            })
          }
          setClose={(close) =>
            this.setState({
              closeEmailTestMessageDialog: close,
            })
          }
          onClose={() => {}}
          body={<p>Email a text message to test if the email(s) is valid?</p>}
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                buttonType={ButtonType.Transparent}
                content="Close"
                onClick={this.state.closeEmailTestMessageDialog}
              />
              <Button
                content="Email"
                buttonType={ButtonType.Success}
                onClick={this.emailTestMessage}
              />
            </>
          }
        />
      </div>
    );
  }
}
