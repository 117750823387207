import { ApiService } from "./ApiService";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { AccessGroupTag, Tag } from "../../models/api/Tag";
import { PagedResults } from "../../models/api/PagedResults";
import { TableFilters } from "../../models/api/TableFilters";
import { Promise } from "bluebird";
import { DeviceTag } from "../../models/api/DeviceTag";

export class TagApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "tags");

    this.list = this.list.bind(this);
  }

  list(tableFilters?: TableFilters): Promise<PagedResults<Tag>> {
    return new Promise<PagedResults<Tag>>((resolve, reject, cancel) => {
      this.get(
        `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const tags = result.data.map(
            (d: any) =>
              ({
                id: d.TABLEID,
                name: d.NAME,
                recordCreatedOn: d.CREATEDAT ? moment.utc(d.CREATEDAT).local() : undefined,
                deviceCount: d.DEVICECOUNT,
                accessGroupCount: d.ACCESSGROUPCOUNT,
              }) as Tag,
          );
          resolve({
            items: tags,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getById(tagId: string) {
    return new Promise<Tag>((resolve, reject, cancel) => {
      this.get(`${tagId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.TABLEID,
            name: d.NAME,
            RecordCreatedOn: d.RECORDCREATEDON ? moment(new Date(d.RECORDCREATEDON)) : undefined,
            orgId: d.ORGID,
            accessGroup: d.ACCESSGROUPS,
            devices: d.DEVICES,
          } as Tag);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  create(newTag: Tag): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("", newTag, cancel).done((result) => {
        if (result.success) {
          resolve(result.newTagId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  update(editTag: Tag): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`${editTag.id}`, editTag, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  remove(tagId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${tagId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getAccessGroupsByTagId(tagId: string, tableFilters?: TableFilters) {
    return new Promise<PagedResults<AccessGroupTag>>((resolve, reject, cancel) => {
      this.get(
        `${tagId}/accessGroups` +
          `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const accessGroups = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                accessGroupId: d.ACCESSGROUPID,
                tagId: d.TAGID,
                orgId: d.ORGID,
                groupName: d.GROUPNAME,
                deviceCount: d.DEVICECOUNT,
              }) as AccessGroupTag,
          );

          resolve({
            items: accessGroups,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  removeAccessGroupFromTag(accessGroupTagId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`accessGroup/${accessGroupTagId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateAccessGroupTag(tagId: string, accessGroupTags: string[]): Promise<void> {
    const postData = {
      accessGroups: JSON.stringify(accessGroupTags),
    };

    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`accessGroupTag/${tagId}`, postData, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDevicesByTagId(tagId: string, tableFilters?: TableFilters) {
    return new Promise<PagedResults<DeviceTag>>((resolve, reject, cancel) => {
      this.get(
        `${tagId}/devices` +
          `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                deviceId: d.DEVICEID,
                tagId: d.TAGID,
                serialNumber: d.SERIALNUMBER,
                deviceName: d.NAME,
              }) as DeviceTag,
          );

          resolve({
            items: devices,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  removeDeviceFromTag(deviceTagId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`device/${deviceTagId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateDeviceTag(tagId: string, deviceTags: string[]): Promise<void> {
    const postData = {
      devices: JSON.stringify(deviceTags),
    };

    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`deviceTag/${tagId}`, postData, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
