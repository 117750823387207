import { ReactChild, useState } from "react";
import { IconButton } from "./IconButton";

import { IconType as IconTypes, Icon } from "./Icon";
import { Link } from "react-router-dom";
import { useLayout } from "../contexts/layout/layout-context";

interface IProps {
  backButton?: boolean;
  backUrl?: string;
  pageTitle: ReactChild | undefined;
  backPageTitle?: ReactChild;
  buttons?: ReactChild;
  subHeader?: boolean;
  icon?: ReactChild;
  isDropdown?: boolean;
  addButton?: ReactChild;
  deviceInfo?: ReactChild;
}

export function PageHeader(props: IProps) {
  const [isFavourite, setIsFavourite] = useState(false);

  const { headerButtons, updatedPageTitle, updatedBackPageTitle, updatedBackUrl } = useLayout();

  async function toggleFavourite() {
    setIsFavourite(!isFavourite);
  }

  const GetHeaderButtons = () => {
    if (headerButtons) {
      return headerButtons;
    } else if (props.buttons) {
      return <>{props.buttons}</>;
    } else {
      return null;
    }
  };

  const GetPageTitle = () => {
    let pageTitle = props.pageTitle;

    if (updatedPageTitle) {
      pageTitle = updatedPageTitle;
    }

    if (props.isDropdown) {
      return (
        <div className="dropdown-page-title d-flex flex-row">
          <h1 className="m-0 text-page-title-bold">{pageTitle}</h1>
          <div className="p-1">
            <Icon
              iconType={isFavourite ? IconTypes.Fill : IconTypes.Outline}
              iconClass="fa fa-star"
              className="favourite-icon"
              onClick={toggleFavourite}
            />
            <span className="mr-3" />
            <i className="fa fa-angle-down icon-default" aria-hidden="true" />
          </div>
        </div>
      );
    } else if (props.subHeader) {
      return <h4 className="m-0">{pageTitle}</h4>;
    } else {
      return <h1 className="m-0">{pageTitle}</h1>;
    }
  };

  const GetBackPageTitle = () => {
    let backPageTitle = props.backPageTitle;
    let backUrl = props.backUrl;

    if (updatedBackPageTitle) {
      backPageTitle = updatedBackPageTitle;
    }

    if (updatedBackUrl) {
      backUrl = updatedBackUrl;
    }

    if (!!backPageTitle && !!backUrl) {
      return <Link to={backUrl}> {backPageTitle} / </Link>;
    }

    return null;
  };

  return (
    <div className="d-flex flex-row align-items-center">
      {props.backButton && (
        <>
          <IconButton
            isDark={true}
            iconClass="fas fa-arrow-left"
            onClick={() => {
              if (!props.backUrl) window.history.back();
              else window.location.href = props.backUrl;
            }}
          />
          <span className="mr-2" />
        </>
      )}

      {/* {props.backPageTitle && props.backUrl && (
                <Link to={props.backUrl}> {props.backPageTitle} / </Link>
            )} */}
      <GetBackPageTitle />

      {/* {props.isDropdown ? (
                <>
                <div className="dropdown-page-title d-flex flex-row">
                    <h1 className="m-0 text-page-title-bold">{props.pageTitle}</h1>
                    <div className="p-1">
                    <Icon
                        iconType={isFavourite ? IconTypes.Fill : IconTypes.Outline}
                        iconClass="fa fa-star"
                        className="favourite-icon"
                        onClick={toggleFavourite}
                    />
                    <span className="mr-3"/>
                    <i className="fa fa-angle-down icon-default" aria-hidden="true"/>
                    </div>
                </div>
                </>
            ) : (
                <>
                {!props.subHeader && <h1 className="m-0">{props.pageTitle}</h1>}
                {props.subHeader && <h4 className="m-0">{props.pageTitle}</h4>}
                </>
            )} */}
      <GetPageTitle />

      {/* {props.icon} */}

      <span className="flex-grow-1 mr-2" />
      {/* {props.buttons} */}

      <GetHeaderButtons />

      {!!props.addButton && <div className="bg-color-primary-theme">{props.addButton}</div>}
      {!!props.deviceInfo && <div>{props.deviceInfo}</div>}
    </div>
  );
}
