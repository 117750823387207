import { ChangeEvent, FocusEvent, MouseEventHandler, useState } from "react";
import "./inputStyles.scss";
import { Label } from "./Label";
import { IconButton } from "../IconButton";

export enum InputType {
  Text,
  Number,
  Password,
  Email,
}

export enum StateType {
  Default,
  Error,
  Disabled,
}

interface IProps {
  idName: string;
  isRequired?: boolean;

  labelString?: string;
  hasTooltip?: boolean;
  tooltipText?: string;

  iconClass: string;
  containerClass?: string;

  placeholder: string;
  inputType?: InputType;
  inputState?: StateType;
  inputValue?: string;
  errorMsg?: string;
  onChange:
    | ((event: ChangeEvent<HTMLInputElement>) => void)
    | ((event: FocusEvent<HTMLInputElement>) => void);
  onClick?: MouseEventHandler<HTMLInputElement> | undefined;
}

export function InputWithIcon(props: IProps) {
  const [onFocus, setOnFocus] = useState<boolean>(false);

  const isDisabled: boolean = props.inputState == StateType.Disabled;
  const isError: boolean = props.inputState == StateType.Error;
  const type: string = generateType(props.inputType);
  const classes: string[] = ["input-with-icon-container"];
  classes.push(generateStateClass(props.inputState));

  // onInputFocus() and onInputBlur() are used in the .input-with-icon-container (the most outer container holding the input tag, and IconButton Component) to make it look like a regular input
  // It mimics the onFocus and onFocusOut of the actual input tag inside this container and reflects it here for the user to see.
  function onInputFocus() {
    setOnFocus(true);
  }

  function onInputBlur() {
    setOnFocus(false);
  }

  // Labels for input component are always going to be Standard size
  const _label = props.labelString ? (
    <Label
      idName={props.idName}
      labelString={props.labelString}
      isRequired={props.isRequired}
      hasTooltip={props.hasTooltip}
      tooltipText={props.tooltipText}
    />
  ) : (
    <></>
  );

  const _input = (
    <div className={classes.join(" ") + (onFocus ? " multi-email-focus" : "")} tabIndex={0}>
      <input
        className="input-with-icon-input"
        id={props.idName}
        type={type}
        disabled={isDisabled}
        placeholder={props.placeholder}
        value={props.inputValue}
        required={props.isRequired}
        onChange={props.onChange}
        onClick={props.onClick}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
      <IconButton iconClass={props.iconClass} />
    </div>
  );

  const _errorMsg = isError ? (
    <p className="fg-color-error text-caption input-error-msg"> {props.errorMsg} </p>
  ) : (
    <></>
  );

  return (
    <div className={props.containerClass}>
      {_label}
      {_input}
      {_errorMsg}
    </div>
  );
}

function generateStateClass(inputState?: StateType) {
  if (!inputState) inputState = StateType.Default;
  let stateClass: string = "state-";

  switch (inputState) {
    case StateType.Default:
      stateClass += "default";
      break;
    case StateType.Error:
      stateClass += "error";
      break;
    case StateType.Disabled:
      stateClass += "disabled";
      break;
  }

  return stateClass;
}

function generateType(inputType?: InputType) {
  if (!inputType) inputType = InputType.Text;
  let type: string = "";

  switch (inputType) {
    case InputType.Text:
      type = "text";
      break;
    case InputType.Number:
      type = "number";
      break;
    case InputType.Password:
      type = "password";
      break;
    case InputType.Email:
      type = "email";
      break;
  }

  return type;
}
