import { FC, Fragment, ReactElement, useEffect, useState } from "react";
import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { DivIcon, LatLngBounds, latLngBounds, Map } from "leaflet";
import { useDeviceList } from "../../../services/context/DashboardApiServiceContext";
import { DashboardDevice } from "../../../models/api/DashboardDevice";
import DateHelpers from "../../../helpers/DateHelpers";
import { DashboardButton } from "../../DashboardButton";
import { Button, ButtonType } from "../../Button";
import { LoadingSkeleton } from "../../LoadingSkeleton";
import { BatteryGauge } from "../../buttons/components/BatteryGauge";
import { NoDataCard } from "../../cards";
import { WidgetIcons } from "../../../pages/simpleapp/components/SimpleAppTemplate";
import SupportLink from "../../../helpers/SupportLink";
import { CoordinatesHelper } from "../../../helpers/CoordinatesHelper";
import { useDevicesInAlert } from "../../../services/context/AlertApiServiceContext";

const L = window["L"];

type Props = {
  filter: string;
  selectedDeviceId: string;
};

const OverviewMap: FC<Props> = ({ filter, selectedDeviceId }) => {
  const errorIcon: WidgetIcons = {
    icon: "fa-regular fa-file-exclamation",
    errorIcon: "fa-regular fa-file-exclamation",
  };
  const { isSuccess: devicesLoaded, data: allDashboardDevices } = useDeviceList({
    filter,
    includeMeasurements: false,
    includeSensorDetails: false,
    includeDevicesWithoutLocation: false,
  });
  const { isSuccess: devicesInAlertLoaded, data: devicesInAlert } = useDevicesInAlert();

  const [map, setMap] = useState<Map>();
  const [markers, setMarkers] = useState<ReactElement>();
  const [filteredDevices, setFilteredDevices] = useState<DashboardDevice[]>([]);
  const [isClustered, setIsClustered] = useState<boolean>(true);
  const [dashboardDevices, setDashboardDevices] = useState<DashboardDevice[]>();

  // When device first loaded
  useEffect(() => {
    if (devicesLoaded && !!allDashboardDevices) {
      setDashboardDevices(allDashboardDevices);
      setFilteredDevices(allDashboardDevices);
    }
  }, [devicesLoaded, allDashboardDevices]);

  useEffect(() => {
    if (filteredDevices && devicesInAlertLoaded) generateMarkers();
  }, [filteredDevices, devicesInAlertLoaded]);

  useEffect(() => {
    if (map && filteredDevices) fitMapToBounds();
  }, [map, selectedDeviceId, filteredDevices]);

  useEffect(() => {
    filterDevices();
  }, [selectedDeviceId, dashboardDevices]);

  const fitMapToBounds = () => {
    const mapBounds = calculateMapBounds();
    if (mapBounds && map) {
      map.fitBounds(mapBounds);
      if (map.getZoom() >= 12) map.setZoom(12);
      if (map.getZoom() === 0) map.setZoom(1);
    }
  };

  const createClusterCustomIcon = function (cluster: any) {
    const childMarkers = cluster.getAllChildMarkers();
    const alertMarkerClass = "fg-color-error";
    // find all child marker which are in alert state
    // alert state is found by checking if the child has a `alertMarkerClass` class
    const alertedMarkers = childMarkers.filter((m: any) =>
      m.options.icon.options.html.includes(alertMarkerClass),
    );
    const className =
      "leaflet-marker-icon marker-cluster leaflet-zoom-animated leaflet-interactive" +
      ` marker-cluster-${alertedMarkers.length === 0 ? "no-" : ""}alert`;
    const childCount = cluster.getChildCount();
    return new DivIcon({
      html: `<div><span>${childCount}</span></div>`,
      className,
      iconSize: L.point(40, 40, true),
    });
  };

  const isDeviceAlerted = (device: DashboardDevice): boolean => {
    return devicesInAlertLoaded && devicesInAlert
      ? devicesInAlert.find((d) => d.DeviceId === device.id.toUpperCase()) !== undefined
      : false;
  };

  const calculateMapBounds = (): LatLngBounds | undefined => {
    if (!devicesLoaded) return;
    const latLngs = filteredDevices
      ?.filter((dd) => !!getLat(dd) && !!getLng(dd))
      .map((dd) => ({
        lat: getLat(dd) as number,
        lng: getLng(dd) as number,
      }));
    if (!latLngs || latLngs.length <= 0) return;
    return latLngBounds(latLngs).pad(0.2);
  };

  const getLat = (item: DashboardDevice): number | undefined => {
    // @ts-expect-error
    return item.latitudeHardcoded && item.latitudeHardcoded != "NaN"
      ? item.latitudeHardcoded
      : item.latitude;
  };

  const getLng = (item: DashboardDevice): number | undefined => {
    // @ts-expect-error
    return item.longitudeHardcoded && item.longitudeHardcoded != "NaN"
      ? item.longitudeHardcoded
      : item.longitude;
  };

  const filterDevices = () => {
    if (devicesLoaded) {
      let filteredDevices = dashboardDevices || [];
      if (selectedDeviceId !== "") {
        filteredDevices = filteredDevices.filter((d) => d.id === selectedDeviceId);
      }
      setFilteredDevices(filteredDevices);
    }
  };

  const generateMarkers = () => {
    const markers = (
      <>
        {filteredDevices
          .filter(
            (d) =>
              !!getLat(d) &&
              !!getLng(d) &&
              CoordinatesHelper.areCoordinatesValid(Number(getLat(d)), Number(getLng(d))),
          )
          .map((d) => {
            const colorClassName = isDeviceAlerted(d) ? "fg-color-error" : "fg-color-primary-theme";
            const html = `<i class="fas fa-map-marker-alt ${colorClassName} fa-3x text-white-outline"></i>`;

            return (
              <Marker
                key={d.id}
                position={{
                  lat: getLat(d) as number,
                  lng: getLng(d) as number,
                }}
                icon={
                  new DivIcon({
                    html,
                    iconAnchor: [13, 36],
                    popupAnchor: [0, -36],
                  })
                }
              >
                <Popup maxWidth={1000}>
                  <div className="d-flex flex-column">
                    <h5>{d.name}</h5>
                    <div className="d-flex flex-row mt-2 mb-2">
                      <strong>Last Seen&nbsp;</strong>
                      {DateHelpers.formatMomentAsTimeSince(d.timestamp)}
                    </div>

                    <BatteryGauge item={d} label={true} />
                    <span className="mb-3"></span>

                    <div className="d-flex flex-row align-items-start">
                      <DashboardButton
                        deviceId={d.id}
                        dashboardTemplateId={d.dashboardTemplateId}
                      />
                      <span className="mr-3"></span>
                      <a href={`/go/devices/${d.id}`}>
                        <Button
                          content="Edit"
                          iconClass="fa fa-pencil-alt"
                          buttonType={ButtonType.Outline}
                        />
                      </a>
                    </div>
                  </div>
                </Popup>
              </Marker>
            );
          })}
        ;
      </>
    );
    setMarkers(markers);
  };

  return (
    <>
      {!devicesLoaded && (
        <Fragment>
          <LoadingSkeleton width="100%" height="100%" />
        </Fragment>
      )}

      {devicesLoaded &&
        (filteredDevices && filteredDevices.length > 0 ? (
          <MapContainer
            whenCreated={(map) => setMap(map)}
            zoom={13}
            scrollWheelZoom={true}
            style={{ height: "100%", borderRadius: "5px" }}
            worldCopyJump={true}
          >
            <div className="leaflet-bottom leaflet-left">
              <div
                className={
                  "leaflet-custom-button leaflet-control" + (isClustered ? " selected" : "")
                }
              >
                <a role="button" onClick={() => setIsClustered(!isClustered)}>
                  <strong> Clustering {isClustered ? "On" : "Off"}</strong>
                </a>
              </div>
              <div className="leaflet-custom-button leaflet-control">
                <a role="button" onClick={fitMapToBounds}>
                  <strong> Center Map </strong>
                </a>
              </div>
            </div>

            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Roads">
                <TileLayer
                  attribution={
                    'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
                    'contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                  }
                  url={
                    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                  }
                  accessToken={
                    "pk.eyJ1IjoibW9kdXNlbnNlIiwiYSI6ImNqb25tdG5idDBieHUzdnBncndkc2ZuNjQifQ.H75OJyk0mCT9CcLXWcBfWw"
                  }
                  id="mapbox/streets-v11"
                  zoomOffset={-1}
                  maxZoom={18}
                  tileSize={512}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Satellite">
                <TileLayer
                  attribution={
                    'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
                    'contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                  }
                  url={
                    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                  }
                  accessToken={
                    "pk.eyJ1IjoibW9kdXNlbnNlIiwiYSI6ImNqb25tdG5idDBieHUzdnBncndkc2ZuNjQifQ.H75OJyk0mCT9CcLXWcBfWw"
                  }
                  id="mapbox/satellite-v9"
                  zoomOffset={-1}
                  maxZoom={18}
                  tileSize={512}
                />
              </LayersControl.BaseLayer>
            </LayersControl>

            {isClustered && (
              // @ts-expect-error
              <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                {markers}
              </MarkerClusterGroup>
            )}

            {!isClustered && markers}
          </MapContainer>
        ) : (
          <div>
            <NoDataCard
              cardCaption="There are no devices with a GPS location."
              supportQuestion={
                <p>
                  Expecting data?
                  <span>&nbsp;</span>
                  <SupportLink />
                </p>
              }
              iconClass={errorIcon.errorIcon}
              iconPos="left"
            />
          </div>
        ))}
    </>
  );
};

export default OverviewMap;
