import { FC, useEffect, useState } from "react";
import "../cardStyles.scss";
import { GetMissingDataDisplay } from "../CardHelper";

type Props = {
  currLevel: number;
  currLevelDisplay: string;
  maxLevel?: number;
  maxLevelDisplay?: string;
  cardCaption: string;

  isCritical: boolean;
  iconClass: string | JSX.Element;
  iconPos?: "left" | "right";
  lastSeen?: string;

  // For missing data
  errorDets?: string;
  supportQuestion?: string | JSX.Element;

  // For Cylinder
  lowestLevelAlert?: number;
};

const LevelProgressCard: FC<Props> = ({
  currLevel,
  currLevelDisplay,
  maxLevel,
  maxLevelDisplay,
  cardCaption,

  isCritical,
  iconClass,
  iconPos,
  lastSeen,

  errorDets,
  supportQuestion,
}) => {
  // Card Numbers
  const [percentage, setPercentage] = useState("");

  // Icon
  const [chartIcon, setChartIcon] = useState<JSX.Element>(<></>);

  // Error in the Card
  const [errorDisplayDets, setErrorDisplayDets] = useState(
    "We need more information to provide you with better valuable data.",
  );
  const [errorDisplaySupport, setErrorDisplaySupport] = useState<string | JSX.Element>(
    <p>
      {" "}
      Please contact <span className="text-caption-link">support</span>.
    </p>,
  );

  useEffect(() => {
    if (iconClass) {
      if (typeof iconClass == "string") {
        const _gauge = <i className={iconClass} />;
        setChartIcon(_gauge);
        return;
      } else {
        setChartIcon(iconClass);
      }
    }
  }, [iconClass]);

  useEffect(() => {
    if (!!currLevel && !!maxLevel) {
      const percentage = ((currLevel / maxLevel) * 100).toFixed(0).toString().concat("%");
      setPercentage(percentage);
    }
  }, [currLevel, maxLevel]);

  useEffect(() => {
    if (errorDets) setErrorDisplayDets(errorDets);

    if (supportQuestion) setErrorDisplaySupport(supportQuestion);
  }, [errorDets, supportQuestion]);

  const GetLastSeenBadge = () => {
    if (lastSeen) {
      return <div className="progress-card-last-seen-badge">{lastSeen}</div>;
    }
    return <></>;
  };

  return (
    <div style={isCritical ? { borderColor: "#E05B5B" } : {}} className="progress-card-container">
      <section className="progress-card-info">
        <section className="progress-card-details">
          <p className="progress-card-num">
            <span className="text-numbers-bold fg-color-black-dark"> {currLevelDisplay} </span>
            {!!maxLevelDisplay && <span> / {maxLevelDisplay} </span>}
            {!!percentage && <span className="text-para-small-bold"> {percentage} remaining </span>}
          </p>
          <p className="progress-card-dets">{cardCaption}</p>
        </section>

        <section
          style={{ order: `${!!iconPos && iconPos == "left" ? "-1" : ""}` }}
          className="progress-card-icon"
        >
          {chartIcon}
        </section>
      </section>

      {!percentage && <>{GetMissingDataDisplay(errorDisplayDets, errorDisplaySupport)}</>}

      <GetLastSeenBadge />
    </div>
  );
};

export default LevelProgressCard;
