import { FC } from "react";
import "../buttonStyles.scss";
import { useHistory } from "react-router-dom";
import { Guid } from "js-guid";

export interface DropdownMenuListItem {
  listName: string;
  onclick: () => void;
  isInternalLink?: boolean;
  linkTo?: string;
  id?: string;
}

type Props = {
  dropdownMenuList: DropdownMenuListItem[];
  dropdownMenuSpecialListItem?: JSX.Element;
  dropdownMenuId?: string;
  dropdownMenuWidth?: string;
};

// This is the dropdown menu only not the button that the user will click //
// The user will click a ButtonDropdown and ButtonDropdownMenu will show/hide //
// This is the default view //
// There are other different views like ButtonDropdownAlertNotification //
const ButtonDropdownMenu: FC<Props> = ({
  dropdownMenuList,
  dropdownMenuSpecialListItem,
  dropdownMenuId,
  dropdownMenuWidth,
}) => {
  const history = useHistory();

  const GenerateDropdownMenuList = () => {
    const menuList: JSX.Element[] = [];

    if (dropdownMenuList) {
      dropdownMenuList.forEach((listItem) => {
        const isInternal = listItem.isInternalLink ?? true;

        if (isInternal) {
          menuList.push(
            <div
              key={new Guid().toString()}
              className="button-dropdown-menu-list-item"
              id={listItem.id ?? ""}
              onClick={() => {
                listItem.onclick();
                if (listItem.linkTo) {
                  history.push(listItem.linkTo);
                }
              }}
            >
              {listItem.listName}
            </div>,
          );
        } else {
          menuList.push(
            <a
              key={new Guid().toString()}
              className="button-dropdown-menu-list-item"
              href={listItem.linkTo}
              id={listItem.id ?? ""}
              target="_blank"
              onClick={listItem.onclick}
            >
              {listItem.listName}
              <i className="fa fa-external-link" aria-hidden="true" />
            </a>,
          );
        }
      });
    }

    return (
      <div className="button-dropdown-menu-list">
        {menuList}
        {dropdownMenuSpecialListItem}
      </div>
    );
  };

  return (
    <section
      id={dropdownMenuId ?? ""}
      className="button-dropdown-menu-container"
      style={{ width: dropdownMenuWidth }}
    >
      <GenerateDropdownMenuList />
    </section>
  );
};

export default ButtonDropdownMenu;
