import { Component, ReactChild } from "react";
import { OrganizationsApiService } from "../../../services/api/OrganizationsApiService";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { Organization } from "../../../models/api/Organization";
import { OrganizationDetailsPage } from "../../shared/OrganizationDetailsPage";
import { User } from "../../../models/api/User";
import { UsersApiService } from "../../../services/api/UsersApiService";
import { LayoutContext, LayoutContextType } from "../../../contexts/layout/layout-context";

interface IProps {
  pageTitle?: ReactChild;
}

interface IState {
  organization?: Organization;
  users?: User[];
}

export class YourOrganizationPage extends Component<IProps, IState> {
  static contextType = LayoutContext;

  constructor(props: IProps) {
    super(props);
    this.state = {};

    this.updatePageHeader = this.updatePageHeader.bind(this);
  }

  componentDidMount() {
    Promise.all([
      new OrganizationsApiService().getById(JwtTokenHelpers.getOrgId()),
      new UsersApiService().listAllByOrg(JwtTokenHelpers.getOrgId()),
    ])
      .then((result) =>
        this.setState({
          organization: result[0],
          users: result[1].items,
        }),
      )
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );

    this.updatePageHeader();
  }

  updatePageHeader() {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.removeAllExtraInfos();
      layoutContext.clearHeaderButtons();
    }
  }

  render() {
    return (
      <div className="max-width-container-xl d-flex flex-column h-100">
        {this.state.organization && (
          <OrganizationDetailsPage
            organization={this.state.organization}
            yourOrgPage={true}
            onOrganizationUpdate={(organization) => this.setState({ organization })}
            users={this.state.users}
          />
        )}
        {!this.state.organization && (
          <div className="mt-3">
            <LoadingSpinner />
          </div>
        )}
      </div>
    );
  }
}
