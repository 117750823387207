import { FC, useEffect, MouseEvent } from "react";
import {
  ResendRequest,
  ConfirmVerificationRequest,
  UsersApiService,
} from "../../services/api/UsersApiService";
import PageLayout from "./components/PageLayout";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

export interface IVerifyPageMatchParams {
  email: string;
  factor: string;
}

type LocationState = {
  email: string;
  factor: string;
};

interface IProps extends RouteComponentProps<IVerifyPageMatchParams> {}

const VerifyAccount: FC<IProps> = () => {
  const history = useHistory(); // resend verification emailcreate-account-box
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (document.body) document.body.className = "modusense login";
  });

  useEffect(() => {
    console.log("initiated delayed interval check");
    intervalCheck();
  }, []);

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const _service = new UsersApiService();
  const intervalCheck = async () => {
    await sleep(5000).then(() => {
      const confirmRequest: ConfirmVerificationRequest = {
        email: location.state.email,
      };
      _service
        .confirmVerification(confirmRequest)
        .then(() => {
          history.push({
            pathname: "/go/home/verifiedSuccessfully",
            state: {
              email: location.state.email,
              factor: location.state.factor,
            },
          });
        })
        .catch(() => {
          intervalCheck();
        });
    });
  };

  const handleResend = (evt: MouseEvent) => {
    evt.preventDefault();

    const resendRequest: ResendRequest = {
      email: location.state.email,
    };
    _service.resend(resendRequest).then(() => {
      $.notification("show", {
        type: "message",
        title: undefined,
        message: "Verification Email for New User Account was successfully resent.",
        toastOnly: true,
      });
    });
  };

  return (
    <PageLayout tagLine="">
      <div className="container-fluid card-outline card p-0">
        <div className="text-center">
          <img
            className="mb-3"
            src="https://c.animaapp.com/fuPDoueX/img/icons-outline-mail-1.svg"
          />
        </div>

        <div className="text-center row">
          <div className="col-xs-12 col-sm-12">
            <p className="mb-3">We’ve sent a verification email to {location.state.email}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <p className="card-body mb-3 white">
              Please check your email and click on the link to verify your account.
            </p>
          </div>
        </div>
        <div className="text-center">
          <p className="mb-3">
            <a
              className="link-center"
              href="#"
              onClick={(evt: MouseEvent<HTMLAnchorElement>) => handleResend(evt)}
            >
              click here to resend
            </a>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export { VerifyAccount };
