import { FC, useEffect, MouseEvent } from "react";
import PageLayout from "./components/PageLayout";
import { useUserLogin } from "../../services/context/AuthenticationApiServiceContext";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

export interface IConfirmPageMatchParams {
  email: string;
  factor: string;
}

type LocationState = {
  email: string;
  factor: string;
};

interface IProps extends RouteComponentProps<IConfirmPageMatchParams> {}

const ConfirmedAccount: FC<IProps> = () => {
  const history = useHistory(); // resend verification emailcreate-account-box
  const location = useLocation<LocationState>();
  const { mutate: doUserLogin } = useUserLogin();
  useEffect(() => {
    if (document.body) document.body.className = "modusense login";
  });

  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    doUserLogin(
      {
        email: location.state.email,
        password: location.state.factor,
      },
      {
        onSuccess: () => {
          history.push("/go/welcome");
        },
        onError: (error) => {
          console.log(error);
          $.notification("show", {
            type: "error",
            title: undefined,
            message: "Login attempt failed - redirecting to login page.",
            toastOnly: true,
          });
          history.push("/go/home/login");
        },
      },
    );
  };
  return (
    <PageLayout tagLine="">
      <div className="container-fluid card-outline card p-0">
        <div className="text-center">
          <img className="mb-3" src="https://c.animaapp.com/t7wsDXRS/img/step-complete.svg" />
        </div>
        <div className="text-center row">
          <div className="col-xs-12 col-sm-12">
            <p className="mb-3">Your email has been successfully verified!</p>
          </div>
        </div>
        <div className="row text-center">
          <p className="col-xs-12 col-sm-12">
            <button
              className="cardbody mb-3 btn btn-fill"
              onClick={(evt) => handleClick(evt)}
              id="dasboard-button"
            >
              Continue to Dashboard
            </button>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export { ConfirmedAccount };
