import { useMutation, useQuery } from "react-query";
import { UsersApiService } from "../api/UsersApiService";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { useAuth } from "../../contexts/auth/auth.context";
import { IMutationOptions } from "./ContextServiceHelper";

const useResetRequired = (email?: string) => {
  return useQuery(
    ["users-reset-required"],
    () => {
      const service = new UsersApiService();
      return service.resetRequired(email!);
    },
    {
      enabled: !!email,
      staleTime: 0,
    },
  );
};

const useMySession = () => {
  return useQuery(
    ["users-reset-required"],
    () => {
      const service = new UsersApiService();
      return service.getCurrentSessionUser();
    },
    {},
  );
};

interface ITemplateMutationParams {
  accountId: string;
  accessGroupsId: string[];
  roleId?: string;
}
const useUpdatePermissions = (mutationOptions: IMutationOptions = {}) => {
  const { setToken } = useAuth();
  return useMutation(
    ({ accountId, accessGroupsId, roleId }: ITemplateMutationParams) => {
      const service = new UsersApiService();
      return service.updatePermissions(accountId, accessGroupsId, roleId);
    },
    {
      onSuccess: (tokenString) => {
        if (tokenString) {
          localStorage.setItem("JWT_TOKEN", tokenString);
          const token = JwtTokenHelpers.getDecodedJwtToken();
          setToken!(token);
        }
        mutationOptions?.onSuccess?.();
      },
      onError: (error) => {
        mutationOptions?.onError?.(error);
      },
    },
  );
};

export { useUpdatePermissions, useResetRequired, useMySession };
