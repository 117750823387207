import { FC, useMemo } from "react";
import "../../../buttons/buttonStyles.scss";
import { AlertNotification } from "./AlertNotificationHelper";
import AlertNotificationCard from "./AlertNotificationCard";
import NoAlertNotification from "./NoAlertNotification";
import { Button, ButtonType } from "../../../Button";
import { useHistory } from "react-router-dom";

type Props = {
  alertNotificationList: AlertNotification[];
  dropdownMenuId?: string;
  dropdownMenuWidth?: string;
};

const ButtonDropdownAlertNotification: FC<Props> = ({
  alertNotificationList,
  dropdownMenuId,
  dropdownMenuWidth,
}) => {
  const history = useHistory();

  const GenerateAlertNotificationList = useMemo(() => {
    if (!!alertNotificationList && alertNotificationList.length > 0) {
      const alertList: JSX.Element[] = alertNotificationList
        .sort((a, b) => (a.deviceAlertInfoId < b.deviceAlertInfoId ? 1 : -1))
        .sort((a, b) => (a.date.valueOf() < b.date.valueOf() ? 1 : -1))
        .map((alertNotification) => (
          <AlertNotificationCard
            key={alertNotification.deviceAlertInfoId.toString()}
            alertNotification={alertNotification}
          />
        ));

      return <div className="button-dropdown-alert-notification-list">{alertList}</div>;
    }

    return (
      <div className="button-dropdown-alert-notification-list">
        <NoAlertNotification />
      </div>
    );
  }, [alertNotificationList]);

  return (
    <section
      id={dropdownMenuId ?? ""}
      className="button-dropdown-alert-notification-container"
      style={{ width: dropdownMenuWidth }}
    >
      {GenerateAlertNotificationList}

      <div className="button-dropdown-alert-notification-buttons">
        <div className="button-dropdown-alert-notification-button">
          <Button
            content="See All"
            buttonType={ButtonType.Transparent}
            rightIconClass="fa-light fa-arrow-right"
            onClick={() => {
              history.push(`/go/manage/alerts`);
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default ButtonDropdownAlertNotification;
