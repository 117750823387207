import { FC, useEffect, useState } from "react";
import * as d3 from "d3";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
//Type of the chart
import * as am5xy from "@amcharts/amcharts5/xy";
import { WaterVolumeTemperatureHistoricalData } from "../../../pages/simpleapp/components/widgets/historic/hooks/useHistoricalWaterVolTemp";
import { MinMaxUnit } from "../../../pages/simpleapp/components/SimpleAppTemplate";
import {
  ColorVariables,
  getChartDateTime,
  getLineColor,
  TemperatureDisplayUnit,
} from "../../../pages/simpleapp/components/widgets/WidgetHelper";

type DataModel = {
  time: string;
  volume: number;
  temperature?: number;
  lineStrokeSetting?: {
    stroke: string;
  };
  lineFillSetting?: {
    fill: string;
  };
};

type Props = {
  data: WaterVolumeTemperatureHistoricalData[];
  volumeLabel: string;
  temperatureLabel: string;
  chartId: string;
};

const AMCWaterVolumeAndTemperature: FC<Props> = ({
  data,
  volumeLabel,
  temperatureLabel,
  chartId,
}) => {
  const [normalizedData, setNormalizedData] = useState<DataModel[]>([]);

  // Default
  const [temperatureData, setTemperatureData] = useState<MinMaxUnit>({
    min: 0,
    max: 75,
    unit: "°C",
  });
  const [volumeData, setVolumeData] = useState<MinMaxUnit>({ min: 0, max: 0, unit: "L" });
  const [hasTemperatureData, setHasTemperatureData] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setVolumeData({
        min: 0,
        max: data[0].volumeData.maxVolume,
        unit: data[0].volumeData.volumeDisplayUnit,
      });
      const dataArr: DataModel[] = [];

      data.map((datum) => {
        let displayUnit = TemperatureDisplayUnit.Undefined;
        if (datum.hasTemperatureData) {
          setHasTemperatureData(true);
          displayUnit = TemperatureDisplayUnit.Celsius;
          switch (datum.temperatureData.temperatureDisplayUnit) {
            case "°C":
              setTemperatureData({
                min: 0,
                max: 75,
                unit: datum.temperatureData.temperatureDisplayUnit,
              });
              displayUnit = TemperatureDisplayUnit.Celsius;
              break;
            case "°F":
              setTemperatureData({
                min: 32,
                max: 167,
                unit: datum.temperatureData.temperatureDisplayUnit,
              });
              displayUnit = TemperatureDisplayUnit.Fahrenheit;
              break;
            case "°K":
              setTemperatureData({
                min: 273,
                max: 348,
                unit: datum.temperatureData.temperatureDisplayUnit,
              });
              displayUnit = TemperatureDisplayUnit.Kelvin;
              break;
          }
        }

        dataArr.push({
          time: getChartDateTime(datum.volumeData.time),
          volume: datum.volumeData.volume,
          temperature: datum.hasTemperatureData ? datum.temperatureData.temperature : undefined,
          lineStrokeSetting:
            datum.hasTemperatureData && displayUnit != TemperatureDisplayUnit.Undefined
              ? { stroke: getLineColor(datum.temperatureData.temperature, displayUnit) }
              : undefined,
          lineFillSetting:
            datum.hasTemperatureData && displayUnit != TemperatureDisplayUnit.Undefined
              ? { fill: getLineColor(datum.temperatureData.temperature, displayUnit) }
              : undefined,
        });
      });

      setNormalizedData(dataArr);
    }
  }, [data]);

  useEffect(() => {
    if (!!chartId && !!normalizedData && normalizedData.length > 0) {
      const root = am5.Root.new(chartId);

      // Set themes (https://www.amcharts.com/docs/v5/concepts/themes/)
      root.setThemes([am5themes_Animated.new(root)]);

      // Create Chart (https://www.amcharts.com/docs/v5/charts/xy-chart/)
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: false,
          wheelY: "zoomX",
          layout: root.verticalLayout,
        }),
      );

      // Add cursor: In here you can configure how the cursor is displayed and how it behaves on the chart.
      const cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        }),
      );
      cursor.lineY.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });
      cursor.lineX.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });

      // Create axes https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      // For the X-Axis
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "time",
          marginTop: 15,
          maxDeviation: 0,
          renderer: am5xy.AxisRendererX.new(root, {}),
        }),
      );
      xAxis.data.setAll(normalizedData);

      //### For the Volume ###//
      //Left Axis
      const leftYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: volumeData.max,
          numberFormat: `#,###'${volumeData.unit}'`,
          // maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );
      leftYAxis.get("renderer").grid.template.set("forceHidden", true);

      //Column Bar - the data
      const columnBarVolume = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: volumeLabel,
          xAxis: xAxis,
          yAxis: leftYAxis,
          valueYField: "volume",
          categoryXField: "time",
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]${volumeLabel}[/]: {valueY}${volumeData.unit} \n\n[bold]DateTime[/]: {categoryX}`,
            dy: -5,
          }),
        }),
      );
      columnBarVolume.columns.template.setAll({
        stroke: am5.color(ColorVariables.colorBlueWaterLight),
        fill: am5.color(ColorVariables.colorBlueWaterLight),
        width: am5.percent(60),
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
      });
      columnBarVolume.data.setAll(normalizedData);
      columnBarVolume.appear(1000);

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      columnBarVolume.set("setStateOnChildren", true);
      columnBarVolume.states.create("hover", {});

      columnBarVolume.mainContainer.set("setStateOnChildren", true);
      columnBarVolume.mainContainer.states.create("hover", {});

      columnBarVolume.columns.template.states.create("hover", {
        stroke: am5.color(ColorVariables.colorBlueWater),
        strokeWidth: 2,
      });

      // The Horizontal line/target/reference

      //### For the Temperature ###//
      const rightYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: temperatureData.min,
          max: temperatureData.max,
          numberFormat: `#'${temperatureData.unit}'`,
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, { opposite: true }),
          visible: hasTemperatureData,
        }),
      );
      rightYAxis.get("renderer").grid.template.set("forceHidden", true);

      const lineAreaTemp = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: temperatureLabel,
          xAxis: xAxis,
          yAxis: rightYAxis,
          valueYField: "temperature",
          categoryXField: "time",
          curveFactory: d3.curveBumpX,
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]${temperatureLabel}[/]: {valueY}${temperatureData.unit} \n\n[bold]DateTime[/]: {categoryX}`,
            dy: -5,
          }),
        }),
      );
      lineAreaTemp.strokes.template.setAll({
        templateField: "lineStrokeSetting",
        strokeWidth: 1,
      });
      lineAreaTemp.fills.template.setAll({
        templateField: "lineFillSetting",
        visible: true,
        opacity: 0.4,
      });
      lineAreaTemp.data.setAll(normalizedData);
      lineAreaTemp.appear(1000);

      lineAreaTemp.events.once("datavalidated", () => {
        if (!hasTemperatureData) lineAreaTemp.get("legendDataItem")?.get("itemContainer").hide();
      });

      // // create hover state for series and for mainContainer, so that when series is hovered,
      // // the state would be passed down to the strokes which are in mainContainer.
      // lineAreaTemp.set("setStateOnChildren", true);
      // lineAreaTemp.states.create("hover", {});
      //
      // lineAreaTemp.mainContainer.set("setStateOnChildren", true);
      // lineAreaTemp.mainContainer.states.create("hover", {});
      //
      // lineAreaTemp.strokes.template.states.create("hover", {
      //    templateField: "lineStrokeSetting",
      //    strokeWidth: 2
      // });
      // lineAreaTemp.fills.template.states.create("hover", {
      //    templateField: "lineFillSetting",
      //    visible: true,
      //    opacity: 0.4,
      // });

      // Add scrollbar
      const scrollbar = chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
          start: 0.8,
          height: 10,
          marginTop: 15,
        }),
      );
      scrollbar.thumb.setAll({
        fill: am5.color(ColorVariables.colorPlaceholder),
      });
      scrollbar.startGrip.setAll({
        visible: false,
      });
      scrollbar.endGrip.setAll({
        visible: false,
      });
      chart.bottomAxesContainer.children.push(scrollbar);

      // Add Legends
      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      );

      // Make series change state when legend item is hovered
      legend.itemContainers.template.states.create("hover", {});

      legend.itemContainers.template.events.on("pointerover", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.hover();
      });
      legend.itemContainers.template.events.on("pointerout", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.unhover();
      });

      legend.data.setAll(chart.series.values);

      // Make stuff animate on load (https://www.amcharts.com/docs/v5/concepts/animations/)
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }
  }, [chartId, normalizedData]);

  return <div className="am-chart-container" id={chartId} />;
};

export default AMCWaterVolumeAndTemperature;
