import { FC, useEffect, useRef, useState } from "react";
import "../header/headerStyles.scss";
import Logout from "./Logout";
import { IconButton } from "../../IconButton";
import { useMySession } from "../../../services/context/UsersApiServiceContext";
import { UrlsAddress } from "../../../helpers/UrlsAddress";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { cfHost } from "../../../config";
import HeaderIconButtonAlert from "./alertNotifications/HeaderIconButtonAlert";
import { ButtonDropdownMenu } from "../../buttons";
import { Guid } from "js-guid";
import ButtonDropdownAlertNotification from "./alertNotifications/ButtonDropdownAlertNotification";
import { useListAlertNotifications } from "../../../services/context/AlertApiServiceContext";
import { AlertNotification, AlertPriority } from "./alertNotifications/AlertNotificationHelper";
import { useAuth } from "../../../contexts/auth/auth.context";

type Props = Record<string, never>;

const HeaderIconButtons: FC<Props> = () => {
  const auth = useAuth();
  const { isSuccess: isMySessionLoaded, data: mySession } = useMySession();
  const {
    isSuccess: alertNotificationsLoaded,
    data: alertNotifications,
    refetch: refetchListAlertNotifications,
  } = useListAlertNotifications();
  const ref: any = useRef();

  const [expandedActionsMenu, setExpandedActionsMenu] = useState(false);
  const [expandedAlertsMenu, setExpandedAlertsMenu] = useState(false);
  const [expandedSettingsMenu, setExpandedSettingsMenu] = useState(false);
  const [supportUrl, setSupportUrl] = useState<string>(UrlsAddress.SupportLink);
  const [alertNotificationList, setAlertNotificationList] = useState<AlertNotification[]>([]);

  useEffect(() => {
    if (isExpanded()) {
      const checkIfClickedOutside = (e: any) => {
        // If the menu is open and the clicked target is not within the menu, then close the menu
        if (ref.current && !ref.current.contains(e.target)) {
          closeAllMenus();
        }
      };

      document.addEventListener("mousedown", checkIfClickedOutside);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }
  }, [expandedSettingsMenu, expandedActionsMenu, expandedAlertsMenu]);

  useEffect(() => {
    const orgTheme = JwtTokenHelpers.getOrgTheme();
    setSupportUrl(orgTheme.supportUrl || UrlsAddress.SupportLink);
  }, [supportUrl]);

  useEffect(() => {
    refetchListAlertNotifications();
  }, [auth.token]);

  useEffect(() => {
    if (alertNotificationsLoaded && !!alertNotifications) {
      const alertNotifs: AlertNotification[] = [];
      alertNotifications.forEach((alert) => {
        alertNotifs.push({
          deviceAlertInfoId: alert.DeviceAlertInfoId,
          deviceId: alert.DeviceId,
          deviceName: alert.DeviceName,
          priority: alert.Priority as AlertPriority,
          date: alert.LastTriggered,
          dateString: alert.LastTriggered.format("Do MMM. YYYY, h:mm a"),
          alertMessage: alert.AlertMessage,
          isSeen: alert.IsSeen,
          isActioned: alert.IsActioned ?? false,
        });
      });

      setAlertNotificationList(alertNotifs);
    }
  }, [alertNotifications]);

  const toggleMenu = (type: string) => {
    switch (type) {
      case "actions":
        setExpandedActionsMenu(!expandedActionsMenu);
        closeAlertsMenu();
        closeSettingsMenu();
        break;
      case "alerts":
        closeActionsMenu();
        closeSettingsMenu();
        if (expandedAlertsMenu) refetchListAlertNotifications();
        setExpandedAlertsMenu(!expandedAlertsMenu);
        break;
      case "settings":
        closeActionsMenu();
        closeAlertsMenu();
        setExpandedSettingsMenu(!expandedSettingsMenu);
        break;
    }
  };

  const isExpanded = () => expandedSettingsMenu || expandedActionsMenu || expandedAlertsMenu;

  const toggleActionsMenu = () => toggleMenu("actions");
  const toggleAlertsMenu = () => toggleMenu("alerts");
  const toggleSettingsMenu = () => toggleMenu("settings");

  const closeActionsMenu = () => setExpandedActionsMenu(false);
  const closeAlertsMenu = () => setExpandedAlertsMenu(false);
  const closeSettingsMenu = () => setExpandedSettingsMenu(false);

  const closeAllMenus = () => {
    closeActionsMenu();
    closeAlertsMenu();
    closeSettingsMenu();
  };

  if (!isMySessionLoaded) return <></>;

  return (
    <>
      <div className="header-icon-buttons-container" ref={ref}>
        <div id="actions-dropdown" className="header-icon-button" onClick={toggleActionsMenu}>
          <IconButton iconClass="fa fa-plus" />
          {expandedActionsMenu && (
            <ButtonDropdownMenu
              dropdownMenuId="actions-dropdown-menu"
              dropdownMenuWidth="200px"
              dropdownMenuList={[
                { listName: "Add Device", onclick: closeAllMenus, linkTo: "/go/devices/claim" },
                { listName: "Create Alert", onclick: closeAllMenus, linkTo: "/go/manage/alerts" },
                {
                  listName: "Invite User",
                  onclick: closeAllMenus,
                  linkTo: "/go/manage/userManagement/users#invite",
                },
                {
                  listName: "Import Data",
                  onclick: closeAllMenus,
                  linkTo: "/go/admin/import/index",
                },
                {
                  listName: "Create Organization",
                  onclick: closeAllMenus,
                  linkTo: "/go/admin/organizations/create",
                },
              ]}
            />
          )}
        </div>

        <div className="header-icon-button" onClick={toggleAlertsMenu}>
          <HeaderIconButtonAlert
            indicatorCount={alertNotificationList.filter((value) => !value.isSeen).length}
          />
          {expandedAlertsMenu && (
            <ButtonDropdownAlertNotification
              alertNotificationList={alertNotificationList}
              dropdownMenuId="alert-dropdown-menu"
            />
          )}
        </div>

        <div className="header-icon-button" onClick={toggleSettingsMenu}>
          {mySession!.profileImagePath ? (
            <img
              id="settings-dropdown"
              src={`${cfHost}${mySession!.profileImagePath}`}
              className="img-circle ml-1"
            />
          ) : (
            <img
              id="settings-dropdown"
              src={`${cfHost}/images/profile-placeholder.png`}
              className="img-circle ml-1"
            />
          )}
          {expandedSettingsMenu && (
            <ButtonDropdownMenu
              dropdownMenuId="settings-dropdown-menu"
              dropdownMenuWidth="200px"
              dropdownMenuList={[
                { listName: "My Profile", onclick: closeAllMenus, linkTo: "/go/user/profile" },
                {
                  listName: "Organization Settings",
                  onclick: closeAllMenus,
                  linkTo: "/go/manage/organization/yourOrganization",
                },
                {
                  listName: "User Management",
                  onclick: closeAllMenus,
                  linkTo: "/go/manage/userManagement/users",
                },
              ]}
              dropdownMenuSpecialListItem={
                <div
                  key={new Guid().toString()}
                  id="logout-wrapper"
                  style={{ width: "100%" }}
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <Logout onClose={() => closeSettingsMenu()} />
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderIconButtons;
