import { FC, createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import {
  BreadcrumbTitle,
  DropdownTitle,
  PageHeaderButton,
  PageTitleType,
} from "../../components/layout/pageHeader/PageHeaderNew";

const InitialState = {
  isMenuCollapsed: false,
};

export type LayoutContextType = {
  isMenuCollapsed: boolean;
  headerButtons?: JSX.Element;
  updatedBackPageTitle?: string;
  updatedBackUrl?: string;
  toggleMenu(): void;
  addHeaderButtons(headerButtons: JSX.Element): void;
  clearHeaderButtons(): void;
  updatePageTitleAndBackLinks(
    updatedPageTitle: string,
    updatedBackPageTitle: string,
    updateBackUrl: string,
  ): void;
  clearPageTitleAndBackLinks(): void;

  updatedPageTitle?: string | JSX.Element;
  updatedPageTitleType?: PageTitleType;
  updatedLastSeen?: string;
  updatedExtraInfo?: JSX.Element;

  updatedDropdownSetting?: DropdownTitle;
  updatedBreadcrumbSetting?: BreadcrumbTitle;
  updatedButtons?: PageHeaderButton[];

  updatePageTitle(updatedPageTitle: string | JSX.Element): void;
  clearPageTitle(): void;
  updateButtons(buttons: PageHeaderButton[]): void;
  removeAllButtons(): void;
  updateExtraInfos(extraInfos: JSX.Element): void;
  removeAllExtraInfos(): void;
  convertTypeToDefault(updatedPageTitle?: string | JSX.Element): void;
  convertTypeToBreadcrumb(
    breadcrumbSetting: BreadcrumbTitle,
    updatedPageTitle?: string | JSX.Element,
  ): void;
  convertTypeToDropDown(
    dropdownSetting: DropdownTitle,
    updatedPageTitle?: string | JSX.Element,
  ): void;
};

type LayoutContextState = {
  isMenuCollapsed: boolean;
  headerButtons?: JSX.Element;
  updatedBackPageTitle?: string;
  updatedBackUrl?: string;

  updatedPageTitle?: string | JSX.Element;
  updatedPageTitleType?: PageTitleType;
  updatedLastSeen?: string;
  updatedExtraInfo?: JSX.Element;

  updatedDropdownSetting?: DropdownTitle;
  updatedBreadcrumbSetting?: BreadcrumbTitle;
  updatedButtons?: PageHeaderButton[];
};

export const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType);

const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<LayoutContextState>(InitialState);

  // FOR THE NEW PAGE HEADER
  const updatePageTitle = (updatedPageTitle: string | JSX.Element) => {
    setState((prevState) => ({
      ...prevState,
      updatedPageTitle,
    }));
  };

  const clearPageTitle = () => {
    setState((prevState) => ({
      ...prevState,
      updatedPageTitle: "",
    }));
  };

  const updateButtons = (buttons: PageHeaderButton[]) => {
    setState((prevState) => ({
      ...prevState,
      updatedButtons: buttons,
    }));
  };

  const removeAllButtons = () => {
    setState((prevState) => ({
      ...prevState,
      updatedButtons: undefined,
    }));
  };

  const updateExtraInfos = (extraInfos: JSX.Element) => {
    setState((prevState) => ({
      ...prevState,
      updatedExtraInfo: extraInfos,
    }));
  };

  const removeAllExtraInfos = () => {
    setState((prevState) => ({
      ...prevState,
      updatedExtraInfo: undefined,
    }));
  };

  const convertTypeToDefault = (updatedPageTitle?: string | JSX.Element) => {
    const pageTitle = state.updatedPageTitle;

    setState((prevState) => ({
      ...prevState,
      updatedPageTitle: updatedPageTitle ?? pageTitle,
      updatedPageTitleType: "Default",
    }));
  };

  const convertTypeToBreadcrumb = (
    breadcrumbSetting: BreadcrumbTitle,
    updatedPageTitle?: string | JSX.Element,
  ) => {
    const pageTitle = state.updatedPageTitle;

    setState((prevState) => ({
      ...prevState,
      updatedPageTitle: updatedPageTitle ?? pageTitle,
      updatedPageTitleType: "Breadcrumb",
      updatedBreadcrumbSetting: breadcrumbSetting,
    }));
  };

  const convertTypeToDropDown = (
    dropdownSetting: DropdownTitle,
    updatedPageTitle?: string | JSX.Element,
  ) => {
    const pageTitle = state.updatedPageTitle;

    setState((prevState) => ({
      ...prevState,
      updatedPageTitle: updatedPageTitle ?? pageTitle,
      updatedPageTitleType: "Dropdown",
      updatedDropdownSetting: dropdownSetting,
    }));
  };

  const toggleMenu = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isMenuCollapsed: !prevState.isMenuCollapsed,
    }));
  }, []);

  //
  const clearHeaderButtons = () => {
    setState((prevState) => ({
      ...prevState,
      headerButtons: undefined,
    }));
  };

  const addHeaderButtons = (headerButtons: JSX.Element) => {
    setState((prevState) => ({
      ...prevState,
      headerButtons,
    }));
  };

  const updatePageTitleAndBackLinks = (
    updatedPageTitle: string | JSX.Element,
    updatedBackPageTitle: string,
    updatedBackUrl: string,
  ) => {
    setState((prevState) => ({
      ...prevState,
      updatedPageTitle,
      updatedBackPageTitle,
      updatedBackUrl,
    }));
  };

  const clearPageTitleAndBackLinks = () => {
    setState((prevState) => ({
      ...prevState,
      updatedPageTitle: "",
      updatedBackPageTitle: "",
      updateBackUrl: "",
    }));
  };

  return (
    <LayoutContext.Provider
      value={{
        isMenuCollapsed: state.isMenuCollapsed,
        headerButtons: state.headerButtons,
        toggleMenu,
        addHeaderButtons,
        clearHeaderButtons,
        updatedBackPageTitle: state.updatedBackPageTitle,
        updatePageTitleAndBackLinks,
        clearPageTitleAndBackLinks,
        updatedBackUrl: state.updatedBackUrl,

        updatedPageTitle: state.updatedPageTitle,
        updatePageTitle,
        clearPageTitle,
        updatedButtons: state.updatedButtons,
        updateButtons,
        removeAllButtons,
        updatedExtraInfo: state.updatedExtraInfo,
        updateExtraInfos,
        removeAllExtraInfos,
        updatedBreadcrumbSetting: state.updatedBreadcrumbSetting,
        updatedDropdownSetting: state.updatedDropdownSetting,
        updatedPageTitleType: state.updatedPageTitleType,
        convertTypeToDefault,
        convertTypeToBreadcrumb,
        convertTypeToDropDown,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

function useLayout(): LayoutContextType {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error("useLayout must be used within an LayoutProvider");
  }

  return context;
}

export { LayoutProvider, useLayout };
