import { FC } from "react";
import "../iconStyles.scss";

type Props = Record<string, never>;

const PressureIcon: FC<Props> = () => {
  return <span className="material-icons mi-pressure-icon">compress</span>;
};

export default PressureIcon;
