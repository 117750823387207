import { FC } from "react";
import "../iconStyles.scss";

type Props = Record<string, never>;

const BellIcon: FC<Props> = () => {
  return <span className="material-symbols-outlined"> notifications </span>;
};

export default BellIcon;
