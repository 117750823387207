import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { User } from "../../models/api/User";
import { UsersApiService } from "../../services/api/UsersApiService";
import { UserProfile } from "./components/UserProfile";
import { UserPersonalInformation } from "./components/UserPersonalInformation";
import { UserPasswordChange } from "./components/UserPasswordChange";
import { UnitPreferences } from "../../components/UnitPreferences";
import { LoadingSkeleton } from "../../components/LoadingSkeleton";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { FormWrapper } from "../../components/forms/FormWrapper";
import PageHeaderNew from "../../components/layout/pageHeader/PageHeaderNew";
import UserPermissions from "./components/UserPermissions";
import { LayoutContext, LayoutContextType } from "../../contexts/layout/layout-context";

export interface IUserEditPageMatchParams {
  userId?: string;
}

export interface IProps extends RouteComponentProps<IUserEditPageMatchParams> {
  userId?: string;
}

interface IState {
  user?: User;
  userId?: string;
}

export class UserEditPage extends Component<IProps, IState> {
  static contextType = LayoutContext;

  constructor(props: IProps) {
    super(props);

    this.state = {
      userId: props.match.params.userId ?? props.userId,
    };
    this.updateAccessGroups = this.updateAccessGroups.bind(this);
  }

  componentDidMount() {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.removeAllButtons();
      layoutContext.removeAllExtraInfos();
    }

    if (!this.state.userId) return;
    new UsersApiService()
      .getById(this.state.userId)
      .then((res) => {
        this.setState({
          user: res,
        });
      })
      .catch(() =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "An error occurred while loading user",
          toastOnly: true,
        }),
      );
  }

  updateAccessGroups() {
    if (!this.state.userId) return;
    new UsersApiService()
      .getById(this.state.userId)
      .then((res) => {
        this.setState({
          user: res,
        });
      })
      .catch(() =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "An error occurred while loading user.",
          toastOnly: true,
        }),
      );
  }

  render() {
    return (
      <div className="max-width-container-xl d-flex flex-column h-100">
        <PageHeaderNew
          pageTitle="User Profile"
          pageTitleType="Breadcrumb"
          breadcrumbSetting={{ backPageTitle: "Users", backUrl: "/go/manage/userManagement/users" }}
        />

        <section className="content">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <FormWrapper>
                {this.state.user && <UserProfile user={this.state.user} />}
                {!this.state.user && <LoadingSkeleton width="100%" height="260px" />}
                <span className="mb-3" />

                {this.state.user && <UserPersonalInformation user={this.state.user} />}
                {!this.state.user && <LoadingSkeleton width="100%" height="230px" />}
                <span className="mb-3" />

                {this.state.user && (
                  <UserPermissions
                    user={this.state.user}
                    updateAccessGroups={this.updateAccessGroups}
                  />
                )}
                {!this.state.user && <LoadingSkeleton width="100%" height="230px" />}
                <span className="mb-3" />

                {this.state.userId == JwtTokenHelpers.getUserId() && (
                  <>
                    {this.state.user && <UserPasswordChange user={this.state.user} />}
                    {!this.state.user && <LoadingSkeleton width="100%" height="230px" />}
                    <span className="mb-3" />

                    {this.state.user && <UnitPreferences onOrgPage={false} />}
                    {!this.state.user && <LoadingSkeleton width="100%" height="400px" />}
                    <span className="mb-3" />
                  </>
                )}
              </FormWrapper>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
