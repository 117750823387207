import { useQuery } from "react-query";
import { DeviceTypesApiService } from "../api/DeviceTypesApiService";

const useDeviceType = (id?: string) => {
  return useQuery(
    ["device-type", id],
    () => {
      const service = new DeviceTypesApiService();
      return service.getById(id!);
    },
    {
      enabled: !!id,
    },
  );
};

const useDeviceTypeList = () => {
  return useQuery(["device-types"], () => {
    const service = new DeviceTypesApiService();
    return service.list();
  });
};

export { useDeviceType, useDeviceTypeList };
