import { Component } from "react";
import { Tabs } from "../../../components/Tabs";
import { YourOrganizationPage } from "./YourOrganizationPage";
import { BrandingPage } from "../../branding/BrandingPage";
import { ApiKeysPage } from "../apiKeys/ApiKeysPage";
import { WebhooksPage } from "../webhooks/WebhooksPage";
import { EventLogPage } from "../eventLog/EventLogPage";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../../models/api/Role";
import { PageHeader } from "../../../components/PageHeader";

interface IProps {}
interface IState {}

export class OrganizationPage extends Component<IProps, IState> {
  getPageTitle() {
    switch (window.location.pathname) {
      case "/go/manage/organization/branding":
        return "Branding";
      case "/go/manage/organization/apiKeys":
        return "API Keys";
      case "/go/manage/organization/webhooks":
        return "Webhooks";
      case "/go/manage/organization/eventLog":
        return "Event Logs";
      default:
        return "Organization Settings";
    }
  }

  render() {
    return (
      <>
        <div className="page-structure-container">
          <section className="page-structure-header content-header">
            <PageHeader pageTitle={this.getPageTitle()} />
          </section>

          <section className="page-structure-tabs">
            <Tabs
              title={""}
              tabs={[
                {
                  path: "/go/manage/organization/yourOrganization",
                  title: "Overview",
                  component: () => <YourOrganizationPage />,
                },
                {
                  path: "/go/manage/organization/branding",
                  title: "Branding",
                  component: () =>
                    JwtTokenHelpers.checkPermissionForRoute(
                      "permissionsBranding",
                      PermissionFlags.Read,
                      <BrandingPage />,
                    ),
                },
                {
                  path: "/go/manage/organization/apiKeys",
                  title: "API Keys",
                  component: () =>
                    JwtTokenHelpers.checkPermissionForRoute(
                      "permissionsApiKey",
                      PermissionFlags.Read,
                      <ApiKeysPage />,
                    ),
                },
                {
                  path: "/go/manage/organization/webhooks",
                  title: "Webhooks",
                  component: () =>
                    JwtTokenHelpers.checkPermissionForRoute(
                      "permissionsWebhooks",
                      PermissionFlags.Read,
                      <WebhooksPage />,
                    ),
                },
                {
                  path: "/go/manage/organization/eventLog",
                  title: "Event Logs",
                  component: () =>
                    JwtTokenHelpers.checkPermissionForRoute(
                      "permissionsEventLog",
                      PermissionFlags.Read,
                      <EventLogPage />,
                    ),
                },
              ]}
            />
          </section>
        </div>
      </>
    );
  }
}
