import { FC } from "react";
import "../iconStyles.scss";

type Props = Record<string, never>;

const GrassIcon: FC<Props> = () => {
  return <span className="material-icons mi-grass-icon">grass</span>;
};

export default GrassIcon;
