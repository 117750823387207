import { Dispatch, SetStateAction } from "react";
import { Tag } from "../../../models/api/Tag";

export type Tag_State = {
  tag?: Tag;

  tagName: string;
  isTagNameValid: boolean;

  tagToDelete: string;
  accessGroupLinkToDelete: string;
  deviceLinkToDelete: string;

  accessGroupsToAdd: string[];
  devicesToAdd: string[];
};

export const INITIAL_TAG_STATE: Tag_State = {
  tagName: "",
  isTagNameValid: true,
  tagToDelete: "",
  accessGroupsToAdd: [],
  accessGroupLinkToDelete: "",
  devicesToAdd: [],
  deviceLinkToDelete: "",
};

export function validateTagName(name: string, setTagNameFunc: Dispatch<SetStateAction<Tag_State>>) {
  const tagName = name.trim();
  setTagNameFunc((prevState) => ({
    ...prevState,
    tagName: tagName,
    isTagNameValid: !!tagName,
  }));
  return !!tagName;
}
