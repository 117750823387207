import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";

import "./checkboxStyles.scss";
import { HintIcon } from "../HintIcon";

type NativeProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type Props = {
  labelsize: "small" | "large";
  text?: string;
  hint?: string;
  noWrapText?: boolean;
  showTextAtTheBeggining?: boolean;
} & NativeProps;

const Checkbox: FC<Props> = ({
  labelsize,
  text,
  hint,
  noWrapText = false,
  showTextAtTheBeggining = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(props.checked);

  const toggleChecked = (e: ChangeEvent<HTMLInputElement> | undefined) => {
    if (props.disabled) return;

    setIsChecked((prev) => !prev);

    // Hack to make trigger event from input and text
    if (props.onChange) {
      props.onChange(e || (undefined as unknown as ChangeEvent<HTMLInputElement>));
    }
  };

  const getTextClass = () => {
    const sizeClass = labelsize === "large" ? "checkbox-text-large" : "checkbox-text-small";
    const noWrapTextClass = noWrapText ? "checkbox-no-wrap-text" : "";
    const marginTextClass = showTextAtTheBeggining
      ? "checkbox-text-at-the-beggining"
      : "checkbox-text-at-the-end";

    return `checkbox-text ${sizeClass} ${noWrapTextClass} ${marginTextClass}`;
  };

  useEffect(() => {
    setIsChecked(() => props.checked);
  }, [props.checked]);

  return (
    <div className="checkbox-container">
      <div className="checkbox-wrapper" data-status={showTextAtTheBeggining ? "show-reverse" : ""}>
        <label className="checkbox-label">
          <input
            {...props}
            aria-label={text || ""}
            type="checkbox"
            checked={isChecked}
            onChange={(e) => toggleChecked(e)}
            className={isChecked ? "checkbox-checked" : ""}
          />
        </label>
        {!!text && (
          <span
            className={getTextClass()}
            onClick={() => toggleChecked(undefined)}
            data-status={props.disabled ? "disabled" : ""}
          >
            {text}
          </span>
        )}
        {/* <HintIcon tooltipText="Global webhooks are applied to messages from all devices" /> */}
        {!!hint && <HintIcon tooltipText={hint} />}
      </div>
    </div>
  );
};

export default Checkbox;
