import { FC, createContext, PropsWithChildren, useContext, useState } from "react";
import { SimpleAppCurrentWidget } from "../../../SimpleAppTemplate";

type WidgetContextInterface = {
  addWidget(measurement: SimpleAppCurrentWidget): void;
  widgets: SimpleAppCurrentWidget[];
};

type WidgetContextState = {
  widgets: SimpleAppCurrentWidget[];
};

const WidgetContextInitialState = {
  widgets: [],
};

const WidgetContext = createContext<WidgetContextInterface>({} as WidgetContextInterface);

const WidgetContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<WidgetContextState>(WidgetContextInitialState);

  const addWidget = (measurement: SimpleAppCurrentWidget) => {
    if (measurement) {
      const widgets = state.widgets;
      widgets.push(measurement);

      setState((prevState) => ({
        ...prevState,
        widgets: widgets,
      }));
    }
  };

  return (
    <WidgetContext.Provider
      value={{
        addWidget,
        widgets: state.widgets,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};

function useWidgetContext(): WidgetContextInterface {
  const context = useContext(WidgetContext);

  if (!context) {
    throw new Error("useWidgetContext must be used within an WidgetContextProvider");
  }

  return context;
}

export { WidgetContextProvider, useWidgetContext };
