import { ChangeEvent, FocusEventHandler, MouseEventHandler } from "react";
import "./inputStyles.scss";
import { Label } from "./Label";

export enum InputType {
  Text,
  Number,
  Password,
  Email,
}

export enum StateType {
  Default,
  Error,
  Disabled,
}

interface IProps {
  idName: string;
  labelString?: string;
  placeholder?: string;
  name?: string;
  isRequired?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
  inputType?: InputType;
  inputState?: StateType;
  inputValue?: string;
  errorMsg?: string;
  isReadOnly?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  onClick?: MouseEventHandler<HTMLInputElement> | undefined;
  step?: string | number | undefined;
  noBorder?: boolean;
}

export function Input(props: IProps) {
  const inputContainerClass = `input-container-flex-column-100`;
  const isDisabled = props.inputState == StateType.Disabled;
  const hasError = props.inputState == StateType.Error;
  const type = generateType(props.inputType);
  const classes = ["input-default"];
  classes.push(generateStateClass(props.inputState));
  if (props.noBorder) classes.push("no-border");

  return (
    <div className={inputContainerClass}>
      {/* Labels for input component are always going to be Standard size */}
      {props.labelString && (
        <Label
          idName={props.idName}
          labelString={props.labelString}
          isRequired={props.isRequired}
          isDisabled={isDisabled}
          hasTooltip={props.hasTooltip}
          tooltipText={props.tooltipText}
        />
      )}

      <input
        className={classes.join(" ")}
        id={props.idName}
        name={props.name}
        type={type}
        disabled={isDisabled}
        placeholder={props.placeholder ?? ""}
        value={props.inputValue}
        required={props.isRequired}
        onChange={props.onChange}
        readOnly={!!props.isReadOnly}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onClick={props.onClick}
        step={props.step}
      />
      {hasError && (
        <p className="fg-color-error text-caption input-error-msg"> {props.errorMsg} </p>
      )}
    </div>
  );
}

function generateStateClass(inputState?: StateType) {
  if (!inputState) inputState = StateType.Default;
  let stateClass: string = "state-";

  switch (inputState) {
    case StateType.Default:
      stateClass += "default";
      break;
    case StateType.Error:
      stateClass += "error";
      break;
    case StateType.Disabled:
      stateClass += "disabled";
      break;
  }

  return stateClass;
}

function generateType(inputType?: InputType) {
  if (!inputType) inputType = InputType.Text;
  let type: string = "";

  switch (inputType) {
    case InputType.Text:
      type = "text";
      break;
    case InputType.Number:
      type = "number";
      break;
    case InputType.Password:
      type = "password";
      break;
    case InputType.Email:
      type = "email";
      break;
  }

  return type;
}
