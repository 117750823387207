import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import {
  ConfigState,
  HistoricalWidgetMeasurementConfig,
  INITIAL_CONFIG_STATE,
} from "../../../SimpleAppTemplate";
import { ChartCard } from "../../../../../../components/cards";
import { ErrorNoData } from "../../index";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import { useHistoricalChartsFilter } from "../../context/historical-charts-filter";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import SupportLink from "../../../../../../helpers/SupportLink";
import useHistoricalWaterVolTemp from "../hooks/useHistoricalWaterVolTemp";
import { Guid } from "js-guid";
import { AMCWaterVolumeAndTemperature } from "../../../../../../components/charts";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  chartLabel: string;
  measurements: HistoricalWidgetMeasurementConfig[];
};

const WaterVolumeAndTemperature: FC<Props> = ({ device, orgId, chartLabel, measurements }) => {
  const icons: { icon: string | JSX.Element; errorIcon: string | JSX.Element } = {
    icon: "",
    errorIcon: "fa-regular fa-chart-column",
  };
  const { duration, resolution, timeLastRefresh, customEndDate } = useHistoricalChartsFilter();
  const [volumeLabel, setVolumeLabel] = useState<string>("Volume");
  const [temperatureLabel, setTemperatureLabel] = useState<string>("Temperature");
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const {
    fetchData,
    data: volumeTemperatureChartData,
    isLoading,
    hasError,
    errorMessage,
  } = useHistoricalWaterVolTemp(device.id);

  useEffect(() => {
    if (!!measurements && measurements.length == 2) {
      // Volume Data - Required
      const volumeName = measurements[0].name;
      const volumeFieldKey = measurements[0].fieldKey;

      // Volume Data - Optional
      const volumeDisplayName = measurements[0].displayName;
      const volumeFieldName = measurements[0].fieldName;
      const volumePortNumber = parseInt(measurements[0].port ?? "0");
      const volumeLabel = measurements[0].label;

      // Temperature Data - Required
      const temperatureName = measurements[1].name;
      const temperatureFieldKey = measurements[1].fieldKey;

      // Temperature Data - Optional
      const temperatureDisplayName = measurements[1].displayName;
      const temperatureFieldName = measurements[1].fieldName;
      const temperaturePortNumber = parseInt(measurements[1].port ?? "0");
      const temperatureLabel = measurements[1].label;

      // Device Data
      const tankArea = device.tankArea;
      const tankDepth = device.tankDepth;

      let hasConfigError =
        !volumeName || !volumeFieldKey || !temperatureName || !temperatureFieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Measurement Name and/or FieldKey configured for this widget",
        });
      }

      hasConfigError = !tankArea || !tankDepth;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Tank Depth and/or Area configured for this device",
        });
      }

      if (volumeLabel) setVolumeLabel(volumeLabel);
      if (temperatureLabel) setTemperatureLabel(temperatureLabel);

      hasConfigError =
        !volumeName ||
        !volumeFieldKey ||
        !temperatureName ||
        !temperatureFieldKey ||
        !tankArea ||
        !tankDepth;
      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            duration,
            resolution,
            customEndDate,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            volumeName,
            volumeFieldKey,
            temperatureName,
            temperatureFieldKey,
            tankArea,
            tankDepth,
            volumeDisplayName,
            volumeFieldName,
            volumePortNumber,
            temperatureDisplayName,
            temperatureFieldName,
            temperaturePortNumber,
            orgId,
          });
        };

        getData();
      }
    } else {
      setConfigError({
        hasConfigError: true,
        errorMsg: "We need 2 Measurement Configurations for this widget",
      });

      if (measurements.length > 2) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "Only 2 Measurement Configurations is needed for this widget",
        });
      }
    }
  }, [timeLastRefresh, orgId]);

  const GetDisplayChart = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="500px" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (volumeTemperatureChartData.hasData) {
      return (
        <AMCWaterVolumeAndTemperature
          data={volumeTemperatureChartData.historicalData}
          volumeLabel={volumeLabel}
          temperatureLabel={temperatureLabel}
          chartId={new Guid().toString()}
        />
      );
    } else {
      if (volumeTemperatureChartData.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${volumeTemperatureChartData.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return (
        <ErrorNoData
          cardCaption={`No data for ${device.name} in this duration.`}
          supportQuestion={
            <>
              <p>
                Click
                <span>&nbsp;</span>
                <span className="text-caption-link">
                  <a
                    href={`/go/admin/devices/${device.id}/details`}
                    className="link"
                    target="_blank"
                  >
                    here
                  </a>
                </span>
                <span>&nbsp;</span>
                to set up the data.
              </p>
              <p>
                Expecting data?
                <span>&nbsp;</span>
                <SupportLink />
              </p>
            </>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }
  }, [volumeTemperatureChartData.historicalData?.length, configError]);

  return (
    <ChartCard
      chartTitle={chartLabel == "" ? "Water Tank Volume x Temperature" : chartLabel}
      showFilters={true}
      chart={<>{GetDisplayChart}</>}
    />
  );
};

export default WaterVolumeAndTemperature;
