import { lazy } from "react";
import { RouteComponentProps } from "react-router-dom";

import { RouteConfig } from "../../AuthenticatedApp";

const GraphingPage = lazy(() =>
  import("./GraphingPage").then(({ GraphingPage }) => ({ default: GraphingPage })),
);

export const GraphingRoutes: RouteConfig[] = [
  {
    path: "/go/graphing",
    title: "Graphing",
    component: (props: RouteComponentProps<any>) => <GraphingPage {...props} />,
  },
];
