import { FC } from "react";
import { LoginPage } from "./pages/home/LoginPage";
import { CreateAccount } from "./pages/home/CreateAccount";
import { VerifyAccount } from "./pages/home/VerifyAccount";
import { ConfirmedAccount } from "./pages/home/ConfirmedAccount";
import ResetPassword from "./pages/home/ResetPassword";
import ResetPasswordRequest from "./pages/home/ResetPasswordRequest";
import {
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

type Props = Record<string, never>;

const UnAuthenticatedApp: FC<Props> = () => {
  return (
    <Router>
      <div className="wrapper d-flex flex-column align-items-center justify-content-center h-100 p-3">
        <Switch>
          <Route path="/go/home/login" render={() => <LoginPage />} />
          <Route path="/go/home/createAccount" render={() => <CreateAccount />} />
          <Route
            path="/go/home/verifyAccount"
            render={(props: RouteComponentProps<any>) => <VerifyAccount {...props} />}
          />
          <Route
            path="/go/home/verifiedSuccessfully"
            render={(props: RouteComponentProps<any>) => <ConfirmedAccount {...props} />}
          />

          <Route path="/go/home/resetPasswordRequest" render={() => <ResetPasswordRequest />} />
          <Route
            path="/go/home/resetPassword/:tokenId"
            render={() => <ResetPassword action="reset" />}
          />
          <Route
            path="/go/home/inviteUser/:tokenId"
            render={() => <ResetPassword action="set" />}
          />
          <Route path="*" render={() => <Redirect to="/go/home/login" />} />
        </Switch>
      </div>
    </Router>
  );
};

export default UnAuthenticatedApp;
