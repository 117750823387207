import { Color, toColor } from "react-color-palette";

export default {
  lightenDarken(color: Color, amt: number): Color {
    return toColor("rgb", {
      r: Math.min(Math.max(color.rgb.r + amt, 0), 255),
      g: Math.min(Math.max(color.rgb.g + amt, 0), 255),
      b: Math.min(Math.max(color.rgb.b + amt, 0), 255),
      a: color.rgb.a,
    });
  },

  getCompanyName(): string {
    const companyNameTag = document.querySelector('meta[name="company-name"]');
    const companyName = companyNameTag?.getAttribute("content") ?? "FreeWave";
    return companyName;
  },
};
