import { FC, useEffect, useState } from "react";

import { PageHeader } from "../../components/PageHeader";
import MapViewCard from "../../components/cards/mapCard/MapViewCard";
import { Card } from "../../components/Card";
import { Accordion, AccordionItem } from "../../components/Accordion";
import { DashboardDevice } from "../../models/api/DashboardDevice";
import BrandingHelpers from "../../helpers/BrandingHelpers";
import { PromptMessage } from "../../models/api/PromptMessage";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { UrlsAddress } from "../../helpers/UrlsAddress";
import { EmailsAddress } from "../../helpers/EmailsAddress";
import { OrganizationTheme } from "../../models/api/OrganizationTheme";
import { DeviceAlert } from "../../models/api/DeviceAlert";
import {
  DevicesBatterySohWarning,
  DevicesInAlert,
  DevicesLostCommunication,
  FavoriteDashboards,
} from "./components";

import "./inertia-fleet-dashboard-styles.scss";

type StateType = {
  dashboardDevices: DashboardDevice[];
  favoriteDevices: DashboardDevice[];
  pageLoadCount: number;
  openBrandingDialog: () => void;
  closeBrandingDialog: () => void;
  ignoreLearnAboutBranding: boolean;

  openPromptMessageDialog: () => void;
  closePromptMessageDialog: () => void;

  promptMessage?: PromptMessage;
  devicesInAlert: DeviceAlert[];
  isLoading: boolean;
  companyName: string;
  theme: OrganizationTheme;
};

import { DeviceOverviewMap } from "../../components/deviceOverviewMap/DeviceOverviewMap";

export const InertiaFleetDashboardMockPage: FC = () => {
  const [state, setState] = useState<StateType>({
    pageLoadCount: Number(localStorage.getItem("welcome-page-load-count") ?? "0"),
    openBrandingDialog: () => {},
    closeBrandingDialog: () => {},
    openPromptMessageDialog: () => {},
    closePromptMessageDialog: () => {},
    ignoreLearnAboutBranding: localStorage.getItem("ignore-learn-about-branding") === "true",
    dashboardDevices: [],
    favoriteDevices: [],
    devicesInAlert: [],
    isLoading: true,
    companyName: BrandingHelpers.getCompanyName(),
    theme: JwtTokenHelpers.getOrgTheme(),
  });

  useEffect(() => {
    Promise.all([
      import("./fakeData/dashboard-devices"),
      import("./fakeData/devices-in-alert"),
    ]).then(([{ DASHBOARD_DEVICES_OBJ }, { DEVICES_IN_ALERT_OBJ }]) => {
      const dashboardDevices = DASHBOARD_DEVICES_OBJ;
      const devicesInAlert = DEVICES_IN_ALERT_OBJ;

      const favoriteDevices = dashboardDevices.filter((dd) => dd.isFavorite);

      setState((prevState) => ({
        ...prevState,
        dashboardDevices,
        favoriteDevices,
        devicesInAlert,
        isLoading: false,
      }));
    });

    return () => {};
  }, []);

  return (
    <div className="max-width-container-xl">
      <div className="content-header">
        <PageHeader pageTitle="Welcome to the Inertia Fleet Dashboard Data Portal" />
      </div>
      <section className="content">
        <div className="inertia-fleet-widgets">
          <div className="inertia-fleet-widget-item favorite">
            <FavoriteDashboards
              // @ts-expect-error
              favoriteDevices={state.favoriteDevices}
              isLoading={state.isLoading}
            />
          </div>
          <div className="inertia-fleet-widget-item">
            <DevicesInAlert
              devicesInAlert={state.devicesInAlert}
              // @ts-expect-error
              dashboardDevices={state.dashboardDevices}
              isLoading={state.isLoading}
            />
          </div>
          <div className="inertia-fleet-widget-item">
            <DevicesLostCommunication
              // @ts-expect-error
              dashboardDevices={state.dashboardDevices}
              isLoading={state.isLoading}
            />
          </div>
          <div className="inertia-fleet-widget-item">
            <DevicesBatterySohWarning
              // @ts-expect-error
              dashboardDevices={state.dashboardDevices}
              isLoading={state.isLoading}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12 mb-3">
            <MapViewCard>
              <DeviceOverviewMap />
            </MapViewCard>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-8 col-xl-8">
            <div className="row mt-2 mb-3" id="tutorials">
              <div className="col-12">
                <h5 className="mb-0">Tutorials</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <Accordion accordionId="tutorial-accordion">
                  <AccordionItem
                    id="basics-tutorial-card"
                    accordionItemId="tutorial-accordion-getting-started"
                    header="Getting Started"
                    className="tutorial-card"
                    details={
                      <>
                        <span className="text-muted text-sm mr-2">15 steps - About 6 min</span>
                        <span className="text-xs mr-2 p-1 bg-gray-light rounded not-completed">
                          <i className="far fa-square ml-1 mr-1" />
                          <span className="mr-1">Not completed</span>
                        </span>

                        <span className="text-xs mr-2 p-1 bg-warning rounded active">
                          <i className="far fa-minus-square ml-1 mr-1" />
                          <span className="mr-1">In Progress</span>
                        </span>

                        <span className="text-xs mr-2 p-1 bg-primary rounded completed">
                          <i className="far fa-check-square ml-1 mr-1" />
                          <span className="mr-1">Completed</span>
                        </span>
                      </>
                    }
                    children={
                      <>
                        <p>First time using the new {state.companyName} portal?</p>
                        <p>
                          Want some clarification on the different areas of the site, and what they
                          are used for?
                        </p>
                        <p>
                          This tutorial will walk you through each section of the site, explaining
                          it's purpose.
                        </p>
                        <button
                          className="btn-type-fill mt-3 start-tutorial"
                          onClick={() => setTimeout(() => startLearnTheBasicsTutorial(), 500)}
                        >
                          Start Tutorial
                        </button>
                      </>
                    }
                  />
                  <AccordionItem
                    id="welcome-page-tutorial-card"
                    accordionItemId="tutorial-accordion-welcome-dashboard"
                    header="The Welcome Dashboard"
                    className="tutorial-card"
                    details={
                      <>
                        <span className="text-muted text-sm mr-2">6 steps - About 3 min</span>

                        <span className="text-xs mr-2 p-1 bg-gray-light rounded not-completed">
                          <i className="far fa-square ml-1 mr-1" />
                          <span className="mr-1">Not completed</span>
                        </span>

                        <span className="text-xs mr-2 p-1 bg-warning rounded active">
                          <i className="far fa-minus-square ml-1 mr-1" />
                          <span className="mr-1">In Progress</span>
                        </span>

                        <span className="text-xs mr-2 p-1 bg-primary rounded completed">
                          <i className="far fa-check-square ml-1 mr-1" />
                          <span className="mr-1">Completed</span>
                        </span>
                      </>
                    }
                    children={
                      <>
                        <p>A quick run-down on the various features of the "Welcome Page".</p>
                        <p>
                          Will explain what each widget on the page is used for, and how to get the
                          most out of the page.
                        </p>
                        <button
                          className="btn-type-fill mt-3 start-tutorial"
                          onClick={() => startWelcomePageTutorial()}
                        >
                          Start Tutorial
                        </button>
                      </>
                    }
                  />
                  <AccordionItem
                    id="learn-about-branding-tutorial-card"
                    accordionItemId="tutorial-accordion-learn-about-branding"
                    header="Learn About Branding"
                    className="tutorial-card"
                    details={
                      <>
                        <span className="text-muted text-sm mr-2">8 steps - About 4 min</span>

                        <span className="text-xs mr-2 p-1 bg-gray-light rounded not-completed">
                          <i className="far fa-square ml-1 mr-1" />
                          <span className="mr-1">Not completed</span>
                        </span>

                        <span className="text-xs mr-2 p-1 bg-warning rounded active">
                          <i className="far fa-minus-square ml-1 mr-1" />
                          <span className="mr-1">In Progress</span>
                        </span>

                        <span className="text-xs mr-2 p-1 bg-primary rounded completed">
                          <i className="far fa-check-square ml-1 mr-1" />
                          <span className="mr-1">Completed</span>
                        </span>
                      </>
                    }
                    children={
                      <>
                        <p>Explains the "Branding" functionality.</p>
                        <p>
                          Branding allows you to customize various aspects of the site, including
                          company name, logo and color.
                        </p>
                        <button
                          className="btn-type-fill mt-3 start-tutorial"
                          onClick={() => setTimeout(() => startLearnAboutBrandingTutorial(), 500)}
                        >
                          Start Tutorial
                        </button>
                      </>
                    }
                  />
                </Accordion>
              </div>
            </div>
          </div>
          <div id="support-area" className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="row mt-2 mb-3">
              <div className="col-12">
                <h5 className="mb-0">Support</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <Card
                  header={`${state.companyName} Support Team`}
                  body={
                    <>
                      <p className="mb-2">
                        Get in touch with the {state.companyName} Support Team, for hands-on help.
                      </p>
                      <p>
                        {" "}
                        <strong>Email:</strong>{" "}
                        {state.theme.supportEmail ?? EmailsAddress.SupportEmail}
                      </p>
                      <p>
                        {" "}
                        <strong>Phone:</strong> {state.theme.supportPh ?? "+1 303 381 9200"}{" "}
                      </p>
                      <span className="flex-grow-1" />
                      <div className="d-flex flex-row mt-3">
                        <a
                          href={
                            "mailto:" + (state.theme.supportEmail ?? EmailsAddress.SupportEmail)
                          }
                          className="btn-type-fill"
                        >
                          Email&nbsp;&nbsp; <i className="fa fa-envelope" />
                        </a>
                        <span className="ml-2" />
                        <a
                          href={"tel:" + (state.theme.supportPh ?? "+13033819200")}
                          className="btn-type-fill"
                        >
                          Phone&nbsp;&nbsp; <i className="fa fa-phone" />
                        </a>
                      </div>
                    </>
                  }
                />
              </div>
              <div className="col-sm-12 col-md-6 col-xl-12 mb-3">
                <Card
                  header="Knowledge Base"
                  body={
                    <>
                      <p className="mb-2">
                        View more tutorials and help content in the {state.companyName} Knowledge
                        Base
                      </p>
                      <span className="flex-grow-1" />
                      <div className="d-flex flex-row mt-3">
                        <a
                          href={state.theme.supportUrl ?? UrlsAddress.SupportLink}
                          target="_blank"
                          className="btn-type-fill"
                        >
                          Knowledge Base&nbsp;&nbsp;
                          <i className="fa fa-external-link-alt" />
                        </a>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
