import { FC, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";

import { Label } from "../../../../../../components/inputs/Label";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";

import "./calendar-filter-styles.scss";

const getFromSpecificDateFormatted = (): string => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = `${new Date().getDate().toString().padStart(2, "0")}`;
  const month = `${months[new Date().getMonth()]}`;
  const year = `${new Date().getFullYear()}`;
  const hours = `${new Date().getHours().toString().padStart(2, "0")}`;
  const minutes = `${new Date().getMinutes().toString().padStart(2, "0")}`;
  const seconds = `${new Date().getSeconds().toString().padStart(2, "0")}`;

  // "M d, Y H:i:S"
  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
};

const InitialState = {
  startDate: new Date(),
  fromSpecificDateFormatted: getFromSpecificDateFormatted(),
  customEndDate: undefined,
};

type HistoricalChartsFilterState = {
  startDate: Date;
  fromSpecificDateFormatted: string;
  customEndDate?: number;
};

type Props = {
  startDate: Date;
  callbackFnCustomEndDateToUpdate(fromSpecificDateFormatted: string, customEndDate?: number): void;
};

const CalendarFilter: FC<Props> = ({ callbackFnCustomEndDateToUpdate }) => {
  const [state, setState] = useState<HistoricalChartsFilterState>(InitialState);
  const refCalendarContainer = useRef<HTMLDivElement>(null);
  // Flatpickr doesn't have a type for TypeScript yet (Date: 2023-02-08)
  const refCalendar = useRef<any>(null);

  const getDifferenceToCurrentDateInMinutes = (date: Date): number | undefined => {
    // hack to avoid getTime is not a function error
    const startDate = new Date(date);
    const endTime = new Date();
    const differenceInMilliseconds = endTime.getTime() - startDate.getTime(); // difference in milliseconds
    return Math.round(differenceInMilliseconds / 60000);
  };

  const handleOnCloseCalendar = (dateStr: any) => {
    setState((prevState) => ({
      ...prevState,
      fromSpecificDateFormatted: dateStr,
    }));

    callbackFnCustomEndDateToUpdate(dateStr, state.customEndDate);
  };

  useOutsideClick(refCalendarContainer, () => {
    if (!!refCalendar.current && !!refCalendar.current.flatpickr.isOpen) {
      refCalendar.current.flatpickr.close();
    }
  });

  return (
    <div className="historical-charts-filter-calendar-conainer" ref={refCalendarContainer}>
      <Label idName="flat-picker" labelString="From" />
      <Flatpickr
        id="historical-charts-filter-calendar"
        ref={refCalendar}
        options={{
          maxDate: new Date().toString(),
          dateFormat: "M d, Y H:i:S",
          enableSeconds: true,
          time_24hr: true,
          static: true,
        }}
        data-enable-time
        value={state.startDate}
        onChange={(date: Date) => {
          setState((prevState) => ({
            ...prevState,
            startDate: date,
            customEndDate: getDifferenceToCurrentDateInMinutes(date),
          }));
        }}
        onClose={(_selectedDates: any, dateStr: any) => {
          handleOnCloseCalendar(dateStr);
        }}
      />
    </div>
  );
};

export default CalendarFilter;
