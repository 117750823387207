import { ChangeEvent, FC, useState } from "react";
import "./deviceOverviewMapStyles.scss";
import { useDebounce } from "../../hooks/Debounce";
import { SortDirection, SortType } from "../../services/api/DashboardApiService";
import { MapDeviceList, OverviewMap } from "./components";
import { InputWithIcon } from "../inputs/InputWithIcon";
import { ReactSelect } from "../inputs/ReactSelect";
import { useAuth } from "../../contexts/auth/auth.context";

const DeviceOverviewMap: FC = () => {
  const { isInertiaOrg } = useAuth();

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [sortType, setSortType] = useState<SortType>(
    isInertiaOrg ? SortType.LastSeen : SortType.Name,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Asc);

  const debouncedFilterValue = useDebounce<string>(filterValue, 500);

  const handleFilterValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const sortTypeOptions = [
    { label: "Name", value: SortType.Name },
    { label: "Last Seen", value: SortType.LastSeen },
  ];

  const sortDirectionOptions = [
    { label: "Ascending", value: SortDirection.Asc },
    { label: "Descending", value: SortDirection.Desc },
  ];

  const handleOnItemClick = (deviceId: string) => {
    setSelectedDeviceId(deviceId);
  };

  return (
    <div className="device-overview-map-container">
      <div className="overview-map-device-list">
        <InputWithIcon
          idName="map-view-filter-value"
          placeholder="Enter text to filter..."
          iconClass="fa-sharp fa-solid fa-magnifying-glass"
          containerClass="device-list-search"
          onChange={handleFilterValueChange}
          inputValue={filterValue}
        />

        <div className="device-list-sort">
          <p className="device-sort-name"> Sort by </p>

          <ReactSelect
            idName="device-sort-by"
            options={sortTypeOptions}
            inputValue={sortTypeOptions.find((sto) => sto.value == sortType)?.value}
            onChange={(option: any) => option && setSortType(option.value)}
          />

          <ReactSelect
            idName="device-sort-direction"
            options={sortDirectionOptions}
            inputValue={sortDirectionOptions.find((sdo) => sdo.value == sortDirection)?.value}
            onChange={(option: any) => !!option && setSortDirection(option.value)}
          />
        </div>

        <MapDeviceList
          filter={debouncedFilterValue}
          sortBy={sortType}
          sortDirection={sortDirection}
          handleOnItemClick={handleOnItemClick}
        />
      </div>

      <div className="overview-map">
        <OverviewMap filter={debouncedFilterValue} selectedDeviceId={selectedDeviceId} />
      </div>
    </div>
  );
};
export { DeviceOverviewMap };
