import { FC, useEffect, useState } from "react";

import DeviceAlertHistoryList from "./components/DeviceAlertHistoryList";
import { AlertApiService } from "../../services/api/AlertApiService";
import PageContainerWrapper from "../../components/layout/layout-wrapper/PageContainerWrapper";
import PageContentWrapper from "../../components/layout/layout-wrapper/PageContentWrapper";
import { Alert } from "../../models/api/Alert";
import { DeviceAlertHistory } from "../../models/api/DeviceAlertHistory";
import { Range } from "react-date-range";
import moment from "moment";
import { useDebounce } from "../../hooks/Debounce";

import "./alerts-feed-styles.scss";

export type AlertHistorySortColumn =
  | "alertId"
  | "alertMessage"
  | "deviceId"
  | "priorityLevel"
  | "startedAt"
  | "stoppedAt";

type AlertHistorySortDirection = "asc" | "desc";

type State = {
  alerts: DeviceAlertHistory[];
  filterAlertsByName: Alert[];
  isLoading: boolean;
  lastLoadedCount?: number;
  page: number;
  searchText: string;
  sortColumn: AlertHistorySortColumn;
  sortDirection: AlertHistorySortDirection;
  dateRange: Range | null;
};

const ROWS_PER_PAGE = 10;

const initialDateRange = {
  startDate: moment().startOf("day").toDate(),
  endDate: moment().toDate(),
  key: "selection",
};

export const AlertsHistoryPage: FC = () => {
  const [state, setState] = useState<State>({
    alerts: [],
    filterAlertsByName: [],
    isLoading: false,
    page: 0,
    searchText: "",
    sortColumn: "startedAt",
    sortDirection: "desc",
    dateRange: null,
  });

  const debouncedSearchText = useDebounce<string>(state.searchText, 500);

  useEffect(() => {
    listAlertsHistory();
  }, [
    state.filterAlertsByName,
    state.page,
    debouncedSearchText,
    state.sortColumn,
    state.sortDirection,
    state.dateRange,
  ]);

  const listAlertsHistory = async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    let alertNameFilter = "";

    if (state.filterAlertsByName.length > 0) {
      alertNameFilter = `'${state.filterAlertsByName.map((a) => a.name).join("', '")}'`;
      alertNameFilter = alertNameFilter.replaceAll("'", "%27").replaceAll(" ", "%20");
    }

    const alerts: DeviceAlertHistory[] = await new AlertApiService().listDeviceAlertHistory({
      startingAt: state.dateRange?.startDate?.toISOString(),
      endingAt: state.dateRange?.endDate?.toISOString(),

      alertNameFilter,
      filter: state.searchText,
      isAsc: state.sortDirection === "asc",
      limit: ROWS_PER_PAGE,
      offset: state.page * ROWS_PER_PAGE,
      orderBy: state.sortColumn,
    });

    setState((prevState) => ({
      ...prevState,
      alerts: state.page ? prevState.alerts.concat(alerts) : alerts,
      isLoading: false,
      lastLoadedCount: alerts.length,
    }));
  };

  const handleNextPage = () => {
    if (state.isLoading || state.lastLoadedCount === 0) return;
    setState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  const handleSort = (sortColumn: AlertHistorySortColumn) => {
    let sortDirection: AlertHistorySortDirection = "asc";

    if (sortColumn === state.sortColumn) {
      sortDirection = state.sortDirection === "asc" ? "desc" : "asc";
    }

    setState((prevState) => ({
      ...prevState,
      alerts: [],
      page: 0,
      sortColumn,
      sortDirection,
    }));
  };

  const handleSearch = (searchText: string) => {
    setState((prevState) => ({
      ...prevState,
      page: 0,
      searchText,
    }));
  };

  const handleFilterAlerts = (alerts: Alert[]): void => {
    setState((prevState) => ({
      ...prevState,
      alerts: [],
      filterAlertsByName: alerts,
      page: 0,
    }));
  };

  const handleSetDateRange = (dateRange: Range | null): void => {
    setState((prevState) => ({
      ...prevState,
      alerts: [],
      dateRange: dateRange,
      page: 0,
    }));
  };

  return (
    <PageContainerWrapper>
      <PageContentWrapper>
        <div className="page-wrapper">
          <div className="title-container">
            <div className="title-main">
              <div className="title-name">Alerts History</div>
            </div>
          </div>

          <div className="table-container">
            <DeviceAlertHistoryList
              isLoading={state.isLoading}
              alerts={state.alerts}
              sortColumn={state.sortColumn}
              sortDirection={state.sortDirection}
              searchText={state.searchText}
              handleNextPage={handleNextPage}
              handleSearch={handleSearch}
              handleSort={handleSort}
              handleFilterAlerts={handleFilterAlerts}
              handleSetDateRange={handleSetDateRange}
              initialDateRange={initialDateRange}
            />
          </div>
        </div>
      </PageContentWrapper>
    </PageContainerWrapper>
  );
};
