import { FocusEventHandler, ReactNode } from "react";
import Select, {
  StylesConfig,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  MultiValue,
  SingleValue,
  ActionMeta,
  components,
} from "react-select";
import "./inputStyles.scss";
import variables from "../../scss/variables.module.scss";
import { Label } from "./Label";
import CreatableSelect from "react-select/creatable";

export enum StateType {
  Default,
  Error,
  Disabled,
}

type ReactSelectOpt = {
  label: ReactNode;
  value: any;
};

interface IProps {
  idName: string;
  forPageTitle?: boolean;
  isRequired?: boolean;
  labelString?: string;
  hasTooltip?: boolean;
  tooltipText?: string;

  placeholder?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isCreatable?: boolean;
  inputState?: StateType;
  inputValue?: any[] | any;
  errorMsg?: string;
  menuPlacement?: "bottom" | "auto" | "top";
  options: ReactSelectOpt[] | undefined;
  filterOpt?: (opt: ReactSelectOpt, inputValue: string) => boolean;
  onChange: (
    options: MultiValue<object> | SingleValue<object>,
    actionMeta: ActionMeta<object>,
  ) => void;
  onCreateOption?: (inputValue: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  isOptionDisabled?: (opt: ReactSelectOpt) => boolean;
}

export function ReactSelect(props: IProps) {
  const isDisabled: boolean = props.inputState == StateType.Disabled;
  const isError: boolean = props.inputState == StateType.Error;

  const classes: string[] = [];
  props.forPageTitle ? classes.push("page-title-dropdown") : classes.push("text-para-default");
  const menuPlacement = props.menuPlacement ?? "bottom";
  let defaultInput: any = props.isMulti
    ? props.options?.filter((opt) => props.inputValue?.includes(opt.value))
    : props.options?.find((opt) => opt.value == props.inputValue);

  if (defaultInput === undefined) defaultInput = null;

  // Default Components are replaced to match the "fontawesome" logo/icon design
  const ClearIndicator = (props: ClearIndicatorProps<ReactSelectOpt>) => {
    return (
      <components.ClearIndicator {...props}>
        <i className="fa-sharp fa-solid fa-circle-xmark" />
      </components.ClearIndicator>
    );
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<ReactSelectOpt>) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="fa-sharp fa-solid fa-chevron-down" />
      </components.DropdownIndicator>
    );
  };

  // Default Component Label has been replaced so that we can add classname to match the design easily with the pill component
  // Pill Component is not used in this scenario so that we can use the built in library X-Button on Multi-Select
  const MultiValueLabel = (props: MultiValueGenericProps<ReactSelectOpt>) => {
    return (
      <components.MultiValueLabel {...props}>
        <div className="text-caption-bold">{props.data.label}</div>
      </components.MultiValueLabel>
    );
  };

  const MultiValueRemove = (props: MultiValueRemoveProps<ReactSelectOpt>) => {
    return (
      <components.MultiValueRemove {...props}>
        <i className="fa-sharp fa-solid fa-circle-xmark" />
      </components.MultiValueRemove>
    );
  };

  // Paddings and Margins are styled around these keys to match the design.
  // Some styles are separated into different places to precisely match it.
  //      i.e padding of each pills in figma is padding: 8.5px 15px, to match it here, valueContainer has padding: '0px 15px' and multiValue has margin: "0", marginRight: "5px",
  // Some styles are not exactly the same px to match the same intention
  //      i.e padding of each pills in figma has padding top and bottom of 8.5px to make it centred vertically, but in here, it uses the default to make it centered vertically,
  // These styles have been discussed and approved by the UX Designer
  const reactSelectStyle: StylesConfig<ReactSelectOpt> = {
    // Style for the dropdown container (the outermost container)
    control: (styles) => ({
      display: "flex",

      gap: "10px",
      padding: "0",

      width: "100%",
      minHeight: "50px",

      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "3px",
      borderColor: props.forPageTitle
        ? "transparent"
        : isDisabled
          ? variables.ColorDisabled
          : isError
            ? variables.ColorError
            : variables.ColorBorder,
      color: props.forPageTitle
        ? variables.ColorWhite
        : isDisabled
          ? variables.ColorPlaceholder
          : variables.ColorBlackDark,
      backgroundColor: props.forPageTitle ? "transparent" : variables.ColorWhite,

      ":focus-within": {
        ...styles[":focus-within"],
        borderColor: isError ? variables.ColorError : variables.ColorPrimaryTheme,
      },

      ":hover": {
        backgroundColor: props.forPageTitle ? variables.ColorHoverColor : variables.ColorWhite,
        borderColor: isError ? variables.ColorError : variables.ColorPrimaryTheme,
      },
    }),
    // Styles for the value container (it holds the value of the ReactSelect) (the second-outer most container)
    valueContainer: (styles) => ({
      ...styles,
      padding: props.forPageTitle ? "0 15px 0 10px" : "0 15px",
    }),
    // Style for the <input> when a react-select isSearchable.
    input: (styles) => ({
      ...styles,
      margin: "0",
      padding: props.forPageTitle ? "0" : "9px 0px",
      color: props.forPageTitle
        ? variables.ColorWhite
        : isDisabled
          ? variables.ColorPlaceholder
          : variables.ColorBlackDark,
    }),
    placeholder: (styles) => ({
      ...styles,
      padding: props.forPageTitle ? "0" : "9px 0px",
      color: props.forPageTitle ? variables.ColorWhite : variables.ColorPlaceholder,
    }),
    // Styles for the single value (this is inside valueContainer and only used of isMulti is false)
    singleValue: (style) => ({
      ...style,
      padding: "9px 0px",
      color: props.forPageTitle
        ? variables.ColorWhite
        : isDisabled
          ? variables.ColorPlaceholder
          : variables.ColorBlackDark,
    }),
    // Styles for the container of each multi value (this is inside valueContainer and only used of isMulti is true)
    multiValue: (style) => ({
      ...style,
      margin: "5px",
      borderWidth: "0px",
      borderRadius: "100px",
      backgroundColor: variables.ColorBorder,
    }),
    // Styles for the value of the multiValue
    multiValueLabel: (_style, { isDisabled }) => ({
      padding: "6px",
      paddingLeft: "10px",

      color: isDisabled ? variables.ColorPlaceholder : variables.ColorBlackMid,
    }),
    // Styles for the X-Button of the multiValue
    multiValueRemove: () => ({
      fontSize: "11px",
      padding: "6px 10px 6px 0px",

      color: variables.ColorPlaceholder,

      ":hover": {
        color: variables.ColorBlackMid,
      },
    }),
    // Styles for the container of all the options
    menu: (style) => ({
      ...style,
      margin: "5px 0",
      width: props.forPageTitle ? "fit-content" : "100%",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: variables.ColorBorder,
      borderRadius: "0 0 3px 3px",
    }),
    // Styles of each option
    option: (style, { isFocused, isSelected, isDisabled }) => ({
      ...style,
      padding: "10px 200px 10px 15px",
      margin: "1px 0",
      whiteSpace: "nowrap",

      // @extend .text-sub-nav
      fontWeight: props.forPageTitle ? "400" : "inherit",
      fontSize: props.forPageTitle ? "14px" : "inherit",
      cursor: props.forPageTitle ? "cursor" : "default",

      color: isDisabled ? variables.ColorDisabled : variables.ColorBlackMid,
      borderWidth: isDisabled ? "1px 0" : "none",
      borderStyle: isDisabled ? "solid" : "none",
      borderColor: isDisabled ? variables.ColorDisabled : "none",
      backgroundColor: isSelected
        ? variables.ColorPrimaryThemeTransparent
        : isFocused
          ? variables.ColorHoverWhite
          : variables.ColorWhite,
    }),
    // Styles for the indicator/button separator
    indicatorSeparator: () => ({
      display: "none",
    }),
    // Styles for the clear all button
    clearIndicator: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "12px",
      color: variables.ColorDisabled,

      ":hover": {
        color: variables.ColorBlackMid,
      },
    }),
    // Style for the dropdown arrow button
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: "10px 15px 13px 0",
      fontSize: props.forPageTitle ? "13px" : "9px",
      color: props.forPageTitle
        ? variables.ColorWhite
        : isDisabled
          ? variables.ColorPlaceholder
          : variables.ColorBlackMid,

      ":hover": {
        color: props.forPageTitle ? variables.ColorWhite : variables.ColorBlackMid,
      },
    }),
  };

  // Labels for input component are always going to be Standard size
  const _label = props.labelString ? (
    <Label
      idName={props.idName}
      labelString={props.labelString}
      isRequired={props.isRequired}
      isDisabled={isDisabled}
      hasTooltip={props.hasTooltip}
      tooltipText={props.tooltipText}
    />
  ) : (
    <></>
  );

  // isRequired and onChange is not yet set up
  const _select = (
    <Select
      styles={reactSelectStyle}
      components={{
        ClearIndicator,
        DropdownIndicator,
        MultiValueLabel,
        MultiValueRemove,
      }}
      className={classes.join(" ")}
      id={props.idName}
      placeholder={props.placeholder}
      options={props.options}
      filterOption={props.filterOpt}
      menuPlacement={menuPlacement}
      value={defaultInput}
      isDisabled={isDisabled}
      isClearable={props.isClearable}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      onChange={props.onChange}
      onBlur={props.onBlur}
      isOptionDisabled={props.isOptionDisabled}
    />
  );

  const _creatableSelect = (
    <CreatableSelect
      styles={reactSelectStyle}
      components={{
        ClearIndicator,
        DropdownIndicator,
        MultiValueLabel,
        MultiValueRemove,
      }}
      className={classes.join(" ")}
      id={props.idName}
      placeholder={props.placeholder}
      options={props.options}
      menuPlacement={menuPlacement}
      value={defaultInput}
      isDisabled={isDisabled}
      isClearable={props.isClearable}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      onChange={props.onChange}
      onCreateOption={props.onCreateOption}
      onBlur={props.onBlur}
    />
  );

  const _errorMsg = isError ? (
    <p className="fg-color-error text-caption input-error-msg">{props.errorMsg}</p>
  ) : (
    <></>
  );

  function generateSelect(isCreatable: boolean) {
    if (isCreatable) {
      return _creatableSelect;
    } else {
      return _select;
    }
  }

  return (
    <div style={{ width: "100%", flex: "1" }}>
      {_label}
      {generateSelect(props.isCreatable ?? false)}
      {_errorMsg}
    </div>
  );
}
