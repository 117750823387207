import "./tableFilterStyles.scss";
import { ColumnDefinition } from "./FilterTable";
import { HintIcon } from "../HintIcon";

interface IProps<T> {
  columnDefinition: ColumnDefinition<T>;
  columnNum: number;

  isVirtualize: boolean;

  getSortIconClass: (colDef: ColumnDefinition<T>) => string;
  handleSort: (def: ColumnDefinition<T>, index: number) => void;

  // I have remove the compact as I don't think it matches Emma's Design.
  // isCompact: boolean | undefined,
  // getSortIconClass: (colDef: ColumnDefinition<T>, isCompact?: boolean) => string
}

export function TableTH<T>(props: IProps<T>) {
  const thDiv = (
    <>
      <div
        onClick={() => props.handleSort(props.columnDefinition, props.columnNum)}
        className={
          "tf-th-table-column-title " + (props.columnDefinition.sortable ? "tf-sortable" : "")
        }
      >
        {/* The Column Label/Title */}
        <span>
          {props.columnDefinition.label ?? props.columnDefinition.header}

          {/* The tooltip button if it has one */}
          {props.columnDefinition.hasTooltip && (
            <HintIcon tooltipText={props.columnDefinition.tooltipText ?? ""} />
          )}
        </span>

        {/* The sort button if it is sortable */}
        {props.columnDefinition.sortable && (
          <>
            <i className={props.getSortIconClass(props.columnDefinition)} />
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      {/* Row Title */}
      {props.isVirtualize ? (
        <div className={"tf-th " + (props.columnNum == 0 ? "tf-th-buttons" : "")}> {thDiv} </div>
      ) : (
        <th> {thDiv} </th>
      )}
    </>
  );
}
