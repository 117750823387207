import { Component } from "react";
import { Property } from "csstype";

interface IProps {
  className?: string;
  width?: Property.Width;
  height?: Property.Height;
  aspectRatio?: Property.AspectRatio;
  noRoundedCorners?: boolean;
}
interface IState {}
export class LoadingSkeleton extends Component<IProps, IState> {
  render() {
    return (
      <div
        className={`loading-skeleton ${this.props.className} ${
          this.props.noRoundedCorners ? "no-rounding" : ""
        }`}
        style={{
          width: this.props.width,
          height: this.props.height,
          aspectRatio: this.props.aspectRatio,
        }}
      />
    );
  }
}
