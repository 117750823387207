import { FC, PropsWithChildren } from "react";

const PageContentWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="page-structure-content">
      <div className="page-content-container">{children}</div>
    </div>
  );
};

export default PageContentWrapper;
