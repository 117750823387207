import { RouteComponentProps } from "react-router-dom";
import { lazy } from "react";
import { RouteConfig } from "../../AuthenticatedApp";
import EditTemplatePage from "./templates/EditTemplatePage";
import { InertiaFleetDashboardMockPage } from "../inertia-fleet-dashboard/InertiaFleetDashboardMockPage";

const SatellitePage = lazy(() =>
  import("./satellite/SatellitePage").then(({ SatellitePage }) => ({
    default: SatellitePage,
  })),
);
const IngestMappingsPage = lazy(() =>
  import("./ingestMapping/IngestMappingPage").then(({ IngestMappingsPage }) => ({
    default: IngestMappingsPage,
  })),
);
const ProductTypesPage = lazy(() =>
  import("./productTypes/ProductTypesPage").then(({ ProductTypesPage }) => ({
    default: ProductTypesPage,
  })),
);
const ReleasesPage = lazy(() =>
  import("./releases/ReleasesPage").then(({ ReleasesPage }) => ({
    default: ReleasesPage,
  })),
);
const DeviceTypesPage = lazy(() =>
  import("./deviceTypes/DeviceTypesPage").then(({ DeviceTypesPage }) => ({
    default: DeviceTypesPage,
  })),
);
const EditDeviceTypePage = lazy(() =>
  import("./deviceTypes/EditDeviceTypePage").then(({ EditDeviceTypePage }) => ({
    default: EditDeviceTypePage,
  })),
);
const MeasurementsPage = lazy(() =>
  import("./measurements/MeasurementsPage").then(({ MeasurementsPage }) => ({
    default: MeasurementsPage,
  })),
);
const EditMeasurementPage = lazy(() =>
  import("./measurements/EditMeasurementPage").then(({ EditMeasurementPage }) => ({
    default: EditMeasurementPage,
  })),
);

const RainHistoryPage = lazy(() =>
  import("./rainHistory/RainHistoryPage").then(({ RainHistoryPage }) => ({
    default: RainHistoryPage,
  })),
);

const DashboardApiTestPage = lazy(() =>
  import("./dashboardApiTest/DashboardApiTestPage").then(({ DashboardApiTestPage }) => ({
    default: DashboardApiTestPage,
  })),
);

const ScssClassLibraryPage = lazy(() =>
  import("./scssClassLibrary/ScssClassLibraryPage").then(({ ScssClassLibraryPage }) => ({
    default: ScssClassLibraryPage,
  })),
);

const ReactComponentLibraryPage = lazy(() =>
  import("./reactComponentLibrary/ReactComponentLibraryPage").then(
    ({ ReactComponentLibraryPage }) => ({
      default: ReactComponentLibraryPage,
    }),
  ),
);

const CardLibraryPage = lazy(() =>
  import("./cardLibrary/CardLibraryPage").then(({ CardLibraryPage }) => ({
    default: CardLibraryPage,
  })),
);

const WidgetLibraryPage = lazy(() =>
  import("./widgetLibrary/WidgetLibraryPage").then(({ WidgetLibraryPage }) => ({
    default: WidgetLibraryPage,
  })),
);

const TemplatesPage = lazy(() =>
  import("./templates/TemplatesPage").then(({ TemplatesPage }) => ({
    default: TemplatesPage,
  })),
);

export const InhouseRoutes: RouteConfig[] = [
  {
    path: "/go/inhouse/deviceTypes/:deviceTypeId",
    title: "Edit Device Type",
    component: (props: RouteComponentProps<any>) => <EditDeviceTypePage {...props} />,
  },
  {
    path: "/go/inhouse/deviceTypes",
    title: "Device Types",
    component: () => <DeviceTypesPage />,
  },
  {
    path: "/go/inhouse/templates/add",
    title: "Add Template",
    component: () => <EditTemplatePage />,
  },
  {
    path: "/go/inhouse/templates/:templateId",
    title: "Edit Template",
    component: () => <EditTemplatePage />,
  },
  {
    path: "/go/inhouse/templates",
    title: "Templates",
    component: () => <TemplatesPage />,
  },
  {
    path: "/go/inhouse/releases",
    title: "Releases",
    component: () => <ReleasesPage />,
  },
  {
    path: "/go/inhouse/measurements/:measurementId",
    title: "Edit Measurement",
    component: (props: RouteComponentProps<any>) => <EditMeasurementPage {...props} />,
  },
  {
    path: "/go/inhouse/measurements",
    title: "Measurements",
    component: () => <MeasurementsPage />,
  },
  {
    path: "/go/inhouse/productTypes",
    component: () => <ProductTypesPage />,
  },
  {
    path: "/go/inhouse/ingestMapping",
    component: () => <IngestMappingsPage />,
  },
  {
    path: "/go/inhouse/satellite",
    component: () => <SatellitePage />,
  },
  {
    path: "/go/inhouse/rain-history/:deviceId",
    title: "Rain History",
    component: (props: RouteComponentProps<any>) => <RainHistoryPage {...props} />,
  },
  {
    path: "/go/inhouse/inertia-fleet-dashboard-mock",
    title: "Inertia Fleet Dashboard Mock",
    component: () => <InertiaFleetDashboardMockPage />,
  },
  {
    path: "/go/inhouse/dashboard-api-test",
    title: "Dashboard API (C#) Test",
    component: () => <DashboardApiTestPage />,
  },
  {
    path: "/go/inhouse/scssClassLibrary",
    title: "SCSS Class Library",
    component: () => <ScssClassLibraryPage />,
  },
  {
    path: "/go/inhouse/reactComponentLibrary",
    title: "React Component Library",
    component: () => <ReactComponentLibraryPage />,
  },
  {
    path: "/go/inhouse/cardLibrary",
    title: "Card and FC Library",
    component: () => <CardLibraryPage />,
  },
  {
    path: "/go/inhouse/widgetLibrary",
    title: "Widget Library",
    component: () => <WidgetLibraryPage />,
  },
];
