import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import {
  ConfigState,
  HistoricalWidgetMeasurementConfig,
  INITIAL_CONFIG_STATE,
} from "../../../SimpleAppTemplate";
import { ChartCard } from "../../../../../../components/cards";
import { ErrorNoData } from "../../index";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import { useHistoricalChartsFilter } from "../../context/historical-charts-filter";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import { AMCColumnLineMulti } from "../../../../../../components/charts";
import useHistoricalColumnLineMulti from "../hooks/useHistoricalColumnLineMulti";
import { Guid } from "js-guid";
import SupportLink from "../../../../../../helpers/SupportLink";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  chartLabel: string;
  measurements: HistoricalWidgetMeasurementConfig[];
};

const ColumnLineMulti: FC<Props> = ({ device, orgId, chartLabel, measurements }) => {
  const icons: { icon: string | JSX.Element; errorIcon: string | JSX.Element } = {
    icon: "",
    errorIcon: "fa-regular fa-chart-column",
  };
  const { duration, resolution, timeLastRefresh, customEndDate } = useHistoricalChartsFilter();
  const [lineLabel, setLineLabel] = useState<string>("Line Series");
  const [columnLabel, setColumnLabel] = useState<string>("Column Series");
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const {
    fetchData,
    data: historicalChartData,
    isLoading,
    hasError,
    errorMessage,
  } = useHistoricalColumnLineMulti(device.id);

  useEffect(() => {
    if (!!measurements && measurements.length == 2) {
      // Column Data - Required
      const columnName = measurements[0].name;
      const columnFieldKey = measurements[0].fieldKey;

      // Column Data - Optional
      const columnDisplayName = measurements[0].displayName;
      const columnFieldName = measurements[0].fieldName;
      const columnPortNumber = parseInt(measurements[0].port ?? "0");
      const columnLabel = measurements[0].label;

      // Line Data - Required
      const lineName = measurements[1].name;
      const lineFieldKey = measurements[1].fieldKey;

      // Line Data - Optional
      const lineDisplayName = measurements[1].displayName;
      const lineFieldName = measurements[1].fieldName;
      const linePortNumber = parseInt(measurements[1].port ?? "0");
      const lineLabel = measurements[1].label;

      const hasConfigError = !columnName || !columnFieldKey || !lineName || !lineFieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Measurement Name and/or FieldKey configured for this widget",
        });
      }

      if (lineLabel) setLineLabel(lineLabel);
      if (columnLabel) setColumnLabel(columnLabel);

      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            duration,
            resolution,
            customEndDate,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            columnName,
            columnFieldKey,
            lineName,
            lineFieldKey,
            columnDisplayName,
            columnFieldName,
            columnPortNumber,
            lineDisplayName,
            lineFieldName,
            linePortNumber,
            orgId,
          });
        };

        getData();
      }
    } else {
      setConfigError({
        hasConfigError: true,
        errorMsg: "We need 2 Measurement Configurations for this widget",
      });

      if (measurements.length > 2) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "Only 2 Measurement Configurations is needed for this widget",
        });
      }
    }
  }, [timeLastRefresh, orgId]);

  const GetDisplayChart = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="500px" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (historicalChartData.hasData) {
      return (
        <AMCColumnLineMulti
          data={historicalChartData.historicalData}
          columnLabel={columnLabel}
          lineLabel={lineLabel}
          chartId={new Guid().toString()}
        />
      );
    } else {
      if (historicalChartData.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${historicalChartData.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return (
        <ErrorNoData
          cardCaption={`No data for ${device.name} in this duration.`}
          supportQuestion={
            <p>
              Expecting data?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }
  }, [historicalChartData.historicalData?.length, configError]);

  return (
    <ChartCard
      chartTitle={chartLabel == "" ? "Column x Line" : chartLabel}
      showFilters={true}
      chart={<>{GetDisplayChart}</>}
    />
  );
};

export default ColumnLineMulti;
