import { Measurement } from "../models/api/Measurement";
import { MeasurementField } from "../models/api/MeasurementField";
import { ReactChild } from "react";
import { HistoricalMeasurementDataResponse } from "../models/api/MeasurementDataResponses";
import { UnitPreference } from "../models/api/UnitPreference";
import JwtTokenHelpers from "./JwtTokenHelpers";
import { DashboardDevice, MeasurementName } from "../models/api/DashboardDevice";
import { MeasurementFieldDisplayUnit } from "../models/api/MeasurementFieldDisplayUnit";

const InfluxMeasurementValues = ["battery", "charge", "charge-circuit", "humidity"] as const;
const InfluxMeasurementsObj = InfluxMeasurementValues.reduce(
  (acc, next) => ({ ...acc, [next]: null }),
  {} as Record<string, null>,
);
export type InfluxDbMeasurement = (typeof InfluxMeasurementValues)[number];

export default {
  generateMeasurementLabel(
    measurement: Measurement,
    unitPreferences: UnitPreference[],
  ): ReactChild {
    if (measurement.fields && measurement.fields.length == 1) {
      return this.generateFieldLabel(measurement.fields[0], unitPreferences);
    } else {
      return measurement.displayName ?? "";
    }
  },

  generateFieldLabel(field: MeasurementField, unitPreferences: UnitPreference[]): ReactChild {
    const displayUnitId = this.getPreferedDisplayUnitId(unitPreferences, field);
    const displayUnit = field.displayUnits.find((du) => du.id == displayUnitId);
    return (
      <span>
        <span>{field.displayName}</span>
        &nbsp;
        {displayUnit?.displayUnit && (
          <span className="text-muted">({displayUnit?.displayUnit})</span>
        )}
      </span>
    );
  },

  generateFieldLabelPlainText(field: MeasurementField, unitPreferences: UnitPreference[]): string {
    const displayUnitId = this.getPreferedDisplayUnitId(unitPreferences, field);
    const displayUnit = field.displayUnits.find((du) => du.id == displayUnitId);

    if (displayUnit) {
      return `${field.displayName} (${displayUnit.displayUnit})`;
    }

    return field.displayName!;
  },

  validateLatLng(lat?: number, lng?: number, accuracy?: number): { lat?: number; lng?: number } {
    if (!lat || !lng || (accuracy && accuracy > 2000)) return { lat: undefined, lng: undefined };
    return { lat: lat, lng: lng };
  },

  validateValue(value: any, field: MeasurementField): boolean {
    switch (field.dataType) {
      case "integer": {
        const iValue = parseInt(value);
        return (
          (!field.minimum || field.minimum < iValue) && (!field.maximum || iValue < field.maximum)
        );
      }
      case "float": {
        const fValue = parseFloat(value);
        return (
          (!field.minimum || field.minimum < fValue) && (!field.maximum || fValue < field.maximum)
        );
      }
      case "string":
      case "boolean":
        return true;
    }
  },

  formatValue(
    value: any,
    field: MeasurementField,
    displayUnit?: MeasurementFieldDisplayUnit,
  ): string {
    switch (field.dataType) {
      case "integer":
        value = parseFloat(value).toFixed(displayUnit?.decimalPlaces ?? 0);
        return `${value}${displayUnit?.displayUnit ?? ""}`;
      case "float":
        value = parseFloat(parseFloat(value).toFixed(displayUnit?.decimalPlaces ?? 2));
        return `${value}${displayUnit?.displayUnit ?? ""}`;
      case "string":
        return value as string;
      case "boolean":
        return (value as boolean) ? "True" : "False";
    }
  },

  getUserUnitPreference(
    unitPreferences: UnitPreference[],
    measurementField: MeasurementField,
  ): UnitPreference | undefined {
    const relevantUnitPreferences = unitPreferences.filter(
      (up) => up.measurementFieldId == measurementField.id,
    );
    return relevantUnitPreferences?.find(
      (up) => up.userId == JwtTokenHelpers.getUserId() && !up.orgId,
    );
  },

  getOrgUnitPreference(
    unitPreferences: UnitPreference[],
    measurementField: MeasurementField,
  ): UnitPreference | undefined {
    const relevantUnitPreferences = unitPreferences.filter(
      (up) => up.measurementFieldId == measurementField.id,
    );
    return relevantUnitPreferences?.find(
      (up) => up.orgId == JwtTokenHelpers.getOrgId() && !up.userId,
    );
  },

  getPreferedDisplayUnit(
    unitPreferences: UnitPreference[],
    measurementField: MeasurementField,
    orgOnly?: boolean,
  ): MeasurementFieldDisplayUnit | undefined {
    const userUnitPreference = this.getUserUnitPreference(unitPreferences, measurementField);
    const orgUnitPreference = this.getOrgUnitPreference(unitPreferences, measurementField);

    if (userUnitPreference && !orgOnly) {
      return measurementField.displayUnits.find((du) => du.id == userUnitPreference!.displayUnitId);
    } else if (orgUnitPreference) {
      return measurementField.displayUnits.find((du) => du.id == orgUnitPreference!.displayUnitId);
    } else {
      return measurementField.displayUnits.find((du) => du.isDefault);
    }
  },

  getPreferedDisplayUnitId(
    unitPreferences: UnitPreference[],
    measurementField: MeasurementField,
    orgOnly?: boolean,
  ): string | undefined {
    const userUnitPreference = this.getUserUnitPreference(unitPreferences, measurementField);
    const orgUnitPreference = this.getOrgUnitPreference(unitPreferences, measurementField);

    if (userUnitPreference && !orgOnly) {
      return userUnitPreference.displayUnitId;
    } else if (orgUnitPreference) {
      return orgUnitPreference.displayUnitId;
    }
    return measurementField.displayUnits.find((du) => du.isDefault)?.id;
  },

  getDisplayUnitFromData(fieldKey: string, data: HistoricalMeasurementDataResponse[]): string {
    return data[0].displayUnits[fieldKey];
  },

  hasDevicePowerPerformanceMeasurements(device: DashboardDevice) {
    return (
      device.measurements.includes("battery") && device.measurements.includes("charge-circuit")
    );
  },

  getDevicePowerPerformanceMeasurements(
    device: DashboardDevice,
  ): { battery: string; batteryKey: string; charge: string; chargeKey: string } | undefined {
    const battery = "battery";
    const batteryKey = "i-voltage";
    const chargeCircuit = "charge-circuit";
    const chargeCircuitKey = "i-input";
    const charge = "charge";
    const chargeKey = "i-voltage";

    const hasBattery = device.measurements.includes(battery as MeasurementName);
    const hasChargeCircuit = device.measurements.includes(chargeCircuit as MeasurementName);
    const hasCharge = device.measurements.includes(charge as MeasurementName);

    if (!hasBattery) return undefined;
    if (hasChargeCircuit)
      return {
        battery: battery,
        batteryKey: batteryKey,
        charge: chargeCircuit,
        chargeKey: chargeCircuitKey,
      };
    if (hasCharge)
      return { battery: battery, batteryKey: batteryKey, charge: charge, chargeKey: chargeKey };

    return undefined;
  },

  hasDevicePowerPerformanceDeviceTypeMeasurements(
    device: DashboardDevice,
    measurements: InfluxDbMeasurement[],
  ) {
    return measurements.every((m) => device.deviceTypeMeasurements?.includes(m.toString()));
  },

  getDifferenceInHoursFromUTCTime(): number {
    const offset = new Date().getTimezoneOffset();
    let timezone = 60;

    if (offset < 0) {
      timezone = -60;
    }

    return offset / timezone;
  },

  getTimeOffsetInMinToUTCTime(): number {
    return new Date().getTimezoneOffset();
  },

  getTimezoneAbbr(): string {
    const dates = new Date().toLocaleTimeString(undefined, { timeZoneName: "short" }).split(" ");
    return dates[2];
  },

  isInfluxDbMeasurement(value: string): value is InfluxDbMeasurement {
    return value in InfluxMeasurementsObj;
  },
};
