import { Component } from "react";
import { Button, ButtonType } from "./Button";

interface IProps {
  deviceId: string;
  dashboardTemplateId?: string;
}
interface IState {}

export class DashboardButton extends Component<IProps, IState> {
  render() {
    if (this.props.dashboardTemplateId) {
      return (
        <a href={`/go/dashboard/device/${this.props.deviceId}`}>
          <Button
            content="View Dashboard"
            iconClass="fa fa-tachometer-alt"
            buttonType={ButtonType.Outline}
          />
        </a>
      );
    }

    return (
      <a href={`/go/dashboard/default/${this.props.deviceId}`}>
        <Button
          content="View Dashboard"
          iconClass="fa fa-tachometer-alt"
          buttonType={ButtonType.Outline}
        />
      </a>
    );
  }
}
