import { ApiService } from "./ApiService";
import { Measurement, NewMeasurement } from "../../models/api/Measurement";
import { apiEndPoint } from "../../config";
import { MeasurementField } from "../../models/api/MeasurementField";
import { PagedResults } from "../../models/api/PagedResults";
import { TableFilters } from "../../models/api/TableFilters";
import { MeasurementFieldDisplayUnit } from "../../models/api/MeasurementFieldDisplayUnit";
import { UnitPreference } from "../../models/api/UnitPreference";
import { Promise } from "bluebird";

export class MeasurementsApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "measurements");

    this.list = this.list.bind(this);
  }

  createMeasurement(newMeasurement: NewMeasurement): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("", newMeasurement, cancel).done((result) => {
        if (result.success) {
          resolve(result.measurementId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateMeasurement(measurementId: string, measurement: Measurement): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(`${measurementId}`, measurement, cancel).done((result) => {
        if (result.success) {
          resolve(result.measurementId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteMeasurement(measurementId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${measurementId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listRequest(
    filterByOrg: boolean,
    includeFields: boolean = false,
    tableFilters?: TableFilters,
  ): Promise<PagedResults<Measurement>> {
    return new Promise<PagedResults<Measurement>>((resolve, reject, cancel) => {
      this.get(
        `?filterByOrg=${filterByOrg}&includeFields=${includeFields}${
          tableFilters != undefined ? "&" + tableFilters.toQueryParameterString() : ""
        }`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const measurements = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                displayName: d.DISPLAYNAME,
                fieldCount: d.FIELDCOUNT,
                fieldKeys: d.FIELDKEYS,
                fields:
                  includeFields && d.FIELDS != undefined
                    ? d.FIELDS.map(
                        (a: any) =>
                          ({
                            id: a.ID,
                            dataType: a.DATATYPE,
                            displayName: a.DISPLAYNAME,
                            fieldKey: a.FIELDKEY,
                            displayUnits:
                              a.DISPLAYUNITS != undefined
                                ? a.DISPLAYUNITS.map(
                                    (du: any) =>
                                      ({
                                        id: du.ID,
                                        measurementFieldId: du.MEASUREMENTFIELDID,
                                        displayUnit: du.DISPLAYUNIT,
                                        formula: du.FORMULA,
                                        isDefault: du.ISDEFAULT,
                                        decimalPlaces: du.DECIMALPLACES,
                                      }) as MeasurementFieldDisplayUnit,
                                  )
                                : [],
                            hasRange: a.HASRANGE,
                            maximum: a.MAXIMUM,
                            minimum: a.MINIMUM,
                            measurementId: a.MEASUREMENTID,
                            storedUnit: a.STOREDUNIT,
                          }) as MeasurementField,
                      )
                    : [],
              }) as Measurement,
          );
          resolve({
            items: measurements,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
  list(
    includeFields: boolean = false,
    tableFilters?: TableFilters,
  ): Promise<PagedResults<Measurement>> {
    return this.listRequest(false, includeFields, tableFilters);
  }
  listForOrg(
    includeFields: boolean = false,
    tableFilters?: TableFilters,
  ): Promise<PagedResults<Measurement>> {
    return this.listRequest(true, includeFields, tableFilters);
  }

  getMeasurement(measurementId: string): Promise<Measurement> {
    return new Promise<Measurement>((resolve, reject, cancel) => {
      this.get(`${measurementId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            name: d.NAME,
            displayName: d.DISPLAYNAME,
          } as Measurement);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getMeasurementFields(measurementId: string): Promise<MeasurementField[]> {
    return new Promise<MeasurementField[]>((resolve, reject, cancel) => {
      this.get(`${measurementId}/fields`, cancel).done((result) => {
        if (result.success) {
          resolve(
            result.data.map(
              (d: any) =>
                ({
                  id: d.ID,
                  measurementId: d.MEASUREMENTID,
                  fieldKey: d.FIELDKEY,
                  displayName: d.DISPLAYNAME,
                  dataType: d.DATATYPE,
                  storedUnit: d.STOREDUNIT,
                  displayUnits:
                    d.DISPLAYUNITS != undefined
                      ? d.DISPLAYUNITS.map(
                          (du: any) =>
                            ({
                              id: du.ID,
                              measurementFieldId: du.MEASUREMENTFIELDID,
                              displayUnit: du.DISPLAYUNIT,
                              formula: du.FORMULA,
                              isDefault: du.ISDEFAULT,
                              decimalPlaces: du.DECIMALPLACES,
                            }) as MeasurementFieldDisplayUnit,
                        )
                      : [],
                  hasRange: d.HASRANGE,
                  maximum: d.MAXIMUM,
                  minimum: d.MINIMUM,
                  defaultDataField: d.DEFAULTDATAFIELD,
                }) as MeasurementField,
            ),
          );
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteMeasurementField(measurementId: string, fieldId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${measurementId}/fields/${fieldId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  createMeasurementField(measurementId: string, field: MeasurementField): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `${measurementId}/fields`,
        {
          dataType: field.dataType,
          decimalPlaces: field.displayUnits.map((du) => du.decimalPlaces ?? 0).join(","),
          defaultDataField: field.defaultDataField,
          displayName: field.displayName,
          displayUnits: field.displayUnits.map((du) => du.displayUnit ?? "").join(","),
          fieldKey: field.fieldKey,
          formulas: field.displayUnits.map((du) => du.formula ?? "").join(","),
          hasRange: field.hasRange,
          isDefaults: field.displayUnits.map((du) => du.isDefault ?? "").join(","),
          maximum: field.maximum,
          measurementId: field.measurementId,
          minimum: field.minimum,
          storedUnit: field.storedUnit,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.measurementId);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateMeasurementField(
    measurementId: string,
    fieldId: string,
    field: MeasurementField,
  ): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `${measurementId}/fields/${fieldId}`,
        {
          dataType: field.dataType,
          decimalPlaces: field.displayUnits.map((du) => du.decimalPlaces ?? "0").join(","),
          defaultDataField: field.defaultDataField,
          displayName: field.displayName,
          displayUnitIds: field.displayUnits.map((du) => du.id ?? "").join(","),
          displayUnits: field.displayUnits.map((du) => du.displayUnit ?? "").join(","),
          fieldKey: field.fieldKey,
          formulas: field.displayUnits.map((du) => du.formula ?? "").join(","),
          hasRange: field.hasRange,
          isDefaults: field.displayUnits.map((du) => du.isDefault ?? "").join(","),
          maximum: field.maximum,
          measurementId: field.measurementId,
          minimum: field.minimum,
          storedUnit: field.storedUnit,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listUnitPreferences(): Promise<UnitPreference[]> {
    return new Promise<UnitPreference[]>((resolve, reject, cancel) => {
      this.get(`unitPreferences`, cancel).done((result) => {
        if (result.success) {
          resolve(
            result.data.map(
              (d: any) =>
                ({
                  id: d.ID,
                  orgId: d.ORGID,
                  userId: d.USERID,
                  displayUnitId: d.DISPLAYUNITID,
                  measurementFieldId: d.MEASUREMENTFIELDID,
                }) as UnitPreference,
            ),
          );
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  createUnitPreference(unitPreference: UnitPreference): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(`unitPreferences`, unitPreference, cancel).done((result) => {
        if (result.success) {
          resolve(result.unitPreferenceId);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateUnitPreference(unitPreferenceId: string, unitPreference: UnitPreference): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`unitPreferences/${unitPreferenceId}`, unitPreference, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteUnitPreference(unitPreferenceId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`unitPreferences/${unitPreferenceId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
