import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthToken } from "../../services/api/AuthenticationApiService";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { useIsSubOrgOf } from "../../services/context/OrganizationsApiContext";

export interface AuthContextType {
  token?: AuthToken;
  setToken?: Dispatch<SetStateAction<AuthToken | undefined>>;
  tokenLoaded: boolean;
  emailAddress?: string;
  setEmailAddress?: Dispatch<SetStateAction<string | undefined>>;
  isImpersonated: boolean;
  isDevelopment: boolean;
  isStaging: boolean;
  impersonatedUserName: string;
  isInertiaOrg: boolean;
  isInertiaSubOrg: boolean;
  usesInertiaDashboard: boolean;
}
export const AuthContext = createContext<AuthContextType>({
  tokenLoaded: false,
  isImpersonated: false,
  isStaging: false,
  isDevelopment: false,
  impersonatedUserName: "",
  isInertiaOrg: false,
  isInertiaSubOrg: false,
  usesInertiaDashboard: false,
});

interface IProps {
  children: any;
}

export const AuthProvider: FC<IProps> = ({ children }) => {
  const [token, setToken] = useState<AuthToken>();
  const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>();
  const [isImpersonated, setIsImpersonated] = useState<boolean>(false);
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [isStaging, setIsStaging] = useState<boolean>(false);
  const [isInertiaOrg, setIsInertiaOrg] = useState(false);
  const [inertiaOrgId, setInertiaOrgId] = useState<string>();
  const [usesInertiaDashboard, setUsesInertiaDashboard] = useState(false);
  const [isInertiaSubOrg, setIsInertiaSubOrg] = useState<boolean>(false);
  const [impersonatedUserName] = useState<string>("");
  const { isSuccess: isSubOrgDataLoaded, data: subOrg } = useIsSubOrgOf(inertiaOrgId);

  useEffect(() => {
    if (!tokenLoaded) {
      if (JwtTokenHelpers.authenticated()) {
        setToken(JwtTokenHelpers.getDecodedJwtToken());
      }
      setTokenLoaded(true);
    }
  });
  useEffect(() => {
    if (token) {
      setIsImpersonated(JwtTokenHelpers.isImpersonated());
      setIsDevelopment(JwtTokenHelpers.isDevelopment());
      setIsStaging(JwtTokenHelpers.isStaging());
      setIsInertiaOrg(JwtTokenHelpers.isInertiaOrg());
      setInertiaOrgId(JwtTokenHelpers.getInertiaOrgId());
      setUsesInertiaDashboard(JwtTokenHelpers.usesInertiaDashboard());
    }
  }, [token, setToken]);

  useEffect(() => {
    if (isSubOrgDataLoaded) {
      setIsInertiaSubOrg(subOrg!);
    }
  }, [
    isInertiaOrg,
    setIsInertiaOrg,
    setInertiaOrgId,
    inertiaOrgId,
    isSubOrgDataLoaded,
    usesInertiaDashboard,
    setUsesInertiaDashboard,
  ]);

  const value = {
    token,
    setToken,
    tokenLoaded,
    emailAddress,
    setEmailAddress,
    isImpersonated,
    isDevelopment,
    isStaging,
    impersonatedUserName,
    isInertiaOrg,
    isInertiaSubOrg,
    usesInertiaDashboard,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};
