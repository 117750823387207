import { FC, useEffect, useMemo, useState } from "react";
import { DivIcon } from "leaflet";
import { LayersControl, MapContainer, Marker, TileLayer } from "react-leaflet";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import {
  ConfigState,
  DASHBOARD_WIDGET_CLASS,
  INITIAL_CONFIG_STATE,
  WidgetIcons,
} from "../../../SimpleAppTemplate";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import { MapCard } from "../../../../../../components/cards";
import useCurrentMapLocation from "../hooks/useCurrentMapLocation";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import { ErrorNoData } from "../../index";
import SupportLink from "../../../../../../helpers/SupportLink";
import { CoordinatesHelper } from "../../../../../../helpers/CoordinatesHelper";

type Props = {
  device: DashboardDevice;
  orgId?: string;
};

interface HardcodedLocation {
  latitudeHardcoded?: number | string;
  longitudeHardcoded?: number | string;
  hasHardcodedLocation: boolean;
}

const INITIAL_HARDCODED_LOCATION_STATE = {
  latitudeHardcoded: undefined,
  longitudeHardcoded: undefined,
  hasHardcodedLocation: false,
};

const LocationWidget: FC<Props> = ({ device, orgId }) => {
  const icons: WidgetIcons = { icon: "", errorIcon: "fa-regular fa-map-location-dot" };
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const [hardcodedLocation, setHardcodedLocation] = useState<HardcodedLocation>(
    INITIAL_HARDCODED_LOCATION_STATE,
  );
  const {
    fetchData,
    data: mapLocationData,
    isLoading,
    hasError,
    errorMessage,
  } = useCurrentMapLocation(device.id);

  useEffect(() => {
    let hasHardcodedLocation = false;
    if (
      !!device.latitudeHardcoded &&
      // @ts-expect-error
      device.latitudeHardcoded != "NaN" &&
      !!device.longitudeHardcoded &&
      // @ts-expect-error
      device.longitudeHardcoded != "NaN"
    ) {
      hasHardcodedLocation = true;
    }
    setHardcodedLocation({
      latitudeHardcoded: device.latitudeHardcoded,
      longitudeHardcoded: device.longitudeHardcoded,
      hasHardcodedLocation: hasHardcodedLocation,
    });
  }, [device.latitudeHardcoded, device.longitudeHardcoded]);

  useEffect(() => {
    // Measurement Names
    const mapLocationName = "location";
    const latitudeFieldKey = "n-latitude";
    const longitudeFieldKey = "n-longitude";

    const hasConfigError = !mapLocationName || !latitudeFieldKey || !longitudeFieldKey;
    if (hasConfigError) {
      setConfigError({
        hasConfigError: true,
        errorMsg: "No Measurement Name and/or Field configured for this widget",
      });
    }

    if (!hasConfigError) {
      const getData = async () => {
        await fetchData({
          mapLocationName,
          latitudeFieldKey,
          longitudeFieldKey,
          hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
          orgId,
        });
      };

      getData();
    }
  }, [orgId]);

  const GenerateMap = (position: { lat: number; lng: number }) => {
    return (
      <MapCard>
        <MapContainer
          center={position}
          className="h-100"
          zoom={13}
          scrollWheelZoom={true}
          worldCopyJump={true}
        >
          {/* Change Map Style */}
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Roads">
              <TileLayer
                attribution={
                  `Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ` +
                  `Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`
                }
                url={
                  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                }
                accessToken={
                  "pk.eyJ1IjoibW9kdXNlbnNlIiwiYSI6ImNqb25tdG5idDBieHUzdnBncndkc2ZuNjQifQ.H75OJyk0mCT9CcLXWcBfWw"
                }
                id="mapbox/streets-v11"
                zoomOffset={-1}
                maxZoom={18}
                tileSize={512}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                attribution={
                  'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
                  'contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                }
                url={
                  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                }
                accessToken={
                  "pk.eyJ1IjoibW9kdXNlbnNlIiwiYSI6ImNqb25tdG5idDBieHUzdnBncndkc2ZuNjQifQ.H75OJyk0mCT9CcLXWcBfWw"
                }
                id="mapbox/satellite-v9"
                zoomOffset={-1}
                maxZoom={18}
                tileSize={512}
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          <TileLayer
            attribution={
              `Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ` +
              `Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`
            }
            url={
              "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
            }
            accessToken={
              "pk.eyJ1IjoibW9kdXNlbnNlIiwiYSI6ImNqb25tdG5idDBieHUzdnBncndkc2ZuNjQifQ.H75OJyk0mCT9CcLXWcBfWw"
            }
            id="mapbox/streets-v11"
          />
          {CoordinatesHelper.areCoordinatesValid(position.lat, position.lng) && (
            <Marker
              position={position}
              icon={
                new DivIcon({
                  html: '<i class="fas fa-map-marker-alt text-primary fa-3x text-white-outline"></i>',
                  iconAnchor: [13, 36],
                  popupAnchor: [0, -36],
                })
              }
            />
          )}
        </MapContainer>
      </MapCard>
    );
  };

  const GetDisplayCard = useMemo(() => {
    if (
      hardcodedLocation.hasHardcodedLocation &&
      typeof hardcodedLocation.latitudeHardcoded == "number" &&
      typeof hardcodedLocation.longitudeHardcoded == "number"
    ) {
      return (
        <GenerateMap
          lat={hardcodedLocation.latitudeHardcoded}
          lng={hardcodedLocation.longitudeHardcoded}
        />
      );
    }

    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="100%" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (mapLocationData.hasData) {
      return (
        <GenerateMap
          lat={mapLocationData.currentData.latitude}
          lng={mapLocationData.currentData.longitude}
        />
      );
    } else {
      if (mapLocationData.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${mapLocationData.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return (
        <ErrorNoData
          cardCaption={`No Proper Location data for ${device.name}`}
          supportQuestion={
            <>
              <p>
                Click
                <span>&nbsp;</span>
                <span className="text-caption-link">
                  <a
                    href={`/go/admin/devices/${device.id}/details`}
                    className="link"
                    target="_blank"
                  >
                    here
                  </a>
                </span>
                <span>&nbsp;</span>
                to set up the data.
              </p>
              <p>
                Or, Expecting data?
                <span>&nbsp;</span>
                <SupportLink />
              </p>
            </>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }
  }, [mapLocationData, hardcodedLocation, configError]);

  return <div className={DASHBOARD_WIDGET_CLASS}>{GetDisplayCard}</div>;
};

export default LocationWidget;
