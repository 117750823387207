import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import {
  ConfigState,
  INITIAL_CONFIG_STATE,
  CurrentWidgetMeasurementConfig,
  WidgetIcons,
  DASHBOARD_WIDGET_CLASS,
} from "../../../SimpleAppTemplate";
import { ValueCard } from "../../../../../../components/cards";
import { ErrorNoData } from "../../index";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import DateHelpers from "../../../../../../helpers/DateHelpers";
import useCurrentRainfall from "../hooks/useCurrentRainfall";
import SupportLink from "../../../../../../helpers/SupportLink";
import { getWidgetIcons } from "../../WidgetHelper";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  measurement: CurrentWidgetMeasurementConfig;
};

const Rainfall: FC<Props> = ({ device, orgId, measurement }) => {
  const [icons, setIcons] = useState<WidgetIcons>({
    icon: "fa-regular fa-cloud-showers-heavy",
    errorIcon: "fa-regular fa-cloud-showers-heavy",
  });
  const [label, setLabel] = useState<string>("Rainfall (Last 24 Hours)");
  const [hideIfNoData, setHideIfNoData] = useState<boolean>(false);
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const { fetchData, data, isLoading, hasError, errorMessage } = useCurrentRainfall(device.id);

  useEffect(() => {
    if (measurement) {
      // Required
      const name = measurement.name;
      const fieldKey = measurement.fieldKey;

      // Optional
      const displayName = measurement.displayName;
      const fieldName = measurement.fieldName;
      const portNumber = parseInt(measurement.port ?? "0");
      const label = measurement.label;

      const hasConfigError = !name || !fieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Measurement Name and/or FieldKey configured for this widget",
        });
      }

      if (measurement.hideIfNoData) setHideIfNoData(measurement.hideIfNoData);
      if (measurement.icon) setIcons(getWidgetIcons(measurement.icon));
      if (label) setLabel(label);

      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            name,
            fieldKey,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            displayName,
            fieldName,
            portNumber,
            orgId,
          });
        };

        getData();
      }
    } else {
      setConfigError({
        hasConfigError: true,
        errorMsg: "No Measurement Information is configured for this widget",
      });
    }
  }, [measurement, orgId]);

  const GetDisplayCard = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="100%" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (data.hasData) {
      return (
        <ValueCard
          cardValue={data.currentData.value}
          cardValueDisplay={data.currentData.value
            .toFixed(data.currentData.decimalPlaces)
            .concat(data.currentData.valueDisplayUnit)}
          isCritical={false}
          cardCaption={`${label}`}
          iconPos="left"
          iconClass={icons.icon}
          lastSeen={DateHelpers.getLastSeenBadge(data.currentData.time)}
        />
      );
    } else {
      if (data.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${data.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      if (!hideIfNoData) {
        return (
          <ErrorNoData
            cardCaption={`No ${label} data for ${device.name}`}
            // This could also be wrong configuration - Either wrong measurement name or field
            supportQuestion={
              <p>
                Expecting data?
                <span>&nbsp;</span>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return <></>;
    }
  }, [data, configError, icons]);

  const displayCard: JSX.Element = hideIfNoData ? (
    <></>
  ) : (
    <div className={DASHBOARD_WIDGET_CLASS}> {GetDisplayCard} </div>
  );
  return <>{displayCard}</>;
};

export default Rainfall;
