import { FC } from "react";
import DateHelpers from "../../../helpers/DateHelpers";
import { AlertPriorityLabel } from "../../../components/layout/components/alertNotifications/AlertNotificationHelper";
import { DeviceAlertHistory } from "../../../models/api/DeviceAlertHistory";

import "./alert-history-list.scss";

type Props = {
  deviceAlertHistory: DeviceAlertHistory[];
};

const DeviceAlertHistoryList: FC<Props> = ({ deviceAlertHistory }) => {
  return (
    <div className="chart-card-chart-display">
      <table className="alert-history-table">
        <thead>
          <tr>
            <th>Alert</th>
            <th>Priority</th>
            <th>Alert Message</th>
            <th>Started At</th>
            <th>Stopped At</th>
          </tr>
        </thead>
        <tbody>
          {deviceAlertHistory.map((item) => (
            <tr>
              <td>
                <a href={`/go/manage/alerts/${item.alertId}`}>{item.alertName}</a>
              </td>
              <td>
                <AlertPriorityLabel priority={item.priority} showNone={true} />
              </td>
              <td>{item.message}</td>
              <td>{DateHelpers.formatMomentAsDateTime(item.dateTriggered)}</td>
              <td>
                {item.stoppedAlerting && DateHelpers.formatMomentAsDateTime(item.stoppedAlerting)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DeviceAlertHistoryList;
