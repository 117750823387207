import { FC, useEffect, useRef, useState } from "react";
import { useLinkedAccounts } from "../../../services/context/OrganizationsApiContext";
import { useAuth } from "../../../contexts/auth/auth.context";
import Select from "react-select";
import HeaderIconButtons from "../components/HeaderIconButtons";
import { useSwitchOrganisation } from "../../../services/context/AuthenticationApiServiceContext";
import { useHistory } from "react-router-dom";
import { Dialog, DialogSize } from "../../Dialog";
import { Button, ButtonType } from "../../Button";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";

type Props = Record<string, never>;

const Header: FC<Props> = () => {
  const auth = useAuth();
  const history = useHistory();

  const { data: organizations, refetch } = useLinkedAccounts();
  const { mutate: doSwitchOrg } = useSwitchOrganisation({
    onSuccess: () => {
      const orgId = JwtTokenHelpers.getOrgId();
      setCurrentOrgId(orgId);
      history.push("/go/welcome");
      window.location.reload();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [selectedOrgId, setSelectedOrgId] = useState<string>();
  const [currentOrgId, setCurrentOrgId] = useState<string>();

  const closeDialog = useRef<() => void>(() => {});
  const openDialog = useRef<() => void>(() => {});

  const orgSelectOptions = organizations?.map((d) => ({ value: d.id, label: d.name }));

  useEffect(() => {
    // refetch linked accounts
    refetch();

    const orgId = JwtTokenHelpers.getOrgId();
    setCurrentOrgId(orgId);
  }, [auth]);

  const handleSwitchOrg = () => {
    if (selectedOrgId) {
      closeDialog.current();
      doSwitchOrg(selectedOrgId);
    }
  };
  return (
    <>
      <nav className="main-header navbar navbar-expand">
        <div className="navbar-nav d-flex w-100">
          {/* Nav Menu Toggle */}
          <div className="nav-item">
            <a
              id="menu-id"
              className="btn btn-icon-toolbar light"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="nav-icon fas fa-bars" />
            </a>
          </div>

          {/* Organization List */}
          {organizations?.length !== undefined && organizations?.length > 1 && (
            <div className="pl-2">
              <Select
                classNamePrefix="react-select"
                className="input org-selector"
                id="org_selector"
                options={orgSelectOptions}
                value={orgSelectOptions?.find((i: any) => i.value == currentOrgId)}
                onChange={(option: any) => {
                  if (option?.value != currentOrgId) {
                    setSelectedOrgId(option?.value);
                    openDialog.current();
                  }
                }}
                isSearchable={false}
                isClearable={false}
                menuPortalTarget={document.body}
              />
            </div>
          )}

          {/* Environment Tag */}
          {auth.isDevelopment && (
            <div className="nav-item d-none d-sm-inline-block">
              <div className="environment-warning nav-link text-danger">DEVELOPMENT</div>
            </div>
          )}
          {auth.isStaging && (
            <div className="nav-item d-none d-sm-inline-block">
              <div className="environment-warning nav-link text-danger">STAGING</div>
            </div>
          )}

          {/* Impersonated Tag */}
          {auth.isImpersonated && (
            <div className="nav-item d-none d-sm-inline-block">
              <div className="environment-warning nav-link text-danger">IMPERSONATED LOGIN</div>
            </div>
          )}
        </div>

        <div className="user-panel" id="settings-link">
          <HeaderIconButtons />
        </div>
      </nav>

      <Dialog
        size={DialogSize.Small}
        setOpen={(open) => (openDialog.current = open)}
        setClose={(open) => (closeDialog.current = open)}
        onClose={() => {}}
        header="Switch Organization"
        body={<p>Please confirm you wish to switch organization.</p>}
        footer={
          <>
            <span className="flex-grow-1" />
            <Button
              content="No"
              buttonType={ButtonType.Transparent}
              onClick={() => {
                setSelectedOrgId(currentOrgId);
                closeDialog.current();
              }}
            />
            <Button content="Yes" buttonType={ButtonType.Error} onClick={handleSwitchOrg} />
          </>
        }
      />
    </>
  );
};

export default Header;
