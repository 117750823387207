import { useQuery } from "react-query";
import { OrganizationsApiService } from "../api/OrganizationsApiService";

const useLinkedAccounts = () => {
  return useQuery(
    ["linked-accounts"],
    () => {
      const service = new OrganizationsApiService();
      return service.linkedAccounts().then((res) => res.items);
    },
    {
      staleTime: 0,
    },
  );
};

const useIsSubOrgOf = (parentOrgId?: string) => {
  return useQuery(
    ["isSubOrg", parentOrgId],
    () => {
      const service = new OrganizationsApiService();
      return service.isSubOrg(parentOrgId!);
    },
    {
      staleTime: 0,
      enabled: !!parentOrgId,
    },
  );
};

export { useLinkedAccounts, useIsSubOrgOf };
