import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import {
  ConfigState,
  HistoricalWidgetMeasurementConfig,
  INITIAL_CONFIG_STATE,
} from "../../../SimpleAppTemplate";
import { ChartCard } from "../../../../../../components/cards";
import { ErrorNoData } from "../../index";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import { useHistoricalChartsFilter } from "../../context/historical-charts-filter";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import { AMCWindSpeedAndDirection } from "../../../../../../components/charts";
import useHistoricalWindSpeedAndDirection from "../hooks/useHistoricalWindSpeedAndDirection";
import { Guid } from "js-guid";
import SupportLink from "../../../../../../helpers/SupportLink";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  chartLabel: string;
  measurements: HistoricalWidgetMeasurementConfig[];
};

// TODO: Informative Comment
const WindSpeedAndDirection: FC<Props> = ({ device, orgId, chartLabel, measurements }) => {
  const icons: { icon: string | JSX.Element; errorIcon: string | JSX.Element } = {
    icon: "",
    errorIcon: "fa-regular fa-chart-column",
  };
  const { duration, resolution, timeLastRefresh, customEndDate } = useHistoricalChartsFilter();
  const [speedLabel, setSpeedLabel] = useState<string>("Wind Speed");
  const [directionLabel, setDirectionLabel] = useState<string>("Wind Direction");
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const {
    fetchData,
    data: historicalChartData,
    isLoading,
    hasError,
    errorMessage,
  } = useHistoricalWindSpeedAndDirection(device.id);

  useEffect(() => {
    if (!!measurements && measurements.length == 2) {
      // Line Data - Required
      const speedName = measurements[0].name;
      const speedFieldKey = measurements[0].fieldKey;

      // Line Data - Optional
      const speedDisplayName = measurements[0].displayName;
      const speedFieldName = measurements[0].fieldName;
      const speedPortNumber = parseInt(measurements[0].port ?? "0");
      const speedLabel = measurements[0].label;

      // Temperature Data - Required
      const directionName = measurements[1].name;
      const directionFieldKey = measurements[1].fieldKey;

      // Temperature Data - Optional
      const directionDisplayName = measurements[0].displayName;
      const directionFieldName = measurements[0].fieldName;
      const directionPortNumber = parseInt(measurements[1].port ?? "0");
      const directionLabel = measurements[1].label;

      const hasConfigError = !speedName || !speedFieldKey || !directionName || !directionFieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Measurement Name and/or FieldKey configured for this widget",
        });
      }

      if (speedLabel) setSpeedLabel(speedLabel);
      if (directionLabel) setDirectionLabel(directionLabel);

      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            duration,
            resolution,
            customEndDate,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            speedName,
            speedFieldKey,
            directionName,
            directionFieldKey,
            speedDisplayName,
            speedFieldName,
            speedPortNumber,
            directionDisplayName,
            directionFieldName,
            directionPortNumber,
            orgId,
          });
        };

        getData();
      }
    } else {
      setConfigError({
        hasConfigError: true,
        errorMsg: "We need Wind Speed and Direction Measurement Configurations for this widget",
      });

      if (measurements.length > 2) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "Only 2 Measurement Configurations is needed for this widget",
        });
      }
    }
  }, [timeLastRefresh, orgId]);

  const GetDisplayChart = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="500px" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (historicalChartData.hasData) {
      return (
        <AMCWindSpeedAndDirection
          data={historicalChartData.historicalData}
          speedLabel={speedLabel}
          directionLabel={directionLabel}
          chartId={new Guid().toString()}
        />
      );
    } else {
      if (historicalChartData.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${historicalChartData.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return (
        <ErrorNoData
          cardCaption={`No data for ${device.name} in this duration.`}
          supportQuestion={
            <p>
              Expecting data?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }
  }, [historicalChartData.historicalData?.length, configError]);

  return (
    <ChartCard
      chartTitle={chartLabel == "" ? "Wind Speed x Direction" : chartLabel}
      showFilters={true}
      chart={<>{GetDisplayChart}</>}
    />
  );
};

export default WindSpeedAndDirection;
