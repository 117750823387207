import { FC, PropsWithChildren } from "react";
import { IconButton } from "../../IconButton";
import { Button, ButtonType } from "../../Button";

export interface ModalFooterButton {
  buttonName: string;
  onClick: () => void;
  disabled?: boolean;
}

interface Props extends PropsWithChildren {
  modalTitle: string;
  onClose: () => void;
  closeButtonText?: string;
  onConfirm?: () => void;
  isOnConfirmError?: boolean;
  confirmButtonText?: string;
}

const ModalOverlay: FC<Props> = ({
  modalTitle,
  onClose,
  closeButtonText = "Close",
  onConfirm,
  isOnConfirmError = false,
  confirmButtonText = "Save Changes",
  children,
}) => {
  return (
    <section className="modal-wrapper">
      <section className="modal-backdrop-container" onClick={onClose} />

      <section className="modal-overlay-container">
        <div className="modal-header-container">
          <div className="modal-header"> {modalTitle} </div>
          <IconButton iconClass="fa-solid fa-x modal-close-icon" onClick={onClose} />
        </div>

        <div className="modal-content-container">{children}</div>

        <div className="modal-footer-container">
          <Button content={closeButtonText} onClick={onClose} buttonType={ButtonType.Transparent} />
          {!!onConfirm && (
            <Button
              content={confirmButtonText}
              onClick={onConfirm}
              buttonType={isOnConfirmError ? ButtonType.Error : ButtonType.Success}
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default ModalOverlay;
