import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import "../cardStyles.scss";
import { IconButton } from "../../IconButton";

interface Props extends PropsWithChildren {
  mapViewTitle?: string;
}

const MapViewCard: FC<Props> = ({ mapViewTitle = "Map View", children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isCollapsed) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isCollapsed]);

  useEffect(() => {
    if (!height || !isCollapsed || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isCollapsed]);

  const collapsedToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="map-view-card-container">
      <div className="map-view-header-container">
        <div className="map-view-header"> {mapViewTitle} </div>
        <IconButton
          iconClass={`fa-solid ${isCollapsed ? "fa-minus" : "fa-plus"} map-view-collapsed-icon`}
          onClick={collapsedToggle}
        />
      </div>

      <div
        className={`map-view-content-container ${isCollapsed ? "" : "hidden"}`}
        style={{ height }}
      >
        <div ref={ref}>
          <div className="map-view-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MapViewCard;
