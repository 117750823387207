import { FC } from "react";

import { DeviceSOHWarnings } from "../../../models/api/DashboardDevice";
import { IHasBattery } from "../../../models/IHasBattery";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import CardContainer from "./card/CardContainer";
import { TableFilters } from "../../../models/api/TableFilters";

import "./devices-soh-warning-styles.scss";

type Props = {
  dashboardDevices: DeviceSOHWarnings[] | undefined;
  isLoading: boolean;
};

const MIN_BATTERY_LEVEL = 0;
const MAX_BATTERY_LEVEL = 25;

const DevicesBatterySohWarning: FC<Props> = ({ dashboardDevices, isLoading }) => {
  if (isLoading || !dashboardDevices) {
    return <LoadingSkeleton width="100%" height="4rem" />;
  }

  const calculateBatteryPercentage = (item: IHasBattery): number => {
    if (!item.battery || !item.batteryMinimum || !item.batteryMaximum) return -1;

    let percentage = Math.round(
      (100 * (item.battery - item.batteryMinimum)) / (item.batteryMaximum - item.batteryMinimum),
    );
    percentage = Math.max(percentage, 0);
    percentage = Math.min(percentage, 100);

    return percentage;
  };

  const getLowBatteryGateways = (floor: number, ceiling: number): DeviceSOHWarnings[] => {
    if (dashboardDevices.length === 0) return [];

    return dashboardDevices.filter((dd) => {
      const percentage = calculateBatteryPercentage(dd);
      return percentage >= floor && percentage < ceiling;
    });
  };

  const getDeviceName = (device: DeviceSOHWarnings) => {
    return (
      <a
        href={`/go/dashboard/device/${device.deviceId}`}
        className="device-soh-warning-content-device-name"
        title="View dashboard"
        target="_blank"
      >
        {device.name}
      </a>
    );
  };

  const getIconAndName = (device: DeviceSOHWarnings) => {
    const deviceName = getDeviceName(device);

    const iconBatteryClass = "fas fa-battery-empty text-danger";

    if (!device.battery) {
      return <div className="device-soh-warning-wrapper">{deviceName}</div>;
    }

    return (
      <div className="device-soh-warning-wrapper">
        <div className="device-soh-warning-content-icon">
          <i className={iconBatteryClass} />
        </div>
        {deviceName}
      </div>
    );
  };

  const getBatteryLife = (device: DeviceSOHWarnings) => {
    const batteryLevelPercent = calculateBatteryPercentage(device);

    return (
      <div className="device-soh-warning-wrapper">
        <div className="device-soh-warning-content-battery-percent">
          {`${batteryLevelPercent}%`}
        </div>
      </div>
    );
  };

  const devices = getLowBatteryGateways(MIN_BATTERY_LEVEL, MAX_BATTERY_LEVEL);

  return (
    <CardContainer
      title={`${devices.length}`}
      subTitle="SOH Warning"
      iconClass="fas fa-battery-bolt"
    >
      <FilterTable<DeviceSOHWarnings>
        tableId="device-status-dialog-devices"
        items={devices}
        paginate={devices.length > 5}
        paginationOptions={[
          { label: "5", value: 5 },
          { label: "10", value: 10 },
        ]}
        columnDefinitions={[
          {
            header: "Device Name",
            valueFunction: (item) => getIconAndName(item),
            sortable: true,
            databaseColumn: "name",
            sortFunction: (a, b) => {
              if (!a.name) return -1;
              if (!b.name) return 1;
              return a.name.localeCompare(b.name);
            },
          },
          {
            header: "Battery Life",
            valueFunction: (item) => {
              if (!item.battery) return "";

              return getBatteryLife(item);
            },
            sortable: true,
            databaseColumn: "battery",
            sortFunction: (a, b) => {
              if (!a.battery) return -1;
              if (!b.battery) return 1;
              return a.battery - b.battery;
            },
          },
        ]}
        initialTableFilters={new TableFilters("battery", true)}
        hasSmallFooter
        hideFooterSelection
        removeBorder
      />
    </CardContainer>
  );
};

export default DevicesBatterySohWarning;
