import { FC, useEffect, useState } from "react";
import "../cardStyles.scss";

type Props = {
  cardValueDisplay: string;
  cardCaption: string;
  iconClass: string | JSX.Element;
};

const IndicatorCard: FC<Props> = ({ cardValueDisplay, cardCaption, iconClass }) => {
  const [chartIcon, setChartIcon] = useState<JSX.Element>(<></>);

  useEffect(() => getSettings(), []);

  function getSettings() {
    if (iconClass) {
      if (typeof iconClass == "string") {
        const _icon = <i className={iconClass} />;
        setChartIcon(_icon);
        return;
      } else {
        setChartIcon(iconClass);
      }
    }
  }

  return (
    <div className="indicator-card-container">
      <section className="indicator-card-icon">{chartIcon}</section>

      <section className="indicator-card-details">
        <p className="indicator-card-value">
          {" "}
          {cardValueDisplay} {cardCaption}{" "}
        </p>
      </section>
    </div>
  );
};

export default IndicatorCard;
