import { PropsWithChildren, ReactChild, ReactNode } from "react";
import "../../scss/formStyles.scss";

interface IProps {
  title: ReactChild;
  description?: any;
  button?: ReactNode;
}

export function FormGroup(props: PropsWithChildren<IProps>) {
  return (
    <div className="form-group">
      <div className="title-container fg-color-black-dark">
        <div className="form-subheading">{props.title}</div>
        {props.description && <div className="form-desc">{props.description}</div>}
        {props.button && <div>{props.button}</div>}
      </div>
      <div className="flex-grow-1 content-container">{props.children}</div>
    </div>
  );
}
