import { useMutation, useQuery, useQueryClient } from "react-query";
import { DashboardTemplateApiService } from "../api/DashboardTemplateApiService";

const useDashboardTemplates = () => {
  return useQuery(["dashboard-template"], () => {
    const service = new DashboardTemplateApiService();
    return service.listDashboardTemplates();
  });
};
const useDashboardTemplate = (id?: string) => {
  return useQuery(
    ["dashboard-template", id],
    () => {
      const service = new DashboardTemplateApiService();
      return service.getDashboardTemplate(id!);
    },
    {
      enabled: !!id,
    },
  );
};

const useDashboardTemplateByName = (name?: string) => {
  return useQuery(
    ["dashboard-template", name],
    async () => {
      const service = new DashboardTemplateApiService();
      return service.getDashboardTemplateByName(name!);
    },
    {
      enabled: !!name,
    },
  );
};

interface ITemplateMutationParams {
  id?: string;
  name: string;
  content: string;
  deviceTypeIds?: string[];
  testDeviceId?: string;
  testOrgId?: string;
}
interface IMutationOptions {
  onSuccess?: () => void;
  onError?: (err: any) => void;
}
const useUpsertDashboardTemplate = (mutationOptions: IMutationOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, name, content, deviceTypeIds, testDeviceId, testOrgId }: ITemplateMutationParams) => {
      const service = new DashboardTemplateApiService();
      if (id) {
        return service.updateDashboardTemplate(
          id,
          name,
          content!,
          deviceTypeIds ?? [],
          testDeviceId,
          testOrgId,
        );
      } else {
        return service.addDashboardTemplate(
          name,
          content!,
          deviceTypeIds ?? [],
          testDeviceId,
          testOrgId,
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboard-template"]);
        queryClient.invalidateQueries(["device-types-with-templates"]);
        mutationOptions?.onSuccess?.();
      },
      onError: (error) => {
        mutationOptions?.onError?.(error);
      },
    },
  );
};

const useDeviceTypesWithTemplates = () => {
  return useQuery(["device-types-with-templates"], () => {
    const service = new DashboardTemplateApiService();
    return service.getDeviceTypesWithTemplates();
  });
};

export {
  useDashboardTemplate,
  useDashboardTemplates,
  useDashboardTemplateByName,
  useUpsertDashboardTemplate,
  useDeviceTypesWithTemplates,
};
