import { useMutation, useQuery } from "react-query";
import { AlertApiService } from "../api/AlertApiService";
import { Guid } from "js-guid";
import { useEffect } from "react";
import { IMutationOptions } from "./ContextServiceHelper";

/* WILL GET DELETED */
const useDevicesInAlert = () => {
  return useQuery(
    ["devices-in-alert"],
    () => {
      const service = new AlertApiService();
      return service.getDevicesInAlert();
    },
    {
      staleTime: 0,
    },
  );
};

const useListAlertNotifications = () => {
  const ALERT_NOTIF_POLLING_INTERVAL_MILLISEC = 120000;
  const { isSuccess, data, refetch } = useQuery(
    ["alert-notifications-list"],
    () => {
      const service = new AlertApiService();
      return service.listAlertNotifications();
    },
    {
      staleTime: 0,
    },
  );

  useEffect(() => {
    const pollingIntervalId = setInterval(refetch, ALERT_NOTIF_POLLING_INTERVAL_MILLISEC);
    return () => clearInterval(pollingIntervalId);
  }, [refetch]);

  return { isSuccess, data, refetch };
};

interface IAlertNotificationDeviceAlertInfoParams {
  deviceAlertInfoId: Guid;
  deviceId: Guid;
}
const useUpdateAlertNotificationFirstSeen = (mutationOptions: IMutationOptions = {}) => {
  return useMutation(
    ({ deviceAlertInfoId, deviceId }: IAlertNotificationDeviceAlertInfoParams) => {
      const service = new AlertApiService();
      return service.updateFirstSeen(deviceAlertInfoId, deviceId);
    },
    {
      onSuccess: () => {
        mutationOptions?.onSuccess?.();
      },
      onError: (error) => {
        mutationOptions?.onError?.(error);
      },
    },
  );
};

const useUpdateAlertNotificationIsActioned = (mutationOptions: IMutationOptions = {}) => {
  return useMutation(
    ({ deviceAlertInfoId, deviceId }: IAlertNotificationDeviceAlertInfoParams) => {
      const service = new AlertApiService();
      return service.updateIsActioned(deviceAlertInfoId, deviceId);
    },
    {
      onSuccess: () => {
        mutationOptions?.onSuccess?.();
      },
      onError: (error) => {
        mutationOptions?.onError?.(error);
      },
    },
  );
};

export {
  useDevicesInAlert,
  useListAlertNotifications,
  useUpdateAlertNotificationFirstSeen,
  useUpdateAlertNotificationIsActioned,
};
