import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
import "regenerator-runtime/runtime";
import "react-credit-cards/lib/styles.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-leaflet-markercluster/dist/styles.min.css"; // sass
import "react-virtualized/styles.css"; // react-virtualized
import "rc-slider/assets/index.css"; //react slider (https://slider-react-component.vercel.app/)
import "./scss/styles.scss";
import "jquery";
import "jquery-validation";

// Replace all promises with bluebird ones
import Bluebird, * as Promise from "bluebird";
import { AwsRumConfig } from "aws-rum-web";
import ModuSenseApp from "./ModuSenseApp";
import { AuthProvider } from "./contexts/auth/auth.context";
import { QueryClient, QueryClientProvider } from "react-query";
Bluebird.config({
  cancellation: true,
  warnings: {
    wForgottenReturn: false,
  },
});
// @ts-expect-error ignore the error below. Bluebird promise is missing a few functions as compared to the
global.Promise = Promise;
declare global {
  export interface Promise<T> extends Bluebird<T> {}
}

//Configure AWS RUM client, only if running on live freewave/ms
if (window.location.hostname == "portal.modusense.com") {
  import("aws-rum-web").then(({ AwsRum }) => {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn:
          "arn:aws:iam::278949472115:role/RUM-Monitor-us-east-1-278949472115-7245063152661-Unauth",
        identityPoolId: "us-east-1:baccd5f8-a8ba-4028-823c-158121521045",
        endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: true,
      };

      const APPLICATION_ID: string = "0ef3cce4-5ef5-4205-ac49-c1781ef347b4";
      const APPLICATION_VERSION: string = "1.0.0";
      const APPLICATION_REGION: string = "us-east-1";

      new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  });
}
if (window.location.hostname == "portal.freewave.com") {
  import("aws-rum-web").then(({ AwsRum }) => {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn:
          "arn:aws:iam::278949472115:role/RUM-Monitor-us-east-1-278949472115-5319848342661-Unauth",
        identityPoolId: "us-east-1:df577cdd-d715-4a1c-9665-941835c2f803",
        endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: true,
      };

      const APPLICATION_ID: string = "178d6873-eeb6-4378-b0d7-20610badaba5";
      const APPLICATION_VERSION: string = "1.0.0";
      const APPLICATION_REGION: string = "us-east-1";

      new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  });
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  },
});

const domContainer = document.getElementById("main-freewave");
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ModuSenseApp />
    </AuthProvider>
  </QueryClientProvider>,
  domContainer,
);

// Hot Module Replacement
// @ts-expect-error
if (module.hot) module.hot.accept();
