import { Component } from "react";
/**
 * @deprecated Replaced with the LoadingSkeleton component
 */
export class LoadingSpinner extends Component<{ faSizeClass?: string }, Record<string, never>> {
  render() {
    const faSizeClass = this.props.faSizeClass ?? "fa-2x";
    return (
      <div className={`loading-spinner text-center ${faSizeClass} text-primary`}>
        <i className="fas fa-circle-notch fa-spin" aria-hidden="true" />
      </div>
    );
  }
}
