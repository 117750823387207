import { FC, useEffect, useRef } from "react";
import * as d3 from "d3";
import { Guid } from "js-guid";

type LinesPositionModel = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  charge: number;
  battery: number;
  time: string;
};

type Props = {
  width: number;
  height: number;
  x: number;
  y: number;
  data: Array<{
    time: string;
    charge: number;
    battery: number;
    label: string;
  }>;
  barSpacing: number;
  barInitialPadding: number;
  barWidth: number;
  barLeftMargin: number;
  barColumnWidth: number;
  canvasScaleHeight: number;
  marginFromTopParentDiv: number;
  hoursToPlotLabels: number[];
  batteryScale: d3.ScaleLinear<number, number>;
  chargingScale: d3.ScaleLinear<number, number>;
  chargingTicks: number[];
  chartId: Guid;
};

// SVG doesn't use px, it calculates the width automatically
const CHART_AREA_MAX_WIDTH = 760;

const ChartArea: FC<Props> = ({
  width,
  height,
  x,
  y,
  data,
  barSpacing,
  barInitialPadding,
  barWidth,
  barLeftMargin,
  barColumnWidth,
  canvasScaleHeight,
  marginFromTopParentDiv,
  batteryScale,
  chargingScale,
  chartId,
}) => {
  const groupRef = useRef<SVGGElement>(null);

  let chartAreaDifference = (CHART_AREA_MAX_WIDTH - width) / 2;

  if (width > CHART_AREA_MAX_WIDTH) {
    chartAreaDifference = width;
  }

  const plotChart = (groupId: string) => {
    const tooltipDiv = d3
      .select(`#chartAreaGroup-${chartId}`)
      .append("div")
      .attr("id", `dpp-tooltip-${chartId}`)
      .style("opacity", 0);

    const linesPosition: LinesPositionModel[] = [];

    const group = d3.select(`#${groupId}`);
    group.selectAll("g").remove();

    const chargingTrendArea = group
      .append("g")
      .attr("id", "chargingTrendArea")
      .attr("transform", `translate(${0}, ${0})`);

    // for (let index = 0; index < chargingTicks.length; index++) {
    //    if (chargingTicks[index] === 0 || chargingTicks[index] === 18) {
    //       continue;
    //    }
    //
    //    group
    //       .append("line")
    //       .attr("transform", `translate(${0}, ${marginFromTopParentDiv})`)
    //       .attr("x1", -chartAreaDifference)
    //       .attr("y1", chargingScale(chargingTicks[index]))
    //       .attr("x2", width + chartAreaDifference)
    //       .attr("y2", chargingScale(chargingTicks[index]))
    //       .attr("class", "charging-tick-lines");
    // }

    plotBatteryBars(
      linesPosition,
      group,
      chargingTrendArea,
      batteryScale,
      chargingScale,
      tooltipDiv,
    );

    plotChargingLine(linesPosition, group);

    plotChargingBottomLine(group);
  };

  const plotBatteryBars = (
    linesPosition: LinesPositionModel[],
    group: d3.Selection<d3.BaseType, unknown, HTMLElement, any>,
    chargingTrendArea: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    batteryScale: d3.ScaleLinear<number, number>,
    chargingScale: d3.ScaleLinear<number, number>,
    tooltipDiv: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
  ) => {
    let startBarsPosition = barInitialPadding;
    let startChargingX = 0;
    let finishChargingX = 0;
    let isCharging = false;
    let addChargingTrendArea = false;

    for (let index = 0; index < data.length; index++) {
      const { time, battery, charge, label } = data[index];
      const addLine = index + 1 < data.length;

      // battery bars
      group
        .append("rect")
        .attr("class", "battery-bars-rect")
        .attr("transform", `translate(${0}, ${0})`)
        .attr("x", startBarsPosition)
        .attr("y", batteryScale(battery) + marginFromTopParentDiv)
        .attr("height", canvasScaleHeight - batteryScale(battery))
        .attr("width", barWidth)
        .style("fill", getBarColor(battery))
        .on("mouseover", (e) => showTooltip(e, tooltipDiv, index, charge, battery, time))
        .on("mouseout", () => hideTooltip(tooltipDiv));

      // Need to add charging lines and positions to add lines above bars, otherwise some bars will appear above lines
      const lineXAxis = startBarsPosition + barWidth / 2;

      if (addLine) {
        const nextIndex = index + 1;
        const x1 = lineXAxis;
        const y1 = chargingScale(charge) + marginFromTopParentDiv;
        const x2 = x1 + barColumnWidth;
        const y2 = chargingScale(data[nextIndex].charge) + marginFromTopParentDiv;

        linesPosition.push({ x1, y1, x2, y2, charge, battery, time });
      }

      // Plot Charging Trend Area
      if (charge >= 18) {
        if (!isCharging) startChargingX = startBarsPosition;

        isCharging = true;
        addChargingTrendArea = false;
      }

      const lastItem = index === data.length - 1;

      if (isCharging && (charge < 18 || lastItem)) {
        isCharging = false;
        addChargingTrendArea = true;

        if (lastItem) {
          finishChargingX = startBarsPosition + barSpacing;
        } else {
          finishChargingX = startBarsPosition - barSpacing;
        }
      }

      if (!isCharging && addChargingTrendArea) {
        chargingTrendArea
          .append("rect")
          .attr("class", "charging-trend-area")
          .attr("transform", `translate(${0}, ${0})`)
          .attr("x", startChargingX)
          .attr("y", marginFromTopParentDiv)
          .attr("height", canvasScaleHeight + 10)
          .attr("width", finishChargingX - startChargingX);

        chargingTrendArea
          .append("line")
          .attr("class", "charging-bottom-line")
          .attr("transform", `translate(${0}, ${0})`)
          .attr("x1", startChargingX)
          .attr("y1", canvasScaleHeight + 10 + marginFromTopParentDiv)
          .attr("x2", finishChargingX)
          .attr("y2", canvasScaleHeight + 10 + marginFromTopParentDiv);

        startChargingX = 0;
        finishChargingX = 0;
        isCharging = false;
        addChargingTrendArea = false;
      }
      // End - Plot Charging Trend Area

      if (label) {
        plotLabels(label, group, lineXAxis);
      }

      startBarsPosition += barWidth + barLeftMargin;
    }
  };

  const plotLabels = (
    label: string,
    group: d3.Selection<d3.BaseType, unknown, HTMLElement, any>,
    lineXAxis: number,
  ) => {
    const labelMarginTop = 18;

    group
      .append("text")
      .attr("class", "dpp-plot-labels")
      .attr("x", "0")
      .attr("y", "0")
      .attr(
        "transform",
        `translate(${lineXAxis}, ${
          canvasScaleHeight + marginFromTopParentDiv + labelMarginTop
        }) rotate(-50)`,
      )
      .text(label.replace("<br>", ""));

    group
      .append("line")
      .attr("class", "dpp-plot-label-lines")
      .attr("x1", `${lineXAxis}`)
      .attr("y1", marginFromTopParentDiv)
      .attr("x2", `${lineXAxis}`)
      .attr("y2", `${canvasScaleHeight + marginFromTopParentDiv}`);
  };

  const plotChargingLine = (
    linesPosition: LinesPositionModel[],
    group: d3.Selection<d3.BaseType, unknown, HTMLElement, any>,
  ) => {
    for (let index = 0; index < linesPosition.length; index++) {
      const { x1, y1, x2, y2 } = linesPosition[index];

      group
        .append("line")
        .attr("class", "charging-line")
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2);
    }
  };

  const plotChargingBottomLine = (group: d3.Selection<d3.BaseType, unknown, HTMLElement, any>) => {
    group
      .append("line")
      .attr("class", "charging-line-bottom")
      .attr("x1", -chartAreaDifference)
      .attr("y1", canvasScaleHeight)
      .attr("x2", width + chartAreaDifference)
      .attr("y2", `${canvasScaleHeight}`);
  };

  const showTooltip = (
    e: any,
    tooltipDiv: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
    _index: number,
    charging: number,
    battery: number,
    time: string,
  ) => {
    const isRightSide = e.clientX > window.innerWidth / 2 + 1;

    const tooltipDivClass = isRightSide
      ? "dpp-chart-area-tooltip-right"
      : "dpp-chart-area-tooltip-left";

    const left = isRightSide ? e.layerX - 170 : e.layerX + 15;

    const batteryColor = getBarColor(battery);

    tooltipDiv.attr("class", tooltipDivClass).transition().duration(200).style("opacity", "0.9");

    tooltipDiv
      .html(
        `
            <div class="tooltip-charging">
               <div class="dpp-tooltip-dot"></div>
               <div class="dpp-tooltip-details">
                  ${charging} 
                  <p class="tooltip-small-text">(charging)</p>
               </div>
            </div>
            
            <div class="tooltip-battery">
               <div class="dpp-tooltip-dot" style='background: ${batteryColor}'></div>
               <div class="dpp-tooltip-details">
                  ${battery}
                  <p class="tooltip-small-text">(battery)</p>
               </div>
            </div>
            
            <div class="tooltip-time">
               <div>${time}</div>
            </div>
            `,
      )
      .style("left", left + "px")
      .style("top", e.layerY + "px");
  };

  const hideTooltip = (tooltipDiv: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>) => {
    tooltipDiv.transition().duration(500).style("opacity", "0");
  };

  const getBarColor = (battery: number): string => {
    const percent = battery - 3;

    if (percent < 0.25) {
      return "#E05B5B";
    }

    return "#3DB45E";
  };

  useEffect(() => {
    plotChart(`chart-area-dpp-group-${chartId}`);
  }, [width, chartId]);

  return (
    <svg width={width} height="100%" x={x} y={y} style={{ overflow: "initial" }}>
      <g
        ref={groupRef}
        id={`chart-area-dpp-group-${chartId}`}
        width={width}
        height={height}
        x={x}
        y={y}
      />
    </svg>
  );
};

export default ChartArea;
