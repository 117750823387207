import { FC, useCallback, useState } from "react";
import { Moment } from "moment";
import { Guid } from "js-guid";
import { ReactSelect } from "../../../inputs/ReactSelect";
import { AlertApiService } from "../../../../services/api/AlertApiService";

export enum AlertPriority {
  None,
  Low,
  Medium,
  High,
  Critical,
}

export interface AlertNotification {
  deviceAlertInfoId: Guid;
  deviceId: Guid;
  deviceName: string;
  priority: AlertPriority;
  date: Moment;
  dateString: string;
  alertMessage: string;
  isSeen: boolean;
  isActioned: boolean;
}

const priorityIconClasses = {
  [AlertPriority.None]: "",
  [AlertPriority.Low]: "fa-solid fa-flag fg-color-low-warning",
  [AlertPriority.Medium]: "fa-solid fa-flag fg-color-warning",
  [AlertPriority.High]: "fa-solid fa-flag fg-color-error",
  [AlertPriority.Critical]: "fa-solid fa-diamond-exclamation fg-color-error",
};

export const AlertPriorityLabel: FC<{
  priority: AlertPriority;
  showNone?: boolean;
  iconOnly?: boolean;
}> = ({ priority, showNone = false }) => {
  let label;
  let iconClasses = "";

  if (priority !== null && Object.values(AlertPriority).includes(priority)) {
    label = AlertPriority[priority];
    iconClasses = priorityIconClasses[priority];
  } else if (showNone) {
    label = "None";
    iconClasses = "fa-solid fa-ban fg-color-info";
  }

  return (
    <div className="d-flex">
      <div className={"mr-2 my-auto " + iconClasses}></div>
      {label}
    </div>
  );
};

const getPriorityOptions = () => {
  const alertPriorityOptions: any[] = [];

  for (const [key, value] of Object.entries(AlertPriority)) {
    if (typeof value === "number") {
      alertPriorityOptions.push({
        label: <AlertPriorityLabel priority={AlertPriority[key as keyof typeof AlertPriority]} />,
        value: `${value}`,
      });
    }
  }

  return alertPriorityOptions;
};

export const PrioritySelect: FC<{
  inputValue?: AlertPriority;
  onChange: (value: AlertPriority) => void;
}> = ({ inputValue, onChange }) => {
  return (
    <ReactSelect
      idName={new Guid().toString()}
      options={getPriorityOptions()}
      inputValue={inputValue}
      // @ts-expect-error
      onChange={({ value }) => onChange(value)}
      isClearable={false}
    />
  );
};

export interface DeviceAlertForPrioritySelect {
  deviceAlertInfoId: Guid | string;
  deviceId: Guid | string;
  priority: AlertPriority | number;
}

export const DeviceAlertPrioritySelect: FC<{
  alert: DeviceAlertForPrioritySelect;
}> = ({ alert: { deviceAlertInfoId, deviceId, priority: givenPriority } }) => {
  const [priority, setPriority] = useState(givenPriority);
  const updatePriority = useCallback(
    (value: AlertPriority) => {
      const alertService = new AlertApiService();
      alertService
        .updateAlertNotificationPriority(deviceAlertInfoId, deviceId, value)
        .then(() => {
          $.notification("show", {
            type: "message",
            title: undefined,
            message: "The device alert priority was successfully updated",
            toastOnly: true,
          });
        })
        .then(() => {
          setPriority(value);
        })
        .catch((err) => {
          $.notification("show", {
            type: "error",
            title: undefined,
            message: err,
            toastOnly: true,
          });
        });
    },
    [deviceAlertInfoId, deviceId],
  );
  return <PrioritySelect inputValue={priority} onChange={updatePriority} />;
};
