import { FC, PropsWithChildren, useEffect } from "react";
import { useDomainBranding } from "../../../services/context/BrandingApiServiceContext";

type Props = {
  tagLine: string;
};

const PageLayout: FC<PropsWithChildren<Props>> = ({ children, tagLine }) => {
  const { isSuccess: isBrandingLoaded, data: brandingTheme } = useDomainBranding();
  useEffect(() => {
    if (document.body) document.body.classList.add("login");
  });

  if (!isBrandingLoaded) return <></>;

  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100 p-3 login">
      <div className="text-center">
        <img className="logo" src={brandingTheme!.logoImageUri} />
      </div>
      <h5 className="text-center font-weight-light text-theme mb-4">{tagLine}</h5>
      <div>{children}</div>
    </div>
  );
};

export default PageLayout;
