import { Component } from "react";
import { Guid } from "js-guid";

interface IProps {
  tooltipText: string;
  className?: string;
  dataPlacement?: string;
  warningText?: boolean;
}
interface IState {
  dataPlacement: string;
  id: string;
}
export class HintIcon extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      dataPlacement: this.props.dataPlacement ?? "right",
      id: new Guid().toString(),
    };
  }

  componentDidMount() {
    $(`#${this.state.id}`).tooltip();
  }

  render() {
    return (
      <i
        id={this.state.id}
        className={`fa-regular fa-circle-question hint-icon ${
          this.props.warningText ? "text-warning" : "text-muted"
        } ${this.props.className ?? ""}`}
        data-toggle="tooltip"
        data-html="true"
        data-placement={this.state.dataPlacement}
        title={this.props.tooltipText}
      />
    );
  }
}
