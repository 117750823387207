import { FC, useState, useEffect } from "react";
import { Field, Formik, FormikValues } from "formik";
import { CountryDropdown } from "react-country-region-selector";
import * as Yup from "yup";
import { SignUpRequest, UsersApiService } from "../../services/api/UsersApiService";
import PageLayout from "./components/PageLayout";
import { Link, useHistory } from "react-router-dom";

type Props = Record<string, never>;

const CreateAccount: FC<Props> = () => {
  const history = useHistory();
  const [signUpErrorMessage, setSignUpErrorMessage] = useState<string>("");
  const isBrandingError = true;
  const defaultBranding = {
    logoImageUri: "",
    companyName: "",
    brandingTagLine: "",
    backgroundColor: "rgb(24, 27, 41)",
  };
  const CreateAccountSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    organizationName: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    password: Yup.string().min(8).required("Required"),
    confirmPassword: Yup.string()
      .min(8)
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Required"),
    iAgree: Yup.boolean().oneOf([true], "You must accept the Terms of Service and Privacy Policy"),
  });

  const initialFormValues = {
    email: "",
    organizationName: "",
    firstName: "",
    country: "US",
    lastName: "",
    password: "",
    confirmPassword: "",
    receivePromoEmails: true,
    iAgree: false,
  };

  useEffect(() => {
    if (document.body) document.body.className = "modusense login";
  });

  const getBrandName = () => {
    return isBrandingError ? "FreeWave" : defaultBranding!.companyName;
  };

  const handleSignup = (values: FormikValues) => {
    const service = new UsersApiService();

    const signUpRequest: SignUpRequest = {
      ...(values as SignUpRequest),
      email: values.email.toLowerCase(),
      countryCode: values.country.toUpperCase(),
    };
    setSignUpErrorMessage("");
    service
      .signUp(signUpRequest)
      .then(() => {
        history.push({
          pathname: "/go/home/verifyAccount",
          state: {
            email: signUpRequest.email,
            factor: signUpRequest.password,
          },
        });
      })
      .catch((rej) => {
        setSignUpErrorMessage(rej);
      });
  };

  return (
    <PageLayout tagLine="Welcome to the FreeWave Data Portal">
      <div className="create-account-box">
        <div className="card card-outline">
          <div className="card-body">
            <p className="mb-3">
              Creating a new account creates a new organization as well. If you want to join an
              existing organization, please contact an administrator of that organization for an
              invitation.
            </p>
            <Formik
              initialValues={initialFormValues}
              validationSchema={CreateAccountSchema}
              validateOnChange={true}
              validateOnBlur={false}
              onSubmit={(values) => {
                handleSignup(values);
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                isValid,
                dirty,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit} method="post" id="create-an-account">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <div className="form-group mb-3">
                          <label>Email</label>
                          <Field name="email" className="form-control" autoFocus />
                          {errors.email && touched.email ? (
                            <div style={{ color: "red" }}>{errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <div className="form-group mb-3">
                          <label>Organization Name</label>
                          <Field name="organizationName" className="form-control" />
                          {errors.organizationName && touched.organizationName ? (
                            <div style={{ color: "red" }}>{errors.organizationName}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-3">
                        <div className="form-group mb-3">
                          <label>First Name</label>
                          <Field name="firstName" className="form-control" />
                          {errors.firstName && touched.firstName ? (
                            <div style={{ color: "red" }}>{errors.firstName}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-3">
                        <div className="form-group mb-3">
                          <label>Last Name</label>
                          <Field name="lastName" className="form-control" />
                          {errors.lastName && touched.lastName ? (
                            <div style={{ color: "red" }}>{errors.lastName}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <div className="form-group mb-3 d-flex flex-column">
                          <label>Country</label>
                          <CountryDropdown
                            name="country"
                            value={values.country}
                            valueType="short"
                            onChange={(_, e) => handleChange(e)}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <div className="form-group mb-3">
                          <label>Password</label>
                          <Field name="password" className="form-control" type="password" />
                          {errors.password && touched.password ? (
                            <div style={{ color: "red" }}>{errors.password}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <div className="form-group mb-3">
                          <label>Confirm Password</label>
                          <Field name="confirmPassword" className="form-control" type="password" />
                          {errors.confirmPassword && touched.confirmPassword ? (
                            <div style={{ color: "red" }}>{errors.confirmPassword}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      {signUpErrorMessage && signUpErrorMessage !== "" && (
                        <div style={{ color: "red" }}>{signUpErrorMessage}</div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group checkbox mb-3">
                          <Field name="receivePromoEmails" type="checkbox" />
                          <label className="text-wrap" htmlFor="recievePromoEmails">
                            I would like to receive promotional emails
                          </label>
                          {errors.receivePromoEmails && touched.receivePromoEmails ? (
                            <div style={{ color: "red" }}>{errors.receivePromoEmails}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group checkbox mb-3 d-flex flex-column align-items-start">
                          <div className="d-flex flex-row">
                            <Field name="iAgree" type="checkbox" />
                            <label className="text-wrap" htmlFor="iAgree">
                              I agree to the {getBrandName()}
                              <a
                                className="link"
                                href="https://www.freewave.com/terms-of-use/"
                                target="_blank"
                              >
                                {" "}
                                terms of service{" "}
                              </a>
                              and{" "}
                              <a
                                className="link"
                                href="https://www.freewave.com/privacy-policy/"
                                target="_blank"
                              >
                                privacy policy
                              </a>
                            </label>
                            {errors.iAgree && touched.iAgree ? (
                              <div style={{ color: "red" }}>{errors.iAgree}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-row align-items-center">
                    <Link to="/go/home/login">Sign In</Link> <span className="flex-grow-1"></span>
                    <button
                      className="btn btn-fill"
                      id="create-account-button"
                      disabled={!(isValid && dirty)}
                    >
                      Create Account
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export { CreateAccount };
