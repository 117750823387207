import { Component, ReactChild } from "react";
import { IconButton } from "./IconButton";

interface IProps {
  id?: string;
  header?: ReactChild;
  body: ReactChild;
  noPadding?: boolean;
  collapsable?: boolean;
  onclick?: () => void;
  className?: string;
  isPrimary?: boolean;
  buttons?: ReactChild[];
}
interface IState {
  expanded: boolean;
}
export class Card extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expanded: true,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    if (this.props.onclick) this.props.onclick();
  }

  render() {
    const header = this.props.header;
    const body = this.props.body;

    return (
      <div
        id={this.props.id}
        className={
          "card text-theme" +
          (this.props.isPrimary != undefined ? "card-primary " : "card-default ") +
          (this.props.onclick != undefined ? "home-card light " : "") +
          (this.props.className != undefined ? `${this.props.className} ` : "")
        }
        onClick={this.handleOnClick}
      >
        {header && (
          <div className="card-header d-flex flex-row">
            {header}
            {(this.props.collapsable || (this.props.buttons && this.props.buttons.length > 0)) && (
              <>
                <span className="flex-grow-1" />
                <span className="m-n2">
                  {this.props.collapsable && (
                    <span data-card-widget="collapse">
                      <IconButton iconClass="fas fa-minus" isDark={!this.props.isPrimary} />
                    </span>
                  )}
                  {this.props?.buttons ?? []}
                </span>
              </>
            )}
          </div>
        )}
        <div className={"card-body" + (this.props.noPadding ? " p-0" : "")}>{body}</div>
      </div>
    );
  }
}
