export class TableFilters {
  keyword: string;
  filterBy: { [columnName: string]: string };
  orderBy: string;
  isAsc: boolean;
  offset: number;
  limit: number;
  visibleColumns: string;

  constructor(orderBy?: string, isAsc?: boolean) {
    this.keyword = "";
    this.filterBy = {};
    this.orderBy = orderBy ?? "";
    this.isAsc = isAsc ?? false;
    this.offset = 0;
    this.limit = 10;
    this.visibleColumns = "";
  }

  toQueryParameterString(): string {
    const params = new URLSearchParams();

    params.append("keyword", this.keyword);
    params.append("orderBy", this.orderBy);
    params.append("isAsc", this.isAsc.toString());
    params.append("limit", this.limit.toString());
    params.append("offset", this.offset.toString());
    params.append("visibleColumns", this.visibleColumns);

    return params.toString();
  }
}
