import { ApiService } from "./ApiService";
import moment from "moment";
import { AccessGroup } from "../../models/api/AccessGroup";
import { apiEndPoint } from "../../config";
import { PagedResults } from "../../models/api/PagedResults";
import { TableFilters } from "../../models/api/TableFilters";
import { Promise } from "bluebird";

export class AccessGroupApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "accessGroups");

    this.list = this.list.bind(this);
  }

  list(tableFilters?: TableFilters): Promise<PagedResults<AccessGroup>> {
    return new Promise<PagedResults<AccessGroup>>((resolve, reject, cancel) => {
      this.get(
        `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const accessGroups = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                orgId: d.ORGID,
                webhooks: d.WEBHOOKS,
                apiKeyCount: d.APIKEYCOUNT,
                removedAt: moment.utc(d.REMOVEDAT).local(),
                createdAt: moment.utc(d.CREATEDAT).local(),
                deviceCount: d.DEVICECOUNT,
                groupName: d.GROUPNAME,
                isHidden: d.ISHIDDEN,
                webhookCount: d.WEBHOOKCOUNT,
                userCount: d.USERCOUNT,
              }) as AccessGroup,
          );
          resolve({
            items: accessGroups,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listForOrg(orgId: string): Promise<AccessGroup[]> {
    return new Promise<AccessGroup[]>((resolve, reject, cancel) => {
      this.get(`${orgId}/organization`, cancel).done((result) => {
        if (result.success) {
          const ags = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                orgId: d.ORGID,
                webhooks: d.WEBHOOKS,
                apiKeyCount: d.APIKEYCOUNT,
                removedAt: moment.utc(d.REMOVEDAT).local(),
                createdAt: moment.utc(d.CREATEDAT).local(),
                deviceCount: d.DEVICECOUNT,
                groupName: d.GROUPNAME,
                isHidden: d.ISHIDDEN,
                webhookCount: d.WEBHOOKCOUNT,
                userCount: d.USERCOUNT,
              }) as AccessGroup,
          );
          resolve(ags);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDetails(accessGroupId: string): Promise<AccessGroup> {
    return new Promise<AccessGroup>((resolve, reject, cancel) => {
      this.get(`${accessGroupId}`, cancel).done((result) => {
        if (result.success) {
          const ags = {
            id: result.data.ID,
            orgId: result.data.ORGID,
            webhooks: result.data.WEBHOOKIDS,
            apiKeyCount: result.data.APIKEYCOUNT,
            removedAt: moment.utc(result.data.REMOVEDAT).local(),
            createdAt: moment.utc(result.data.CREATEDAT).local(),
            deviceCount: result.data.DEVICECOUNT,
            groupName: result.data.GROUPNAME,
            isHidden: result.data.ISHIDDEN,
            webhookCount: result.data.WEBHOOKCOUNT,
            userCount: result.data.USERCOUNT,
            users: result.data.ACCOUNTIDS,
            devices: result.data.DEVICEIDS,
          } as AccessGroup;
          resolve(ags);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listForUser(userId: string): Promise<AccessGroup[]> {
    return new Promise<AccessGroup[]>((resolve, reject, cancel) => {
      this.get(`${userId}/user`, cancel).done((result) => {
        if (result.success) {
          const ags = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                orgId: d.ORGID,
                webhooks: d.WEBHOOKS,
                apiKeyCount: d.APIKEYCOUNT,
                removedAt: moment.utc(d.REMOVEDAT).local(),
                createdAt: moment.utc(d.CREATEDAT).local(),
                deviceCount: d.DEVICECOUNT,
                groupName: d.GROUPNAME,
                isHidden: d.ISHIDDEN,
                webhookCount: d.WEBHOOKCOUNT,
                userCount: d.USERCOUNT,
              }) as AccessGroup,
          );
          resolve(ags);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  create(newAccessGroupName: string): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(``, { name: newAccessGroupName }, cancel).done((result) => {
        if (result.success) {
          resolve(result.accessGroupId);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  remove(accessGroupId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${accessGroupId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  update(accessGroup: AccessGroup): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(`${accessGroup.id}`, accessGroup, cancel).done((result) => {
        if (result.success) {
          resolve(result.accessGroupId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
