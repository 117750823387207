import { Component } from "react";
import { Button, ButtonType } from "./Button";
import moment from "moment";
import DateHelpers from "../helpers/DateHelpers";
import { DateRangePicker, Range } from "react-date-range";

interface IProps {
  onDateRangeChange: (range: Range) => void;
  initialRange: Range;
  className?: string;
  buttonInputClassName?: string;
  disabled?: boolean;
}

interface IState {
  showDateRangePicker: boolean;
  pickerRange: Range;
  range: Range;
}

export class MsDateRangePicker extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showDateRangePicker: false,
      pickerRange: props.initialRange,
      range: props.initialRange,
    };

    this.generateDateRangeButtonText = this.generateDateRangeButtonText.bind(this);
    this.updateDateRange = this.updateDateRange.bind(this);
    this.cancelDateRange = this.cancelDateRange.bind(this);
    this.toggleDateRangePicker = this.toggleDateRangePicker.bind(this);
    this.showDateRangePicker = this.showDateRangePicker.bind(this);
    this.hideDateRangePicker = this.hideDateRangePicker.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
  }

  componentDidMount() {
    // Setup click handler to close date picker
    $("body").on("click", (e) => {
      if ($(e.target).closest(".date-range-picker-parent").length === 0) {
        this.cancelDateRange();
      }
    });
  }

  generateDateRangeButtonText(): string {
    const startDate = moment(this.state.range.startDate);
    const endDate = moment(this.state.range.endDate);
    return DateHelpers.formatDateRange(startDate, endDate);
  }

  updateDateRange() {
    this.setState(
      (state) => ({ range: state.pickerRange }),
      () => {
        this.props.onDateRangeChange(this.state.range);
        this.hideDateRangePicker();
      },
    );
  }

  cancelDateRange() {
    this.setState((state) => ({ pickerRange: state.range }), this.hideDateRangePicker);
  }

  toggleDateRangePicker() {
    if (this.state.showDateRangePicker) {
      this.cancelDateRange();
    } else {
      this.showDateRangePicker();
    }
  }

  showDateRangePicker() {
    this.setState(
      { showDateRangePicker: true },
      () =>
        $("#date-range-picker").get(0)?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        }),
    );
  }

  hideDateRangePicker() {
    this.setState({
      showDateRangePicker: false,
    });
  }

  handleDateRangeChange(val: any) {
    if (!val) return;
    val.selection.endDate = moment(val.selection.endDate).endOf("day").toDate();

    this.setState({
      pickerRange: val.selection,
    });
  }

  isDisabled = () => this.props.disabled === true;

  render() {
    return (
      <div className={`date-range-picker-parent ${this.props.className}`}>
        <Button
          className={`w-100 ${this.props.buttonInputClassName}`}
          content={this.generateDateRangeButtonText()}
          onClick={this.isDisabled() ? undefined : this.toggleDateRangePicker}
          rightIconClass="fas fa-chevron-down"
          buttonType={ButtonType.Outline}
        />
        {this.state.showDateRangePicker && (
          <div id="date-range-picker" className="date-range-picker">
            <DateRangePicker
              onChange={this.handleDateRangeChange}
              // @ts-expect-error
              disabled={this.props.disabled}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={[this.state.pickerRange]}
              direction="horizontal"
            />
            <div className="d-flex flex-row align-items-stretch">
              <div className="date-range-picker-button-spacer"></div>
              <span className="flex-grow-1"></span>
              <Button
                className="m-3"
                content="Cancel"
                onClick={this.cancelDateRange}
                buttonType={ButtonType.Outline}
              />
              <Button
                className="mr-3 mt-3 mb-3 ml-0"
                content="Confirm"
                onClick={this.updateDateRange}
                buttonType={ButtonType.Fill}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
