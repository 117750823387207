import { useState, useCallback } from "react";
import axios from "axios";
import cSharpApi from "../../../../../../services/api/CSharpApi";

export type DoubleLineTemperatureMulti3HistoricalDatum = {
  time: string;
  lineOne: number;
  lineTwo: number;
  temperature: number;
  lineOneDisplayUnit: string;
  lineTwoDisplayUnit: string;
  temperatureDisplayUnit: string;
};

export type DoubleLineTemperatureMulti3Data = {
  historicalData: DoubleLineTemperatureMulti3HistoricalDatum[];
  hasData: boolean;
  errorMessage: string;
};

type DoubleLineTemperatureMulti3State = {
  data: DoubleLineTemperatureMulti3Data;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
};

type FetchParams = {
  duration: number;
  resolution: number;
  customEndDate?: number;
  hoursToAdd: number;

  lineOneName: string;
  lineOneFieldKey: string;
  lineTwoName: string;
  lineTwoFieldKey: string;
  temperatureName: string;
  temperatureFieldKey: string;

  lineOneDisplayName?: string;
  lineOneFieldName?: string;
  lineOnePortNumber: number;
  lineTwoDisplayName?: string;
  lineTwoFieldName?: string;
  lineTwoPortNumber: number;
  temperatureDisplayName?: string;
  temperatureFieldName?: string;
  temperaturePortNumber: number;
  orgId?: string;
};

const INITIAL_STATE = {
  data: {
    historicalData: [],
    hasData: false,
    errorMessage: "",
  },
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};

const useDoubleLineTemperatureMulti3 = (deviceId: string) => {
  const [fetchedData, setFetchedData] = useState<DoubleLineTemperatureMulti3State>(INITIAL_STATE);

  const cancelTokenSource = axios.CancelToken.source();

  const fetchData = useCallback(
    async (params: FetchParams) => {
      setFetchedData({
        ...INITIAL_STATE,
        isLoading: true,
      });

      try {
        const response = await cSharpApi.post(
          `charts/get-historical-double-line-temperature-multi-3/${deviceId}`,
          JSON.stringify(params),
          {
            cancelToken: cancelTokenSource.token,
          },
        );

        const data = await response.data.data;

        if (data) {
          setFetchedData({
            data,
            isLoading: false,
            hasError: false,
            errorMessage: "",
            message: response.data.message,
          });
        }
      } catch (error) {
        let errorMessage = "fetching data aborted";

        if (axios.isCancel(error)) {
          console.log("fetching data aborted");
        } else {
          errorMessage = error instanceof Error ? error.message : "Unknown error";
          console.log("An error occurred", error);
        }

        setFetchedData({
          ...INITIAL_STATE,
          errorMessage,
        });
      }
      return () => cancelTokenSource.cancel();
    },
    [deviceId],
  );

  const { data, isLoading, hasError, errorMessage, message } = fetchedData;

  return { fetchData, data, isLoading, hasError, errorMessage, message };
};

export default useDoubleLineTemperatureMulti3;
