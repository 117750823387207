import { FC, useEffect, useState } from "react";
import * as d3 from "d3";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
//Type of the chart
import * as am5xy from "@amcharts/amcharts5/xy";
import { LineTemperatureHistoricalData } from "../../../pages/simpleapp/components/widgets/historic/hooks/useHistoricalLineAndTemperature";
import { MinMaxUnit } from "../../../pages/simpleapp/components/SimpleAppTemplate";
import {
  getLineColor,
  TemperatureDisplayUnit,
  ColorVariables,
  getChartDateTime,
} from "../../../pages/simpleapp/components/widgets/WidgetHelper";

type DataModel = {
  time: string;
  line: number;
  temperature: number;
  lineStrokeSetting: {
    stroke: string;
  };
  lineFillSetting: {
    fill: string;
  };
};

type Props = {
  data: LineTemperatureHistoricalData[];
  lineLabel: string;
  temperatureLabel: string;
  chartId: string;
};

const AMCLineTemperature: FC<Props> = ({ data, lineLabel, temperatureLabel, chartId }) => {
  const [normalizedData, setNormalizedData] = useState<DataModel[]>([]);

  // Default
  const [temperatureData, setTemperatureData] = useState<MinMaxUnit>({
    min: 0,
    max: 75,
    unit: "°C",
  });
  const [lineData, setLineData] = useState<MinMaxUnit>({ min: 0, max: 100, unit: "" });

  useEffect(() => {
    if (data) {
      let displayUnit = TemperatureDisplayUnit.Undefined;
      switch (data[0].temperatureDisplayUnit) {
        case "°C":
          setTemperatureData({ min: 0, max: 75, unit: data[0].temperatureDisplayUnit });
          displayUnit = TemperatureDisplayUnit.Celsius;
          break;
        case "°F":
          setTemperatureData({ min: 32, max: 167, unit: data[0].temperatureDisplayUnit });
          displayUnit = TemperatureDisplayUnit.Fahrenheit;
          break;
        case "°K":
          setTemperatureData({ min: 273, max: 348, unit: data[0].temperatureDisplayUnit });
          displayUnit = TemperatureDisplayUnit.Kelvin;
          break;
      }
      setLineData({ min: 0, max: 100, unit: data[0].lineDisplayUnit });
      const dataArr: DataModel[] = [];

      data.map((datum) => {
        dataArr.push({
          time: getChartDateTime(datum.time),
          line: datum.line,
          temperature: datum.temperature,
          lineStrokeSetting: { stroke: getLineColor(datum.temperature, displayUnit) },
          lineFillSetting: { fill: getLineColor(datum.temperature, displayUnit) },
        });
      });

      setNormalizedData(dataArr);
    }
  }, [data]);

  useEffect(() => {
    if (!!chartId && !!normalizedData && normalizedData.length > 0) {
      const root = am5.Root.new(chartId);

      // Set themes (https://www.amcharts.com/docs/v5/concepts/themes/)
      root.setThemes([am5themes_Animated.new(root)]);

      // Create Chart (https://www.amcharts.com/docs/v5/charts/xy-chart/)
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: false,
          wheelY: "zoomX",
          layout: root.verticalLayout,
        }),
      );

      // Add cursor: In here you can configure how the cursor is displayed and how it behaves on the chart.
      const cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        }),
      );
      cursor.lineY.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });
      cursor.lineX.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });

      // Create axes https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      // For the X-Axis
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "time",
          marginTop: 15,
          maxDeviation: 0,
          renderer: am5xy.AxisRendererX.new(root, {}),
        }),
      );
      xAxis.data.setAll(normalizedData);

      //### For the Line ###//
      //Left Axis
      const lineYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // min: lineData.min,
          numberFormat: `#,###'${lineData.unit}'`,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );
      lineYAxis.get("renderer").grid.template.set("forceHidden", true);

      //Line Series - the data
      const lineSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: lineLabel,
          xAxis: xAxis,
          yAxis: lineYAxis,
          valueYField: "line",
          categoryXField: "time",
          curveFactory: d3.curveBumpX,
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]${lineLabel}[/]: {valueY}${lineData.unit} \n\n[bold]DateTime[/]: {categoryX}`,
            dy: -5,
          }),
        }),
      );
      lineSeries.strokes.template.setAll({
        stroke: am5.color(ColorVariables.colorBlueWaterLight),
        strokeWidth: 1,
      });
      lineSeries.data.setAll(normalizedData);
      lineSeries.appear(1000);

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      lineSeries.set("setStateOnChildren", true);
      lineSeries.states.create("hover", {});

      lineSeries.mainContainer.set("setStateOnChildren", true);
      lineSeries.mainContainer.states.create("hover", {});

      lineSeries.strokes.template.states.create("hover", {
        strokeWidth: 3,
      });

      //### For the Temperature ###//
      const temperatureYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // min: temperatureData.min,
          // max: temperatureData.max,
          numberFormat: `#'${temperatureData.unit}'`,
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, { opposite: true }),
        }),
      );
      temperatureYAxis.get("renderer").grid.template.set("forceHidden", true);

      const lineAreaTemp = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: temperatureLabel,
          xAxis: xAxis,
          yAxis: temperatureYAxis,
          valueYField: "temperature",
          categoryXField: "time",
          curveFactory: d3.curveBumpX,
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]${temperatureLabel}[/]: {valueY}${temperatureData.unit} \n\n[bold]DateTime[/]: {categoryX}`,
            dy: -5,
          }),
        }),
      );
      lineAreaTemp.strokes.template.setAll({
        templateField: "lineStrokeSetting",
        strokeWidth: 1,
      });
      lineAreaTemp.fills.template.setAll({
        templateField: "lineFillSetting",
        visible: true,
        opacity: 0.4,
      });
      lineAreaTemp.data.setAll(normalizedData);
      lineAreaTemp.appear(1000);

      // // create hover state for series and for mainContainer, so that when series is hovered,
      // // the state would be passed down to the strokes which are in mainContainer.
      // lineAreaTemp.set("setStateOnChildren", true);
      // lineAreaTemp.states.create("hover", {});
      //
      // lineAreaTemp.mainContainer.set("setStateOnChildren", true);
      // lineAreaTemp.mainContainer.states.create("hover", {});
      //
      // lineAreaTemp.strokes.template.states.create("hover", {
      //    templateField: "lineStrokeSetting",
      //    strokeWidth: 2
      // });
      // lineAreaTemp.fills.template.states.create("hover", {
      //    templateField: "lineFillSetting",
      //    visible: true,
      //    opacity: 0.4,
      // });

      // Add scrollbar
      const scrollbar = chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
          start: 0.8,
          height: 10,
          marginTop: 15,
        }),
      );
      scrollbar.thumb.setAll({
        fill: am5.color(ColorVariables.colorPlaceholder),
      });
      scrollbar.startGrip.setAll({
        visible: false,
      });
      scrollbar.endGrip.setAll({
        visible: false,
      });
      chart.bottomAxesContainer.children.push(scrollbar);

      // Add Legends
      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      );

      // Make series change state when legend item is hovered
      legend.itemContainers.template.states.create("hover", {});

      legend.itemContainers.template.events.on("pointerover", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.hover();
      });
      legend.itemContainers.template.events.on("pointerout", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.unhover();
      });

      legend.data.setAll(chart.series.values);

      // Make stuff animate on load (https://www.amcharts.com/docs/v5/concepts/animations/)
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }
  }, [chartId, normalizedData]);

  return <div className="am-chart-container" id={chartId} />;
};

export default AMCLineTemperature;
