import { Component, FormEvent, ReactChild } from "react";
import { Organization } from "../../models/api/Organization";
import DateHelpers from "../../helpers/DateHelpers";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { OrganizationsApiService } from "../../services/api/OrganizationsApiService";
import * as countryList from "country-list";
import { User } from "../../models/api/User";
import { Button, ButtonType } from "../../components/Button";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../models/api/Role";
import { UnitPreferences } from "../../components/UnitPreferences";
import { FormWrapper } from "../../components/forms/FormWrapper";
import { FormInfo } from "../../components/forms/FormInfo";
import { FormGroup } from "../../components/forms/FormGroup";
import { Input, InputType, StateType } from "../../components/inputs/Input";
import { ReactSelect } from "../../components/inputs/ReactSelect";

interface IProps {
  organization: Organization;
  yourOrgPage?: boolean;
  onOrganizationUpdate?: (o: Organization) => void;
  users?: User[];
}

interface IState {
  organization?: Organization;
  organizations?: Organization[];
  users: User[];
  newOwnerId: string;
  newOrgName?: string;
  newBillingContactEmails?: string;
  organizationName: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingAddressCity: string;
  billingAddressPostalCode: string;
  billingAddressCountryCode: string;
  hierarchyLevelId: string;
  distributorId: string;
  resellerId: string;
  readAccess: boolean;
  writeAccess: boolean;
  validator?: any;
  isFormInvalid: boolean;
  newBillingContactEmailsError: string;
  isDistributorSelectionDisabled: boolean;
}

/**
 * Note this page is shared by both the "Your Org" page, and the Admin/Manage Org page. Ensure you don't expose anything
 * here that should not be seen by non in-house users.
 */
export class OrganizationDetailsPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      organization: this.props.organization,
      users: this.props.users ?? [],
      newOwnerId: this.props.organization?.ownerId ?? "",
      newOrgName: this.props.organization?.name,
      newBillingContactEmails: this.props.organization?.billingContactEmails,
      organizationName: this.props.organization.name ?? "",
      billingAddressLine1: this.props.organization.billingAddressLine1 ?? "",
      billingAddressLine2: this.props.organization.billingAddressLine2 ?? "",
      billingAddressCity: this.props.organization.billingAddressCity ?? "",
      billingAddressPostalCode: this.props.organization.billingAddressPostalCode ?? "",
      billingAddressCountryCode: this.props.organization.billingAddressCountryCode ?? "",
      hierarchyLevelId: this.props.organization.hierarchyLevelId ?? "",
      distributorId: this.props.organization.distributorId ?? "",
      resellerId: this.props.organization.resellerId ?? "",
      readAccess: true,
      writeAccess: true,
      isFormInvalid: true,
      newBillingContactEmailsError: "",
      isDistributorSelectionDisabled: false,
    };

    this.handleUpdateOrganization = this.handleUpdateOrganization.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.GetValueComponent = this.GetValueComponent.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    const userRole = JwtTokenHelpers.getUserRole();
    const readAccess = (userRole.permissionsOrg & PermissionFlags.Read) != 0; // if true then user has Read
    const writeAccess = (userRole.permissionsOrg & PermissionFlags.Write) != 0; //

    this.setState({ readAccess, writeAccess });

    new OrganizationsApiService()
      .list()
      .then((res) => this.setState({ organizations: res.items }))
      .catch((err) => {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        });
      });
  }

  validate(field: string) {
    let newBillingContactEmailsError = "";

    if (field === "billingContactEmail") {
      if (!this.state.newBillingContactEmails)
        newBillingContactEmailsError = "Please enter the billing contact email.";
    }

    this.setState((prevState) => ({
      ...prevState,
      newBillingContactEmailsError,
      isFormInvalid: !!newBillingContactEmailsError,
    }));

    this.setState((prevState) => ({
      ...prevState,
    }));
  }

  componentDidUpdate(_prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (prevState.newBillingContactEmails !== this.state.newBillingContactEmails) {
      this.validate("billingContactEmail");
    }
  }

  validateFormData() {
    this.validate("billingContactEmail");
  }

  handleUpdateOrganization() {
    if (this.state.organization == undefined) return;

    this.validateFormData();

    if (this.state.isFormInvalid) return;

    const organization = this.state.organization;
    organization.name = this.state.newOrgName
      ? this.state.newOrgName
      : this.state.organization.name;
    organization.billingContactEmails = this.state.newBillingContactEmails
      ? this.state.newBillingContactEmails
      : "";
    organization.ownerId = this.state.newOwnerId
      ? this.state.newOwnerId
      : this.state.organization.ownerId;
    organization.billingAddressLine1 = this.state.billingAddressLine1;
    organization.billingAddressLine2 = this.state.billingAddressLine2;
    organization.billingAddressCity = this.state.billingAddressCity;
    organization.billingAddressPostalCode = this.state.billingAddressPostalCode;
    organization.billingAddressCountryCode = this.state.billingAddressCountryCode;
    organization.hierarchyLevelId = this.state.hierarchyLevelId;
    organization.distributorId = this.showDistributorPicker() ? this.state.distributorId : "";
    organization.resellerId = this.showResellerPicker() ? this.state.resellerId : "";

    new OrganizationsApiService()
      .update(this.state.organization.id, organization)
      .then(() => {
        // need to update parent state
        this.setState({ organization });

        if (this.props.onOrganizationUpdate != undefined) {
          this.props.onOrganizationUpdate(organization);
        }

        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Organization was successfully updated",
          toastOnly: true,
        });
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );
  }

  handleInputChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const target = event.currentTarget;
    const value = target.type === "checkbox" ? (target as HTMLInputElement).checked : target.value;
    const name = target.name;

    this.setState(
      (state) =>
        ({
          ...state,
          [name]: value,
        }) as IState,
    );
  }

  GetValueComponent({ allAccessValue }: { allAccessValue?: ReactChild }) {
    if (allAccessValue !== undefined) {
      return (
        <p>
          <span> {allAccessValue} </span>
        </p>
      );
    }

    return <></>;
  }

  getBillingContactEmailInputState() {
    if (!this.state.writeAccess) return StateType.Disabled;

    if (this.state.newBillingContactEmailsError) return StateType.Error;

    return StateType.Default;
  }

  render() {
    const countrySelectItems = countryList.getData().map((c) => ({
      label: `${c.name} (${c.code})`,
      value: c.code,
    }));

    const userSelectOptions =
      this.props.users?.map((d) => ({
        value: d.accountId,
        label: !d.name.trim() ? d.email : d.name,
      })) ?? [];

    const hierarchyLevelAdminOptions = {
      label: "Admin",
      value: "11320c1b-27cb-4a36-9665-7edf87b36c26",
    };
    const hierarchyLevelDistributorOptions = {
      label: "Distributor",
      value: "b58f4f52-4f2c-452d-a9d4-e1e08d5cc566",
    };
    const hierarchyLevelResellerOptions = {
      label: "Reseller",
      value: "4b2b4322-aea3-4a74-a11b-b70af297f5e6",
    };
    const hierarchyLevelCustomerOptions = {
      label: "Customer",
      value: "9e424ce9-dd7b-43ef-abe8-3be974d7f485",
    };

    const hierarchyLevelOptions = [
      hierarchyLevelAdminOptions,
      hierarchyLevelDistributorOptions,
      hierarchyLevelResellerOptions,
      hierarchyLevelCustomerOptions,
    ];

    const orgOptions =
      this.state.organizations?.map((o) => ({
        label: o.name,
        value: o,
      })) ?? [];

    const distributorsOptions =
      orgOptions
        ?.filter(
          (o) =>
            o.value.hierarchyLevelId == hierarchyLevelDistributorOptions.value &&
            o.value.id != this.props.organization.id,
        )
        ?.map((o) => ({
          label: o.value.name,
          value: o.value.id,
        })) ?? [];

    const resellersOptions =
      this.state.organizations
        ?.filter(
          (o) =>
            o.hierarchyLevelId == hierarchyLevelResellerOptions.value &&
            o.id != this.props.organization.id,
        )
        ?.map((o) => ({
          label: o.name,
          value: o.id,
        })) ?? [];

    return (
      <section className={`content ${this.props.yourOrgPage ? "" : "mt-4"}`}>
        {this.props.organization && (
          <FormWrapper>
            <FormInfo
              properties={[
                {
                  key: "Created",
                  value: `${
                    this.props.organization.createdAt
                      ? DateHelpers.formatMoment(this.props.organization.createdAt)
                      : ""
                  }`,
                },
                {
                  key: "Total Users",
                  value: (
                    <this.GetValueComponent allAccessValue={this.props.organization.totalUsers} />
                  ),
                },
                {
                  key: "Total Access Groups",
                  value: (
                    <this.GetValueComponent
                      allAccessValue={this.props.organization.totalAccessGroups}
                    />
                  ),
                },
                {
                  key: "Total Devices",
                  value: (
                    <this.GetValueComponent allAccessValue={this.props.organization.totalDevices} />
                  ),
                },
                {
                  key: "Total API Keys",
                  value: (
                    <this.GetValueComponent allAccessValue={this.props.organization.totalApiKeys} />
                  ),
                },
                {
                  key: "Company ID",
                  value: (
                    <this.GetValueComponent
                      allAccessValue={100000 + this.props.organization.uniqueCode}
                    />
                  ),
                },
                {
                  key: "Heirachy Level",
                  value: (
                    <this.GetValueComponent
                      allAccessValue={this.props.organization.hierarchyLevelName}
                    />
                  ),
                },
                {
                  key: `${this.props.organization.distributor ? "Distributor" : ""}`,
                  value: (
                    <this.GetValueComponent allAccessValue={this.props.organization.distributor} />
                  ),
                },
                {
                  key: `${this.props.organization.reseller ? "Reseller" : ""}`,
                  value: (
                    <this.GetValueComponent allAccessValue={this.props.organization.reseller} />
                  ),
                },
              ]}
            />
            <FormGroup title="Details">
              <form id="configure-organization-form">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <Input
                      idName="name"
                      labelString="Organization Name"
                      placeholder="Organization Name"
                      inputType={InputType.Text}
                      inputValue={this.state.newOrgName}
                      onChange={(event) =>
                        this.setState({
                          newOrgName: (event.target as HTMLInputElement).value,
                        })
                      }
                      inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <ReactSelect
                      idName="owner"
                      labelString="Owner"
                      options={userSelectOptions}
                      inputValue={this.state.newOwnerId}
                      onChange={(option: any) => this.setState({ newOwnerId: option?.value ?? "" })}
                      isSearchable={false}
                      isClearable={false}
                      placeholder="Select owner"
                      inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <ReactSelect
                      idName="hierarchyLevel"
                      labelString="Hierarchy Level"
                      options={hierarchyLevelOptions}
                      inputValue={this.state.hierarchyLevelId}
                      onChange={(option: any) => {
                        this.setState({ hierarchyLevelId: option?.value ?? "" });

                        if (option) {
                          if (!this.showDistributorPicker()) {
                            this.setState({ distributorId: "" });
                          }
                          if (!this.showResellerPicker()) {
                            this.setState({ resellerId: "" });
                          }
                        }
                      }}
                      isSearchable={false}
                      isClearable={false}
                      placeholder="Select Hierarchy Level"
                      inputState={
                        !JwtTokenHelpers.isInhouse() || !this.state.writeAccess
                          ? StateType.Disabled
                          : StateType.Default
                      }
                    />
                  </div>

                  {/* check organization details is available, then check distributor dependency basedon hierarchy level */}
                  {this.state.organizations && this.showDistributorPicker() && (
                    <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                      <ReactSelect
                        idName="distributor"
                        labelString="Distributor"
                        options={distributorsOptions}
                        inputValue={this.state.distributorId}
                        onChange={(option: any) =>
                          this.setState({ distributorId: option?.value ?? "" })
                        }
                        isSearchable={false}
                        isClearable={true}
                        placeholder="Select Distributor"
                        inputState={
                          !this.state.writeAccess ? StateType.Disabled : StateType.Default
                        }
                      />
                    </div>
                  )}
                </div>

                {/* check organization details is available, then check reseller dependency basedon hierarchy level */}
                {this.state.organizations && this.showResellerPicker() && (
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                      <ReactSelect
                        idName="reseller"
                        labelString="Reseller"
                        options={resellersOptions}
                        inputValue={this.state.resellerId}
                        onChange={(option: any) => {
                          this.setState({ resellerId: option?.value ?? "" });
                        }}
                        isSearchable={false}
                        isClearable={true}
                        placeholder="Select Reseller"
                        inputState={
                          !this.state.writeAccess ? StateType.Disabled : StateType.Default
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <Input
                      idName="billing-contact-email"
                      labelString="Billing Contact Email"
                      inputType={InputType.Email}
                      inputValue={this.state.newBillingContactEmails}
                      inputState={this.getBillingContactEmailInputState()}
                      onChange={(event) =>
                        this.setState({
                          newBillingContactEmails: (event.target as HTMLInputElement).value,
                        })
                      }
                      placeholder="Enter a billing contact email"
                      isRequired={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <Input
                      idName="billing-address"
                      labelString="Billing Address Line 1"
                      placeholder="Address Line 1"
                      inputValue={this.state.billingAddressLine1}
                      onChange={this.handleInputChange}
                      inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <Input
                      idName="address-line-2"
                      labelString="Billing Address Line 2"
                      placeholder="Address Line 2"
                      inputValue={this.state.billingAddressLine2}
                      onChange={this.handleInputChange}
                      inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <Input
                      idName="city"
                      labelString="City"
                      placeholder="City"
                      inputValue={this.state.billingAddressCity}
                      onChange={this.handleInputChange}
                      inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <Input
                      idName="postal-code"
                      labelString="Postal Code"
                      placeholder="Postal Code"
                      inputValue={this.state.billingAddressPostalCode}
                      onChange={this.handleInputChange}
                      inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12 mb-3">
                    <ReactSelect
                      idName="country"
                      labelString="Country"
                      options={countrySelectItems}
                      inputValue={this.state.billingAddressCountryCode}
                      onChange={(option) =>
                        // @ts-expect-error
                        this.setState({ billingAddressCountryCode: option?.value ?? "" })
                      }
                      placeholder="Country"
                      isClearable={false}
                      isSearchable={true}
                      inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                    />
                  </div>
                </div>
              </form>
            </FormGroup>

            {this.state.writeAccess && <UnitPreferences onOrgPage={true} />}

            {this.state.writeAccess && (
              <div className="col-12 d-flex flex-row form-footer">
                <span className="flex-grow-0 flex-md-grow-1"></span>
                <Button
                  content="Update"
                  buttonType={ButtonType.Fill}
                  onClick={this.handleUpdateOrganization}
                />
              </div>
            )}
          </FormWrapper>
        )}

        {!this.props.organization && <LoadingSpinner />}
        <br />
        <br />
        <br />
      </section>
    );
  }

  private showDistributorPicker(): boolean {
    let showDistributorPicker = true;

    const CustomerHierarchyLevel = "9e424ce9-dd7b-43ef-abe8-3be974d7f485";
    const ResellerHierarchyLevel = "4b2b4322-aea3-4a74-a11b-b70af297f5e6";

    if (
      this.state.hierarchyLevelId != CustomerHierarchyLevel &&
      this.state.hierarchyLevelId != ResellerHierarchyLevel
    ) {
      // only show distributor  picker for reseller and customer
      showDistributorPicker = false;
    }

    // if this session is inhouse user, can update distributor
    const isInhouseUser = JwtTokenHelpers.isInhouse();
    if (!isInhouseUser) {
      showDistributorPicker = false;
    }
    return showDistributorPicker;
  }

  private showResellerPicker(): boolean {
    let showResellerPicker = true;
    const CustomerHierarchyLevel = "9e424ce9-dd7b-43ef-abe8-3be974d7f485";
    if (this.state.hierarchyLevelId != CustomerHierarchyLevel) {
      showResellerPicker = false;
    }
    const isInhouseUser = JwtTokenHelpers.isInhouse();
    if (!isInhouseUser) {
      showResellerPicker = false;
    }
    return showResellerPicker;
  }
}
