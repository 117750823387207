import { ApiService } from "./ApiService";
import { apiEndPoint } from "../../config";
import { Promise } from "bluebird";
import { Login } from "../../models/api/Login";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { ResetAccount } from "../../models/api/ResetAccount";

export interface AuthToken {
  "theme.organizationId": string;
  "theme.themeColor": string;
  isStaging: boolean;
  "role.permissionsAccessGroup": number;
  firstName: string;
  email: string;
  "role.permissionsApiKey": number;
  impersionatedLogin: boolean;
  lastName: string;
  userId: string;
  "role.permissionsProvision": number;
  orgId: string;
  "role.permissionsRole": number;
  "application.version": string;
  sub: string;
  "theme.supportUrl": string;
  "role.name": string;
  "theme.domain": string;
  "theme.supportPh": string;
  "theme.id": string;
  profileImagePath: string;
  "theme.useDarkText": string;
  "role.permissionsDeviceGroup": number;
  "theme.privacyPolicyUrl": string;
  "role.permissionsOrg": number;
  "role.permissionsDevice": number;
  orgName: string;
  "theme.companyName": string;
  portalEnv: string;
  "role.id": string;
  isDevelopment: boolean;
  accountId: string;
  orgHierarchyLevelId: string;
  iss: string;
  "role.permissionsAdminMenu": number;
  "role.permissionsBranding": number;
  "role.permissionsImport": number;
  isInhouse: number;
  "role.permissionsWebhooks": number;
  "role.permissionsDocumentation": number;
  "role.permissionsDashboard": number;
  "role.permissionsUser": number;
  "role.permissionsEventLog": number;
  "role.permissionsAlert": number;
  "role.permissionsCustomDashboard": number;
  "theme.iconImageUri": string;
  "theme.termsOfServiceUrl": string;
  "role.permissionsSubOrganization": number;
  "theme.supportEmail": string;
  exp: string;
  "theme.logoImageUri": string;
}

export interface AuthResponse {
  authenticate: boolean;
  authorizationMethod: string;
  decodedToken?: AuthToken;
  success: boolean;
  tokenSource: string;
}
export interface ILoginCredentials {
  email: string;
  password: string;
  origin?: string;
}

export interface IResetPasswordDetails {
  tokenId: string;
  password: string;
  confirmPassword: string;
}

export class AuthenticationApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "authentication");
  }

  login(credentials: ILoginCredentials) {
    credentials.origin ??= "portal";

    return new Promise<Login>((resolve, reject, cancel) => {
      this.post("login", credentials, cancel).done((result) => {
        if (result) {
          if (result.success) {
            const login: Login = {
              token: result.token,
            };
            resolve(login);
          } else {
            reject(result.message);
          }
        }
      });
    });
  }
  requestPasswordReset(email: string) {
    return new Promise<Login>((resolve, reject, cancel) => {
      this.post("requestPasswordReset", { email: email }, cancel).done((result) => {
        if (result) {
          if (result.success) {
            const login: Login = {
              token: result.token,
            };
            resolve(login);
          } else {
            reject(result.message);
          }
        }
      });
    });
  }

  resetPassword(details: IResetPasswordDetails) {
    return new Promise<Login>((resolve, reject, cancel) => {
      this.post("resetPassword", details, cancel).done((result) => {
        if (result) {
          if (result.success) {
            const login: Login = {
              token: result.token,
            };
            if (result.message) {
              login.message = result.message;
            }
            resolve(login);
          } else {
            reject(result.message);
          }
        }
      });
    });
  }

  authenticate(): Promise<AuthResponse> {
    return new Promise<AuthResponse>((resolve, reject, cancel) => {
      // check if JWT Token exists
      const token = JwtTokenHelpers.getJwtToken();
      if (!token) {
        const response: AuthResponse = {
          authenticate: false,
          authorizationMethod: "",
          success: false,
          tokenSource: "",
        };
        resolve(response);
      } else {
        this.get("authenticate", cancel).done((result) => {
          if (result.success) {
            resolve(result);
          } else {
            reject(result.message);
          }
        });
      }
    });
  }

  getResetAccountDetails(tokenId: string) {
    return new Promise<ResetAccount>((resolve, reject, cancel) => {
      this.get(`${tokenId}/resetaccount`, cancel).done((result) => {
        if (result) {
          if (result.success) {
            resolve(result.account);
          } else {
            reject(result.message);
          }
        }
      });
    });
  }

  impersonateLogin(impersonatedUserId: string, impersonatedOrgId?: string) {
    const impersonationDetails = {
      userId: impersonatedUserId,
      orgId: impersonatedOrgId,
    };
    return new Promise<Login>((resolve, reject, cancel) => {
      this.post("impersonateLogin", impersonationDetails, cancel).done((result) => {
        if (result) {
          if (result.success) {
            const login: Login = {
              token: result.token,
            };
            resolve(login);
          } else {
            reject(result.message);
          }
        }
      });
    });
  }

  impersonateLogout() {
    return new Promise<Login>((resolve, reject, cancel) => {
      this.post("impersonateLogout", cancel).done((result) => {
        if (result) {
          if (result.success) {
            const login: Login = {
              token: result.token,
            };
            resolve(login);
          } else {
            reject(result.message);
          }
        }
      });
    });
  }

  switchOrganisation(orgId: string) {
    return new Promise<Login>((resolve, reject, cancel) => {
      this.post("switchOrg", { orgId }, cancel).done((result) => {
        if (result) {
          if (result.success) {
            const login: Login = {
              token: result.token,
            };
            resolve(login);
          } else {
            reject(result.message);
          }
        }
      });
    });
  }
}
