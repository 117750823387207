import { FC } from "react";
import { DashboardDevice } from "../../models/api/DashboardDevice";
import MeasurementHelpers from "../../helpers/MeasurementHelpers";
import { BatteryGauge } from "./components/BatteryGauge";
import DPPButton from "./components/DPPButton";

interface Props {
  dashboardDevice: DashboardDevice;
  selectedDeviceId: string;
  isDPPModalOpen: boolean;
  onClick: () => void;
  onClose: () => void;
}

const PowerPerformanceButton: FC<Props> = ({
  dashboardDevice,
  selectedDeviceId,
  isDPPModalOpen,
  onClick,
  onClose,
}) => {
  const dppMeasurements = MeasurementHelpers.getDevicePowerPerformanceMeasurements(dashboardDevice);

  return (
    <>
      {dppMeasurements ? (
        <DPPButton
          dashboardDevice={dashboardDevice}
          dppMeasurement={dppMeasurements}
          selectedDeviceId={selectedDeviceId}
          isDPPModalOpen={isDPPModalOpen}
          onClick={onClick}
          onClose={onClose}
        />
      ) : (
        <div
          className={
            "d-flex flex-row align-items-center battery-percentage bg-color-white-70 fg-color-black-mid"
          }
        >
          <BatteryGauge item={dashboardDevice} label={true} />
        </div>
      )}
    </>
  );
};

export default PowerPerformanceButton;
