import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import { ChartCard } from "../../../../../../components/cards";
import { ErrorNoData } from "../../index";
import useDevicePowerPerformance from "../../../../../../hooks/useDevicePowerPerformance";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import { useHistoricalChartsFilter } from "../../context/historical-charts-filter";
import {
  ConfigState,
  CurrentWidgetMeasurementConfig,
  INITIAL_CONFIG_STATE,
} from "../../../SimpleAppTemplate";
import SupportLink from "../../../../../../helpers/SupportLink";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import { Guid } from "js-guid";
import { D3DevicePowerPerformance } from "../../../../../../components/charts";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  measurement: CurrentWidgetMeasurementConfig;
};

const DevicePowerPerformanceWidget: FC<Props> = ({ device, orgId, measurement }) => {
  const icons: { icon: string | JSX.Element; errorIcon: string | JSX.Element } = {
    icon: "",
    errorIcon: "fa-regular fa-chart-column",
  };
  const [batteryLabel, setBatteryLabel] = useState<string>("Battery Voltage");
  const [chargeLabel, setChargeLabel] = useState<string>("Charge Voltage");
  const { duration, resolution, timeLastRefresh, customEndDate } = useHistoricalChartsFilter();
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const {
    fetchData,
    data: devicePowerPerformanceData,
    isLoading,
    hasError,
    errorMessage,
  } = useDevicePowerPerformance(device.id);

  useEffect(() => {
    if (!!measurement && !!device.batteryMaximum && !!device.batteryMinimum) {
      // Required
      const batteryName = measurement.name;
      const batteryFieldKey = measurement.fieldKey;
      const maximumBattery = device.batteryMaximum;
      const minimumBattery = device.batteryMinimum;

      const chargeMeasurement = measurement.chargeMeasurement;
      let chargeName = "";
      let chargeFieldKey = "";
      const maximumCharge = 24000;
      const minimumCharge = 0;

      if (chargeMeasurement) {
        chargeName = chargeMeasurement.name;
        chargeFieldKey = chargeMeasurement.fieldKey;
      }

      // Optional
      const batteryDisplayName = measurement.displayName;
      const batteryFieldName = measurement.fieldName;
      const batteryLabel = measurement.label;

      const chargeDisplayName = chargeMeasurement ? chargeMeasurement.displayName : "";
      const chargeFieldName = chargeMeasurement ? chargeMeasurement.fieldName : "";
      const chargeLabel = chargeMeasurement ? chargeMeasurement.label : "";

      if (batteryLabel) setBatteryLabel(batteryLabel);
      if (chargeLabel) setChargeLabel(chargeLabel);

      let hasConfigError = !batteryName || !batteryFieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Battery Measurement Name and/or FieldKey configured for this widget",
        });
      }

      hasConfigError = !chargeName || !chargeFieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Charge Measurement Name and/or FieldKey configured for this widget",
        });
      }

      hasConfigError = !maximumBattery || !minimumBattery || !maximumCharge;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Battery or/and Charge Min/Max Information for this device",
        });
      }

      hasConfigError =
        !batteryName ||
        !batteryFieldKey ||
        !chargeName ||
        !chargeFieldKey ||
        !maximumBattery ||
        !minimumBattery ||
        !maximumCharge;
      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            duration,
            resolution,
            customEndDate,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            batteryName,
            batteryFieldKey,
            maximumBattery,
            minimumBattery,
            chargeName,
            chargeFieldKey,
            maximumCharge,
            minimumCharge,
            batteryDisplayName,
            batteryFieldName,
            chargeDisplayName,
            chargeFieldName,
            orgId,
          });
        };

        getData();
      }
    } else {
      const err =
        !!measurement && !!device.batteryMaximum && !!device.batteryMinimum
          ? "No Measurements and Battery Information for this widget"
          : measurement
            ? "No Measurements for this widget"
            : !!device.batteryMaximum || !!device.batteryMinimum
              ? "No Battery Information for this widget"
              : "No configuration set up for this widget";

      setConfigError({
        hasConfigError: true,
        errorMsg: err,
      });
    }
  }, [timeLastRefresh]);

  const GetDisplayChart = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="360px" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (devicePowerPerformanceData.hasData) {
      return (
        <>
          <D3DevicePowerPerformance
            data={devicePowerPerformanceData.historicalData}
            chargingMinValue={devicePowerPerformanceData.chargeMinValue}
            chargingMaxValue={devicePowerPerformanceData.chargeMaxValue}
            chargeDisplayUnit={devicePowerPerformanceData.chargeDisplayUnit}
            chargeLabel={chargeLabel}
            batteryMinValue={devicePowerPerformanceData.batteryMinValue}
            batteryMaxValue={devicePowerPerformanceData.batteryMaxValue}
            batteryDisplayUnit={devicePowerPerformanceData.batteryDisplayUnit}
            batteryLabel={batteryLabel}
            chartId={new Guid()}
          />
        </>
      );
    } else {
      if (devicePowerPerformanceData.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${devicePowerPerformanceData.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return (
        <ErrorNoData
          cardCaption={`No data for ${device.name} in this duration.`}
          supportQuestion={
            <p>
              Expecting data?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }
  }, [devicePowerPerformanceData.historicalData?.length, configError]);

  return (
    <ChartCard
      chartTitle="Device Power Performance"
      showFilters={true}
      chart={<>{GetDisplayChart}</>}
    />
  );
};

export default DevicePowerPerformanceWidget;
