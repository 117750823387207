import { ApiService } from "./ApiService";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { EventLog } from "../../models/api/EventLog";
import { Promise } from "bluebird";

export class EventLogApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "eventLog");
  }

  list(): Promise<EventLog[]> {
    return new Promise<EventLog[]>((resolve, reject, cancel) => {
      this.get("", cancel).done((result) => {
        if (result.success) {
          const eventLogs = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                accountId: d.ACCOUNTID,
                category: d.CATEGORY,
                description: d.DESCRIPTION,
                orgId: d.ORGID,
                title: d.TITLE,
                createdAt: d.CREATEDAT ? moment(new Date(d.CREATEDAT)) : undefined,
              }) as EventLog,
          );
          resolve(eventLogs);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  create(eventLog: EventLog): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("", eventLog, cancel).done((result) => {
        if (result.success) {
          resolve(result.eventLogId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
