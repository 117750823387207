import { FC, useEffect, useState, MouseEvent } from "react";
import { useUserLogin } from "../../services/context/AuthenticationApiServiceContext";
import { useResetRequired } from "../../services/context/UsersApiServiceContext";
import { Formik } from "formik";
import PageLayout from "./components/PageLayout";
import { useBrandName } from "../../services/context/BrandingApiServiceContext";
import { AuthenticationApiService } from "../../services/api/AuthenticationApiService";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth/auth.context";

type Props = Record<string, never>;
/**
 * LoginPage to get the user credentials and obtain a JWT TOKEN from the backend
 * Steps:
 * 1. Check if there is a JWT_TOKEN and if it is valid.
 *    If so, redirect to the welcome page
 * 2. Accept email address
 * 3. Check if email address needs a reset
 *    This is done by using the useResetRequired(email) hook. This hook only calls the
 *    /users/resetRequired endpoint if the email address has been specified
 * 4. Shows the correct password UI components based on the resetRequired response
 * 5. Logins in to the CF Rest system. A JWT_TOKEN is returned if the correct credentials
 *    were entered
 * 6. Store the JWT_TOKEN in local storage
 * @constructor
 */
const LoginPage: FC<Props> = () => {
  const { setEmailAddress } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState<string>();
  const {
    isSuccess: isResetRequiredLoaded,
    data: resetRequiredResponse,
    isError: isResetError,
    error,
  } = useResetRequired(email);
  const [emailSlideExtraClass, setEmailSlideExtraClass] = useState<string>("");
  const { mutate: doUserLogin } = useUserLogin({
    onSuccess: () => {
      setEmail("");
      setInvalidUserEmail("");
      history.push("/go/welcome");
    },
    onError: (result) => {
      switch (result) {
        case "User has no right to access portal":
          setLoginError("User not allowed to login to Portal");
          break;
        case "The email and password combination is not valid.":
          setLoginError("No user with these credentials");
          break;
        default:
          setLoginError("Unexpected Login Error");
      }
    },
  });
  const brandName = useBrandName();
  const [loginError, setLoginError] = useState("");
  const [userFound, setUserFound] = useState(true);
  const [invalidUserEmail, setInvalidUserEmail] = useState<string>();
  const [backLogin, setBackLogin] = useState(false);

  useEffect(() => {
    if (resetRequiredResponse && resetRequiredResponse.message === "User could not be found") {
      setUserFound(false);
      setInvalidUserEmail(email);
      setEmail("");
    } else {
      setUserFound(true);
    }
  }, [isResetError, error, resetRequiredResponse, isResetRequiredLoaded]);

  useEffect(() => {
    if (isResetRequiredLoaded && !isResetError && userFound && email !== undefined) {
      setEmailSlideExtraClass("slide");
    } else {
      setEmailSlideExtraClass("");
    }
  }, [resetRequiredResponse, isResetRequiredLoaded, isResetError, email, userFound, backLogin]);

  const handleLogin = (values: any) => {
    setLoginError("");
    doUserLogin({
      email: email!,
      password: values.password,
      origin: "portal",
    });
  };

  const handleEmailSubmit = (values: any): void => {
    const { email: enteredEmail } = values;
    if (enteredEmail !== "") {
      setEmail(enteredEmail);
    }

    setBackLogin(false);
  };

  const handleCreateAccount = (evt: MouseEvent) => {
    evt.preventDefault();
    history.push("/go/home/createAccount");
  };

  const back = () => {
    setEmailSlideExtraClass("");
    setBackLogin(true);
  };

  const forgotPassword = (evt: MouseEvent) => {
    evt.preventDefault();
    const service = new AuthenticationApiService();
    service.requestPasswordReset(email!).then(() => {
      setEmailAddress && setEmailAddress(email!);
      history.push("/go/home/resetPasswordRequest");
    });
  };

  return (
    <PageLayout tagLine="Welcome to the FreeWave Data Portal">
      <div className="login-box">
        <div className="card card-outline">
          <div className="slide-parent">
            <div id="email-slide" className={`slide-child ${emailSlideExtraClass}`}>
              <Formik
                initialValues={{ email: "" }}
                validate={(values) => {
                  const errors: any = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = "Invalid email address";
                  }
                  return errors;
                }}
                onSubmit={handleEmailSubmit}
              >
                {({ values, handleChange, handleSubmit, isValid, dirty }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="email-form"
                    noValidate={true}
                    className="d-flex flex-column h-100"
                  >
                    <p className="text-center w-100 mb-3">
                      Please enter the email associated with your {brandName} Data Portal account
                    </p>
                    <div className="form-group mb-3">
                      <input
                        id="emailInput"
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        autoComplete="email"
                        autoFocus
                        required={true}
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {userFound === false && (
                        <label id="no-account-error" className="error" htmlFor="email">
                          No account found for <span className="text-bold">{invalidUserEmail}</span>
                          , please create an account, or try again.
                        </label>
                      )}
                    </div>
                    <input
                      className="d-none"
                      type="password"
                      name="password"
                      id="hiddenPasswordInput"
                      autoComplete="current-password"
                    />
                    <span className="flex-grow-1"></span>
                    <div className="row align-items-center">
                      <div className="col-8">
                        <a className="link" href="#" onClick={(evt) => handleCreateAccount(evt)}>
                          Create account
                        </a>
                      </div>
                      <div className="col-4">
                        <button
                          type="submit"
                          className="btn btn-outline"
                          disabled={!(isValid && dirty)}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            {isResetRequiredLoaded &&
              resetRequiredResponse &&
              !resetRequiredResponse.requiresReset && (
                <div id="enter-password-slide" className="slide-child">
                  <Formik
                    initialValues={{ password: "" }}
                    validate={(values) => {
                      const errors: any = {};
                      if (!values.password) {
                        errors.password = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={handleLogin}
                  >
                    {({ values, handleChange, handleSubmit, isValid, dirty }) => (
                      <form
                        onSubmit={handleSubmit}
                        method="post"
                        id="enter-password-form"
                        noValidate={true}
                        className="d-flex flex-column h-100"
                      >
                        <p className="text-center text-muted text-bold w-100 mb-1 email"></p>
                        <p className="text-center w-100 mb-3">Next, please enter your password</p>
                        <input
                          className="d-none"
                          type="email"
                          name="email"
                          id="hiddenEmailInput"
                          autoComplete="email"
                        />
                        <div className="form-group password mb-3">
                          <input
                            id="passwordInput"
                            type="password"
                            className="form-control ignore"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            autoComplete="current-password"
                            placeholder="Password"
                          />
                        </div>
                        {loginError !== "" && <div style={{ color: "red" }}>{loginError}</div>}
                        <span className="flex-grow-1"></span>
                        <div className="align-items-center d-flex flex-row">
                          <a className="link" href="#" onClick={(evt) => forgotPassword(evt)}>
                            Forgot password?
                          </a>
                          <span className="flex-grow-1"></span>
                          <a onClick={back} className="btn btn-outline">
                            Back
                          </a>
                          <button
                            type="submit"
                            className="btn btn-fill ml-2"
                            disabled={!(isValid && dirty)}
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            {isResetRequiredLoaded &&
              resetRequiredResponse &&
              resetRequiredResponse.requiresReset && (
                <div id="reset-password-slide" className="slide-child">
                  <form method="post" id="form_forgot" className="d-flex flex-column h-100">
                    <p className="text-center text-muted text-bold w-100 mb-3 email"></p>
                    <p className="text-center w-100 mb-3">
                      As this is your first time logging in, a password reset is required.
                    </p>
                    <p className="text-center w-100 mb-3">
                      Click the button below to reset your password.
                    </p>
                    <input
                      id="reset-email-input"
                      type="hidden"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      autoFocus
                    />
                    <button
                      className="btn btn-fill"
                      type="button"
                      onClick={(evt) => forgotPassword(evt)}
                    >
                      Reset Password
                    </button>
                  </form>
                </div>
              )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export { LoginPage };
