import { FC, PropsWithChildren, Fragment } from "react";
import "./modalStyles.scss";
import ReactDOM from "react-dom";
import ModalOverlay from "../components/ModalOverlay";
import { MODAL_FREEWAVE_ID } from "../LayoutHelper";

export enum ModalSize {
  Default,
  Small,
}

interface Props extends PropsWithChildren {
  modalTitle: string;
  onClose: () => void;
  closeButtonText?: string;
  onConfirm?: () => void;
  isOnConfirmError?: boolean;
  confirmButtonText?: string;
}

const Modal: FC<Props> = ({
  modalTitle,
  onClose,
  closeButtonText = "Close",
  onConfirm,
  isOnConfirmError = false,
  confirmButtonText = "Save Changes",
  children,
}) => {
  const modalContainer = document.getElementById(MODAL_FREEWAVE_ID);
  if (!modalContainer) return null;

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          modalTitle={modalTitle}
          onClose={onClose}
          closeButtonText={closeButtonText}
          onConfirm={onConfirm}
          isOnConfirmError={isOnConfirmError}
          confirmButtonText={confirmButtonText}
        >
          {children}
        </ModalOverlay>,
        modalContainer,
      )}
    </Fragment>
  );
};

export default Modal;
