import { FC, useEffect, useRef, useState, type FocusEvent } from "react";

import { AlertHistorySortColumn } from "../AlertsHistoryPage";
import { InputWithIcon } from "../../../components/inputs/InputWithIcon";
import { Button, ButtonType } from "../../../components/Button";
import {
  AlertPriority,
  AlertPriorityLabel,
} from "../../../components/layout/components/alertNotifications/AlertNotificationHelper";
import { AlertApiService } from "../../../services/api/AlertApiService";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { Alert } from "../../../models/api/Alert";
import UseOutsideClick from "../../../hooks/useOutsideClick";
import { DeviceAlertHistory } from "../../../models/api/DeviceAlertHistory";
import DateHelpers from "../../../helpers/DateHelpers";
import { MsDateRangePicker } from "../../../components/DateRangePicker";
import { Range } from "react-date-range";
import { IconButton } from "../../../components/IconButton";

import "./alerts-list-styles.scss";

type Props = {
  alerts: DeviceAlertHistory[];
  handleFilterAlerts: (alerts: Alert[]) => void;
  handleSetDateRange: (dateRange: Range | null) => void;
  handleNextPage: () => void;
  handleSearch: (text: string) => void;
  handleSort: (sortColumn: AlertHistorySortColumn) => void;
  isLoading: boolean;
  searchText: string;
  sortColumn: AlertHistorySortColumn;
  sortDirection: "asc" | "desc";
  initialDateRange: Range;
};

type State = {
  alertsNames: Alert[];
  selectedAlertsNames: Alert[];
  showAlertsNames: boolean;
  initialDateRange: Range;
  hasDateRange: boolean;
};

const AlertsList: FC<Props> = ({
  alerts,
  handleFilterAlerts,
  handleSetDateRange,
  handleNextPage,
  handleSearch,
  handleSort,
  isLoading,
  searchText,
  sortColumn,
  sortDirection,
  initialDateRange,
}) => {
  const observerTarget = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: any) => {
      if (entries[0].isIntersecting) handleNextPage();
    });

    if (observerTarget?.current) observer.observe(observerTarget.current);

    return () => observer.disconnect();
  }, [observerTarget, alerts]);

  const [filterText, setFilterText] = useState(searchText);

  const [state, setState] = useState<State>({
    alertsNames: [],
    selectedAlertsNames: [],
    showAlertsNames: false,
    hasDateRange: false,
    initialDateRange,
  });

  const refAlertsNamesContent = useRef<HTMLDivElement>(null);

  UseOutsideClick(refAlertsNamesContent, () => {
    if (state.showAlertsNames) {
      setState((prevState) => ({
        ...prevState,
        showAlertsNames: false,
      }));
    }
  });

  const handleToggleAlertNames = () => {
    setState((prevState) => ({
      ...prevState,
      showAlertsNames: !prevState.showAlertsNames,
    }));
  };

  const fetchAlertsNames = async () => {
    const orgId = JwtTokenHelpers.getOrgId();
    const alertsNames = await new AlertApiService().getAlertsNameByOrgId(orgId);

    setState((prevState) => ({ ...prevState, alertsNames }));
  };

  useEffect(() => {
    fetchAlertsNames();
  }, []);

  const getIcon = (column: string) => {
    if (column === sortColumn) {
      if (sortDirection === "asc") return <i className="fas fa-sort-up" />;
      return <i className="fas fa-sort-down" />;
    }

    return <i className="fas fa-sort" />;
  };

  const handleChangeDateRange = (dateRange: Range) => {
    handleSetDateRange(dateRange);
  };

  const handleClearDateRange = () => {
    setState((prevState) => ({
      ...prevState,
      hasDateRange: false,
    }));

    handleSetDateRange(null);
  };

  const handleAddDateRange = () => {
    handleSetDateRange(initialDateRange);
    setState((prevState) => ({
      ...prevState,
      hasDateRange: true,
    }));
  };

  const handleRemoveSelectedAlertName = (alert: Alert) => {
    window.history.replaceState({}, document.title);
    const newAlertsNames = [...state.alertsNames, alert].sort((a, b) =>
      a.name! > b.name! ? 1 : -1,
    );

    setState((prevState) => ({
      ...prevState,
      alertsNames: newAlertsNames,
    }));

    const index = state.selectedAlertsNames.indexOf(alert);

    if (index > -1) {
      const newSelectedAlertsNames = state.selectedAlertsNames;
      newSelectedAlertsNames.splice(index, 1);

      setState((prevState) => ({
        ...prevState,
        selectedAlertsNames: newSelectedAlertsNames,
      }));

      handleFilterAlerts(newSelectedAlertsNames);
    }
  };

  const handleOnChange = (e: FocusEvent<HTMLInputElement>): void => {
    setFilterText(e.target.value);
    handleSearch(e.target.value);
  };

  const handleSelectAlertName = (alert: Alert) => {
    const newSelectedAlertsNames = [...state.selectedAlertsNames, alert].sort((a, b) =>
      a.name! > b.name! ? 1 : -1,
    );

    setState((prevState) => ({
      ...prevState,
      selectedAlertsNames: newSelectedAlertsNames,
    }));

    const index = state.alertsNames.indexOf(alert);

    if (index > -1) {
      const newAlertsNames = state.alertsNames;
      newAlertsNames.splice(index, 1);

      setState((prevState) => ({
        ...prevState,
        alertsNames: newAlertsNames,
      }));
    }

    handleFilterAlerts(newSelectedAlertsNames);
  };

  const GetSelectedAlertsNames = () => {
    if (state.selectedAlertsNames.length === 0) {
      return <div className="selected-alert-name-container" />;
    }

    return (
      <div className="selected-alert-name-container">
        {state.selectedAlertsNames.map((a) => (
          <button
            key={a.id}
            type="button"
            className="selected-alert-name"
            onClick={() => handleRemoveSelectedAlertName(a)}
          >
            {a.name}
            <i className="fas fa-times" />
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="alerts-list-container">
      <div className="filter-table-wrapper">
        <div className="search-input-wrapper">
          <InputWithIcon
            idName="alert-feed-filter-value"
            placeholder="Search Alerts"
            iconClass="fa-sharp fa-solid fa-magnifying-glass"
            onChange={handleOnChange}
            inputValue={filterText}
          />
        </div>

        <button
          className="btn-type-transparent sm"
          onClick={() => {
            const csv = [
              // Header
              [
                "Device",
                "Priority Level",
                "Alert Triggered",
                "Alert Message",
                "Measurement Caused",
                "Tags",
                "Started At",
                "Stopped At",
              ],
              // Items
              ...alerts.map((alert) => [
                `"${alert.deviceName}"`,
                AlertPriority[alert.priority as any],
                `"${alert.alertName}"`,
                `"${alert.alertMessage}"`,
                alert.measurementValueCaused,
                `"${alert.tagNames}"`,
                `"${DateHelpers.formatMomentAsDateTime(alert.dateTriggered)}"`,
                alert.stoppedAlerting &&
                  `"${DateHelpers.formatMomentAsDateTime(alert.stoppedAlerting)}"`,
              ]),
            ]
              .map((row) => row.join(","))
              .join("\n");

            const url = URL.createObjectURL(new Blob([csv], { type: "text/csv;charset=utf-8;" }));

            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "alerts-history-export.csv");
            link.style.visibility = "hidden";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          <span className="mr-2">
            <i className="fa-sharp fa-solid fa-arrow-down-to-bracket" aria-hidden="true"></i>
          </span>
          Export
        </button>

        <div className="d-flex flex-row align-items-end mt-2 mb-2">
          {state.hasDateRange ? (
            <>
              <span className="ml-3">
                <MsDateRangePicker
                  initialRange={initialDateRange}
                  onDateRangeChange={handleChangeDateRange}
                />
              </span>
              <IconButton
                iconClass="fa fa-remove"
                onClick={handleClearDateRange}
                className="mb-3"
                tooltip="Clear date range."
              />
            </>
          ) : (
            <>
              <Button content="Restrict Dates" onClick={handleAddDateRange} small />
            </>
          )}
        </div>

        <div ref={refAlertsNamesContent} className="filter-alerts-wrapper">
          <GetSelectedAlertsNames />
          <Button
            className="filter-alerts-names-button"
            content="Filter Alerts"
            buttonType={ButtonType.Outline}
            iconClass="fas fa-filter"
            onClick={handleToggleAlertNames}
            small
          />

          {!!state.showAlertsNames && (
            <div className="alerts-names-content">
              <div className="alerts-names-card">
                {state.alertsNames.map((a) => (
                  <button
                    key={a.id}
                    className="alerts-name-item-buttom"
                    onClick={() => handleSelectAlertName(a)}
                  >
                    {a.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="alerts-table-wrapper">
        <table className="alerts-table">
          <thead>
            <tr className="alerts-table-row">
              <th className="table-header device">
                Device
                <button type="button" onClick={() => handleSort("deviceId")}>
                  {getIcon("deviceId")}
                </button>
              </th>
              <th className="table-header priorityLevel">
                Priority Level
                <button type="button" onClick={() => handleSort("priorityLevel")}>
                  {getIcon("priorityLevel")}
                </button>
              </th>
              <th className="table-header alertId">
                Alert Triggered
                <button type="button" onClick={() => handleSort("alertId")}>
                  {getIcon("alertId")}
                </button>
              </th>
              <th className="table-header alertMessage">
                Alert Message
                <button type="button" onClick={() => handleSort("alertMessage")}>
                  {getIcon("alertMessage")}
                </button>
              </th>
              <th className="table-header measurementCaused">Measurement Caused</th>
              <th className="table-header stoppedAt">Tags</th>
              <th className="table-header startedAt">
                Started At
                <button type="button" onClick={() => handleSort("startedAt")}>
                  {getIcon("startedAt")}
                </button>
              </th>
              <th className="table-header stoppedAt">
                Stopped At
                <button type="button" onClick={() => handleSort("stoppedAt")}>
                  {getIcon("stoppedAt")}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {!alerts.length && !isLoading && (
              <tr>
                <td colSpan={8} className="alerts-list-no-results">
                  No results
                </td>
              </tr>
            )}
            {!!alerts.length && (
              <>
                {alerts.map((alert) => (
                  <tr key={`row-${alert.deviceAlertHistoryId}`} className="alerts-table-row">
                    <td>
                      <a
                        href={`/go/dashboard/device/${alert.deviceId}`}
                        className="widget-table-item-row"
                      >
                        {alert.deviceName}
                      </a>
                    </td>
                    <td>
                      <AlertPriorityLabel priority={alert.priority} showNone={true} />
                    </td>
                    <td>{alert.alertName}</td>
                    <td>{alert.alertMessage}</td>
                    <td>{alert.measurementValueCaused}</td>
                    <td>{alert.tagNames}</td>
                    <td>{DateHelpers.formatMomentAsDateTime(alert.dateTriggered)}</td>
                    <td>
                      {alert.stoppedAlerting &&
                        DateHelpers.formatMomentAsDateTime(alert.stoppedAlerting)}
                    </td>
                  </tr>
                ))}
                <tr ref={observerTarget}></tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AlertsList;
