import { IconButton } from "../IconButton";
import { ReactSelect } from "../inputs/ReactSelect";
import { Pill } from "../inputs/Pill";
import { PageDirection } from "./FilterTable";

import "./tableFilterStyles.scss";

interface IProps {
  totalSelectedItems: number;
  rowsPerPageId: string;
  currentPageOpt: any;
  pageText: string;
  currPage: number;
  isLastPage: boolean;
  paginationOptions: { label: string; value: number }[];
  onHandleUpdatePage: (pageDirection: PageDirection) => void;
  onChangePageOpt: (options: any) => void;
  onRemoveAllSelected: (options: any) => void;
  hasSmallFooter?: boolean;
  hideFooterSelection?: boolean;
  removeBorder?: boolean;
}

export function TableFooter(props: IProps) {
  const initialClass = `tf-footer-cont ${props.hasSmallFooter ? "small-footer" : ""} ${
    props.removeBorder ? "remove-border" : ""
  }`;
  const containerClasses: string[] = [initialClass];

  return (
    <div className={containerClasses.join(" ")}>
      {!props.hideFooterSelection && (
        <div className={`tf-footer-selected-items ${props.hasSmallFooter ? "small-footer" : ""}`}>
          {props.totalSelectedItems > 0 && (
            <Pill
              pillValue={`Selected: ${props.totalSelectedItems}`}
              onDelete={props.onRemoveAllSelected}
            />
          )}
        </div>
      )}
      <div className={`tf-footer-rows-per-page ${props.hasSmallFooter ? "small-footer" : ""}`}>
        <span>Rows per page</span>
        <ReactSelect
          idName={props.rowsPerPageId}
          options={props.paginationOptions}
          inputValue={[props.currentPageOpt]}
          onChange={props.onChangePageOpt}
          menuPlacement="top"
          data-status="footer-select"
        />
      </div>
      <div className={`tf-footer-current-page ${props.hasSmallFooter ? "small-footer" : ""}`}>
        <p> {props.pageText} </p>
        <IconButton
          iconClass="fa-sharp fa-solid fa-chevron-left"
          isDark={false}
          disabled={props.currPage <= 0}
          onClick={() => props.onHandleUpdatePage(PageDirection.Back)}
        />
        <IconButton
          iconClass="fa-sharp fa-solid fa-chevron-right"
          isDark={false}
          disabled={props.isLastPage}
          onClick={() => props.onHandleUpdatePage(PageDirection.Forward)}
        />
        <IconButton
          iconClass="fa-sharp fa-solid fa-chevrons-left"
          isDark={false}
          disabled={props.currPage <= 0}
          onClick={() => props.onHandleUpdatePage(PageDirection.First)}
        />
        <IconButton
          iconClass="fa-regular fa-chevrons-right"
          isDark={false}
          disabled={props.isLastPage}
          onClick={() => props.onHandleUpdatePage(PageDirection.Last)}
        />
      </div>
    </div>
  );
}
