import { FC } from "react";
import "../iconStyles.scss";

type Props = Record<string, never>;

const ParticulateMatterIcon: FC<Props> = () => {
  return <span className="material-symbols-outlined">grain</span>;
};

export default ParticulateMatterIcon;
