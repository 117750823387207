import { FC } from "react";
import { NoDataCard } from "../../../../../components/cards";

type Props = {
  cardCaption: string;
  supportQuestion: string | JSX.Element;
  iconClass: string | JSX.Element;
  iconPos?: "left" | "right";
};

const ErrorNoData: FC<Props> = ({ cardCaption, supportQuestion, iconClass, iconPos }) => {
  return (
    <NoDataCard
      cardCaption={cardCaption}
      supportQuestion={supportQuestion}
      iconClass={iconClass}
      iconPos={iconPos}
    />
  );
};

export default ErrorNoData;
