import { useState, useCallback } from "react";
import axios from "axios";
import { Moment } from "moment";
import cSharpApi from "../../../../../../services/api/CSharpApi";

export type WaterVolumeAndRainHistoricalData = {
  time: Moment;
  volume: number;
  maxVolume: number;
  isOver: boolean;
  percentageOver?: number;
  rain: number;
  volumeDisplayUnit: string;
  rainDisplayUnit: string;
};

export type WaterVolumeAndRainChartData = {
  historicalData: WaterVolumeAndRainHistoricalData[];
  hasData: boolean;
  errorMessage: string;
};

type WaterVolumeAndRainChartState = {
  data: WaterVolumeAndRainChartData;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
};

type FetchParams = {
  duration: number;
  resolution: number;
  customEndDate?: number;
  hoursToAdd: number;

  volumeName: string;
  volumeFieldKey: string;
  rainName: string;
  rainFieldKey: string;
  tankArea: number;
  tankDepth: number;

  volumeDisplayName?: string;
  volumeFieldName?: string;
  volumePortNumber: number;
  rainDisplayName?: string;
  rainFieldName?: string;
  rainPortNumber: number;
  orgId?: string;
};

const INITIAL_STATE = {
  data: {
    historicalData: [],
    hasData: false,
    errorMessage: "",
  },
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};

const useHistoricalWaterVolTemp = (deviceId: string) => {
  const [fetchedData, setFetchedData] = useState<WaterVolumeAndRainChartState>(INITIAL_STATE);

  const cancelTokenSource = axios.CancelToken.source();

  const fetchData = useCallback(
    async (params: FetchParams) => {
      setFetchedData({
        ...INITIAL_STATE,
        isLoading: true,
      });

      try {
        const response = await cSharpApi.post(
          `charts/get-historical-water-volume-and-rain/${deviceId}`,
          JSON.stringify(params),
          {
            cancelToken: cancelTokenSource.token,
          },
        );

        const data = await response.data.data;

        if (data) {
          setFetchedData({
            data,
            isLoading: false,
            hasError: false,
            errorMessage: "",
            message: response.data.message,
          });
        }
      } catch (error) {
        let errorMessage = "fetching data aborted";

        if (axios.isCancel(error)) {
          console.log("fetching data aborted");
        } else {
          errorMessage = error instanceof Error ? error.message : "Unknown error";
          console.log("An error occurred", error);
        }

        setFetchedData({
          ...INITIAL_STATE,
          errorMessage,
        });
      }
      return () => cancelTokenSource.cancel();
    },
    [deviceId],
  );

  const { data, isLoading, hasError, errorMessage, message } = fetchedData;

  return { fetchData, data, isLoading, hasError, errorMessage, message };
};

export default useHistoricalWaterVolTemp;
