import { WarningMsg } from "../../../../components/warningMsgs";
import moment, { Moment } from "moment/moment";
import SupportLink from "../../../../helpers/SupportLink";
import { CurrentIndicatorData, INDICATOR_ICONS, WidgetIcons } from "../SimpleAppTemplate";
import { CylinderIcon, GrassIcon, PressureIcon } from "../../../../components/createdIcons";
import { LoadingSkeleton } from "../../../../components/LoadingSkeleton";
import { IndicatorCard } from "../../../../components/cards";

export const ColorVariables = {
  colorBlackDark: "#101010",
  colorBlackMid: "#313131",
  colorBorder: "#F2F2F2",

  colorPrimary: "#323E48",
  colorPrimaryLight: "#323E4826",
  colorSuccess: "#3DB45E",
  colorWarning: "#E29D18",
  colorWarningLight: "#FBF0DC",
  colorError: "#E05B5B",
  colorErrorLight: "#F9DEDE",

  colorBlueWater: "#3472EB",
  colorBlueWaterLight: "#D7E4FF",
  colorBlueWaterTransparent: "#a5e1cc",

  colorSuccessTransparent: "rgba(61,180,94,0.15)",
  colorWarningDark: "#9f6a02",

  colorPlaceholder: "#979797",
  colorBgGray: "#F2F2F2",
  colorWhite: "#FFFFFF",
};

export enum TemperatureDisplayUnit {
  Undefined = "",
  Celsius = "°C",
  Fahrenheit = "°F",
  Kelvin = "°K",
}

export const getLineColor = (temp: number, unit: TemperatureDisplayUnit) => {
  let cold = 15;
  let warm = 22.5;

  switch (unit) {
    case TemperatureDisplayUnit.Celsius:
      cold = 15;
      warm = 22.5;
      break;
    case TemperatureDisplayUnit.Fahrenheit:
      cold = 59;
      warm = 72.5;
      break;
    case TemperatureDisplayUnit.Kelvin:
      cold = 288.15;
      warm = 295.65;
      break;
  }

  if (temp < cold) {
    return ColorVariables.colorBlueWater;
  } else if (temp >= cold && temp <= warm) {
    return ColorVariables.colorWarning;
  } else {
    return ColorVariables.colorError;
  }
};

export const getChartDateTime = (dateTime: Moment | string) => {
  return moment(dateTime).format("Do [of] MMM (HH:mm)");
};

export function getBatteryIconClass(percentage: number) {
  const errorLevel = 25;
  const warningLevel = 50;
  const midFull = 75;

  if (percentage < errorLevel) {
    return "fa-regular fa-battery-low fg-color-error";
  } else if (percentage > errorLevel && percentage < warningLevel) {
    return "fa-regular fa-battery-half fg-color-warning";
  } else if (percentage > warningLevel && percentage < midFull) {
    return "fa-regular fa-battery-three-quarters fg-color-primary-theme";
  } else {
    return "fa-regular fa-battery-full fg-color-primary-theme";
  }
}

export function getWidgetIcons(iconType: string, value?: number): WidgetIcons {
  switch (iconType.toLowerCase()) {
    case "Default".toLowerCase():
      return { icon: "fa-regular fa-file-lines", errorIcon: "fa-regular fa-file-exclamation" };
    case "Battery".toLowerCase():
      return {
        icon: getBatteryIconClass(value ?? 100),
        errorIcon: "fa-regular fa-battery-exclamation",
      };
    case "Temperature".toLowerCase():
      return {
        icon: "fa-regular fa-temperature-full",
        errorIcon: "fa-regular fa-temperature-empty",
      };
    case "SoilTemperature".toLowerCase():
      return {
        icon: "fa-regular fa-temperature-list",
        errorIcon: "fa-regular fa-temperature-empty",
      };
    case "AmbientTemperature".toLowerCase():
      return { icon: "fa-regular fa-heat", errorIcon: "fa-regular fa-heat" };
    case "Tank".toLowerCase():
      return {
        icon: <CylinderIcon percentage={value ?? 100} />,
        errorIcon: <CylinderIcon percentage={value ?? 100} />,
      };
    case "WaterDepth".toLowerCase():
      return { icon: "fa-regular fa-water", errorIcon: "fa-regular fa-droplet-slash" };
    case "SoilMoisture".toLowerCase():
      return { icon: <GrassIcon />, errorIcon: <GrassIcon /> };
    case "Rainfall".toLowerCase():
      return {
        icon: "fa-regular fa-cloud-showers-heavy",
        errorIcon: "fa-regular fa-cloud-showers-heavy",
      };
    case "Humidity".toLowerCase():
      return { icon: "fa-regular fa-droplet-percent", errorIcon: "fa-regular fa-droplet-percent" };
    case "Pressure".toLowerCase():
      return { icon: <PressureIcon />, errorIcon: <PressureIcon /> };
    case "Chart".toLowerCase():
      return { icon: "fa-regular fa-chart-column", errorIcon: "fa-regular fa-chart-column" };
    case "Day".toLowerCase():
      return { icon: "fa-light fa-calendar-day", errorIcon: "fa-light fa-calendar-day" };
    case "Week".toLowerCase():
      return { icon: "fa-light fa-calendar-week", errorIcon: "fa-light fa-calendar-week" };
    case "Solar".toLowerCase():
      return { icon: "fa-regular fa-solar-panel", errorIcon: "fa-regular fa-solar-panel" };
    case "Error".toLowerCase():
      return {
        icon: "fa-solid fa-triangle-exclamation",
        errorIcon: "fa-solid fa-triangle-exclamation",
      };
    default:
      return { icon: "fa-regular fa-file-lines", errorIcon: "fa-regular fa-file-exclamation" };
  }
}

export function getIndicator(
  data: CurrentIndicatorData,
  valueLabel: string,
  isLoading: boolean,
): JSX.Element {
  // If it is still loading
  if (isLoading) {
    return (
      <div className="indicator-card-container">
        <LoadingSkeleton width="100px" height="100%" />
      </div>
    );
  }

  // if it has data
  if (data.hasData) {
    return (
      <IndicatorCard
        cardValueDisplay={data.currentData.value
          .toString()
          .concat(data.currentData.valueDisplayUnit)}
        cardCaption={valueLabel}
        iconClass={data.currentData.value < 0 ? INDICATOR_ICONS.errorIcon : INDICATOR_ICONS.icon}
      />
    );
  }

  return <></>;
}

export function getDppWarningMsg(
  msgType: string,
  deviceName: string,
  startDate: Date,
  endDate: Date,
) {
  switch (msgType.toLowerCase()) {
    case "nochargedata":
      return (
        <WarningMsg
          warningDetail={`There are no charge data for ${deviceName} 
                  from ${moment(startDate).format("MMM DD, YYYY (hh:mm a)")} 
                  to ${moment(endDate).format("MMM DD, YYYY (hh:mm a)")}.`}
          warningSupport={
            <>
              Mistake?
              <SupportLink />
            </>
          }
        />
      );
    case "nobatterydata":
      return (
        <WarningMsg
          warningDetail={`There are no battery data for ${deviceName} 
                  from ${moment(startDate).format("MMM DD, YYYY (hh:mm a)")} 
                  to ${moment(endDate).format("MMM DD, YYYY (hh:mm a)")}.`}
          warningSupport={
            <>
              Mistake?
              <SupportLink />
            </>
          }
        />
      );
  }
}
