import { FC } from "react";
import { useAuth } from "./contexts/auth/auth.context";
import AuthenticatedApp from "./AuthenticatedApp";
import UnAuthenticatedApp from "./UnAuthenticatedApp";
import { LayoutProvider } from "./contexts/layout/layout-context";
import { BrandingProvider } from "./contexts/branding/branding.context";

type Props = Record<string, never>;

const ModuSenseApp: FC<Props> = () => {
  const { token, tokenLoaded } = useAuth();

  if (!tokenLoaded) return <></>;

  return (
    <>
      {token ? (
        <LayoutProvider>
          <BrandingProvider>
            <AuthenticatedApp />
          </BrandingProvider>
        </LayoutProvider>
      ) : (
        <UnAuthenticatedApp />
      )}
    </>
  );
};
export default ModuSenseApp;
