import { Component, ReactChild } from "react";
import { IHasBattery } from "../../../models/IHasBattery";
import { Button, ButtonType } from "../../Button";
import { Dialog, DialogSize } from "../../Dialog";
import { GraphWidget } from "../../../pages/dashboard/components/GraphWidget";
import { Measurement } from "../../../models/api/Measurement";
import { MeasurementField } from "../../../models/api/MeasurementField";
import { ColorMap } from "../../../pages/dashboard/components/DefaultDashboard";
import { Range } from "react-date-range";
import {
  BatteryMeasurementDatum,
  HistoricalMeasurementDataResponse,
} from "../../../models/api/MeasurementDataResponses";
import { UnitPreference } from "../../../models/api/UnitPreference";

interface IDeviceHistoryProps {
  range: Range;
  colorMap: ColorMap;
  voltageField: MeasurementField;
  batteryMeasurement: Measurement;
  unitPreferences: UnitPreference[];
  deviceName: string;
  deviceId: string;
  data?: HistoricalMeasurementDataResponse<BatteryMeasurementDatum>[];
}
interface IProps {
  item: IHasBattery;
  label: boolean;
  showHistory?: true;
  historyProps?: IDeviceHistoryProps;
}
interface IState {
  openDialog: () => void;
  closeDialog: () => void;
}

//TODO: I NEED TO BE CONVERTED TO FUNCTIONAL COMPONENT
export class BatteryGauge extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openDialog: () => {},
      closeDialog: () => {},
    };
  }

  calculateBatteryPercentage(item: IHasBattery): number {
    if (
      !item.battery ||
      !item.batteryMinimum ||
      !item.batteryMaximum ||
      isNaN(item.battery) ||
      isNaN(item.batteryMinimum) ||
      isNaN(item.batteryMaximum)
    ) {
      return -1;
    }

    let percentage = Math.round(
      (100 * (item.battery - item.batteryMinimum)) / (item.batteryMaximum - item.batteryMinimum),
    );
    percentage = Math.max(percentage, 0);
    percentage = Math.min(percentage, 100);

    return percentage;
  }

  generateGauge(): ReactChild {
    const batteryPercentage = this.calculateBatteryPercentage(this.props.item);

    if (batteryPercentage == -1)
      return (
        <span className="d-flex flex-row align-items-center text-para-small-bold fg-color-black-mid">
          {this.props.label && <span>Battery&nbsp;</span>}Unknown
        </span>
      );

    let percentageClass: string = "";

    if (batteryPercentage > 0) {
      percentageClass += "p-25 ";
    }
    if (batteryPercentage > 25) {
      percentageClass += "p-50 ";
    }
    if (batteryPercentage > 50) {
      percentageClass += "p-75 ";
    }
    if (batteryPercentage > 75) {
      percentageClass += "p-100 ";
    }

    return (
      <>
        <div className="d-flex flex-row align-items-center text-para-small-bold fg-color-black-mid">
          <span>
            {this.props.label && <span>Battery&nbsp;</span>}
            {batteryPercentage}%
          </span>
          <span className="mr-2" />
          <div className={`battery ${percentageClass}`}>
            <div className="body">
              <div className="bar-1" />
              <div className="bar-2" />
              <div className="bar-3" />
              <div className="bar-4" />
            </div>
            <div className="terminal" />
          </div>
        </div>
      </>
    );
  }

  render() {
    if (!this.props.showHistory) {
      return this.generateGauge();
    } else {
      return (
        <>
          <Button
            content={this.generateGauge()}
            buttonType={ButtonType.Transparent}
            tooltip="Click to view battery history"
            tooltipDelay={1000}
            onClick={this.state.openDialog}
          />
          <Dialog
            size={DialogSize.Large}
            header="Battery History"
            body={
              <>
                {this.props.historyProps && (
                  <>
                    <p className="text-muted mb-3">Showing last 7 days of battery level history.</p>
                    <GraphWidget
                      data={this.props.historyProps.data}
                      deviceId={this.props.historyProps.deviceId}
                      deviceName={this.props.historyProps.deviceName}
                      title={""}
                      iconClass={""}
                      unitPreferences={this.props.historyProps.unitPreferences}
                      measurement={this.props.historyProps.batteryMeasurement}
                      measurementField={this.props.historyProps.voltageField}
                      colorMap={this.props.historyProps.colorMap}
                      range={this.props.historyProps.range}
                      noCard={true}
                    />
                  </>
                )}
              </>
            }
            setOpen={(openDialog) => this.setState({ openDialog })}
            setClose={(closeDialog) => this.setState({ closeDialog })}
          />
        </>
      );
    }
  }
}
