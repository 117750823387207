import { DashboardApiService, IListDevicesParams } from "../api/DashboardApiService";
import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import { DashboardDevice, MeasurementName } from "../../models/api/DashboardDevice";

const useDeviceList = ({
  filter = undefined,
  sortBy = undefined,
  sortDirection = undefined,
  includeSensorDetails = undefined,
  includeMeasurements = undefined,
  includeNeverSeen = undefined,
  includeDevicesWithoutLocation = undefined,
}: IListDevicesParams = {}) => {
  const params = {
    filter,
    sortBy,
    sortDirection,
    includeSensorDetails,
    includeMeasurements,
    includeNeverSeen,
    includeDevicesWithoutLocation,
  };

  return useQuery<DashboardDevice[]>(["dashboard-devices", params], () => {
    const service = new DashboardApiService();
    return service.listDevices(params);
  });
};

const useDashboardDevice = (deviceId?: string) => {
  return useQuery(
    ["dashboard-device", deviceId],
    () => {
      const service = new DashboardApiService();
      return service.getDevice(deviceId!);
    },
    {
      enabled: !!deviceId,
    },
  );
};

const useMeasurementHistory = (
  deviceId?: string,
  measurementId?: string,
  start?: Date,
  end?: Date,
  readingCount?: number,
  interval?: string,
) => {
  return useQuery(
    ["measurement-history", deviceId, measurementId],
    () => {
      const service = new DashboardApiService();
      return service.getMeasurementHistoricalData(
        deviceId as string,
        measurementId as string,
        start,
        end,
        readingCount,
        interval,
      );
    },
    {
      enabled: !!deviceId && !!measurementId,
    },
  );
};

const useDeviceMeasurementsHistory = (
  deviceId?: string,
  measurementIds: string[] = [],
  start?: Date,
  end?: Date,
  readingCount?: number,
  interval?: string,
) => {
  if (!measurementIds) {
    return [];
  }

  return useQueries(
    measurementIds.map((measurementId: string) => {
      return {
        queryKey: ["measurement-history", deviceId, measurementId],
        queryFn: () => {
          const service = new DashboardApiService();
          return service.getMeasurementHistoricalData(
            deviceId as string,
            measurementId as string,
            start,
            end,
            readingCount,
            interval,
          );
        },
      };
    }),
  );
};

const useCurrentMeasurement = (deviceId: string, measurementNames: MeasurementName[]) => {
  return useQuery(
    ["current-measurement", deviceId, measurementNames],
    () => {
      const service = new DashboardApiService();
      return service.getCurrentMeasurements(measurementNames, deviceId);
    },
    {
      enabled: measurementNames && measurementNames.length > 0,
    },
  );
};

export const DEFAULT_DEVICE_LIST_PAGE_SIZE = 10;

const useInfiniteDeviceListQuery = ({
  limit = DEFAULT_DEVICE_LIST_PAGE_SIZE,
  filter = undefined,
  sortBy = undefined,
  sortDirection = undefined,
  favourites = undefined,
  includeSensorDetails = undefined,
  includeMeasurements = undefined,
  includeDevicesWithoutLocation = undefined,
}: IListDevicesParams = {}) => {
  return useInfiniteQuery(
    ["device list", filter, sortBy, sortDirection, favourites],
    ({ pageParam = 1 }) => {
      const offset = (pageParam - 1) * limit;
      const service = new DashboardApiService();
      return service.listDevices({
        limit,
        offset,
        filter,
        sortBy,
        sortDirection,
        favourites,
        includeDevicesWithoutLocation,
        includeMeasurements,
        includeSensorDetails,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length !== 0 ? nextPage : undefined;
      },
    },
  );
};

const useFavoriteDashboards = () => {
  return useQuery(
    ["favorite-dashboards"],
    () => {
      const service = new DashboardApiService();
      return service.getFavoriteDashboards();
    },
    {
      staleTime: 0,
    },
  );
};

const useDevicesLostCommunication = () => {
  return useQuery(
    ["devices-lost-communication"],
    () => {
      const service = new DashboardApiService();
      return service.getDevicesLostCommunication();
    },
    {
      staleTime: 0,
    },
  );
};

const useBatterySohWarning = () => {
  return useQuery(
    ["devices-battery-warning"],
    () => {
      const service = new DashboardApiService();
      return service.getDevicesBatterySohWarning();
    },
    {
      staleTime: 0,
    },
  );
};

export {
  useDashboardDevice,
  useDeviceList,
  useDeviceMeasurementsHistory,
  useMeasurementHistory,
  useCurrentMeasurement,
  useInfiniteDeviceListQuery,
  useFavoriteDashboards,
  useDevicesLostCommunication,
  useBatterySohWarning,
};
