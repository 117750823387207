import { ApiService } from "./ApiService";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { Alert } from "../../models/api/Alert";
import { AlertRecipient } from "../../models/api/AlertRecipient";
import { DeviceIds, DeviceUser } from "../../models/api/DeviceUser";
import { PagedResults } from "../../models/api/PagedResults";
import { TableFilters } from "../../models/api/TableFilters";
import { AlertRule, AlertRuleCondition } from "../../models/api/AlertRule";
import { Promise } from "bluebird";
import { DeviceAlert } from "../../models/api/DeviceAlert";
import { Device } from "../../models/api/Device";
import { Guid } from "js-guid";
import { AlertFeed } from "../../models/api/AlertFeed";
import { AlertNotification, AlertPriority } from "../../models/api/AlertNotification";
import { DeviceAlertHistory } from "../../models/api/DeviceAlertHistory";

export class AlertApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "alerts");

    this.list = this.list.bind(this);
  }

  getById(alertId: string) {
    return new Promise<Alert>((resolve, reject, cancel) => {
      this.get(`${alertId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            name: d.NAME,
            lastFired: d.LASTFIRE ? moment(new Date(d.LASTFIRED)) : undefined,
            createdAt: d.CREATEDAT ? moment(new Date(d.CREATEDAT)) : undefined,
            orgId: d.ORGID,
            period: d.PERIOD,
            createdBy: d.CREATEDBY,
          } as Alert);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  list(tableFilters?: TableFilters): Promise<PagedResults<Alert>> {
    return new Promise<PagedResults<Alert>>((resolve, reject, cancel) => {
      this.get(
        `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const alerts = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                lastFired: d.LASTFIRED ? moment.utc(d.LASTFIRED).local() : undefined,
                createdAt: d.CREATEDAT ? moment.utc(d.CREATEDAT).local() : undefined,
                orgId: d.ORGID,
                period: d.PERIOD,
              }) as Alert,
          );
          resolve({
            items: alerts,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  create(newAlert: Alert): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("", newAlert, cancel).done((result) => {
        if (result.success) {
          resolve(result.newAlertId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  update(editAlert: Alert): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`${editAlert.id}`, editAlert, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  remove(alertId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${alertId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  snooze(alertId: string, snoozeUntil: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`snooze/${alertId.toLowerCase()}/${snoozeUntil}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  unsnooze(alertId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`unsnooze/${alertId.toLowerCase()}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listRecipients(alertId: string): Promise<AlertRecipient[]> {
    return new Promise<AlertRecipient[]>((resolve, reject, cancel) => {
      this.get(`${alertId}/recipients`, cancel).done((result) => {
        if (result.success) {
          const AlertRecipients = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                alertId: d.ALERTID,
                recipient: d.RECIPIENT,
                type: d.TYPE,
                lastFired: d.LASTFIRE ? moment(new Date(d.LASTFIRED)) : undefined,
                createdAt: d.CREATEDAT ? moment(new Date(d.CREATEDAT)) : undefined,
                createdBy: d.CREATEDBY,
              }) as AlertRecipient,
          );
          resolve(AlertRecipients);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  addRecipients(newAlertRecipient: AlertRecipient): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(`${newAlertRecipient.alertId}/recipient`, newAlertRecipient, cancel).done(
        (result) => {
          if (result.success) {
            resolve(result.alertId as string);
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        },
      );
    });
  }

  removeRecipients(recipientId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`recipient/${recipientId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  sendTestMessage(alertId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.get(`${alertId}/sendTestMessage`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  filterDevices(orgId: string, deviceIds: DeviceIds, attributeName: string): Promise<DeviceUser[]> {
    return new Promise<DeviceUser[]>((resolve, reject, cancel) => {
      this.post(`${orgId}/filterDevices/${attributeName}`, deviceIds, cancel).done((result) => {
        if (result.success) {
          if (result.data.length > 0) {
            const DeviceUsers = result.data.map(
              (d: any) =>
                ({
                  deviceId: d.DEVICEID,
                  name: d.DEVICENAME,
                }) as DeviceUser,
            );
            resolve(DeviceUsers);
          } else {
            resolve([]);
          }
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getAlertRule(alertRuleId: string): Promise<AlertRule> {
    return new Promise<AlertRule>((resolve, reject, cancel) => {
      this.get(`alertRule/${alertRuleId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          const alertRule: AlertRule = {
            id: d.ID,
            selectionCriteria: d.SELECTIONCRITERIA,
            message: d.MESSAGE != "" ? d.MESSAGE : undefined,
            lastFired:
              d.LASTFIRED != undefined && d.LASTFIRED != ""
                ? moment(new Date(d.LASTFIRED)).local()
                : undefined,
            deviceIds: d.DEVICEIDS,
            deviceTypeIds: d.DEVICETYPEIDS,
            sortOrder: d.SORTORDER,
            tagIds: d.TAGIDS,
          };
          resolve(alertRule);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAlertRules(alertId: string): Promise<AlertRule[]> {
    return new Promise<AlertRule[]>((resolve, reject, cancel) => {
      this.get(`${alertId}/alertRules`, cancel).done((result) => {
        if (result.success) {
          const alertRules: AlertRule[] = result.data.map((d: any) => ({
            id: d.ID,
            selectionCriteria: d.SELECTIONCRITERIA,
            message: d.MESSAGE != "" ? d.MESSAGE : undefined,
            lastFired:
              d.LASTFIRED != undefined && d.LASTFIRED != ""
                ? moment(new Date(d.LASTFIRED)).local()
                : undefined,
            deviceIds: d.DEVICEIDS,
            deviceTypeIds: d.DEVICETYPEIDS,
            priority: d.PRIORITY,
            sortOrder: d.SORTORDER,
            tagIds: d.TAGIDS,
          }));
          resolve(alertRules);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  addAlertRule(alertId: string, alertRule: AlertRule): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `${alertId}/alertRule`,
        {
          selectionCriteria: alertRule.selectionCriteria,
          message: alertRule.message,
          deviceIds: alertRule.deviceIds.join(","),
          deviceTypeIds: alertRule.deviceTypeIds.join(","),
          tagIds: alertRule.tagIds.join(","),
          sortOrder: alertRule.sortOrder,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.newRuleId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateAlertRule(alertRuleId: string, alertRule: AlertRule): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `alertRule/${alertRuleId}`,
        {
          deviceIds: alertRule.deviceIds.join(","),
          deviceTypeIds: alertRule.deviceTypeIds.join(","),
          message: alertRule.message,
          priority: alertRule.priority,
          selectionCriteria: alertRule.selectionCriteria,
          sortOrder: alertRule.sortOrder,
          tagIds: alertRule.tagIds.join(","),
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteAlertRule(alertRuleId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`alertRule/${alertRuleId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getAlertRuleCondition(alertRuleConditionId: string): Promise<AlertRuleCondition> {
    return new Promise<AlertRuleCondition>((resolve, reject, cancel) => {
      this.get(`alertRuleCondition/${alertRuleConditionId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          const alertRuleCondition: AlertRuleCondition = {
            id: d.ID,
            measurementId: d.MEASUREMENTID,
            measurementFieldId: d.MEASUREMENTFIELDID,
            operator: d.OPERATOR,
            value: d.VALUE,
          };
          resolve(alertRuleCondition);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAlertRuleConditions(alertRuleId: string): Promise<AlertRuleCondition[]> {
    return new Promise<AlertRuleCondition[]>((resolve, reject, cancel) => {
      this.get(`${alertRuleId}/alertRuleConditions`, cancel).done((result) => {
        if (result.success) {
          const alertRuleConditions: AlertRuleCondition[] = result.data.map((d: any) => ({
            id: d.ID,
            measurementId: d.MEASUREMENTID,
            measurementFieldId: d.MEASUREMENTFIELDID,
            operator: d.OPERATOR,
            value: d.VALUE,
          }));
          resolve(alertRuleConditions);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  addAlertRuleCondition(
    alertRuleId: string,
    alertRuleCondition: AlertRuleCondition,
  ): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `${alertRuleId}/alertRuleCondition`,
        {
          measurementId: alertRuleCondition.measurementId,
          measurementFieldId: alertRuleCondition.measurementFieldId,
          operator: alertRuleCondition.operator,
          value: alertRuleCondition.value,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.newRuleConditionId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateAlertRuleCondition(
    alertRuleConditionId: string,
    alertRuleCondition: AlertRuleCondition,
  ): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `alertRuleCondition/${alertRuleConditionId}`,
        {
          measurementId: alertRuleCondition.measurementId,
          measurementFieldId: alertRuleCondition.measurementFieldId,
          operator: alertRuleCondition.operator,
          value: alertRuleCondition.value,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteAlertRuleCondition(alertRuleConditionId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`alertRuleCondition/${alertRuleConditionId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  sendTextMessage(phoneNumbers: string[]): Promise<string[]> {
    return new Promise<string[]>((resolve, _reject, cancel) => {
      const numberData = phoneNumbers;
      if (phoneNumbers == undefined || phoneNumbers.length == 0) {
        resolve(["No phone number entered."]);
        return;
      }

      const postData = { PhoneNumbers: numberData };
      this.post(`sendTextMessage`, postData, cancel).done((resultInner) => {
        if (resultInner.success) {
          //resolve();
          resolve(resultInner.data);
        } else {
          resolve([resultInner.errorMessage]);
        }
      });
    });
  }

  emailTestMessage(emails: string[]): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      const emailData = emails;
      if (emailData == undefined || emailData.length == 0) {
        resolve();
        return;
      }
      const postData = { emailsAddress: emailData };
      this.post(`emailTestMessage`, postData, cancel).done((resultInner) => {
        if (resultInner.success) {
          resolve();
        } else {
          console.error(resultInner.errorMessage);
          reject(resultInner.message);
        }
      });
    });
  }

  getDevicesInAlert(): Promise<DeviceAlert[]> {
    return new Promise<DeviceAlert[]>((resolve, reject, cancel) => {
      this.get("deviceAlerts", cancel).done((result) => {
        if (result.success) {
          if (result.data.length > 0) {
            const alerts = result.data.map(
              (item: any) =>
                ({
                  Id: item.ID,
                  AlertRuleConditionId: item.ALERTRULECONDITIONID ?? "",
                  AlertName: item.ALERTNAME,
                  DeviceId: item.DEVICEID,
                  DeviceName: item.DEVICENAME,
                  DeviceTypeId: item.DEVICETYPEID ?? "",
                  OrgId: item.ORGID,
                  DateTriggered: item.DATETRIGGERED
                    ? moment(new Date(item.DATETRIGGERED))
                    : undefined,
                  MeasurementValueCaused: item.MEASUREMENTVALUECAUSED,
                  MeasurementId: item.MEASUREMENTID,
                  MeasurementFieldId: item.MEASUREMENTFIELDID,
                  RawValue: item.RAWVALUE ?? "",
                  Operator: item.OPERATOR ?? "",
                  RuleValue: item.RULEVALUE ?? "",
                  EmailNotified: item.EMAILNOTIFIED ?? "",
                  PhoneNotified: item.PHONENOTIFIED ?? "",
                  AlertState: item.ALERTSTATE ?? false,
                  RecordUpdated: item.RECORDUPDATED
                    ? moment(new Date(item.RECORDUPDATED))
                    : undefined,
                  OrganizationDashboardId: item.ORGANIZATIONDASHBOARDID ?? "",
                  Priority: item.PRIORITY,
                }) as DeviceAlert,
            );
            resolve(alerts);
          } else {
            resolve([]);
          }
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getAlertsFeed(page: number): Promise<DeviceAlert[]> {
    return new Promise<DeviceAlert[]>((resolve, reject, cancel) => {
      this.get(`alertsFeed/${page}`, cancel).done((result) => {
        if (result.success) {
          if (result.data.length > 0) {
            const alerts = result.data.map(
              (item: any) =>
                ({
                  Id: item.ID,
                  AlertRuleConditionId: item.ALERTRULECONDITIONID ?? "",
                  AlertName: item.ALERTNAME,
                  DeviceId: item.DEVICEID ?? "",
                  DeviceName: item.DEVICENAME,
                  DeviceTypeId: item.DEVICETYPEID ?? "",
                  OrgId: item.ORGID,
                  DateTriggered: item.DATETRIGGERED
                    ? moment(new Date(item.DATETRIGGERED))
                    : undefined,
                  MeasurementValueCaused: item.MEASUREMENTVALUECAUSED,
                  MeasurementId: item.MEASUREMENTID,
                  MeasurementFieldId: item.MEASUREMENTFIELDID,
                  RawValue: item.RAWVALUE ?? "",
                  Operator: item.OPERATOR ?? "",
                  RuleValue: item.RULEVALUE ?? "",
                  EmailNotified: item.EMAILNOTIFIED ?? "",
                  PhoneNotified: item.PHONENOTIFIED ?? "",
                  AlertState: item.ALERTSTATE ?? false,
                  RecordUpdated: item.RECORDUPDATED
                    ? moment(new Date(item.RECORDUPDATED))
                    : undefined,
                  OrganizationDashboardId: item.ORGANIZATIONDASHBOARDID ?? "",
                  Priority: item.PRIORITY,
                }) as DeviceAlert,
            );
            resolve(alerts);
          } else {
            resolve([]);
          }
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listDevicesForAlert(alertId: string): Promise<Device[]> {
    return new Promise<Device[]>((resolve, reject, cancel) => {
      this.get(`${alertId}/devices`, cancel).done((result) => {
        if (result.success) {
          const devices: Device[] = result.data.map((d: any) => ({
            id: d.ID,
            shortId: d.SHORTID,
            serialNumber: d.SERIALNUMBER,
            deviceTypeId: d.DEVICETYPEID,
            name: d.NAME,
            tagIds: d.DEVICETAGS ? d.DEVICETAGS.split(", ") : [],
          }));
          resolve(devices);
        } else {
          console.error(result);
          reject(result.message);
        }
      });
    });
  }

  // Alert Notifications
  listAlertNotifications(): Promise<AlertNotification[]> {
    return new Promise<AlertNotification[]>((resolve, reject, cancel) => {
      this.get("alertNotifications", cancel).done((result) => {
        if (result.success) {
          if (result.data.length > 0) {
            const alertNotification = result.data.map(
              (item: any) =>
                ({
                  DeviceAlertInfoId: item.DEVICEALERTINFOID,
                  DeviceId: item.DEVICEID,
                  DeviceAlertId: item.DEVICEALERTID,
                  DeviceName: item.DEVICENAME,
                  AlertName: item.ALERTNAME,
                  Priority: item.PRIORITY === "" ? null : item.PRIORITY,
                  AlertMessage: item.ALERTMESSAGE,
                  // Latitude: item.,
                  // Longitude: item.,
                  IsSeen: item.ISSEEN == 1,
                  IsActioned: item.ISACTIONED,
                  IsInAlertState: item.ISINALERTSTATE ?? false,
                  LastTriggered: moment(new Date(item.LASTTRIGGERED)),
                }) as AlertNotification,
            );
            resolve(alertNotification);
          } else {
            resolve([]);
          }
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAlertsFeed(alertNameFilter?: string): Promise<AlertFeed[]> {
    return new Promise<AlertFeed[]>((resolve, reject, cancel) => {
      let path = "alertsFeed";
      if (alertNameFilter) path += `?alertNameFilter=${alertNameFilter}`;

      this.get(path, cancel).done((result) => {
        if (result.success) {
          if (result.data.length > 0) {
            const alertFeed = result.data.map(
              (item: any) =>
                ({
                  AlertMessage: item.ALERTMESSAGE,
                  AlertName: item.ALERTNAME,
                  DeviceAlertId: item.DEVICEALERTID,
                  DeviceAlertInfoId: item.DEVICEALERTINFOID,
                  DeviceId: item.DEVICEID,
                  DeviceName: item.DEVICENAME,
                  IsActioned: item.ISACTIONED,
                  IsInAlertState: item.ISINALERTSTATE ?? false,
                  IsSeen: item.ISSEEN == 1,
                  LastTriggered: moment(new Date(item.LASTTRIGGERED)),
                  MeasurementValueCaused: item.MEASUREMENTVALUECAUSED,
                  Priority: item.PRIORITY,
                  TagNames: item.TAGNAMES,
                  SnoozeUntil: item.SNOOZEUNTIL,
                }) as AlertFeed,
            );
            resolve(alertFeed);
          } else {
            resolve([]);
          }
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listDeviceAlertHistory(params: {
    alertNameFilter?: string;
    startingAt?: string;
    endingAt?: string;
    deviceId?: string;
    filter?: string;
    isAsc?: boolean;
    limit: number;
    offset: number;
    orderBy?: string;
  }): Promise<DeviceAlertHistory[]> {
    return new Promise<DeviceAlertHistory[]>((resolve, reject, cancel) => {
      let path = "";

      if (params.deviceId) {
        path = `/${params.deviceId}`;
        delete params.deviceId;
      }

      const query = Object.entries(params)
        .filter(([_, value]) => value)
        .map((entry) => entry.join("="))
        .join("&");

      this.get(`history${path}?${query}`, cancel).done((result) => {
        if (!result.success) {
          console.error(result.errorMessage);
          return reject(result.message);
        }

        resolve(
          result.data.map(
            (item: any) =>
              ({
                alertId: item["ALERTID"],
                alertMessage: item["ALERTMESSAGE"],
                alertName: item["ALERTNAME"],
                dateTriggered: item["ALERTSTATE"] ? moment(item["DATETRIGGERED"]) : undefined,
                deviceAlertHistoryId: item["DEVICEALERTHISTORYID"],
                deviceId: item["DEVICEID"],
                deviceName: item["DEVICENAME"],
                measurementValueCaused: item["MEASUREMENTVALUECAUSED"]
                  .split(/\s+/)
                  .slice(1, 3)
                  .join(" "),
                message: item["ALERTMESSAGE"],
                priority: item["PRIORITY"],
                stoppedAlerting: item["ALERTSTATE"] ? undefined : moment(item["DATETRIGGERED"]),
                tagNames: item["TAGNAMES"],
              }) as DeviceAlertHistory,
          ),
        );
      });
    });
  }

  updateFirstSeen(deviceAlertInfoId: Guid, deviceId: Guid): Promise<string | undefined> {
    return new Promise<string | undefined>((resolve, reject, cancel) => {
      super
        .post(`${deviceAlertInfoId}/alertNotification/firstSeen`, { deviceId: deviceId }, cancel)
        .done((result) => {
          if (result.success) {
            resolve(result.token);
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        });
    });
  }

  updateIsActioned(deviceAlertInfoId: Guid, deviceId: Guid): Promise<string | undefined> {
    return new Promise<string | undefined>((resolve, reject, cancel) => {
      super
        .post(`${deviceAlertInfoId}/alertNotification/isActioned`, { deviceId: deviceId }, cancel)
        .done((result) => {
          if (result.success) {
            resolve(result.token);
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        });
    });
  }

  updateAlertNotificationPriority(
    deviceAlertInfoId: Guid | string,
    deviceId: Guid | string,
    priority: AlertPriority | number | null | undefined,
  ): Promise<string | undefined> {
    return new Promise<string | undefined>((resolve, reject, cancel) => {
      super
        .post(`${deviceAlertInfoId}/alertNotification/priority`, { deviceId, priority }, cancel)
        .done((result) => {
          if (result.success) {
            resolve(result.token);
          } else {
            reject(result.message);
          }
        });
    });
  }

  getAlertsNameByOrgId(orgId: string): Promise<Alert[]> {
    return new Promise<Alert[]>((resolve, reject, cancel) => {
      this.get(`getAlertsNameByOrgId/${orgId}`, cancel).done((result) => {
        if (result.success) {
          if (result.data.length > 0) {
            const alerts = result.data.map(
              (item: any) =>
                ({
                  id: item.ID,
                  name: item.NAME,
                  orgId: undefined,
                  period: undefined,
                  lastFired: undefined,
                  createdAt: undefined,
                  createdBy: undefined,
                }) as Alert,
            );
            resolve(alerts);
          } else {
            resolve([]);
          }
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
