import { ApiService } from "./ApiService";
import { DeviceType, DeviceTypeAttribute } from "../../models/api/DeviceType";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { TableFilters } from "../../models/api/TableFilters";
import { PagedResults } from "../../models/api/PagedResults";
import { Promise } from "bluebird";

export class DeviceTypesApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "deviceTypes");

    this.list = this.list.bind(this);
  }

  getById(deviceTypeId: string) {
    return new Promise<DeviceType>((resolve, reject, cancel) => {
      this.get(`${deviceTypeId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            name: d.NAME,
            description: d.DESCRIPTION,
            notes: d.NOTES,
            manafacturerName: d.MANAFACTURERNAME,
            deviceCount: d.DEVICECOUNT,
            shopUrl: d.SHOPURL,
            imagePath: d.IMAGEPATH,
            createdAt:
              d.CREATEDAT != undefined && d.CREATEDAT != ""
                ? moment(new Date(d.CREATEDAT)).local()
                : undefined,
            removedAt:
              d.REMOVEDAT != undefined && d.REMOVEDAT != ""
                ? moment(new Date(d.REMOVEDAT)).local()
                : undefined,
            measurementIds: d.MEASUREMENTIDS,
            dashboardTemplateIds: d.DASHBOARDTEMPLATEIDS,
          } as DeviceType);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  list(
    showAttributes: boolean = false,
    tableFilters?: TableFilters,
  ): Promise<PagedResults<DeviceType>> {
    return new Promise<PagedResults<DeviceType>>((resolve, reject, cancel) => {
      this.get(
        `?showAttributes=${showAttributes ?? false}${
          tableFilters != undefined ? "&" + tableFilters.toQueryParameterString() : ""
        }`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const deviceTypes = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                description: d.DESCRIPTION,
                notes: d.NOTES,
                manafacturerName: d.MANAFACTURERNAME,
                deviceCount: d.DEVICECOUNT,
                shopUrl: d.SHOPURL,
                imagePath: d.IMAGEPATH,
                createdAt:
                  d.CREATEDAT != undefined && d.CREATEDAT != ""
                    ? moment(new Date(d.CREATEDAT)).local()
                    : undefined,
                removedAt:
                  d.REMOVEDAT != undefined && d.REMOVEDAT != ""
                    ? moment(new Date(d.REMOVEDAT)).local()
                    : undefined,
                measurementIds: d.MEASUREMENTIDS ?? [],
                attributes:
                  showAttributes && d.ATTRIBUTES != undefined
                    ? d.ATTRIBUTES.map(
                        (a: any) =>
                          ({
                            id: a.ID,
                            name: a.NAME,
                            dataType: a.DATATYPE,
                            deivceTypeId: a.DEVICETYPEID,
                            minimum: a.MINIMUM,
                            maximum: a.MAXIMUM,
                            formula: a.FORMULA,
                            hasRange: a.HASRANGE,
                            unit: a.UNIT,
                          }) as DeviceTypeAttribute,
                      )
                    : [],
              }) as DeviceType,
          );
          resolve({
            items: deviceTypes,
            totalCount: result.totalCount,
          } as PagedResults<DeviceType>);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
  listForOrg(): Promise<DeviceType[]> {
    return new Promise<DeviceType[]>((resolve, reject, cancel) => {
      this.get("listForOrg", cancel).done((result) => {
        if (result.success) {
          const deviceTypes = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                description: d.DESCRIPTION,
                notes: d.NOTES,
                manafacturerName: d.MANAFACTURERNAME,
                deviceCount: d.DEVICECOUNT,
                shopUrl: d.SHOPURL,
                imagePath: d.IMAGEPATH,
                createdAt:
                  d.CREATEDAT != undefined && d.CREATEDAT != ""
                    ? moment(new Date(d.CREATEDAT)).local()
                    : undefined,
                removedAt:
                  d.REMOVEDAT != undefined && d.REMOVEDAT != ""
                    ? moment(new Date(d.REMOVEDAT)).local()
                    : undefined,
              }) as DeviceType,
          );
          resolve(
            deviceTypes.sort((a: DeviceType, b: DeviceType) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }),
          );
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  update(deviceTypeId: string, deviceType: DeviceType): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.post(`${deviceTypeId}`, deviceType).done((result) => {
        if (result.success) {
          resolve(result.deviceTypeId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  uploadImage(deviceTypeId: string, fileName: string, fileContents: string): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `${deviceTypeId}/uploadImage`,
        {
          fileName: fileName,
          imageFile: fileContents,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.data as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteImage(deviceTypeId: string) {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${deviceTypeId}/deleteImage`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  create(newDeviceType: DeviceType): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("", newDeviceType, cancel).done((result) => {
        if (result.success) {
          resolve(result.deviceTypeId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  remove(deviceTypeId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${deviceTypeId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  addMeasurement(deviceTypeId: string, measurementId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`${deviceTypeId}/measurements`, { measurementId: measurementId }, cancel).done(
        (result) => {
          if (result.success) {
            resolve();
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        },
      );
    });
  }

  deleteMeasurement(deviceTypeId: string, measurementId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${deviceTypeId}/measurements/${measurementId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  addDashboardTemplate(deviceTypeId: string, templateId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`${deviceTypeId}/dashboardTemplates`, { templateId: templateId }, cancel).done(
        (result) => {
          if (result.success) {
            resolve();
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        },
      );
    });
  }

  deleteDashboardTemplate(deviceTypeId: string, measurementId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${deviceTypeId}/dashboardTemplates/${measurementId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
