import { ReactElement, Ref, RefObject } from "react";
import "./tableFilterStyles.scss";
import { TableSelectedItem } from "../../models/api/TableSelectedItem";
import { MultiGrid, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import { ColumnDefinition } from "./FilterTable";
import { TableTH } from "./TableTH";
import { TableTD } from "./TableTD";
import { Guid } from "js-guid";
import { GridCellProps } from "react-virtualized/dist/es/Grid";
import { TableLoading } from "./TableLoading";
import { TableEmpty } from "./TableEmpty";

interface IProps<T> {
  tableId: string;
  visibleColumns: ColumnDefinition<T>[];
  items: T[] | undefined;

  allowBulkUpdate: boolean;
  isLoading: boolean;
  noButtons: boolean | undefined;

  cache: CellMeasurerCache;
  bulkItems: TableSelectedItem<T>[];
  filterValue: string;
  noItemsText: ReactElement | undefined;
  // expandedRowIndexes: number[];
  selectAllCheckbox: RefObject<HTMLInputElement>;
  multiGridRef: Ref<MultiGrid>;

  getColumnCount: (visibleColumns: ColumnDefinition<T>[]) => number;
  getFixedColumnCount: () => number;
  getSortIconClass: (colDef: ColumnDefinition<T>) => string;
  bulkActions: ReactElement | (() => ReactElement | undefined) | undefined;
  expandableRowFunction: ((item: T, index: number) => JSX.Element) | undefined;
  rowClickFunction?: (item: T, index: number) => void;

  handleSort: (def: ColumnDefinition<T>, index: number) => void;
  // handleRowClick: (item: T, index: number) => void,
  handleSelectSingleRow: (event: any) => void;
  handleSelectAll: (event: any) => void;
}

export function VirtualizeTable<T>(props: IProps<T>) {
  function renderCell(
    { columnIndex, key, rowIndex, style, parent }: GridCellProps,
    visibleColumns: ColumnDefinition<T>[],
    items?: T[],
  ) {
    // If there are no items return
    if (!items || items.length <= 0) return undefined;

    const columnDefinitions = [...visibleColumns];

    if (props.allowBulkUpdate && props.bulkActions) {
      columnDefinitions.unshift({
        header: (
          <div className="form-group checkbox">
            <input
              ref={props.selectAllCheckbox}
              type="checkbox"
              checked={props.bulkItems.every((b) => b.isChecked)}
              onClick={props.handleSelectAll}
              onChange={() => {}}
            />
          </div>
        ),
        valueFunction: () => (
          <div className="form-group checkbox">
            <input
              type="checkbox"
              onClick={props.handleSelectSingleRow}
              onChange={() => {}}
              checked={props.bulkItems[rowIndex - 1].isChecked}
              value={rowIndex - 1}
            />
          </div>
        ),
      });
    }

    // This must stay here because?..................
    const columnDefinition = columnDefinitions[columnIndex];
    const item = items[rowIndex - 1];
    let content: JSX.Element;

    if (rowIndex === 0) {
      content = (
        <TableTH
          key={props.tableId + "th" + columnIndex}
          columnDefinition={columnDefinition}
          columnNum={columnIndex}
          isVirtualize={true}
          getSortIconClass={props.getSortIconClass}
          handleSort={props.handleSort}
        />
      );
    } else {
      content = (
        <TableTD
          key={props.tableId + "ex" + rowIndex + "-" + columnIndex}
          item={item}
          columnDefinition={columnDefinition}
          itemIndex={rowIndex}
          colDefIndex={columnIndex}
          isRowClickable={
            props.expandableRowFunction != undefined || props.rowClickFunction != undefined
          }
          isVirtualize={true}
          showTitle={true}
        />
      );
    }
    return (
      <CellMeasurer
        cache={props.cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div style={style}>{content}</div>
      </CellMeasurer>
    );
  }

  const GetTableComponent = () => {
    if (props.isLoading) {
      return <TableLoading isLoading={props.isLoading} />;
    } else if (!!props.items && props.items.length > 0) {
      return (
        <AutoSizer className="tf-table">
          {({ height, width }) => (
            <MultiGrid
              ref={props.multiGridRef}
              id={new Guid().toString()}
              cellRenderer={(gcProps) => renderCell(gcProps, props.visibleColumns, props.items)}
              columnWidth={(params) => {
                const columnCount = props.getColumnCount(props.visibleColumns);
                const buttonOffset = props.noButtons ? 0 : 1;
                const nonButtonColumnCount = columnCount - buttonOffset;
                let totalColumnWidth = 0;

                for (let i = 0; i < columnCount; i++) {
                  totalColumnWidth += props.cache.columnWidth({ index: i });
                }

                //-18px for scrollbar, bit hacky, maybe not ideal
                const remainingWidth = width - totalColumnWidth - 18;

                if (params.index > buttonOffset - 1 && remainingWidth > 0) {
                  return props.cache.columnWidth(params) + remainingWidth / nonButtonColumnCount;
                } else {
                  return props.cache.columnWidth(params);
                }
              }}
              rowHeight={props.cache.rowHeight}
              deferredMeasurementCache={props.cache}
              columnCount={props.getColumnCount(props.visibleColumns)}
              fixedColumnCount={props.getFixedColumnCount()}
              fixedRowCount={1}
              width={width}
              height={height}
              rowCount={props.items?.length != undefined ? props.items.length + 1 : 0}
            />
          )}
        </AutoSizer>
      );
    } else {
      return (
        <TableEmpty
          tableId={props.tableId}
          isVirtualize={true}
          visibleColumns={props.visibleColumns}
          filterValue={props.filterValue}
          noItemsText={props.noItemsText}
          hasCheckbox={props.allowBulkUpdate && !!props.bulkActions}
          selectAllCheckbox={props.selectAllCheckbox}
          getSortIconClass={props.getSortIconClass}
          handleSelectAll={props.handleSelectAll}
          handleSort={props.handleSort}
        />
      );
    }
  };

  return (
    <div className="tf-content-wrapper">
      <GetTableComponent />
    </div>
  );
}
