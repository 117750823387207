const COLDFUSION = "cf";
const COLDFUSIONHOST = "cf-host";
const DOTNET = ".net";

const endPoints: Record<string, Record<string, string>> = {
  localhost: {
    cf: "http://localhost:8500/rest/modusense",
    "cf-host": "http://localhost:8500",
    ".net": "http://localhost:5249",
    inmarsat: "https://inmarsat-api.fwdp-staging.com",
  },
  "portal.staging.freewave.com": {
    cf: "https://spa.fwdp-staging.com/rest/modusense",
    "cf-host": "https://spa.fwdp-staging.com",
    ".net": "https://api-v2.fwdp-staging.com",
    inmarsat: "https://inmarsat-api.fwdp-staging.com",
  },
  "spa-app.fwdp-staging.com": {
    cf: "https://ecs-app.fwdp-staging.com/rest/modusense",
    "cf-host": "https://ecs-app.fwdp-staging.com",
    ".net": "https://api2.fwdp-staging.com",
    inmarsat: "https://inmarsat-api.fwdp-staging.com",
  },
  "spa-app.fwdp.io": {
    cf: "https://ecs-app.fwdp.io/rest/modusense",
    "cf-host": "https://ecs-app.fwdp.io",
    ".net": "https://api2.fwdp.io",
    inmarsat: "https://inmarsat-api.fwdp.io",
  },
  "portal-cloudfront.staging.freewave.com": {
    cf: "https://spa.fwdp-staging.com/rest/modusense",
    "cf-host": "https://spa.fwdp-staging.com",
    ".net": "https://api-v2.fwdp-staging.com",
    inmarsat: "https://inmarsat-api.fwdp-staging.com",
  },
  "portal.prod.modusense.com": {
    cf: "https://spa-prod.fwdp.io/rest/modusense",
    "cf-host": "https://spa-prod.fwdp.io",
    ".net": "https://api-v2.fwdp.io",
    inmarsat: "https://inmarsat-api.fwdp.io",
  },
  "portal.freewave.com": {
    cf: "https://spa-prod.fwdp.io/rest/modusense",
    "cf-host": "https://spa-prod.fwdp.io",
    ".net": "https://api-v2.fwdp.io",
    inmarsat: "https://inmarsat-api.fwdp.io",
  },
  "reinke.fwdp.io": {
    cf: "https://spa-prod.fwdp.io/rest/modusense",
    "cf-host": "https://spa-prod.fwdp.io",
    ".net": "https://api-v2.fwdp.io",
    inmarsat: "https://inmarsat-api.fwdp.io",
  },
  "inertia.fwdp.io": {
    cf: "https://spa-prod.fwdp.io/rest/modusense",
    "cf-host": "https://spa-prod.fwdp.io",
    ".net": "https://api-v2.fwdp.io",
    inmarsat: "https://inmarsat-api.fwdp.io",
  },
  "portal.inertiadata.com": {
    cf: "https://spa-prod.fwdp.io/rest/modusense",
    "cf-host": "https://spa-prod.fwdp.io",
    ".net": "https://api-v2.fwdp.io",
    inmarsat: "https://inmarsat-api.fwdp.io",
  },
};

export default class ConfigHelper {
  static getColdFusionEndpoint() {
    return ConfigHelper.getConfig(COLDFUSION);
  }
  static getColdFusionHost() {
    return ConfigHelper.getConfig(COLDFUSIONHOST);
  }
  static getDotNetEndpointV2() {
    return ConfigHelper.getConfig(DOTNET);
  }
  static getInmarsatEndpoint = () => ConfigHelper.getConfig("inmarsat");

  static getConfig(config: string) {
    const [host] = window.location.host.split(":");

    if (!(host in endPoints)) {
      throw new Error(`No config found for ${host}`);
    }
    if (!("cf" in endPoints[host])) {
      throw new Error(`no ColdFusion config entry found for ${host}`);
    }
    return endPoints[host][config];
  }
}
