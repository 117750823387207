import { RouteComponentProps } from "react-router-dom";
import { lazy } from "react";
import { RouteConfig } from "../../AuthenticatedApp";

const AdminDevicesPage = lazy(() =>
  import("./devices/AdminDevicesPage").then(({ AdminDevicesPage }) => ({
    default: AdminDevicesPage,
  })),
);
const OrganizationPage = lazy(() =>
  import("./organizations/OrganizationPage").then(({ OrganizationPage }) => ({
    default: OrganizationPage,
  })),
);
const AdminUsersPage = lazy(() =>
  import("./users/AdminUsersPage").then(({ AdminUsersPage }) => ({ default: AdminUsersPage })),
);
const OrganizationsPage = lazy(() =>
  import("./organizations/OrganizationsPage").then(({ OrganizationsPage }) => ({
    default: OrganizationsPage,
  })),
);
const ImportPage = lazy(() =>
  import("./import/ImportPage").then(({ ImportPage }) => ({ default: ImportPage })),
);

const AdminFirmwarePage = lazy(() =>
  import("./firmware/AdminFirmwarePage").then(({ AdminFirmwarePage }) => ({
    default: AdminFirmwarePage,
  })),
);
const AdminFirmwareReleasesPage = lazy(() =>
  import("./firmware/AdminFirmwareReleasesPage").then(({ AdminFirmwareReleasesPage }) => ({
    default: AdminFirmwareReleasesPage,
  })),
);
const AdminDevicePage = lazy(() =>
  import("./devices/AdminDevicePage").then(({ AdminDevicePage }) => ({ default: AdminDevicePage })),
);

export const AdminRoutes: RouteConfig[] = [
  {
    path: "/go/admin/users/:orgId",
    component: (props: RouteComponentProps<any>) => <AdminUsersPage {...props} />,
  },
  {
    path: "/go/admin/users",
    component: (props: RouteComponentProps<any>) => <AdminUsersPage {...props} />,
  },
  {
    path: "/go/admin/organization/:orgId",
    title: "Organization",
    component: (props: RouteComponentProps<any>) => <OrganizationPage {...props} />,
  },
  {
    path: "/go/admin/organizations",
    title: "Organizations",
    component: (props: RouteComponentProps<any>) => <OrganizationsPage {...props} />,
  },
  {
    path: "/go/admin/organizations/create",
    title: "Organizations",
    component: (props: RouteComponentProps<any>) => <OrganizationsPage {...props} />,
  },
  {
    path: "/go/admin/devices/:orgId/org",
    component: (props: RouteComponentProps<any>) => <AdminDevicesPage {...props} />,
  },
  {
    path: "/go/admin/devices/:deviceId",
    title: "Edit Device",
    component: (props: RouteComponentProps<any>) => <AdminDevicePage {...props} />,
  },
  {
    path: "/go/admin/devices",
    component: (props: RouteComponentProps<any>) => <AdminDevicesPage {...props} />,
  },
  {
    path: "/go/admin/firmware/:firmwareAppId/releases",
    component: (props: RouteComponentProps<any>) => <AdminFirmwareReleasesPage {...props} />,
  },
  {
    path: "/go/admin/firmware",
    component: (props: RouteComponentProps<any>) => (
      <AdminFirmwarePage {...props} pageTitle="Firmwares" showHeader={true} />
    ),
  },
  {
    path: "/go/admin/import/:dataType",
    title: "Data Import",
    component: (props: RouteComponentProps<any>) => <ImportPage {...props} />,
  },
];
