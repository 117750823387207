import { Fragment, ReactElement, RefObject } from "react";
import "./tableFilterStyles.scss";
import { TableSelectedItem } from "../../models/api/TableSelectedItem";
import { ColumnDefinition } from "./FilterTable";
import { TableTH } from "./TableTH";
import { TableTD } from "./TableTD";
import { TableLoading } from "./TableLoading";
import { TableEmpty } from "./TableEmpty";

interface IProps<T> {
  tableId: string;

  visibleColumns: ColumnDefinition<T>[];
  items: T[] | undefined;

  allowBulkUpdate: boolean;
  isLoading: boolean;
  filterValue: string;
  noItemsText: ReactElement | undefined;
  bulkItems: TableSelectedItem<T>[];
  expandedRowIndexes: number[];
  selectAllCheckbox: RefObject<HTMLInputElement>;

  getSortIconClass: (colDef: ColumnDefinition<T>) => string;
  bulkActions: ReactElement | (() => ReactElement | undefined) | undefined;
  expandableRowFunction: ((item: T, index: number) => JSX.Element) | undefined;
  rowClickFunction?: (item: T, index: number) => void;

  handleSort: (def: ColumnDefinition<T>, index: number) => void;
  handleRowClick: (item: T, index: number) => void;
  handleSelectSingleRow: (event: any) => void;
  handleSelectAll: (event: any) => void;

  hideHeader?: boolean;
  removeBorder?: boolean;
}

export function NonVirtualizeTable<T>(props: IProps<T>) {
  return (
    <div
      key={props.tableId + " non-virtualized-table"}
      className={`tf-content-wrapper ${props.removeBorder ? "remove-border" : ""} tf-scrollable`}
    >
      {/* Loading Spinner maybe unnecessary in virtualize table? */}
      {/* When loading */}
      <TableLoading isLoading={props.isLoading} />

      {/* The Rows */}
      {!!props.items && (
        <table className="tf-table">
          {!props.hideHeader && (
            <thead className="tf-thead">
              <tr>
                {/* Checkbox Input for all */}
                {props.bulkItems.length > 0 && props.allowBulkUpdate && props.bulkActions && (
                  <th>
                    <div className="form-group checkbox">
                      <input
                        ref={props.selectAllCheckbox}
                        type="checkbox"
                        checked={props.bulkItems.every((b) => b.isChecked)}
                        onClick={props.handleSelectAll}
                      />
                    </div>
                  </th>
                )}

                {/* Row Title */}
                {props.visibleColumns.map((colDef, index) => (
                  <TableTH
                    key={props.tableId + "th" + index}
                    columnDefinition={colDef}
                    columnNum={index}
                    isVirtualize={false}
                    getSortIconClass={props.getSortIconClass}
                    handleSort={props.handleSort}
                  />
                ))}
              </tr>
            </thead>
          )}
          <tbody className={`tf-tbody ${props.removeBorder ? "remove-border" : ""}`}>
            {props.items.map((item, itemIndex) => (
              <Fragment key={props.tableId + "tr" + itemIndex}>
                <tr onClick={() => props.handleRowClick(item, itemIndex)}>
                  {/* Checkbox Input for each rows */}
                  {props.bulkItems.length > 0 && props.allowBulkUpdate && props.bulkActions && (
                    <td key={props.tableId + "td-c" + itemIndex}>
                      <div className="form-group checkbox">
                        <input
                          type="checkbox"
                          onClick={props.handleSelectSingleRow}
                          onChange={() => {}}
                          checked={props.bulkItems[itemIndex].isChecked}
                          value={itemIndex}
                        />
                      </div>
                    </td>
                  )}

                  {/* Row Value */}
                  {props.visibleColumns.map((colDef, colDefIndex) => (
                    <TableTD
                      key={props.tableId + "ex" + itemIndex + "-" + colDefIndex}
                      item={item}
                      columnDefinition={colDef}
                      itemIndex={itemIndex}
                      colDefIndex={colDefIndex}
                      isRowClickable={
                        props.expandableRowFunction != undefined ||
                        props.rowClickFunction != undefined
                      }
                      isVirtualize={false}
                    />
                  ))}
                </tr>
                {props.expandableRowFunction != undefined &&
                  props.expandedRowIndexes.includes(itemIndex) && (
                    <tr
                      className="tf-expanded-tr"
                      key={props.tableId + "tf-expanded-tr" + itemIndex}
                    >
                      <td colSpan={props.visibleColumns.length}>
                        {props.expandableRowFunction(item, itemIndex)}
                      </td>
                    </tr>
                  )}
              </Fragment>
            ))}
          </tbody>
        </table>
      )}

      {/* If There is no items */}
      {(!props.items || props.items.length <= 0) && (
        <TableEmpty
          tableId={props.tableId}
          isVirtualize={false}
          visibleColumns={props.visibleColumns}
          filterValue={props.filterValue}
          noItemsText={props.noItemsText}
          hasCheckbox={props.allowBulkUpdate && !!props.bulkActions}
          selectAllCheckbox={props.selectAllCheckbox}
          getSortIconClass={props.getSortIconClass}
          handleSelectAll={props.handleSelectAll}
          handleSort={props.handleSort}
        />
      )}
    </div>
  );
}
