import { FC, useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import { ColorVariables } from "../../../pages/simpleapp/components/widgets/WidgetHelper";

type Props = {
  chartId: string;
  lastSeen: string;
  pmValue: number;
};

const AMCAirQualityPM: FC<Props> = ({ chartId, lastSeen, pmValue }) => {
  const [isCritical] = useState<boolean>(false);

  useEffect(() => {
    if (chartId) {
      const root = am5.Root.new(chartId);

      // Set themes https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart https://www.amcharts.com/docs/v5/charts/radar-chart/
      const chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          startAngle: -180,
          endAngle: 0,
          marginTop: 0,
        }),
      );

      // Create axis and its renderer https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
      const axisRenderer = am5radar.AxisRendererCircular.new(root, {
        innerRadius: am5.percent(85),
        // Trick to show only first and last label
        minGridDistance: 100000,
      });
      axisRenderer.grid.template.setAll({
        stroke: root.interfaceColors.get("background"),
        visible: true,
      });
      axisRenderer.labels.template.setAll({
        textType: "radial",
      });

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          min: 0,
          max: 1000,
          strictMinMax: true,
          renderer: axisRenderer,
        }),
      );

      // Add clock hand https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
      const axisDataItem = xAxis.makeDataItem({ value: 0 });
      const clockHand = am5radar.ClockHand.new(root, {
        pinRadius: am5.percent(50),
        radius: am5.percent(92),
        innerRadius: am5.percent(86),
        topWidth: 1,
        bottomWidth: 20,
      });
      const bullet = axisDataItem.set(
        "bullet",
        am5xy.AxisBullet.new(root, {
          sprite: clockHand,
        }),
      );
      xAxis.createAxisRange(axisDataItem);

      const label = chart.radarContainer.children.push(
        am5.Label.new(root, {
          fill: am5.color(ColorVariables.colorBlackMid),
          textAlign: "center",
          fontSize: "2em",
          centerY: am5.p100,
          centerX: am5.p50,
        }),
      );

      bullet.get("sprite").on("rotation", () => {
        const text = Math.round(axisDataItem.get("value") ?? 0).toString();
        const fill = am5.color(ColorVariables.colorPrimary);
        const stroke = am5.color(ColorVariables.colorWhite);

        label.set("text", text);

        clockHand.pin.animate({
          key: "fill",
          to: am5.color(ColorVariables.colorWhite),
          duration: 200,
          easing: am5.ease.out(am5.ease.cubic),
        });

        clockHand.hand.animate({
          key: "fill",
          to: fill,
          duration: 200,
          easing: am5.ease.out(am5.ease.cubic),
        });
        clockHand.hand.animate({
          key: "stroke",
          to: stroke,
          duration: 200,
          easing: am5.ease.out(am5.ease.cubic),
        });
      });

      axisDataItem.animate({
        key: "value",
        to: pmValue,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic),
      });

      chart.bulletsContainer.set("mask", undefined);

      // Create axis ranges bands https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Bands
      const bandsData = [
        {
          title: "[#00 fontWeight: 500; fontSize: 14px;]Good[/]",
          color: "#93C57A",
          lowScore: 0,
          highScore: 50,
        },
        {
          title: "[#00 fontWeight: 500; fontSize: 14px;]Moderate[/]",
          color: "#F0EE67",
          lowScore: 51,
          highScore: 100,
          textAlign: "center",
        },
        {
          title:
            "[#00 fontWeight: 500; fontSize: 12px;]groups\n[/][#00 fontWeight: 500; fontSize: 12px;]sensitive\n[/][#00 fontWeight: 500; fontSize: 12px;]Unhealthy for[/]",
          color: "#E7A46A",
          lowScore: 101,
          highScore: 150,
        },
        {
          title: "[#00 fontWeight: 500; fontSize: 14px;]Unhealthy[/]",
          color: "#E05B5B",
          lowScore: 151,
          highScore: 200,
        },
        {
          title: "[#00 fontWeight: 500; fontSize: 14px;]Very Unhealthy[/]",
          color: "#916299",
          lowScore: 201,
          highScore: 300,
        },
        {
          title: "[#fff fontWeight: 500; fontSize: 14px;]Hazardous[/]",
          color: "#644051",
          lowScore: 301,
          highScore: 1000,
        },
      ];

      am5.array.each(bandsData, (data) => {
        const axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));

        axisRange.setAll({
          value: data.lowScore,
          endValue: data.highScore,
        });

        axisRange.get("axisFill")?.setAll({
          visible: true,
          fill: am5.color(data.color),
          // fillOpacity: 0.5
        });
      });

      // Make stuff animate on load
      chart.appear(1000, 100);

      return () => root.dispose();
    }
  }, [chartId, pmValue]);

  const GetLabelText = () => {
    let labelStatus = "hazardous";
    let labelText = "Hazardous";
    let smallText = "";

    if (pmValue >= 0 && pmValue <= 50) {
      labelStatus = "good";
      labelText = "Good";
    } else if (pmValue >= 51 && pmValue <= 100) {
      labelStatus = "moderate";
      labelText = "Moderate";
    } else if (pmValue >= 101 && pmValue <= 150) {
      labelStatus = "unhealthy-for-sensitive-groups";
      labelText = "Unhealthy";
      smallText = "for sensitive groups";
    } else if (pmValue >= 151 && pmValue <= 200) {
      labelStatus = "unhealthy";
      labelText = "Unhealthy";
    } else if (pmValue >= 201 && pmValue <= 300) {
      labelStatus = "very-unhealthy";
      labelText = "Very unhealthy";
    }

    return (
      <p className="am-chart-label" data-status={labelStatus}>
        {labelText}
        {!!smallText && <span className="text-para-small-bold">{smallText}</span>}
      </p>
    );
  };

  return (
    <>
      <div style={isCritical ? { borderColor: "#E05B5B" } : {}} className="value-card-container">
        <GetLabelText />
        <div className="am-chart-container" id={chartId} />
        <div className="value-card-last-seen-badge">{lastSeen}</div>
      </div>
    </>
  );
};

export default AMCAirQualityPM;
