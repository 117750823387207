import { useQuery } from "react-query";
import { BrandingApiService, defaultBranding } from "../api/BrandingApiService";
let isBrandingError = true;

const useBranding = () => {
  return useQuery(["branding"], () => {
    const service = new BrandingApiService();
    return service.getBranding().catch(() => (isBrandingError = true));
  });
};
const useDomainBranding = () => {
  return useQuery(["domain-branding"], () => {
    const service = new BrandingApiService();
    return service.getDomainBranding();
  });
};

const useBrandName = () => {
  return isBrandingError ? "FreeWave" : defaultBranding!.companyName;
};

export { useBranding, useBrandName, useDomainBranding };
