import { Component, MouseEvent, ReactChild } from "react";
import { LoadingSpinner } from "./LoadingSpinner";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import { Guid } from "js-guid";
import "./buttons/buttonStyles.scss";

export enum ButtonType {
  Fill,
  Outline,
  White,
  Transparent,
  Error,
  Success,
}

interface IProps {
  className?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  content: ReactChild;
  iconClass?: string;
  rightIconClass?: string;
  buttonType?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
  tooltipPosition?: "top" | "right" | "bottom" | "left";
  tooltipDelay?: number;
  small?: boolean;
  type?: "button" | "submit" | "reset";
}

interface IState {
  disabled?: boolean;
}
export class Button extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      disabled: this.props.disabled,
    };
  }

  componentDidMount() {
    $('button[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    if (this.state.disabled != this.props.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
  }

  render() {
    const content = this.props.content;
    const onClick = this.props.onClick;
    const iconClass = this.props.iconClass;
    const buttonType = this.props.buttonType;
    const className = this.props.className;
    const buttonClass: string[] = [];
    buttonClass.push(this.generateButtonClass(buttonType));
    const tooltip = this.props.tooltip;
    const tooltipPosition = this.props.tooltipPosition;
    const id = new Guid().toString();

    return (
      <>
        {tooltip && (
          <>
            {ReactDOM.createPortal(
              <ReactTooltip
                id={id}
                place={tooltipPosition}
                effect="float"
                delayShow={this.props.tooltipDelay}
              >
                {tooltip}
              </ReactTooltip>,
              document.body,
            )}
          </>
        )}
        <button
          data-tip
          data-for={id}
          className={`${buttonClass.join(" ")} ${className}`}
          type={this.props.type || "button"}
          onClick={(ev) => {
            if (onClick) onClick(ev);
          }}
          disabled={this.state.disabled || this.props.loading}
        >
          <span className="d-flex flex-row align-items-center justify-content-center">
            {iconClass && (
              <span className="mr-2">
                <i className={iconClass} />
              </span>
            )}
            {content}
            {this.props.rightIconClass && !this.props.loading && (
              <span className="ml-2">
                <i className={this.props.rightIconClass} />
              </span>
            )}
            {this.props.loading && (
              <span className="ml-2">
                <LoadingSpinner faSizeClass="fa-1x" />
              </span>
            )}
          </span>
        </button>
      </>
    );
  }

  private generateButtonClass(buttonType?: ButtonType) {
    if (!buttonType) buttonType = ButtonType.Fill;

    let buttonClass = "btn-type-";

    switch (buttonType) {
      case ButtonType.Fill:
        buttonClass += "fill";
        break;
      case ButtonType.Outline:
        buttonClass += "outline";
        break;
      case ButtonType.White:
        buttonClass += "white";
        break;
      case ButtonType.Transparent:
        buttonClass += "transparent";
        break;
      case ButtonType.Error:
        buttonClass += "error";
        break;
      case ButtonType.Success:
        buttonClass += "success";
        break;
    }

    if (this.props.small) {
      buttonClass += " sm";
    }

    return buttonClass;
  }
}
