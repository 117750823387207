import { Component } from "react";
import { IconButton } from "../../../components/IconButton";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../../models/api/Role";
import { AlertApiService } from "../../../services/api/AlertApiService";

interface IProps {
  deviceId: string;
  deviceName: string;
  measurement: string;
  measurementName: string;
  onExpandGraph: () => void;
  expandGraphDisabled: boolean;
}
interface IState {
  readAccess: boolean;
  writeAccess: boolean;
}

export class GraphWidgetMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      readAccess: true,
      writeAccess: true,
    };

    this.createAndOpenNewAlert = this.createAndOpenNewAlert.bind(this);
  }

  componentDidMount() {
    const userRole = JwtTokenHelpers.getUserRole();
    const readAccess = (userRole.permissionsAlert & PermissionFlags.Read) != 0; // if true then user has Read
    const writeAccess = (userRole.permissionsAlert & PermissionFlags.Write) != 0; //
    this.setState({
      readAccess,
      writeAccess,
    });
  }

  createAndOpenNewAlert() {
    new AlertApiService()
      .create({
        name: `${this.props.deviceName} - ${this.props.measurement}`,
      })
      .then((res) => {
        const w = window.open(
          `/go/manage/alerts/${res}#deviceId=${this.props.deviceId}&measurement=${this.props.measurement}&deleteOnCancel=true`,
          "_blank",
        );
        if (w != undefined) window.focus();
      });
  }

  render() {
    return (
      <span className="m-n2 dropdown">
        <span
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <IconButton isDark={false} iconClass="fas fa-ellipsis-v" />
        </span>

        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            onClick={this.props.onExpandGraph}
            className={
              "dropdown-item d-flex flex-row align-items-center " +
              (this.props.expandGraphDisabled ? "disabled" : "")
            }
          >
            <i className="fas fa-expand-alt mr-3"></i>
            <span className="flex-grow-1">Expand Graph</span>
          </a>
          {this.state.writeAccess && (
            <a
              onClick={this.createAndOpenNewAlert}
              className="dropdown-item d-flex flex-row align-items-center"
            >
              <i className="fas fa-bell mr-3"></i>
              <span className="flex-grow-1">Create alert</span>
              <i className="fas fa-external-link-alt ml-3 text-sm"></i>
            </a>
          )}
        </div>
      </span>
    );
  }
}
