import { ReactElement, RefObject } from "react";
import "./tableFilterStyles.scss";
import { ColumnDefinition } from "./FilterTable";
import { TableTH } from "./TableTH";

interface IProps<T> {
  tableId: string;
  isVirtualize: boolean;
  visibleColumns: ColumnDefinition<T>[];
  filterValue: string;
  noItemsText: ReactElement | undefined;
  hasCheckbox: boolean;
  selectAllCheckbox: RefObject<HTMLInputElement>;

  getSortIconClass: (colDef: ColumnDefinition<T>) => string;
  handleSelectAll: (event: any) => void;
  handleSort: (def: ColumnDefinition<T>, index: number) => void;
  hideHeader?: boolean;
}

export function TableEmpty<T>(props: IProps<T>) {
  return (
    <>
      {/*/!* If There is no items *!/*/}
      {props.isVirtualize && (
        <table className="tf-table">
          {!props.hideHeader && (
            <thead className="tf-thead">
              <tr>
                {/* Checkbox Input for all */}
                {props.hasCheckbox && (
                  <th>
                    <div className="form-group checkbox">
                      <input
                        ref={props.selectAllCheckbox}
                        type="checkbox"
                        onClick={props.handleSelectAll}
                      />
                    </div>
                  </th>
                )}

                {/* Row Title */}
                {props.visibleColumns.map((colDef, index) => (
                  <TableTH
                    key={props.tableId + "th" + index}
                    columnDefinition={colDef}
                    columnNum={index}
                    isVirtualize={false}
                    getSortIconClass={props.getSortIconClass}
                    handleSort={props.handleSort}
                  />
                ))}
              </tr>
            </thead>
          )}
        </table>
      )}

      {/* No Item Div */}
      <div className="tf-empty-list">
        {props.filterValue
          ? "No items found"
          : props.noItemsText != undefined
            ? props.noItemsText
            : "No items"}
      </div>
    </>
  );
}
