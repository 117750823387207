import { ApiService } from "./ApiService";
import { apiEndPoint } from "../../config";
import { Branding } from "../../models/api/Branding";
import { Promise } from "bluebird";
import { OrganizationTheme } from "../../models/api/OrganizationTheme";
import axios from "axios";
import cSharpApi from "./CSharpApi";

export const defaultBranding = {
  brandName: "FreeWave",
  brandImgSrc: "/images/freewave-logo.png",
  brandIconSrc: "/images/img-fav32.png",
  logoImageUri: "",
  companyName: "FreeWave",
  brandingTagLine: "",
  backgroundColor: "rgb(24, 27, 41)",
};

export class BrandingApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "branding");
  }

  getBranding(): Promise<Branding> {
    return new Promise<Branding>((resolve, reject, cancel) => {
      this.get("", cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          const theme: Branding = {
            id: d.ID,
            companyName: d.COMPANYNAME,
            logoImageUri: d.LOGOIMAGEURI,
            iconImageUri: d.ICONIMAGEURI,
            themeColor: d.THEMECOLOR,
            useDarkText: d.USEDARKTEXT,
          };
          resolve(theme);
        } else {
          reject(result.message);
        }
      });
    });
  }

  getBrandingCSharp(): Promise<OrganizationTheme> {
    const cancelTokenSource = axios.CancelToken.source();
    return new Promise<OrganizationTheme>((resolve, reject) =>
      cSharpApi
        .get(`branding/`, {
          data: {},
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          console.error(err.errorMessage);
          reject(err.message);
        }),
    );
  }

  getDomainBranding(): Promise<Branding> {
    return new Promise<Branding>((resolve, _reject, cancel) => {
      this.get("themeByDomain", cancel).done((result) => {
        let theme: Branding;
        if (result.success) {
          const d = result.data;
          const themeFound = d.ID !== "";
          theme = {
            id: d.ID,
            companyName: themeFound ? d.COMPANYNAME : defaultBranding.brandName,
            logoImageUri: themeFound ? d.LOGOIMAGEURI : defaultBranding.brandImgSrc,
            iconImageUri: themeFound ? d.ICONIMAGEURI : defaultBranding.brandIconSrc,
            themeColor: d.THEMECOLOR,
            useDarkText: d.USEDARKTEXT,
          };
        } else {
          theme = {
            id: "-1",
            companyName: defaultBranding.brandName,
            logoImageUri: defaultBranding.brandImgSrc,
            iconImageUri: defaultBranding.brandIconSrc,
            themeColor: "",
            useDarkText: false,
          };
        }
        resolve(theme);
      });
    });
  }

  deleteTheme(): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete("", cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  update(theme: Branding): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post("", theme, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  uploadImage = (fileName: string, fileContents: string): Promise<string> => {
    return new Promise<string>((_resolve, reject, cancel) => {
      this.post(
        "uploadImage",
        {
          fileName: fileName,
          imageFile: fileContents,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          location.reload(); // have to be hard refresh to clear cache.
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  };

  uploadIcon = (fileName: string, fileContents: string): Promise<string> => {
    return new Promise<string>((_resolve, reject, cancel) => {
      this.post(
        "uploadIcon",
        {
          fileName: fileName,
          imageFile: fileContents,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          location.reload(); // have to be hard refresh to clear cache.
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  };

  deleteImage = () => {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete("deleteImage", cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  };

  deleteIcon = () => {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete("deleteIcon", cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  };
}
