import { FC, useEffect, useState } from "react";
import { User } from "../../../models/api/User";
import { FormGroup } from "../../../components/forms/FormGroup";
import { ReactSelect, StateType } from "../../../components/inputs/ReactSelect";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { Button, ButtonType } from "../../../components/Button";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../../models/api/Role";
import { useAccessGroups } from "../../../services/context/AccessGroupApiServiceContext";
import { useRoles } from "../../../services/context/RolesApiServiceContext";
import { useUpdatePermissions } from "../../../services/context/UsersApiServiceContext";
import { useAuth } from "../../../contexts/auth/auth.context";

type Props = {
  user: User;
  updateAccessGroups: (accessGroups: string[]) => void;
};

const UserPermissions: FC<Props> = ({ user, updateAccessGroups }) => {
  const {
    isSuccess: accessGroupsLoaded,
    isLoading: isAccessGroupLoading,
    data: accessGroups,
  } = useAccessGroups();
  const { isSuccess: rolesLoaded, isLoading: isRolesLoading, data: roles } = useRoles();
  const { token, setToken } = useAuth();
  const [accessGroupOptions, setAccessGroupOptions] = useState<{ label: any; value: any }[]>([]);
  const [roleOptions, setRoleOptions] = useState<{ label: any; value: any }[]>([]);
  const [selectedAccessGroups, setSelectedAccessGroups] = useState<string[]>(
    user.accessGroupIds ?? [],
  );
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>(user.roleId);
  const [roleReadAccess, setRoleReadAccess] = useState<boolean>(false);
  const [roleWriteAccess, setRoleWriteAccess] = useState<boolean>(false);
  const [accessGroupReadAccess, setAccessGroupReadAccess] = useState<boolean>(false);
  const [accessGroupWriteAccess, setAccessGroupWriteAccess] = useState<boolean>(false);
  const { mutate: doUpdatePermissions } = useUpdatePermissions({
    onSuccess: () => {
      updateAccessGroups(selectedAccessGroups);
      $.notification("show", {
        type: "message",
        title: undefined,
        message: "User permission was successfully updated",
        toastOnly: true,
      });
    },
    onError: (err) => {
      $.notification("show", {
        type: "error",
        title: undefined,
        message: err,
        toastOnly: true,
      });
    },
  });
  let userRole;

  useEffect(() => {
    if (token) {
      userRole = JwtTokenHelpers.getUserRole();
      setRoleReadAccess((userRole.permissionsRole & PermissionFlags.Read) != 0);
      setRoleWriteAccess((userRole.permissionsRole & PermissionFlags.Write) != 0);
      setRoleReadAccess((userRole.permissionsRole & PermissionFlags.Read) != 0);
      setAccessGroupWriteAccess((userRole.permissionsAccessGroup & PermissionFlags.Write) != 0);
      setAccessGroupReadAccess((userRole.permissionsAccessGroup & PermissionFlags.Read) != 0);
    }
  }, [token, setToken]);

  useEffect(() => {
    if (accessGroupsLoaded && accessGroupReadAccess) {
      setAccessGroupOptions(
        accessGroups!.map((ag) => {
          return {
            value: ag.id,
            label: ag.groupName,
          };
        }),
      );
    }
  }, [accessGroups, accessGroupReadAccess]);

  useEffect(() => {
    if (rolesLoaded && roleReadAccess) {
      setRoleOptions(
        roles!.map((r) => ({
          value: r.id,
          label: r.name,
        })),
      );
    }
  }, [roles, roleReadAccess]);

  const updatePermissions = () => {
    /* Update user*/
    doUpdatePermissions({
      accessGroupsId: selectedAccessGroups,
      accountId: user.id!,
      roleId: selectedRoleId,
    });
  };

  if (isAccessGroupLoading || isRolesLoading) return <></>;

  return (
    <FormGroup title="Permissions">
      <form id="user-permissions-form" action="javascript:void(0);">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            {accessGroupOptions && (
              <ReactSelect
                idName="accessGroups"
                labelString="Access Groups"
                options={accessGroupOptions}
                inputValue={selectedAccessGroups}
                onChange={(option: any) => {
                  setSelectedAccessGroups(option != null ? option.map((o: any) => o.value) : []);
                }}
                isSearchable={true}
                isClearable={false}
                isMulti={true}
                placeholder="Select access groups..."
                inputState={accessGroupWriteAccess ? StateType.Default : StateType.Disabled}
              />
            )}
            {!accessGroupOptions && <LoadingSkeleton width="100%" height="38px" />}
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            {roleOptions && (
              <ReactSelect
                idName="roles"
                labelString="Role"
                options={roleOptions}
                inputValue={selectedRoleId}
                onChange={(option: any) => setSelectedRoleId(option.value)}
                isSearchable={true}
                isClearable={false}
                placeholder="Select role..."
                inputState={roleWriteAccess ? StateType.Default : StateType.Disabled}
              />
            )}
            {!roleOptions && <LoadingSkeleton width="100%" height="38px" />}
          </div>
        </div>

        <div className="d-flex flex-row mt-3">
          <span className="flex-grow-0 flex-md-grow-1"></span>
          <Button
            buttonType={ButtonType.Success}
            content="Update Permissions"
            onClick={() => updatePermissions()}
            disabled={!roleWriteAccess && !accessGroupWriteAccess}
          />
        </div>
      </form>
    </FormGroup>
  );
};

export default UserPermissions;
