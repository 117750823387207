import { Component, FormEvent } from "react";
import { Button, ButtonType } from "./Button";
import { LoadingSpinner } from "./LoadingSpinner";
import { cfHost } from "../config";

interface IProps {
  title: string;
  imgSrc?: string;
  bodyContent: JSX.Element;
  onUpload: (...args: any[]) => Promise<any>;
  onUploadPayload: (file: any, fileData: string) => any;
  onDelete?: () => Promise<any>;
  key: string;
}

interface IState {
  imgSrc?: string;
  imgUploading: boolean;
}

export class ImageUpload extends Component<IProps, IState> {
  private uuid: string;
  private allowedExtensions: string[];

  constructor(props: IProps) {
    super(props);

    this.uuid = Math.round(Math.random() * (1000000 - 1) + 1).toString();

    this.allowedExtensions = [".jpg", ".png", ".svg", ".gif"];

    this.state = {
      imgSrc: props.imgSrc,
      imgUploading: false,
    };
  }

  onImageFileChange = (event: FormEvent<HTMLInputElement>) => {
    // @ts-expect-error
    if (event.target.files) {
      // @ts-expect-error
      const file = event.target.files[0];

      const fileExtension = "." + file.name.split(".").pop();

      if (!this.allowedExtensions.includes(fileExtension.toLowerCase())) {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "Selected file must be an image type",
          toastOnly: true,
        });

        return;
      }

      this.setState(
        {
          imgSrc: URL.createObjectURL(file),
        },
        () => this.handleImageUpload(file),
      );
    }
  };

  handleImageUpload = (file: File) => {
    const reader = new FileReader(); // instance of the FileReader
    this.setState({ imgUploading: true });

    reader.onloadend = () => {
      // set image data as background of div
      const fileData = (reader.result as string).split(",")[1];

      this.props
        .onUpload(...Object.values(this.props.onUploadPayload(file, fileData)))
        .then(() => window.location.reload())
        .catch(() => {
          $.notification("show", {
            type: "error",
            title: undefined,
            message: "An error occured while uploading your image. Please try again later",
            toastOnly: true,
          });
        });
    };
    reader.readAsDataURL(file); // read the local file
  };

  onImageFileDelete = () => {
    this.props.onDelete &&
      this.props
        .onDelete()
        .then(() => {
          $.notification("show", {
            type: "message",
            title: undefined,
            message: "Image removed successfully",
            toastOnly: true,
          });
          this.setState({ imgSrc: undefined }, () => window.location.reload());
        })
        .catch(() => {
          $.notification("show", {
            type: "error",
            title: undefined,
            message: "An error occured while removing the image. Please try again later.",
            toastOnly: true,
          });
        });
  };

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.imgSrc != prevProps.imgSrc) {
      this.setState({
        imgSrc: this.props.imgSrc,
      });
    }
  }

  render() {
    return (
      <div key={this.props.key}>
        <h5 className="mt-4">{this.props.title ?? "Image Upload"}</h5>
        {this.props.bodyContent}
        <div className="row">
          <div className="col-6 mt-3">
            <input
              id={this.uuid}
              type="file"
              accept="image/*"
              className="inputUploadImage d-none"
              onChange={this.onImageFileChange}
            />
            <span id="branding-upload-image-button">
              <Button
                content="Upload Image"
                iconClass="fa-regular fa-arrow-up-from-bracket"
                buttonType={ButtonType.Outline}
                onClick={() => $("input#" + this.uuid).trigger("click")}
              />
            </span>
            <span className="mr-2" />
            <span id="branding-remove-image-button">
              <Button
                content="Remove Image"
                disabled={!this.state.imgSrc}
                buttonType={ButtonType.Error}
                onClick={this.onImageFileDelete}
              />
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <div className="max-width-container-md">
              {this.state.imgUploading ? (
                <LoadingSpinner />
              ) : (
                this.state.imgSrc && (
                  <img style={{ maxWidth: "100%" }} src={`${cfHost}${this.state.imgSrc}`} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
