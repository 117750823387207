import { useState, useCallback } from "react";
import axios from "axios";
import cSharpApi from "../../../services/api/CSharpApi";
import {
  CSharpModelListResponse,
  FetchedData,
  INITIAL_CSHARP_MODEL_LIST_RESPONSE_STATE,
  INITIAL_FETCHED_DATA_STATE,
} from "../../../hooks/HookHelper";

interface DevicesForSelectInput {
  name?: string;
  id: string;
  serialNumber: string;
  isFavorite: boolean;
  isActive: boolean;
}

interface DevicesForSelectInputData extends CSharpModelListResponse<DevicesForSelectInput[]> {}

const INITIAL_DEVICES_FOR_SELECT_OPT_STATE: FetchedData<DevicesForSelectInputData> = {
  ...INITIAL_FETCHED_DATA_STATE,
  data: {
    ...INITIAL_CSHARP_MODEL_LIST_RESPONSE_STATE,
    data: [],
  },
};

const useDevice = () => {
  const [devicesForSelectOpt, setDevicesForSelectOpt] = useState<
    FetchedData<DevicesForSelectInputData>
  >(INITIAL_DEVICES_FOR_SELECT_OPT_STATE);
  const cancelTokenSource = axios.CancelToken.source();

  const getDevicesForSelectOpt = useCallback(async () => {
    setDevicesForSelectOpt({
      ...INITIAL_DEVICES_FOR_SELECT_OPT_STATE,
      isLoading: true,
    });

    try {
      const getDevices = await cSharpApi.get(`devices/forSelectInput/`, {
        cancelToken: cancelTokenSource.token,
      });

      const data = await getDevices.data;

      if (data) {
        setDevicesForSelectOpt({
          data,
          isLoading: false,
          hasError: false,
          errorMessage: "",
          message: data.data.message,
        });
      }
    } catch (error) {
      let errorMessage = "fetching data aborted";

      if (axios.isCancel(error)) {
        console.log("fetching data aborted");
      } else {
        errorMessage = error instanceof Error ? error.message : "Unknown error";
        console.log("An error occurred", error);
      }

      setDevicesForSelectOpt({
        ...INITIAL_DEVICES_FOR_SELECT_OPT_STATE,
        errorMessage,
      });
    }
    return () => cancelTokenSource.cancel();
  }, []);

  return { getDevicesForSelectOpt, devicesForSelectOpt };
};

export default useDevice;
