import { FC, useEffect, useState } from "react";
import * as d3 from "d3";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
//Type of the chart
import * as am5xy from "@amcharts/amcharts5/xy";
import { MinMaxUnit } from "../../../pages/simpleapp/components/SimpleAppTemplate";
import {
  ColorVariables,
  getChartDateTime,
} from "../../../pages/simpleapp/components/widgets/WidgetHelper";
import { WaterVolumeAndRainHistoricalData } from "../../../pages/simpleapp/components/widgets/historic/hooks/useHistoricalWaterVolumeAndRain";

type DataModel = {
  time: string;
  volume: number;
  rain: number;
};

type Props = {
  data: WaterVolumeAndRainHistoricalData[];
  volumeLabel: string;
  rainLabel: string;
  chartId: string;
};

const AMCWaterVolumeAndRain: FC<Props> = ({ data, volumeLabel, rainLabel, chartId }) => {
  const [normalizedData, setNormalizedData] = useState<DataModel[]>([]);

  // Default
  const [volumeData, setVolumeData] = useState<MinMaxUnit>({ min: 0, max: 0, unit: "L" });
  const [rainData, setRainData] = useState<MinMaxUnit>({ min: 0, max: 100, unit: "mm" });

  useEffect(() => {
    if (data) {
      setVolumeData({ min: 0, max: data[0].maxVolume, unit: data[0].volumeDisplayUnit });
      setRainData({ min: 0, max: 100, unit: data[0].rainDisplayUnit });
      const dataArr: DataModel[] = [];

      data.map((datum) => {
        dataArr.push({
          time: getChartDateTime(datum.time),
          volume: datum.volume,
          rain: datum.rain,
        });
      });

      setNormalizedData(dataArr);
    }
  }, [data]);

  useEffect(() => {
    if (!!chartId && !!normalizedData && normalizedData.length > 0) {
      const root = am5.Root.new(chartId);

      // Set themes (https://www.amcharts.com/docs/v5/concepts/themes/)
      root.setThemes([am5themes_Animated.new(root)]);

      // Create Chart (https://www.amcharts.com/docs/v5/charts/xy-chart/)
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: false,
          wheelY: "zoomX",
          layout: root.verticalLayout,
        }),
      );

      // Add cursor: In here you can configure how the cursor is displayed and how it behaves on the chart.
      const cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        }),
      );
      cursor.lineY.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });
      cursor.lineX.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });

      // Create axes https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      // For the X-Axis
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "time",
          marginTop: 15,
          maxDeviation: 0,
          renderer: am5xy.AxisRendererX.new(root, {}),
        }),
      );
      xAxis.data.setAll(normalizedData);

      //### For the Volume ###//
      //Left Axis
      const columnYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: volumeData.max,
          numberFormat: `#,###'${volumeData.unit}'`,
          // maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );
      columnYAxis.get("renderer").grid.template.set("forceHidden", true);

      //Column Bar - the data
      const columnVolume = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: volumeLabel,
          xAxis: xAxis,
          yAxis: columnYAxis,
          valueYField: "volume",
          categoryXField: "time",
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]${volumeLabel}[/]: {valueY}${volumeData.unit} \n\n[bold]DateTime[/]: {categoryX}`,
            dy: -5,
          }),
        }),
      );
      columnVolume.columns.template.setAll({
        stroke: am5.color(ColorVariables.colorBlueWaterLight),
        fill: am5.color(ColorVariables.colorBlueWaterLight),
        width: am5.percent(60),
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
      });
      columnVolume.data.setAll(normalizedData);
      columnVolume.appear(1000);

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      columnVolume.set("setStateOnChildren", true);
      columnVolume.states.create("hover", {});

      columnVolume.mainContainer.set("setStateOnChildren", true);
      columnVolume.mainContainer.states.create("hover", {});

      columnVolume.columns.template.states.create("hover", {
        stroke: am5.color(ColorVariables.colorBlueWater),
        strokeWidth: 2,
      });

      //### For the Rain ###//
      //Left Axis
      const lineYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          numberFormat: `#,###'${rainData.unit}'`,
          // maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, { opposite: true }),
        }),
      );
      lineYAxis.get("renderer").grid.template.set("forceHidden", true);

      //Line Series - the data
      const lineRain = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: rainLabel,
          xAxis: xAxis,
          yAxis: lineYAxis,
          valueYField: "rain",
          categoryXField: "time",
          curveFactory: d3.curveBumpX,
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]${rainLabel}[/]: {valueY}${rainData.unit} \n\n[bold]DateTime[/]: {categoryX}`,
            dy: -5,
          }),
        }),
      );
      lineRain.strokes.template.setAll({
        stroke: am5.color(ColorVariables.colorBlueWaterLight),
        strokeWidth: 1,
      });
      lineRain.data.setAll(normalizedData);
      lineRain.appear(1000);

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      lineRain.set("setStateOnChildren", true);
      lineRain.states.create("hover", {});

      lineRain.mainContainer.set("setStateOnChildren", true);
      lineRain.mainContainer.states.create("hover", {});

      lineRain.strokes.template.states.create("hover", {
        strokeWidth: 3,
      });

      // Add scrollbar
      const scrollbar = chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
          start: 0.8,
          height: 10,
          marginTop: 15,
        }),
      );
      scrollbar.thumb.setAll({
        fill: am5.color(ColorVariables.colorPlaceholder),
      });
      scrollbar.startGrip.setAll({
        visible: false,
      });
      scrollbar.endGrip.setAll({
        visible: false,
      });
      chart.bottomAxesContainer.children.push(scrollbar);

      // Add Legends
      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      );

      // Make series change state when legend item is hovered
      legend.itemContainers.template.states.create("hover", {});

      legend.itemContainers.template.events.on("pointerover", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.hover();
      });
      legend.itemContainers.template.events.on("pointerout", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.unhover();
      });

      legend.data.setAll(chart.series.values);

      // Make stuff animate on load (https://www.amcharts.com/docs/v5/concepts/animations/)
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }
  }, [chartId, normalizedData]);

  return <div className="am-chart-container" id={chartId} />;
};

export default AMCWaterVolumeAndRain;
