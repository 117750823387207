import { FC } from "react";
import moment from "moment";

import { DeviceLostCommunication } from "../../../models/api/DashboardDevice";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import DateHelpers from "../../../helpers/DateHelpers";
import CardContainer from "./card/CardContainer";
import { TableFilters } from "../../../models/api/TableFilters";

import "./devices-lost-communication-styles.scss";

type Props = {
  dashboardDevices: DeviceLostCommunication[] | undefined;
  isLoading: boolean;
};

const DevicesLostCommunication: FC<Props> = ({ dashboardDevices, isLoading }) => {
  if (isLoading || !dashboardDevices) {
    return <LoadingSkeleton width="100%" height="4rem" />;
  }

  const getDeviceNameByDevice = (dashboardDevice: DeviceLostCommunication) => {
    return (
      <>
        <a
          className="widget-table-item-row"
          href={`/go/dashboard/device/${dashboardDevice.deviceId}`}
          title="View dashboard"
          target="_blank"
        >
          {dashboardDevice.name}
        </a>
      </>
    );
  };

  const getIconAndName = (dashboardDevice: DeviceLostCommunication) => {
    const deviceName = getDeviceNameByDevice(dashboardDevice);

    return (
      <div className="device-lost-communication-wrapper">
        <div className="device-lost-communication-content-icon">
          <i className="fas fa-comment-slash" />
        </div>
        {deviceName}
      </div>
    );
  };

  const getSignalLost = (dashboardDevice: DeviceLostCommunication) => {
    return (
      <div className="device-lost-communication-wrapper">
        <div className="device-lost-communication-content-date">
          {DateHelpers.formatMomentAsTimeSince(dashboardDevice.timestamp)}
        </div>
      </div>
    );
  };

  return (
    <CardContainer
      title={`${dashboardDevices.length}`}
      subTitle="Signal Loss"
      iconClass="fas fa-comment-slash"
    >
      <FilterTable<DeviceLostCommunication>
        tableId="device-lots-communication-table"
        items={dashboardDevices}
        paginate={dashboardDevices.length > 5}
        paginationOptions={[
          { label: "5", value: 5 },
          { label: "10", value: 10 },
        ]}
        columnDefinitions={[
          {
            header: "Device Name",
            valueFunction: (item) => getIconAndName(item),
            sortable: true,
            databaseColumn: "name",
            sortFunction: (a, b) => {
              if (!a.name) return -1;
              if (!b.name) return 1;
              return a.name.localeCompare(b.name);
            },
          },
          {
            header: "Signal Lost",
            valueFunction: (item) => getSignalLost(item),
            sortable: true,
            databaseColumn: "timestamp",
            sortFunction: (a, b) => {
              return moment(a.timestamp).isAfter(moment(b.timestamp)) ? -1 : 1;
            },
          },
        ]}
        initialTableFilters={new TableFilters("timestamp", false)}
        hasSmallFooter
        hideFooterSelection
        removeBorder
      />
    </CardContainer>
  );
};

export default DevicesLostCommunication;
