import { FC } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { FilterTable } from "../../../../components/filterTable/FilterTable";
import { TableFilters } from "../../../../models/api/TableFilters";
import { DeviceInAlertGrouped } from "../DevicesInAlert";

import "./devices-in-alert-grouped-styles.scss";

type Props = {
  deviceInAlertGrouped: DeviceInAlertGrouped[];
};

const FilterTableAlertsGrouped: FC<Props> = ({ deviceInAlertGrouped }) => {
  const getIconAndName = (alert: DeviceInAlertGrouped) => {
    return (
      <div className="device-in-alert-grouped-wrapper">
        <div className="device-in-alert-grouped-content-device-name">
          <Link
            className="widget-table-item-row"
            title={alert.alertName}
            to={{
              pathname: "/go/manage/alerts",
              state: {
                groupedAlertName: alert.alertName,
              },
            }}
          >
            {alert.alertName}
          </Link>
        </div>
      </div>
    );
  };

  const getAlertsCount = (alert: DeviceInAlertGrouped) => {
    return (
      <div className="device-in-alert-grouped-content-device-count">
        <span>{alert.devicesAffected}</span>
      </div>
    );
  };

  return (
    <FilterTable<DeviceInAlertGrouped>
      tableId="device-in-alert-grouped-table"
      items={deviceInAlertGrouped}
      paginate={deviceInAlertGrouped.length > 5}
      paginationOptions={[
        { label: "5", value: 5 },
        { label: "10", value: 10 },
      ]}
      columnDefinitions={[
        {
          header: "Alert Name",
          valueFunction: (item: DeviceInAlertGrouped) => getIconAndName(item),
          sortable: true,
          databaseColumn: "alertName",
          sortFunction: (a: DeviceInAlertGrouped, b: DeviceInAlertGrouped) => {
            if (!a.alertName) return -1;
            if (!b.alertName) return 1;
            return a.alertName.localeCompare(b.alertName);
          },
        },
        {
          header: "Devices Alerting",
          valueFunction: (item: DeviceInAlertGrouped) => getAlertsCount(item),
          sortable: true,
          databaseColumn: "devicesAffected",
          sortFunction: (a: DeviceInAlertGrouped, b: DeviceInAlertGrouped) => {
            if (!a.devicesAffected) return -1;
            if (!b.devicesAffected) return 1;
            return a.devicesAffected.toString().localeCompare(b.devicesAffected.toString());
          },
        },
        {
          header: "Last Fired",
          valueFunction: (item: DeviceInAlertGrouped) => item.lastFiredFormatted,
          sortable: true,
          databaseColumn: "lastFired",
          sortFunction: (a: DeviceInAlertGrouped, b: DeviceInAlertGrouped) => {
            return moment(a.lastFired).isAfter(moment(b.lastFired)) ? 1 : -1;
          },
        },
      ]}
      initialTableFilters={new TableFilters("lastFired", false)}
      hasSmallFooter
      hideFooterSelection
      removeBorder
    />
  );
};

export default FilterTableAlertsGrouped;
