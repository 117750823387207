import { FC, useEffect, useState } from "react";
import "../cardStyles.scss";

export enum ValueType {
  Currency,
}

type Props = {
  cardValue: number;
  cardValueDisplay: string;

  cardCaption: string;
  iconClass: string | JSX.Element;
  isCritical: boolean;

  iconPos?: "left" | "right";
  lastSeen?: string;
  valueType?: ValueType;

  // Icon to open DevicePowerPerformance modal from Current Battery Card
  callbackButton?: JSX.Element;
};

/**
 * @param cardValue @param cardValue (number) is the value of the card. It is used if further calculation is required.
 * @param cardValueDisplay (string) is the value to display on the card. It is for manipulating the cardValue-
 * Concat with the unit or decimal values or add format in front of the cardValue.
 * @param cardCaption (string) a caption for the card.
 * @param iconClass (string | JSX.Element) is the icon to display on the card.
 * @param isCritical (boolean) if the value is in critical. This changes the border color to red.
 * @param iconPos ("left" | "right") is where to place the icon. The default is "right".
 * @param lastSeen
 * @param valueType
 * @param callbackButton
 * @constructor
 **/
const ValueCard: FC<Props> = ({
  cardValue,
  cardValueDisplay,

  cardCaption,
  iconClass,
  isCritical,

  iconPos,
  lastSeen,
  valueType,

  callbackButton,
}) => {
  const [chartIcon, setChartIcon] = useState<JSX.Element>(<></>);
  const [displayValueClass, setDisplayValueClass] = useState<string>("fg-color-black-dark");

  useEffect(() => getSettings(), []);

  function getSettings() {
    if (iconClass) {
      const isCurrency: boolean = valueType == ValueType.Currency;
      if (isCurrency) {
        if (cardValue < 0) setDisplayValueClass("fg-color-error");
      }

      if (typeof iconClass == "string") {
        const _icon = <i className={iconClass} />;
        setChartIcon(_icon);
        return;
      } else {
        setChartIcon(iconClass);
      }
    }
  }

  const GetCaption = () => {
    if (callbackButton !== undefined) {
      return (
        <p className="value-card-dets value-card-dets-button-container">
          {cardCaption}
          {callbackButton}
        </p>
      );
    }

    return <p className="value-card-dets value-card-dets-button-container">{cardCaption}</p>;
  };

  const GetLastSeenBadge = () => {
    if (lastSeen) {
      return <div className="value-card-last-seen-badge">{lastSeen}</div>;
    }
    return <></>;
  };

  return (
    <div style={isCritical ? { borderColor: "#E05B5B" } : {}} className="value-card-container">
      <div className="value-card-main-info">
        <section className="value-card-details">
          <p className={`value-card-num ${displayValueClass}`}>{cardValueDisplay}</p>
          <GetCaption />
        </section>

        <section
          style={{ order: `${!!iconPos && iconPos == "left" ? "-1" : ""}` }}
          className="value-card-icon"
        >
          {chartIcon}
        </section>
      </div>

      <GetLastSeenBadge />
    </div>
  );
};

export default ValueCard;
