import { Component } from "react";
import { Alert } from "../../../models/api/Alert";
import { AlertApiService } from "../../../services/api/AlertApiService";
import { Button, ButtonType } from "../../../components/Button";
import DateHelpers from "../../../helpers/DateHelpers";
import { IconButton } from "../../../components/IconButton";
import { Dialog, DialogSize } from "../../../components/Dialog";
import { Link } from "react-router-dom";
import { TableFilters } from "../../../models/api/TableFilters";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../../models/api/Role";
import { Input } from "../../../components/inputs/Input";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import { LayoutContext, LayoutContextType } from "../../../contexts/layout/layout-context";
import { RouteComponentProps } from "react-router";
import PageHeaderNew, {
  PageHeaderButton,
} from "../../../components/layout/pageHeader/PageHeaderNew";
import { PageContainerWrapper } from "../../../components/layout";
import { MIN_SCREEN_WIDTH } from "../../../components/layout/LayoutHelper";

interface IProps extends RouteComponentProps {}
interface IState {
  openNewDialog: () => void;
  closeNewDialog: () => void;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
  newAlertName: string;
  deleteAlertId?: string;
  reloadTable: () => void;
  readAccess: boolean;
  writeAccess: boolean;
  innerWidth: number;
}
export class AlertsPage extends Component<IProps, IState> {
  static contextType = LayoutContext;

  constructor(props: IProps) {
    super(props);

    this.state = {
      openNewDialog: () => {},
      closeNewDialog: () => {},
      openDeleteDialog: () => {},
      closeDeleteDialog: () => {},
      newAlertName: "",
      reloadTable: () => {},
      readAccess: true,
      writeAccess: true,
      innerWidth: window.innerWidth,
    };

    this.handleDeleteAlert = this.handleDeleteAlert.bind(this);
    this.updatePageHeader = this.updatePageHeader.bind(this);
  }

  componentDidMount() {
    const userRole = JwtTokenHelpers.getUserRole();
    const readAccess = (userRole.permissionsAlert & PermissionFlags.Read) != 0; // if true then user has Read
    const writeAccess = (userRole.permissionsAlert & PermissionFlags.Write) != 0; //
    this.setState({ readAccess, writeAccess }, this.updatePageHeader);

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ innerWidth: window.innerWidth });
    this.updatePageHeader();
  }

  componentWillUnmount(): void {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.removeAllButtons();
      layoutContext.removeAllExtraInfos();
    }
  }

  updatePageHeader() {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.updateButtons(this.generateHeaderButtons());
      layoutContext.removeAllExtraInfos();
    }
  }

  showDeleteAlertDialog(deleteAlertId: string) {
    this.setState({ deleteAlertId }, this.state.openDeleteDialog);
  }

  handleDeleteAlert() {
    if (!this.state.deleteAlertId) return;
    new AlertApiService()
      .remove(this.state.deleteAlertId)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Alert was successfully deleted",
          toastOnly: true,
        });
        this.state.closeDeleteDialog();
        this.state.reloadTable();
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );
  }

  addNewAlert() {
    const newAlert: Alert = {
      name: this.state.newAlertName,
    };
    new AlertApiService()
      .create(newAlert)
      .then((res) => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "New alert was successfully added",
          toastOnly: true,
        });
        this.state.closeNewDialog();
        this.state.reloadTable();
        this.props.history.push(`/go/manage/alerts/${res}`);
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );
  }

  generateHeaderButtons(): PageHeaderButton[] {
    const headerButtons: PageHeaderButton[] = [];

    if (
      this.state.writeAccess &&
      JwtTokenHelpers.checkPermission("permissionsAlert", PermissionFlags.Write)
    ) {
      headerButtons.push({
        buttonName: "Add Alert",
        onClick: this.state.openNewDialog,
        iconClass: "fa fa-plus",
        isIconButton: this.state.innerWidth < MIN_SCREEN_WIDTH,
      });
    }

    return headerButtons;
  }

  render() {
    return (
      <PageContainerWrapper>
        <section className="page-structure-header">
          <PageHeaderNew
            pageTitle="Configure Alerts"
            pageTitleType="Breadcrumb"
            breadcrumbSetting={{ backPageTitle: "Alerts Feed", backUrl: `/go/manage/alerts` }}
          />
        </section>

        <section className="content pb-4 flex-grow-1">
          <FilterTable<Alert>
            tableId="alerts-table"
            paginate={true}
            virtualize={true}
            fetchItemsRemotely={true}
            fetchItemsFunction={new AlertApiService().list}
            initialTableFilters={new TableFilters("Name", true)}
            reloadFunction={(reload) => {
              this.setState({ reloadTable: reload });
            }}
            columnDefinitions={[
              {
                header: "",
                notTogglable: true,
                valueFunction: (item) => (
                  <>
                    {JwtTokenHelpers.checkPermission("permissionsAlert", PermissionFlags.Write) && (
                      <div className="d-flex flex-row">
                        <Link to={`/go/manage/alerts/${item.id}`}>
                          <IconButton
                            isDark={false}
                            iconClass="fa fa-pencil-alt"
                            tooltip="Edit alert"
                          />
                        </Link>
                        <IconButton
                          isDark={false}
                          iconClass="fa fa-trash-alt"
                          tooltip="Delete alert"
                          onClick={() => this.showDeleteAlertDialog(item.id ?? "")}
                        />
                      </div>
                    )}
                  </>
                ),
              },
              {
                header: "Name",
                sortable: true,
                valueFunction: (item) => (item.name ? item.name : ""),
                databaseColumn: "Name",
              },
              {
                header: "Last Fired",
                sortable: true,
                valueFunction: (item) =>
                  item.lastFired && item.lastFired.isValid()
                    ? DateHelpers.formatMoment(item.lastFired)
                    : "",
                databaseColumn: "LastFired",
              },
              {
                header: "Date Created",
                sortable: true,
                valueFunction: (item) =>
                  item.createdAt && item.createdAt.isValid()
                    ? DateHelpers.formatMoment(item.createdAt)
                    : "",
                databaseColumn: "CreatedAt",
              },
            ]}
          />
        </section>

        <Dialog
          setOpen={(open) => this.setState({ openNewDialog: open })}
          setClose={(close) => this.setState({ closeNewDialog: close })}
          onClose={() => this.setState({ newAlertName: "" })}
          header="Add New Alert"
          size={DialogSize.Large}
          body={
            <>
              <form id="newAlert-form">
                <div className="row mb-n3">
                  <div className="col-12 mb-3">
                    <Input
                      idName="newAlertName"
                      labelString="Name"
                      placeholder=""
                      onChange={(e) => {
                        this.setState({ newAlertName: e.target.value });
                      }}
                      name="newAlertName"
                      inputValue={this.state.newAlertName}
                    />
                  </div>
                </div>
              </form>
            </>
          }
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                buttonType={ButtonType.Transparent}
                content="Close"
                onClick={this.state.closeNewDialog}
              />
              <Button
                buttonType={ButtonType.Success}
                content="Add"
                onClick={() => this.addNewAlert()}
              />
            </>
          }
        />

        <Dialog
          size={DialogSize.Small}
          header="Delete Alert"
          setOpen={(open) => this.setState({ openDeleteDialog: open })}
          setClose={(close) => this.setState({ closeDeleteDialog: close })}
          onClose={() => this.setState({ deleteAlertId: undefined })}
          body={<p>Are you sure you want to delete the alert?</p>}
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                buttonType={ButtonType.Transparent}
                content="Close"
                onClick={this.state.closeDeleteDialog}
              />
              <Button
                content="Delete"
                buttonType={ButtonType.Error}
                onClick={this.handleDeleteAlert}
              />
            </>
          }
        />
      </PageContainerWrapper>
    );
  }
}
