import { useEffect, useState } from "react";

export function debounce<A = unknown>(
  fn: (args: A) => void,
  ms: number,
): [(args: A) => void, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args: A): void => {
    if (timer) {
      console.log("clearing timeout");
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      console.log("calling function on timeout: ", ms);
      fn(args);
    }, 2 * ms);
    console.log("Debounce.tsx::debouncedFunc() => timer after: ", timer);
  };

  const teardown = () => clearTimeout(timer);

  return [debouncedFunc, teardown];
}
// export const useDebounce = function<A = unknown>(
//     fn: (args: A) => void,
//     ms: number
// ): ((args: A) => void) {
//     const [debouncedFun, teardown] = debounce<A>(fn, ms);
//
//     useEffect(() => () => teardown(), []);
//
//     return debouncedFun;
// };

// Hook
export function useDebounce<A>(value: A, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<A>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
