import { ApiService } from "./ApiService";
import { apiEndPoint } from "../../config";
import { Promise } from "bluebird";
import { TableFilters } from "../../models/api/TableFilters";
import { PagedResults } from "../../models/api/PagedResults";
import { DashboardTemplate } from "../../models/api/DashboardTemplate";

export class DashboardTemplateApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "dashboardTemplate");

    this.listDashboardTemplates = this.listDashboardTemplates.bind(this);
  }

  listDashboardTemplates(tableFilters?: TableFilters): Promise<PagedResults<DashboardTemplate>> {
    return new Promise<PagedResults<DashboardTemplate>>((resolve, _reject, cancel) => {
      this.get(
        `templates${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const dashboards = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                measurementCount: d.MEASUREMENTCOUNT,
                measurementIds: d.MEASUREMENTIDS != undefined ? d.MEASUREMENTIDS.split(",") : [],
              }) as DashboardTemplate,
          );
          resolve({
            items: dashboards,
            totalCount: result.TOTALCOUNT,
          });
        }
      });
    });
  }

  getDashboardTemplate(dashboardTemplateId: string): Promise<DashboardTemplate> {
    return new Promise<DashboardTemplate>((resolve, _reject, cancel) => {
      this.get(`templates/${dashboardTemplateId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            name: d.NAME,
            measurementCount: d.MEASUREMENTCOUNT,
            measurementIds: d.MEASUREMENTIDS != undefined ? d.MEASUREMENTIDS.split(",") : [],
            content: d.CONTENT,
            dashboardTemplateDeviceTypeIds: d.DASHBOARDTEMPLATEDEVICETYPEIDS.split(", ").map(
              (id: string) => id.toLowerCase(),
            ),
            testDeviceId: d.TESTDEVICEID != null ? d.TESTDEVICEID.toLowerCase() : null,
            testOrgId: d.TESTORGID != null ? d.TESTORGID.toLowerCase() : null,
          });
        }
      });
    });
  }

  getDashboardTemplateByName(name: string): Promise<DashboardTemplate | null> {
    return new Promise<DashboardTemplate | null>((resolve, reject, cancel) => {
      this.get(`templates/name/${name}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve(
            d
              ? {
                  id: d.ID,
                  name: d.NAME,
                  measurementCount: d.MEASUREMENTCOUNT,
                  measurementIds: d.MEASUREMENTIDS != undefined ? d.MEASUREMENTIDS.split(",") : [],
                  content: d.CONTENT,
                }
              : null,
          );
        } else {
          reject(new Error(result.message));
        }
      });
    });
  }

  getDashboardTemplatesByDeviceType(deviceTypeId: string): Promise<DashboardTemplate[]> {
    return new Promise<DashboardTemplate[]>((resolve, reject, cancel) => {
      this.get(`templates/deviceType/${deviceTypeId}`, cancel).done((result) => {
        if (result.success) {
          resolve(
            result.data.map(
              (d: any) =>
                ({
                  id: d.ID,
                  name: d.NAME,
                  description: d.DESCRIPTION,
                }) as DashboardTemplate,
            ),
          );
        } else {
          reject(new Error(result.message));
        }
      });
    });
  }

  getDeviceTypesWithTemplates() {
    return new Promise<any>((resolve, reject, cancel) => {
      this.get(`templates/deviceTypes`, cancel).done((result) => {
        if (result.success) {
          resolve(
            result.data.map((d: { DEVICETYPEID: string }) => {
              return {
                deviceTypeId: d.DEVICETYPEID.toLowerCase(),
              };
            }),
          );
        } else {
          console.error(result.errorMessage);
          reject(new Error(result.message));
        }
      });
    });
  }

  addDashboardTemplate(
    newDashboardTemplateName: string,
    newDashboardTemplateContent: string,
    deviceTypeIds: string[],
    testDeviceId?: string,
    testOrgId?: string,
  ): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `add`,
        {
          name: newDashboardTemplateName,
          content: newDashboardTemplateContent,
          deviceTypeIds: JSON.stringify(deviceTypeIds),
          testDeviceId,
          testOrgId,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.newDashboardTemplateId);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateDashboardTemplate(
    templateId: string,
    dashboardTemplateName: string,
    dashboardTemplateContent: string,
    deviceTypeIds: string[],
    testDeviceId?: string,
    testOrgId?: string,
  ): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `${templateId}`,
        {
          name: dashboardTemplateName,
          content: dashboardTemplateContent,
          deviceTypeIds: JSON.stringify(deviceTypeIds),
          testDeviceId,
          testOrgId,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.newDashboardTemplateId);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteDashboardTemplate(templateId: string): Promise<void> {
    return new Promise<void>((resolve, _reject, cancel) => {
      this.delete(`${templateId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        }
      });
    });
  }

  getDashboardTemplateDeviceTypes(dashboardTemplateId: string): Promise<string[]> {
    return new Promise<string[]>((resolve, _reject, cancel) => {
      this.get(`deviceTypes/${dashboardTemplateId}`, cancel).done((result) => {
        if (result.success) {
          const dashboardTemplateDeviceTypes = result.data.map(
            (d: any) => d.DEVICETYPEID as string,
          );
          resolve(dashboardTemplateDeviceTypes);
        } else {
          resolve([]);
        }
      });
    });
  }
}
