import { useRef } from "react";
import "../../scss/formStyles.scss";
import { Button, ButtonType } from "../Button";
import { useHistory } from "react-router-dom";
import { Dialog } from "../Dialog";

interface IProps {
  cancelUrl: string;
  onSave: () => void;
  hasChanges: boolean;
  disableSave?: boolean;
  isSaving: boolean;
}

export function FormButtons(props: IProps) {
  const history = useHistory();
  const closeDialog = useRef<() => void>(() => {});
  const openDialog = useRef<() => void>(() => {});

  const handleCancel = () => {
    if (!props.hasChanges) history.push(props.cancelUrl);
    else openDialog.current();
  };

  return (
    <>
      <div className="form-buttons">
        <div className="spacer" />
        <Button content="Cancel" buttonType={ButtonType.Transparent} onClick={handleCancel} />
        <Button
          content="Save Changes"
          buttonType={ButtonType.Fill}
          disabled={(!props.hasChanges && !props.disableSave) || props.isSaving}
          onClick={props.onSave}
        />
      </div>
      <Dialog
        header="Unsaved Changes"
        body="Your unsaved changes will be lost. Are you sure you want to leave the page?"
        footer={
          <>
            <span className="flex-grow-1"></span>
            <Button
              buttonType={ButtonType.Transparent}
              content="Leave Page"
              onClick={() => history.push(props.cancelUrl)}
            />
            <Button
              content="Stay on Page"
              buttonType={ButtonType.Fill}
              onClick={closeDialog.current}
            />
          </>
        }
        setOpen={(open) => (openDialog.current = open)}
        setClose={(open) => (closeDialog.current = open)}
      />
    </>
  );
}
