import {
  ChangeEvent,
  Component,
  ReactChild,
  ReactElement,
  ReactNode,
  Ref,
  RefObject,
  createRef,
  isValidElement,
} from "react";
import reactNodeToString from "react-node-to-string";

import { IconButton } from "../IconButton";
import { TableFilters } from "../../models/api/TableFilters";
import { PagedResults } from "../../models/api/PagedResults";
import { TableSelectedItem } from "../../models/api/TableSelectedItem";
import { CellMeasurerCache, MultiGrid } from "react-virtualized";
import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";
import { NonVirtualizeTable } from "./NonVirtualizeTable";
import { VirtualizeTable } from "./VirtualizeTable";

// The Column Names/Titles of the Table.
export interface ColumnDefinition<T> {
  // Required
  label?: string;
  header: ReactChild;
  valueFunction: (item: T, index?: number) => ReactChild;

  // For the Label
  hasTooltip?: boolean;
  tooltipText?: string;

  // For the Value
  cellClassFunction?: (item: T, index?: number) => string;
  isValueCapitalize?: boolean;
  editable?: boolean;
  editableItemComponent?: (
    item: any,
    column: ColumnDefinition<any>,
    onEditableFieldChange: (columnHeader: string, event: ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
  ) => ReactNode;

  // For the Column
  sortable?: boolean;
  sortDirection?: SortDirection;
  sortFunction?: (a: T, b: T) => number;
  hidden?: boolean;
  notTogglable?: boolean;
  databaseColumn?: string;
}

// Page Directions to help navigate through the pages.
// This is used in the handleUpdatePage() and
// In the TableFooter Component (For the arrow buttons)
export enum PageDirection {
  First,
  Back,
  Forward,
  Last,
}

export enum SortDirection {
  None,
  Ascending,
  Descending,
}

interface IProps<T> {
  allowBulkUpdate?: boolean;
  bulkActions?: ReactElement | (() => ReactElement | undefined);
  columnDefinitions: ColumnDefinition<T>[];
  customFilters?: JSX.Element[];
  disableColumnToggle?: boolean;
  expandableRowFunction?: (item: T, index: number) => JSX.Element;
  extraDatabaseColumns?: string[];
  fetchItemsFunction?: (tableFilters: TableFilters) => Promise<PagedResults<T>>;
  fetchItemsRemotely?: boolean;
  hasSmallFooter?: boolean;
  hideFooterSelection?: boolean;
  hideHeader?: boolean;
  hideSearch?: boolean;
  initialSortFunction?: (itemA: T, itemB: T) => number;
  initialTableFilters?: TableFilters;
  items?: T[];
  noButtons?: boolean;
  noItemsText?: ReactElement;
  onRemoteItemsChange?: (items: T[]) => void;
  onSelectedItemsChange?: (items: TableSelectedItem<T>[]) => void;
  paginate?: boolean;
  paginationOptions?: { label: string; value: number }[];
  reloadFunction?: (reload: () => void) => void;
  removeBorder?: boolean;
  rowClickFunction?: (item: T, index: number) => void;
  tableId: string;
  textFilterFunction?: (item: T, filterValue: string) => boolean;
  virtualize?: boolean;
}

interface IState<T> {
  allowBulkUpdate: boolean;
  bulkItems: TableSelectedItem<T>[];
  columnDefinitions: ColumnDefinition<T>[];
  expandedRowIndexes: number[];
  fetchItemsRequest?: Promise<PagedResults<T>>;
  fetchItemsRequestDebounceTimeout?: NodeJS.Timeout;
  filterValue: string;
  idsSelectedItems: string[];
  loading: boolean;
  page: number;
  paginationOptions: { label: string; value: number }[];
  rows: number;
  selectAll: boolean;
  selectedItems: TableSelectedItem<T>[];
  selectedOption: { label: string; value: number };
  serversideItems?: T[];
  serversideItemsTotalCount: number;
  sortIndex?: number;
  tableFilters?: TableFilters;
}

const TABLE_SETTINGS_BUTTON = "table-settings-button";

export class FilterTable<T> extends Component<IProps<T>, IState<T>> {
  private readonly cache: CellMeasurerCache;
  private readonly multiGridRef: Ref<MultiGrid>;
  private readonly selectAllCheckbox: RefObject<HTMLInputElement>;

  constructor(props: IProps<T>) {
    super(props);

    const po = this.props.paginationOptions ?? [
      { label: "10", value: 10 },
      { label: "25", value: 25 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
      { label: "1000", value: 1000 },
    ];

    let columnDefinitions = this.props.columnDefinitions;
    if (this.props.expandableRowFunction != undefined) {
      columnDefinitions.push({
        header: "",
        notTogglable: true,
        valueFunction: (_item, index) => (
          <IconButton
            isDark={false}
            iconClass={`fas ${
              this.state.expandedRowIndexes.includes(index!) ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          />
        ),
      });
    }

    columnDefinitions = columnDefinitions.map((cd) => {
      const hiddenSetting = localStorage.getItem(`${props.tableId}-${cd.header}`);

      if (hiddenSetting != undefined) {
        cd.hidden = hiddenSetting == "true";
      }
      return cd;
    });

    const sortIndex = this.calculateInitialSortIndex(columnDefinitions);
    let page = 0;

    let selectedPo = po[0];
    if (this.props.fetchItemsRemotely && this.props.initialTableFilters) {
      selectedPo =
        po.find(
          (po) =>
            this.props.initialTableFilters && po.value == this.props.initialTableFilters.limit,
        ) ?? po[0];
      page = this.props.initialTableFilters.offset * selectedPo.value;
      this.props.initialTableFilters.visibleColumns = this.calculateVisibleColumns(
        props.columnDefinitions,
      );
    }

    this.state = {
      filterValue: "",
      rows: po[0].value,
      page: page,
      paginationOptions: po,
      selectedOption: selectedPo,
      columnDefinitions: columnDefinitions,
      sortIndex: sortIndex,
      expandedRowIndexes: [],
      tableFilters: this.props.initialTableFilters,
      serversideItemsTotalCount: 100000,
      loading: false,
      allowBulkUpdate: this.props.allowBulkUpdate ?? false,
      selectAll: false,
      bulkItems: [],
      selectedItems: [],
      idsSelectedItems: [],
    };

    this.cache = new CellMeasurerCache({
      defaultWidth: 90,
      minWidth: 80,
      // fixedWidth: true,
    });

    this.multiGridRef = createRef<MultiGrid>();
    this.selectAllCheckbox = createRef();

    this.downloadVisibleItems = this.downloadVisibleItems.bind(this);
    this.getColumnCount = this.getColumnCount.bind(this);
    this.getFixedColumnCount = this.getFixedColumnCount.bind(this);
    this.handleColumnToggle = this.handleColumnToggle.bind(this);
    this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectSingleRow = this.handleSelectSingleRow.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.hideColumnHideTutorial = this.hideColumnHideTutorial.bind(this);
    this.loadServersideItems = this.loadServersideItems.bind(this);
    this.redrawGridView = this.redrawGridView.bind(this);
    this.showColumnHideTutorial = this.showColumnHideTutorial.bind(this);
  }

  componentDidMount() {
    const isFirstTimeSeeingATable = localStorage.getItem("has-user-seen-a-table") == "true";
    if (!isFirstTimeSeeingATable) {
      this.handleFirstTimeSeeingTable();
    }

    if (this.props.fetchItemsRemotely) {
      this.loadServersideItems();
    }

    this.props.reloadFunction && this.props.reloadFunction(this.loadServersideItems);
  }

  componentDidUpdate(prevProps: Readonly<IProps<T>>) {
    if (this.props.items !== prevProps.items) {
      if (!this.props.fetchItemsRemotely && !!this.props.items) {
        this.setState({
          bulkItems: this.props.items?.map((item, index) => ({
            index,
            item,
            isChecked: false,
          })),
        });
      }
    }
  }

  getGenericItemId(item: T) {
    if (!(item as unknown as any)["id"]) return "";
    return (item as unknown as any)["id"] as string;
  }

  isItemChecked(item: T) {
    if (!this.state.idsSelectedItems) return false;
    if (!(item as unknown as any)["id"]) return false;

    const idItem = this.getGenericItemId(item);
    return this.state.idsSelectedItems.includes(idItem);
  }

  loadServersideItems() {
    if (!this.props.fetchItemsFunction || !this.state.tableFilters) {
      this.setState({
        serversideItems: [],
        bulkItems: [],
        selectAll: false,
      });
      console.error(
        "To load items from a remote server, please define the 'fetchItemsFunction' and 'tableFilters' properties",
      );
    } else {
      this.setState({ loading: true }, () => {
        if (!this.props.fetchItemsFunction || !this.state.tableFilters) {
          console.error(
            "To load items from a remote server, please define the 'fetchItemsFunction' and 'tableFilters' properties",
          );
          return;
        } else {
          if (this.state.fetchItemsRequest && !this.state.fetchItemsRequest.isFulfilled()) {
            this.state.fetchItemsRequest.cancel();
          }

          if (this.state.fetchItemsRequestDebounceTimeout) {
            clearTimeout(this.state.fetchItemsRequestDebounceTimeout);
          }

          this.uncheckSelectAllCheckbox();

          const timeout = setTimeout(() => {
            const req = this.props.fetchItemsFunction!(this.state.tableFilters!);
            req
              .then((res: PagedResults<T>) =>
                this.setState(
                  {
                    serversideItems: res.items,
                    serversideItemsTotalCount: res.totalCount,
                    loading: false,
                    bulkItems: res.items.map((item, index) => ({
                      index: index,
                      item: item,
                      isChecked: this.isItemChecked(item),
                    })),
                  },
                  () => {
                    this.props.onRemoteItemsChange && this.props.onRemoteItemsChange(res.items);
                    this.redrawGridView();
                  },
                ),
              )
              .catch((err) =>
                $.notification("show", {
                  type: "error",
                  title: undefined,
                  message: err,
                  toastOnly: true,
                }),
              );
            this.setState({ fetchItemsRequest: req });
          }, 500);

          this.setState({ fetchItemsRequestDebounceTimeout: timeout });
        }
      });
    }
  }

  calculateInitialSortIndex(columnDefinitions: ColumnDefinition<T>[]): number {
    if (this.props.initialTableFilters && this.props.initialTableFilters.orderBy) {
      const visibleColumnDefinitions = columnDefinitions.filter((cd) => !cd.hidden);
      const cdIndex = visibleColumnDefinitions.findIndex(
        (cd) =>
          this.props.initialTableFilters &&
          cd.databaseColumn == this.props.initialTableFilters.orderBy,
      );
      if (cdIndex < 0) {
        console.error(
          "Couldn't find column definition with 'databaseColumn' matching 'orderBy' property in supplied table " +
            "filters. Please ensure each sortable column's definition has a value set for 'databaseColumn' and " +
            "the 'orderBy' property on the supplied table filters matches at least one of them.",
        );
        return -1;
      }
      const cd = visibleColumnDefinitions[cdIndex];
      cd.sortDirection = this.props.initialTableFilters.isAsc
        ? SortDirection.Ascending
        : SortDirection.Descending;
      return cdIndex;
    } else {
      return columnDefinitions
        .filter((cd) => !cd.hidden)
        .findIndex((cd) => cd.sortDirection != undefined && cd.sortDirection != SortDirection.None);
    }
  }

  calculateVisibleColumns(columnDefinitions: ColumnDefinition<T>[]): string {
    return [
      ...columnDefinitions
        .filter((cd) => !cd.hidden && cd.databaseColumn != undefined)
        .map((cd) => cd.databaseColumn as string),
      ...(this.props.extraDatabaseColumns ?? []),
    ].join(",");
  }

  updateColumnDefinition() {
    let columnDefinitions = this.props.columnDefinitions;
    if (this.props.expandableRowFunction != undefined) {
      columnDefinitions.push({
        header: "",
        notTogglable: true,
        valueFunction: (_item, index) => (
          <IconButton
            isDark={false}
            iconClass={`fas ${
              this.state.expandedRowIndexes.includes(index as number)
                ? "fa-chevron-up"
                : "fa-chevron-down"
            }`}
          />
        ),
      });
    }

    columnDefinitions = columnDefinitions.map((cd) => {
      const hiddenSetting = localStorage.getItem(`${this.props.tableId}-${cd.header}`);
      if (hiddenSetting != undefined) {
        cd.hidden = hiddenSetting == "true";
      }
      return cd;
    });

    this.setState({ columnDefinitions });
  }

  getItems(): T[] | undefined {
    if (!this.props.fetchItemsRemotely) {
      return this.getItemsLocal();
    } else {
      return this.getItemsServerside();
    }
  }

  getItemsLocal(): T[] {
    let items = this.getFilteredItems();
    const visibleColumns = this.state.columnDefinitions.filter((cd) => !cd.hidden);

    if (this.props.initialSortFunction) items = items.sort(this.props.initialSortFunction);
    if (this.state.sortIndex !== undefined && this.state.sortIndex > -1) {
      const def = visibleColumns[this.state.sortIndex];
      if (def.sortDirection != undefined && def.sortDirection != SortDirection.None) {
        const defaultSortFunction = (a: T, b: T) =>
          def
            .valueFunction(b)
            .toString()
            .trim()
            .localeCompare(def.valueFunction(a).toString().trim());
        const sortFunc = def.sortFunction ?? defaultSortFunction;
        items = items.sort(sortFunc);
        if (def.sortDirection == SortDirection.Descending) items.reverse();
      }
    }

    if (this.props.paginate && this.state.rows != -1)
      items = items.slice(
        this.state.page * this.state.rows,
        Math.min((this.state.page + 1) * this.state.rows, this.getTotalItemCount()),
      );

    return items;
  }

  getItemsServerside(): T[] | undefined {
    return this.state.serversideItems;
  }

  getFilteredItems(): T[] {
    return (
      this.props.items?.filter(
        (i) =>
          !this.props.textFilterFunction ||
          this.props.textFilterFunction(i, this.state.filterValue),
      ) ?? []
    );
  }

  getTotalItemCount(): number {
    if (!this.state.serversideItems) {
      return this.getFilteredItems().length;
    } else {
      return this.state.serversideItemsTotalCount;
    }
  }

  isLastPage() {
    return (
      this.state.rows == -1 || this.getTotalItemCount() <= this.state.rows * (this.state.page + 1)
    );
  }

  getPageText() {
    let pageText = "";
    const totalItemCount = this.getTotalItemCount();

    if (this.state.rows == -1) {
      return `1-${totalItemCount} of ${totalItemCount}`;
    }

    const fromCount = this.state.rows * this.state.page + 1;
    const toCount = Math.min(this.state.rows * (this.state.page + 1), totalItemCount);

    if (toCount > 1) {
      pageText += `${fromCount}-${toCount}`;
    } else {
      pageText += "1";
    }

    pageText += ` of ${totalItemCount}`;
    return pageText;
  }

  redrawGridView() {
    if (this.props.virtualize) {
      this.forceUpdate();
      this.cache.clearAll();

      // @ts-expect-error
      if (!!this.multiGridRef && !!this.multiGridRef.current) {
        // @ts-expect-error
        (this.multiGridRef.current as MultiGrid).recomputeGridSize();
      }
    }
  }

  handleRowClick(item: T, index: number) {
    if (this.props.expandableRowFunction != undefined) {
      let newIndexes = this.state.expandedRowIndexes;

      if (this.state.expandedRowIndexes.includes(index)) {
        newIndexes = newIndexes.filter((i) => i != index);
      } else {
        newIndexes.push(index);
      }

      this.setState(
        {
          expandedRowIndexes: newIndexes,
        },
        this.redrawGridView,
      );
    }

    if (this.props.rowClickFunction) this.props.rowClickFunction(item, index);
  }

  handleColumnToggle(cd: ColumnDefinition<T>) {
    cd.hidden = !cd.hidden;
    this.redrawGridView();

    localStorage.setItem(`${this.props.tableId}-${cd.header}`, cd.hidden.toString());

    if (
      this.props.fetchItemsRemotely &&
      this.state.tableFilters &&
      cd.databaseColumn != undefined
    ) {
      const tableFilters = this.state.tableFilters;
      tableFilters.visibleColumns = this.calculateVisibleColumns(this.state.columnDefinitions);
      this.setState({ tableFilters }, () => {
        if (tableFilters.keyword != "") {
          this.loadServersideItems();
        }
      });
    }
  }

  handleFirstTimeSeeingTable() {
    if (this.props.disableColumnToggle || !this.props.textFilterFunction) return;

    localStorage.setItem("has-user-seen-a-table", true.toString());
    this.showColumnHideTutorial();
  }

  showColumnHideTutorial() {
    const popoverContent = $(`
            <span>
                <p>
                    Missing a column? You can use this menu to show and hide this table's columns.
                </p>
                <div class="d-flex flex-row w-100 mt-2">
                    <span class="flex-grow-1 mr-2"></span>
                    <a class="link" href="#">Close</a>
                </div>
            </span>`);

    popoverContent.find("a").on("click", this.hideColumnHideTutorial);

    $(`#${TABLE_SETTINGS_BUTTON}`).popover({
      html: true,
      content: popoverContent[0],
      placement: "right",
      container: document.body,
      boundary: document.body,
      animation: true,
    });

    $(`#${TABLE_SETTINGS_BUTTON}`).popover("show");

    updateDimDiv(`#${TABLE_SETTINGS_BUTTON}`);

    this.makeDimDivFullScreen();

    this.scrollToElement(TABLE_SETTINGS_BUTTON);
  }

  makeDimDivFullScreen() {
    const dimDiv = document.querySelector("body > div.dim") as HTMLDivElement;

    if (dimDiv) {
      dimDiv.style.top = "0px";
      dimDiv.style.left = "0px";
      dimDiv.style.removeProperty("width");
      dimDiv.style.removeProperty("height");
    }
  }

  scrollToElement(elementId: string) {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView();
    }
  }

  hideColumnHideTutorial() {
    $(`#${TABLE_SETTINGS_BUTTON}`).popover("dispose");
    hideDimDiv();
    this.scrollToTop();
  }

  scrollToTop() {
    const bodyElement = document.getElementsByTagName("body")[0];

    if (bodyElement) {
      bodyElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  getSortIconClass(def: ColumnDefinition<T>) {
    let iconClass: string = "text-muted fas ";

    if (def.sortDirection == undefined || def.sortDirection == SortDirection.None) {
      iconClass += "fa-light fa-sort ";
    } else if (def.sortDirection == SortDirection.Ascending) {
      iconClass += "fa-light fa-sort-up ";
    } else if (def.sortDirection == SortDirection.Descending) {
      iconClass += "fa-light fa-sort-down ";
    }

    return iconClass;
  }

  handleSort(def: ColumnDefinition<T>) {
    if (!def.sortable) return;

    if (!this.state.tableFilters || !def.databaseColumn) {
      console.error(
        "To fetch table items remotely, please supply a value for the 'tableFilters' property, and supply a " +
          "value for 'databaseColumn' for each sortable column definition",
      );
      return;
    }

    const tableFilters = this.state.tableFilters;
    const columnDefinitions = this.state.columnDefinitions;
    let sortIndex = 0;

    for (let index = 0; index < columnDefinitions.length; index++) {
      const column = columnDefinitions[index];

      if (def.databaseColumn === column.databaseColumn) {
        sortIndex = index;
        tableFilters.orderBy = column.databaseColumn;

        if (column.sortDirection === undefined || column.sortDirection === SortDirection.None) {
          column.sortDirection = SortDirection.Ascending;
          tableFilters.isAsc = true;
        } else if (column.sortDirection === SortDirection.Ascending) {
          column.sortDirection = SortDirection.Descending;
          tableFilters.isAsc = false;
        } else {
          column.sortDirection = SortDirection.None;
          tableFilters.orderBy = "";
        }
      } else {
        column.sortDirection = SortDirection.None;
      }

      columnDefinitions[index] = column;
    }

    if (!this.props.fetchItemsRemotely) {
      this.setState({ columnDefinitions, sortIndex }, this.redrawGridView);
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          columnDefinitions,
          sortIndex,
          tableFilters,
        }),
        this.loadServersideItems,
      );
    }
  }

  handleFilterValueChange(filterValue: string) {
    if (!this.props.fetchItemsRemotely) {
      this.setState({ filterValue });
    } else {
      if (!this.state.tableFilters) {
        console.error(
          "To fetch table items remotely, please supply a value for the 'tableFilters' property.",
        );
        return;
      } else {
        const tableFilters = this.state.tableFilters;
        tableFilters.keyword = filterValue;
        this.setState({ filterValue, tableFilters }, () => {
          this.loadServersideItems();
          this.redrawGridView();
        });
      }
    }
  }

  handleUpdatePage(pageDirection: PageDirection) {
    let newPage = this.state.page;

    if (pageDirection == PageDirection.First) {
      newPage = 0;
    } else if (pageDirection == PageDirection.Back) {
      newPage -= 1;
    } else if (pageDirection == PageDirection.Forward) {
      newPage += 1;
    } else if (pageDirection == PageDirection.Last) {
      newPage = Math.trunc((this.getTotalItemCount() - 1) / this.state.rows);
    }

    if (!this.props.fetchItemsRemotely) {
      this.setState({ page: newPage });
    } else {
      if (!this.state.tableFilters) {
        console.error(
          "To fetch table items remotely, please supply a value for the 'tableFilters' property.",
        );
        return;
      } else {
        const tableFilters = this.state.tableFilters;
        tableFilters.offset = newPage * tableFilters.limit;
        this.setState(
          {
            page: newPage,
            tableFilters,
          },
          this.loadServersideItems,
        );
      }
    }
  }

  handleChangeRowsPerPage(option: { label: string; value: number } | null) {
    if (!option) return;

    if (!this.props.fetchItemsRemotely) {
      this.setState({
        page: 0,
        rows: option.value,
        selectedOption: option,
      });
    } else {
      if (!this.state.tableFilters) {
        console.error(
          "To fetch table items remotely, please supply a value for the 'tableFilters' property.",
        );
        return;
      } else {
        const tableFilters = this.state.tableFilters;
        tableFilters.limit = option.value;
        tableFilters.offset = 0;
        this.setState(
          {
            page: 0,
            rows: option.value,
            selectedOption: option,
            tableFilters,
          },
          this.loadServersideItems,
        );
      }
    }
  }

  getNormalizedSelectedItems() {
    const idsSelectedItems = this.state.idsSelectedItems;
    const selectedItems = this.state.selectedItems;

    for (let i = 0; i < this.state.bulkItems.length; i++) {
      const item = this.state.bulkItems[i];
      const itemId = this.getGenericItemId(item.item);

      if (item.isChecked) {
        if (!idsSelectedItems.includes(itemId)) {
          idsSelectedItems.push(itemId);
          selectedItems.push(item);
        }
      } else {
        if (idsSelectedItems.includes(itemId)) {
          const existingIdIndex = idsSelectedItems.indexOf(itemId);
          idsSelectedItems.splice(existingIdIndex, 1);

          const existingItemIndex = selectedItems.indexOf(item);
          selectedItems.splice(existingItemIndex, 1);
        }
      }
    }

    return { idsSelectedItems, selectedItems };
  }

  updateBulkItemsAndSelectedItemsState(
    bulkItems: TableSelectedItem<T>[],
    selectedItems: TableSelectedItem<T>[],
    idsSelectedItems: string[],
  ) {
    this.setState(
      { bulkItems, selectedItems, idsSelectedItems },
      () => this.props.onSelectedItemsChange && this.props.onSelectedItemsChange(selectedItems),
    );
  }

  uncheckSelectAllCheckbox() {
    if (!!this.selectAllCheckbox.current && this.selectAllCheckbox.current.checked) {
      this.selectAllCheckbox.current.checked = false;
    }
  }

  clearSelectedItems() {
    const bulkItems = this.state.bulkItems;
    bulkItems.forEach((item) => (item.isChecked = false));

    this.uncheckSelectAllCheckbox();

    this.setState(
      (prevState) => ({
        ...prevState,
        bulkItems,
        selectedItems: [],
        idsSelectedItems: [],
      }),
      () => this.props.onSelectedItemsChange && this.props.onSelectedItemsChange([]),
    );
  }

  handleSelectAll(event: any) {
    const bulkItems = this.state.bulkItems;
    bulkItems.forEach((item) => (item.isChecked = event.target.checked));

    const { idsSelectedItems, selectedItems } = this.getNormalizedSelectedItems();

    this.updateBulkItemsAndSelectedItemsState(bulkItems, selectedItems, idsSelectedItems);
  }

  handleSelectSingleRow(event: any) {
    const bulkItems = this.state.bulkItems;
    bulkItems.forEach((item) => {
      if (item.index.toString() === event.target.value) item.isChecked = event.target.checked;
    });

    const { idsSelectedItems, selectedItems } = this.getNormalizedSelectedItems();

    this.updateBulkItemsAndSelectedItemsState(bulkItems, selectedItems, idsSelectedItems);
  }

  getColumnCount(visibleColumns: ColumnDefinition<T>[]) {
    return this.state.allowBulkUpdate && this.props.bulkActions
      ? visibleColumns.length + 1
      : visibleColumns.length;
  }

  getFixedColumnCount() {
    let count = this.state.allowBulkUpdate && this.props.bulkActions ? 2 : 1;
    if (this.props.noButtons) count--;
    return count;
  }

  downloadVisibleItems() {
    const columns = this.props.columnDefinitions.slice(1).filter((column) => !column.hidden);

    const csv = [
      // Header
      columns.map((column) => column.header),
      // Items
      ...this.state.bulkItems.map(({ item }) =>
        columns.map((column, index) => {
          let value = column.valueFunction(item, index);

          if (isValidElement(value)) value = reactNodeToString(value);
          if (value) value = `"${value}"`;

          return value;
        }),
      ),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const url = URL.createObjectURL(new Blob([csv], { type: "text/csv;charset=utf-8;" }));

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "table-export.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const items = this.getItems();
    const visibleColumns = this.state.columnDefinitions.filter((cd) => !cd.hidden);

    return (
      <div className="tf-main-wrapper">
        {(this.props.textFilterFunction || this.props.fetchItemsRemotely) &&
          !this.props.hideHeader && (
            <TableHeader<T>
              allowBulkUpdate={this.state.allowBulkUpdate}
              bulkActions={this.props.bulkActions}
              columnDefinitions={this.state.columnDefinitions}
              customFilters={this.props.customFilters}
              disableColumnToggle={this.props.disableColumnToggle}
              downloadVisibleItems={this.downloadVisibleItems}
              fetchItemsRemotely={this.props.fetchItemsRemotely}
              handleColumnToggle={this.handleColumnToggle}
              handleFilterValueChange={this.handleFilterValueChange}
              hideColumnHideTutorial={this.hideColumnHideTutorial}
              hideSearch={this.props.hideSearch}
              isReloadDisabled={!this.state.serversideItems || this.state.loading}
              loadServersideItems={this.loadServersideItems}
              reloadClassName={`${!items || (this.state.loading && "spin")}`}
              searchInputId={this.props.tableId + "-search-input"}
              searchInputPlaceholder="Search Something"
              selectedItems={this.state.selectedItems}
              tableId={this.props.tableId}
            />
          )}

        {/* Virtualize Table means that it will only render necessary items-- items that are currently visible to the screen.*/}
        {/* This is so that the website don't crash when user try to load (choose 50 - all for rows per page) too many or all items on the table.*/}

        {this.props.virtualize ? (
          <VirtualizeTable
            tableId={this.props.tableId}
            visibleColumns={visibleColumns}
            items={items}
            allowBulkUpdate={this.state.allowBulkUpdate}
            isLoading={this.state.loading}
            noButtons={this.props.noButtons}
            cache={this.cache}
            bulkItems={this.state.bulkItems}
            filterValue={this.state.filterValue}
            noItemsText={this.props.noItemsText}
            // expandedRowIndexes={this.state.expandedRowIndexes}
            selectAllCheckbox={this.selectAllCheckbox}
            multiGridRef={this.multiGridRef}
            getColumnCount={this.getColumnCount}
            getFixedColumnCount={this.getFixedColumnCount}
            getSortIconClass={this.getSortIconClass}
            bulkActions={this.props.bulkActions}
            expandableRowFunction={this.props.expandableRowFunction}
            rowClickFunction={this.props.rowClickFunction}
            handleSort={this.handleSort}
            // handleRowClick={this.handleRowClick}
            handleSelectSingleRow={this.handleSelectSingleRow}
            handleSelectAll={this.handleSelectAll}
          />
        ) : (
          <NonVirtualizeTable
            tableId={this.props.tableId}
            visibleColumns={visibleColumns}
            items={items}
            // isCompact={this.props.compact}
            allowBulkUpdate={this.state.allowBulkUpdate}
            isLoading={this.state.loading}
            filterValue={this.state.filterValue}
            noItemsText={this.props.noItemsText}
            bulkItems={this.state.bulkItems}
            expandedRowIndexes={this.state.expandedRowIndexes}
            selectAllCheckbox={this.selectAllCheckbox}
            getSortIconClass={this.getSortIconClass}
            bulkActions={this.props.bulkActions}
            expandableRowFunction={this.props.expandableRowFunction}
            rowClickFunction={this.props.rowClickFunction}
            handleSort={this.handleSort}
            handleRowClick={this.handleRowClick}
            handleSelectSingleRow={this.handleSelectSingleRow}
            handleSelectAll={this.handleSelectAll}
            hideHeader={this.props.hideHeader}
            removeBorder={this.props.removeBorder}
          />
        )}

        {/* The footer of the table where pagination are manage.*/}
        {/* Here we show and let user interact with how many rows per page to show.*/}
        {/* Where user navigate through the pages.*/}
        {/* Shows how many items are selected if there are.*/}
        {!this.state.loading && this.props.paginate && (
          <TableFooter
            rowsPerPageId={""}
            paginationOptions={this.state.paginationOptions}
            currentPageOpt={this.state.selectedOption.value}
            pageText={this.getPageText()}
            currPage={this.state.page}
            isLastPage={this.isLastPage()}
            onHandleUpdatePage={this.handleUpdatePage}
            onChangePageOpt={(option) => this.handleChangeRowsPerPage(option)}
            onRemoveAllSelected={() => this.clearSelectedItems()}
            totalSelectedItems={this.state.selectedItems.length}
            hasSmallFooter={this.props.hasSmallFooter}
            hideFooterSelection={this.props.hideFooterSelection}
            removeBorder={this.props.removeBorder}
          />
        )}
      </div>
    );
  }
}
