export default {
  hexToRGB(hex: string): string {
    let alpha = false;
    let code = hex.slice(hex.startsWith("#") ? 1 : 0);

    if (code.length === 3) code = [...code].map((x) => x + x).join("");
    else if (code.length === 8) alpha = true;

    const iCode = parseInt(code, 16);

    return (
      // 'rgb' + (alpha ? 'a' : '') + '(' +
      (iCode >>> (alpha ? 24 : 16)) +
      ", " +
      ((iCode & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
      ", " +
      ((iCode & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
      (alpha ? `, ${iCode & 0x000000ff}` : "")
      // ')'
    );
  },
};
