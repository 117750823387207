import { FC, useState } from "react";
import PageLayout from "./components/PageLayout";
import {
  useResetAccount,
  useResetPassword,
} from "../../services/context/AuthenticationApiServiceContext";
import { useParams } from "react-router";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Login } from "../../models/api/Login";

type Props = {
  action: "reset" | "set";
};

const ResetPassword: FC<Props> = ({ action = "reset" }) => {
  const history = useHistory();
  const { tokenId } = useParams<{ tokenId: string }>();
  const { isSuccess, data: resetAccount } = useResetAccount(tokenId);
  const [loginError, setLoginError] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const { mutate: doResetPassword } = useResetPassword({
    onSuccess: (data: Login) => {
      if (data.token) {
        history.push("/go/welcome");
      } else {
        if (data.message && data.message === "User has no right to access portal") {
          setLoginMessage("Your password has been set");
        }
      }
    },
    onError: (result) => {
      setLoginError(result);
    },
  });
  const validToken = true;
  const resetResult = false;

  const prompt = `${action === "reset" ? "Resetting" : "Setting"} password for:`;
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().min(8).required("Required"),
    confirmPassword: Yup.string()
      .min(8)
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Required"),
  });

  if (!isSuccess) return <></>;

  return (
    <PageLayout tagLine="Welcome to the FreeWave Data Portal">
      <div className="login-box">
        <div className="card card-outline">
          <div className="card-body">
            <div className="panel-body">
              <p className="text-center text-muted w-100 mb-3 email">
                {" "}
                {prompt} <span className="text-bold">{resetAccount?.emailAddress}</span>
              </p>
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={ResetPasswordSchema}
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={(values) => {
                  const { password, confirmPassword } = values;
                  setLoginError("");
                  setLoginMessage("");
                  doResetPassword({ password, confirmPassword, tokenId });
                }}
              >
                {({ handleSubmit, isValid, dirty, errors, touched }) => (
                  <form
                    className="form_resetpassword"
                    role="form"
                    method="post"
                    id="form_reset"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <div className="form-group mb-3">
                      <Field
                        name="password"
                        className="form-control"
                        type="password"
                        placeholder="New Password"
                        autofocus
                      />
                      {errors.password && touched.password ? (
                        <div style={{ color: "red" }}>{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="form-group mb-3">
                      <Field
                        name="confirmPassword"
                        className="form-control"
                        type="password"
                        placeholder="Confirm New Password"
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div style={{ color: "red" }}>{errors.confirmPassword}</div>
                      ) : null}
                    </div>
                    <button
                      className="btn btn-fill btn-block"
                      id="link_reset"
                      disabled={!(isValid && dirty)}
                    >
                      Reset Password
                    </button>
                    {loginError !== "" && <div style={{ color: "red" }}>{loginError}</div>}
                    {loginMessage !== "" && <div style={{ color: "green" }}>{loginMessage}</div>}
                  </form>
                )}
              </Formik>

              {validToken && resetResult && (
                <span>
                  Your password has been reset. Use your new password to
                  <a className="link" href="/go/home/login">
                    Sign in
                  </a>
                  .
                </span>
              )}
              {!validToken && (
                <span>
                  The password reset link you used has expired. Please request a new link on the
                  <a className="link" href="/go/home/forgot">
                    forgot password
                  </a>{" "}
                  page.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ResetPassword;
