import { FC } from "react";
import "../../header/headerStyles.scss";
import { BellIcon } from "../../../createdIcons";

type Props = {
  indicatorCount: number;
};

const HeaderIconButtonAlert: FC<Props> = ({ indicatorCount }) => {
  return (
    <section className="header-icon-button-alert-container">
      {indicatorCount > 0 && (
        <div className="header-menu-button-alert-indicator"> {indicatorCount} </div>
      )}

      <div className="header-menu-button-alert-button">
        <div className="alert-button-icon">
          {" "}
          <BellIcon />{" "}
        </div>
        <div className="alert-button-wording"> Alerts </div>
      </div>
    </section>
  );
};

export default HeaderIconButtonAlert;
