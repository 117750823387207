import { Component, MouseEvent } from "react";
import ReactTooltip from "react-tooltip";
import { Guid } from "js-guid";
import ReactDOM from "react-dom";
import "./buttons/buttonStyles.scss";

interface IProps {
  className?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  iconClass: string | JSX.Element;
  isDark?: boolean;
  tooltip?: string;
  tooltipPosition?: "auto" | "top" | "right" | "bottom" | "left";
  disabled?: boolean;
}
interface IState {}
export class IconButton extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    $('button[data-toggle="tooltip"]').tooltip();
  }

  render() {
    const onClick = this.props.onClick;
    const iconClass = IconButton.getIcon(this.props.iconClass);
    const className = this.props.className;
    const tooltip = this.props.tooltip;
    const buttonClass = IconButton.generateButtonClass(this.props.isDark);
    const id = new Guid().toString();

    let tooltipPosition;
    if (this.props.tooltipPosition !== "auto") tooltipPosition = this.props.tooltipPosition;

    return (
      <>
        {tooltip && (
          <>
            {ReactDOM.createPortal(
              <ReactTooltip id={id} place={tooltipPosition} effect="float">
                {tooltip}
              </ReactTooltip>,
              document.body,
            )}
          </>
        )}

        <button
          data-tip
          data-for={id}
          type="button"
          className={`${buttonClass} ${className ?? ""}`}
          onClick={onClick}
          disabled={this.props.disabled}
        >
          {iconClass}
        </button>
      </>
    );
  }

  private static generateButtonClass(isDark?: boolean) {
    if (!isDark) return "icon-btn-type-light";
    else return "icon-btn-type-dark";
  }

  private static getIcon(iconClass: string | JSX.Element) {
    const isIconClassString = typeof iconClass == "string";

    if (isIconClassString) return <i className={iconClass as string} />;
    else return iconClass as JSX.Element;
  }
}
