import { FC, FocusEvent, RefObject, useEffect, useRef, useState } from "react";
import { AlertFeed, AlertsFeedSortColumn } from "../AlertsFeedPage";
import { InputWithIcon } from "../../../components/inputs/InputWithIcon";
import { Button, ButtonType } from "../../../components/Button";
import {
  AlertPriority,
  DeviceAlertPrioritySelect,
} from "../../../components/layout/components/alertNotifications/AlertNotificationHelper";
import { AlertApiService } from "../../../services/api/AlertApiService";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { Alert } from "../../../models/api/Alert";
import UseOutsideClick from "../../../hooks/useOutsideClick";

import "./alerts-list-styles.scss";
import { Guid } from "js-guid";
import moment from "moment";

type Props = {
  isLoading: boolean;
  alerts: AlertFeed[];
  sortColumn: "device" | "priorityLevel" | "alertMessage" | "timestamp" | "alertTriggered";
  sortDirection: "asc" | "desc";
  searchText: string;
  observerRowTarget: RefObject<HTMLTableRowElement>;
  alertNameFromAlertWidget: string;
  handleSort: (sortColumn: AlertsFeedSortColumn) => void;
  handleSearch: (text: string) => void;
  handleFilterAlerts: (alerts: Alert[]) => void;
};

type State = {
  alertsNames: Alert[];
  selectedAlertsNames: Alert[];
  showAlertsNames: boolean;
};

const AlertsList: FC<Props> = ({
  alerts,
  sortColumn,
  sortDirection,
  searchText,
  observerRowTarget,
  alertNameFromAlertWidget,
  handleSort,
  handleSearch,
  handleFilterAlerts,
  isLoading,
}) => {
  const [filterText, setFilterText] = useState(searchText);
  const [state, setState] = useState<State>({
    alertsNames: [],
    selectedAlertsNames: [],
    showAlertsNames: false,
  });

  const refAlertsNamesContent = useRef<HTMLDivElement>(null);

  UseOutsideClick(refAlertsNamesContent, () => {
    if (state.showAlertsNames) {
      setState((prevState) => ({
        ...prevState,
        showAlertsNames: false,
      }));
    }
  });

  const handleToggleAlertNames = () => {
    setState((prevState) => ({
      ...prevState,
      showAlertsNames: !prevState.showAlertsNames,
    }));
  };

  async function fetchAlertsNames() {
    const orgId = JwtTokenHelpers.getOrgId();

    const response = await new AlertApiService().getAlertsNameByOrgId(orgId);

    if (alertNameFromAlertWidget) {
      const alert = response.find(
        (a) => a.name!.toLocaleLowerCase() === alertNameFromAlertWidget.toLocaleLowerCase(),
      );

      if (alert) {
        const index = response.indexOf(alert);
        const newAlertsNames = response;

        if (index > -1) {
          newAlertsNames.splice(index, 1);
        }

        setState((prevState) => ({
          ...prevState,
          alertsNames: newAlertsNames,
          selectedAlertsNames: [alert],
        }));

        handleFilterAlerts([alert]);
      }
    } else {
      const alertsNames: Alert[] = response.map((a) => ({ ...a }));

      setState((prevState) => ({
        ...prevState,
        alertsNames,
      }));
    }
  }

  useEffect(() => {
    fetchAlertsNames();
  }, []);

  async function snoozeAlert(alert: AlertFeed, snoozeUntilDate: string) {
    if (alert.snoozeUntil) {
      await new AlertApiService().unsnooze(`${alert.deviceAlertId}`);
    } else {
      await new AlertApiService().snooze(`${alert.deviceAlertId}`, snoozeUntilDate);
    }
  }

  const getIcon = (column: string) => {
    if (column === sortColumn) {
      if (sortDirection === "asc") {
        return <i className="fas fa-sort-up" />;
      } else {
        return <i className="fas fa-sort-down" />;
      }
    }

    return <i className="fas fa-sort" />;
  };

  const handleOnChange = (e: FocusEvent<HTMLInputElement>): void => {
    setFilterText(e.target.value);
    handleSearch(e.target.value);
  };

  const handleRemoveSelectedAlertName = (alert: Alert) => {
    window.history.replaceState({}, document.title);
    const newAlertsNames = [...state.alertsNames, alert].sort((a, b) =>
      a.name! > b.name! ? 1 : -1,
    );

    setState((prevState) => ({
      ...prevState,
      alertsNames: newAlertsNames,
    }));

    const index = state.selectedAlertsNames.indexOf(alert);

    if (index > -1) {
      const newSelectedAlertsNames = state.selectedAlertsNames;
      newSelectedAlertsNames.splice(index, 1);

      setState((prevState) => ({
        ...prevState,
        selectedAlertsNames: newSelectedAlertsNames,
      }));

      handleFilterAlerts(newSelectedAlertsNames);
    }
  };

  const handleSelectAlertName = (alert: Alert) => {
    const newSelectedAlertsNames = [...state.selectedAlertsNames, alert].sort((a, b) =>
      a.name! > b.name! ? 1 : -1,
    );

    setState((prevState) => ({
      ...prevState,
      selectedAlertsNames: newSelectedAlertsNames,
    }));

    const index = state.alertsNames.indexOf(alert);

    if (index > -1) {
      const newAlertsNames = state.alertsNames;
      newAlertsNames.splice(index, 1);

      setState((prevState) => ({
        ...prevState,
        alertsNames: newAlertsNames,
      }));
    }

    handleFilterAlerts(newSelectedAlertsNames);
  };

  const GetSelectedAlertsNames = () => {
    if (state.selectedAlertsNames.length === 0) {
      return <div className="selected-alert-name-container" />;
    }

    return (
      <div className="selected-alert-name-container">
        {state.selectedAlertsNames.map((a) => (
          <button
            key={a.id}
            type="button"
            className="selected-alert-name"
            onClick={() => handleRemoveSelectedAlertName(a)}
          >
            {a.name}
            <i className="fas fa-times" />
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="alerts-list-container">
      <div className="filter-table-wrapper">
        <div className="search-input-wrapper">
          <InputWithIcon
            idName="alert-feed-filter-value"
            placeholder="Search Alerts"
            iconClass="fa-sharp fa-solid fa-magnifying-glass"
            onChange={handleOnChange}
            inputValue={filterText}
          />
        </div>
        <div ref={refAlertsNamesContent} className="filter-alerts-wrapper">
          <GetSelectedAlertsNames />

          <button
            className="btn-type-transparent sm"
            onClick={() => {
              const csv = [
                // Header
                [
                  "Device",
                  "Priority Level",
                  "Alert Triggered",
                  "Alert Message",
                  "Measurement Caused",
                  "Tags",
                  "Timestamp",
                ],
                // Items
                ...alerts.map((alert) => [
                  `"${alert.deviceName}"`,
                  AlertPriority[alert.priority],
                  `"${alert.alertName}"`,
                  `"${alert.alertMessage}"`,
                  alert.measurementValueCaused,
                  `"${alert.tagNames}"`,
                  `"${alert.dateString}"`,
                ]),
              ]
                .map((row) => row.join(","))
                .join("\n");

              const url = URL.createObjectURL(new Blob([csv], { type: "text/csv;charset=utf-8;" }));

              const link = document.createElement("a");
              link.setAttribute("href", url);
              link.setAttribute("download", "alerts-feed-export.csv");
              link.style.visibility = "hidden";

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            <span className="mr-2">
              <i className="fa-sharp fa-solid fa-arrow-down-to-bracket" aria-hidden="true"></i>
            </span>
            Export
          </button>

          <Button
            className="filter-alerts-names-button"
            content="Filter Alerts"
            buttonType={ButtonType.Outline}
            iconClass="fas fa-filter"
            onClick={handleToggleAlertNames}
            small
          />

          {!!state.showAlertsNames && (
            <div className="alerts-names-content">
              <div className="alerts-names-card">
                {state.alertsNames.map((a) => (
                  <button
                    key={a.id}
                    className="alerts-name-item-buttom"
                    onClick={() => handleSelectAlertName(a)}
                  >
                    {a.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="alerts-table-wrapper">
        <table className="alerts-table">
          <thead>
            <tr className="alerts-table-row">
              <th className="table-header device">
                Device
                <button type="button" onClick={() => handleSort("device")}>
                  {getIcon("device")}
                </button>
              </th>
              <th className="table-header priorityLevel">
                Priority Level
                <button type="button" onClick={() => handleSort("priorityLevel")}>
                  {getIcon("priorityLevel")}
                </button>
              </th>
              <th className="table-header alertTriggered">
                Alert Triggered
                <button type="button" onClick={() => handleSort("alertTriggered")}>
                  {getIcon("alertTriggered")}
                </button>
              </th>
              <th className="table-header alertMessage">
                Alert Message
                <button type="button" onClick={() => handleSort("alertMessage")}>
                  {getIcon("alertMessage")}
                </button>
              </th>
              <th className="table-header measurementCaused">Measurement Caused</th>
              <th className="table-header measurementCaused">Tags</th>
              <th className="table-header timestamp">
                Timestamp (UTC)
                <button type="button" onClick={() => handleSort("timestamp")}>
                  {getIcon("timestamp")}
                </button>
              </th>
              <th className="table-header actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!alerts.length && !isLoading && (
              <tr>
                <td colSpan={6} className="alerts-list-no-results">
                  No results
                </td>
              </tr>
            )}
            {alerts.map((alert, index) => {
              let addObserverDiv = false;

              // if alerts has less than or equal to 10 rows, need to trigger at least once
              if (alerts.length <= 10 && index === 0) {
                addObserverDiv = true;
                // add row reference to the middle of page to fetch when scrolling down
              } else if (alerts.length > 10 && alerts.length - 4 === index) {
                addObserverDiv = true;
              }

              return (
                <TableRow
                  key={new Guid().toString()}
                  row={alert}
                  addObserverDiv={addObserverDiv}
                  observerRowTarget={observerRowTarget}
                  snoozeFunc={snoozeAlert}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AlertsList;

const TableRow = ({
  row,
  addObserverDiv,
  observerRowTarget,
  snoozeFunc,
}: {
  row: AlertFeed;
  addObserverDiv: boolean;
  observerRowTarget: RefObject<HTMLTableRowElement>;
  snoozeFunc: (alertId: AlertFeed, snoozeUntilDate: string) => void;
}) => {
  const [alert, setAlert] = useState(row);

  const doSnooze = () => {
    const snoozeUntilDate = moment().utc().add(1, "days");
    snoozeFunc(alert, snoozeUntilDate.format());
    if (alert.snoozeUntil && alert.snoozeUntil != "") {
      setAlert((alert) => ({
        ...alert,
        snoozeUntil: undefined,
      }));
    } else {
      setAlert((alert) => ({
        ...alert,
        snoozeUntil: snoozeUntilDate.format("MMMM, DD YYYY HH:mm:ss"),
      }));
    }
  };

  return (
    <tr
      key={`row-${alert.deviceAlertInfoId}`}
      className="alerts-table-row"
      ref={addObserverDiv ? observerRowTarget : null}
    >
      <td>
        <a href={`/go/dashboard/device/${alert.deviceId}`} className="widget-table-item-row">
          {alert.deviceName}
        </a>
      </td>
      <td>
        <DeviceAlertPrioritySelect alert={alert} />
      </td>
      <td>{alert.alertName}</td>
      <td>{alert.alertMessage}</td>
      <td>{alert.measurementValueCaused}</td>
      <td>{alert.tagNames}</td>
      <td>{alert.dateString}</td>
      <td>
        <Button
          className="ml-3"
          tooltip={
            alert.snoozeUntil
              ? `Snoozed until: ${alert.snoozeUntil} UTC`
              : "Snooze alert for 24 hours"
          }
          onClick={() => doSnooze()}
          content={alert.snoozeUntil ? "Un-snooze" : "Snooze"}
          small
        />
      </td>
    </tr>
  );
};
