import { FC } from "react";
import "../cardStyles.scss";
import { Guid } from "js-guid";
import { useHistoricalChartsFilter } from "../../../pages/simpleapp/components/widgets/context/historical-charts-filter";

type Props = {
  chartTitle: string;
  chart: JSX.Element;
  properties?: { key: string | JSX.Element; value?: string | number }[];
  hasWarning?: boolean;
  warning?: JSX.Element;
  //    durationResolutionFilter?: JSX.Element;
  showFilters?: boolean;
};

const ChartCard: FC<Props> = ({
  chartTitle,
  chart,
  properties,
  hasWarning,
  warning,
  //    durationResolutionFilter
  showFilters,
}) => {
  const { filterButton } = useHistoricalChartsFilter();

  return (
    <div className="chart-card-container">
      <div className="chart-card-title">
        <>
          {`${chartTitle}`}
          {!!showFilters && <>{filterButton}</>}
        </>
      </div>

      <div className="chart-card-chart-display">
        <>{hasWarning && !!warning && <>{warning}</>}</>

        <div className="chart-card-info">
          {!!properties && properties.length > 0 && (
            <div className="chart-card-dets">
              <>
                {properties.map((prop) => {
                  return (
                    <div className="chart-card-info-item" key={new Guid() as unknown as number}>
                      <p className="chart-card-item-key"> {prop.key} </p>
                      <p className="chart-card-item-value">
                        {prop.value ? prop.value.toString() : "No Value"}
                      </p>
                    </div>
                  );
                })}
              </>
            </div>
          )}
        </div>

        <div className="chart-card-graphs">{chart}</div>
      </div>
    </div>
  );
};

export default ChartCard;
