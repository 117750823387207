import { ApiService } from "./ApiService";
import {
  Device,
  DeviceBundle,
  DeviceConfig,
  DeviceMeasurementData,
  DeviceMessage,
  DeviceSummary,
  MessageCountSummary,
} from "../../models/api/Device";
import moment from "moment";
import { DeviceTypeAttribute } from "../../models/api/DeviceType";
import { apiEndPoint } from "../../config";
import { DeviceClaimTokenValidationResult } from "../../models/api/DeviceClaimTokenValidationResult";
import { Measurement } from "../../models/api/Measurement";
import { Tag } from "../../models/api/Tag";
import { TableFilters } from "../../models/api/TableFilters";
import { PagedResults } from "../../models/api/PagedResults";
import DateHelpers from "../../helpers/DateHelpers";
import { Promise } from "bluebird";
import { IDeviceTypeCustomProperty } from "../../models/api/DeviceTypeCustomProperty";
import { DeviceDebugData } from "../../models/api/DeviceDebugData";
import cSharpApi from "./CSharpApi";
import axios from "axios";

export class DevicesApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "devices");
  }

  list(
    productType: "sensor" | "controller" | "gateway" | "oem" | "any",
    tableFilters?: TableFilters,
    includePlan?: boolean,
    includeAddon?: boolean,
    fastPath?: boolean,
  ): Promise<PagedResults<Device>> {
    let queryParameters = `?productType=${productType}${
      tableFilters != undefined ? "&" + tableFilters.toQueryParameterString() : ""
    }`;
    if (includePlan !== undefined) {
      queryParameters += `&includePlan=${includePlan}`;
    }
    if (includeAddon !== undefined) {
      queryParameters += `&includeAddon=${includeAddon}`;
    }
    if (fastPath) {
      queryParameters += `&fastPath=true`;
    }
    return new Promise<PagedResults<Device>>((resolve, reject, cancel) => {
      this.get(queryParameters, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                claimToken: d.CLAIMTOKEN,
                deviceTypeId: d.DEVICETYPEID,
                productTypeId: d.PRODUCTTYPEID,
                deviceType: d.DEVICETYPENAME,
                productType: d.PRODUCTTYPENAME,
                isVirtual: d.ISVIRTUAL,
                createdAt: moment.utc(d.CREATEDAT).local(),
                warrantyStatus:
                  d.WARRANTYSTATUS != undefined && d.WARRANTYSTATUS != ""
                    ? moment(new Date(d.WARRANTYSTATUS))
                    : undefined,
                startDate:
                  d.STARTDATE != undefined && d.STARTDATE != ""
                    ? moment(new Date(d.STARTDATE))
                    : undefined,
                platformAccessStatus:
                  d.PLATFORMACCESSSTATUS != undefined && d.PLATFORMACCESSSTATUS != ""
                    ? moment(new Date(d.PLATFORMACCESSSTATUS))
                    : undefined,
                satelliteAccessStatus:
                  d.SATELLITEACCESSSTATUS != undefined && d.SATELLITEACCESSSTATUS != ""
                    ? moment(new Date(d.SATELLITEACCESSSTATUS))
                    : undefined,
                deviceGroups: d.DEVICEGROUPS,
                isActive: d.ISACTIVE,
                lastSeen: moment.utc(d.LASTSEEN).local(),
                serialNumber: d.SERIALNUMBER,
                shortId: d.SHORTID,
                swarmDeviceId: d.SWARMDEVICEID,
                simIccId: d.SIMICCID,
                latitude: d.LATITUDE != "" ? parseFloat(d.LATITUDE) : undefined,
                longitude: d.LONGITUDE != "" ? parseFloat(d.LONGITUDE) : undefined,
                cellularSendTimes: d.CELLULARSENDTIMES,
                satelliteSendTimes: d.SATELLITESENDTIMES,
                tankArea: d.TANKAREA,
                tankDepth: d.TANKDEPTH,
                bluetoothAddress: d.BLUETOOTHADDRESS,
                tags: d.TAGNAME?.split(",") ?? [],
                planId: d.PLANID,
                addOnIds: d.ADDONIDS,
                dashboardTemplateId: d.DASHBOARDTEMPLATE,
              }) as Device,
          );
          resolve({
            items: devices,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceTypeAttrbiutes(deviceId: string): Promise<DeviceTypeAttribute[]> {
    return new Promise<DeviceTypeAttribute[]>((resolve, reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}/deviceTypeAttributes`, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                dataType: d.DATATYPE,
                deivceTypeId: d.DEVICETYPEID,
                minimum: d.MINIMUM,
                maximum: d.MAXIMUM,
                formula: d.FORMULA,
                hasRange: d.HASRANGE,
                unit: d.UNIT,
              }) as DeviceTypeAttribute,
          );
          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getById(deviceId: string): Promise<Device> {
    return new Promise<Device>((resolve, reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          const device: Device = {
            id: d.ID,
            orgId: d.ORGID,
            name: d.NAME,
            ownerName: d.OWNERNAME,
            claimToken: d.CLAIMTOKEN,
            deviceTypeId: d.DEVICETYPE,
            deviceType: d.DEVICETYPENAME,
            productTypeId: d.PRODUCTTYPE,
            dashboardTemplateId: d.DASHBOARDTEMPLATE,
            createdAt: moment.utc(d.CREATEDAT).local(),
            warrantyStatus:
              d.WARRANTYSTATUS != undefined && d.WARRANTYSTATUS != ""
                ? moment.utc(d.WARRANTYSTATUS).local()
                : undefined,
            startDate:
              d.STARTDATE != undefined && d.STARTDATE != ""
                ? moment.utc(d.STARTDATE).local()
                : undefined,
            platformAccessStatus:
              d.PLATFORMACCESSSTATUS != undefined && d.PLATFORMACCESSSTATUS != ""
                ? moment.utc(d.PLATFORMACCESSSTATUS).local()
                : undefined,
            satelliteAccessStatus:
              d.SATELLITEACCESSSTATUS != undefined && d.SATELLITEACCESSSTATUS != ""
                ? moment.utc(d.SATELLITEACCESSSTATUS).local()
                : undefined,
            deviceGroups: d.DEVICEGROUPS,
            isActive: d.ISACTIVE,
            isVirtual: d.ISVIRTUAL,
            lastSeen: moment.utc(d.LASTSEEN).local(),
            serialNumber: d.SERIALNUMBER,
            shortId: d.SHORTID,
            swarmDeviceId: d.SWARMDEVICEID,
            accessGroupIds: d.ACCESSGROUPIDS,
            deviceTypeImagePath: d.DEVICETYPEIMAGEPATH,
            latitude: d.LATITUDE != "" ? parseFloat(d.LATITUDE) : undefined,
            longitude: d.LONGITUDE != "" ? parseFloat(d.LONGITUDE) : undefined,
            cellularSendTimes: d.CELLULARSENDTIMES,
            satelliteSendTimes: d.SATELLITESENDTIMES,
            tankArea: d.TANKAREA,
            tankDepth: d.TANKDEPTH,
            orgName: d.ORGANIZATION,
            authToken: d.AUTHTOKEN,
            hwVersion: d.HWVERSION,
            fwVersionNina: d.FWVERSIONNINA,
            fwVersionPic: d.FWVERSIONPIC,
            bluetoothAddress: d.BLUETOOTHADDRESS,
            notes: d.NOTES,
            satelliteSerial: d.SATELLITESERIAL,
            usesSwarmProd2Login: d.USESSWARMPROD2LOGIN,
            swarm2Way: d.SWARM2WAY,
            riverMountDepth: d.RIVERMOUNTDEPTH,
            ultrasonicMountHeight: d.ULTRASONICMOUNTHEIGHT,
            simIccId: d.SIMICCID,
            timestamp:
              d.TIMESTAMP != undefined && d.TIMESTAMP != ""
                ? moment.utc(d.TIMESTAMP).local()
                : undefined,
            locationTimestamp:
              d.LOCATIONTIMESTAMP != undefined && d.LOCATIONTIMESTAMP != ""
                ? moment.utc(d.LOCATIONTIMESTAMP).local()
                : undefined,
            lastLatitude: d.LASTLATITUDE,
            lastLongitude: d.LASTLONGITUDE,
            currentFirmware: d.CURRENTFIRMWARE,
            latestFirmware: d.LATESTFIRMWARE,
            currentFirmwareKnown: d.CURRENTFIRMWAREKNOWN,
            properties: d.PROPERTIES.map((item: any) => {
              return {
                Id: item.ID,
                Label: item.LABEL,
                Value: item.VALUE,
                Type: item.TYPENAME,
              } as IDeviceTypeCustomProperty;
            }),
            dailyMessagesCount: d.DAILYMESSAGESCOUNT != undefined ? d.DAILYMESSAGESCOUNT : 0,
            monthlyMessagesCount: d.MONTHLYMESSAGESCOUNT != undefined ? d.MONTHLYMESSAGESCOUNT : 0,
            organizationDashboardId:
              d.ORGANIZATIONDASHBOARDID != undefined ? d.ORGANIZATIONDASHBOARDID : undefined,
            installerName: d.INSTALLERNAME,
            installDate:
              d.INSTALLDATE != undefined && d.INSTALLDATE != ""
                ? moment.utc(d.INSTALLDATE).local()
                : undefined,
            emei: d.EMEI,
            installStatusFlag: d.INSTALLSTATUSFLAG,
            installNotes: d.INSTALLNOTES,
            tagIds: [],
            tags: [],
          };
          resolve(device);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getConfigById(deviceId: string): Promise<DeviceConfig | undefined> {
    return new Promise<DeviceConfig | undefined>((resolve, _reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}/config`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          const config: DeviceConfig = this.mapDataToConfig(d);
          resolve(config);
        } else {
          resolve(undefined);
        }
      });
    });
  }

  listConfigs(): Promise<DeviceConfig[]> {
    return new Promise<DeviceConfig[]>((resolve, reject, cancel) => {
      this.get(`config`, cancel).done((result) => {
        if (result.success) {
          const configs = result.data.map((d: any) => this.mapDataToConfig(d));
          resolve(configs);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listConfigsByOrg(orgId: string): Promise<DeviceConfig[]> {
    return new Promise<DeviceConfig[]>((resolve, reject, cancel) => {
      this.get(`config/organization/${orgId}`, cancel).done((result) => {
        if (result.success) {
          const configs = result.data.map((d: any) => this.mapDataToConfig(d));
          resolve(configs);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAllConfigs(): Promise<DeviceConfig[]> {
    return new Promise<DeviceConfig[]>((resolve, reject, cancel) => {
      this.get(`config/all`, cancel).done((result) => {
        if (result.success) {
          const configs = result.data.map((d: any) => this.mapDataToConfig(d));
          resolve(configs);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  setSamplingSchedule(
    deviceId: string,
    sampleIntervalMinute: number,
    sampleIntervalHour: number,
    sampleOffsetHour: number,
  ): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `${deviceId.toLowerCase()}/config/samplingSchedule`,
        {
          sampleIntervalMinute: sampleIntervalMinute,
          sampleIntervalHour: sampleIntervalHour,
          sampleOffsetHour: sampleOffsetHour,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  setDeviceConfig(deviceId: string, configurations: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject, cancel) => {
      this.post(`${deviceId.toLowerCase()}/config`, configurations, cancel).done((result) => {
        if (result.success) {
          resolve(true);
        } else {
          if (result.errorMessage) {
            console.error(result.errorMessage);
            reject(result.errorMessage);
          } else {
            console.error("Failed to update device configuration. Reason: Unknown");
            reject("Failed to update device configuration. Please try again later");
          }
        }
      });
    });
  }

  setTransmissionSchedule(
    deviceId: string,
    transmitIntervalMinute: number,
    transmitScheduleHour: number,
  ): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `${deviceId.toLowerCase()}/config/transmissionSchedule`,
        {
          transmitIntervalMinute: transmitIntervalMinute,
          transmitScheduleHour: transmitScheduleHour,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getMessagesForDevice(deviceId: string): Promise<DeviceMessage[]> {
    return new Promise<DeviceMessage[]>((resolve, reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}/messages`, cancel).done((result) => {
        if (result.success) {
          const messages = result.data.map(
            (d: any) =>
              ({
                sRawData: d.SRAWDATA,
                iLength: d.ILENGTH,
                iTime: d.ITIME,
                time: moment.utc(d.TIME).local(),
                sProtocol: d.SPROTOCOL,
              }) as DeviceMessage,
          );
          resolve(messages);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAttributes(deviceId: string): Promise<DeviceTypeAttribute[]> {
    return new Promise<DeviceTypeAttribute[]>((resolve, reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}/attributes`, cancel).done((result) => {
        if (result.success) {
          const DeviceTypeAttributes = result.data.map(
            (d: any) =>
              ({
                id: d.ID ?? "",
                deviceTypeId: d.DEVICETYPEID ?? "",
                name: d.NAME ?? "",
                dataType: d.DATATYPE,
                hasRange: d.HASRANGE ?? "",
                minimum: d.MINIMUM ?? "",
                maximum: d.MAXIMUM ?? "",
                unit: d.UNIT ?? "",
                formula: d.FORMULA ?? "",
              }) as DeviceTypeAttribute,
          );
          resolve(DeviceTypeAttributes);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  update(deviceId: string, device: Device): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`${deviceId.toLowerCase()}`, device, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          reject(result);
        }
      });
    });
  }

  updateTags(deviceId: string, tags?: string[]): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      let tagData = tags;
      if (tags == undefined || tags.length == 0) {
        tagData = [""];
      }
      const postData = { tags: tagData };
      this.post(`${deviceId.toLowerCase()}/tags`, postData, cancel).done((resultInner) => {
        if (resultInner.success) {
          resolve();
        } else {
          console.error(resultInner.errorMessage);
          reject(resultInner.message);
        }
      });
    });
  }

  bulkUpdateTags(deviceIds: string, addTags?: string[], removeTags?: string[]): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      let addTagData = addTags;
      if (addTags == undefined || addTags.length == 0) {
        addTagData = [""];
      }

      let removeTagData = removeTags;
      if (removeTags == undefined || removeTags.length == 0) {
        removeTagData = [""];
      }

      const postData = {
        deviceIds: deviceIds,
        addTags: addTagData,
        removeTags: removeTagData,
      };
      this.post(`update/tags`, postData, cancel).done((resultInner) => {
        if (resultInner.success) {
          resolve();
        } else {
          console.error(resultInner.errorMessage);
          reject(resultInner.message);
        }
      });
    });
  }

  bulkUpdateAccessGroups(
    deviceIds: string,
    addAccessGroups?: string[],
    removeAccessGroups?: string[],
  ): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      let addAccessGroupData = addAccessGroups;
      if (addAccessGroups == undefined || addAccessGroups.length == 0) {
        addAccessGroupData = [""];
      }

      let removeAccessGroupData = removeAccessGroups;
      if (removeAccessGroups == undefined || removeAccessGroups.length == 0) {
        removeAccessGroupData = [""];
      }

      const postData = {
        deviceIds: deviceIds,
        addAccessGroups: addAccessGroupData,
        removeAccessGroups: removeAccessGroupData,
      };
      this.post(`update/accessGroups`, postData, cancel).done((resultInner) => {
        if (resultInner.success) {
          resolve();
        } else {
          console.error(resultInner.errorMessage);
          reject(resultInner.message);
        }
      });
    });
  }

  listPlanDevices(): Promise<Device[]> {
    return new Promise<Device[]>((resolve, reject, cancel) => {
      this.get(`plan`, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                claimToken: d.CLAIMTOKEN,
                deviceType: d.DEVICETYPE,
                deviceTypeId: d.DEVICETYPEID,
                productType: d.PRODUCTTYPE,
                productTypeId: d.PRODUCTTYPEID,
                createdAt: moment.utc(d.CREATEDAT).local(),
                deviceGroups: d.DEVICEGROUPS,
                isActive: d.ISACTIVE,
                lastSeen: moment.utc(d.LASTSEEN).local(),
                serialNumber: d.SERIALNUMBER,
                shortId: d.SHORTID,
                swarmDeviceId: d.SWARMDEVICEID,
              }) as Device,
          );
          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAll(tableFilters?: TableFilters): Promise<PagedResults<Device>> {
    return new Promise<PagedResults<Device>>((resolve, reject, cancel) => {
      this.get(
        `all${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                ownerName: d.OWNERNAME,
                serialNumber: d.SERIALNUMBER,
                shortId: d.SHORTID,
                claimToken: d.CLAIMTOKEN,
                swarmDeviceId: d.SWARMDEVICEID,
                simIccId: d.SIMICCID,
                deviceType: d.DEVICETYPENAME,
                productType: d.PRODUCTTYPENAME,
                deviceTypeId: d.DEVICETYPE,
                productTypeId: d.PRODUCTTYPE,
                bluetoothAddress: d.BLUETOOTHADDRESS,
                orgName: d.ORGNAME,
                orgId: d.ORGID,
                createdAt: moment.utc(d.CREATEDAT).local(),
                lastSeen: moment.utc(d.LASTSEEN).local(),
                isActive: d.ISACTIVE,
                tankArea: d.TANKAREA,
                tankDepth: d.TANKDEPTH,
                planId: d.PLANID,
                addOnIds: d.ADDONIDS,
                dashboardTemplateId: d.DASHBOARDTEMPLATE,
              }) as Device,
          );
          resolve({
            items: devices,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAllByOrg(orgId: string, tableFilters?: TableFilters): Promise<PagedResults<Device>> {
    return new Promise<PagedResults<Device>>((resolve, reject, cancel) => {
      this.get(
        `all/${orgId}${
          tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""
        }`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                ownerName: d.OWNERNAME,
                serialNumber: d.SERIALNUMBER,
                shortId: d.SHORTID,
                claimToken: d.CLAIMTOKEN,
                swarmDeviceId: d.SWARMDEVICEID,
                simIccId: d.SIMICCID,
                deviceType: d.DEVICETYPENAME,
                productType: d.PRODUCTTYPENAME,
                deviceTypeId: d.DEVICETYPE,
                productTypeId: d.PRODUCTTYPE,
                bluetoothAddress: d.BLUETOOTHADDRESS,
                orgName: d.ORGNAME,
                orgId: d.ORGID,
                createdAt: moment.utc(d.CREATEDAT).local(),
                lastSeen: moment.utc(d.LASTSEEN).local(),
                isActive: d.ISACTIVE,
              }) as Device,
          );
          resolve({
            items: devices,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  search(tableFilters?: TableFilters): Promise<PagedResults<Device>> {
    return new Promise<PagedResults<Device>>((resolve, reject, cancel) => {
      this.get(
        `search${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                ownerName: d.OWNERNAME,
                serialNumber: d.SERIALNUMBER,
                shortId: d.SHORTID,
                claimToken: d.CLAIMTOKEN,
                swarmDeviceId: d.SWARMDEVICEID,
                simIccId: d.SIMICCID,
                deviceType: d.DEVICETYPENAME,
                productType: d.PRODUCTTYPENAME,
                deviceTypeId: d.DEVICETYPE,
                productTypeId: d.PRODUCTTYPE,
                bluetoothAddress: d.BLUETOOTHADDRESS,
                orgName: d.ORGNAME,
                orgId: d.ORGID,
                createdAt: moment.utc(d.CREATEDAT).local(),
                lastSeen: moment.utc(d.LASTSEEN).local(),
                isActive: d.ISACTIVE,
                tankArea: d.TANKAREA,
                tankDepth: d.TANKDEPTH,
                planId: d.PLANID,
                addOnIds: d.ADDONIDS,
                dashboardTemplateId: d.DASHBOARDTEMPLATE,
              }) as Device,
          );
          resolve({
            items: devices,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  validateClaimToken(claimToken: string): Promise<DeviceClaimTokenValidationResult> {
    return new Promise<DeviceClaimTokenValidationResult>((resolve, reject, cancel) => {
      this.get(`claim/validate?claimToken=${encodeURIComponent(claimToken)}`, cancel).done(
        (result) => {
          if (result.success) {
            resolve({
              deviceId: result.deviceId,
              deviceType: result.deviceType,
              deviceTypeId: result.deviceTypeId,
              deviceSerialNum: result.deviceSerialNum,
              productType: result.productType,
              productTypeId: result.productTypeId,
            } as DeviceClaimTokenValidationResult);
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        },
      );
    });
  }

  listUserDevices(orgId: string): Promise<Device[]> {
    return new Promise<Device[]>((resolve, reject, cancel) => {
      this.get(`${orgId}/userDevices`, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                serialNumber: d.SERIALNUMBER,
                name: d.NAME,
              }) as Device,
          );
          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getMeasurements(orgId: string): Promise<Measurement[]> {
    return new Promise<Measurement[]>((resolve, reject, cancel) => {
      this.get(`${orgId}/measurements`, cancel).done((result) => {
        if (result.success) {
          const measurements: Measurement[] = [];

          result.data.forEach((item: any) => {
            item.values.forEach((innerItem: any) => {
              measurements.push({ id: innerItem[0], name: innerItem[0] });
            });
          });

          resolve(measurements);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  create(newDevice: Device): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("", newDevice, cancel).done((result) => {
        if (result.success) {
          resolve(result.releaseId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  generateVirtual(name: string): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post("generateVirtual", { name: name }, cancel).done((result) => {
        if (result.success) {
          resolve(result.deviceId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  claimDevice(
    claimToken: string,
    deviceName: string,
    accessGroupIds: string[],
    deviceTankArea?: string,
    deviceTankDepth?: string,
  ): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `claim`,
        {
          claimToken: claimToken,
          deviceName: deviceName,
          accessGroupIds: accessGroupIds,
          deviceTankArea: deviceTankArea,
          deviceTankDepth: deviceTankDepth,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  moveDevices(devices: string[], orgId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `move`,
        {
          devices: devices,
          orgId: orgId,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  removeDevices(deviceId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${deviceId.toLowerCase()}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  sendMessage(deviceId: string, messageText: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(
        `${deviceId.toLowerCase()}/messages`,
        {
          message: messageText,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deviceSummary(): Promise<DeviceSummary[]> {
    return new Promise<DeviceSummary[]>((resolve, reject, cancel) => {
      this.get("summary", cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                bluetoothAddress: d.BLUETOOTHADDRESS,
                // da DASHBOARDTEMPLATE,
                swarmDeviceId: d.SWARMDEVICEID,
                serialNumber: d.SERIALNUMBER,
                lastSeen: d.LASTSEEN,
                tags: d.TAGNAME?.split(",") ?? [],
                productTypeId: d.PRODUCTTYPEID,
                deviceTypeId: d.DEVICETYPEID,
                isActive: d.ISACTIVE,
                name: d.NAME,
                productType: d.PRODUCTTYPE,
                measurements: d.MEASUREMENTS?.split(",") ?? [],
                shortId: d.SHORTID,
                deviceType: d.DEVICETYPE,
                isVirtual: d.ISVIRTUAL,
                createdAt: moment.utc(d.CREATEDAT).local(),
                id: d.ID,
                claimToken: d.CLAIMTOKEN,
                // "DEVICEGROUPS": null,
                simIccId: d.SIMICCID,
              }) as DeviceSummary,
          );

          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceBundle(deviceId: string): Promise<DeviceBundle> {
    return new Promise<DeviceBundle>((resolve, reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}/bundle`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          const deviceBundle: DeviceBundle = {
            deviceId: d.DEVICEID,
            planId: d.PLANID,
            addOnIds: d.ADDONIDS,
            planDateActivated:
              d.PLANDATEACTIVATED != undefined && d.PLANDATEACTIVATED != ""
                ? moment(new Date(d.PLANDATEACTIVATED))
                : undefined,
            planDateRenewal:
              d.PLANDATERENEWAL != undefined && d.PLANDATERENEWAL != ""
                ? moment(new Date(d.PLANDATERENEWAL))
                : undefined,
            addOnDateActivated:
              d.ADDONDATEACTIVATED != undefined && d.ADDONDATEACTIVATED != ""
                ? moment(new Date(d.ADDONDATEACTIVATED))
                : undefined,
            presale: d.PRESALE,
          };
          resolve(deviceBundle);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listTagsByDevice(deviceId: string): Promise<Tag[]> {
    return new Promise<Tag[]>((resolve, reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}/tags`, cancel).done((result) => {
        if (result.success) {
          const tags = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.TAGNAME,
                value: d.TAGNAME,
                label: d.TAGNAME,
                recordCreatedOn:
                  d.RECORDCREATEDON != undefined && d.RECORDCREATEDON != ""
                    ? moment(new Date(d.RECORDCREATEDON))
                    : undefined,
              }) as Tag,
          );
          resolve(tags);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listSuggestedTagsByDevice(deviceId: string): Promise<Tag[]> {
    return new Promise<Tag[]>((resolve, reject, cancel) => {
      this.get(`${deviceId.toLowerCase()}/suggestedTags`, cancel).done((result) => {
        if (result.success) {
          const tags = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.TAGNAME,
                recordCreatedOn:
                  d.RECORDCREATEDON != undefined && d.RECORDCREATEDON != ""
                    ? moment(new Date(d.RECORDCREATEDON))
                    : undefined,
              }) as Tag,
          );
          resolve(tags);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceMessagesCount(deviceId: string, start?: Date, end?: Date): Promise<number> {
    return new Promise<number>((resolve, reject, cancel) => {
      const params = new URLSearchParams();
      if (start && end) {
        params.append("start", DateHelpers.formatDateAsServerString(start));
        params.append("end", DateHelpers.formatDateAsServerString(end));
      }

      this.get(`${deviceId.toLowerCase()}/messagesCount?${params}`, cancel).done((result) => {
        if (result.success) {
          resolve(result.data);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceMessagesCountSummary(deviceId: string): Promise<MessageCountSummary> {
    return new Promise<MessageCountSummary>((resolve, reject) => {
      this.get(`${deviceId.toLowerCase()}/messagesCountSummary`).done((result) => {
        if (result.success) {
          const messageCountSummary: MessageCountSummary = {
            dailyMessagesCount: result.dailyMessagesCount,
            monthlyMessagesCount: result.monthlyMessagesCount,
          };
          resolve(messageCountSummary);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  exportDeviceMeasurements(deviceIds: string[], start: Date, end: Date): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `fpm/measurements/export`,
        {
          startDate: DateHelpers.formatDateAsServerString(start),
          endDate: DateHelpers.formatDateAsServerString(end),
          deviceIds: deviceIds.join(","),
        },
        cancel,
      ).done((result) => {
        if (result.SUCCESS) {
          resolve(result.MESSAGE);
        } else {
          console.error(result.errorMessage);
          reject(result.MESSAGE);
        }
      });
    });
  }

  fetchMeasurementData(
    deviceIds: string[],
    start: Date,
    end: Date,
  ): Promise<DeviceMeasurementData> {
    return new Promise<DeviceMeasurementData>((resolve, reject, cancel) => {
      this.post(
        `${deviceIds.join(",")}/measurements/view`,
        {
          deviceIds: deviceIds.join(","),
          startDate: DateHelpers.formatDateAsServerString(start),
          endDate: DateHelpers.formatDateAsServerString(end),
        },
        cancel,
      ).done((result) => {
        if (result.SUCCESS) {
          resolve({
            columns: result.columns,
            data: result.flattenedData,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.MESSAGE);
        }
      });
    });
  }

  getDevicesCount(): Promise<number> {
    return new Promise<number>((resolve, reject, cancel) => {
      this.get(`count`, cancel).done((result) => {
        if (result.success) {
          resolve(result.count);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceDebugData(
    deviceId: string,
    tableFilters?: TableFilters,
    start?: Date,
    end?: Date,
  ): Promise<PagedResults<DeviceDebugData>> {
    return new Promise<PagedResults<DeviceDebugData>>((resolve, reject, cancel) => {
      const requestUrl = `${deviceId.toLowerCase()}/debugData${
        tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""
      }`;

      const params = new URLSearchParams();
      if (start && end) {
        params.append("start", DateHelpers.formatDateAsServerString(start));
        params.append("end", DateHelpers.formatDateAsServerString(end));
      }

      this.get(`${requestUrl}?${params}`, cancel).done((result) => {
        if (result.success) {
          const debugData = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                orgId: d.ORGID,
                deviceId: d.DEVICEID,
                debugString: JSON.parse(d.DEBUGSTRING),
                timestamp:
                  d.TIMESTAMP != undefined && d.TIMESTAMP != ""
                    ? moment(new Date(d.TIMESTAMP)).local()
                    : undefined,
              }) as DeviceDebugData,
          );
          resolve({
            items: debugData,
            totalCount: result.totalCount,
          } as PagedResults<DeviceDebugData>);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceInstallPhoto(deviceId: string): Promise<string> {
    const cancelTokenSource = axios.CancelToken.source();
    return new Promise<string>((resolve, reject) => {
      cSharpApi
        .get(`photos/device/${deviceId.toLowerCase()}/installer`, {
          data: {
            deviceId,
          },
          cancelToken: cancelTokenSource.token,
          responseType: "arraybuffer",
        })
        .then((response) => {
          const buffer = Buffer.from(response.data).toString("base64");
          resolve(buffer);
        })
        .catch((err) => {
          console.error(err.errorMessage);
          reject(err.message);
        });
    });
  }

  private mapDataToConfig(d: any): DeviceConfig {
    return {
      port2Period: d["i-port2-period"],
      port1Period: d["i-port1-period"],
      bluetoothMaxScanCount: d["i-bluetooth-max-scan-count"],
      sampleOffsetHour: d["i-sample-offset-hour"],
      sampleIntervalHour: d["i-sample-interval-hour"],
      sampleIntervalMinute: d["i-sample-interval-minute"],
      maxSendsPerCycle: d["i-max-sends-per-cycle"],
      time: moment.utc(d.time).local(),
      transmitIntervalMinute: d["i-transmit-interval-minute"],
      port2Profile: d["i-port2-profile"],
      port1PeriodOffset: d["i-port1-period-offset"],
      port2PeriodOffset: d["i-port2-period-offset"],
      port1PeriodConfig: d["i-port1-period-config"],
      port1Profile: d["i-port1-profile"],
      uuid: d["uuid"],
      port2PeriodConfig: d["i-port2-period-config"],
      transmitScheduleHour: d["i-transmit-schedule-hour"],
      lastConfigTime: !d.lastConfigTime ? undefined : moment.utc(d.lastConfigTime).local(),
      // eslint-disable-next-line
      swarmWindow0: d["i-swarm-window0"] === -1 ? 18446744073709551615 : d["i-swarm-window0"],
      swarmWindow1: d["i-swarm-window1"],
    };
  }
}
