import moment, { Moment } from "moment";

export default {
  formatDateRange(startDate: Moment, endDate: Moment): string {
    if (startDate.isSame(moment(), "day") && endDate.isSame(moment(), "day")) {
      return "Today";
    }

    if (
      startDate.isSame(moment().subtract(1, "day"), "day") &&
      endDate.isSame(moment().subtract(1, "day"), "day")
    ) {
      return "Yesterday";
    }

    if (
      startDate.isSame(moment().startOf("week"), "day") &&
      endDate.isSame(moment().endOf("week"), "day")
    ) {
      return "This Week";
    }

    if (
      startDate.isSame(moment().subtract(1, "week").startOf("week"), "day") &&
      endDate.isSame(moment().subtract(1, "week").endOf("week"), "day")
    ) {
      return "Last Week";
    }

    if (
      startDate.isSame(moment().startOf("month"), "day") &&
      endDate.isSame(moment().endOf("month"), "day")
    ) {
      return "This Month";
    }

    if (
      startDate.isSame(moment().subtract(1, "month").startOf("month"), "day") &&
      endDate.isSame(moment().subtract(1, "month").endOf("month"), "day")
    ) {
      return "Last Month";
    }

    if (
      startDate.isSame(moment().startOf("year"), "day") &&
      endDate.isSame(moment().endOf("year"), "day")
    ) {
      return "This Year";
    }

    if (
      startDate.isSame(moment().subtract(1, "year").startOf("year"), "day") &&
      endDate.isSame(moment().subtract(1, "year").endOf("year"), "day")
    ) {
      return "Last Year";
    }

    if (endDate.isSame(moment(), "day") && endDate.diff(startDate, "days") < 7) {
      return `Last ${endDate.diff(startDate, "days") + 1} Days`;
    }

    const startString = startDate ? this.formatMomentAsDateTime(startDate) : "Unknown";
    const endString = endDate ? this.formatMomentAsDateTime(endDate) : "Unknown";
    return `${startString}–${endString}`;
  },

  formatDateAsDateTime(date: Date): string {
    return this.formatMomentAsDateTime(moment(date));
  },

  formatMomentAsDateTime(dateTime?: Moment): string {
    return dateTime?.format("Do MMM YYYY, h:mm a") ?? "No Time";
  },

  formatMomentAsTime(dateTime?: Moment): string {
    return dateTime?.format("h:mm a") ?? "No Time";
  },

  stringToNullableMoment(str?: string): Moment | undefined {
    if (!str) return undefined;
    return moment(new Date(str));
  },

  formatMoment(moment: Moment, offset?: number, dateOnly?: boolean): string {
    if (dateOnly) {
      if (offset) moment.add(offset, "hours").format("MMMM Do, YYYY");
      return moment.format("MMMM Do, YYYY");
    }

    if (offset) moment.add(offset, "hours").format("Do MMM YYYY, h:mm a");
    return moment.format("Do MMM YYYY, h:mm a");
  },

  formatMomentAsTimeSince(moment?: Moment) {
    if (moment == undefined || !moment.isValid()) return "Never";
    return moment.fromNow();
  },

  formatMomentAsTimeBetween(from: Moment, to: Moment) {
    return to.from(from);
  },

  formatDateAsServerString(date: Date): string {
    return moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
  },

  formatMomentAsServerString(moment: Moment): string {
    return moment.utc().format("YYYY-MM-DD HH:mm:ss");
  },

  formatDateTimeAsString(date: Date): string {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  },

  formatDateTimeAsStringWithFormat(date: Moment, format: string): string {
    return moment(date).format(format);
  },

  formatDateStringOnly(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
  },

  formatMomentAsYearMonth(date: Date): string {
    return moment(date).format("YYYYMM");
  },

  formatDateRangeAsString(startDate?: Date, endDate?: Date): string {
    if (startDate == undefined || endDate == undefined) return "";

    const getFormat = ({
      year,
      date,
      time,
      seconds,
    }: {
      year: boolean;
      date: boolean;
      time: boolean;
      seconds: boolean;
    }): string => {
      let format = "";

      if (year) {
        format += "YYYY-";
      }
      if (date) {
        format += "MM-DD ";
      }
      if (time) {
        format += "HH:mm";
        if (seconds) {
          format += ":ss";
        }
      }

      return format;
    };
    const start = moment(startDate);
    const end = moment(endDate);
    const startFormat = getFormat({
      year: true,
      date: true,
      time: true,
      seconds: start.seconds() !== 0 || end.seconds() !== 0,
    });
    const endFormat = getFormat({
      year: start.year() !== end.year(),
      date:
        start.month() !== end.month() || start.date() !== end.date() || start.year() !== end.year(),
      time: true,
      seconds: start.seconds() !== 0 || end.seconds() !== 0,
    });

    return `${start.format(startFormat)} to ${end.format(endFormat)}`;
  },

  getLastSeenBadge(lastSeenDate: Moment) {
    const today = moment();
    let diff = today.diff(lastSeenDate, "minutes");
    let label = "";

    if (diff < 1) {
      diff = today.diff(lastSeenDate, "seconds");
      label = "sec";
    } else if (diff <= 59) {
      label = "min";
    } else if (diff > 59 && diff < 1440) {
      diff = today.diff(lastSeenDate, "hours");
      label = "hour";
    } else {
      diff = today.diff(lastSeenDate, "days");
      label = "day";
    }

    return `Last seen ${diff} ${label}${diff > 1 ? "s" : ""} ago`;
  },
};
