import { FC, useEffect, useState } from "react";
import "../iconStyles.scss";

type Props = {
  percentage: number;
};

const CylinderIcon: FC<Props> = ({ percentage }) => {
  const [iconFill, setIconFill] = useState("cylinder-fill-empty");
  const [iconColor, setIconColor] = useState("fg-color-primary-theme");

  useEffect(() => {
    if (percentage) {
      const errorLevel = 25;
      const warningLevel = 50;
      const midFull = 75;

      if (percentage < errorLevel) {
        setIconFill("cylinder-fill-empty");
        setIconColor("fg-color-error");
      } else if (percentage >= errorLevel && percentage <= warningLevel) {
        setIconFill("cylinder-fill-half");
        setIconColor("fg-color-warning");
      } else if (percentage >= warningLevel && percentage <= midFull) {
        setIconFill("cylinder-fill-three-quarters");
      } else {
        setIconFill("cylinder-fill-full");
      }
    }
  }, [percentage]);

  return (
    <>
      <div className="cylinder-container">
        <div className={`cylinder-body ${iconColor}`}>
          <div className={iconFill} />
        </div>
      </div>
    </>
  );
};

export default CylinderIcon;
