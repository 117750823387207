import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import {
  ConfigState,
  INITIAL_CONFIG_STATE,
  CurrentWidgetMeasurementConfig,
  WidgetIcons,
  DASHBOARD_WIDGET_CLASS,
} from "../../../SimpleAppTemplate";
import { LevelProgressCard } from "../../../../../../components/cards";
import { ErrorNoData } from "../../index";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import useCurrentWaterVol from "../hooks/useCurrentWaterVol";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import DateHelpers from "../../../../../../helpers/DateHelpers";
import { CylinderIcon } from "../../../../../../components/createdIcons";
import SupportLink from "../../../../../../helpers/SupportLink";
import { getWidgetIcons } from "../../WidgetHelper";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  measurement: CurrentWidgetMeasurementConfig;
};

const WaterVolume: FC<Props> = ({ device, orgId, measurement }) => {
  const [icons, setIcons] = useState<WidgetIcons>({
    icon: "",
    errorIcon: "fa-regular fa-droplet-slash",
  });
  const [label, setLabel] = useState<string>("Tank Volume");
  const [hideIfNoData, setHideIfNoData] = useState<boolean>(false);
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const { fetchData, data, isLoading, hasError, errorMessage } = useCurrentWaterVol(device.id);

  useEffect(() => {
    if (measurement) {
      // Required
      const name = measurement.name;
      const fieldKey = measurement.fieldKey;
      const tankArea = device.tankArea;

      // Optional
      const tankDepth = device.tankDepth != 0 ? device.tankDepth : 0;
      const displayName = measurement.displayName;
      const fieldName = measurement.fieldName;
      const portNumber = parseInt(measurement.port ?? "0");
      const label = measurement.label;

      let hasConfigError = !name || !fieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Measurement Name and/or FieldKey configured for this widget",
        });
      }

      hasConfigError = !tankArea;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg:
            "Tank Area for this device is not configure. Please configure Tank Area and Tank Depth for this device",
        });
      }

      if (measurement.hideIfNoData) setHideIfNoData(measurement.hideIfNoData);
      if (measurement.icon) setIcons(getWidgetIcons(measurement.icon));
      if (label) setLabel(label);

      hasConfigError = !name || !fieldKey || !tankArea;
      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            name,
            fieldKey,
            tankArea,
            tankDepth,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            displayName,
            fieldName,
            portNumber,
            orgId,
          });
        };

        getData();
      }
    } else {
      setConfigError({
        hasConfigError: true,
        errorMsg: "No Measurement Information is configured for this widget",
      });
    }
  }, [measurement, orgId]);

  /** Note: To get the current fill volume (current water the tank has) of the tank we need:
   *      Sensor Measurement (stored in mm) and Cross-section Area (provided by the users)
   * Note: To get the maximum volume (maximum capacity the tank can hold) of the tank we need:
   *      Tank Depth (provided by the users) and Cross-section Area (provided by the users)
   *
   * There are 4 different state we can display to the users depending on the information we have
   * 1.) No Data for the past 30 days
   * 2.) We have current fill volume and maximum volume (this is the complete data)
   * 3.) We only have current fill volume (cannot show percentage)
   * 4.) We DON'T have current fill volume (cannot display proper info) **/
  const GetDisplayCard = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <>
              <p>
                Click
                <span>&nbsp;</span>
                <span className="text-caption-link">
                  <a
                    href={`/go/admin/devices/${device.id}/details`}
                    className="link"
                    target="_blank"
                  >
                    here
                  </a>
                </span>
                <span>&nbsp;</span>
                to set up the data.
              </p>
              <p>
                Or Configured correctly?
                <span>&nbsp;</span>
                <SupportLink />
              </p>
            </>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="100%" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (data.hasData) {
      if (data.hasTankArea && data.hasTankDepth) {
        const totalPercentage: number = (data.currentData.value / data.currentData.maxValue) * 100;

        return (
          <LevelProgressCard
            currLevel={data.currentData.value}
            currLevelDisplay={data.currentData.value
              .toFixed(data.currentData.decimalPlaces)
              .concat(data.currentData.valueDisplayUnit)}
            maxLevel={data.currentData.maxValue}
            maxLevelDisplay={data.currentData.maxValue
              .toFixed(data.currentData.decimalPlaces)
              .concat(data.currentData.valueDisplayUnit)}
            isCritical={totalPercentage < 25}
            cardCaption={`${label}`}
            iconClass={
              icons.icon != "" ? icons.icon : <CylinderIcon percentage={totalPercentage} />
            }
            iconPos="left"
            lastSeen={DateHelpers.getLastSeenBadge(data.currentData.time)}
          />
        );
      } else if (data.hasTankArea && !data.hasTankDepth) {
        return (
          <LevelProgressCard
            currLevel={data.currentData.value}
            currLevelDisplay={data.currentData.value
              .toFixed(data.currentData.decimalPlaces)
              .concat(data.currentData.valueDisplayUnit)}
            isCritical={false}
            cardCaption={`${label}`}
            iconClass={icons.icon != "" ? icons.icon : <CylinderIcon percentage={100} />}
            iconPos="left"
            lastSeen={DateHelpers.getLastSeenBadge(data.currentData.time)}
            errorDets={
              "Tank Depth is not configured. We can provide more valuable data if this is set up."
            }
            supportQuestion={
              <>
                <p>
                  Click
                  <span>&nbsp;</span>
                  <span className="text-caption-link">
                    <a
                      href={`/go/admin/devices/${device.id}/details`}
                      className="link"
                      target="_blank"
                    >
                      here
                    </a>
                  </span>
                  <span>&nbsp;</span>
                  to set up the data.
                </p>
                <p>
                  Or, configured correctly?
                  <span>&nbsp;</span>
                  <SupportLink />
                </p>
              </>
            }
          />
        );
      } else {
        return (
          <ErrorNoData
            cardCaption={`No proper ${label} data for ${device.name}. Please make sure Tank Area and Depth is configured.`}
            supportQuestion={
              <>
                <p>
                  Click
                  <span>&nbsp;</span>
                  <span className="text-caption-link">
                    <a
                      href={`/go/admin/devices/${device.id}/details`}
                      className="link"
                      target="_blank"
                    >
                      here
                    </a>
                  </span>
                  <span>&nbsp;</span>
                  to set up the data.
                </p>
                <p>
                  Or, Expecting data?
                  <span>&nbsp;</span>
                  <SupportLink />
                </p>
              </>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }
    } else {
      if (data.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${data.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      if (!data.hasTankArea && !data.hasTankDepth) {
        return (
          <ErrorNoData
            cardCaption={`No proper ${label} data for ${device.name}. Please make sure Tank Area and Depth is configured.`}
            supportQuestion={
              <>
                <p>
                  Click
                  <span>&nbsp;</span>
                  <span className="text-caption-link">
                    <a
                      href={`/go/admin/devices/${device.id}/details`}
                      className="link"
                      target="_blank"
                    >
                      here
                    </a>
                  </span>
                  <span>&nbsp;</span>
                  to set up the data.
                </p>
                <p>
                  Or, Expecting data?
                  <span>&nbsp;</span>
                  <SupportLink />
                </p>
              </>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      if (!hideIfNoData) {
        return (
          <ErrorNoData
            cardCaption={`No ${label} data for ${device.name} in the last 30 Days`}
            // This could also be wrong configuration - Either wrong measurement name or field
            supportQuestion={
              <p>
                Expecting data?
                <span>&nbsp;</span>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return <></>;
    }
  }, [data, configError, icons]);

  const displayCard: JSX.Element = hideIfNoData ? (
    <></>
  ) : (
    <div className={DASHBOARD_WIDGET_CLASS}> {GetDisplayCard} </div>
  );
  return <>{displayCard}</>;
};

export default WaterVolume;
