import { FC, PropsWithChildren } from "react";

import "./card-container-styles.scss";

type Props = {
  title: string;
  subTitle?: string;
  iconClass?: string;
} & PropsWithChildren;

const CardContainer: FC<Props> = ({ title, subTitle, iconClass, children }) => {
  return (
    <div className="card-container">
      <div className="card-container-title-wrapper">
        <div>
          <div className="card-container-title">{title}</div>
          {!!subTitle && <p className="card-container-subtitle">{subTitle}</p>}
        </div>

        {!!iconClass && (
          <div className="card-container-icon">
            <i className={iconClass} />
          </div>
        )}
      </div>

      <div className="card-container-content">{children}</div>
    </div>
  );
};

export default CardContainer;
