import { Component, ReactChild } from "react";
import DateHelpers from "../../../helpers/DateHelpers";
import { ApiKey } from "../../../models/api/ApiKey";
import { ApiKeyApiService } from "../../../services/api/ApiKeyApiService";
import { IconButton } from "../../../components/IconButton";
import { Dialog, DialogSize } from "../../../components/Dialog";
import { Button, ButtonType } from "../../../components/Button";
import { TableFilters } from "../../../models/api/TableFilters";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../../models/api/Role";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import { LayoutContext, LayoutContextType } from "../../../contexts/layout/layout-context";

interface IProps {
  pageTitle?: ReactChild;
}
interface IState {
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
  deleteApiKeyId?: string;
  reloadTable: () => void;
  readAccess: boolean;
  writeAccess: boolean;
}

export class ApiKeysPage extends Component<IProps, IState> {
  static contextType = LayoutContext;
  constructor(props: IProps) {
    super(props);
    this.state = {
      openDeleteDialog: () => {},
      closeDeleteDialog: () => {},
      deleteApiKeyId: "",
      reloadTable: () => {},
      readAccess: true,
      writeAccess: true,
    };

    this.handleDeleteApiKey = this.handleDeleteApiKey.bind(this);
  }

  componentDidMount() {
    const userRole = JwtTokenHelpers.getUserRole();
    const readAccess = (userRole.permissionsApiKey & PermissionFlags.Read) != 0; // if true then user has Read
    const writeAccess = (userRole.permissionsApiKey & PermissionFlags.Write) != 0; //
    this.setState({ readAccess, writeAccess });

    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.removeAllExtraInfos();
      layoutContext.clearHeaderButtons();
    }
  }

  showDeleteApiKeyDialog(deleteApiKeyId: string) {
    this.setState({ deleteApiKeyId }, this.state.openDeleteDialog);
  }

  handleDeleteApiKey() {
    if (!this.state.deleteApiKeyId) return;
    new ApiKeyApiService()
      .remove(this.state.deleteApiKeyId)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "The API key was successfully deleted",
          toastOnly: true,
        });
        this.state.closeDeleteDialog();
        this.state.reloadTable();
      })
      .catch(() =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "An error occurred while loading API keys",
          toastOnly: true,
        }),
      );
  }

  generateTableButtons(item: ApiKey): ReactChild {
    if (this.state.writeAccess) {
      return (
        <div className="d-flex flex-row">
          <IconButton
            isDark={false}
            iconClass="fa fa-trash-alt"
            onClick={() => this.showDeleteApiKeyDialog(item.id ? item.id : "")}
          />
        </div>
      );
    }
    return "";
  }

  render() {
    return (
      <div className="max-width-container-xl d-flex flex-column h-100">
        <section className="content pb-4 flex-grow-1">
          <FilterTable<ApiKey>
            tableId="api-keys-table"
            fetchItemsRemotely={true}
            fetchItemsFunction={new ApiKeyApiService().list}
            initialTableFilters={new TableFilters("GroupName", true)}
            reloadFunction={(reload) => this.setState({ reloadTable: reload })}
            paginate={true}
            virtualize={true}
            columnDefinitions={[
              {
                header: "",
                notTogglable: true,
                valueFunction: (item) => this.generateTableButtons(item),
              },
              {
                header: "Group Name",
                sortable: true,
                valueFunction: (item) => (item.groupName ? item.groupName : ""),
                databaseColumn: "GroupName",
              },
              {
                header: "Name",
                sortable: true,
                valueFunction: (item) => (item.name ? item.name : ""),
                databaseColumn: "Name",
              },
              {
                header: "Token",
                sortable: true,
                valueFunction: (item) => (item.token ? item.token : ""),
                databaseColumn: "Token",
              },
              {
                header: "Date Created",
                sortable: true,
                valueFunction: (item) =>
                  item.createdAt && item.createdAt.isValid()
                    ? DateHelpers.formatMoment(item.createdAt)
                    : "",
                databaseColumn: "CreatedAt",
              },
            ]}
          />
        </section>

        <Dialog
          size={DialogSize.Small}
          header="Delete API Key"
          setOpen={(open) => this.setState({ openDeleteDialog: open })}
          setClose={(close) => this.setState({ closeDeleteDialog: close })}
          onClose={() => this.setState({ deleteApiKeyId: undefined })}
          body={<p>Are you sure you want to delete this API key?</p>}
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                buttonType={ButtonType.Transparent}
                content="Close"
                onClick={this.state.closeDeleteDialog}
              />
              <Button
                content="Delete"
                buttonType={ButtonType.Error}
                onClick={this.handleDeleteApiKey}
              />
            </>
          }
        />
      </div>
    );
  }
}
