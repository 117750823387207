/*************** **************/
/***** Dashboard Template *****/
import moment, { Moment } from "moment";

/*************** **************/

export type SimpleWidgetTypes = SimpleAppCurrentWidget | SimpleAppHistoricWidget;

export enum WidgetType {
  current = "Current",
  historic = "Historic",
}

export interface SimpleAppTemplate {
  headingLabel: string;
  current: SimpleAppCurrent;
  historic: SimpleAppHistoric;
}

export interface SimpleAppCurrent {
  columns: SimpleAppColumn<SimpleAppCurrentWidget>[];
}

export interface SimpleAppHistoric {
  columns: SimpleAppColumn<SimpleAppHistoricWidget>[];
}

export interface SimpleAppColumn<T> {
  label: string;
  rows: SimpleAppRow<T>[];
}
export interface SimpleAppRow<T> {
  label: string;
  widget: T;
}

export interface SimpleAppCurrentWidget {
  type: string;
  measurement: CurrentWidgetMeasurementConfig;
}

export interface CurrentWidgetMeasurementConfig {
  name: string;
  displayName?: string;
  fieldKey: string;
  fieldName?: string;
  port?: string;
  hideIfNoData?: boolean;
  label?: string;
  icon?: string;
  chargeMeasurement?: {
    name: string;
    displayName?: string;
    fieldKey: string;
    fieldName?: string;
    label?: string;
  };
}

export interface SimpleAppHistoricWidget {
  type: string;
  measurements: HistoricalWidgetMeasurementConfig[];
}

export interface HistoricalWidgetMeasurementConfig {
  name: string;
  displayName?: string;
  fieldKey: string;
  fieldName?: string;
  port?: string;
  label?: string;
}

/*************** **************/
/***** Constant Variables *****/
/*************** **************/

export const DASHBOARD_WIDGET_CLASS: string = "dashboard-widget";

/************************ ***********************/
/***** For using measurements from Template *****/
/************************ ***********************/

export interface ConfigState {
  hasConfigError: boolean;
  errorMsg: string;
}

export const INITIAL_CONFIG_STATE = {
  hasConfigError: false,
  errorMsg: "",
};

export interface MinMaxUnit {
  min: number;
  max: number;
  unit: string;
}

export interface WidgetIcons {
  icon: string | JSX.Element;
  errorIcon: string | JSX.Element;
}

/******************** *******************/
/***** For getting data using hooks *****/
/******************** *******************/

// For Current Widgets: Getting Data
export interface CurrentDatum {
  time: Moment;
  value: number;
  decimalPlaces: number;
  valueDisplayUnit: string;
}

export interface CurrentData {
  currentData: CurrentDatum;
  hasData: boolean;
  errorMessage: string;
}

export interface CurrentDataState {
  data: CurrentData;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
}

export const INITIAL_CURRENT_DATA_STATE: CurrentDataState = {
  data: {
    currentData: {
      time: moment(),
      value: 0,
      decimalPlaces: 0,
      valueDisplayUnit: "",
    },
    hasData: false,
    errorMessage: "",
  },
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};

// For Current Indicators: Getting Data
export interface CurrentIndicatorDatum {
  value: number;
  valueDisplayUnit: string;
}

export interface CurrentIndicatorData {
  currentData: CurrentIndicatorDatum;
  hasData: boolean;
}

export interface CurrentIndicatorDataState {
  data: CurrentIndicatorData;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
}

export const INITIAL_CURRENT_INDICATOR_STATE = {
  data: {
    currentData: {
      value: 0,
      valueDisplayUnit: "",
    },
    hasData: false,
  },
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};

export const INDICATOR_ICONS: WidgetIcons = {
  icon: "fa-regular fa-arrow-trend-up fg-color-success",
  errorIcon: "fa-regular fa-arrow-trend-down fg-color-error",
};

// For Historic Widgets: Getting Data
export interface HistoricalDatum {
  time: Moment;
}

export interface HistoricalData {
  historicalData: HistoricalDatum[];
  hasData: boolean;
  errorMessage: string;
}

export interface HistoricalDataState {
  data: HistoricalData;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
}

export const INITIAL_HISTORIC_DATA_STATE = {
  data: {
    historicalData: [],
    hasData: false,
    errorMessage: "",
  },
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};
