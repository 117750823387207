import { Component, createRef, FormEvent, ReactChild, RefObject } from "react";
import "./brandingStyles.scss";
import { Color, ColorPicker, toColor } from "react-color-palette";
import { BrandingApiService } from "../../services/api/BrandingApiService";
import { ImageUpload } from "../../components/ImageUpload";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../models/api/Role";
import { Input, InputType, StateType } from "../../components/inputs/Input";
import { FormWrapper } from "../../components/forms/FormWrapper";
import { FormGroup } from "../../components/forms/FormGroup";
import { ReactSelect } from "../../components/inputs/ReactSelect";
import { LayoutContext, LayoutContextType } from "../../contexts/layout/layout-context";
import { InputWithIcon } from "../../components/inputs/InputWithIcon";
import { FormButtons } from "../../components/forms/FormButtons";
import { EmailsAddress } from "../../helpers/EmailsAddress";

interface IProps {
  pageTitle?: ReactChild;
}

interface IState {
  companyName: string;
  themeColor: Color;
  useDarkText: boolean;
  uploadingImage: boolean;
  imgSrc?: string;
  iconImgSrc?: string;
  showColorPicker: boolean;
  isUpsellClosed: boolean;
  readAccess: boolean;
  writeAccess: boolean;
  hasChanges: boolean;
}

export class BrandingPage extends Component<IProps, IState> {
  static contextType = LayoutContext;
  private brandingApi: BrandingApiService;
  private contentWrapperRef: RefObject<HTMLDivElement>;
  constructor(props: IProps) {
    super(props);
    this.contentWrapperRef = createRef<HTMLDivElement>();
    this.brandingApi = new BrandingApiService();

    const userRole = JwtTokenHelpers.getUserRole();
    const readAccess = (userRole.permissionsBranding & PermissionFlags.Read) != 0; // if true then user has Read
    const writeAccess = (userRole.permissionsBranding & PermissionFlags.Write) != 0; //

    this.state = {
      companyName: "",
      themeColor: toColor("hex", "#323E48"),
      useDarkText: false,
      uploadingImage: false,
      showColorPicker: false,
      isUpsellClosed: this.isUpsellClosed(),
      readAccess: readAccess,
      writeAccess: writeAccess,
      hasChanges: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveTheme = this.handleSaveTheme.bind(this);
    this.handleImageInputChange = this.handleImageInputChange.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
    this.toggleColorPicker = this.toggleColorPicker.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
    this.hideColorPicker = this.hideColorPicker.bind(this);
    this.showUpsell = this.showUpsell.bind(this);
    this.handleUpsellClose = this.handleUpsellClose.bind(this);
    this.hasChanges = this.hasChanges.bind(this);
  }

  componentDidMount() {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.clearHeaderButtons();
      layoutContext.removeAllExtraInfos();
    }

    new BrandingApiService()
      .getBranding()
      .then((res) => {
        this.setState({
          companyName: res.companyName ?? "",
          imgSrc: res.logoImageUri,
          iconImgSrc: res.iconImageUri,
          themeColor: res.themeColor ? toColor("hex", res.themeColor) : toColor("hex", "#88bf40"),
          useDarkText: res.useDarkText,
        });
      })
      .catch((err) => {
        console.error(err);
      });

    // Setup click handler to close date picker
    $("body").on("click", (e) => {
      if ($(e.target).closest(".color-picker-parent").length === 0) {
        this.hideColorPicker();
      }
    });
  }

  componentWillUnmount(): void {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.clearHeaderButtons();
    }
  }

  handleInputChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const target = event.currentTarget;
    const value = target.type === "checkbox" ? (target as HTMLInputElement).checked : target.value;
    const name = target.name;

    this.setState((state) => ({
      ...state,
      [name]: value,
    }));
    this.hasChanges();
  }

  handleImageInputChange(event: FormEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      this.setState(
        {
          imgSrc: URL.createObjectURL(event.currentTarget.files[0]),
        },
        this.handleUploadImage,
      );
    }
  }

  handleDeleteTheme() {
    new BrandingApiService()
      .deleteTheme()
      .then(() => window.location.reload())
      .catch((err) => {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        });
      });
  }

  handleSaveTheme() {
    new BrandingApiService()
      .update({
        companyName: this.state.companyName,
        useDarkText: this.state.useDarkText,
        themeColor: this.state.themeColor.hex,
      })
      .then(() => {
        // need to refresh the page to update theme elsewhere.
        window.location.reload();
      })
      .catch((err) => {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        });
      });
  }

  handleUploadImage() {
    const imageInput = $("#inputUploadImage");
    const files = imageInput.prop("files");

    if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

    const file = files[0];
    const fileName = file.name;

    if (/^image/.test(file.type)) {
      // only image file
      const reader = new FileReader(); // instance of the FileReader
      this.setState({
        uploadingImage: true,
      });
      reader.onloadend = () => {
        // set image data as background of div
        const fileData = (reader.result as string).split(",")[1];
        new BrandingApiService()
          .uploadImage(fileName, fileData)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            $.notification("show", {
              type: "error",
              title: undefined,
              message: err,
              toastOnly: true,
            });
          });
      };
      reader.readAsDataURL(file); // read the local file
    }
  }

  handleDeleteImage() {
    new BrandingApiService()
      .deleteImage()
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Theme logo image removed",
          toastOnly: true,
        });
        this.setState({
          imgSrc: undefined,
        });
      })
      .catch((err) => {
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        });
      });
  }

  hasChanges() {
    this.setState({
      hasChanges: true,
    });
  }

  toggleColorPicker() {
    if (this.state.showColorPicker) {
      this.hideColorPicker();
    } else {
      this.showColorPicker();
    }
    this.hasChanges();
  }

  showColorPicker() {
    this.setState(
      { showColorPicker: true },
      () =>
        $("#color-picker").get(0)?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        }),
    );
  }

  hideColorPicker() {
    this.setState({
      showColorPicker: false,
    });
  }

  // TODO: Upsell is now part of the form instead of popup. Remove all that is related to it and not using.
  isUpsellClosed() {
    return localStorage.getItem("has-user-closed-billing-upsell") == "true";
  }

  handleUpsellClose() {
    this.setState({
      isUpsellClosed: true,
    });
    localStorage.setItem("has-user-closed-billing-upsell", "true");
  }

  showUpsell() {
    this.setState({
      isUpsellClosed: false,
    });
  }

  render() {
    const theme = JwtTokenHelpers.getOrgTheme();
    const colourVariables = {
      colorWhite: "#FFFFFF",
      colorBlackMid: "#313131",
    };

    return (
      <>
        <>
          <section className="page-structure-content">
            <div
              className="page-content-container branding-page-content"
              ref={this.contentWrapperRef}
            >
              <FormWrapper>
                <FormGroup title="Details">
                  <Input
                    idName="companyName"
                    labelString="Company Name"
                    placeholder=""
                    onChange={this.handleInputChange}
                    name="companyName"
                    inputValue={this.state.companyName}
                    inputState={!this.state.writeAccess ? StateType.Disabled : StateType.Default}
                  />

                  <div className="branding-colour-inputs">
                    <div
                      className="branding-colour-input color-picker-parent"
                      id="branding-theme-color-field"
                    >
                      <InputWithIcon
                        idName="ThemeColor"
                        labelString="Theme Color"
                        placeholder="Theme Color"
                        iconClass="fa-solid fa-square branding-thm-colour"
                        inputType={InputType.Text}
                        inputValue={this.state.themeColor.hex}
                        onClick={this.toggleColorPicker}
                        onChange={() => {}}
                      />

                      {this.state.showColorPicker && this.state.writeAccess && (
                        <div id="color-picker" className="color-picker">
                          <ColorPicker
                            width={456}
                            height={228}
                            color={this.state.themeColor}
                            onChange={(color) => {
                              this.setState({
                                themeColor: color as Color,
                              });
                              this.contentWrapperRef.current?.style.setProperty(
                                "--theme-bg-color",
                                color.hex,
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="branding-colour-input">
                      <>
                        <ReactSelect
                          idName="textColour"
                          labelString="Text Colour"
                          options={[
                            { value: "light", label: "Light" },
                            { value: "dark", label: "Dark" },
                          ]}
                          inputValue={this.state.useDarkText ? "dark" : "light"}
                          onChange={(option: any) => {
                            this.setState({
                              useDarkText: option.value == "dark",
                              hasChanges: true,
                            });
                            if (option.value === "dark") {
                              this.contentWrapperRef.current?.style.setProperty(
                                "--theme-fg-color",
                                "#101010",
                              );
                            } else {
                              this.contentWrapperRef.current?.style.setProperty(
                                "--theme-fg-color",
                                "#FFFFFF",
                              );
                            }
                          }}
                          isClearable={false}
                          // @ts-expect-error
                          isDisabled={!this.state.writeAccess}
                          hasTooltip={true}
                          tooltipText="Check if white text is too bright against your theme color"
                        />
                      </>
                    </div>
                  </div>

                  <div className="branding-colour-preview">
                    <p className="text-nav-bold"> Color Preview </p>
                    <p className="text-para-default">
                      Check your theme colour. If the text isn’t legible below, try switching the
                      text colour to light or dark above. If the icon isn’t readible, try darkening
                      your theme colour.
                    </p>

                    <div className="branding-preview-display">
                      <div
                        className="branding-display-card branding-text-display"
                        style={{
                          backgroundColor: this.state.themeColor.hex,
                          color: this.state.useDarkText
                            ? colourVariables.colorBlackMid
                            : colourVariables.colorWhite,
                        }}
                      >
                        <p className="text-page-title-bold"> Example Title </p>
                        <p className="text-para-default-bold"> Example Text </p>
                      </div>

                      <div
                        className="branding-display-card branding-icon-display"
                        style={{
                          color: this.state.themeColor.hex,
                        }}
                      >
                        <i className="fa-regular fa-house-blank" />
                      </div>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup
                  title="Domain Branding"
                  description={
                    "Domain branding gives you more control over the appearance of your portal " +
                    "by connecting your own domain and having a branded login screen."
                  }
                >
                  <div>
                    <div className="branding-domain-a-buttons">
                      <a
                        href={`mailto:${
                          theme.supportEmail ?? EmailsAddress.SupportEmail
                        }?subject=Enquiry about domain-based branding`}
                        className="btn-type-outline"
                      >
                        <i className="fa-regular fa-envelope" />
                        &nbsp; Enquire about domain branding by email
                      </a>

                      <a href="tel:+6479749150" className="btn-type-outline">
                        <i className="fa-regular fa-phone" />
                        &nbsp; Enquire about domain branding by phone
                      </a>
                    </div>
                  </div>
                </FormGroup>

                <FormGroup
                  title="Icon Image"
                  description={
                    "This image is displayed in the side navigation, " +
                    "as well as the browser tab icon (favicon)"
                  }
                >
                  <ImageUpload
                    key="icon-image"
                    title={""}
                    onUpload={this.brandingApi.uploadIcon}
                    onUploadPayload={(file: any, fileData: string) => {
                      return { file: file.name, fileData: fileData };
                    }}
                    onDelete={this.brandingApi.deleteIcon}
                    imgSrc={this.state.iconImgSrc}
                    bodyContent={<></>}
                  />
                </FormGroup>

                <FormGroup
                  title="Logo Image"
                  description={
                    "This image is displayed on the login page for domain branded instances"
                  }
                >
                  <ImageUpload
                    key="logo-image"
                    title={""}
                    onUpload={this.brandingApi.uploadImage}
                    onUploadPayload={(file: any, fileData: string) => {
                      return { file: file.name, fileData: fileData };
                    }}
                    onDelete={this.brandingApi.deleteImage}
                    imgSrc={this.state.imgSrc}
                    bodyContent={<></>}
                  />
                </FormGroup>

                <FormButtons
                  cancelUrl="/go/manage/organization/branding"
                  onSave={this.handleSaveTheme}
                  hasChanges={this.state.hasChanges}
                  isSaving={false}
                />
              </FormWrapper>
            </div>
          </section>
        </>
      </>
    );
  }
}
