import { useQuery } from "react-query";
import { RolesApiService } from "../api/RolesApiService";

const useRoles = () => {
  return useQuery(
    ["roles"],
    () => {
      const service = new RolesApiService();
      return service.listRoles();
    },
    {
      staleTime: 0,
    },
  );
};

export { useRoles };
