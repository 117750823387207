import { ReactElement } from "react";
import "./tableFilterStyles.scss";
import { TableSelectedItem } from "../../models/api/TableSelectedItem";
import { Button } from "../Button";
import { ColumnDefinition } from "./FilterTable";
import { IconButton } from "../IconButton";
import { InputWithIcon } from "../inputs/InputWithIcon";

interface IProps<T> {
  allowBulkUpdate: boolean;
  bulkActions: ReactElement | (() => ReactElement | undefined) | undefined;
  columnDefinitions: ColumnDefinition<T>[];
  customFilters?: JSX.Element[];
  disableColumnToggle?: boolean;
  downloadVisibleItems?: () => void;
  fetchItemsRemotely?: boolean;
  handleColumnToggle: (cd: ColumnDefinition<T>) => void;
  handleFilterValueChange: (filterValue: string) => void;
  hideColumnHideTutorial: () => void;
  hideSearch?: boolean;
  isReloadDisabled: boolean;
  loadServersideItems: () => void;
  reloadClassName: string;
  searchInputId: string;
  searchInputPlaceholder: string;
  selectedItems: TableSelectedItem<T>[];
  tableId: string;
}

export function TableHeader<T>(props: IProps<T>) {
  const containerClasses: string[] = ["tf-header-cont"];

  const itemsChecked = props.selectedItems.some((s) => s.isChecked);

  return (
    <div className={containerClasses.join(" ")}>
      <div className="tf-header-selected-items-buttons">
        {props.allowBulkUpdate && props.bulkActions && (
          <>
            <div id="table-settings-button" data-toggle="dropdown" data-boundary="scrollParent">
              {itemsChecked && (
                <Button
                  className="btn btn-secondary dropdown-toggle"
                  content="Actions "
                  aria-haspopup="true"
                  aria-expanded="false"
                />
              )}
            </div>

            {!itemsChecked && (
              <Button
                className="btn btn-secondary dropdown-toggle"
                content="Actions "
                disabled={true}
              />
            )}

            {typeof props.bulkActions === "function" ? props.bulkActions() : props.bulkActions}
          </>
        )}
      </div>

      {props.hideSearch ? (
        <span className="flex-grow-1 justify-content-between" />
      ) : (
        <div className="tf-header-search-input">
          <InputWithIcon
            idName={props.searchInputId}
            placeholder={props.searchInputPlaceholder}
            iconClass="fa-sharp fa-solid fa-magnifying-glass"
            onChange={(e: any) => props.handleFilterValueChange(e.target.value)}
          />
        </div>
      )}

      {props.customFilters && (
        <div className="tf-header-custom-filter">
          {props.customFilters.map((cf, i) => (
            <div key={`${props.tableId}cf${i}`}>{cf}</div>
          ))}
        </div>
      )}

      {(!props.disableColumnToggle || props.fetchItemsRemotely) && (
        <div className="tf-header-indicators">
          {props.fetchItemsRemotely && (
            <IconButton
              className={props.reloadClassName}
              iconClass="fa-sharp fa-solid fa-arrows-rotate"
              isDark={false}
              tooltip="Reload"
              disabled={props.isReloadDisabled}
              onClick={() => props.loadServersideItems()}
            />
          )}

          {props.downloadVisibleItems && (
            <IconButton
              iconClass="fa-sharp fa-solid fa-arrow-down-to-bracket"
              isDark={false}
              tooltip="Export"
              onClick={() => props.downloadVisibleItems && props.downloadVisibleItems()}
            />
          )}

          {!props.disableColumnToggle && (
            <>
              <div id="table-settings-button" data-toggle="dropdown" data-boundary="viewport">
                <IconButton
                  iconClass="fa-light fa-gear"
                  isDark={false}
                  tooltip="Table Setting"
                  onClick={() => props.hideColumnHideTutorial}
                />
              </div>
              <div className="dropdown-menu">
                <span className="dropdown-header">Column Visibility</span>
                {props.columnDefinitions
                  .filter((cd) => !cd.notTogglable)
                  .map((cd, i) => (
                    <a
                      key={`${props.tableId}cv${i}`}
                      href="#"
                      className="dropdown-item d-flex flex-row align-items-center"
                      onClick={() => props.handleColumnToggle(cd)}
                    >
                      {cd.hidden ? (
                        <i className="fa-sharp fa-solid fa-check mr-3 invisible" />
                      ) : (
                        <i className="fa-sharp fa-solid fa-check mr-3" />
                      )}
                      <div className="mr-3 flex-grow-1 text-nowrap">{cd.header}</div>
                    </a>
                  ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
