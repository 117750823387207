import { RouteComponentProps } from "react-router-dom";
import { lazy } from "react";
import { RouteConfig } from "../../AuthenticatedApp";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../models/api/Role";

const ClaimDevicePage = lazy(() =>
  import("./ClaimDevicePage").then(({ ClaimDevice }) => ({
    default: ClaimDevice,
  })),
);
const BulkOperationsPage = lazy(() =>
  import("./BulkOperationsPage").then(({ BulkOperationsPage }) => ({
    default: BulkOperationsPage,
  })),
);
const DeviceManagementPage = lazy(() =>
  import("./DeviceManagementPage").then(({ DeviceManagementPage }) => ({
    default: DeviceManagementPage,
  })),
);
const DevicesPage = lazy(() =>
  import("./DevicesPage").then(({ DevicesPage }) => ({ default: DevicesPage })),
);
const DevicePage = lazy(() =>
  import("./DevicePage").then(({ DevicePage }) => ({ default: DevicePage })),
);
const ExportMeasurementDataPage = lazy(() =>
  import("./ExportMeasurementDataPage").then(({ ExportMeasurementDataPage }) => ({
    default: ExportMeasurementDataPage,
  })),
);
const EditReportPage = lazy(() =>
  import("./report/EditReportPage").then(({ EditReportPage }) => ({ default: EditReportPage })),
);

export const DeviceRoutes: RouteConfig[] = [
  {
    path: "/go/devices/claim",
    title: "Claim a Device",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDevice",
        PermissionFlags.Write,
        <ClaimDevicePage />,
      ),
  },
  {
    path: "/go/devices/bulkOperations",
    title: "Bulk Operations",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDevice",
        PermissionFlags.Write,
        <BulkOperationsPage />,
      ),
  },
  {
    path: "/go/devices/exportMeasurementData",
    title: "Export Measurement Data",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDevice",
        PermissionFlags.Read,
        <ExportMeasurementDataPage {...props} />,
      ),
  },
  /*    {
        path: "/go/devices/deviceTransmission",
        title: "Device Transmission",
        component: (props: RouteComponentProps<any>) =>
          JwtTokenHelpers.checkPermissionForRoute(
                "permissionsDevice",
                PermissionFlags.Read,
                <DeviceTransmissionPage {...props} />)
    },*/
  /* {
        path: "/go/devices/:deviceId/latestMessages",
        title: "Device Messages",
        component: (props: RouteComponentProps<any>) =>
          JwtTokenHelpers.checkPermissionForRoute(
                "permissionsDevice",
                PermissionFlags.Read,
                <ViewDeviceMessagesPage {...props} />)
    },
    {
        path: "/go/devices/:deviceId/configure",
        title: "Configure Device",
        component: (props: RouteComponentProps<any>) =>
          JwtTokenHelpers.checkPermissionForRoute(
                "permissionsDevice",
                PermissionFlags.Write,
                <DeviceConfigPage {...props} />)
    },
    */
  {
    path: "/go/devices/:deviceId",
    title: "Edit Device",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDevice",
        PermissionFlags.Read,
        <DevicePage {...props} />,
      ),
  },
  {
    path: "/go/devicesManagement",
    title: "Devices",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDevice",
        PermissionFlags.Read,
        <DeviceManagementPage />,
      ),
  },
  {
    path: "/go/devices",
    title: "Devices",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDevice",
        PermissionFlags.Read,
        <DevicesPage {...props} />,
      ),
  },
  {
    path: "/go/reports/:deviceReportId",
    title: "Edit Report",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDevice",
        PermissionFlags.Read,
        <EditReportPage {...props} />,
      ),
  },
];
