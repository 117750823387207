import { RouteComponentProps } from "react-router-dom";
import { lazy } from "react";
import { RouteConfig } from "../../AuthenticatedApp";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../models/api/Role";

const MapPage = lazy(() => import("./MapPage").then(({ MapPage }) => ({ default: MapPage })));
const DeviceListFuncPage = lazy(() =>
  import("./DeviceListFuncPage").then(({ DeviceListFuncPage }) => ({
    default: DeviceListFuncPage,
  })),
);
const DefaultDashboard = lazy(() =>
  import("./components/DefaultDashboard").then(({ DefaultDashboard }) => ({
    default: DefaultDashboard,
  })),
);
const DeviceDashboardPage = lazy(() =>
  import("./DeviceDashboardPage").then(({ DeviceDashboardPage }) => ({
    default: DeviceDashboardPage,
  })),
);
const CustomDashboardsPage = lazy(() =>
  import("./CustomDashboardsPage").then(({ CustomDashboardsPage }) => ({
    default: CustomDashboardsPage,
  })),
);
const CustomDashboardPage = lazy(() =>
  import("./CustomDashboardPage").then(({ CustomDashboardPage }) => ({
    default: CustomDashboardPage,
  })),
);

export const DashboardRoutes: RouteConfig[] = [
  {
    path: "/go/dashboard/map",
    title: "Device Map",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDashboard",
        PermissionFlags.Read,
        <MapPage />,
      ),
  },
  {
    path: "/go/dashboard/deviceList",
    title: "Device List",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDashboard",
        PermissionFlags.Read,
        <DeviceListFuncPage />,
      ),
  },
  {
    path: "/go/dashboard/default/:deviceId",
    title: "Default Dashboard",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDashboard",
        PermissionFlags.Read,
        <DefaultDashboard {...props} />,
      ),
  },
  {
    path: "/go/dashboard/device/:deviceId",
    title: "Device Dashboard",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDashboard",
        PermissionFlags.Read,
        <DeviceDashboardPage />,
      ),
  },
  {
    path: "/go/dashboard/custom/:organizationDashboardId",
    title: "Dashboards",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDashboard",
        PermissionFlags.Read,
        <CustomDashboardPage {...props} />,
      ),
  },
  {
    path: "/go/dashboard/custom",
    title: "Dashboards",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDashboard",
        PermissionFlags.Read,
        <CustomDashboardsPage />,
      ),
  },
];
