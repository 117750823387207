import "./tableFilterStyles.scss";
import { ColumnDefinition } from "./FilterTable";

interface IProps<T> {
  item: T;
  columnDefinition: ColumnDefinition<T>;
  itemIndex: number;
  colDefIndex: number;
  showTitle?: boolean;
  isRowClickable: boolean;
  isVirtualize: boolean;
}

export function TableTD<T>(props: IProps<T>) {
  const getTitle = () => {
    let title = "";
    if (props.showTitle && props.showTitle === true) {
      const value = props.columnDefinition.valueFunction(props.item);
      if (value !== null && value !== undefined && typeof value !== "object") {
        title = value.toString();
      }
    }
    return title;
  };
  return (
    <>
      {/* Row Value */}
      {props.isVirtualize ? (
        // For virtualize rows
        <div
          className={
            "tf-td " +
            (props.isRowClickable ? "clickable " : "") +
            (props.columnDefinition.cellClassFunction
              ? props.columnDefinition.cellClassFunction(props.item, props.colDefIndex)
              : "") +
            (props.showTitle ? "td-hide-overflow " : "") +
            (props.columnDefinition.isValueCapitalize ? "td-capitalize " : "") +
            (props.colDefIndex == 0 ? "tf-td-buttons" : "")
          }
          title={`${getTitle()}`}
        >
          {props.columnDefinition.valueFunction(props.item, props.itemIndex)}
        </div>
      ) : (
        // For non virtualize rows
        <td
          className={
            (props.isRowClickable ? "clickable " : "") +
            (props.columnDefinition.cellClassFunction
              ? props.columnDefinition.cellClassFunction(props.item, props.colDefIndex)
              : "") +
            (props.columnDefinition.isValueCapitalize ? "td-capitalize" : "")
          }
        >
          {props.columnDefinition.valueFunction(props.item, props.itemIndex)}
        </td>
      )}
    </>
  );
}
