import { FC, Fragment, useEffect, useRef, useState } from "react";
import { SortDirection, SortType } from "../../../services/api/DashboardApiService";
import DateHelpers from "../../../helpers/DateHelpers";
import { useInfiniteDeviceListQuery } from "../../../services/context/DashboardApiServiceContext";
import { Guid } from "js-guid";
import { DashboardDevice } from "../../../models/api/DashboardDevice";
import { LoadingSkeleton } from "../../LoadingSkeleton";
import PowerPerformanceButton from "../../buttons/PowerPerformanceButton";

const PAGE_SIZE = 10;

type Props = {
  filter: string;
  sortBy: SortType;
  sortDirection: SortDirection;
  handleOnItemClick: (id: string) => void;
};

const MapDeviceList: FC<Props> = ({ filter, sortBy, sortDirection, handleOnItemClick }) => {
  const [selectedDevice, setSelectedDevice] = useState<DashboardDevice>();
  const [isDPPModalOpen, setIsDPPModalOpen] = useState<boolean>(false);

  const {
    data: dashboardDevices,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteDeviceListQuery({
    limit: PAGE_SIZE,
    filter,
    sortBy,
    sortDirection,
    includeDevicesWithoutLocation: false,
    includeMeasurements: false,
    includeSensorDetails: false,
  });
  const observerElem = useRef<HTMLDivElement>(null);

  // Called when div.loader element comes into view
  // It retrieves the next page of data
  const handleObserver = (entries: any) => {
    const [target] = entries;
    if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  // Observes div.loader element to trigger the next data fetch.
  // This element is placed in the middle of the last page.
  // This way the user can continue to scroll. The data loading
  // while scrolling the last items
  useEffect(() => {
    const element: HTMLDivElement | undefined | null = observerElem?.current;
    const option = { threshold: 0 };
    if (element && !isFetchingNextPage) {
      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [fetchNextPage, hasNextPage, handleObserver, filter, isFetchingNextPage]);

  const showScrollingTarget = (currentPageIndex: number, currentDetailIndex: number) =>
    currentPageIndex === dashboardDevices!.pages.length - 1 &&
    currentDetailIndex === Math.floor(PAGE_SIZE / 2);

  const handleOnDeviceItemClick = (device: DashboardDevice) => {
    setSelectedDevice(device);
    handleOnItemClick(device.id);
  };

  return (
    <>
      {dashboardDevices && (
        <div className="device-list">
          {dashboardDevices?.pages.map((page: any, pageIndex: number) => {
            const uid = new Guid().toString();
            return (
              <Fragment key={uid}>
                {page.map((d: DashboardDevice, detailIndex: number) => {
                  return (
                    <div
                      key={d.id}
                      className={
                        "device-list-item" + (selectedDevice?.id === d.id ? " selected" : "")
                      }
                      onClick={() => handleOnDeviceItemClick(d)}
                    >
                      {showScrollingTarget(pageIndex, detailIndex) && (
                        <div className="loader" ref={observerElem}>
                          {isFetchingNextPage && hasNextPage ? "Loading..." : "loading done"}
                          {hasNextPage ? "  Has Next Page" : "  No search left"}
                        </div>
                      )}

                      <div className="device-info">
                        <div className="device-name"> {d.name} </div>

                        <div className="device-caption">
                          Last seen {DateHelpers.formatMomentAsTimeSince(d.timestamp)}
                        </div>
                      </div>

                      <div className="device-buttons">
                        <PowerPerformanceButton
                          dashboardDevice={d}
                          selectedDeviceId={selectedDevice ? selectedDevice.id : ""}
                          isDPPModalOpen={isDPPModalOpen}
                          onClick={() => {
                            setIsDPPModalOpen(true);
                          }}
                          onClose={() => {
                            setIsDPPModalOpen(false);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            );
          })}
        </div>
      )}

      {!dashboardDevices && <LoadingSkeleton width="100%" height="100%" />}
    </>
  );
};

export default MapDeviceList;
