import { DashboardDevice } from "../../../../models/api/DashboardDevice";
import { FC } from "react";
import {
  DASHBOARD_WIDGET_CLASS,
  SimpleAppCurrentWidget,
  SimpleAppHistoricWidget,
  SimpleAppRow,
  SimpleWidgetTypes,
  WidgetType,
} from "../SimpleAppTemplate";
import {
  ErrorNoData,
  DeviceBatteryCharge,
  DeviceLocation,
  WaterVolume,
  WaterVolumeAndTemperature,
  WaterVolumeAndRain,
  Rainfall,
  DoubleLineTemperatureMulti3,
  AirQualityPM,
  CurrentValue,
  SingleLine,
  DoubleLine,
  SingleTemperature,
  LineTemperature,
  ColumnLineMulti,
  WindSpeedAndDirection,
} from "./index";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { HistoricalChartsFilterProvider } from "./context/historical-charts-filter";
import SupportLink from "../../../../helpers/SupportLink";

type WidgetGeneratorProps = {
  widgetType: WidgetType;
  device: DashboardDevice;
  orgId?: string;
  row: SimpleAppRow<SimpleWidgetTypes>;
};

const WidgetGenerator: FC<WidgetGeneratorProps> = ({ widgetType, device, orgId, row }) => {
  const getWidget = () => {
    switch (widgetType) {
      case WidgetType.current:
        return (
          <CurrentWidgetGenerator
            device={device}
            orgId={orgId}
            rowDef={row as SimpleAppRow<SimpleAppCurrentWidget>}
          />
        );
      case WidgetType.historic:
        return (
          <HistoricWidgetGenerator
            device={device}
            orgId={orgId}
            rowDef={row as SimpleAppRow<SimpleAppHistoricWidget>}
          />
        );
      default:
        return (
          <div className={DASHBOARD_WIDGET_CLASS}>
            <ErrorNoData
              cardCaption="We don't know this widget type."
              iconClass="fa-solid fa-triangle-exclamation"
              supportQuestion={
                <p>
                  <SupportLink />
                </p>
              }
            />
          </div>
        );
    }
  };

  return <ErrorBoundary FallbackComponent={ErrorFallback}>{getWidget()}</ErrorBoundary>;
};

const ErrorFallback = ({ error }: FallbackProps) => {
  return (
    <div className={DASHBOARD_WIDGET_CLASS}>
      <ErrorNoData
        cardCaption={`${error.message}.`}
        iconClass="fa-solid fa-triangle-exclamation"
        supportQuestion={
          <p>
            <SupportLink />
          </p>
        }
      />
    </div>
  );
};

type CurrentWidgetGeneratorProps = {
  device: DashboardDevice;
  orgId?: string;
  rowDef: SimpleAppRow<SimpleAppCurrentWidget>;
};

const CurrentWidgetGenerator: FC<CurrentWidgetGeneratorProps> = ({ device, orgId, rowDef }) => {
  const getWidget = (kindOfWidget: string) => {
    switch (kindOfWidget) {
      case "water-volume":
        return (
          <WaterVolume device={device} orgId={orgId} measurement={rowDef.widget.measurement} />
        );
      case "rainfall":
        return <Rainfall device={device} orgId={orgId} measurement={rowDef.widget.measurement} />;
      case "air-quality-pm":
        return (
          <AirQualityPM device={device} orgId={orgId} measurement={rowDef.widget.measurement} />
        );
      case "current-value":
        return (
          <CurrentValue device={device} orgId={orgId} measurement={rowDef.widget.measurement} />
        );
      case "device-battery":
        return (
          <DeviceBatteryCharge
            device={device}
            orgId={orgId}
            measurement={rowDef.widget.measurement}
          />
        );
      case "device-map":
        return <DeviceLocation device={device} orgId={orgId} />;
      default:
        return (
          <div className={DASHBOARD_WIDGET_CLASS}>
            <ErrorNoData
              cardCaption="We don't know this widget."
              iconClass="fa-solid fa-triangle-exclamation"
              iconPos="left"
              supportQuestion={
                <p>
                  <SupportLink />
                </p>
              }
            />
          </div>
        );
    }
  };

  return <>{getWidget(rowDef.widget.type.toLowerCase())}</>;
};

type HistoricWidgetProps = {
  device: DashboardDevice;
  orgId?: string;
  rowDef: SimpleAppRow<SimpleAppHistoricWidget>;
};

const HistoricWidgetGenerator: FC<HistoricWidgetProps> = ({ device, orgId, rowDef }) => {
  const getHistoricWidget = (kindOfWidget: string) => {
    switch (kindOfWidget) {
      case "water-volume-temperature":
        return (
          <HistoricalChartsFilterProvider>
            <WaterVolumeAndTemperature
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "water-volume-rain":
        return (
          <HistoricalChartsFilterProvider>
            <WaterVolumeAndRain
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "wind-speed-direction":
        return (
          <HistoricalChartsFilterProvider>
            <WindSpeedAndDirection
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "single-line":
        return (
          <HistoricalChartsFilterProvider>
            <SingleLine
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "double-line":
        return (
          <HistoricalChartsFilterProvider>
            <DoubleLine
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "single-temperature":
        return (
          <HistoricalChartsFilterProvider>
            <SingleTemperature
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "line-temperature":
        return (
          <HistoricalChartsFilterProvider>
            <LineTemperature
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "double-line-temperature-multi-3":
        return (
          <HistoricalChartsFilterProvider>
            <DoubleLineTemperatureMulti3
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      case "column-line-multi":
        return (
          <HistoricalChartsFilterProvider>
            <ColumnLineMulti
              device={device}
              orgId={orgId}
              chartLabel={rowDef.label}
              measurements={rowDef.widget.measurements}
            />
          </HistoricalChartsFilterProvider>
        );
      default:
        return (
          <ErrorNoData
            cardCaption="We don't know this widget."
            iconClass="fa-solid fa-triangle-exclamation"
            iconPos="left"
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
          />
        );
    }
  };

  return (
    <div className={DASHBOARD_WIDGET_CLASS}>
      {getHistoricWidget(rowDef.widget.type.toLowerCase())}
    </div>
  );
};

export default WidgetGenerator;
