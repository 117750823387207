import { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { AccessGroupTag, Tag } from "../../../models/api/Tag";
import { TagApiService } from "../../../services/api/TagApiService";
import { Input, StateType } from "../../../components/inputs/Input";
import PageHeaderNew from "../../../components/layout/pageHeader/PageHeaderNew";
import { TableFilters } from "../../../models/api/TableFilters";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import { IconButton } from "../../../components/IconButton";
import { PermissionFlags } from "../../../models/api/Role";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { DeviceTag } from "../../../models/api/DeviceTag";
import { PageContainerWrapper, PageContentWrapper } from "../../../components/layout";
import { FormWrapper } from "../../../components/forms/FormWrapper";
import { FormGroup } from "../../../components/forms/FormGroup";
import { useLayout } from "../../../contexts/layout/layout-context";
import { INITIAL_TAG_STATE, Tag_State, validateTagName } from "./TagHelper";
import { Guid } from "js-guid";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { Label } from "../../../components/inputs/Label";
import { FormButtons } from "../../../components/forms/FormButtons";
import Modal from "../../../components/layout/modal/Modal";
import { Button, ButtonType } from "../../../components/Button";
import { ReactSelect } from "../../../components/inputs/ReactSelect";
import { useAccessGroups } from "../../../services/context/AccessGroupApiServiceContext";
import useDevice from "../../devices/hooks/useDevice";
import { PagedResults } from "../../../models/api/PagedResults";

export interface IControllerTagsPageMatchParams {
  tagId: string;
}

interface IProps extends RouteComponentProps<IControllerTagsPageMatchParams> {}

type Page_State = {
  accessGroupsReloadTable: (() => void) | undefined;
  devicesReloadTable: (() => void) | undefined;

  isLoading: boolean;
};

type Modal_State = {
  isAddAccessGroupLinkModalOpen: boolean;
  isDeleteAccessGroupLinkModalOpen: boolean;
  isAddDeviceLinkModalOpen: boolean;
  isDeleteDeviceLinkModalOpen: boolean;
};

const INITIAL_PAGE_STATE: Page_State = {
  accessGroupsReloadTable: undefined,
  devicesReloadTable: undefined,

  isLoading: true,
};

const INITIAL_MODAL_STATE: Modal_State = {
  isAddAccessGroupLinkModalOpen: false,
  isDeleteAccessGroupLinkModalOpen: false,
  isAddDeviceLinkModalOpen: false,
  isDeleteDeviceLinkModalOpen: false,
};

const EditTagPage: FC<IProps> = ({ match }) => {
  // States for the Tag
  const [tagState, setTagState] = useState<Tag_State>(INITIAL_TAG_STATE);

  // States and Hooks for this page
  const tagApiService = new TagApiService();
  const [pageState, setPageState] = useState<Page_State>(INITIAL_PAGE_STATE);
  const [modalState, setModalState] = useState<Modal_State>(INITIAL_MODAL_STATE);
  const [hasChange, setHasChange] = useState<boolean>(false);

  const [tagAccessGroupList, setTagAccessGroupList] = useState<AccessGroupTag[]>([]);
  const { isSuccess: accessGroupsLoaded, data: accessGroups } = useAccessGroups();
  const [accessGroupOptions, setAccessGroupOptions] = useState<{ label: any; value: any }[]>([]);

  const [tagDeviceList, setTagDeviceList] = useState<DeviceTag[]>([]);
  const { getDevicesForSelectOpt, devicesForSelectOpt } = useDevice();
  const [deviceOptions, setDeviceOptions] = useState<{ label: any; value: any }[]>([]);

  const { removeAllButtons, removeAllExtraInfos } = useLayout();

  // Clear all buttons
  useEffect(() => {
    removeAllButtons();
    removeAllExtraInfos();

    Promise.all([tagApiService.getById(match.params.tagId)]).then((res) => {
      const tag = res[0];

      setTagState((prevState) => ({
        ...prevState,
        tag: tag,
        tagName: tag.name ?? "",
        isTagNameValid: !!tag.name,
      }));
      setPageState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    });
  }, []);

  useEffect(() => {
    if (accessGroupsLoaded) {
      setAccessGroupOptions(
        accessGroups!.map((ag) => {
          return {
            value: ag.id,
            label: ag.groupName,
          };
        }),
      );
    }
  }, [accessGroups]);

  useEffect(() => {
    async function getDevicesOpt() {
      await getDevicesForSelectOpt();
    }

    getDevicesOpt();
  }, []);

  useEffect(() => {
    if (devicesForSelectOpt.data.hasData) {
      setDeviceOptions(
        devicesForSelectOpt.data.data.map((deviceOpt) => {
          return {
            label: deviceOpt.name ? deviceOpt.name : deviceOpt.serialNumber,
            value: deviceOpt.id,
          };
        }),
      );
    }
  }, [devicesForSelectOpt]);

  useEffect(() => {
    accessGroupOptions.filter((o) =>
      tagAccessGroupList.find((a) => a.accessGroupId?.toUpperCase() != o.value.toUpperCase()),
    );
  }, [tagAccessGroupList]);

  useEffect(() => {
    deviceOptions.filter((o) =>
      tagDeviceList.find((d) => d.deviceId?.toUpperCase() != o.value.toUpperCase()),
    );
  }, [tagDeviceList]);

  async function fetchAccessGroupsItems(tableFilters: TableFilters) {
    const tagAccessGroups = tagApiService.getAccessGroupsByTagId(match.params.tagId, tableFilters);

    const tagAccessGroupsCopy = tagAccessGroups;
    tagAccessGroupsCopy
      .then((res: PagedResults<AccessGroupTag>) => {
        setTagAccessGroupList(res.items);
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );

    return tagAccessGroups;
  }

  async function fetchDevicesItems(tableFilters: TableFilters) {
    const tagDevices = tagApiService.getDevicesByTagId(match.params.tagId, tableFilters);

    const tagDevicesCopy = tagDevices;
    tagDevicesCopy
      .then((res: PagedResults<DeviceTag>) => {
        setTagDeviceList(res.items);
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );

    return tagDevices;
  }

  async function updateTagAsync() {
    const hasValidTagName = validateTagName(tagState.tagName, setTagState);

    if (hasValidTagName) {
      const tag: Tag = {
        id: tagState.tag?.id,
        name: tagState.tagName,
      };

      Promise.all<any>([tagApiService.update(tag)])
        .then(() => {
          $.notification("show", {
            type: "message",
            title: undefined,
            message: "The tag name was successfully updated",
            toastOnly: true,
          });
        })
        .catch((err) => {
          $.notification("show", {
            type: "error",
            title: undefined,
            message: err,
            toastOnly: true,
          });
        });
    }
  }

  async function deleteAccessGroupTagAsync(accessGroupTagId: string) {
    if (!accessGroupTagId) return;

    tagApiService
      .removeAccessGroupFromTag(accessGroupTagId)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Selected access group has been successfully unlinked from this tag",
          toastOnly: true,
        });
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );

    setTagState((prevState) => ({
      ...prevState,
      accessGroupLinkToDelete: new Guid(Guid.EMPTY).toString(),
    }));
    setModalState((prevState) => ({
      ...prevState,
      isDeleteAccessGroupLinkModalOpen: false,
    }));
    if (pageState.accessGroupsReloadTable !== undefined) pageState.accessGroupsReloadTable();
  }

  async function deleteDeviceTagAsync(deviceTagId: string) {
    if (!deviceTagId) return;

    tagApiService
      .removeDeviceFromTag(deviceTagId)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Selected device has been successfully unlinked from this tag",
          toastOnly: true,
        });
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );

    setTagState((prevState) => ({
      ...prevState,
      deviceLinkToDelete: new Guid(Guid.EMPTY).toString(),
    }));
    setModalState((prevState) => ({
      ...prevState,
      isDeleteDeviceLinkModalOpen: false,
    }));
    if (pageState.devicesReloadTable !== undefined) pageState.devicesReloadTable();
  }

  async function handleLinkAccessGroup() {
    tagApiService
      .updateAccessGroupTag(match.params.tagId, tagState.accessGroupsToAdd)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Selected access groups have been successfully linked to this tag",
          toastOnly: true,
        });

        if (pageState.accessGroupsReloadTable !== undefined) pageState.accessGroupsReloadTable();

        setModalState((prevState) => ({
          ...prevState,
          isAddAccessGroupLinkModalOpen: false,
        }));

        setTagState((prevState) => ({
          ...prevState,
          accessGroupsToAdd: [],
        }));
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );
  }

  async function handleLinkDevice() {
    tagApiService
      .updateDeviceTag(match.params.tagId, tagState.devicesToAdd)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Selected devices have been successfully linked to this tag",
          toastOnly: true,
        });

        if (pageState.devicesReloadTable !== undefined) pageState.devicesReloadTable();

        setModalState((prevState) => ({
          ...prevState,
          isAddDeviceLinkModalOpen: false,
        }));

        setTagState((prevState) => ({
          ...prevState,
          devicesToAdd: [],
        }));
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );
  }

  return (
    <>
      <PageContainerWrapper>
        <section className="page-structure-header">
          <PageHeaderNew
            pageTitle="Edit Tag"
            pageTitleType="Breadcrumb"
            breadcrumbSetting={{ backPageTitle: "Tags", backUrl: "/go/manage/tags" }}
          />
        </section>

        <PageContentWrapper>
          <FormWrapper>
            <FormGroup title="Tag Settings">
              <div className="tag-settings-content">
                {!pageState.isLoading ? (
                  <Input
                    idName="tag-name"
                    inputValue={tagState.tagName}
                    labelString="Tag Name"
                    isRequired={true}
                    inputState={tagState.isTagNameValid ? StateType.Default : StateType.Error}
                    onChange={(event) => {
                      setHasChange(true);
                      const value = event.target.value;
                      setTagState((prevState) => ({
                        ...prevState,
                        tagName: value,
                        isTagNameValid: !!value,
                      }));
                    }}
                    onBlur={() => {
                      validateTagName(tagState.tagName, setTagState);
                    }}
                  />
                ) : (
                  <div className="tag-loading-content">
                    <Label idName="tag-name-loading" labelString="Tag Name" isRequired={true} />
                    <LoadingSkeleton width="100%" height="45px" />
                  </div>
                )}
              </div>
            </FormGroup>

            <FormGroup
              title="Access Group"
              description="Access groups that are linked to this tag."
              button={
                <Button
                  content="Link Access Group"
                  iconClass="fa fa-plus"
                  buttonType={ButtonType.Transparent}
                  onClick={() => {
                    setModalState((prevState) => ({
                      ...prevState,
                      isAddAccessGroupLinkModalOpen: true,
                    }));
                  }}
                />
              }
            >
              <div className="tag-access-group-table-content">
                <FilterTable<AccessGroupTag>
                  tableId="access-groups-table"
                  paginate={true}
                  virtualize={true}
                  fetchItemsRemotely={true}
                  fetchItemsFunction={fetchAccessGroupsItems}
                  initialTableFilters={new TableFilters("GroupName", true)}
                  reloadFunction={(reload) => {
                    setPageState((prevState) => ({
                      ...prevState,
                      accessGroupsReloadTable: reload,
                    }));
                  }}
                  columnDefinitions={[
                    {
                      header: "",
                      notTogglable: true,
                      valueFunction: (item) => (
                        <>
                          <IconButton
                            isDark={false}
                            iconClass="fa fa-trash-alt"
                            tooltip="Remove access group tag"
                            onClick={() => {
                              setTagState((prevState) => ({
                                ...prevState,
                                accessGroupLinkToDelete: new Guid(item.id).toString(),
                              }));
                              setModalState((prevState) => ({
                                ...prevState,
                                isDeleteAccessGroupLinkModalOpen: true,
                              }));
                            }}
                          />
                        </>
                      ),
                    },
                    {
                      header: "Name",
                      valueFunction: (item) => {
                        return item.groupName ? (
                          <a href={`/go/manage/accessGroups/${item.accessGroupId}`}>
                            {item.groupName}
                          </a>
                        ) : (
                          ""
                        );
                      },
                      sortable: true,
                      databaseColumn: "GroupName",
                    },
                    {
                      header: "Device Count",
                      valueFunction: (item) => item.deviceCount,
                      sortable: true,
                      databaseColumn: "devicecount",
                    },
                  ]}
                />
              </div>
            </FormGroup>

            <FormGroup
              title="Devices"
              description="Devices that are linked to this tag."
              button={
                <Button
                  content="Link Device"
                  iconClass="fa fa-plus"
                  buttonType={ButtonType.Transparent}
                  onClick={() => {
                    setModalState((prevState) => ({
                      ...prevState,
                      isAddDeviceLinkModalOpen: true,
                    }));
                  }}
                />
              }
            >
              <div className="tag-devices-table-content">
                <FilterTable<DeviceTag>
                  tableId="device-tag-table"
                  paginate={true}
                  virtualize={true}
                  fetchItemsRemotely={true}
                  fetchItemsFunction={fetchDevicesItems}
                  initialTableFilters={new TableFilters("SerialNumber", true)}
                  reloadFunction={(reload) => {
                    setPageState((prevState) => ({
                      ...prevState,
                      devicesReloadTable: reload,
                    }));
                  }}
                  columnDefinitions={[
                    {
                      header: "",
                      notTogglable: true,
                      valueFunction: (item) => (
                        <>
                          {JwtTokenHelpers.checkPermission(
                            "permissionsTag",
                            PermissionFlags.Write,
                          ) && (
                            <div className="d-flex flex-row">
                              <IconButton
                                isDark={false}
                                iconClass="fa fa-trash-alt"
                                tooltip="Remove device tag"
                                onClick={() => {
                                  setTagState((prevState) => ({
                                    ...prevState,
                                    deviceLinkToDelete: new Guid(item.id).toString(),
                                  }));
                                  setModalState((prevState) => ({
                                    ...prevState,
                                    isDeleteDeviceLinkModalOpen: true,
                                  }));
                                }}
                              />
                            </div>
                          )}
                        </>
                      ),
                    },
                    {
                      header: "Serial Number",
                      sortable: true,
                      valueFunction: (item) => {
                        return item.serialNumber ? (
                          <a href={`/go/devices/${item.deviceId}`}>{item.serialNumber}</a>
                        ) : (
                          ""
                        );
                      },
                      databaseColumn: "SerialNumber",
                    },
                    {
                      header: "Name",
                      sortable: true,
                      valueFunction: (item) => (item.deviceName ? item.deviceName : ""),
                      databaseColumn: "DeviceName",
                    },
                  ]}
                />
              </div>
            </FormGroup>

            <FormButtons
              cancelUrl="/go/manage/tags"
              onSave={() => {
                updateTagAsync();
              }}
              hasChanges={hasChange}
              isSaving={false}
            />
          </FormWrapper>
        </PageContentWrapper>
      </PageContainerWrapper>

      {modalState.isAddAccessGroupLinkModalOpen && (
        <Modal
          modalTitle="Link Access Group(s)"
          onConfirm={() => {
            handleLinkAccessGroup();
          }}
          confirmButtonText="Save"
          isOnConfirmError={false}
          onClose={() => {
            setModalState((prevState) => ({
              ...prevState,
              isAddAccessGroupLinkModalOpen: false,
            }));
          }}
        >
          <div className="tag-modal-messages">
            <div className="modal-main-msg">
              Choose the access group(s) that you'd like to assign to the tag{" "}
              <b>{tagState.tagName}</b>.
            </div>
            <div className="modal-caption-msg">
              <ReactSelect
                idName="add-access-groups"
                options={accessGroupOptions}
                onChange={(options: any) => {
                  if (options != null) {
                    setTagState((prevState) => ({
                      ...prevState,
                      accessGroupsToAdd: options.map((o: any) => o.value),
                    }));
                  } else {
                    setTagState((prevState) => ({
                      ...prevState,
                      accessGroupsToAdd: [],
                    }));
                  }
                }}
                inputState={StateType.Default}
                inputValue={tagState.accessGroupsToAdd}
                isClearable={true}
                isMulti={true}
                isCreatable={false}
                placeholder="Select access group(s) to add for tag..."
              />
            </div>
          </div>
        </Modal>
      )}

      {modalState.isDeleteAccessGroupLinkModalOpen && (
        <Modal
          modalTitle="Delete Access Group Tag"
          onConfirm={() => {
            if (!new Guid(tagState.accessGroupLinkToDelete).isEmpty()) {
              deleteAccessGroupTagAsync(tagState.accessGroupLinkToDelete);
            }
          }}
          confirmButtonText="Delete Access Group Tag"
          isOnConfirmError={true}
          onClose={() => {
            setTagState((prevState) => ({
              ...prevState,
              accessGroupLinkToDelete: new Guid(Guid.EMPTY).toString(),
            }));
            setModalState((prevState) => ({
              ...prevState,
              isDeleteAccessGroupLinkModalOpen: false,
            }));
          }}
        >
          <div className="tag-modal-messages">
            <div className="modal-main-msg">
              Are you sure you want to remove the tag from this access group?
            </div>
            <div className="modal-caption-msg">
              <i className="fa-solid fa-triangle-exclamation fg-color-error" />
              Please note that all devices linked to the tag through this access group will be
              removed too.
            </div>
          </div>
        </Modal>
      )}

      {modalState.isAddDeviceLinkModalOpen && (
        <Modal
          modalTitle="Link Device(s)"
          onConfirm={() => {
            handleLinkDevice();
          }}
          confirmButtonText="Save"
          isOnConfirmError={false}
          onClose={() => {
            setModalState((prevState) => ({
              ...prevState,
              isAddDeviceLinkModalOpen: false,
            }));
          }}
        >
          <div className="tag-modal-messages">
            <div className="modal-main-msg">
              Choose the device(s) that you'd like to assign to the tag <b>{tagState.tagName}</b>.
            </div>
            <div className="modal-caption-msg">
              <ReactSelect
                idName="add-devices"
                options={deviceOptions}
                onChange={(options: any) => {
                  if (options != null) {
                    setTagState((prevState) => ({
                      ...prevState,
                      devicesToAdd: options.map((o: any) => o.value),
                    }));
                  } else {
                    setTagState((prevState) => ({
                      ...prevState,
                      devicesToAdd: [],
                    }));
                  }
                }}
                inputState={StateType.Default}
                inputValue={tagState.devicesToAdd}
                isClearable={true}
                isMulti={true}
                isCreatable={false}
                placeholder="Select device(s) to add for tag..."
              />
            </div>
          </div>
        </Modal>
      )}

      {modalState.isDeleteDeviceLinkModalOpen && (
        <Modal
          modalTitle="Delete Device Tag"
          onConfirm={() => {
            if (!new Guid(tagState.deviceLinkToDelete).isEmpty()) {
              deleteDeviceTagAsync(tagState.deviceLinkToDelete);
            }
          }}
          confirmButtonText="Delete Device Tag"
          isOnConfirmError={true}
          onClose={() => {
            setTagState((prevState) => ({
              ...prevState,
              deviceLinkToDelete: new Guid(Guid.EMPTY).toString(),
            }));
            setModalState((prevState) => ({
              ...prevState,
              isDeleteDeviceLinkModalOpen: false,
            }));
          }}
        >
          <div className="tag-modal-messages">
            <div className="modal-main-msg">
              Are you sure you want to remove the tag from this device?
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export { EditTagPage };
