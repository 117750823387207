import { Component } from "react";
import "../scss/styles.scss";

export enum IconType {
  Fill,
  Outline,
}
interface IProps {
  className?: string;
  onClick?: () => void;
  iconClass: string;
  iconType?: IconType;
  isFavourited?: boolean;
}
interface IState {}
export class Icon extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const onClick = this.props.onClick;
    const iconClass = this.props.iconClass;
    const className = this.props.className;
    const iconTypeClass = this.generateIconClass(this.props.iconType);

    return (
      <>
        <i className={`${iconClass}${iconTypeClass} ${className ?? ""}`} onClick={onClick} />
      </>
    );
  }

  private generateIconClass(iconType?: IconType) {
    if (!iconType) iconType = IconType.Fill;

    let iconTypeClass = "";

    switch (iconType) {
      case IconType.Fill:
        iconTypeClass += " icon";
        break;
      case IconType.Outline:
        iconTypeClass += "-o icon";
        break;
    }

    return iconTypeClass;
  }
}
