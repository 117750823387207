import { Component, ReactChild } from "react";
import { IconButton } from "./IconButton";
import ReactDOM from "react-dom";
import "../scss/modalStyles.scss";

export enum DialogSize {
  Small,
  Large,
  ExtraLarge,
}

interface IProps {
  size?: DialogSize;
  maxWidth?: string;
  header: ReactChild;
  tabs?: ReactChild;
  body: ReactChild;
  footer?: ReactChild;
  setOpen: (open: () => void) => void;
  setClose: (open: () => void) => void;
  onClose?: () => void;
  onShown?: () => void;
  onKeypress?: (e: any) => void;
  noPadding?: boolean;
}

interface IState {
  visible: boolean;
}

export class Dialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { visible: false };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.props.setOpen(this.open);
    this.props.setClose(this.close);
  }

  open() {
    this.setState({ visible: true }, this.props.onShown);
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }

    this.setState({ visible: false }, this.props.onClose);
  }

  private generateModalClass(size?: DialogSize) {
    let modalClass = "ms-modal ";

    switch (size) {
      case DialogSize.Small:
        modalClass += "ms-modal-sm ";
        break;
      case DialogSize.Large:
        modalClass += "ms-modal-lg ";
        break;
      case DialogSize.ExtraLarge:
        modalClass += "ms-modal-xl ";
        break;
      case undefined:
        break;
    }
    modalClass += "elevation-4";
    return modalClass;
  }

  render() {
    const modalClass = this.generateModalClass(this.props.size);

    return (
      <>
        {ReactDOM.createPortal(
          <div
            className="ms-modal-backdrop"
            onMouseDown={this.close}
            style={{
              opacity: this.state.visible ? 1 : 0,
              pointerEvents: this.state.visible ? "all" : "none",
            }}
          >
            <div
              onMouseDown={(e) => e.stopPropagation()}
              className={modalClass}
              onKeyPress={this.props.onKeypress}
              style={{
                transform: this.state.visible ? "none" : "translateY(300%)",
                maxWidth: this.props.maxWidth,
              }}
            >
              <div className="d-flex flex-column">
                <div
                  className={`ms-modal-header d-flex flex-column p-2 
                                    ${this.props.tabs ? "ms-modal-header-w-tabs" : ""}`}
                >
                  <div className="modal-header-title-container">
                    <h5 className="ml-2 m-0 flex-grow-1 text-modal-header">{this.props.header}</h5>
                    <IconButton
                      iconClass="fas fa-times"
                      onClick={() => {
                        if (this.props.onClose) this.props.onClose();
                        this.close();
                      }}
                    />
                  </div>
                  {this.props.tabs && (
                    <div className="modal-header-tabs-container">{this.props.tabs}</div>
                  )}
                </div>
                <div
                  className={`ms-modal-body d-flex flex-column ${
                    this.props.noPadding ? "" : "p-3"
                  }`}
                >
                  {this.props.body}
                </div>
                {this.props.footer && (
                  <div className="ms-modal-footer d-flex flex-row align-items-center">
                    {this.props.footer}
                  </div>
                )}
              </div>
            </div>
          </div>,
          document.body,
        )}
      </>
    );
  }
}
