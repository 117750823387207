import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../models/api/DashboardDevice";
import { BatteryGauge } from "./BatteryGauge";
import { HistoricalChartsFilterProvider } from "../../../pages/simpleapp/components/widgets/context/historical-charts-filter";
import { CurrentWidgetMeasurementConfig } from "../../../pages/simpleapp/components/SimpleAppTemplate";
import { DPPWidget, ErrorNoData } from "../../../pages/simpleapp/components/widgets";
import SupportLink from "../../../helpers/SupportLink";
import Modal from "../../layout/modal/Modal";

type Props = {
  dashboardDevice: DashboardDevice;
  dppMeasurement: { battery: string; batteryKey: string; charge: string; chargeKey: string };
  selectedDeviceId: string;
  isDPPModalOpen: boolean;
  onClick: () => void;
  onClose: () => void;
};

const INITIAL_MEASUREMENT_STATE: CurrentWidgetMeasurementConfig = {
  name: "",
  fieldKey: "",
  chargeMeasurement: {
    name: "",
    fieldKey: "",
  },
};

const DPPButton: FC<Props> = ({
  dashboardDevice,
  dppMeasurement,
  selectedDeviceId,
  isDPPModalOpen,
  onClick,
  onClose,
}) => {
  const icons: { icon: string | JSX.Element; errorIcon: string | JSX.Element } = {
    icon: "",
    errorIcon: "fa-regular fa-chart-column",
  };
  const [measurement, setMeasurement] = useState<{
    measurements: CurrentWidgetMeasurementConfig;
    hasMeasurements: boolean;
  }>({ measurements: INITIAL_MEASUREMENT_STATE, hasMeasurements: false });

  useEffect(() => {
    if (dppMeasurement) {
      setMeasurement({
        measurements: {
          name: dppMeasurement.battery,
          fieldKey: dppMeasurement.batteryKey,
          chargeMeasurement: {
            name: dppMeasurement.charge,
            fieldKey: dppMeasurement.chargeKey,
          },
        },
        hasMeasurements: true,
      });
    }
  }, [dppMeasurement]);

  const getDPPWidget = useMemo(() => {
    if (measurement.hasMeasurements) {
      return (
        <DPPWidget
          device={dashboardDevice}
          orgId={dashboardDevice.orgId}
          measurement={measurement.measurements}
        />
      );
    }

    return (
      <ErrorNoData
        cardCaption="No Measurements and Battery Information for this widget."
        supportQuestion={
          <p>
            Configured correctly?
            <span>&nbsp;</span>
            <SupportLink />
          </p>
        }
        iconClass={icons.errorIcon}
        iconPos="left"
      />
    );
  }, [measurement]);

  return (
    <>
      <button
        type="button"
        className="btn-device-power-performance bg-color-white-70 fg-color-black-mid"
        onClick={onClick}
      >
        <BatteryGauge item={dashboardDevice} label={true} />
        <small className="text-caption-bold">Device Power Performance</small>
      </button>

      {isDPPModalOpen && selectedDeviceId == dashboardDevice.id && (
        <Modal modalTitle={`${dashboardDevice.name}`} onClose={onClose}>
          <HistoricalChartsFilterProvider>{getDPPWidget}</HistoricalChartsFilterProvider>
        </Modal>
      )}
    </>
  );
};

export default DPPButton;
