import "./inputStyles.scss";

export enum XButtonState {
  Default,
  Critical,
  Warning,
  Disabled,
}

export enum XButtonSize {
  Small, //font-size: 11px
  Large, //font-size: 16px
}

interface IProps {
  size?: XButtonSize;
  xButtonState?: XButtonState;
  isOnPill?: boolean;
  onDelete: (...args: any) => void;
}

export function XButton(props: IProps) {
  const xButtonClasses: string[] = [];
  // let indicatorClass = props.isOnPill ? "pill-indicators" : "input-indicators";
  // xButtonClasses.push(indicatorClass);
  props.isOnPill
    ? xButtonClasses.push("pill-indicators")
    : props.size == XButtonSize.Small
      ? xButtonClasses.push("input-indicators sm")
      : xButtonClasses.push("input-indicators");
  xButtonClasses.push(generateStateClass(props.xButtonState));

  return (
    <div className={xButtonClasses.join(" ")}>
      <i className="fa-sharp fa-solid fa-circle-xmark" onClick={props.onDelete} />
    </div>
  );
}

function generateStateClass(xButtonState?: XButtonState) {
  if (!xButtonState) xButtonState = XButtonState.Default;
  let stateClass: string = "xButton-";

  switch (xButtonState) {
    case XButtonState.Default:
      stateClass += "default";
      break;
    case XButtonState.Critical:
      stateClass += "critical";
      break;
    case XButtonState.Warning:
      stateClass += "warning";
      break;
    case XButtonState.Disabled:
      stateClass += "disabled";
      break;
  }

  return stateClass;
}
