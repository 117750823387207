import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import * as d3 from "d3";

import "./D3DevicePowerPerformanceStyle.scss";
import LeftAxis from "./LeftAxis/LeftAxis";
import RightAxis from "./RightAxis/RightAxis";
import ChartArea from "./ChartArea/ChartArea";
import { Guid } from "js-guid";

type BoundingProps = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
  x: number;
  y: number;
};

const InitialBoundingProps = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

type Props = {
  data: Array<{
    time: string;
    charge: number;
    battery: number;
    label: string;
  }>;
  chargingMinValue: number;
  chargingMaxValue: number;
  chargeDisplayUnit: string;
  chargeLabel: string;
  batteryMinValue: number;
  batteryMaxValue: number;
  batteryDisplayUnit: string;
  batteryLabel: string;
  chartHeader?: string;
  deviceName?: string;
  chartId: Guid;
};

const DevicePowerPerformanceNewD3: FC<Props> = ({
  data,
  chargingMinValue,
  chargingMaxValue,
  chargeDisplayUnit,
  chargeLabel,
  batteryMinValue,
  batteryMaxValue,
  batteryDisplayUnit,
  batteryLabel,
  chartId,
}) => {
  const CHARGING_TICKS = [0, 4, 8, 12, 16, 18, 20, 24];
  const hoursToPlotLabels = [0, 12];
  const marginFromTopParentDiv = 0;
  const canvasScaleHeight = 200;

  const [_titleDimensions, setTitleDimensions] = useState<BoundingProps>(InitialBoundingProps);
  const [leftAxisDimensions, setLeftAxisDimensions] = useState<BoundingProps>(InitialBoundingProps);
  const [rightAxisDimensions, setRightAxisDimensions] =
    useState<BoundingProps>(InitialBoundingProps);
  const [chartAreaDimensions, setChartAreaDimensions] =
    useState<BoundingProps>(InitialBoundingProps);
  const [isChargingVoltageVisible, setIsChargingVoltageVisible] = useState(true);
  const [isBatteryVoltageVisible, setIsBatteryVoltageVisible] = useState(true);

  const titleRef = useRef<HTMLDivElement>(null);
  const leftAxisRef = useRef<HTMLDivElement>(null);
  const rightAxisRef = useRef<HTMLDivElement>(null);
  const chartAreaRef = useRef<HTMLDivElement>(null);
  const legendsRef = useRef<HTMLDivElement>(null);

  // bars
  const barSpacing = 2;
  const barInitialPadding = 5;
  const barWidth = 3;
  const barLeftMargin = 1;
  const barColumnWidth = barWidth + barLeftMargin;

  const chargingScale = d3
    .scaleLinear()
    .range([canvasScaleHeight, 0])
    // .domain([0, 24]);
    .domain([chargingMinValue, chargingMaxValue]);

  const batteryScale = d3
    .scaleLinear()
    .range([canvasScaleHeight, 0])
    .domain([batteryMinValue, batteryMaxValue]);

  const plotChart = () => {
    updateAxesPositionsAndDimensions();
  };

  const updateAxesPositionsAndDimensions = () => {
    if (titleRef.current) {
      setTitleDimensions(titleRef.current.getBoundingClientRect());
    }

    if (leftAxisRef.current) {
      setLeftAxisDimensions(leftAxisRef.current.getBoundingClientRect());
    }

    if (rightAxisRef.current) {
      setRightAxisDimensions(rightAxisRef.current.getBoundingClientRect());
    }

    if (chartAreaRef.current) {
      const chartAreaWidth = data.length * barColumnWidth + barColumnWidth + barInitialPadding;
      setChartAreaDimensions({
        ...chartAreaRef.current.getBoundingClientRect(),
        width: chartAreaWidth,
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateAxesPositionsAndDimensions();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    plotChart();
  }, []);

  const toggleChargingMeasurements = () => {
    const lineDotsOpacity = isChargingVoltageVisible ? "0" : "1";
    const btnOpacity = isChargingVoltageVisible ? "0.4" : "1";
    const showTooltip = !isChargingVoltageVisible || isBatteryVoltageVisible;

    d3.selectAll(".charging-line").style("opacity", lineDotsOpacity);
    d3.selectAll(".charging-trend-area").style("opacity", lineDotsOpacity);
    d3.selectAll(".charging-bottom-line").style("opacity", lineDotsOpacity);
    d3.selectAll(".charging-bottom-line-icon").style("opacity", lineDotsOpacity);

    d3.selectAll(".charging-legend-icon").style("opacity", btnOpacity);
    d3.selectAll(".charging-legend-text").style("opacity", btnOpacity);

    setIsChargingVoltageVisible(!isChargingVoltageVisible);

    const tooltipDiv = document.getElementById(`dpp-tooltip-${chartId}`);

    if (tooltipDiv !== null) {
      if (showTooltip) {
        tooltipDiv.style.display = "block";
      } else {
        tooltipDiv.style.display = "none";
      }
    }
  };

  const toggleBatteryMeasurements = () => {
    const barsOpacity = isBatteryVoltageVisible ? "0" : "1";
    const barsCursor = isBatteryVoltageVisible ? "initial" : "pointer";
    const btnOpacity = isBatteryVoltageVisible ? "0.4" : "1";
    const showTooltip = isChargingVoltageVisible || !isBatteryVoltageVisible;

    d3.selectAll(".battery-bars-rect").style("opacity", barsOpacity);
    d3.selectAll(".battery-bars-rect").style("cursor", barsCursor);

    d3.selectAll(".battery-legend-icon").style("opacity", btnOpacity);
    d3.selectAll(".battery-legend-text").style("opacity", btnOpacity);

    setIsBatteryVoltageVisible(!isBatteryVoltageVisible);

    const tooltipDiv = document.getElementById(`dpp-tooltip-${chartId}`);

    if (tooltipDiv !== null) {
      if (showTooltip) {
        tooltipDiv.style.display = "block";
      } else {
        tooltipDiv.style.display = "none";
      }
    }
  };

  return (
    <div className="dpp-chart-wrapper-flex">
      <div className="dpp-chart-content-flex">
        <div ref={leftAxisRef} id="leftAxisGroup" className="dpp-left-axis-container-flex">
          {leftAxisDimensions.width > 0 && (
            <LeftAxis
              height={leftAxisDimensions.height}
              width={leftAxisDimensions.width}
              x={leftAxisDimensions.x}
              y={leftAxisDimensions.y}
              chargingTicks={CHARGING_TICKS}
              axisLabel={chargeLabel}
              measurementSuffix={chargeDisplayUnit}
              marginFromTopParentDiv={marginFromTopParentDiv}
              chargingScale={chargingScale}
              chartId={chartId}
            />
          )}
        </div>

        <div
          ref={chartAreaRef}
          id={`chartAreaGroup-${chartId}`}
          className="dpp-chart-area-container-flex"
        >
          {chartAreaDimensions.width > 0 && (
            <ChartArea
              height={chartAreaDimensions.height}
              width={chartAreaDimensions.width}
              x={chartAreaDimensions.x}
              y={chartAreaDimensions.y}
              data={data}
              barSpacing={barSpacing}
              barInitialPadding={barInitialPadding}
              barWidth={barWidth}
              barLeftMargin={barLeftMargin}
              barColumnWidth={barColumnWidth}
              canvasScaleHeight={canvasScaleHeight}
              marginFromTopParentDiv={marginFromTopParentDiv}
              hoursToPlotLabels={hoursToPlotLabels}
              batteryScale={batteryScale}
              chargingScale={chargingScale}
              chargingTicks={CHARGING_TICKS}
              chartId={chartId}
            />
          )}
        </div>
        <div ref={rightAxisRef} id="rightAxisGroup" className="dpp-right-axis-container-flex">
          {rightAxisDimensions.width > 0 && (
            <RightAxis
              height={rightAxisDimensions.height}
              width={rightAxisDimensions.width}
              x={rightAxisDimensions.x}
              y={rightAxisDimensions.y}
              axisLabel={batteryLabel}
              measurementSuffix={batteryDisplayUnit}
              marginFromTopParentDiv={marginFromTopParentDiv}
              batteryScale={batteryScale}
              chargingScale={chargingScale}
              chartId={chartId}
            />
          )}
        </div>
      </div>

      <div ref={legendsRef} id="legendsGroup" className="dpp-legends-wrapper-flex">
        <div className="dpp-legends-container-flex">
          <button
            type="button"
            onClick={toggleChargingMeasurements}
            className="dpp-charging-button"
            style={{ opacity: isChargingVoltageVisible ? "1" : "0.4" }}
          >
            <span className="charging-stack-icon">
              <i className="fa-thin fa-wave-pulse" />
            </span>
            {chargeLabel}
          </button>

          <button
            type="button"
            onClick={toggleBatteryMeasurements}
            className="dpp-battery-button"
            style={{ opacity: isBatteryVoltageVisible ? "1" : "0.4" }}
          >
            <div className="battery-stack-icon">
              <span />
              <span /> <span />
              <span />
            </div>
            {batteryLabel}
          </button>
        </div>
      </div>

      <div ref={legendsRef}>
        <span className="dpp-warning text-caption">
          Device Power Performance shows value as Volts
        </span>
      </div>
    </div>
  );
};

export default DevicePowerPerformanceNewD3;
