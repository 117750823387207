import {
  Component,
  Children,
  ReactChild,
  ReactFragment,
  ReactNode,
  ReactPortal,
  cloneElement,
  isValidElement,
} from "react";

interface IAccordionProps {
  accordionId: string;
  children: any;
  noCard?: boolean;
}
interface IAccordionState {}
export class Accordion extends Component<IAccordionProps, IAccordionState> {
  cloneChildren(): (ReactChild | ReactFragment | ReactPortal)[] | null | undefined {
    const children = this.props.children;
    const accordionId = this.props.accordionId;

    if (!children) {
      return undefined;
    } else {
      const mappedChildren = Children.map<ReactNode, ReactNode>(children, (child) => {
        if (isValidElement(child)) {
          // @ts-expect-error
          return cloneElement(child, { accordionId });
        } else {
          return undefined;
        }
      });
      return mappedChildren;
    }
  }

  render() {
    const accordionId = this.props.accordionId;
    const children = this.cloneChildren();

    return (
      <div id={accordionId} className={`accordion ${this.props.noCard ? "no-card" : ""}`}>
        {children}
      </div>
    );
  }
}

interface IAccordionItemProps {
  header: ReactChild;
  details?: ReactChild;
  accordionId?: string;
  accordionItemId: string;
  expanded?: boolean;
  allowExpand?: boolean;
  padContent?: boolean;
  children: any;
  className?: string;
  id?: string;
  overflow?: boolean;
}
interface IAccordionItemState {}
export class AccordionItem extends Component<IAccordionItemProps, IAccordionItemState> {
  render() {
    const accordionId = this.props.accordionId;
    const accordionItemId = this.props.accordionItemId;
    const header = this.props.header;
    const details = this.props.details;
    const children = this.props.children;
    const allowExpand = this.props.allowExpand ?? true;
    const padContent = this.props.padContent ?? true;
    const overflow = this.props.overflow ?? true;

    return (
      <div id={this.props.id} className={"card accordion-item " + (this.props.className ?? "")}>
        <div className="card-header">
          <h4 className="card-title w-100">
            <a
              className={
                "d-block w-100 d-flex flex-row align-items-center p-3" +
                (this.props.expanded ? "" : " collapsed") +
                (allowExpand ? "" : " pe-none")
              }
              data-toggle="collapse"
              href={`#${accordionItemId}`}
              aria-expanded="false"
            >
              <span className="header-text">{header}</span>
              {details && <span className="text-muted text-sm ml-4">{details}</span>}
              <span className="flex-grow-1"></span>
              <i className="fas fa-chevron-down if-collapsed"></i>
              <i className="fas fa-chevron-up if-expanded"></i>
            </a>
          </h4>
        </div>
        <div
          id={accordionItemId}
          className={"collapse" + (this.props.expanded ? " show" : "")}
          data-parent={`#${accordionId}`}
          style={overflow ? { overflow: "hidden" } : {}}
        >
          <div className={"card-body" + (padContent ? " p-3" : "")}>{children}</div>
        </div>
      </div>
    );
  }
}
