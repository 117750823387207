import { ApiService } from "./ApiService";
import moment from "moment";
import DateHelpers from "../../helpers/DateHelpers";
import {
  SensorDetails,
  CurrentMeasurementData,
  DashboardDevice,
  Measurement,
  MeasurementData,
  CurrentMeasurementDataCollection,
  MeasurementName,
  HistoricMeasurementDataCollection,
  DevicePhoto,
  DeviceCamera,
  DeviceLostCommunication,
  FavoriteDashboard,
  DeviceSOHWarnings,
} from "../../models/api/DashboardDevice";
import { apiEndPoint } from "../../config";
import { DeviceMessage } from "../../models/api/DeviceTransmission";
import { PagedResults } from "../../models/api/PagedResults";
import { OrganizationDashboard } from "../../models/api/OrganizationDashboard";
import { TableFilters } from "../../models/api/TableFilters";
import { DashboardTemplate } from "../../models/api/DashboardTemplate";
import { OrganizationDashboardMeasurementConnection } from "../../models/api/OrganizationDashboardMeasurementConnection";
import {
  CurrentMeasurementDataResponse,
  HistoricalMeasurementDataResponse,
  MeasurementDatum,
  RainfallMeasurementDatum,
} from "../../models/api/MeasurementDataResponses";
import { Promise } from "bluebird";

export enum SortType {
  Name,
  LastSeen,
}

export enum SortDirection {
  Asc,
  Desc,
}
export interface IListDevicesParams {
  limit?: number;
  offset?: number;
  filter?: string;
  sortBy?: SortType;
  sortDirection?: SortDirection;
  favourites?: boolean;
  includeSensorDetails?: boolean;
  includeMeasurements?: boolean;
  includeDevicesWithoutLocation?: boolean;
  includeNeverSeen?: boolean;
}
export class DashboardApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "dashboard");

    this.listOrganizationDashboards = this.listOrganizationDashboards.bind(this);
    this.listDashboardTemplates = this.listDashboardTemplates.bind(this);
  }

  getOrganizationDashboard(organizationDashboardId: string): Promise<OrganizationDashboard> {
    return new Promise<OrganizationDashboard>((resolve, _reject, cancel) => {
      this.get(`organization/dashboard/${organizationDashboardId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            name: d.NAME,
            orgId: d.ORGID,
            dashboardTemplateId: d.DASHBOARDTEMPLATEID,
            dashboardTemplateName: d.DASHBOARDTEMPLATENAME,
            allMeasurementsConnected: d.ALLMEASUREMENTSCONNECTED == 1,
            missingMeasurementIds:
              d.MISSINGMEASUREMENTIDS != undefined && d.MISSINGMEASUREMENTIDS != ""
                ? d.MISSINGMEASUREMENTIDS.split(",")
                : [],
          });
        }
      });
    });
  }

  updateOrganizationDashboard(
    organizationDashboardId: string,
    organizationDashboard: OrganizationDashboard,
  ): Promise<void> {
    return new Promise<void>((resolve, _reject, cancel) => {
      this.post(
        `organization/dashboard/${organizationDashboardId}`,
        {
          name: organizationDashboard.name,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        }
      });
    });
  }

  listOrganizationDashboards(
    tableFilters?: TableFilters,
  ): Promise<PagedResults<OrganizationDashboard>> {
    return new Promise<PagedResults<OrganizationDashboard>>((resolve, _reject, cancel) => {
      this.get(
        `organization${
          tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""
        }`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const dashboards = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                orgId: d.ORGID,
                dashboardTemplateId: d.DASHBOARDTEMPLATEID,
                dashboardTemplateName: d.DASHBOARDTEMPLATENAME,
                allMeasurementsConnected: d.ALLMEASUREMENTSCONNECTED == 1,
                missingMeasurementIds:
                  d.MISSINGMEASUREMENTIDS != undefined && d.MISSINGMEASUREMENTIDS != ""
                    ? d.MISSINGMEASUREMENTIDS.split(",")
                    : [],
              }) as OrganizationDashboard,
          );
          resolve({
            items: dashboards,
            totalCount: result.totalCount,
          });
        }
      });
    });
  }

  listOrganizationDashboardsForOrg(
    orgId: string,
    tableFilters?: TableFilters,
  ): Promise<PagedResults<OrganizationDashboard>> {
    return new Promise<PagedResults<OrganizationDashboard>>((resolve, _reject, cancel) => {
      this.get(
        `organization/${orgId}${
          tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""
        }`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const dashboards = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                orgId: d.ORGID,
                dashboardTemplateId: d.DASHBOARDTEMPLATEID,
                dashboardTemplateName: d.DASHBOARDTEMPLATENAME,
                allMeasurementsConnected: d.ALLMEASUREMENTSCONNECTED == 1,
                missingMeasurementIds:
                  d.MISSINGMEASUREMENTIDS != undefined && d.MISSINGMEASUREMENTIDS != ""
                    ? d.MISSINGMEASUREMENTIDS.split(",")
                    : [],
              }) as OrganizationDashboard,
          );
          resolve({
            items: dashboards,
            totalCount: result.totalCount,
          });
        }
      });
    });
  }

  deleteOrganizationDashboard(organizationDashboardId: string): Promise<void> {
    return new Promise<void>((resolve, _reject, cancel) => {
      this.delete(`organization/dashboard/${organizationDashboardId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        }
      });
    });
  }

  listOrganizationDashboardMeasurementConnections(
    organizationDashboardId: string,
  ): Promise<OrganizationDashboardMeasurementConnection[]> {
    return new Promise<OrganizationDashboardMeasurementConnection[]>((resolve, _reject, cancel) => {
      this.get(`organization/dashboard/${organizationDashboardId}/connection`, cancel).done(
        (result) => {
          if (result.success) {
            resolve(
              result.data.map(
                (d: any) =>
                  ({
                    id: d.ID,
                    organizationDashboardId: d.ORGANIZATIONDASHBOARDID,
                    measurementId: d.MEASUREMENTID,
                    deviceId: d.DEVICEID,
                  }) as OrganizationDashboardMeasurementConnection,
              ),
            );
          }
        },
      );
    });
  }

  addOrganizationDashboardMeasurementConnection(
    organizationDashboardId: string,
    odmc: OrganizationDashboardMeasurementConnection,
  ): Promise<string> {
    return new Promise<string>((resolve, _reject, cancel) => {
      this.post(
        `organization/dashboard/${organizationDashboardId}/connection`,
        {
          measurementId: odmc.measurementId,
          deviceId: odmc.deviceId,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.newOrganizationDashboardMeasurementConnectionId);
        }
      });
    });
  }

  updateOrganizationDashboardMeasurementConnection(
    odmcId: string,
    odmc: OrganizationDashboardMeasurementConnection,
  ): Promise<void> {
    return new Promise<void>((resolve, _reject, cancel) => {
      this.post(
        `organization/dashboard/connection/${odmcId}`,
        {
          measurementId: odmc.measurementId,
          deviceId: odmc.deviceId,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve();
        }
      });
    });
  }

  deleteOrganizationDashboardMeasurementConnection(odmcId: string): Promise<void> {
    return new Promise<void>((resolve, _reject, cancel) => {
      this.delete(`organization/dashboard/connection/${odmcId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        }
      });
    });
  }

  getDashboardTemplate(dashboardTemplateId: string): Promise<DashboardTemplate> {
    return new Promise<DashboardTemplate>((resolve, _reject, cancel) => {
      this.get(`templates/${dashboardTemplateId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            name: d.NAME,
            measurementCount: d.MEASUREMENTCOUNT,
            measurementIds: d.MEASUREMENTIDS != undefined ? d.MEASUREMENTIDS.split(",") : [],
          });
        }
      });
    });
  }

  listDashboardTemplates(tableFilters?: TableFilters): Promise<PagedResults<DashboardTemplate>> {
    return new Promise<PagedResults<DashboardTemplate>>((resolve, _reject, cancel) => {
      this.get(
        `templates${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const dashboards = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                measurementCount: d.MEASUREMENTCOUNT,
                measurementIds: d.MEASUREMENTIDS != undefined ? d.MEASUREMENTIDS.split(",") : [],
              }) as DashboardTemplate,
          );
          resolve({
            items: dashboards,
            totalCount: result.TOTALCOUNT,
          });
        }
      });
    });
  }

  addDashboardToOrganization(
    orgId: string,
    dashboardTemplateId: string,
    newDashboardName: string,
  ): Promise<string> {
    return new Promise<string>((resolve, reject, cancel) => {
      this.post(
        `organization/${orgId}`,
        {
          dashboardTemplateId: dashboardTemplateId,
          name: newDashboardName,
        },
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(result.newOrganizationDashboardId);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getMeasurementHistoricalData<T extends MeasurementDatum = MeasurementDatum>(
    deviceId: string,
    measurementId: string,
    start?: Date,
    end?: Date,
    readingCount?: number,
    interval?: string,
  ): Promise<HistoricalMeasurementDataResponse<T>[]> {
    return new Promise<HistoricalMeasurementDataResponse<T>[]>((resolve, reject, cancel) => {
      const requestUrl = `device/${deviceId.toLowerCase()}/measurement/${measurementId.toLowerCase()}`;
      const params = new URLSearchParams();
      if (start && end) {
        params.append("start", DateHelpers.formatDateAsServerString(start));
        params.append("end", DateHelpers.formatDateAsServerString(end));
      }
      if (readingCount) {
        params.append("readingCount", readingCount.toString());
      }
      if (interval) {
        params.append("interval", interval);
      }
      this.get(
        `${requestUrl}${params.toString() != "" ? `?${params.toString()}` : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          resolve(
            result.data.map(
              (d: any) =>
                ({
                  deviceId: d.DEVICEID,
                  measurementId: d.MEASUREMENTID,
                  name: d.NAME,
                  bluetoothAddress: d.BLUETOOTHADDRESS,
                  isSensor: d.ISSENSOR,
                  displayUnits: d.DISPLAYUNITS,
                  data: d.DATA.map((dd: any) => ({
                    ...dd,
                    time: moment.utc(dd.time).local(),
                  })),
                }) as HistoricalMeasurementDataResponse<T>,
            ),
          );
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getMeasurementCurrentData<T extends MeasurementDatum = MeasurementDatum>(
    deviceId: string,
    measurementId: string,
  ): Promise<CurrentMeasurementDataResponse<T>[]> {
    return new Promise<CurrentMeasurementDataResponse<T>[]>((resolve, reject, cancel) => {
      const requestUrl = `device/${deviceId.toLowerCase()}/measurement/${measurementId.toLowerCase()}/current`;
      this.get(`${requestUrl}`, cancel).done((result) => {
        if (result.success) {
          resolve(
            result.data.map(
              (d: any) =>
                ({
                  deviceId: d.DEVICEID,
                  measurementId: d.MEASUREMENTID,
                  name: d.NAME,
                  bluetoothAddress: d.BLUETOOTHADDRESS,
                  isSensor: d.ISSENSOR,
                  displayUnits: d.DISPLAYUNITS,
                  data: {
                    ...d.DATA,
                    time: moment.utc(d.DATA.time).local(),
                  },
                }) as CurrentMeasurementDataResponse<T>,
            ),
          );
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listDevices({
    limit = undefined,
    offset = undefined,
    filter = undefined,
    sortBy = undefined,
    sortDirection = undefined,
    favourites = undefined,
    includeSensorDetails = undefined,
    includeMeasurements = undefined,
    includeDevicesWithoutLocation = undefined,
    includeNeverSeen = undefined,
  }: IListDevicesParams = {}): Promise<DashboardDevice[]> {
    return new Promise<DashboardDevice[]>((resolve, reject, cancel) => {
      const params = new URLSearchParams();
      if (limit) params.append("limit", limit.toString());
      if (offset) params.append("offset", offset.toString());
      if (filter) params.append("filter", filter.toString());
      if (sortBy !== undefined)
        params.append("sortBy", sortBy === SortType.LastSeen ? "lastseen" : "name");
      if (sortDirection !== undefined)
        params.append(
          "sortDirection",
          sortDirection === SortDirection.Desc ? "descending" : "ascending",
        );
      if (favourites !== undefined) params.append("favourites", favourites ? "true" : "false");
      if (includeSensorDetails !== undefined)
        params.append("includeSensorDetails", includeSensorDetails ? "true" : "false");
      if (includeMeasurements !== undefined)
        params.append("includeMeasurements", includeMeasurements ? "true" : "false");
      if (includeDevicesWithoutLocation !== undefined)
        params.append(
          "includeDevicesWithoutLocation",
          includeDevicesWithoutLocation ? "true" : "false",
        );
      if (includeNeverSeen !== undefined)
        params.append("includeNeverSeen", includeNeverSeen ? "true" : "false");

      this.get(`devices?${params}`, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                shortId: d.SHORTID,
                name: d.NAME,
                deviceTypeId: d.DEVICETYPEID,
                deviceTypeMeasurements: d.DEVICETYPEMEASUREMENTS,
                battery: parseFloat(d.BATTERY),
                batteryMinimum: parseFloat(d.BATTERYMINIMUM),
                batteryMaximum: parseFloat(d.BATTERYMAXIMUM),
                signal: parseFloat(d.SIGNAL),
                signalMinimum: parseFloat(d.SIGNALMINIMUM),
                signalMaximum: parseFloat(d.SIGNALMAXIMUM),
                uncertainty: d.UNCERTAINTY,
                sensorBtAddresses: d.SENSORS,
                measurements: d.MEASUREMENTS,
                tags: d.TAGS,
                orgId: d.ORGID,
                latitudeHardcoded:
                  d.LATITUDEHARDCODED != "" ? parseFloat(d.LATITUDEHARDCODED) : undefined,
                longitudeHardcoded:
                  d.LONGITUDEHARDCODED != "" ? parseFloat(d.LONGITUDEHARDCODED) : undefined,
                latitude: d.LATITUDE != "" ? parseFloat(d.LATITUDE) : undefined,
                longitude: d.LONGITUDE != "" ? parseFloat(d.LONGITUDE) : undefined,
                locationTimestamp:
                  d.LOCATIONTIMESTAMP != undefined && d.LOCATIONTIMESTAMP != ""
                    ? moment.utc(d.LOCATIONTIMESTAMP).local()
                    : undefined,
                timestamp: moment.utc(d.TIMESTAMP).local(),
                swarmDeviceId: d.SWARMDEVICEID,
                tankArea: d.TANKAREA,
                tankDepth: d.TANKDEPTH,
                isFavorite: d.ISFAVORITE,
                dashboardTemplateId: d.DASHBOARDTEMPLATE,
                latestMeasurementReadings: d.LATESTMEASUREMENTREADINGS,
                sensorDetails:
                  d.SENSORDETAILS &&
                  d.SENSORDETAILS.map(
                    (cd: any) =>
                      ({
                        name: cd.NAME,
                        orgId: cd.ORGID,
                        deviceTypeId: cd.DEVICETYPEID,
                        timestamp: moment.utc(cd.TIMESTAMP).local(),
                        bluetoothAddress: cd.BLUETOOTHADDRESS,
                        battery: parseFloat(cd.BATTERY),
                        batteryMinimum: parseFloat(cd.BATTERYMINIMUM),
                        batteryMaximum: parseFloat(cd.BATTERYMAXIMUM),
                        signal: parseFloat(cd.SIGNAL),
                        signalMinimum: parseFloat(cd.SIGNALMINIMUM),
                        signalMaximum: parseFloat(cd.SIGNALMAXIMUM),
                        deviceId: cd.DEVICEID,
                        value: cd.VALUE,
                        type: cd.TYPE,
                        measurementDisplayUnit: cd.DISPLAYUNIT,
                        tags: cd.TAGS,
                      }) as SensorDetails,
                  ),
                cameras: (d.CAMERAS ?? []).map(
                  (c: any) =>
                    ({
                      label: c.label,
                      value: c.value,
                    }) as DeviceCamera,
                ),
                organizationDashboardId:
                  d.ORGANIZATIONDASHBOARDID != undefined ? d.ORGANIZATIONDASHBOARDID : undefined,
              }) as DashboardDevice,
          );
          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDevice(deviceId: string): Promise<DashboardDevice> {
    return new Promise<DashboardDevice>((resolve, reject, cancel) => {
      this.get(`devices/${deviceId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          const device: DashboardDevice = {
            id: d.ID,
            shortId: d.SHORTID,
            name: d.NAME,
            deviceTypeId: d.DEVICETYPEID,
            battery: parseFloat(d.BATTERY),
            batteryMinimum: parseFloat(d.BATTERYMINIMUM),
            batteryMaximum: parseFloat(d.BATTERYMAXIMUM),
            signal: parseFloat(d.SIGNAL),
            signalMinimum: parseFloat(d.SIGNALMINIMUM),
            signalMaximum: parseFloat(d.SIGNALMAXIMUM),
            uncertainty: d.UNCERTAINTY,
            sensorBtAddresses: d.SENSORS,
            measurements: d.MEASUREMENTS,
            orgId: d.ORGID,
            latitudeHardcoded:
              d.LATITUDEHARDCODED != "" ? parseFloat(d.LATITUDEHARDCODED) : undefined,
            longitudeHardcoded:
              d.LONGITUDEHARDCODED != "" ? parseFloat(d.LONGITUDEHARDCODED) : undefined,
            latitude: d.LATITUDE,
            longitude: d.LONGITUDE,
            timestamp: moment.utc(d.TIMESTAMP),
            locationTimestamp:
              d.LOCATIONTIMESTAMP != undefined && d.LOCATIONTIMESTAMP != ""
                ? moment.utc(d.LOCATIONTIMESTAMP).local()
                : undefined,
            swarmDeviceId: d.SWARMDEVICEID,
            tankArea: d.TANKAREA,
            tankDepth: d.TANKDEPTH,
            riverMountDepth: d.RIVERMOUNTDEPTH,
            ultrasonicMountHeight: d.ULTRASONICMOUNTHEIGHT,
            isFavorite: d.ISFAVORITE,
            dashboardTemplateId: d.DASHBOARDTEMPLATE,
            latestMeasurementReadings: d.LATESTMEASUREMENTREADINGS,
            sensorDetails:
              d.SENSORDETAILS &&
              d.SENSORDETAILS.map(
                (cd: any) =>
                  ({
                    name: cd.NAME,
                    orgId: cd.ORGID,
                    deviceTypeId: cd.DEVICETYPEID,
                    timestamp: moment.utc(cd.TIMESTAMP),
                    bluetoothAddress: cd.BLUETOOTHADDRESS,
                    battery: parseFloat(cd.BATTERY),
                    batteryMinimum: parseFloat(cd.BATTERYMINIMUM),
                    batteryMaximum: parseFloat(cd.BATTERYMAXIMUM),
                    signal: parseFloat(cd.SIGNAL),
                    signalMinimum: parseFloat(cd.SIGNALMINIMUM),
                    signalMaximum: parseFloat(cd.SIGNALMAXIMUM),
                    locationTimestamp:
                      cd.LOCATIONTIMESTAMP != ""
                        ? moment.utc(cd.LOCATIONTIMESTAMP).local()
                        : undefined,
                    deviceId: cd.DEVICEID,
                  }) as SensorDetails,
              ),
            cameras: d.CAMERAS.map(
              (c: any) =>
                ({
                  label: c.label,
                  value: c.value,
                }) as DeviceCamera,
            ),
          };
          resolve(device);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateDeviceIsFavorite(deviceId: string, isFavorite: boolean): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`devices/${deviceId}/favorite`, { isFavorite: isFavorite }, cancel).done(
        (result) => {
          if (result.success) {
            resolve();
          } else {
            console.error(result.errorMessage);
            reject(result.message);
          }
        },
      );
    });
  }

  getMeasurements(
    measurementNames: MeasurementName[],
    deviceUserId: string,
    start?: Date,
    end?: Date,
    readingCount?: number,
    interval?: string,
  ): Promise<HistoricMeasurementDataCollection> {
    return new Promise<HistoricMeasurementDataCollection>((resolve, reject, cancel) => {
      const requestUrl = `${measurementNames}/${deviceUserId}`;
      const params = new URLSearchParams();
      if (start && end) {
        params.append("start", DateHelpers.formatDateAsServerString(start));
        params.append("end", DateHelpers.formatDateAsServerString(end));
      }
      if (readingCount) {
        params.append("readingCount", readingCount.toString());
      }
      if (interval) {
        params.append("interval", interval);
      }
      this.get(`${requestUrl}?${params}`, cancel).done((result) => {
        if (result.success) {
          const data: HistoricMeasurementDataCollection = {};
          measurementNames.forEach((measurement) => {
            const totalPorts = result.data[measurement]
              .map((x: any) => x.PORT)
              .filter(
                (value: any, index: number, self: any) =>
                  value && value != "" && self.indexOf(value) === index,
              ).length;

            data[measurement] = result.data[measurement].map(
              (d: any) =>
                ({
                  measurement: measurement,
                  valueKey: d.VALUEKEY ?? "",
                  bluetoothAddress: d.BLUETOOTHADDRESS,
                  port: d.PORT,
                  formula: d.FORMULA,
                  displayUnit: d.DISPLAYUNIT,
                  totalPorts: totalPorts,
                  data: d.DATA.map(
                    (dd: any) =>
                      ({
                        value: parseFloat(dd.VALUE),
                        rawValue: dd.RAWVALUE != undefined ? parseFloat(dd.RAWVALUE) : undefined,
                        time: moment.utc(dd.TIME).local(),
                      }) as Measurement,
                  ),
                }) as MeasurementData,
            );
          });
          resolve(data);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getCurrentMeasurements(
    measurementNames: MeasurementName[],
    deviceUserId: string,
  ): Promise<CurrentMeasurementDataCollection> {
    return new Promise<CurrentMeasurementDataCollection>((resolve, reject, cancel) => {
      this.get(`${measurementNames}/${deviceUserId}/current`, cancel).done((result) => {
        if (result.success) {
          const data: CurrentMeasurementDataCollection = {};
          measurementNames.forEach((measurement) => {
            data[measurement] = result.data[measurement].map(
              (d: any) =>
                ({
                  bluetoothAddress: d.BLUETOOTHADDRESS,
                  port: d.PORT,
                  formula: d.FORMULA,
                  displayUnit: d.DISPLAYUNIT,
                  data: {
                    value: parseFloat(d.DATA[0].VALUE),
                    rawValue:
                      d.DATA[0].RAWVALUE != undefined ? parseFloat(d.DATA[0].RAWVALUE) : undefined,
                    time: moment.utc(d.DATA[0].TIME).local(),
                  },
                }) as CurrentMeasurementData,
            );
          });
          resolve(data);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getPhotos(
    deviceUserId: string,
    cameraId: string,
    start?: Date,
    end?: Date,
  ): Promise<DevicePhoto[]> {
    return new Promise<DevicePhoto[]>((resolve, reject, cancel) => {
      const requestUrl = `photos/${deviceUserId}/${cameraId}`;
      const params = new URLSearchParams();
      if (start && end) {
        params.append("start", DateHelpers.formatDateAsServerString(start));
        params.append("end", DateHelpers.formatDateAsServerString(end));
      }

      this.get(`${requestUrl}?${params}`, cancel).done((result) => {
        if (result.success) {
          let data: DevicePhoto[] = [];
          data = result.data.map(
            (d: any) =>
              ({
                imageData: d.ImageData,
                createdOn: moment.utc(d.CreatedOn).local(),
                sendId: d.SendId,
                fileName: DateHelpers.formatMomentAsDateTime(moment.utc(d.CreatedOn).local()),
              }) as DevicePhoto,
          );
          resolve(data);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceMessagesReceived(
    messageType: string,
    start?: Date,
    end?: Date,
    deviceUserId?: string,
  ): Promise<DeviceMessage[]> {
    return new Promise<DeviceMessage[]>((resolve, reject, cancel) => {
      const requestUrl = `transmission/messagesReceived`;
      const params = new URLSearchParams();
      params.append("messageType", messageType);
      if (start && end) {
        params.append("start", DateHelpers.formatDateAsServerString(start));
        params.append("end", DateHelpers.formatDateAsServerString(end));
      }
      if (deviceUserId) {
        params.append("deviceUserId", deviceUserId);
      }

      this.get(`${requestUrl}?${params}`, cancel).done((result) => {
        if (result.success) {
          const deviceMessages = result.data.map(
            (d: any) =>
              ({
                time: moment.utc(d.TIME).local(),
                iTime: moment.utc(d.ITIME).local(),
                sProtocol: d.SPROTOCOL,
                deviceId: d.DEVICEID,
              }) as DeviceMessage,
          );
          resolve(deviceMessages);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDeviceMessagesSent(
    messageType: string,
    start?: Date,
    end?: Date,
    deviceUserId?: string,
  ): Promise<DeviceMessage[]> {
    return new Promise<DeviceMessage[]>((resolve, reject, cancel) => {
      const requestUrl = `transmission/messagesSent`;
      const params = new URLSearchParams();
      params.append("messageType", messageType);
      if (start && end) {
        params.append("start", DateHelpers.formatDateAsServerString(start));
        params.append("end", DateHelpers.formatDateAsServerString(end));
      }
      if (deviceUserId) {
        params.append("deviceUserId", deviceUserId);
      }

      this.get(`${requestUrl}?${params}`, cancel).done((result) => {
        if (result.success) {
          const deviceMessages = result.data.map(
            (d: any) =>
              ({
                time: moment.utc(d.TIME).local(),
                iTime: moment.utc(d.ITIME).local(),
                sProtocol: d.SPROTOCOL,
                deviceId: d.DEVICEID,
              }) as DeviceMessage,
          );
          resolve(deviceMessages);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  // deviceId: string, n: number, from: string, to: string
  getRainfallData(
    deviceId: string,
    n: number,
    from: string,
    to: string,
  ): Promise<RainfallMeasurementDatum[]> {
    return new Promise<RainfallMeasurementDatum[]>((resolve, reject, cancel) => {
      this.get(
        `device/${deviceId.toLowerCase()}/rainfall?n=${n}&from=${encodeURIComponent(
          from,
        )}&to=${encodeURIComponent(to)}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const rData = result.data.map(
            (d: any) =>
              ({
                value: d.REPORTVALUE,
                from: moment(d.REPORTFROMDATE).utc().local(),
                to: moment(d.REPORTTODATE).utc().local(),
              }) as RainfallMeasurementDatum,
          );
          resolve(rData);
        } else {
          console.error(result.message);
          reject(result.message);
        }
      });
    });
  }

  convertTadDashboard(deviceIds: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      const postData = {
        deviceIds: deviceIds,
      };
      this.post(`convert/tad`, postData, cancel).done((resultInner) => {
        if (resultInner.success) {
          resolve();
        } else {
          console.error(resultInner.errorMessage);
          reject(resultInner.message);
        }
      });
    });
  }

  getFavoriteDashboards(): Promise<FavoriteDashboard[]> {
    return new Promise<FavoriteDashboard[]>((resolve, reject, cancel) => {
      this.get(`favorite`, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                deviceId: d.DEVICEID,
                orgId: d.ORGID,
                name: d.NAME,
                serialNumber: d.SERIALNUMBER,
                timestamp: moment.utc(d.TIMESTAMP).local(),
              }) as FavoriteDashboard,
          );
          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDevicesLostCommunication(): Promise<DeviceLostCommunication[]> {
    return new Promise<DeviceLostCommunication[]>((resolve, reject, cancel) => {
      this.get(`devices/lostCommunication`, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                deviceId: d.DEVICEID,
                name: d.NAME,
                serialNumber: d.SERIALNUMBER,
                orgId: d.ORGID,
                timestamp: moment.utc(d.TIMESTAMP).local(),
              }) as DeviceLostCommunication,
          );
          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getDevicesBatterySohWarning(): Promise<DeviceSOHWarnings[]> {
    return new Promise<DeviceSOHWarnings[]>((resolve, reject, cancel) => {
      this.get(`devices/sohWarning`, cancel).done((result) => {
        if (result.success) {
          const devices = result.data.map(
            (d: any) =>
              ({
                deviceId: d.DEVICEID,
                orgId: d.ORGID,
                name: d.NAME,
                serialNumber: d.SERIALNUMBER,
                battery: d.BATTERY,
                batteryMinimum: d.BATTERYMINIMUM,
                batteryMaximum: d.BATTERYMAXIMUM,
                timestamp: moment.utc(d.TIMESTAMP).local(),
              }) as DeviceSOHWarnings,
          );
          resolve(devices);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
