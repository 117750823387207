import { FC, PropsWithChildren } from "react";
import "../cardStyles.scss";

const MapCard: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="map-card-container">
      <div className="map-content">{children}</div>
    </div>
  );
};

export default MapCard;
