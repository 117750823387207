import "./inputStyles.scss";
import { HintIcon } from "../HintIcon";

export enum InputType {
  Text,
  Number,
  Password,
}

export enum LabelSize {
  Standard,
  Small,
}

interface IProps {
  idName: string;
  labelString: string;
  labelSize?: LabelSize;
  isRequired?: boolean;
  isDisabled?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
}

export function Label(props: IProps) {
  // IF REQ IS NULL == NOT REQ
  // IF REQ IS FALSE == NOT REQ
  // IF REQ IS TRUE == REQ
  const labelString = props.isRequired ? props.labelString.concat(" *") : props.labelString;
  const classes: string[] = ["labels"];
  classes.push(generateSizeClass(props.labelSize));
  if (props.isDisabled) classes.push("state-disabled");

  const _hintIcon = props.hasTooltip ? (
    <HintIcon tooltipText={props.tooltipText ? props.tooltipText : ""} />
  ) : (
    <></>
  );

  return (
    <label className={`${classes.join(" ")}`} htmlFor={props.idName}>
      {" "}
      {labelString} {_hintIcon}{" "}
    </label>
  );
}

function generateSizeClass(labelSize?: LabelSize) {
  if (!labelSize) labelSize = LabelSize.Standard;
  let labelClass: string = "text-label-";

  switch (labelSize) {
    case LabelSize.Standard:
      labelClass += "large";
      break;
    case LabelSize.Small:
      labelClass += "small";
      break;
  }

  return labelClass;
}
