import { useQuery } from "react-query";
import { OrganizationsApiService } from "../api/OrganizationsApiService";

const useOrganizationList = () => {
  return useQuery(["organizations"], () => {
    const service = new OrganizationsApiService();
    return service.list();
  });
};

export { useOrganizationList };
