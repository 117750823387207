const LATITUDE_LIMIT = 90;
const LONGITUDE_LIMIT = 180;
export const CoordinatesHelper = {
  latitudeLimit: LATITUDE_LIMIT,
  longitudeLimit: LONGITUDE_LIMIT,
  isLatitudeValid(latitude: number): boolean {
    if (typeof latitude !== "number" || isNaN(latitude)) return false;
    return latitude >= -LATITUDE_LIMIT && latitude <= LATITUDE_LIMIT;
  },

  isLongitudeValid(longitude: number): boolean {
    if (typeof longitude !== "number" || isNaN(longitude)) return false;
    return longitude >= -LONGITUDE_LIMIT && longitude <= LONGITUDE_LIMIT;
  },

  areCoordinatesValid(latitude: number, longitude: number): boolean {
    return this.isLatitudeValid(latitude) && this.isLongitudeValid(longitude);
  },
};
