import { Component } from "react";
import { Button, ButtonType } from "../../../components/Button";
import { User } from "../../../models/api/User";
import { UsersApiService } from "../../../services/api/UsersApiService";
import { Input, InputType } from "../../../components/inputs/Input";
import { FormGroup } from "../../../components/forms/FormGroup";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { IconButton } from "../../../components/IconButton";
import { Dialog, DialogSize } from "../../../components/Dialog";
import { cfHost } from "../../../config";

interface IProps {
  user: User;
}

interface IState {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  user: User;
  selectedImage?: any;
  showDeleteProfileImageDialog?: () => void;
  hideDeleteProfileImageDialog?: () => void;
}

export class UserPersonalInformation extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      user: this.props.user,
      firstName: this.props.user.firstName || "",
      lastName: this.props.user.lastName || "",
      phoneNumber: this.props.user.phoneNumber || "",
      email: this.props.user.email || "",
      showDeleteProfileImageDialog: () => {},
      hideDeleteProfileImageDialog: () => {},
    };

    this.removeProfileImage = this.removeProfileImage.bind(this);
  }

  componentDidMount() {}

  inputUploadImage(e: any) {
    if (!this.state.user.id) return;

    this.setState({ selectedImage: e.target.files[0] });

    const thisUserId = this.state.user.id;
    const fileName = e.target.files[0].name;
    const reader = new FileReader(); // instance of the FileReader
    reader.readAsDataURL(e.target.files[0]); // read the local file

    reader.onloadend = () => {
      // set image data as background of div
      const formData = {
        fileName: fileName,
        // @ts-expect-error
        imageFile: this.result.split(",")[1],
      };
      new UsersApiService()
        .uploadProfileImage(thisUserId, formData)
        .then(() => {
          $.notification("show", {
            type: "message",
            title: undefined,
            message: "User profile was successfully updated",
            toastOnly: true,
          });

          window.location.reload();
        })
        .catch(() =>
          $.notification("show", {
            type: "error",
            title: undefined,
            message: "An error occurred uploading profile image",
            toastOnly: true,
          }),
        );
    };
  }

  removeProfileImage() {
    if (!this.state.user.id) return;
    new UsersApiService()
      .removeProfileImage(this.state.user.id)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "User profile was successfully deleted",
          toastOnly: true,
        });

        const updatedUser = this.state.user;
        if (updatedUser) {
          updatedUser.profileImagePath = "";
        }

        this.setState(
          {
            user: updatedUser,
            selectedImage: undefined,
          },
          this.state.hideDeleteProfileImageDialog,
        );
      })
      .catch(() =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "An error occurred deleting profile image",
          toastOnly: true,
        }),
      );
  }

  updateProfile() {
    const updatedUser = this.state.user;
    updatedUser.firstName = this.state.firstName;
    updatedUser.lastName = this.state.lastName;
    updatedUser.phoneNumber = this.state.phoneNumber;
    updatedUser.email = this.state.email!;

    /* Update user*/
    new UsersApiService()
      .update(updatedUser)
      .then(() => {
        this.state = {
          user: updatedUser,
        };
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "User personal information was successfully updated",
          toastOnly: true,
        });
      })
      .catch(() =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "An error occurred while saving user",
          toastOnly: true,
        }),
      );
  }

  render() {
    return (
      <>
        <FormGroup title="Personal Information">
          <form id="personal-information-form" action="javascript:void(0);">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Input
                  idName="firstNameInput"
                  labelString="First Name"
                  placeholder="Enter your first name"
                  inputType={InputType.Text}
                  inputValue={this.state.firstName}
                  onChange={(e) => {
                    this.setState({ firstName: e.target.value });
                  }}
                  name="firstName"
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Input
                  idName="lastNameInput"
                  labelString="Last Name"
                  placeholder="Enter your last name"
                  inputType={InputType.Text}
                  inputValue={this.state.lastName}
                  onChange={(e) => {
                    this.setState({
                      lastName: e.target.value,
                    });
                  }}
                  name="lastName"
                  isRequired={true}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                <Input
                  idName="phoneNumberInput"
                  labelString="Mobile Number"
                  placeholder="Enter your mobile number"
                  inputType={InputType.Text}
                  onChange={(e) => {
                    this.setState({
                      phoneNumber: e.target.value,
                    });
                  }}
                  name="phoneNumber"
                  inputValue={this.state.phoneNumber}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                <label className="labels text-label-large">Email Address</label>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label className="labels text-label-large">{this.state.email}</label>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                <label className="labels text-label-large">Your Photo</label>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                {!this.state.selectedImage && (
                  <>
                    {!this.state.user && (
                      <LoadingSkeleton
                        width="100px"
                        height="100px"
                        className="rounded-circle elevation-4"
                      />
                    )}
                    {this.state.user && this.state.user.profileImagePath && (
                      <img
                        id="profile-image"
                        className="rounded-circle elevation-4 width-xs"
                        src={`${cfHost}${this.state.user?.profileImagePath}`}
                        alt="profile"
                      />
                    )}
                    {this.state.user && !this.state.user.profileImagePath && (
                      <img
                        id="profile-image"
                        className="rounded-circle elevation-4 width-xs"
                        src={`${cfHost}/images/profile-placeholder.png`}
                        alt="profile"
                      />
                    )}
                  </>
                )}
                {this.state.selectedImage && (
                  <img
                    id="profile-image"
                    className="rounded-circle elevation-4 width-xs"
                    src={URL.createObjectURL(this.state.selectedImage)}
                    alt="profile"
                  />
                )}
                <div className="d-flex flex-row ml-2">
                  <input
                    type="file"
                    id="inputUploadImage"
                    accept="image/*"
                    className="inputUploadImage"
                    hidden={true}
                    onChange={(e) => this.inputUploadImage(e)}
                  />
                  <IconButton
                    isDark={false}
                    iconClass="fa fa-pencil-alt"
                    onClick={() => $("#inputUploadImage").click()}
                  />
                  <span className="mr-4"></span>
                  <IconButton
                    isDark={false}
                    iconClass="fa fa-trash-alt"
                    disabled={
                      !this.state.selectedImage && !this.state.user?.profileImagePath ? true : false
                    }
                    onClick={() =>
                      this.setState(
                        {
                          // @ts-expect-error
                          userId: this.state.user?.id ?? "",
                        },
                        this.state.showDeleteProfileImageDialog,
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row mt-3">
              <span className="flex-grow-0 flex-md-grow-1"></span>
              <Button
                buttonType={ButtonType.Success}
                content="Update Details"
                onClick={() => this.updateProfile()}
              />
            </div>
          </form>
        </FormGroup>
        <Dialog
          size={DialogSize.Small}
          setOpen={(open) => this.setState({ showDeleteProfileImageDialog: open })}
          setClose={(close) => this.setState({ hideDeleteProfileImageDialog: close })}
          onClose={() => {}}
          header="Remove Profile Image"
          body={<p>Please confirm you wish to remove your profile image?</p>}
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                content="Close"
                buttonType={ButtonType.Transparent}
                onClick={this.state.hideDeleteProfileImageDialog}
              />
              <Button
                content="Delete"
                buttonType={ButtonType.Error}
                onClick={this.removeProfileImage}
              />
            </>
          }
        />
      </>
    );
  }
}
