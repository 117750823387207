import { ApiService } from "./ApiService";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { ApiKey } from "../../models/api/ApiKey";
import { PagedResults } from "../../models/api/PagedResults";
import { TableFilters } from "../../models/api/TableFilters";
import { Promise } from "bluebird";

export class ApiKeyApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "apiKeys");

    this.list = this.list.bind(this);
  }

  getById(apiKeyId: string) {
    return new Promise<ApiKey>((resolve, reject, cancel) => {
      this.get(`${apiKeyId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID ?? "",
            groupId: d.GROUPID ?? "",
            orgId: d.ORGID ?? "",
            groupName: d.GROUPNAME,
            name: d.NAME ?? "",
            token: d.TOKEN ?? "",
            createdAt: d.CREATEDAT ?? "",
          } as ApiKey);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  list(tableFilters?: TableFilters): Promise<PagedResults<ApiKey>> {
    return new Promise<PagedResults<ApiKey>>((resolve, reject, cancel) => {
      this.get(
        `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const apiKeys = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                groupName: d.GROUPNAME,
                groupId: d.GROUPID,
                orgId: d.ORGID,
                name: d.NAME,
                token: d.TOKEN,
                createdAt: d.CREATEDAT ? moment(new Date(d.CREATEDAT)) : undefined,
              }) as ApiKey,
          );
          resolve({
            items: apiKeys,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listByAccessGroupId(accessGroupId: string): Promise<ApiKey[]> {
    return new Promise<ApiKey[]>((resolve, reject, cancel) => {
      this.get(`list/accessGroup/${accessGroupId}`, cancel).done((result) => {
        if (result.success) {
          const apiKeys = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                groupId: d.GROUPID,
                orgId: d.ORGID,
                name: d.NAME,
                token: d.TOKEN,
                createdAt: d.CREATEDAT ? moment(new Date(d.CREATEDAT)) : undefined,
              }) as ApiKey,
          );
          resolve(apiKeys);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  remove(apiKeyId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${apiKeyId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  create(apiKey: ApiKey): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.post("", apiKey).done((result) => {
        if (result.success) {
          resolve(result.apiId as string);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateName(apiKeyId: string, apiKeyName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.post(`${apiKeyId}/apiKeyName`, {
        apiKeyName: apiKeyName,
      }).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
