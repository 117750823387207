import { FC, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import nerdamer from "nerdamer";

import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import { DeviceAlert } from "../../../models/api/DeviceAlert";
import AlertsWidgetCardContainer from "./card/AlertsWidgetCardContainer";
import { IconButton } from "../../../components/IconButton";
import DateHelpers from "../../../helpers/DateHelpers";
import { TableFilters } from "../../../models/api/TableFilters";
import { Measurement } from "../../../models/api/Measurement";
import { UnitPreference } from "../../../models/api/UnitPreference";
import MeasurementHelpers from "../../../helpers/MeasurementHelpers";
import FilterTableAlertsGrouped from "./filter-tables/alerts-grouped";

import "./devices-in-alert-styles.scss";

type Props = {
  devicesInAlert: DeviceAlert[] | undefined;
  measurements: Measurement[] | undefined;
  unitPreferences: UnitPreference[] | undefined;
  isLoading: boolean;
};

export type DeviceInAlertGrouped = {
  alertName: string;
  devicesAffected: number;
  lastFired: Moment;
  lastFiredFormatted: string;
};

const DevicesInAlert: FC<Props> = ({
  devicesInAlert,
  measurements,
  unitPreferences,
  isLoading,
}) => {
  if (isLoading || !devicesInAlert || !measurements || !unitPreferences) {
    return <LoadingSkeleton width="100%" height="4rem" />;
  }

  const [isAlertsGrouped, setIsAlertsGrouped] = useState(false);

  const handlehandleGroupAlerts = () => {
    setIsAlertsGrouped((prevState) => {
      return !prevState;
    });
  };

  const getUnitMeasurement = (deviceAlert: DeviceAlert) => {
    let value = "";
    let unit = "";
    const splittedValues = deviceAlert.MeasurementValueCaused?.split(":")[1].trim().split(" ");

    if (splittedValues) {
      value = splittedValues[0].trim() || "";
      unit = splittedValues.pop() || "";
    }

    const measurement = measurements.find((m) => m.id == deviceAlert.MeasurementId);
    const measurementField = measurement?.fields?.find(
      (f) => f.id == deviceAlert.MeasurementFieldId,
    );

    if (measurementField) {
      const displayUnitId = MeasurementHelpers.getPreferedDisplayUnitId(
        unitPreferences,
        measurementField,
      );
      const displayUnit = measurementField.displayUnits.find((du) => du.id == displayUnitId);
      if (displayUnit) {
        const eq = nerdamer(`y=${displayUnit.formula}`, {
          // @ts-expect-error
          x: deviceAlert.RawValue,
        });
        const solution = eq.solveFor("y");
        // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
        value = (solution[0] ?? solution).toDecimal();
        unit = displayUnit.displayUnit;
      }
    }

    return (
      <div className="device-in-alert-wrapper">
        {!!value && <div className="device-in-alert-content-date">{`${value} ${unit}`}</div>}
      </div>
    );
  };

  const getIconAndName = (deviceAlert: DeviceAlert) => {
    let alertCaused = deviceAlert.MeasurementValueCaused;

    const measurement = measurements.find((m) => m.id == deviceAlert.MeasurementId);
    const measurementField = measurement?.fields?.find(
      (f) => f.id == deviceAlert.MeasurementFieldId,
    );

    if (measurementField) {
      const displayUnitId = MeasurementHelpers.getPreferedDisplayUnitId(
        unitPreferences,
        measurementField,
      );
      const displayUnit = measurementField.displayUnits.find((du) => du.id == displayUnitId);
      if (displayUnit) {
        const unit = displayUnit.displayUnit;
        const operator = deviceAlert.Operator;

        const eq = nerdamer(`y=${displayUnit.formula}`, {
          // @ts-expect-error
          x: deviceAlert.RawValue,
        });
        const solution = eq.solveFor("y");
        // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
        const value = (solution[0] ?? solution).toDecimal();

        const req = nerdamer(`y=${displayUnit.formula}`, {
          // @ts-expect-error
          x: deviceAlert.RuleValue,
        });
        const rsolution = req.solveFor("y");
        // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
        const ruleValue = (rsolution[0] ?? rsolution).toDecimal();

        alertCaused =
          measurement?.name +
          ": " +
          value +
          " " +
          unit +
          " " +
          operator +
          " " +
          ruleValue +
          " " +
          unit;
      }
    }

    return (
      <div className="device-in-alert-wrapper">
        <div className="device-in-alert-content-icon">
          <IconButton
            iconClass="fa-solid fa-bell-on"
            tooltip={`[${deviceAlert.AlertName}] ${alertCaused ? alertCaused : ""}`}
          />
        </div>
        <div className="device-in-alert-content-device-name">
          <a
            className="widget-table-item-row"
            href={`/go/dashboard/device/${deviceAlert.DeviceId}`}
            title="View dashboard"
            target="_blank"
          >
            {deviceAlert.DeviceName}
          </a>
        </div>
      </div>
    );
  };

  const getAlertsGrouped = useMemo(() => {
    const result = Object.values(
      devicesInAlert.reduce((d, x) => {
        d[x.AlertName] = d[x.AlertName] || [];
        d[x.AlertName].push(x);
        return d;
      }, Object.create(null)),
    );

    const alertsGrouped: DeviceInAlertGrouped[] = [];

    for (let index = 0; index < result.length; index++) {
      const alertGrouped = result[index] as unknown as DeviceAlert[];

      const lastFired = alertGrouped.reduce((last, alert) =>
        last.DateTriggered! > alert.DateTriggered! ? last : alert,
      ).DateTriggered!;

      alertsGrouped.push({
        alertName: alertGrouped[0].AlertName,
        devicesAffected: alertGrouped.length,
        lastFired,
        lastFiredFormatted: lastFired.format("Do MMM. YYYY, h:mm a"),
      });
    }

    return alertsGrouped;
  }, [devicesInAlert.length]);

  return (
    <AlertsWidgetCardContainer
      totalAlerts={`${devicesInAlert.length}`}
      iconClass="fa-solid fa-bell-on"
      switchGroupAlerts={handlehandleGroupAlerts}
    >
      {isAlertsGrouped ? (
        <FilterTableAlertsGrouped deviceInAlertGrouped={getAlertsGrouped} />
      ) : (
        <FilterTable<DeviceAlert>
          tableId="device-in-alert-table"
          items={devicesInAlert}
          paginate={devicesInAlert.length > 5}
          paginationOptions={[
            { label: "5", value: 5 },
            { label: "10", value: 10 },
          ]}
          columnDefinitions={[
            {
              header: "Device Name",
              valueFunction: (item) => getIconAndName(item),
              sortable: true,
              databaseColumn: "DeviceName",
              sortFunction: (a, b) => {
                if (!a.DeviceName) return -1;
                if (!b.DeviceName) return 1;
                return a.DeviceName.localeCompare(b.DeviceName);
              },
            },
            {
              header: "Alert Date",
              valueFunction: (item) => {
                if (!item.DateTriggered) return "";

                return DateHelpers.formatDateTimeAsStringWithFormat(
                  item.DateTriggered,
                  "MM/DD/YYYY HH:mm:ss",
                );
              },
              sortable: true,
              databaseColumn: "DateTriggered",
              sortFunction: (a, b) => {
                return moment(a.DateTriggered).isAfter(moment(b.DateTriggered)) ? 1 : -1;
              },
            },
            {
              header: "Alert",
              valueFunction: (item) => getUnitMeasurement(item),
              sortable: true,
              databaseColumn: "MeasurementValueCaused",
              sortFunction: (a, b) => {
                const aSplittedValues = a.MeasurementValueCaused?.split(":")[1].trim().split(" ");
                const bSplittedValues = b.MeasurementValueCaused?.split(":")[1].trim().split(" ");

                let aValue = 0;
                let bValue = 0;

                if (aSplittedValues) {
                  aValue = Number(aSplittedValues[0].trim()) || 0;
                } else {
                  return -1;
                }

                if (bSplittedValues) {
                  bValue = Number(bSplittedValues[0].trim()) || 0;
                } else {
                  return 1;
                }

                return aValue - bValue;
              },
            },
          ]}
          initialTableFilters={new TableFilters("DateTriggered", false)}
          hasSmallFooter
          hideFooterSelection
          removeBorder
        />
      )}
    </AlertsWidgetCardContainer>
  );
};

export default DevicesInAlert;
