import { useMutation, useQuery } from "react-query";
import {
  AuthenticationApiService,
  ILoginCredentials,
  IResetPasswordDetails,
} from "../api/AuthenticationApiService";
import { useAuth } from "../../contexts/auth/auth.context";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { IMutationOptions } from "./ContextServiceHelper";

const useAuthenticate = () => {
  return useQuery(
    ["authenticate"],
    () => {
      const service = new AuthenticationApiService();
      return service.authenticate();
    },
    {
      staleTime: 0,
    },
  );
};

const useUserLogin = (mutationOptions: IMutationOptions = {}) => {
  const { setToken } = useAuth();
  return useMutation(
    (credentials: ILoginCredentials) => {
      const service = new AuthenticationApiService();
      return service.login(credentials);
    },
    {
      onSuccess: (data) => {
        if (data.token) {
          localStorage.setItem("JWT_TOKEN", data.token);
          const token = JwtTokenHelpers.getDecodedJwtToken();
          setToken!(token);
        }
        mutationOptions?.onSuccess?.(data);
      },
      onError: (error) => {
        mutationOptions?.onError?.(error);
      },
    },
  );
};
const useResetAccount = (tokenId: string) => {
  return useQuery(
    ["resetAccount", tokenId],
    () => {
      const service = new AuthenticationApiService();
      return service.getResetAccountDetails(tokenId);
    },
    {
      staleTime: 0,
      enabled: !!tokenId,
    },
  );
};

const useResetPassword = (mutationOptions: IMutationOptions = {}) => {
  const { setToken } = useAuth();
  return useMutation(
    (details: IResetPasswordDetails) => {
      const service = new AuthenticationApiService();
      return service.resetPassword(details);
    },
    {
      onSuccess: (data) => {
        if (data.token) {
          localStorage.setItem("JWT_TOKEN", data.token);
          const token = JwtTokenHelpers.getDecodedJwtToken();
          setToken!(token);
        }
        mutationOptions?.onSuccess?.(data);
      },
      onError: (error) => {
        mutationOptions?.onError?.(error);
      },
    },
  );
};

const useSwitchOrganisation = (mutationOptions: IMutationOptions = {}) => {
  const { setToken } = useAuth();
  return useMutation(
    (orgId: string) => {
      const service = new AuthenticationApiService();
      return service.switchOrganisation(orgId);
    },
    {
      onSuccess: (data) => {
        if (data.token) {
          localStorage.setItem("JWT_TOKEN", data.token);
          const token = JwtTokenHelpers.getDecodedJwtToken();
          setToken!(token);
        }
        mutationOptions?.onSuccess?.(data);
      },
      onError: (error) => {
        mutationOptions?.onError?.(error);
      },
    },
  );
};

export { useAuthenticate, useUserLogin, useResetAccount, useResetPassword, useSwitchOrganisation };
