import { lazy } from "react";
import { RouteConfig } from "../../AuthenticatedApp";

const InertiaFleetDashboardPage = lazy(() =>
  import("./InertiaFleetDashboardPage").then(({ InertiaFleetDashboardPage }) => ({
    default: InertiaFleetDashboardPage,
  })),
);

export const InertiaFleetDashboardRoutes: RouteConfig[] = [
  {
    path: "/go/welcome",
    title: "Welcome",
    component: () => <InertiaFleetDashboardPage />,
  },
];
