import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";

export const Footer = () => {
  const applicationVersion = JwtTokenHelpers.getApplicationVersion();

  return (
    <footer className="main-footer elevation-1 d-flex flex-row flex-wrap text-sm">
      <div className="d-flex flex-column flex-md-row">
        {/*Todo: [Fanhua] Add session.user.theme.termsOfServiceUrl */}
        <a
          className="link text-nowrap"
          target="_blank"
          href="https://www.freewave.com/terms-of-use/"
        >
          Terms of Service
        </a>
        <span className="d-none d-md-inline-block">&nbsp;|&nbsp;</span>
        <span className="mt-1"></span>
        {/*Todo: [Fanhua] Add session.user.theme.privacyPolicyUrl */}
        <a
          className="link text-nowrap"
          target="_blank"
          href="https://www.freewave.com/privacy-policy/"
        >
          Privacy Policy
        </a>
      </div>
      <span className="flex-grow-1 mr-3 mt-2"></span>
      <div className="text-muted d-flex flex-column flex-md-row">
        <span className="text-nowrap">
          <strong>Copyright</strong> &copy; {`${new Date().getUTCFullYear()} Freewave.`}&nbsp;
        </span>
        <span className="text-nowrap">
          <strong>All rights reserved.</strong>&nbsp;
        </span>
        <span className="text-nowrap">
          <strong>Version {applicationVersion}</strong>
        </span>
      </div>
    </footer>
  );
};
