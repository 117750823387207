import { FC, ReactNode, useEffect, useState } from "react";
import "./pageHeaderStyles.scss";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import { ReactSelect } from "../../inputs/ReactSelect";
import { Link } from "react-router-dom";
import { Button, ButtonType } from "../../Button";
import { Guid } from "js-guid";
import { IconButton } from "../../IconButton";
import { useLayout } from "../../../contexts/layout/layout-context";

export interface DropdownTitle {
  // Required for Dropdown PageTitle
  dropdownOpt: { label: ReactNode; value: any }[];
  dropdownOnChange: (
    options: MultiValue<object> | SingleValue<object>,
    actionMeta: ActionMeta<object>,
  ) => void;
}

export interface BreadcrumbTitle {
  // Required for Breadcrumb PageTitle
  backPageTitle: string;
  backUrl: string;
}

export interface PageHeaderButton {
  buttonName: string;
  onClick: () => void;
  disabled?: boolean;
  iconClass?: string;
  isIconButton?: boolean;
}

export type PageTitleType = "Default" | "Dropdown" | "Breadcrumb";

type Props = {
  pageTitle: string | JSX.Element;
  pageTitleType: PageTitleType;

  lastSeen?: string;
  extraInfo?: JSX.Element;

  dropdownSetting?: DropdownTitle;
  breadcrumbSetting?: BreadcrumbTitle;
  buttons?: PageHeaderButton[];
};

const PageHeaderNew: FC<Props> = ({
  pageTitle,
  pageTitleType,
  lastSeen,
  extraInfo,
  dropdownSetting,
  breadcrumbSetting,
  buttons,
}) => {
  const [type, setType] = useState<PageTitleType>("Default");
  const {
    updatedPageTitle,
    updatedPageTitleType,
    updatedDropdownSetting,
    updatedBreadcrumbSetting,
    updatedExtraInfo,
    updatedButtons,
  } = useLayout();

  useEffect(() => {
    const type = updatedPageTitleType ?? pageTitleType;
    if (type) setType(type);
  }, [pageTitleType, updatedPageTitleType]);

  const GetPageTitle = () => {
    const title = updatedPageTitle ? updatedPageTitle : pageTitle;
    let titleElement = (
      <div className="page-title-default-container">
        <p className="page-title-default"> {title} </p>
      </div>
    );

    switch (type) {
      case "Default":
        break;
      case "Dropdown": {
        const dSetting = updatedDropdownSetting ? updatedDropdownSetting : dropdownSetting;
        if (dSetting) {
          titleElement = (
            <div className="page-title-dropdown-container">
              <ReactSelect
                idName={`page-title-${title}`}
                forPageTitle={true}
                inputValue={title}
                isSearchable={false}
                options={dSetting.dropdownOpt}
                onChange={dSetting.dropdownOnChange}
              />
            </div>
          );
        }
        break;
      }
      case "Breadcrumb": {
        const bSetting = updatedBreadcrumbSetting ? updatedBreadcrumbSetting : breadcrumbSetting;
        if (bSetting) {
          titleElement = (
            <span className="page-title-breadcrumb-container">
              <Link to={bSetting.backUrl} className="page-title-breadcrumb-back">
                {bSetting.backPageTitle}
              </Link>
              <span className="page-title-breadcrumb-separator"> / </span>
              <span className="page-title-breadcrumb"> {title} </span>
            </span>
          );
        }
        break;
      }
    }

    return <div className="page-header-title"> {titleElement} </div>;
  };

  const GetLastSeenBadge = () => {
    if (lastSeen) {
      return <div className="page-header-last-seen">{lastSeen}</div>;
    }
    return <></>;
  };

  const GenerateExtraInfos = () => {
    const extraInfos = updatedExtraInfo ? updatedExtraInfo : extraInfo;

    if (extraInfos) {
      return <div className="page-header-extra-info">{extraInfos}</div>;
    }

    return <></>;
  };

  const GenerateButtons = () => {
    const newButtons = updatedButtons ? updatedButtons : buttons;
    const headerButtons: JSX.Element[] = [];

    if (newButtons) {
      newButtons.forEach((button) => {
        if (!!button.isIconButton && !!button.iconClass) {
          headerButtons.push(
            <IconButton
              key={new Guid().toString()}
              className="page-header-button icon-button"
              iconClass={button.iconClass}
              onClick={button.onClick}
              disabled={button.disabled}
              isDark={false}
            />,
          );
        } else {
          headerButtons.push(
            <Button
              key={new Guid().toString()}
              content={button.buttonName}
              buttonType={ButtonType.White}
              className="page-header-button"
              onClick={button.onClick}
              disabled={button.disabled}
              iconClass={button.iconClass}
            />,
          );
        }
      });
    }

    return <div className="page-header-buttons"> {headerButtons} </div>;
  };

  return (
    <div className="page-header-container">
      <GetPageTitle />
      <GetLastSeenBadge />
      <GenerateExtraInfos />
      <GenerateButtons />
    </div>
  );
};

export default PageHeaderNew;
