import { OrganizationTheme } from "../models/api/OrganizationTheme";
import { UrlsAddress } from "./UrlsAddress";
import JwtTokenHelpers from "./JwtTokenHelpers";

const SupportLink = () => {
  const theme: OrganizationTheme = JwtTokenHelpers.getOrgTheme();

  return (
    <>
      Please contact<span>&nbsp;</span>
      <span className="text-caption-link">
        <a
          href={`${theme.supportUrl ? theme.supportUrl : UrlsAddress.SupportLink}`}
          className="link"
          target="_blank"
        >
          support
        </a>
      </span>
      .
    </>
  );
};

export default SupportLink;
