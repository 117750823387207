import { FC } from "react";

import { Button } from "../../components/Button";
import { useLayout } from "../../contexts/layout/layout-context";

interface IProps {}

const Http404Page: FC<IProps> = () => {
  const layout = useLayout();

  layout.updatePageTitle("404 - Page not found");

  return (
    <div className="max-width-container-lg">
      <br />
      <br />
      <br />
      <div className="d-flex flex-row align-items-center justify-content-center">
        <i className="far fa-surprise mr-3" style={{ fontSize: "12em" }} />
        <div className="d-flex flex-column align-items-start ml-3">
          <h1 className="mb-0" style={{ fontSize: "8em" }}>
            404!
          </h1>
          <h5 className="text-uppercase mb-1">Page not found</h5>
          <p className="mb-3">Sorry - the page you are looking for could not be found.</p>
          <Button className="mb-3" content="Take me back" onClick={() => window.history.back()} />
        </div>
      </div>
    </div>
  );
};

export { Http404Page };
