import { FC, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import "../../header/headerStyles.scss";
import { AlertNotification, AlertPriorityLabel } from "./AlertNotificationHelper";
import useOnScreen from "../../../../hooks/useOnScreen";
import {
  useListAlertNotifications,
  useUpdateAlertNotificationFirstSeen,
  useUpdateAlertNotificationIsActioned,
} from "../../../../services/context/AlertApiServiceContext";

type Props = {
  alertNotification: AlertNotification;
};

const AlertNotificationCard: FC<Props> = ({ alertNotification }) => {
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const isVisibleOnScreen = useOnScreen(ref);
  const { refetch: refetchListAlertNotifications } = useListAlertNotifications();
  const { mutate: doUpdateAlertNotificationFirstSeen } = useUpdateAlertNotificationFirstSeen();
  const { mutate: doUpdateAlertNotificationIsActioned } = useUpdateAlertNotificationIsActioned();
  const updateFirstSeen = () => {
    doUpdateAlertNotificationFirstSeen({
      deviceAlertInfoId: alertNotification.deviceAlertInfoId,
      deviceId: alertNotification.deviceId,
    });
  };

  const updateIsActioned = () => {
    doUpdateAlertNotificationIsActioned({
      deviceAlertInfoId: alertNotification.deviceAlertInfoId,
      deviceId: alertNotification.deviceId,
    });
    refetchListAlertNotifications();
  };

  useEffect(() => {
    if (isVisibleOnScreen) {
      updateFirstSeen();
    }
  }, [isVisibleOnScreen]);

  return (
    <section
      ref={ref}
      className={`alert-notification-container 
			${
        alertNotification.isActioned && alertNotification.isSeen
          ? "alert-notification-de-highlighted"
          : ""
      }`}
      onClick={() => {
        updateIsActioned();
        history.push(`/go/dashboard/device/${alertNotification.deviceId}`);
      }}
    >
      <div className="alert-notification-row-one">
        <div className="alert-notification-device-name">
          {!alertNotification.isSeen && <div className="alert-notification-dot" />}
          {alertNotification.deviceName}
        </div>

        <AlertPriorityLabel priority={alertNotification.priority} />
      </div>

      <div className="alert-notification-row-two">
        <div className="alert-notification-date">{alertNotification.dateString}</div>

        <div className="alert-notification-message">{alertNotification.alertMessage}</div>
      </div>
    </section>
  );
};

export default AlertNotificationCard;
