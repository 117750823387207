import { Component } from "react";
import { MeasurementsApiService } from "../services/api/MeasurementsApiService";
import { Measurement } from "../models/api/Measurement";
import { LoadingSkeleton } from "./LoadingSkeleton";
import { UnitPreference } from "../models/api/UnitPreference";
import JwtTokenHelpers from "../helpers/JwtTokenHelpers";
import { MeasurementField } from "../models/api/MeasurementField";
import MeasurementHelpers from "../helpers/MeasurementHelpers";
import { ReactSelect } from "./inputs/ReactSelect";
import { FormGroup } from "./forms/FormGroup";

interface IProps {
  onOrgPage: boolean;
}

interface IState {
  measurements?: Measurement[];
  unitPreferences?: UnitPreference[];
}

export class UnitPreferences extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      Promise.all([
        new MeasurementsApiService().listForOrg(true),
        new MeasurementsApiService().listUnitPreferences(),
      ])
        .then((res) =>
          this.setState({
            measurements: res[0].items,
            unitPreferences: res[1],
          }),
        )
        .catch((err) =>
          $.notification("show", {
            type: "error",
            title: undefined,
            message: err,
            toastOnly: true,
          }),
        );
    }, 0);
  }

  getMeasurementsWithUnits(): Measurement[] | undefined {
    return this.state.measurements?.filter((m) => {
      const fieldWithMultipleDisplayUnits = m.fields?.find((f) => f.displayUnits.length > 1);
      return fieldWithMultipleDisplayUnits != undefined;
    });
  }

  generateDisplayUnitsOptions(field: MeasurementField) {
    if (!this.state.unitPreferences) return [];
    const userUp = MeasurementHelpers.getUserUnitPreference(this.state.unitPreferences, field);
    const orgUp = MeasurementHelpers.getOrgUnitPreference(this.state.unitPreferences, field);

    return field.displayUnits.map((du) => {
      return {
        label: (
          <>
            {du.displayUnit}
            <span className="text-muted">
              {!this.props.onOrgPage && userUp?.displayUnitId == du.id && " (User Default)"}
              {orgUp?.displayUnitId == du.id && userUp?.displayUnitId != du.id && " (Org Default)"}
              {du.isDefault &&
                orgUp?.displayUnitId != du.id &&
                userUp?.displayUnitId != du.id &&
                " (Global Default)"}
            </span>
          </>
        ),
        value: du.id,
      };
    });
  }

  getSelectedDisplayUnit(field: MeasurementField) {
    const displayUnitOptions = this.generateDisplayUnitsOptions(field);
    if (!this.state.unitPreferences || !displayUnitOptions || !field || !field.id) return;
    const displayUnitId = MeasurementHelpers.getPreferedDisplayUnitId(
      this.state.unitPreferences,
      field,
      this.props.onOrgPage,
    );
    return displayUnitOptions.find((duo) => duo.value == displayUnitId);
  }

  handleSelectedDisplayUnitChange(
    field: MeasurementField,
    option: { value: string | undefined } | null,
  ) {
    if (!field.id || !this.state.unitPreferences) return;

    let unitPreferences = [...(this.state.unitPreferences ?? [])];
    let unitPreference = !this.props.onOrgPage
      ? MeasurementHelpers.getUserUnitPreference(unitPreferences, field)
      : MeasurementHelpers.getOrgUnitPreference(unitPreferences, field);

    if (!option || !option.value) {
      if (unitPreference && unitPreference.id) {
        new MeasurementsApiService()
          .deleteUnitPreference(unitPreference.id)
          .then(() => {
            if (!unitPreference) return;
            $.notification("show", {
              type: "message",
              title: undefined,
              message: "Unit preference removed",
              toastOnly: true,
            });
            unitPreferences = unitPreferences.filter((up) => up.id != unitPreference?.id);
            this.setState({ unitPreferences });
          })
          .catch((err) =>
            $.notification("show", {
              type: "error",
              title: undefined,
              message: err,
              toastOnly: true,
            }),
          );
      }
      return;
    }

    if (!unitPreference) {
      if (!this.props.onOrgPage) {
        unitPreference = {
          measurementFieldId: field.id as string,
          displayUnitId: option.value as string,
          userId: JwtTokenHelpers.getUserId(),
        };
      } else {
        unitPreference = {
          measurementFieldId: field.id as string,
          displayUnitId: option.value as string,
          orgId: JwtTokenHelpers.getOrgId(),
        };
      }
      new MeasurementsApiService()
        .createUnitPreference(unitPreference)
        .then((res) => {
          if (!unitPreference) return;
          $.notification("show", {
            type: "message",
            title: undefined,
            message: "Unit preference updated",
            toastOnly: true,
          });
          unitPreference.id = res;
          unitPreferences.push(unitPreference);
          this.setState({ unitPreferences });
        })
        .catch((err) =>
          $.notification("show", {
            type: "error",
            title: undefined,
            message: err,
            toastOnly: true,
          }),
        );
    } else {
      unitPreference.displayUnitId = option.value as string;
      new MeasurementsApiService()
        .updateUnitPreference(unitPreference.id as string, unitPreference)
        .then(() => {
          $.notification("show", {
            type: "message",
            title: undefined,
            message: "Unit preference updated",
            toastOnly: true,
          });
          this.setState({ unitPreferences });
        })
        .catch((err) =>
          $.notification("show", {
            type: "error",
            title: undefined,
            message: err,
            toastOnly: true,
          }),
        );
    }
  }

  render() {
    const measurementsWithUnits = this.getMeasurementsWithUnits();

    return (
      <FormGroup title="Unit Preferences">
        <div className="mt-n3">
          {measurementsWithUnits && (
            <>
              {measurementsWithUnits.map((m, index) => (
                <div key={`mwu${index}`}>
                  <p className="text-para-large text-para-default-bold mb-2 mt-3">
                    {m.displayName}
                  </p>

                  <div className="row">
                    {m.fields
                      ?.filter((f) => f.displayUnits.length > 1)
                      ?.map((f, index) => (
                        <div
                          className={`${
                            m.displayName == "Rainfall" ? "col-xl-6" : "col-xl-12"
                          } col-sm-12 mb-3`}
                          key={`du${index}`}
                        >
                          <ReactSelect
                            idName={`${m.displayName}-${f.displayName}-input`}
                            labelString={f.displayName != m.displayName ? f.displayName : ""}
                            options={this.generateDisplayUnitsOptions(f)}
                            inputValue={this.getSelectedDisplayUnit(f)?.value}
                            onChange={(option: any) =>
                              this.handleSelectedDisplayUnitChange(f, option)
                            }
                            isSearchable={false}
                            isClearable={
                              (!this.props.onOrgPage &&
                                MeasurementHelpers.getUserUnitPreference(
                                  this.state.unitPreferences ?? [],
                                  f,
                                ) != undefined) ||
                              (this.props.onOrgPage &&
                                MeasurementHelpers.getOrgUnitPreference(
                                  this.state.unitPreferences ?? [],
                                  f,
                                ) != undefined)
                            }
                            placeholder="Select unit..."
                          />
                          {/* <label>{f.displayName}</label>
                            <Select
                              classNamePrefix="react-select"
                              className="input w-100"
                              options={this.generateDisplayUnitsOptions(f)}
                              value={this.getSelectedDisplayUnit(f)}
                              onChange={(option) =>
                                this.handleSelectedDisplayUnitChange(
                                  f,
                                  option
                                )
                              }
                              isSearchable={false}
                              isClearable={
                                (!this.props.onOrgPage &&
                                  MeasurementHelpers.getUserUnitPreference(
                                    this.state.unitPreferences ?? [],
                                    f
                                  ) != undefined) ||
                                (this.props.onOrgPage &&
                                  MeasurementHelpers.getOrgUnitPreference(
                                    this.state.unitPreferences ?? [],
                                    f
                                  ) != undefined)
                              }
                              placeholder="Select unit..."
                              menuPortalTarget={document.body}
                            /> */}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </>
          )}
          {measurementsWithUnits && measurementsWithUnits.length <= 0 && (
            <p className="text-muted mt-3">No measurements with multiple display unit options.</p>
          )}
          {!measurementsWithUnits && (
            <div className="mt-3">
              <LoadingSkeleton width="48px" height="18px" className="mb-3" />
              <LoadingSkeleton width="162px" height="16px" className="mb-3" />
              <LoadingSkeleton width="146px" height="16px" className="mb-3" />
              <LoadingSkeleton width="62px" height="18px" className="mb-3" />
              <LoadingSkeleton width="132px" height="16px" className="mb-3" />
              <LoadingSkeleton width="156px" height="16px" className="mb-3" />
              <div className="d-flex flex-row">
                <span className="flex-grow-0 flex-md-grow-1"></span>
                <LoadingSkeleton width="132px" height="38px" />
              </div>
            </div>
          )}
        </div>
      </FormGroup>
    );
  }
}
