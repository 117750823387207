import { FC, useEffect, useState } from "react";
import * as d3 from "d3";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
//Type of the chart
import * as am5xy from "@amcharts/amcharts5/xy";
import { MinMaxUnit } from "../../../pages/simpleapp/components/SimpleAppTemplate";
import {
  ColorVariables,
  getChartDateTime,
} from "../../../pages/simpleapp/components/widgets/WidgetHelper";
import { WindSpeedAndDirectionHistoricalDatum } from "../../../pages/simpleapp/components/widgets/historic/hooks/useHistoricalWindSpeedAndDirection";
import arrow from "./assets/arrow-up-solid.png";

type DataModel = {
  time: string;
  speed: number;
  direction: number;
  bulletSettings: {
    fill: am5.Color;
  };
  arrowSettings: {
    rotation: number;
  };
};

type Props = {
  data: WindSpeedAndDirectionHistoricalDatum[];
  speedLabel: string;
  directionLabel: string;
  chartId: string;
};

const AMCWindSpeedAndDirection: FC<Props> = ({ data, speedLabel, directionLabel, chartId }) => {
  const [normalizedData, setNormalizedData] = useState<DataModel[]>([]);

  // Default
  const [speedData, setSpeedData] = useState<MinMaxUnit>({ min: 0, max: 100, unit: "" });
  const [directionData, setDirectionData] = useState<MinMaxUnit>({ min: 0, max: 360, unit: "" });

  function getSpeedColor(speed: number) {
    if (speed >= 0 && speed <= 1) {
      return am5.color(ColorVariables.colorSuccess);
    } else if (speed > 1 && speed <= 1.5) {
      return am5.color(ColorVariables.colorSuccessTransparent);
    } else if (speed > 1.5 && speed <= 2) {
      return am5.color(ColorVariables.colorWarning);
    } else if (speed > 2 && speed <= 3.5) {
      return am5.color(ColorVariables.colorWarningDark);
    } else {
      return am5.color(ColorVariables.colorError);
    }
  }

  useEffect(() => {
    if (data) {
      setSpeedData({ min: 0, max: 100, unit: data[0].speedDisplayUnit });
      setDirectionData({ min: 0, max: 360, unit: data[0].directionDisplayUnit });
      const dataArr: DataModel[] = [];

      data.map((datum) => {
        dataArr.push({
          time: getChartDateTime(datum.time),
          speed: datum.speed,
          direction: datum.direction,
          bulletSettings: {
            fill: getSpeedColor(datum.speed),
          },
          arrowSettings: {
            rotation: datum.direction,
          },
        });
      });

      setNormalizedData(dataArr);
    }
  }, [data]);

  useEffect(() => {
    if (!!chartId && !!normalizedData && normalizedData.length > 0) {
      const root = am5.Root.new(chartId);

      // Set themes (https://www.amcharts.com/docs/v5/concepts/themes/)
      root.setThemes([am5themes_Animated.new(root)]);

      // Create Chart (https://www.amcharts.com/docs/v5/charts/xy-chart/)
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: false,
          wheelY: "zoomX",
          layout: root.verticalLayout,
          marginBottom: 10,
        }),
      );

      // Add cursor: In here you can configure how the cursor is displayed and how it behaves on the chart.
      const cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "none",
        }),
      );
      cursor.lineY.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });
      cursor.lineX.setAll({
        stroke: am5.color(ColorVariables.colorPlaceholder),
        visible: true,
      });

      // Create axes https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      // For the X-Axis
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "time",
          marginTop: 15,
          maxDeviation: 0,
          renderer: am5xy.AxisRendererX.new(root, {}),
        }),
      );
      xAxis.data.setAll(normalizedData);

      //### For the Speed (Line) ###//
      // Left Axis
      const speedYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: -0.5,
          numberFormat: `#'${speedData.unit}'`,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );
      speedYAxis.get("renderer").grid.template.set("forceHidden", true);

      // Line Series - speed data
      const speedSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: speedLabel,
          xAxis: xAxis,
          yAxis: speedYAxis,
          valueYField: "speed",
          valueField: "direction",
          categoryXField: "time",
          curveFactory: d3.curveBumpX,
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]${speedLabel}[/]: {valueY}${speedData.unit} 
                           \n[bold]${directionLabel}[/]: {value}${directionData.unit}
                           \n\n[bold]DateTime[/]: {categoryX}`,
            dy: -5,
          }),
        }),
      );
      speedSeries.strokes.template.setAll({
        stroke: am5.color(ColorVariables.colorBlueWaterLight),
        strokeWidth: 1,
      });

      speedSeries.bullets.push(function () {
        const container = am5.Container.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
        });

        container.children.push(
          am5.Circle.new(root, {
            radius: 13,
            stroke: am5.color(ColorVariables.colorWhite),
            strokeWidth: 3,
            templateField: "bulletSettings",
          }),
        );

        container.children.push(
          am5.Picture.new(root, {
            centerX: am5.p50,
            centerY: am5.p50,
            width: 12,
            height: 12,
            src: arrow,
            templateField: "arrowSettings",
          }),
        );

        return am5.Bullet.new(root, {
          sprite: container,
        });
      });

      speedSeries.data.setAll(normalizedData);
      speedSeries.appear(1000);

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      speedSeries.set("setStateOnChildren", true);
      speedSeries.states.create("hover", {});

      speedSeries.mainContainer.set("setStateOnChildren", true);
      speedSeries.mainContainer.states.create("hover", {});

      speedSeries.strokes.template.states.create("hover", {
        strokeWidth: 3,
      });

      // Add scrollbar
      const scrollbar = chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
          start: 0.8,
          height: 10,
          marginTop: 15,
        }),
      );
      scrollbar.thumb.setAll({
        fill: am5.color(ColorVariables.colorPlaceholder),
      });
      scrollbar.startGrip.setAll({
        visible: false,
      });
      scrollbar.endGrip.setAll({
        visible: false,
      });
      chart.bottomAxesContainer.children.push(scrollbar);

      // Add Legends
      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      );

      // Make series change state when legend item is hovered
      legend.itemContainers.template.states.create("hover", {});

      legend.itemContainers.template.events.on("pointerover", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.hover();
      });
      legend.itemContainers.template.events.on("pointerout", function (e) {
        const target: any = e.target.dataItem?.dataContext;
        target.unhover();
      });

      legend.data.setAll(chart.series.values);

      // Make stuff animate on load (https://www.amcharts.com/docs/v5/concepts/animations/)
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }
  }, [chartId, normalizedData]);

  return <div className="am-chart-container" id={chartId} />;
};

export default AMCWindSpeedAndDirection;
