import { Promise } from "bluebird";
import moment from "moment";
import DateHelpers from "../../helpers/DateHelpers";
import { AxiosProgressEvent, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import axios from "axios";

axios.interceptors.request.use(
  function (request: InternalAxiosRequestConfig) {
    // Do something before request is sent
    const token = localStorage.getItem("JWT_TOKEN");
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // check if the user is logged in
    if (response.data.authenticate && !response.data.success) {
      console.error("Authentication error: ", response.data.message);
      window.location.href = "/go/home/logout";
    }
    // Massage the response
    //  message and errorMessage fields are both used to indicate the nature of an error
    //  we will make sure that both are set
    if (response.data.message && !response.data.errorMessage) {
      response.data.errorMessage = response.data.message;
    } else if (response.data.errorMessage && !response.data.message) {
      response.data.message = response.data.errorMessage;
    }
    return response.data;
  },
  async (error: any) => {
    return Promise.reject(error);
  },
);
export abstract class ApiService {
  apiRoot: string;
  path: string;

  protected constructor(apiRoot: string, path: string) {
    this.apiRoot = apiRoot;
    this.path = path;

    this.get = this.get.bind(this);
    this.delete = this.delete.bind(this);
    this.post = this.post.bind(this);
  }

  /**
   * Send a get request to the specified endpoint
   * Endpoint will be prepended by the current path
   * @param endpoint
   * @param onCancel
   * @protected
   */
  protected async get(endpoint: string, _onCancel?: (callback: () => void) => void): Promise<any> {
    return await this.getOther(`${this.path}/${endpoint}`);
  }

  /**
   * Send a delete request using the specified endpoint
   * Endpoint will be prepended by the API ROOT and current path
   * @param endpoint
   * @param onCancel
   * @protected
   */
  protected async delete(
    endpoint: string,
    onCancel?: (callback: () => void) => void,
  ): Promise<any> {
    const controller = new AbortController();

    const xhr = await axios.delete(`${this.apiRoot}/${this.path}/${endpoint}`, {
      signal: controller.signal,
    });
    if (onCancel) {
      onCancel(() => controller.abort());
    }
    return xhr;
  }

  /**
   * Send post request to specified endpoint
   * Endpoint will be prepended with current path
   * @param endpoint
   * @param postData Data will be formatted prior to sending
   * @param onCancel
   * @protected
   */
  protected async post(
    endpoint: string,
    postData: any,
    onCancel?: (callback: () => void) => void,
  ): Promise<any> {
    postData = this.cleanPostData(postData);
    return await this.postOther(`${this.path}/${endpoint}`, postData, onCancel);
  }

  /**
   * Send raw JSON post request to specified endpoint
   * Endpoint will be prepended with current path
   * @param endpoint
   * @param postData raw JSON data will be sent to endpoint
   * @param onCancel
   * @protected
   */
  protected async postJson(
    endpoint: string,
    postData: string,
    onCancel?: (callback: () => void) => void,
  ): Promise<any> {
    const controller = new AbortController();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      signal: controller.signal,
    };
    const xhr = await axios.post(`${this.apiRoot}/${this.path}/${endpoint}`, postData, config);
    if (onCancel) {
      onCancel(() => controller.abort());
    }
    return xhr;
  }

  /**
   * Format post data
   * Automatically format any moment dates
   * Stringify arrays
   * @param postData
   * @protected
   */
  protected cleanPostData(postData: any) {
    const cleanPostData: any = {};
    for (const key in postData) {
      const field = postData[key];
      if (moment.isMoment(field)) {
        cleanPostData[key] = DateHelpers.formatMomentAsServerString(field);
      } else if (Array.isArray(field)) {
        cleanPostData[key] = field.join(",");
      } else {
        cleanPostData[key] = field;
      }
    }
    return cleanPostData;
  }

  protected async uploadFile(
    endpoint: string,
    postData: FormData,
    progressCallback?: (loaded: number, total?: number) => void,
  ): Promise<any> {
    const onUploadProgress =
      progressCallback && ((ev: AxiosProgressEvent) => progressCallback(ev.loaded, ev.total));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    };
    const xhr = await axios.post(`${this.apiRoot}/${endpoint}`, postData, config);
    this.checkAuth(xhr);
    return xhr;
  }

  /**
   * Sends get request to specified endpoint
   * Endpoint will be prepended with the API ROOT
   * @param endpoint
   * @param onCancel
   * @protected
   */
  protected async getOther(
    endpoint: string,
    onCancel?: (callback: () => void) => void,
  ): Promise<any> {
    const controller = new AbortController();

    const xhr = await axios.get(`${this.apiRoot}/${endpoint}`, { signal: controller.signal });
    if (onCancel) {
      onCancel(() => controller.abort());
    }
    return xhr;
  }

  /**
   * Send post request to specified endpoint
   * Endpoint will be prepended with the API ROOT
   * @param endpoint
   * @param postData
   * @param onCancel
   * @protected
   */
  protected async postOther(
    endpoint: string,
    postData: any,
    onCancel?: (callback: () => void) => void,
  ): Promise<any> {
    const controller = new AbortController();

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      signal: controller.signal,
    };
    const xhr = await axios.post(`${this.apiRoot}/${endpoint}`, postData, config);
    if (onCancel) {
      onCancel(() => controller.abort());
    }
    return xhr;
  }

  /**
   * Check authentication status of response
   * Logout if failure is detected
   * @param response
   */
  checkAuth(response: any) {
    if (response.authenticate && !response.success) {
      console.error("Authentication error: ", response.message);
      window.location.href = "/go/home/logout";
    }
  }
}
