import { useEffect, useMemo, useState } from "react";
import "./dashboardTemplateStyles.scss";
import { useParams } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";
import AceEditor from "react-ace";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import "brace/mode/json";
import "brace/theme/dreamweaver";
import { SimpleApp } from "../../simpleapp/SimpleApp";
import { Input } from "../../../components/inputs/Input";
import { ReactSelect } from "../../../components/inputs/ReactSelect";
import { DashboardTemplate } from "../../../models/api/DashboardTemplate";
import {
  useDashboardTemplate,
  useDeviceTypesWithTemplates,
  useUpsertDashboardTemplate,
} from "../../../services/context/DashboardTemplateApiServiceContext";
import { useDeviceTypeList } from "../../../services/context/DeviceTypesApiServiceContext";
import { FormWrapper } from "../../../components/forms/FormWrapper";
import { FormGroup } from "../../../components/forms/FormGroup";
import { FormButtons } from "../../../components/forms/FormButtons";
import { useOrganizationList } from "../../../services/context/OrganizationsApiServiceContext";
import { Button, ButtonType } from "../../../components/Button";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";

import defaultTemplate from "./template-empty.json";

const ErrorFallback = ({ error }: FallbackProps) => {
  return (
    <div role="alert">
      <p>The renderer is unable to parse the template.</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
};

// device being tested with current template
const DEFAULT_DEVICE_ID = "2952c89c-d462-4285-b154-dcfd81382d52"; // RGD
// const DEFAULT_DEVICE_ID = "7bb63085-9a70-4637-a52a-1778cbed38d9"; // Water tank (Working)
// const DEFAULT_DEVICE_ID = "d72cad6e-d8f4-4b70-96d8-87d92c533714"; // Solar
// const DEFAULT_DEVICE_ID = "fc4882c5-ff79-4f18-8f14-05f0e9379ace"; // Water Tanks (Old and Broken: Cannot configured correctly)
// const DEFAULT_DEVICE_ID = "f6b3f838-d8b5-4557-9b4e-31fc6fd0d435"; // ModuSense1 (Ultrasonic: For River)

// organization being tested with current template
const DEFAULT_ORG_ID = "7216d658-8065-4a0f-ba5b-2b9eff738d4b"; // RGD
// const DEFAULT_ORG_ID = "8c74916b-6073-4b97-ad87-2b263614786c"; // Solar

const EditTemplatePage = () => {
  const params: any = useParams();
  const [templateName, setTemplateName] = useState<string>("");
  // Raw template content, used by the Ace Editor
  const [templateContentRaw, setTemplateContentRaw] = useState<string>();
  // Parsed template content. Set when the raw template can be parsed. Used by Template Renderer
  const [templateContentParsed, setTemplateContentParsed] = useState<any>();
  // device types associated with current template
  const [deviceTypeIds, setDeviceTypeIds] = useState<string[]>([]);
  // device type options showing all device types in Select control
  const [deviceTypeOptions, setDeviceTypeOptions] = useState<{ label: string; value: string }[]>(
    [],
  );

  // organization options showing all organizations in Select control
  const [organizationOptions, setOrganizationOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // flag indicating if the template has been successfully parsed
  const [jsonParsed, setJsonParsed] = useState(true);
  // list of device type ids which have an associated template
  const [deviceTypesWithTemplate, setDeviceTypesWithTemplate] = useState<string[]>();

  const [lastTimeWidgetsUpdate, setLastTimeWidgetsUpdate] = useState(new Date().getTime());

  /* Database related hooks */
  // Dashboard Template object (as stored on the server)
  const [template, setTemplate] = useState<DashboardTemplate>();
  // Get list of device types with a template
  const { isSuccess: deviceTypesWithTemplatesLoaded, data: deviceTypesWithTemplates } =
    useDeviceTypesWithTemplates();
  // current template
  const { isSuccess: templateLoaded, data: dashboardTemplate } = useDashboardTemplate(
    params.templateId,
  );
  // list of device types
  const { isSuccess: deviceTypesLoaded, data: deviceTypes } = useDeviceTypeList();
  // list of organizations
  const { isSuccess: organizationsLoaded, data: organizations } = useOrganizationList();

  const { mutate: saveTemplate } = useUpsertDashboardTemplate({
    onSuccess: () => {
      $.notification("show", {
        type: "message",
        title: undefined,
        message: "The template was successfully saved",
        toastOnly: true,
      });
    },
    onError: (err) => {
      $.notification("show", {
        type: "error",
        title: undefined,
        message: err,
        toastOnly: true,
      });
    },
  });

  // When the template is loaded
  useEffect(() => {
    if (templateLoaded && !!dashboardTemplate) {
      let testDeviceId = DEFAULT_DEVICE_ID;
      let testOrgId = DEFAULT_ORG_ID;

      if (dashboardTemplate.testDeviceId) testDeviceId = dashboardTemplate.testDeviceId;
      if (dashboardTemplate.testOrgId) testOrgId = dashboardTemplate.testOrgId;

      setTemplate({
        ...dashboardTemplate,
        testDeviceId,
        testOrgId,
      });
      setTemplateName(dashboardTemplate!.name);

      if (dashboardTemplate.content) {
        const parsedTemplate = JSON.parse(dashboardTemplate.content);
        setTemplateContentParsed(parsedTemplate);
        setTemplateContentRaw(JSON.stringify(parsedTemplate, null, "\t"));
      }
      setDeviceTypeIds(dashboardTemplate.dashboardTemplateDeviceTypeIds ?? []);
      updateLastTimeWidgetsUpdate();
    }
  }, [dashboardTemplate]);

  useEffect(() => {
    if (deviceTypes?.items) {
      const devTypes = deviceTypes.items!.map((dt) => ({
        label: dt.name,
        value: dt.id || "",
      }));
      setDeviceTypeOptions(devTypes);
    }
  }, [deviceTypes?.items, templateLoaded]);

  useEffect(() => {
    if (organizations?.items) {
      const orgList = organizations.items.map((org) => ({
        label: org.name,
        value: org.id,
      }));
      setOrganizationOptions(orgList);
    }
  }, [organizations?.items, templateLoaded]);

  useEffect(() => {
    // Set a default template, used when adding a new one
    if (params.templateId === undefined) {
      setTemplateContentRaw(JSON.stringify(defaultTemplate, null, "\t"));
      setTemplateContentParsed(defaultTemplate);
      const dashboardTemplate: DashboardTemplate = {
        id: "",
        name: "",
        content: templateContentRaw ?? "",
        dashboardTemplateDeviceTypeIds: deviceTypeIds,
        measurementCount: 0,
        measurementIds: [],
      };
      setTemplate(dashboardTemplate);
    }
  }, []);

  useEffect(() => {
    // Create list a deviceTypeId's which have a template.
    // Note: The deviceTypeId associated with the current template should be excluded
    // This list is used to disable the devices which have a template in the Select Control
    if (
      deviceTypesWithTemplatesLoaded &&
      template &&
      deviceTypeIds &&
      deviceTypesWithTemplate === undefined
    ) {
      const disabledDeviceTypeIds = deviceTypesWithTemplates!
        .map((d: { deviceTypeId: string }) => d.deviceTypeId)
        .filter((d: string) => !deviceTypeIds.includes(d));
      setDeviceTypesWithTemplate(disabledDeviceTypeIds);
    }
  }, [deviceTypesWithTemplates, dashboardTemplate, deviceTypeIds, template]);

  // Event handlers
  const handleChangeTemplate = (newValue: string) => {
    try {
      const parsedTemplate = JSON.parse(newValue);
      setTemplateContentRaw(newValue);
      setTemplateContentParsed(parsedTemplate);
      setJsonParsed(true);
    } catch (e) {
      setTemplateContentRaw(newValue);
      setJsonParsed(false);
    }
  };

  const handleTemplateNameChange = (event: any) => {
    setTemplateName(event.target.value);
  };

  const handleDeviceIdChange = (event: any) => {
    setTemplate((prevState) => ({
      ...prevState!,
      testDeviceId: event.target.value,
    }));
  };

  const handleSaveTemplate = () => {
    if (template) {
      saveTemplate({
        id: template.id,
        name: templateName,
        content: templateContentRaw ?? "",
        deviceTypeIds: deviceTypeIds,
        // set default in case user doesn't enter deviceId
        testDeviceId: template.testDeviceId || DEFAULT_DEVICE_ID,
        // set default in case user doesn't enter orgId
        testOrgId: template.testOrgId || DEFAULT_ORG_ID,
      });

      updateLastTimeWidgetsUpdate();
    }
  };

  const updateLastTimeWidgetsUpdate = () => {
    setLastTimeWidgetsUpdate(new Date().getTime());
  };

  const handleRedrawWidgets = () => updateLastTimeWidgetsUpdate();

  const getSimpleApp = useMemo(() => {
    if (!!template && !!template.testDeviceId && !!templateContentParsed) {
      return (
        <SimpleApp
          deviceId={template.testDeviceId}
          orgId={template.testOrgId}
          template={templateContentParsed}
        />
      );
    }

    return <LoadingSkeleton width="100%" height="100%" />;
  }, [lastTimeWidgetsUpdate]);

  if (!deviceTypesLoaded || !deviceTypesWithTemplatesLoaded || !organizationsLoaded) return <></>;

  return (
    <div className="page-structure-container">
      <section className="page-structure-header content-header">
        <PageHeader
          pageTitle={`${params.templateId === undefined ? "Add Template" : "Edit Template"}`}
        />
      </section>

      {/*TODO: Improve Layout*/}
      <section className="page-structure-content">
        <div className="page-content-container dash-template-content">
          <section className="dash-template-editor">
            <div className="dash-editor-json">
              <AceEditor
                mode="json"
                theme="dreamweaver"
                value={templateContentRaw}
                onChange={handleChangeTemplate}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{ $blockScrolling: true }}
                width="100%"
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 3,
                  useWorker: false,
                }}
              />
              <Button
                content="Refresh Widgets"
                buttonType={ButtonType.Fill}
                onClick={handleRedrawWidgets}
              />
            </div>

            <div className="dash-editor-inputs">
              <FormWrapper>
                <FormGroup title="Test Device" />
                <Input
                  idName="deviceId"
                  labelString="Device Id"
                  placeholder="Enter the device's Id to test"
                  name="deviceId"
                  inputValue={template?.testDeviceId}
                  onChange={handleDeviceIdChange}
                />

                <ReactSelect
                  idName="org-ids"
                  labelString="Org Id"
                  placeholder="Select an organization to test"
                  options={organizationOptions}
                  inputValue={template?.testOrgId}
                  onChange={(opt: any) => {
                    let testOrgId = DEFAULT_ORG_ID;

                    if (opt != null) testOrgId = opt.value;

                    setTemplate((prevState) => ({
                      ...prevState!,
                      testOrgId: testOrgId,
                    }));
                  }}
                />
              </FormWrapper>

              <br />
              <br />
              <br />

              <FormWrapper>
                <FormGroup title="Template Details" />
                <Input
                  idName="name"
                  labelString="Template Name"
                  placeholder="Enter a dashboard template name"
                  name="templateName"
                  inputValue={templateName}
                  onChange={handleTemplateNameChange}
                />

                <ReactSelect
                  idName="device-types"
                  labelString="Device Types"
                  placeholder="Select device types"
                  options={deviceTypeOptions}
                  inputValue={deviceTypeIds}
                  onChange={(opt: any) => {
                    if (opt != null) setDeviceTypeIds(opt.map((o: any) => o.value));
                    else setDeviceTypeIds([]);
                  }}
                  isClearable={true}
                  isMulti={true}
                />

                <FormButtons
                  cancelUrl="/go/inhouse/templates"
                  onSave={handleSaveTemplate}
                  hasChanges={templateName !== "" && deviceTypeIds!.length > 0}
                  isSaving={false}
                />
              </FormWrapper>
            </div>
          </section>

          <section className="dash-template-display">
            <div className="dash-display-separator">
              <p className="text-page-subheading"> Sample View </p>
              <p> Below is the sample display </p>
            </div>

            <div className="dash-display">
              <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[jsonParsed]}>
                {getSimpleApp}
              </ErrorBoundary>
            </div>
          </section>
        </div>
      </section>
      {/*TODO: Improve Layout - End*/}
    </div>
  );
};

export default EditTemplatePage;
