import { lazy } from "react";
import { RouteConfig } from "../../AuthenticatedApp";
import { RouteComponentProps } from "react-router-dom";

const WelcomePage = lazy(() =>
  import("./WelcomePage").then(({ WelcomePage }) => ({ default: WelcomePage })),
);

export const WelcomeRoutes: RouteConfig[] = [
  {
    path: "/go/welcome",
    title: "Welcome",
    component: (props: RouteComponentProps<any>) => <WelcomePage {...props} />,
  },
];
