import { FC, useEffect } from "react";

import { Button } from "../../components/Button";
import { useLayout } from "../../contexts/layout/layout-context";

interface IProps {}
const Http401Page: FC<IProps> = () => {
  const layout = useLayout();

  useEffect(() => {
    layout.updatePageTitle("401 - Access denied");
  }, []);

  return (
    <div className="max-width-container-lg">
      <br />
      <br />
      <br />
      <div className="d-flex flex-row align-items-center justify-content-center">
        <i className="fa fa-exclamation-triangle mr-3" style={{ fontSize: "12em" }} />
        <div className="d-flex flex-column align-items-start ml-3">
          <h1 className="mb-0" style={{ fontSize: "8em" }}>
            401!
          </h1>
          <h5 className="text-uppercase mb-1">Access Denied</h5>
          <p className="mb-3">
            Sorry - you don't have the correct permissions to access this page.
          </p>
          <Button className="mb-3" content="Take me back" onClick={() => window.history.back()} />
        </div>
      </div>
    </div>
  );
};

export { Http401Page };
