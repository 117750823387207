import { Redirect, RouteComponentProps } from "react-router-dom";
import { lazy } from "react";
import { RouteConfig } from "../../AuthenticatedApp";

const EditDeviceGroupPage = lazy(() =>
  import("./deviceGroups/EditDeviceGroupsPage").then(({ EditDeviceGroupPage }) => ({
    default: EditDeviceGroupPage,
  })),
);
const DeviceGroupsPage = lazy(() =>
  import("./deviceGroups/DeviceGroupsPage").then(({ DeviceGroupsPage }) => ({
    default: DeviceGroupsPage,
  })),
);
const EditWebhookPage = lazy(() =>
  import("./webhooks/EditWebhookPage").then(({ EditWebhookPage }) => ({
    default: EditWebhookPage,
  })),
);
const UserManagementPage = lazy(() =>
  import("./users/UserManagementPage").then(({ UserManagementPage }) => ({
    default: UserManagementPage,
  })),
);
const AccessGroupsEditPage = lazy(() =>
  import("./accessGroups/AccessGroupsEditPage").then(({ AccessGroupsEditPage }) => ({
    default: AccessGroupsEditPage,
  })),
);

import { AlertsPage } from "./alerts/AlertsPage";
import { UserEditPage } from "../shared/UserEditPage";
import { EditAlertPage } from "./alerts/EditAlertPage";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../models/api/Role";
import { OrganizationPage } from "./yourOrganization/OrganizationPage";
import { TagsPage } from "./tags/TagsPage";
import { EditTagPage } from "./tags/EditTagPage";
import { AlertsFeedPage } from "../alerts/AlertsFeedPage";
import { AlertsHistoryPage } from "../alerts/AlertsHistoryPage";

export const ManageRoutes: RouteConfig[] = [
  {
    path: "/go/manage/organization",
    title: "Your Organization",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsOrg",
        PermissionFlags.Read,
        <OrganizationPage />,
      ),
  },
  {
    path: "/go/manage/accessGroups/:accessGroupId",
    title: "Edit Access Group",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsAccessGroup",
        PermissionFlags.Write,
        <AccessGroupsEditPage {...props} />,
      ),
  },
  {
    path: "/go/manage/deviceGroups/:deviceGroupId",
    title: "Edit Device Group",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDeviceGroup",
        PermissionFlags.Write,
        <EditDeviceGroupPage {...props} />,
      ),
  },
  {
    path: "/go/manage/deviceGroups",
    title: "Device Groups",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsDeviceGroup",
        PermissionFlags.Read,
        <DeviceGroupsPage />,
      ),
  },
  {
    path: "/go/manage/webhooks/:webhookId",
    title: "Edit Webhook",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsWebhooks",
        PermissionFlags.Write,
        <EditWebhookPage {...props} />,
      ),
  },
  {
    path: "/go/manage/alerts/:alertId",
    title: "Edit Alert",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsAlert",
        PermissionFlags.Write,
        <EditAlertPage {...props} />,
      ),
  },
  {
    path: "/go/manage/alerts",
    title: "Alerts Feed",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsAlert",
        PermissionFlags.Read,
        <AlertsFeedPage />,
      ),
  },
  {
    path: "/go/manage/alertsHistory",
    title: "Alerts History",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsAlert",
        PermissionFlags.Read,
        <AlertsHistoryPage />,
      ),
  },
  {
    path: "/go/manage/config/alerts",
    title: "Configure Alerts",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsAlert",
        PermissionFlags.Read,
        <AlertsPage {...props} />,
      ),
  },
  {
    path: "/go/manage/users/:userId/edit",
    title: "Edit Users",
    component: (props: RouteComponentProps<any>) => (
      <Redirect to={`/go/manage/users/${props.match.params["userId"]}`} />
    ),
  },
  {
    path: "/go/manage/users/:userId",
    title: "Edit Users",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsUser",
        PermissionFlags.Write,
        <UserEditPage {...props} />,
      ),
  },
  {
    path: "/go/manage/userManagement",
    title: "User Management",
    component: () =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsUser",
        PermissionFlags.Read,
        <UserManagementPage />,
      ),
  },
  {
    path: "/go/manage/tags/:tagId",
    title: "Edit Tag",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsTag",
        PermissionFlags.Write,
        <EditTagPage {...props} />,
      ),
  },
  {
    path: "/go/manage/tags",
    title: "Tags",
    component: (props: RouteComponentProps<any>) =>
      JwtTokenHelpers.checkPermissionForRoute(
        "permissionsTag",
        PermissionFlags.Read,
        <TagsPage {...props} />,
      ),
  },
];
