import { Component, FormEvent, ReactChild } from "react";
import { Button, ButtonType } from "../../../components/Button";
import { Dialog, DialogSize } from "../../../components/Dialog";
import { WebhooksApiService } from "../../../services/api/WebhooksApiService";
import { Webhook } from "../../../models/api/Webhook";
import { IconButton } from "../../../components/IconButton";
import { Link } from "react-router-dom";
import { PermissionFlags } from "../../../models/api/Role";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { TableFilters } from "../../../models/api/TableFilters";
import { Input, StateType } from "../../../components/inputs/Input";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import { Checkbox } from "../../../components/inputs";
import { LayoutContext, LayoutContextType } from "../../../contexts/layout/layout-context";

interface IProps {
  pageTitle?: ReactChild;
}
interface IState {
  openCreateDialog: () => void;
  closeCreateDialog: () => void;
  newWebhookName: string;
  newWebhookUrl: string;
  newWebhookIsGlobal: boolean;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
  deleteWebhookId?: string;
  validator?: any;
  readAccess: boolean;
  writeAccess: boolean;
  reloadTable: () => void;
  isFormInvalid: boolean;
  newWebhookNameError: string;
  newWebhookUrlError: string;
}
export class WebhooksPage extends Component<IProps, IState> {
  static contextType = LayoutContext;

  constructor(props: IProps) {
    super(props);

    this.state = {
      openCreateDialog: () => {},
      closeCreateDialog: () => {},
      newWebhookName: "",
      newWebhookUrl: "",
      newWebhookIsGlobal: false,
      openDeleteDialog: () => {},
      closeDeleteDialog: () => {},
      readAccess: true,
      writeAccess: true,
      reloadTable: () => {},
      isFormInvalid: true,
      newWebhookNameError: "",
      newWebhookUrlError: "",
    };

    this.handleCreateWebhook = this.handleCreateWebhook.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDeleteWebhook = this.handleDeleteWebhook.bind(this);
    this.showDeleteWebhookDialog = this.showDeleteWebhookDialog.bind(this);

    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      // Workaround to work with classes calling unmount after another page's didmount method
      setTimeout(() => {
        layoutContext.addHeaderButtons(this.newGenerateHeaderButtons());
      }, 1000);
    }

    const userRole = JwtTokenHelpers.getUserRole();
    const readAccess = (userRole.permissionsWebhooks & PermissionFlags.Read) != 0; // if true then user has Read
    const writeAccess = (userRole.permissionsWebhooks & PermissionFlags.Write) != 0; //
    this.setState({
      readAccess,
      writeAccess,
    });
  }

  componentWillUnmount(): void {
    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.clearHeaderButtons();
    }
  }

  isUrlValid(url: string) {
    const urlRegex =
      /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

    return urlRegex.test(url);
  }

  validate(field: string) {
    let errorMessage = "";

    if (field === "newWebhookName") {
      if (!this.state.newWebhookName) {
        errorMessage = "Please enter a name.";
      } else if (!this.state.newWebhookName.trim()) {
        errorMessage =
          "Only using space is not allowed. Must contain alphabetic or numeric characters.";
      }
    }

    if (field === "newWebhookUrl") {
      if (!this.state.newWebhookUrl) errorMessage = "Please enter a URL.";

      if (this.state.newWebhookUrl) {
        if (!this.isUrlValid(this.state.newWebhookUrl))
          errorMessage = `Please enter a valid URL, i.e. "http(s)://www.example.com".`;
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      [`${field}Error`]: errorMessage,
    }));

    this.setState((prevState) => ({
      ...prevState,
      isFormInvalid: !!prevState.newWebhookNameError || !!prevState.newWebhookUrlError,
    }));
  }

  componentDidUpdate(_prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (prevState.newWebhookName !== this.state.newWebhookName) {
      this.validate("newWebhookName");
    }

    if (prevState.newWebhookUrl !== this.state.newWebhookUrl) {
      this.validate("newWebhookUrl");
    }
  }

  validateFormData() {
    this.validate("newWebhookName");
    this.validate("newWebhookUrl");
  }

  handleCreateWebhook() {
    this.validateFormData();

    if (this.state.isFormInvalid) return;

    const newWebhook: Webhook = {
      name: this.state.newWebhookName,
      uri: this.state.newWebhookUrl,
      isGlobal: this.state.newWebhookIsGlobal,
      webhookDeviceCount: 0,
      webhookAccessGroupCount: 0,
    };

    new WebhooksApiService()
      .createWebhook(newWebhook)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "New webhook was successfully added",
          toastOnly: true,
        });
        this.state.closeCreateDialog();
        this.state.reloadTable();
      })
      .catch(() =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "An error occurred while creating the new webhook",
          toastOnly: true,
        }),
      );
  }

  handleInputChange(event: FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState((state) => {
      return {
        ...state,
        [name]: value,
      } as IState;
    });
  }

  showDeleteWebhookDialog(deleteWebhookId: string) {
    this.setState({ deleteWebhookId }, this.state.openDeleteDialog);
  }

  handleDeleteWebhook() {
    if (!this.state.deleteWebhookId) return;
    new WebhooksApiService()
      .deleteWebhook(this.state.deleteWebhookId)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Webhook was successfully deleted",
          toastOnly: true,
        });
        this.state.closeDeleteDialog();
        this.state.reloadTable();
      })
      .catch(() =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: "An error occurred while deleting webhook",
          toastOnly: true,
        }),
      );
  }

  newGenerateHeaderButtons(): JSX.Element {
    if (this.state.writeAccess) {
      return (
        <Button
          onClick={this.state.openCreateDialog}
          buttonType={ButtonType.Outline}
          content="Create Webhook"
          iconClass="fa fa-plus"
        />
      );
    }

    return <></>;
  }

  generateTableButtons(item: Webhook): ReactChild {
    if (this.state.writeAccess) {
      return (
        <div className="d-flex flex-row">
          <Link to={`/go/manage/webhooks/${item.id}`}>
            <IconButton isDark={false} iconClass="fa fa-pencil-alt" />
          </Link>
          <IconButton
            isDark={false}
            iconClass="fa fa-trash-alt"
            onClick={() => this.showDeleteWebhookDialog(item.id as string)}
          />
        </div>
      );
    }
    return "";
  }

  render() {
    return (
      <div className="max-width-container-xl d-flex flex-column h-100">
        <section className="content pb-4 flex-grow-1">
          <FilterTable<Webhook>
            tableId="webhooks-table"
            fetchItemsRemotely={true}
            fetchItemsFunction={new WebhooksApiService().list}
            initialTableFilters={new TableFilters("Name", true)}
            reloadFunction={(reloadTable) => this.setState({ reloadTable })}
            paginate={true}
            virtualize={true}
            columnDefinitions={[
              {
                header: "",
                notTogglable: true,
                valueFunction: (item) => this.generateTableButtons(item),
              },
              {
                header: "Name",
                valueFunction: (item) => item.name,
                sortable: true,
                databaseColumn: "Name",
              },
              {
                header: "URL",
                valueFunction: (item) => item.uri,
                sortable: true,
                databaseColumn: "Uri",
              },
              {
                header: "Global",
                valueFunction: (item) => (item.isGlobal ? "Yes" : "No"),
                sortable: true,
                databaseColumn: "IsGlobal",
              },
              {
                header: "Number of Devices",
                valueFunction: (item) => (item.webhookDeviceCount as number).toString(),
                sortable: true,
                databaseColumn: "webhookdevicecount",
              },
              {
                header: "Number of Access Groups",
                valueFunction: (item) => (item.webhookAccessGroupCount as number).toString(),
                sortable: true,
                databaseColumn: "webhookaccessgroupcount",
              },
            ]}
          />
        </section>

        <Dialog
          setOpen={(openCreateDialog) => this.setState({ openCreateDialog })}
          setClose={(closeCreateDialog) => this.setState({ closeCreateDialog })}
          onClose={() =>
            this.setState({
              newWebhookName: "",
              newWebhookUrl: "",
              newWebhookIsGlobal: false,
              newWebhookNameError: "",
              newWebhookUrlError: "",
            })
          }
          size={DialogSize.Large}
          header="Add Webhook"
          body={
            <form id="create-webhook-form">
              <div className="row">
                <div className="col-12 mt-3">
                  <Input
                    idName="new-webhook-name"
                    labelString="Name"
                    placeholder=""
                    onChange={this.handleInputChange}
                    name="newWebhookName"
                    inputValue={this.state.newWebhookName}
                    inputState={
                      this.state.newWebhookNameError ? StateType.Error : StateType.Default
                    }
                    errorMsg={this.state.newWebhookNameError}
                    isRequired
                  />
                </div>
                <div className="col-12 mt-3">
                  <Input
                    idName="new-webhook-url"
                    labelString="URL"
                    placeholder=""
                    onChange={this.handleInputChange}
                    name="newWebhookUrl"
                    inputValue={this.state.newWebhookUrl}
                    inputState={this.state.newWebhookUrlError ? StateType.Error : StateType.Default}
                    errorMsg={this.state.newWebhookUrlError}
                    isRequired
                  />
                </div>
                <div className="col-12 mt-3">
                  <Checkbox
                    labelsize="large"
                    text="Global"
                    id="new-webhook-is-global"
                    name="newWebhookIsGlobal"
                    checked={this.state.newWebhookIsGlobal}
                    onChange={() =>
                      this.setState((prevState) => ({
                        ...prevState,
                        newWebhookIsGlobal: !prevState.newWebhookIsGlobal,
                      }))
                    }
                    hint="Global webhooks are applied to messages from all devices"
                  />
                </div>
              </div>
            </form>
          }
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                buttonType={ButtonType.Transparent}
                content="Cancel"
                onClick={this.state.closeCreateDialog}
              />
              <Button
                buttonType={ButtonType.Success}
                content="Create Webhook"
                onClick={this.handleCreateWebhook}
              />
            </>
          }
        />

        <Dialog
          setOpen={(openDeleteDialog) => this.setState({ openDeleteDialog })}
          setClose={(closeDeleteDialog) => this.setState({ closeDeleteDialog })}
          onClose={() => this.setState({ deleteWebhookId: undefined })}
          size={DialogSize.Small}
          header="Delete Webhook"
          body={<p>Are you sure you want to delete this webhook?</p>}
          footer={
            <>
              <span className="flex-grow-1" />
              <Button
                buttonType={ButtonType.Transparent}
                content="Close"
                onClick={this.state.closeDeleteDialog}
              />
              <Button
                content="Delete"
                buttonType={ButtonType.Error}
                onClick={this.handleDeleteWebhook}
              />
            </>
          }
        />
      </div>
    );
  }
}
