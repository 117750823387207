import { useState, useCallback } from "react";
import axios from "axios";
import cSharpApi from "../../../../../../services/api/CSharpApi";
import {
  HistoricalData,
  HistoricalDataState,
  HistoricalDatum,
  INITIAL_HISTORIC_DATA_STATE,
} from "../../../SimpleAppTemplate";

export interface WindSpeedAndDirectionHistoricalDatum extends HistoricalDatum {
  speed: number;
  direction: number;
  speedDisplayUnit: string;
  directionDisplayUnit: string;
}

interface WindSpeedAndDirectionChartData extends HistoricalData {
  historicalData: WindSpeedAndDirectionHistoricalDatum[];
}

interface WindSpeedAndDirectionChartState extends HistoricalDataState {
  data: WindSpeedAndDirectionChartData;
}

type FetchParams = {
  duration: number;
  resolution: number;
  customEndDate?: number;
  hoursToAdd: number;

  speedName: string;
  speedFieldKey: string;
  directionName: string;
  directionFieldKey: string;

  speedDisplayName?: string;
  speedFieldName?: string;
  directionDisplayName?: string;
  directionFieldName?: string;
  speedPortNumber: number;
  directionPortNumber: number;
  orgId?: string;
};

const useHistoricalLineAndTemperature = (deviceId: string) => {
  const [fetchedData, setFetchedData] = useState<WindSpeedAndDirectionChartState>(
    INITIAL_HISTORIC_DATA_STATE,
  );

  const cancelTokenSource = axios.CancelToken.source();

  const fetchData = useCallback(
    async (params: FetchParams) => {
      setFetchedData({
        ...INITIAL_HISTORIC_DATA_STATE,
        isLoading: true,
      });

      try {
        const response = await cSharpApi.post(
          `charts/get-historical-wind-speed-and-direction/${deviceId}`,
          JSON.stringify(params),
          {
            cancelToken: cancelTokenSource.token,
          },
        );

        const data = await response.data.data;

        if (data) {
          setFetchedData({
            data,
            isLoading: false,
            hasError: false,
            errorMessage: "",
            message: response.data.message,
          });
        }
      } catch (error) {
        let errorMessage = "fetching data aborted";

        if (axios.isCancel(error)) {
          console.log("fetching data aborted");
        } else {
          errorMessage = error instanceof Error ? error.message : "Unknown error";
          console.log("An error occurred", error);
        }

        setFetchedData({
          ...INITIAL_HISTORIC_DATA_STATE,
          errorMessage,
        });
      }

      return () => cancelTokenSource.cancel();
    },
    [deviceId],
  );

  const { data, isLoading, hasError, errorMessage, message } = fetchedData;

  return { fetchData, data, isLoading, hasError, errorMessage, message };
};

export default useHistoricalLineAndTemperature;
