import { FC, useEffect, useRef } from "react";
import * as d3 from "d3";
import { Guid } from "js-guid";

type Props = {
  width: number;
  height: number;
  x: number;
  y: number;
  chargingTicks: number[];
  axisLabel: string;
  measurementSuffix: string;
  marginFromTopParentDiv: number;
  chargingScale: d3.ScaleLinear<number, number>;
  chartId: Guid;
};

const LeftAxis: FC<Props> = ({
  width,
  height,
  x,
  y,
  axisLabel,
  measurementSuffix,
  marginFromTopParentDiv,
  chargingScale,
  chartId,
}) => {
  const groupRef = useRef<SVGGElement>(null);

  const plotChart = (groupId: string) => {
    const group = d3.select(`#${groupId}`);
    group.selectAll("g").remove();
    group.selectAll("text").remove();

    const xPosition = width - 0.5;

    // Axis Tickers
    group
      .append("g")
      .attr("transform", `translate(${xPosition}, ${marginFromTopParentDiv})`)
      .call(
        d3
          .axisLeft(chargingScale)
          // .tickValues(chargingTicks)
          .ticks(5)
          .tickFormat((d) => `${d} ${measurementSuffix}`),
      )
      .attr("class", "dpp-left-axis-charging-text");

    // Axis Title
    group
      .append("text")
      .attr("transform", `translate(${xPosition}, ${marginFromTopParentDiv})`)
      .attr("x", `-${100 + marginFromTopParentDiv}`)
      .attr("y", width - 55)
      .text(axisLabel);
  };

  useEffect(() => {
    plotChart(`left-axis-dpp-group-${chartId}`);
  }, [width]);

  return (
    <svg width="100%" height="100%" x={x} y={y} style={{ overflow: "initial" }}>
      <g
        ref={groupRef}
        id={`left-axis-dpp-group-${chartId}`}
        width={width}
        height={height}
        x={x}
        y={y}
      />
    </svg>
  );
};

export default LeftAxis;
