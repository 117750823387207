import { ApiService } from "./ApiService";
import { HierarchyLevel, SubOrganization } from "../../models/api/SubOrganization";
import { Organization } from "../../models/api/Organization";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { TableFilters } from "../../models/api/TableFilters";
import { PagedResults } from "../../models/api/PagedResults";
import { Promise } from "bluebird";

export interface CreateSubOrgRequest {
  organizationName: string;
  email: string;
  hierarchyLevelId: string;
}

export class OrganizationsApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "organizations");

    this.list = this.list.bind(this);
    this.listSubOrganizations = this.listSubOrganizations.bind(this);
    this.createSubOrg = this.createSubOrg.bind(this);
  }

  getById(orgId: string): Promise<Organization> {
    return new Promise<Organization>((resolve, reject, cancel) => {
      this.get(`${orgId}`, cancel).done((result) => {
        if (result.success) {
          const d = result.data;
          resolve({
            id: d.ID,
            name: d.NAME,
            typeId: d.TYPE,
            typeName: d.TYPENAME,
            ownerId: d.OWNERID,
            ownerName: d.OWNERNAME,
            billingContactEmails: d.BILLINGCONTACTEMAILS,
            hierarchyLevelId: d.HIERARCHYLEVELID,
            hierarchyLevelName: d.HIERARCHYLEVELNAME,
            distributorId: d.DISTRIBUTORID,
            distributor: d.DISTRIBUTOR,
            resellerId: d.RESELLERID,
            reseller: d.RESELLER,
            uniqueCode: d.UNIQUECODE,
            createdAt: moment.utc(d.CREATEDAT).local(),
            totalApiKeys: d.TOTALAPIKEYS,
            totalAccessGroups: d.TOTALACCESSGROUPS,
            totalDevices: d.TOTALDEVICES,
            totalPhysicalDevices: d.TOTALPHYSICALDEVICES,
            totalUsers: d.TOTALUSERS,
            totalVirtualDevices: d.TOTALVIRTUALDEVICES,
            billingAddressLine1: d.BILLINGADDRESSLINE1,
            billingAddressLine2: d.BILLINGADDRESSLINE2,
            billingAddressCity: d.BILLINGADDRESSCITY,
            billingAddressPostalCode: d.BILLINGADDRESSPOSTALCODE,
            billingAddressCountryCode: d.BILLINGADDRESSCOUNTRYCODE,
          } as Organization);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  createSubOrg(request: CreateSubOrgRequest): Promise<boolean> {
    return new Promise<boolean>((resolve, reject, cancel) =>
      this.post(`create/subOrg`, request, cancel).done((result) => {
        if (result.success) {
          resolve(result.message);
        } else {
          console.error(result.cfcatch);
          reject(result.message);
        }
      }),
    );
  }

  isSubOrg(parentOrgId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject, cancel) =>
      this.get(`isSubOrgOf/${parentOrgId}`, cancel).done((result) => {
        if (result.success) {
          resolve(result.isSubOrg);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      }),
    );
  }

  list(tableFilters?: TableFilters): Promise<PagedResults<Organization>> {
    return new Promise<PagedResults<Organization>>((resolve, reject, cancel) => {
      this.get(
        `${tableFilters != undefined ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const orgs = result.data.map(
            (d: any) =>
              ({
                id: d.ID,
                name: d.NAME,
                ownerId: d.OWNERID,
                ownerName: d.OWNERNAME,
                typeId: d.TYPE,
                typeName: d.TYPENAME,
                hierarchyLevelId: d.HIERARCHYLEVELID,
                hierarchyLevelName: d.HIERARCHYLEVELNAME,
                distributorId: d.DISTRIBUTORID,
                distributor: d.DISTRIBUTOR,
                resellerId: d.RESELLERID,
                reseller: d.RESELLER,
                uniqueCode: d.UNIQUECODE,
                createdAt: moment.utc(d.CREATEDAT).local(),
                totalApiKeys: d.TOTALAPIKEYS,
                totalAccessGroups: d.TOTALACCESSGROUPS,
                totalDevices: d.TOTALDEVICES,
                totalPhysicalDevices: d.TOTALPHYSICALDEVICES,
                totalUsers: d.TOTALUSERS,
                totalVirtualDevices: d.TOTALVIRTUALDEVICES,
                billingAddressLine1: d.BILLINGADDRESSLINE1,
                billingAddressLine2: d.BILLINGADDRESSLINE2,
                billingAddressCity: d.BILLINGADDRESSCITY,
                billingAddressPostalCode: d.BILLINGADDRESSPOSTALCODE,
                billingAddressCountryCode: d.BILLINGADDRESSCOUNTRYCODE,
              }) as Organization,
          );

          resolve({
            items: orgs,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listMoveOptions(): Promise<{ label: string; value: string }[]> {
    return new Promise((resolve, reject, cancel) => {
      this.get("moveOptions", cancel).done((result) => {
        if (result.success) {
          resolve(result.data.map((d: any) => ({ label: d.NAME, value: d.ID })));
        } else {
          reject(result.message);
        }
      });
    });
  }

  linkedAccounts(): Promise<PagedResults<Organization>> {
    return new Promise<PagedResults<Organization>>((resolve, reject) => {
      this.get("linkedAccounts").done((result) => {
        if (result.success) {
          const orgs = result.data.map(
            (d: any) =>
              ({
                id: d.ORGANIZATIONID,
                name: d.ORGNAME,
              }) as Organization,
          );
          resolve({
            items: orgs,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listSubOrganizations(
    orgId: string,
    tableFilters?: TableFilters,
  ): Promise<PagedResults<SubOrganization>> {
    return new Promise<PagedResults<SubOrganization>>((resolve, reject, cancel) => {
      this.get(
        `${orgId}/sub${tableFilters ? "?" + tableFilters.toQueryParameterString() : ""}`,
        cancel,
      ).done((result) => {
        if (result.success) {
          const subOrgs = result.data.map(
            (d: any) =>
              ({
                orgId: d.ORGID,
                orgName: d.ORGNAME,
                uniqueCode: d.UNIQUECODE,
                primaryUserId: d.PRIMARYUSERID,
                primaryUserEmail: d.PRIMARYUSEREMAIL,
                primaryUserFullName: d.PRIMARYUSERFULLNAME,
                orgHierarchyLevel: d.HIERARCHYLEVELID as HierarchyLevel,
              }) as SubOrganization,
          );
          resolve({
            items: subOrgs,
            totalCount: result.totalCount,
          });
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  update(id: string, organization: Organization) {
    return new Promise<void>((resolve, reject, cancel) => {
      this.post(`${id}`, organization, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  deleteOrg(id: string) {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${id}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  getActiveDevicesCount(): Promise<number> {
    return new Promise<number>((resolve, reject, cancel) => {
      this.get("getActiveDevicesCount", cancel).done((result) => {
        console.log("");
        console.log("result -> ", result);
        console.log("");

        if (result.success) {
          resolve(result.count);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }
}
