import { FC, useEffect, useState } from "react";
import "../cardStyles.scss";
import { GetMissingDataDisplay } from "../CardHelper";

type Props = {
  // cardValueDisplay allows us how to display the cardValue
  cardCaption: string;
  supportQuestion: string | JSX.Element;
  iconClass: string | JSX.Element;

  iconPos?: "left" | "right";
};

const NoDataCard: FC<Props> = ({ cardCaption, supportQuestion, iconClass, iconPos }) => {
  const [chartIcon, setChartIcon] = useState<JSX.Element>(<></>);

  useEffect(() => getSettings(), []);

  function getSettings() {
    if (iconClass) {
      if (typeof iconClass == "string") {
        const _icon = <i className={iconClass} />;
        setChartIcon(_icon);
        return;
      } else {
        setChartIcon(iconClass);
      }
    }
  }

  return (
    <div className="nodata-card-container">
      {/* This is used in other Card as well*/}
      {GetMissingDataDisplay(cardCaption, supportQuestion)}

      <section
        style={{ order: `${!!iconPos && iconPos == "left" ? "-1" : ""}` }}
        className="nodata-card-icon"
      >
        {chartIcon}
      </section>
    </div>
  );
};

export default NoDataCard;
