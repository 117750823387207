import { FC, useEffect } from "react";
import WidgetGenerator from "./widgets/WidgetGenerator";
import {
  SimpleAppColumn,
  SimpleAppCurrentWidget,
  SimpleAppRow,
  SimpleAppTemplate,
  SimpleWidgetTypes,
  WidgetType,
} from "./SimpleAppTemplate";
import { DashboardDevice } from "../../../models/api/DashboardDevice";
import { useWidgetContext } from "./widgets/context/WidgetContext/WidgetContext";
import {
  CurrentAirQualityPMIndicator,
  CurrentBatteryIndicator,
  CurrentIndicator,
  CurrentRainfallIndicator,
  CurrentWaterVolumeIndicator,
} from "./widgets";
import { Guid } from "js-guid";

type Props = {
  template: SimpleAppTemplate;
  orgId?: string;
  device: DashboardDevice;
};

const TemplateRenderer: FC<Props> = ({ template, orgId, device }) => {
  const { widgets } = useWidgetContext();

  const GetCurrentIndicators = () => {
    const indicators: JSX.Element[] = [];

    if (!!widgets && widgets.length > 0) {
      widgets.forEach((widget) => {
        let indicator;

        switch (widget.type) {
          case "water-volume":
            indicator = (
              <CurrentWaterVolumeIndicator
                key={new Guid().toString()}
                device={device}
                orgId={orgId}
                measurement={widget.measurement}
              />
            );
            break;
          case "rainfall":
            indicator = (
              <CurrentRainfallIndicator
                key={new Guid().toString()}
                device={device}
                orgId={orgId}
                measurement={widget.measurement}
              />
            );
            break;
          case "air-quality-pm":
            indicator = (
              <CurrentAirQualityPMIndicator
                key={new Guid().toString()}
                device={device}
                orgId={orgId}
                measurement={widget.measurement}
              />
            );
            break;
          case "current-value":
            indicator = (
              <CurrentIndicator
                key={new Guid().toString()}
                device={device}
                orgId={orgId}
                measurement={widget.measurement}
              />
            );
            break;
          case "device-battery":
            indicator = (
              <CurrentBatteryIndicator
                key={new Guid().toString()}
                device={device}
                orgId={orgId}
                measurement={widget.measurement}
              />
            );
            break;
        }

        if (indicator) indicators.push(indicator);
      });
    }

    if (indicators.length > 0)
      return (
        <div className="dashboard-current-widget-indicators">
          <div className="text-para-small-bold"> Last 24 hours </div>
          <div className="dashboard-current-indicators"> {indicators} </div>
        </div>
      );
    else return <></>;
  };

  return (
    <>
      <GetCurrentIndicators />

      <div className="dashboard-current-widgets">
        {template.current.columns.map((column: any, index: number) => {
          const classname =
            column.label.toLowerCase() == "map"
              ? "dashboard-current-map"
              : "dashboard-current-column";

          return (
            <div key={index} className={classname}>
              <TemplateColumn
                column={column}
                device={device}
                orgId={orgId}
                type={WidgetType.current}
                key={index}
              />
            </div>
          );
        })}
      </div>

      <div className="dashboard-historic-widgets">
        {template.historic.columns.map((column: any, index: number) => {
          const classname = "dashboard-historic-column";

          return (
            <div key={index} className={classname}>
              <TemplateColumn
                column={column}
                device={device}
                orgId={orgId}
                type={WidgetType.historic}
                key={index}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

interface ITemplateColumnProps {
  column: SimpleAppColumn<SimpleWidgetTypes>;
  device: DashboardDevice;
  orgId?: string;
  type: WidgetType;
}

const TemplateColumn = ({ column, device, orgId, type }: ITemplateColumnProps) => {
  return (
    <>
      {column.rows.map((row: any, index: number) => (
        <TemplateRow row={row} device={device} orgId={orgId} type={type} key={index} />
      ))}
    </>
  );
};

interface ITemplateRowProps {
  row: SimpleAppRow<SimpleWidgetTypes>;
  device: DashboardDevice;
  orgId?: string;
  type: WidgetType;
}

const TemplateRow = ({ row, device, orgId, type }: ITemplateRowProps) => {
  const { addWidget } = useWidgetContext();

  useEffect(() => {
    if (type == WidgetType.current) {
      const rowDef = row as SimpleAppRow<SimpleAppCurrentWidget>;
      addWidget(rowDef.widget);
    }
  }, [row]);

  return (
    // Keep for now incase :has gets supported on firefox soon
    // <div className="dashboard-widget">
    //    <WidgetGenerator widgetType={type} device={device} orgId={orgId} row={row}/>
    // </div>

    <WidgetGenerator widgetType={type} device={device} orgId={orgId} row={row} />
  );
};

export default TemplateRenderer;
