import { PropsWithChildren, ReactNode } from "react";
import "../../scss/formStyles.scss";

interface IProps {
  properties: { key?: string; value?: ReactNode }[];
}

export function FormInfo(props: PropsWithChildren<IProps>) {
  return (
    <div className="form-info">
      {props.properties.length > 0 &&
        props.properties.map((p, index) => (
          <div className="form-info-item fg-color-black-dark" key={index.toString()}>
            <div className="text-label-large">
              {p?.key ?? <span className="fg-color-placeholder">No Key</span>}
            </div>
            <div className="text-para-default">
              {p?.value ?? <span className="fg-color-placeholder">No Value</span>}
            </div>
          </div>
        ))}
      {props.properties.length <= 0 && (
        <div className="form-info-item">
          <div className="text-label-large">Error</div>
          <div className="text-para-default">No Properties</div>
        </div>
      )}
    </div>
  );
}
