import axios from "axios";

export interface CSharpResponse {
  success: boolean;
  hasData?: boolean;
  errorMessage?: string;
  message?: string;
}

export interface CSharpModelListResponse<T> extends CSharpResponse {
  data: T;
  totalCount: number;
}

export interface CSharpModelResponse<T> extends CSharpResponse {
  data: T;
}

export interface FetchedData<T> {
  data: T;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
}

export const INITIAL_CSHARP_RESPONSE_STATE: CSharpResponse = {
  success: false,
  hasData: false,
  message: "",
  errorMessage: "",
};

export const INITIAL_CSHARP_MODEL_LIST_RESPONSE_STATE = {
  ...INITIAL_CSHARP_RESPONSE_STATE,
  totalCount: 0,
};

export const INITIAL_FETCHED_DATA_STATE = {
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};

export const catchError = (error: any) => {
  let errorMessage = "fetching data aborted";

  if (axios.isCancel(error)) {
    console.log("fetching data aborted");
  } else {
    errorMessage = error instanceof Error ? error.message : "Unknown error";
    console.log("An error occurred", error);
  }

  return errorMessage;
};
