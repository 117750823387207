import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import { CurrentWidgetMeasurementConfig } from "../../../SimpleAppTemplate";
import useCurrentIndicator from "../hooks/useCurrentIndicator";
import { getIndicator } from "../../WidgetHelper";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  measurement: CurrentWidgetMeasurementConfig;
};

// TODO: Informative Comment
const CurrentIndicator: FC<Props> = ({ device, orgId, measurement }) => {
  const [valueLabel, setValueLabel] = useState<string>("Current Value");
  const { fetchData, data, isLoading } = useCurrentIndicator(device.id);

  useEffect(() => {
    if (measurement) {
      // Required
      const name = measurement.name;
      const fieldKey = measurement.fieldKey;

      // Optional
      const displayName = measurement.displayName;
      const fieldName = measurement.fieldName;
      const portNumber = parseInt(measurement.port ?? "0");
      const label = measurement.label;
      if (label) setValueLabel(label);

      const hasError = !name || !fieldKey;
      if (!hasError) {
        const getData = async () => {
          await fetchData({
            name,
            fieldKey,
            displayName,
            fieldName,
            portNumber,
            orgId,
          });
        };

        getData();
      }
    }
  }, [measurement, orgId]);

  const GetCurrentIndicator = useMemo(() => {
    return getIndicator(data, valueLabel, isLoading);
  }, [data, valueLabel, isLoading]);

  return <>{GetCurrentIndicator}</>;
};

export default CurrentIndicator;
