import "./tableFilterStyles.scss";

interface IProps {
  isLoading: boolean;
}

export function TableLoading(props: IProps) {
  return (
    <>
      {/* When loading */}
      {props.isLoading && (
        <div className="tf-spinner">
          <i className="fa-light fa-spinner-third spin" />
        </div>
      )}
    </>
  );
}
