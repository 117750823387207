import { useState, useCallback } from "react";
import axios from "axios";
import cSharpApi from "../services/api/CSharpApi";

type DevicePowerPerformanceData = {
  currentData: {
    currentBattery?: number;
    dateLastMeasurement?: string;
  };
  historicalData: Array<{
    time: string;
    battery: number;
    charge: number;
    label: string;
  }>;
  hasData: boolean;
  chargeMinValue: number;
  chargeMaxValue: number;
  chargeDisplayUnit: string;
  batteryMinValue: number;
  batteryMaxValue: number;
  batteryDisplayUnit: string;
  errorMessage: string;
};

type DevicePowerPerformanceState = {
  data: DevicePowerPerformanceData;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
};

type FetchParams = {
  duration: number;
  resolution: number;
  customEndDate?: number;
  hoursToAdd: number;

  batteryName: string;
  batteryFieldKey: string;
  maximumBattery: number;
  minimumBattery: number;
  chargeName: string;
  chargeFieldKey: string;
  maximumCharge: number;
  minimumCharge: number;

  batteryDisplayName?: string;
  batteryFieldName?: string;
  chargeDisplayName?: string;
  chargeFieldName?: string;
  orgId?: string;
};

const INITIAL_STATE = {
  data: {
    currentData: {},
    historicalData: [],
    hasData: false,
    chargeMinValue: 0,
    chargeMaxValue: 0,
    chargeDisplayUnit: "",
    batteryMinValue: 0,
    batteryMaxValue: 0,
    batteryDisplayUnit: "",
    errorMessage: "",
  },
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};

const useDevicePowerPerformance = (deviceId: string) => {
  const [fetchedData, setFetchedData] = useState<DevicePowerPerformanceState>(INITIAL_STATE);

  const cancelTokenSource = axios.CancelToken.source();

  const fetchData = useCallback(
    async (params: FetchParams) => {
      setFetchedData({
        ...INITIAL_STATE,
        isLoading: true,
      });

      try {
        const response = await cSharpApi.post(
          `charts/get-device-power-performance/${deviceId}`,
          JSON.stringify(params),
          {
            cancelToken: cancelTokenSource.token,
          },
        );

        const data = await response.data.data;

        if (data) {
          setFetchedData({
            data,
            isLoading: false,
            hasError: false,
            errorMessage: "",
            message: response.data.message,
          });
        }
      } catch (error) {
        let errorMessage = "fetching data aborted";

        if (axios.isCancel(error)) {
          console.log("fetching data aborted");
        } else {
          errorMessage = error instanceof Error ? error.message : "Unknown error";
          console.log("An error occured", error);
        }

        setFetchedData({
          ...INITIAL_STATE,
          errorMessage,
        });
      }
      return () => cancelTokenSource.cancel();
    },
    [deviceId],
  );

  const { data, isLoading, hasError, errorMessage, message } = fetchedData;

  return { fetchData, data, isLoading, hasError, errorMessage, message };
};

export default useDevicePowerPerformance;
