import ConfigHelper from "./helpers/ConfigHelper";

export const cfHost: string = ConfigHelper.getColdFusionHost();
export const apiEndPoint: string = ConfigHelper.getColdFusionEndpoint();
export const apiEndPointV2: string = ConfigHelper.getDotNetEndpointV2();
export const inmarsatEndpoint: string = ConfigHelper.getInmarsatEndpoint();

export const deviceConfigEnabled: boolean = true;
export const deviceFirmwareEnabled: boolean = true;
export const newAlertUxEnabled: boolean = true;
export const debugDataEnabled: boolean = true;
export const userDefineReportEnabled: boolean = true;

/** Billing Constants **/
export const billingAddress: string[] = [
  "ModuSense Limited",
  "PO Box 12054",
  "Chartwell, Hamilton 3248",
  "Waikato, New Zealand",
];
export const billingEmail: string = "accounts@modusense.com";
export const billingPhoneNumbers: string[] = ["+1 303 381 9200"];
export const billingTaxNumber: string = "127-234-612";
export const billingBankNumber: string = "12-3492-0040490-00";

export const beneficiaryAddress: string[] = [
  "2 Melody Lane",
  "Ruakura",
  "Hamilton 3216",
  "New Zealand",
];
export const beneficiaryBankAddress: string[] = [
  "ASB Bank",
  "12 Jellicoe Street",
  "Auckland 1010",
  "New Zealand",
];
export const benificiaryBankNumber: string = "27019155-USD-35";
export const swiftCode: string = "ASBBNZ2A";

export const fusionChartsLicenseKey =
  "kJ-7B9snlC4E3A3F1H1B1C3B6E7E5F3H2I2crtE2D6D-11A-9qmzA7C8qgpC4D1I4D1B3D3C2E6D1H2B4F4A3B6C1E3gzzC1G4B1zB1E4B1oreA33A8B14cetB4A4H4gyB-33A7A3A3D6C1C4C1C3C5A2B2B-13vvF1B3EC2fbqE6D4G4i1sB8TD6B5iizH3H3B5D9A6D5D5B1G4I3H2C9B6C1f1==";

export const CURRENT_APP_STATIC_REFRESH_ID = "2022112800";

export const stripeEnabled: boolean = false;

export const stripePublicKey: string =
  "pk_test_51LOByNAPu2TzArellQIHeZHIEsKGAKaDMXMRsuZOXsRLXta7wtEtDTEnpXw5zuCeuroNdGC764KGnpwJcd8jsy8G00ZdXweX7d";
