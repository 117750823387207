import { useQuery } from "react-query";
import { AccessGroupApiService } from "../api/AccessGroupApiService";

const useAccessGroups = () => {
  return useQuery(
    ["access-groups"],
    () => {
      const service = new AccessGroupApiService();
      return service.list().then((res) => res.items);
    },
    {
      staleTime: 0,
    },
  );
};

export { useAccessGroups };
