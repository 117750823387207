import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import {
  ConfigState,
  HistoricalWidgetMeasurementConfig,
  INITIAL_CONFIG_STATE,
} from "../../../SimpleAppTemplate";
import { ChartCard } from "../../../../../../components/cards";
import { ErrorNoData } from "../../index";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import { useHistoricalChartsFilter } from "../../context/historical-charts-filter";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import useDoubleLineTemperatureMulti3 from "../hooks/useDoubleLineTemperatureMulti3";
import { AMCDoubleLineTemperatureMulti3 } from "../../../../../../components/charts";
import SupportLink from "../../../../../../helpers/SupportLink";
import { Guid } from "js-guid";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  chartLabel: string;
  measurements: HistoricalWidgetMeasurementConfig[];
};

const DoubleLineTemperatureMulti3: FC<Props> = ({ device, orgId, chartLabel, measurements }) => {
  const icons: { icon: string | JSX.Element; errorIcon: string | JSX.Element } = {
    icon: "",
    errorIcon: "fa-regular fa-chart-column",
  };
  const { duration, resolution, timeLastRefresh, customEndDate } = useHistoricalChartsFilter();
  const [lineOneLabel, setLineOneLabel] = useState<string>("Line Series");
  const [lineTwoLabel, setLineTwoLabel] = useState<string>("Line Series");
  const [temperatureLabel, setTemperatureLabel] = useState<string>("Temperature");
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const {
    fetchData,
    data: historicalChartData,
    isLoading,
    hasError,
    errorMessage,
  } = useDoubleLineTemperatureMulti3(device.id);

  useEffect(() => {
    if (!!measurements && measurements.length == 3) {
      // LineOne Data - Required
      const lineOneName = measurements[0].name;
      const lineOneFieldKey = measurements[0].fieldKey;

      // LineOne Data - Optional
      const lineOneDisplayName = measurements[0].displayName;
      const lineOneFieldName = measurements[0].fieldName;
      const lineOnePortNumber = parseInt(measurements[0].port ?? "0");
      const lineOneLabel = measurements[0].label;

      // LineTwo Data - Required
      const lineTwoName = measurements[1].name;
      const lineTwoFieldKey = measurements[1].fieldKey;

      // LineTwo Data - Optional
      const lineTwoDisplayName = measurements[1].displayName;
      const lineTwoFieldName = measurements[1].fieldName;
      const lineTwoPortNumber = parseInt(measurements[1].port ?? "0");
      const lineTwoLabel = measurements[1].label;

      // Temperature Data - Required
      const temperatureName = measurements[2].name;
      const temperatureFieldKey = measurements[2].fieldKey;

      // Temperature Data - Optional
      const temperatureDisplayName = measurements[2].displayName;
      const temperatureFieldName = measurements[2].fieldName;
      const temperaturePortNumber = parseInt(measurements[2].port ?? "0");
      const temperatureLabel = measurements[2].label;

      const hasConfigError =
        !lineOneName ||
        !lineTwoName ||
        !temperatureName ||
        !lineOneFieldKey ||
        !lineTwoFieldKey ||
        !temperatureFieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Measurement Name, Field, and/or FieldKey configured for this widget",
        });
      }

      if (lineOneLabel) setLineOneLabel(lineOneLabel);
      if (lineTwoLabel) setLineTwoLabel(lineTwoLabel);
      if (temperatureLabel) setTemperatureLabel(temperatureLabel);

      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            duration,
            resolution,
            customEndDate,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            lineOneName,
            lineOneFieldKey,
            lineTwoName,
            lineTwoFieldKey,
            temperatureName,
            temperatureFieldKey,
            lineOneDisplayName,
            lineOneFieldName,
            lineOnePortNumber,
            lineTwoDisplayName,
            lineTwoFieldName,
            lineTwoPortNumber,
            temperatureDisplayName,
            temperatureFieldName,
            temperaturePortNumber,
            orgId,
          });
        };

        getData();
      }
    } else {
      setConfigError({
        hasConfigError: true,
        errorMsg: "We need 3 Measurement Configurations for this widget",
      });

      if (measurements.length > 3) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "Only 3 Measurement Configurations is needed for this widget",
        });
      }
    }
  }, [timeLastRefresh, orgId]);

  const GetDisplayChart = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="500px" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (historicalChartData.hasData) {
      return (
        <AMCDoubleLineTemperatureMulti3
          data={historicalChartData.historicalData}
          lineOneLabel={lineOneLabel}
          lineTwoLabel={lineTwoLabel}
          temperatureLabel={temperatureLabel}
          chartId={new Guid().toString()}
        />
      );
    } else {
      if (historicalChartData.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${historicalChartData.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return (
        <ErrorNoData
          cardCaption={`No data for ${device.name} in this duration.`}
          supportQuestion={
            <p>
              Expecting data?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }
  }, [historicalChartData.historicalData?.length, configError]);

  return (
    <>
      <ChartCard
        chartTitle={chartLabel == "" ? "Line x Line x Temperature" : chartLabel}
        showFilters={true}
        chart={<>{GetDisplayChart}</>}
      />
    </>
  );
};

export default DoubleLineTemperatureMulti3;
