import { Component, ReactNode } from "react";
import DateHelpers from "../../../helpers/DateHelpers";
import { EventLog } from "../../../models/api/EventLog";
import { EventLogApiService } from "../../../services/api/EventLogApiService";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../../models/api/Role";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import { LayoutContext, LayoutContextType } from "../../../contexts/layout/layout-context";

interface IProps {
  pageTitle?: ReactNode;
}
interface IState {
  eventLogs: EventLog[];
  readAccess: boolean;
  writeAccess: boolean;
}
export class EventLogPage extends Component<IProps, IState> {
  static contextType = LayoutContext;
  constructor(props: IProps) {
    super(props);
    this.state = {
      eventLogs: [],
      readAccess: false,
      writeAccess: false,
    };
  }

  componentDidMount() {
    const userRole = JwtTokenHelpers.getUserRole();
    const readAccess = (userRole.permissionsEventLog & PermissionFlags.Read) != 0; // if true then user has Read
    const writeAccess = (userRole.permissionsEventLog & PermissionFlags.Write) != 0; //

    this.setState({ readAccess, writeAccess });

    new EventLogApiService().list().then((res) => {
      this.setState({ eventLogs: res });
    });

    const layoutContext = this.context as LayoutContextType;

    if (layoutContext) {
      layoutContext.removeAllExtraInfos();
      layoutContext.clearHeaderButtons();
    }
  }

  render() {
    return (
      <div className="max-width-container-xl d-flex flex-column h-100">
        {this.state.readAccess && (
          <section className="content pb-4 flex-grow-1">
            <FilterTable<EventLog>
              tableId="event-logs-table"
              items={this.state.eventLogs}
              textFilterFunction={(item, filterValue) =>
                (item.title ?? "").toString().toUpperCase().indexOf(filterValue.toUpperCase()) >= 0
              }
              paginate={true}
              virtualize={true}
              columnDefinitions={[
                {
                  header: "Title",
                  sortable: true,
                  valueFunction: (item) => (item.title ? item.title : ""),
                },
                {
                  header: "Description",
                  sortable: true,
                  valueFunction: (item) => (item.description ? item.description : ""),
                },
                {
                  header: "Date Created",
                  sortable: true,
                  valueFunction: (item) =>
                    item.createdAt && item.createdAt.isValid()
                      ? DateHelpers.formatMoment(item.createdAt)
                      : "",
                },
              ]}
            />
          </section>
        )}
      </div>
    );
  }
}
