import { Component } from "react";
import { User } from "../../../models/api/User";
import { Button, ButtonType } from "../../../components/Button";
import { UsersApiService } from "../../../services/api/UsersApiService";
import { Input, InputType, StateType } from "../../../components/inputs/Input";
import { FormGroup } from "../../../components/forms/FormGroup";

interface IProps {
  user: User;
}

interface IState {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  isFormInvalid: boolean;
  currentPasswordError: string;
  newPasswordError: string;
  confirmNewPasswordError: string;
  isInitialState: boolean;
}

const InitialState = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
  isFormInvalid: true,
  currentPasswordError: "",
  newPasswordError: "",
  confirmNewPasswordError: "",
  isInitialState: true,
};

export class UserPasswordChange extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    // this.state = {
    //     currentPassword:"",
    //     newPassword:"",
    //     confirmNewPassword:"",
    //     isFormInvalid: true,
    //     currentPasswordError: "",
    //     newPasswordError: "",
    //     confirmNewPasswordError: ""
    // };
    this.state = InitialState;

    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    // let passwordValidator = $('#change-password-form').validate({
    //     errorClass: "error",
    //     errorPlacement: function(error, element) {
    //         $(element).closest('div.form-group').append(error);
    //     },
    //     rules:	{
    //         currentPassword : {
    //             required: true,
    //             notEqualTo : "#newPasswordInput"
    //         },
    //         newPassword : {
    //             required: true,
    //             minlength : 8
    //         },
    //         confirmNewPassword : {
    //             required: true,
    //             minlength : 8,
    //             equalTo : "#newPasswordInput"
    //         }
    //     },
    //     messages: {
    //         currentPassword : {
    //             notEqualTo: "New password cannot be the same as current password.",
    //         },
    //         newPassword: {
    //             minlength: "New password must be at least 8 characters long.",
    //         },
    //         confirmNewPassword: {
    //             minlength: "New password must be at least 8 characters long.",
    //             equalTo: "Passwords do not match."
    //         }
    //     },
    //     highlight: function(element) {
    //         $(element).parent('div').addClass('has-error');
    //     },
    //     unhighlight: function(element) {
    //         $(element).parent('div').removeClass('has-error');
    //     }
    // });
    // this.setState(state => { return {
    //     validator: passwordValidator,
    // }});
  }

  validate(field: string) {
    let errorMessage = "";

    if (field === "currentPassword") {
      if (this.state.currentPassword === this.state.newPassword)
        errorMessage = "New password cannot be the same as current password.";
    }

    if (field === "newPassword") {
      // check if password string contains whitespace
      if (this.state.newPassword.match(/\s+/g)) {
        errorMessage = "Space/s are not permitted.";
      } else {
        // check password string have at least 8 characters. spacing not counted
        if (this.state.newPassword.replace(/\s+/g, "").length < 8) {
          errorMessage = "New password must be at least 8 characters long.";
        }
      }
    }

    if (field === "confirmNewPassword") {
      if (this.state.confirmNewPassword !== this.state.newPassword) {
        errorMessage = "Passwords do not match.";
      } else {
        if (this.state.newPassword.match(/\s+/g)) {
          errorMessage = "Space/s are not permitted.";
        }
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      [`${field}Error`]: errorMessage,
    }));

    this.setState((prevState) => ({
      ...prevState,
      isFormInvalid:
        !!prevState.currentPasswordError ||
        !!prevState.newPasswordError ||
        !!prevState.confirmNewPasswordError,
    }));
  }

  componentDidUpdate(_prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (!this.state.isInitialState && prevState.currentPassword !== this.state.currentPassword) {
      this.validate("currentPassword");
    }

    if (!this.state.isInitialState && prevState.newPassword !== this.state.newPassword) {
      //console.log("componentDidUpdate - new pass");
      this.validate("newPassword");
    }

    if (
      !this.state.isInitialState &&
      prevState.confirmNewPassword !== this.state.confirmNewPassword
    ) {
      this.validate("confirmNewPassword");
    }
  }

  changePassword() {
    this.validateFormData();

    if (this.state.isFormInvalid) return;

    const passwordData = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      confirmNewPassword: this.state.confirmNewPassword,
    };

    new UsersApiService()
      .updatePassword(this.props.user.id ?? "", passwordData)
      .then(() => {
        this.setState(InitialState);
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "User password was successfully updated",
          toastOnly: true,
        });
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );
  }

  validateFormData() {
    this.validate("currentPassword");
    this.validate("newPassword");
    this.validate("confirmNewPassword");
  }

  render() {
    return (
      <FormGroup title="Change Password">
        <form id="change-password-form" action="javascript:void(0);">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Input
                idName="currentPasswordInput"
                labelString="Current Password"
                placeholder=""
                onChange={(e) => {
                  this.setState({
                    currentPassword: e.target.value,
                    isInitialState: false,
                  });
                }}
                name="currentPassword"
                inputValue={this.state.currentPassword}
                inputType={InputType.Password}
                inputState={this.state.currentPasswordError ? StateType.Error : StateType.Default}
                errorMsg={this.state.currentPasswordError}
                isRequired
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Input
                idName="newPasswordInput"
                labelString="New Password"
                placeholder=""
                onChange={(e) => {
                  this.setState({
                    newPassword: e.target.value,
                    isInitialState: false,
                  });
                }}
                name="newPassword"
                inputValue={this.state.newPassword}
                inputType={InputType.Password}
                inputState={this.state.newPasswordError ? StateType.Error : StateType.Default}
                errorMsg={this.state.newPasswordError}
                isRequired
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Input
                idName="confirmNewPasswordInput"
                labelString="Confirm New Password"
                placeholder=""
                onChange={(e) => {
                  this.setState({
                    confirmNewPassword: e.target.value,
                    isInitialState: false,
                  });
                }}
                name="confirmNewPassword"
                inputValue={this.state.confirmNewPassword}
                inputType={InputType.Password}
                inputState={
                  this.state.confirmNewPasswordError ? StateType.Error : StateType.Default
                }
                errorMsg={this.state.confirmNewPasswordError}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex flex-row mt-3">
            <span className="flex-grow-0 flex-md-grow-1"></span>
            <Button
              buttonType={ButtonType.Success}
              disabled={
                !!this.state.currentPasswordError ||
                !!this.state.newPasswordError ||
                !!this.state.confirmNewPasswordError
              }
              content="Change Password"
              onClick={() => this.changePassword()}
            />
          </div>
        </form>
      </FormGroup>
    );
  }
}
