import { FC, useEffect, useMemo, useState } from "react";
import "./dashboardStyles.scss";
import TemplateRenderer from "./components/TemplateRenderer";
import { useDashboardDevice } from "../../services/context/DashboardApiServiceContext";
import DeviceAlertHistoryList from "./components/DeviceAlertHistoryList";
import { SimpleAppTemplate } from "./components/SimpleAppTemplate";
import { LoadingSkeleton } from "../../components/LoadingSkeleton";
import { ErrorNoData } from "./components/widgets";
import SupportLink from "../../helpers/SupportLink";
import { getWidgetIcons } from "./components/widgets/WidgetHelper";
import { WidgetContextProvider } from "./components/widgets/context/WidgetContext/WidgetContext";
import { AlertApiService } from "../../services/api/AlertApiService";
import { DeviceAlertHistory } from "../../models/api/DeviceAlertHistory";

type SimpleAppProps = {
  deviceId: string;
  orgId?: string;
  template: SimpleAppTemplate;
};

type State = {
  deviceAlertHistory: DeviceAlertHistory[];
  isLoading: boolean;
  template: SimpleAppTemplate | undefined;
};

const SimpleApp: FC<SimpleAppProps> = ({ deviceId, orgId, template }) => {
  const deviceQuery = useDashboardDevice(deviceId);
  const [state, setState] = useState<State>({
    deviceAlertHistory: [],
    isLoading: false,
    template: undefined,
  });

  useEffect(() => {
    if (template) setState((prevState) => ({ ...prevState, template }));
  }, [template]);

  const GetWidgets = useMemo(() => {
    if (deviceQuery.isError) {
      return (
        <ErrorNoData
          cardCaption={`${deviceQuery.error}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={getWidgetIcons("error").icon}
          iconPos="left"
        />
      );
    }

    if (deviceQuery.isSuccess && deviceQuery.data && state.template) {
      return (
        <WidgetContextProvider>
          <TemplateRenderer template={state.template} orgId={orgId} device={deviceQuery.data!} />
        </WidgetContextProvider>
      );
    }

    return <LoadingSkeleton width="100%" height="100%" />;
  }, [deviceQuery, state.template]);

  useEffect(() => {
    listDeviceAlertHistory();
  }, [deviceId]);

  async function listDeviceAlertHistory() {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const deviceAlertHistory = await new AlertApiService().listDeviceAlertHistory({
      deviceId,
      limit: 0,
      offset: 0,
    });

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      deviceAlertHistory: deviceAlertHistory,
    }));
  }

  return (
    <section className="page-structure-content">
      <div className="page-content-container">
        <div className="dashboard-container">
          <>{GetWidgets}</>

          <div className="chart-card-container">
            <div className="chart-card-title">Alert History</div>
            <DeviceAlertHistoryList deviceAlertHistory={state.deviceAlertHistory} />
          </div>
        </div>
      </div>
    </section>
  );
};

export { SimpleApp };
