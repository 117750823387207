import { FC } from "react";
import { BellIcon } from "../../../createdIcons";

type Props = Record<string, never>;

const NoAlertNotification: FC<Props> = () => {
  return (
    <div className="no-alert-notification-container">
      <div className="no-alert-icon">
        {" "}
        <BellIcon />{" "}
      </div>
      <div className="no-alert-wordings">
        <div className="no-alert-notif"> No Alerts </div>
        <div className="no-alert-msg"> We'll notify you when there is something new </div>
      </div>
    </div>
  );
};

export default NoAlertNotification;
