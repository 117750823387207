import { useState, useCallback } from "react";
import axios from "axios";
import cSharpApi from "../../../../../../services/api/CSharpApi";
import { CurrentDataState, INITIAL_CURRENT_DATA_STATE } from "../../../SimpleAppTemplate";

type FetchParams = {
  name: string;
  fieldKey: string;
  hoursToAdd: number;

  displayName?: string;
  fieldName?: string;
  portNumber: number;
  orgId?: string;
};

const useCurrentValue = (deviceId: string) => {
  const [fetchedData, setFetchedData] = useState<CurrentDataState>(INITIAL_CURRENT_DATA_STATE);

  const cancelTokenSource = axios.CancelToken.source();

  const fetchData = useCallback(
    async (params: FetchParams) => {
      setFetchedData({
        ...INITIAL_CURRENT_DATA_STATE,
        isLoading: true,
      });

      try {
        const response = await cSharpApi.post(
          `charts/get-current-value/${deviceId}`,
          JSON.stringify(params),
          {
            cancelToken: cancelTokenSource.token,
          },
        );

        const data = await response.data.data;

        if (data) {
          setFetchedData({
            data,
            isLoading: false,
            hasError: false,
            errorMessage: "",
            message: response.data.message,
          });
        }
      } catch (error) {
        let errorMessage = "fetching data aborted";

        if (axios.isCancel(error)) {
          console.log("fetching data aborted");
        } else {
          errorMessage = error instanceof Error ? error.message : "Unknown error";
          console.log("An error occurred", error);
        }

        setFetchedData({
          ...INITIAL_CURRENT_DATA_STATE,
          errorMessage,
        });
      }
      return () => cancelTokenSource.cancel();
    },
    [deviceId],
  );

  const { data, isLoading, hasError, errorMessage, message } = fetchedData;

  return { fetchData, data, isLoading, hasError, errorMessage, message };
};

export default useCurrentValue;
