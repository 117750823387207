import { Component, ReactChild } from "react";
import { Button, ButtonType } from "../../../../components/Button";
import { StylesConfig } from "react-select";
import { Guid } from "js-guid";
import { DeviceType } from "../../../../models/api/DeviceType";
import { Device } from "../../../../models/api/Device";
import { Measurement } from "../../../../models/api/Measurement";
import { MeasurementField } from "../../../../models/api/MeasurementField";
import MeasurementHelpers from "../../../../helpers/MeasurementHelpers";
import { HintIcon } from "../../../../components/HintIcon";
import { IconButton } from "../../../../components/IconButton";
import { AlertRule, AlertRuleCondition } from "../../../../models/api/AlertRule";
import { UnitPreference } from "../../../../models/api/UnitPreference";
import nerdamer from "nerdamer";
import "nerdamer/Algebra.js";
import "nerdamer/Calculus.js";
import "nerdamer/Solve.js";
import { FormWrapper } from "../../../../components/forms/FormWrapper";
import { FormGroup } from "../../../../components/forms/FormGroup";
import { ReactSelect } from "../../../../components/inputs/ReactSelect";
import { Input, InputType } from "../../../../components/inputs/Input";
import "../../../../scss/alertStyles.scss";
import { Tag } from "../../../../models/api/Tag";
import { PrioritySelect } from "../../../../components/layout/components/alertNotifications/AlertNotificationHelper";

interface IProps {
  alertRules: AlertRule[];
  devices: Device[];
  deviceTypes: DeviceType[];
  measurements: Measurement[];
  unitPreferences: UnitPreference[];
  tags: Tag[];
  onAlertRulesChange: (alertRules: AlertRule[]) => void;
}

interface IState {}

export class AlertRulesTable extends Component<IProps, IState> {
  readonly autoWidthStyle: StylesConfig<any, any> = {
    placeholder: (provided: any) => ({
      ...provided,
      position: "static",
      transform: "none",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      position: "static",
      transform: "none",
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "max-content",
      minWidth: "100%",
    }),
  };

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.addRule = this.addRule.bind(this);
    this.generateErrorMessages = this.generateErrorMessages.bind(this);
  }

  addRule() {
    const newRule: AlertRule = {
      id: new Guid().toString(),
      selectionCriteria: "",
      deviceIds: [],
      tagIds: [],
      deviceTypeIds: [],
      conditions: [
        {
          id: new Guid().toString(),
          measurementId: "",
          measurementFieldId: "",
          operator: "",
          value: "",
          isNew: true,
        },
      ],
      message: undefined,
      isNew: true,
      sortOrder: this.props.alertRules.length,
    };
    const rules = [...this.props.alertRules, newRule];
    this.props.onAlertRulesChange(rules);
  }

  addConditionToRule(alertRule: AlertRule) {
    if (!alertRule.conditions) alertRule.conditions = [];
    alertRule.conditions.push({
      id: new Guid().toString(),
      measurementId: "",
      measurementFieldId: "",
      operator: "",
      value: "",
      isNew: true,
    });
    this.props.onAlertRulesChange(this.props.alertRules);
  }

  removeRule(alertRule: AlertRule) {
    let alertRules = [...this.props.alertRules];
    alertRules = alertRules.filter((ar) => ar.id != alertRule.id);
    this.props.onAlertRulesChange(alertRules);
  }

  removeConditionFromRule(alertRule: AlertRule, index: number) {
    if (!alertRule.conditions) return;
    alertRule.conditions = alertRule.conditions.filter((_c, i) => i != index);
    this.props.onAlertRulesChange(this.props.alertRules);
  }

  addMessageToRule(alertRule: AlertRule) {
    alertRule.message = "";
    this.props.onAlertRulesChange(this.props.alertRules);
  }

  updateAlertRule(alertRuleId: string, update: (alertRule: AlertRule) => AlertRule) {
    const alertRules = [...this.props.alertRules];
    const alertRuleIndex = alertRules.findIndex((ar) => ar.id == alertRuleId);
    alertRules[alertRuleIndex] = update(alertRules[alertRuleIndex]);
    this.props.onAlertRulesChange(alertRules);
  }

  updateAlertRuleCondition(
    alertRuleId: string,
    alertRuleConditionId: string,
    update: (alertRuleCondition: AlertRuleCondition) => AlertRuleCondition,
  ) {
    const alertRules = [...this.props.alertRules];
    const alertRuleIndex = alertRules.findIndex((ar) => ar.id == alertRuleId);
    const alertRule = alertRules[alertRuleIndex];
    if (!alertRule.conditions) return;
    const alertRuleConditionIndex = alertRule.conditions.findIndex(
      (arc) => arc.id == alertRuleConditionId,
    );
    alertRule.conditions[alertRuleConditionIndex] = update(
      alertRule.conditions[alertRuleConditionIndex],
    );
    alertRules[alertRuleIndex] = alertRule;

    this.props.onAlertRulesChange(alertRules);
  }

  getMeasurement(condition: AlertRuleCondition): Measurement | undefined {
    return this.props.measurements.find((m) => m.id == condition.measurementId);
  }

  getMeasurementField(condition: AlertRuleCondition): MeasurementField | undefined {
    return this.getMeasurement(condition)?.fields?.find(
      (f) => f.id == condition.measurementFieldId,
    );
  }

  generateDeviceOptions(): { label: ReactChild; value: string }[] {
    return this.props.devices.map((d) => {
      const deviceType = this.props.deviceTypes.find((dt) => dt.id == d.deviceTypeId);
      if (!deviceType) {
        return {
          label: (
            <div className="d-flex flex-row align-items-center text-danger">
              <span>{d.name ?? d.serialNumber ?? d.shortId ?? "No name"}</span>
              <span className="flex-grow-1 mr-2" />
              <HintIcon tooltipText="Device has no device type, please contact support" />
            </div>
          ),
          value: d.id as string,
        };
      } else if (deviceType.measurementIds.length <= 0) {
        return {
          label: (
            <div className="d-flex flex-row align-items-center text-danger">
              <span>{d.name ?? d.serialNumber ?? d.shortId ?? "No name"}</span>
              <span className="flex-grow-1 mr-2" />
              <HintIcon tooltipText="Device has no measurements, please contact support" />
            </div>
          ),
          value: d.id as string,
        };
      } else {
        return {
          label: d.name ?? d.serialNumber ?? d.shortId ?? "No name",
          value: d.id as string,
        };
      }
    });
  }

  generateTagOptions(): { label: ReactChild; value: string }[] {
    return this.props.tags.map((t) => {
      return {
        label: t.name ?? "No name",
        value: t.id ?? "",
      };
    });
  }

  generateDeviceTypeOptions(): { label: ReactChild; value: string }[] {
    const validDeviceTypeIds = this.props.devices.map((d) => d.deviceTypeId);
    return this.props.deviceTypes
      .filter((dt) => dt.id && validDeviceTypeIds.includes(dt.id))
      .map((dt) => ({
        label:
          dt.measurementIds.length > 0 ? (
            (dt.name as string)
          ) : (
            <div className="d-flex flex-row align-items-center text-danger">
              <span>{dt.name}</span>
              <span className="flex-grow-1 mr-2" />
              <HintIcon tooltipText="Device type has no measurements, please contact support" />
            </div>
          ),
        value: dt.id as string,
      }));
  }

  generateValidMeasurementOptions(rule: AlertRule): { label: ReactChild; value: string }[] {
    const relevantDeviceTypeIds: string[] = [
      ...rule.deviceTypeIds,
      ...this.props.devices.filter((d) => rule.deviceIds.includes(d.id)).map((d) => d.deviceTypeId),
      ...this.props.devices
        .filter((d) => d.tagIds?.some((tagId) => rule.tagIds.includes(tagId)))
        .map((d) => d.deviceTypeId),
    ];
    const relevantDeviceTypes = this.props.deviceTypes.filter(
      (dt) => dt.id && relevantDeviceTypeIds.includes(dt.id),
    );
    const relevantMeasurementIds = relevantDeviceTypes.flatMap((dt) => dt.measurementIds);

    return this.props.measurements
      .filter(
        (m) => relevantMeasurementIds.includes(m.id as string) && (m.fieldCount as number) > 0,
      )
      .map((m) => ({
        label: MeasurementHelpers.generateMeasurementLabel(m, this.props.unitPreferences),
        value: m.id as string,
      }));
  }

  generateMeasurementFieldOptions(
    condition: AlertRuleCondition,
  ): { label: ReactChild; value: string }[] {
    const measurement = this.getMeasurement(condition);
    return (
      measurement?.fields?.map((f) => ({
        label: MeasurementHelpers.generateFieldLabel(f, this.props.unitPreferences),
        value: f.id as string,
      })) ?? []
    );
  }

  generateOperatorSection(rule: AlertRule, condition: AlertRuleCondition): ReactChild {
    const field = this.getMeasurementField(condition);
    if (!field) return "";
    const operatorOptions = [
      { label: "Equal To", value: "=" },
      { label: "Not Equal To", value: "!=" },
      { label: "Greater Than", value: ">" },
      { label: "Greater Than Or Equal To", value: ">=" },
      { label: "Less Than", value: "<" },
      { label: "Less Than Or Equal To", value: "<=" },
    ];
    switch (field.dataType) {
      case "float":
      case "integer":
        return (
          <>
            <span className="mr-2">is</span>
            <ReactSelect
              idName={new Guid().toString()}
              options={operatorOptions}
              inputValue={condition.operator}
              onChange={(option: any) =>
                this.updateAlertRuleCondition(rule.id, condition.id, (arc) => {
                  arc.operator = option?.value ?? "";
                  return arc;
                })
              }
              placeholder="Select operator"
            />
          </>
        );
      case "string":
        return <span className="mr-2">equals</span>;
      case "boolean":
        return <span className="mr-2">is</span>;
    }
  }

  generateValueSection(rule: AlertRule, condition: AlertRuleCondition): ReactChild {
    const field = this.getMeasurementField(condition);

    if (!field) return "";
    const displayUnitId = MeasurementHelpers.getPreferedDisplayUnitId(
      this.props.unitPreferences,
      field,
    );
    const displayUnit = field.displayUnits.find((du) => du.id == displayUnitId);

    let value: string | undefined = condition.value;
    if (value === "") {
      value = undefined;
    } else if (displayUnit) {
      const eq = nerdamer(`y=${displayUnit.formula}`, { x: value });
      const solution = eq.solveFor("y");
      // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
      value = (solution[0] ?? solution).toDecimal();
    }

    switch (field.dataType) {
      case "float":
        return (
          <div className="mr-2">
            <div className="input-group">
              <Input
                idName=""
                labelString=""
                inputValue={value}
                inputType={InputType.Number}
                onChange={(event) =>
                  this.updateAlertRuleCondition(rule.id, condition.id, (arc) => {
                    let value = event.currentTarget.value;
                    if (displayUnit) {
                      const eq = nerdamer(`y=${displayUnit.formula}`, {
                        y: value,
                      });
                      const solution = eq.solveFor("x");
                      // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
                      value = (solution[0] ?? solution).toDecimal();
                    }
                    arc.value = value;
                    return arc;
                  })
                }
                placeholder="Enter value"
              />
              {displayUnit && (
                <div className="input-group-append input-group-append-edit-alert">
                  <span className="input-group-text">{displayUnit.displayUnit}</span>
                </div>
              )}
            </div>
          </div>
        );
      case "integer":
        if (displayUnit?.decimalPlaces != 0) {
          return (
            <div className="mr-2">
              <div className="input-group">
                <Input
                  idName=""
                  labelString=""
                  inputValue={value}
                  inputType={InputType.Number}
                  onChange={(event) =>
                    this.updateAlertRuleCondition(rule.id, condition.id, (arc) => {
                      let value = event.currentTarget.value;
                      if (displayUnit) {
                        const eq = nerdamer(`y=${displayUnit.formula}`, {
                          y: value,
                        });
                        const solution = eq.solveFor("x");
                        // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
                        value = (solution[0] ?? solution).toDecimal();
                      }
                      arc.value = value;
                      return arc;
                    })
                  }
                  placeholder="Enter value"
                />

                {displayUnit && (
                  <div className="input-group-append input-group-append-edit-alert">
                    <span className="input-group-text">{displayUnit.displayUnit}</span>
                  </div>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="mr-2">
              <div className="input-group">
                <Input
                  idName=""
                  labelString=""
                  inputValue={value ? Math.round(parseFloat(value)).toString() : undefined}
                  inputType={InputType.Number}
                  onChange={(event) =>
                    this.updateAlertRuleCondition(rule.id, condition.id, (arc) => {
                      let value = event.currentTarget.value;
                      if (displayUnit) {
                        const eq = nerdamer(`y=${displayUnit.formula}`, {
                          y: value,
                        });
                        const solution = eq.solveFor("x");
                        // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
                        value = (solution[0] ?? solution).toDecimal();
                      }
                      arc.value = value;
                      return arc;
                    })
                  }
                  placeholder="Enter value"
                />
                {/* <input
                  className="form-control"
                  value={Math.round(parseFloat(value))}
                  type="number"
                  step={1}
                  onChange={(event) =>
                    this.updateAlertRuleCondition(
                      rule.id,
                      condition.id,
                      (arc) => {
                        let value = event.currentTarget.value;
                        if (displayUnit) {
                          var eq = nerdamer(`y=${displayUnit.formula}`, {
                            y: value,
                          });
                          var solution = eq.solveFor("x");
                          // @ts-expect-error Weird quirk with library, seems to return either a single expression or an array
                          value = (solution[0] ?? solution).toDecimal();
                        }
                        arc.value = value;
                        return arc;
                      }
                    )
                  }
                  placeholder="Enter value"
                /> */}
                {displayUnit && (
                  <div className="input-group-append input-group-append-edit-alert">
                    <span className="input-group-text">{displayUnit.displayUnit}</span>
                  </div>
                )}
              </div>
            </div>
          );
        }
      case "boolean":
        return (
          <div className="flex-grow-1">
            <ReactSelect
              idName={new Guid().toString()}
              options={[
                { label: "True", value: "true" },
                { label: "False", value: "false" },
              ]}
              inputValue={condition.value}
              onChange={(option: any) =>
                this.updateAlertRuleCondition(rule.id, condition.id, (arc) => {
                  arc.value = option?.value ?? "";
                  return arc;
                })
              }
              placeholder="Enter value"
            />
          </div>
        );
      case "string":
        return (
          <div className="mr-2">
            <div className="input-group">
              <Input
                idName=""
                labelString=""
                placeholder=""
                inputValue={condition.value}
                inputType={InputType.Text}
                onChange={(e) =>
                  this.updateAlertRuleCondition(rule.id, condition.id, (arc) => {
                    arc.value = e.currentTarget.value;
                    return arc;
                  })
                }
              />

              {displayUnit && (
                <div className="input-group-append input-group-append-edit-alert">
                  <span className="input-group-text">{displayUnit.displayUnit}</span>
                </div>
              )}
            </div>
          </div>
        );
    }
  }

  showRuleDotDotDots(ar: AlertRule): boolean {
    return (
      ar.selectionCriteria == "" ||
      (ar.selectionCriteria == "devices" && ar.deviceIds.length <= 0) ||
      (ar.selectionCriteria == "deviceTypes" && ar.deviceTypeIds.length <= 0)
    );
  }

  showConditionDotDotDots(arc: AlertRuleCondition): boolean {
    return (
      arc.measurementId == "" ||
      arc.measurementFieldId == "" ||
      arc.operator == "" ||
      arc.value == ""
    );
  }

  generateErrorMessages(ar: AlertRule): string[] {
    const results: string[] = [];
    const allMeasurementIds = (ar.conditions ?? []).map((arc) => arc.measurementId);

    const relevantDeviceTypeIds = this.props.deviceTypes.filter((dt) =>
      ar.deviceTypeIds.includes(dt.id as string),
    );

    for (const dt of relevantDeviceTypeIds) {
      const missingMeasurementIds = allMeasurementIds.filter(
        (mId) => !dt.measurementIds.includes(mId),
      );
      for (const mmId of missingMeasurementIds) {
        const measurement = this.props.measurements.find((m) => m.id == mmId);
        if (!measurement) continue;
        const error = `Device type "${dt.name}" does not include the measurement "${measurement.displayName}".
                    Alert will never trigger for this device type.`;
        if (results.includes(error)) continue;
        results.push(error);
      }
    }

    const relevantDeviceIds = this.props.devices.filter((d) =>
      ar.deviceIds.includes(d.id as string),
    );

    for (const d of relevantDeviceIds) {
      const dt = this.props.deviceTypes.find((dt) => dt.id == d.deviceTypeId);
      const missingMeasurementIds = allMeasurementIds.filter(
        (mId) => dt && !dt.measurementIds.includes(mId),
      );
      for (const mmId of missingMeasurementIds) {
        const measurement = this.props.measurements.find((m) => m.id == mmId);
        if (!measurement) continue;
        const error = `Device "${d.name}" does not include the measurement "${measurement.displayName}".
                    Alert will never trigger for this device.`;
        if (results.includes(error)) continue;
        results.push(error);
      }
    }

    return results;
  }

  render() {
    const selectionCriteriaOptions = [
      { label: <>Devices</>, value: "devices" },
      { label: "Device Types", value: "deviceTypes" },
      { label: "Tags", value: "tags" },
    ];
    const deviceOptions = this.generateDeviceOptions();
    const tagOptions = this.generateTagOptions();
    const deviceTypeOptions = this.generateDeviceTypeOptions();

    return (
      <>
        <FormWrapper>
          <FormGroup
            title={
              <>
                Rules
                <span className="ml-2"></span>
                <span className="text-md">
                  <HintIcon
                    tooltipText={
                      "Rules are applied in order, top to bottom. The alert will be triggered as soon as one rule is matched."
                    }
                  />
                </span>
              </>
            }
            button={
              <Button
                onClick={this.addRule}
                content="Add Rule"
                iconClass="fa fa-plus"
                buttonType={ButtonType.Transparent}
              />
            }
          >
            {this.props.alertRules.map((ar, i) => (
              <div className="d-flex mb-3">
                <div
                  className={`d-flex flex-row flex-grow-1 align-items-center pt-3 ${
                    i > 0 ? "border-top" : ""
                  }`}
                  style={{ width: "100%" }}
                >
                  <div key={ar.id} className="d-flex flex-row flex-wrap" style={{ width: "100%" }}>
                    {/*SELECTION CRITERIA*/}
                    <div className="d-flex flex-row align-items-center mb-3 mr-3">
                      <span className="form-item-index text-numbers-circle">{i + 1}</span>
                    </div>
                    <div
                      className="d-flex flex-row align-items-center mb-3 mr-3"
                      style={{ width: "100%", flex: "1" }}
                    >
                      <span className="mr-2 text-para-default">For the</span>
                      <ReactSelect
                        idName={new Guid().toString()}
                        options={selectionCriteriaOptions}
                        inputValue={ar.selectionCriteria}
                        onChange={(option: any) =>
                          this.updateAlertRule(ar.id, (ar) => {
                            ar.selectionCriteria = option?.value ?? "";
                            ar.deviceIds = [];
                            ar.deviceTypeIds = [];
                            ar.tagIds = [];
                            ar.conditions = [];
                            this.addConditionToRule(ar);
                            return ar;
                          })
                        }
                        placeholder="Select target"
                        isClearable={true}
                      />
                    </div>

                    {ar.selectionCriteria != "" && (
                      <>
                        {/*DEVICES/DEVICE TYPES*/}
                        <div
                          className="d-flex flex-row align-items-center mb-3"
                          style={{ width: "100%", flex: "1" }}
                        >
                          {/*DEVICES*/}
                          {ar.selectionCriteria == "devices" && (
                            <>
                              <span className="mr-2 text-para-default">named</span>
                              <ReactSelect
                                idName={new Guid().toString()}
                                options={deviceOptions}
                                inputValue={ar.deviceIds}
                                onChange={(options: any) =>
                                  options &&
                                  this.updateAlertRule(ar.id, (ar) => {
                                    ar.deviceIds = options.map((o: any) => o.value);
                                    ar.conditions = [];
                                    this.addConditionToRule(ar);
                                    return ar;
                                  })
                                }
                                placeholder="Select devices"
                                isClearable={true}
                                isMulti={true}
                              />
                            </>
                          )}

                          {/*DEVICE TYPES*/}
                          {ar.selectionCriteria == "deviceTypes" && (
                            <>
                              <span className="mr-2 text-para-default">named</span>
                              <ReactSelect
                                idName={new Guid().toString()}
                                options={deviceTypeOptions}
                                inputValue={ar.deviceTypeIds}
                                onChange={(options: any) =>
                                  options &&
                                  this.updateAlertRule(ar.id, (ar) => {
                                    ar.deviceTypeIds = options.map((o: any) => o.value);
                                    ar.conditions = [];
                                    this.addConditionToRule(ar);
                                    return ar;
                                  })
                                }
                                placeholder="Select device types"
                                isClearable={true}
                                isMulti={true}
                              />
                            </>
                          )}
                          {/*TAGS*/}
                          {ar.selectionCriteria == "tags" && (
                            <>
                              <span className="mr-2 text-para-default">named</span>
                              <ReactSelect
                                idName={new Guid().toString()}
                                options={tagOptions}
                                inputValue={ar.tagIds}
                                onChange={(options: any) =>
                                  options &&
                                  this.updateAlertRule(ar.id, (ar) => {
                                    ar.tagIds = options.map((o: any) => o.value);
                                    ar.conditions = [];
                                    this.addConditionToRule(ar);
                                    return ar;
                                  })
                                }
                                placeholder="Select tags"
                                isClearable={true}
                                isMulti={true}
                              />
                            </>
                          )}
                        </div>

                        <div className="remove-rule-btn-div">
                          <IconButton
                            className="mb-3"
                            onClick={() => this.removeRule(ar)}
                            iconClass="fas fa-trash-alt"
                            tooltip="Remove Rule"
                            isDark={false}
                          />
                        </div>

                        {/* Measurements */}
                        {(ar.deviceIds.length > 0 ||
                          ar.deviceTypeIds.length > 0 ||
                          ar.tagIds.length > 0) && (
                          <>
                            <div className="break"></div>
                            <div className="main-container-with-border">
                              {ar.conditions &&
                                ar.conditions.map((arc, j) => (
                                  <>
                                    {ar.conditions && ar.conditions.length > 1 && (
                                      <div
                                        style={{
                                          flexBasis: "100%",
                                          width: 0,
                                        }}
                                      />
                                    )}
                                    <div
                                      className={
                                        "d-flex flex-row align-items-center mb-3 flex-wrap"
                                      }
                                    >
                                      {/*MEASUREMENT*/}
                                      <span className="mr-2">{j == 0 ? "if" : "and"}</span>

                                      <ReactSelect
                                        idName={new Guid().toString()}
                                        options={this.generateValidMeasurementOptions(ar)}
                                        inputValue={arc.measurementId}
                                        onChange={(option: any) =>
                                          this.updateAlertRuleCondition(ar.id, arc.id, (arc) => {
                                            arc.measurementId = option?.value ?? "";
                                            const measurement = this.getMeasurement(
                                              arc,
                                            ) as Measurement;
                                            if (measurement && measurement.fieldCount == 1) {
                                              arc.measurementFieldId =
                                                (measurement.fields as MeasurementField[])[0]?.id ??
                                                "";
                                            } else {
                                              arc.measurementFieldId = "";
                                            }
                                            return arc;
                                          })
                                        }
                                        placeholder="Select measurement"
                                        isClearable={true}
                                      />

                                      {/*FIELD*/}
                                      {arc.measurementId != "" &&
                                        (this.getMeasurement(arc)?.fieldCount as number) > 1 && (
                                          <>
                                            <span className="mr-2"></span>
                                            <ReactSelect
                                              idName={new Guid().toString()}
                                              options={this.generateMeasurementFieldOptions(arc)}
                                              inputValue={arc.measurementFieldId}
                                              onChange={(option: any) =>
                                                this.updateAlertRuleCondition(
                                                  ar.id,
                                                  arc.id,
                                                  (arc) => {
                                                    arc.measurementFieldId = option?.value ?? "";
                                                    const field = this.getMeasurementField(arc);
                                                    if (
                                                      field != undefined &&
                                                      (field.dataType == "boolean" ||
                                                        field.dataType == "string")
                                                    )
                                                      arc.operator = "=";
                                                    return arc;
                                                  },
                                                )
                                              }
                                              placeholder="Select field"
                                              isClearable={true}
                                              isMulti={false}
                                            />
                                          </>
                                        )}

                                      <span className="mr-2"></span>
                                      {arc.measurementFieldId != "" &&
                                        this.generateOperatorSection(ar, arc)}

                                      <span className="mr-2"></span>
                                      {arc.operator != "" && this.generateValueSection(ar, arc)}

                                      {this.showConditionDotDotDots(arc) && (
                                        <span className="mb-2 mr-2 text-bold">...</span>
                                      )}

                                      {ar.conditions && ar.conditions.length > 1 && (
                                        <div className="remove-condition-btn-div">
                                          <IconButton
                                            className="mb-3 mr-2"
                                            onClick={() => this.removeConditionFromRule(ar, j)}
                                            iconClass="fas fa-trash-alt"
                                            tooltip="Remove Condition"
                                            isDark={false}
                                          />
                                        </div>
                                      )}

                                      {arc.value != "" &&
                                        ar.conditions &&
                                        ar.conditions.length - 1 == j && (
                                          <>
                                            <div className="add-condition-btn-div">
                                              <IconButton
                                                className="mb-3 mr-2"
                                                onClick={() => this.addConditionToRule(ar)}
                                                iconClass="fas fa-plus"
                                                tooltip="Add Condition"
                                                isDark={false}
                                              />
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  </>
                                ))}

                              {ar.conditions &&
                                ar.conditions.length > 0 &&
                                ar.conditions[0].value != "" &&
                                ar.message == undefined && (
                                  <>
                                    {ar.conditions.length > 1 && (
                                      <div style={{ flexBasis: "100%", width: 0 }}></div>
                                    )}
                                    <div className="d-flex flex-row align-items-center mb-3">
                                      <span className="mr-2 text-nowrap">then trigger alert</span>
                                      <IconButton
                                        className="mr-2"
                                        onClick={() => this.addMessageToRule(ar)}
                                        iconClass="fas fa-comment-alt"
                                        tooltip="Add Message"
                                        isDark={false}
                                      />
                                    </div>
                                  </>
                                )}

                              {ar.conditions &&
                                ar.conditions.length > 0 &&
                                ar.conditions[0].value != "" &&
                                ar.message != undefined && (
                                  <>
                                    <div style={{ flexBasis: "100%", width: 0 }}></div>
                                    <div className="d-flex flex-row align-items-center mb-3">
                                      <span className="mr-2 text-nowrap">
                                        then trigger alert with message
                                      </span>
                                      <Input
                                        idName=""
                                        labelString=""
                                        placeholder="Enter message"
                                        inputValue={ar.message}
                                        inputType={InputType.Text}
                                        onChange={(event) =>
                                          this.updateAlertRule(ar.id, (ar) => {
                                            ar.message = event.currentTarget.value;
                                            return ar;
                                          })
                                        }
                                      />
                                    </div>
                                  </>
                                )}

                              {ar.conditions && (
                                <div className="d-flex flex-row align-items-center mb-3">
                                  <span className="mr-2">with priority</span>
                                  <PrioritySelect
                                    inputValue={ar.priority}
                                    onChange={(value) => {
                                      this.updateAlertRule(ar.id, (ar) => {
                                        ar.priority = value;
                                        return ar;
                                      });
                                    }}
                                  />
                                </div>
                              )}

                              {this.showRuleDotDotDots(ar) && (
                                <span className="mb-3 mr-2 text-bold">...</span>
                              )}

                              {this.generateErrorMessages(ar).map((err) => (
                                <p className="text-muted mb-3 text-danger">
                                  <i className="fa fa-exclamation-circle" />
                                  &nbsp;{err}
                                </p>
                              ))}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {this.props.alertRules.length <= 0 && (
              <>
                <p className="text-muted mt-3 mr-2">Click "Add Rule" to begin...</p>
              </>
            )}
          </FormGroup>
        </FormWrapper>
      </>
    );
  }
}
