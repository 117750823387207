import { FC, useEffect, useState } from "react";
import "./tagsStyles.scss";
import { PageContainerWrapper, PageContentWrapper } from "../../../components/layout";
import PageHeaderNew, {
  PageHeaderButton,
} from "../../../components/layout/pageHeader/PageHeaderNew";
import { Tag } from "../../../models/api/Tag";
import { TagApiService } from "../../../services/api/TagApiService";
import { IconButton } from "../../../components/IconButton";
import { useHistory } from "react-router-dom";
import { TableFilters } from "../../../models/api/TableFilters";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { PermissionFlags } from "../../../models/api/Role";
import { Input } from "../../../components/inputs/Input";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import { useLayout } from "../../../contexts/layout/layout-context";
import { MIN_SCREEN_WIDTH } from "../../../components/layout/LayoutHelper";
import { Guid } from "js-guid";
import Modal from "../../../components/layout/modal/Modal";
import { StateType } from "../../../components/inputs/ReactSelect";
import { INITIAL_TAG_STATE, Tag_State, validateTagName } from "./TagHelper";

interface IProps {}

type Page_State = {
  reloadTable: (() => void) | undefined;
  innerWidth: number;

  readAccess: boolean;
  writeAccess: boolean;
};

type Modal_State = {
  isCreateTagModalOpen: boolean;
  isDeleteTagModalOpen: boolean;
};

const INITIAL_PAGE_STATE: Page_State = {
  reloadTable: undefined,
  innerWidth: window.innerWidth,

  readAccess: false,
  writeAccess: false,
};

const INITIAL_MODAL_STATE: Modal_State = {
  isCreateTagModalOpen: false,
  isDeleteTagModalOpen: false,
};

const TagsPage: FC<IProps> = () => {
  const [modalState, setModalState] = useState<Modal_State>(INITIAL_MODAL_STATE);
  const [pageState, setPageState] = useState<Page_State>(INITIAL_PAGE_STATE);
  const [tagState, setTagState] = useState<Tag_State>(INITIAL_TAG_STATE);

  const { updateButtons, removeAllExtraInfos } = useLayout();
  const history = useHistory();

  useEffect(() => {
    removeAllExtraInfos();

    const userRole = JwtTokenHelpers.getUserRole();
    setPageState((prevState) => ({
      ...prevState,
      readAccess: (userRole.permissionsTag & PermissionFlags.Read) != 0,
      writeAccess: (userRole.permissionsTag & PermissionFlags.Write) != 0,
    }));
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setPageState((prevState) => ({
        ...prevState,
        innerWidth: window.innerWidth,
      }));
    }

    window.addEventListener("resize", handleWindowResize);
  });

  useEffect(() => {
    const headerButtons: PageHeaderButton[] = [];

    if (
      pageState.writeAccess &&
      JwtTokenHelpers.checkPermission("permissionsTag", PermissionFlags.Write)
    ) {
      headerButtons.push({
        buttonName: "Create Tag",
        onClick: () => {
          setModalState((prevState) => ({
            ...prevState,
            isCreateTagModalOpen: true,
          }));
        },
        iconClass: "fa fa-plus",
        isIconButton: pageState.innerWidth < MIN_SCREEN_WIDTH,
      });
    }

    updateButtons(headerButtons);
  }, [pageState.innerWidth, pageState.writeAccess]);

  async function createTagAsync() {
    const hasValidTagName = validateTagName(tagState.tagName, setTagState);

    if (hasValidTagName) {
      const newTag: Tag = {
        name: tagState.tagName,
      };

      new TagApiService()
        .create(newTag)
        .then((res) => {
          $.notification("show", {
            type: "message",
            title: undefined,
            message: "New tag was successfully added",
            toastOnly: true,
          });
          history.push(`/go/manage/tags/${res}`);
        })
        .catch((err) =>
          $.notification("show", {
            type: "error",
            title: undefined,
            message: err,
            toastOnly: true,
          }),
        );
    }
  }

  async function deleteTagAsync(tagId: string) {
    new TagApiService()
      .remove(tagId)
      .then(() => {
        $.notification("show", {
          type: "message",
          title: undefined,
          message: "Tag was successfully deleted",
          toastOnly: true,
        });
      })
      .catch((err) =>
        $.notification("show", {
          type: "error",
          title: undefined,
          message: err,
          toastOnly: true,
        }),
      );

    setTagState((prevState) => ({
      ...prevState,
      tagToDelete: "",
    }));
    setModalState((prevState) => ({
      ...prevState,
      isDeleteTagModalOpen: false,
    }));
    if (pageState.reloadTable !== undefined) pageState.reloadTable();
  }

  function clearAllInputs() {
    setTagState((prevState) => ({
      ...prevState,
      tagName: "",
      isTagNameValid: true,
    }));
  }

  return (
    <>
      <PageContainerWrapper>
        <section className="page-structure-header">
          <PageHeaderNew pageTitle="Tags" pageTitleType="Default" />
        </section>

        <PageContentWrapper>
          <FilterTable<Tag>
            tableId="tags-table"
            paginate={true}
            virtualize={true}
            fetchItemsRemotely={true}
            fetchItemsFunction={new TagApiService().list}
            initialTableFilters={new TableFilters("Name", true)}
            reloadFunction={(reload) => {
              setPageState((prevState) => ({
                ...prevState,
                reloadTable: reload,
              }));
            }}
            columnDefinitions={[
              {
                header: "",
                notTogglable: true,
                valueFunction: (item) => (
                  <>
                    {JwtTokenHelpers.checkPermission("permissionsTag", PermissionFlags.Write) && (
                      <div className="d-flex flex-row">
                        <IconButton
                          isDark={false}
                          iconClass="fa fa-pencil-alt"
                          tooltip="Edit tag"
                          onClick={() => {
                            history.push(`/go/manage/tags/${item.id}`);
                          }}
                        />
                        {item.deviceCount == 0 && (
                          <IconButton
                            isDark={false}
                            iconClass="fa fa-trash-alt"
                            tooltip="Delete tag"
                            onClick={() => {
                              setTagState((prevState) => ({
                                ...prevState,
                                tagToDelete: new Guid(item.id).toString(),
                              }));
                              setModalState((prevState) => ({
                                ...prevState,
                                isDeleteTagModalOpen: true,
                              }));
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                ),
              },
              {
                header: "Name",
                sortable: true,
                valueFunction: (item) => (item.name ? item.name : ""),
                databaseColumn: "Name",
              },
              {
                header: "Access Groups",
                sortable: true,
                valueFunction: (item) => (item.accessGroupCount ? item.accessGroupCount : 0),
                databaseColumn: "AccessGroupCount",
              },
              {
                header: "Devices",
                sortable: true,
                valueFunction: (item) => (item.deviceCount ? item.deviceCount : 0),
                databaseColumn: "DeviceCount",
              },
            ]}
          />
        </PageContentWrapper>
      </PageContainerWrapper>

      {modalState.isCreateTagModalOpen && (
        <Modal
          modalTitle="Create Tag"
          onConfirm={() => createTagAsync()}
          confirmButtonText="Create Tag"
          onClose={() => {
            clearAllInputs();
            setModalState((prevState) => ({
              ...prevState,
              isCreateTagModalOpen: false,
            }));
          }}
        >
          <div className="modal-tag-inputs">
            <Input
              idName="tag-name"
              inputValue={tagState.tagName}
              labelString="Tag Name"
              isRequired={true}
              inputState={tagState.isTagNameValid ? StateType.Default : StateType.Error}
              onChange={(event) => {
                const value = event.target.value;
                setTagState((prevState) => ({
                  ...prevState,
                  tagName: value,
                  isTagNameValid: !!value,
                }));
              }}
              onBlur={() => {
                validateTagName(tagState.tagName, setTagState);
              }}
            />
          </div>
        </Modal>
      )}

      {modalState.isDeleteTagModalOpen && (
        <Modal
          modalTitle="Delete Tag"
          onConfirm={() => {
            if (!new Guid(tagState.tagToDelete).isEmpty()) {
              deleteTagAsync(tagState.tagToDelete);
            }
          }}
          confirmButtonText="Delete Tag"
          isOnConfirmError={true}
          onClose={() => {
            setTagState((prevState) => ({
              ...prevState,
              tagToDelete: "",
            }));
            setModalState((prevState) => ({
              ...prevState,
              isDeleteTagModalOpen: false,
            }));
          }}
        >
          <div className="text-para-default">Are you sure you want to delete the tag?</div>
        </Modal>
      )}
    </>
  );
};

export { TagsPage };
