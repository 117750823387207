import { FC } from "react";

import { FavoriteDashboard } from "../../../models/api/DashboardDevice";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { FilterTable } from "../../../components/filterTable/FilterTable";
import DateHelpers from "../../../helpers/DateHelpers";
import CardContainer from "./card/CardContainer";

import "./favorite-dashboards-styles.scss";

type Props = {
  favoriteDevices: FavoriteDashboard[] | undefined;
  isLoading: boolean;
};

const FavoriteDashboards: FC<Props> = ({ favoriteDevices, isLoading }) => {
  if (isLoading) {
    return <LoadingSkeleton width="100%" height="4rem" />;
  }

  const GetContent = () => {
    if (!!favoriteDevices && favoriteDevices.length > 0) {
      return (
        <FilterTable<FavoriteDashboard>
          tableId="favourite-dashboard-table"
          items={favoriteDevices}
          paginate={favoriteDevices.length > 5}
          paginationOptions={[
            { label: "5", value: 5 },
            { label: "10", value: 10 },
          ]}
          columnDefinitions={[
            {
              sortable: false,
              valueFunction: (item) => (
                <>
                  <a
                    className="widget-table-item-row"
                    href={`/go/dashboard/device/${item.deviceId}`}
                    title="View dashboard"
                  >
                    {item.name}
                  </a>
                </>
              ),
              header: "Controller Name",
            },
            {
              sortable: false,
              valueFunction: (item) => (
                <span className="last-seen">
                  {DateHelpers.formatMomentAsTimeSince(item.timestamp)}
                </span>
              ),
              header: "Last Seen",
            },
          ]}
          hideHeader
          hasSmallFooter
          hideFooterSelection
          removeBorder
        />
      );
    } else {
      return (
        <span className="text-muted p-3">
          You have no favorite dashboards. Click the <i className="far fa-star" />
          &nbsp;icon on a dashboard, or on the dashboard list, to add a dashboard to favorites.
        </span>
      );
    }
  };

  return (
    <CardContainer
      title={`${favoriteDevices?.length}`}
      subTitle="Favorite Dashboards"
      iconClass="fas fa-star"
    >
      {<GetContent />}
    </CardContainer>
  );
};

export default FavoriteDashboards;
