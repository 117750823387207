import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import JwtTokenHelpers from "../../helpers/JwtTokenHelpers";
import { cfHost } from "../../config";
import "../../scss/navStyles.scss";
import { useBranding } from "../../contexts/branding/branding.context";
import { hasWriteFlag } from "../../models/api/Role";
import _ from "lodash";

type LinksModel = {
  id: string;
  path: string;
  activeClass: string;
  iconClass: string;
  label: string;
  innerLinks?: LinksModel[];
  hidden?: boolean;
  hasChildPath?: boolean;
  childrenPaths?: string[];
};

const defaultBrandText: string = "FreeWave";
const defaultBrandImageSrc: string = "/images/freewave-icon.png";

const initialLinks: LinksModel[] = [
  /*home*/
  {
    id: "welcome-link",
    path: "/go/welcome",
    activeClass: "",
    iconClass: "nav-icon fa fa-home",
    label: "Home",
    hidden: false,
  },
  /*Dashboard*/
  {
    id: "dashboard-link",
    path: "#",
    activeClass: "",
    iconClass: "nav-icon fa fa-tachometer-alt",
    label: "Dashboard",
    hidden: false,
    innerLinks: [
      /*{id:"dashboard-custom-link", path:"/go/dashboard/custom", activeClass:"", iconClass:"", label:"Dashboards", hidden:false}*/
      {
        id: "dashboard-map-link",
        path: "/go/dashboard/map",
        activeClass: "",
        iconClass: "",
        label: "Map",
        hidden: false,
      },
      {
        id: "dashboard-list-link",
        path: "/go/dashboard/deviceList",
        activeClass: "",
        iconClass: "",
        label: "List",
        hidden: false,
      },
    ],
  },
  /*Devices*/
  {
    id: "devices-link",
    path: "/go/devicesManagement/devices",
    activeClass: "",
    iconClass: "nav-icon fas fa-satellite",
    label: "Devices",
    hidden: false,
  },
  /*Alert*/
  {
    id: "alert-link",
    path: "#",
    activeClass: "",
    iconClass: "nav-icon fa fa-exclamation-triangle",
    label: "Alerts",
    hidden: false,
    innerLinks: [
      {
        id: "alerts-feed-link",
        path: "/go/manage/alerts",
        activeClass: "",
        iconClass: "",
        label: "Active",
        hidden: false,
      },
      {
        id: "alerts-history-link",
        path: "/go/manage/alertsHistory",
        activeClass: "",
        iconClass: "",
        label: "History",
        hidden: false,
      },
      {
        id: "configure-alerts-link",
        path: "/go/manage/config/alerts",
        activeClass: "",
        iconClass: "",
        label: "Configure",
        hidden: false,
      },
    ],
  },
  /*Graphing*/
  {
    id: "graphing-link",
    path: "/go/graphing",
    activeClass: "",
    iconClass: "nav-icon fas fa-bar-chart",
    label: "Graphs",
    hidden: false,
  },
  /*Tag*/
  {
    id: "tag-link",
    path: "/go/manage/tags",
    activeClass: "",
    iconClass: "nav-icon fas fa-tags",
    label: "Tags",
    hidden: false,
  },
  /*Admin*/
  {
    id: "admin-link",
    path: "#",
    activeClass: "",
    iconClass: "nav-icon fa fa-user-shield",
    label: "Admin",
    hidden: true,
    innerLinks: [
      {
        id: "admin-organizations-link",
        path: "/go/admin/organizations",
        activeClass: "",
        iconClass: "",
        label: "Organizations",
        hidden: true,
      },
      {
        id: "admin-firmware-link",
        path: "/go/admin/firmware",
        activeClass: "",
        iconClass: "",
        label: "Firmware",
        hidden: true,
      },
      {
        id: "admin-webhook-link",
        path: "/go/manage/organization/webhooks",
        activeClass: "",
        iconClass: "",
        label: "Webhooks",
        hidden: true,
      },
      {
        id: "admin-dataImport-link",
        path: "/go/admin/import/index",
        activeClass: "",
        iconClass: "",
        label: "Data Import",
        hidden: true,
      },
      {
        id: "admin-dataExport-link",
        path: "/go/devicesManagement/exportMeasurementData",
        activeClass: "",
        iconClass: "",
        label: "Data Export",
        hidden: true,
      },
    ],
  },
  /*InHouse*/
  {
    id: "inhouse-link",
    path: "#",
    activeClass: "",
    iconClass: "nav-icon fa fa-house-user",
    label: "Inhouse",
    hidden: true,
    innerLinks: [
      {
        id: "admin-users-link",
        path: "/go/admin/users",
        activeClass: "",
        iconClass: "",
        label: "Users",
        hidden: true,
      },
      {
        id: "admin-devices-link",
        path: "/go/admin/devices",
        activeClass: "",
        iconClass: "",
        label: "Devices",
        hidden: true,
      },
      {
        id: "inhouse-deviceTypes-link",
        path: "/go/inhouse/deviceTypes",
        activeClass: "",
        iconClass: "",
        label: "Device Types",
        hidden: true,
      },
      {
        id: "inhouse-templates-link",
        path: "/go/inhouse/templates",
        activeClass: "",
        iconClass: "",
        label: "Templates",
        hidden: true,
      },
      {
        id: "inhouse-productTypes-link",
        path: "/go/inhouse/productTypes",
        activeClass: "",
        iconClass: "",
        label: "Product Types",
        hidden: true,
      },
      {
        id: "inhouse-satellite-link",
        path: "/go/inhouse/satellite",
        activeClass: "",
        iconClass: "",
        label: "Satellite",
        hidden: true,
      },
      {
        id: "inhouse-ingestMapping-link",
        path: "/go/inhouse/ingestMapping",
        activeClass: "",
        iconClass: "",
        label: "Ingest Mapping",
        hidden: true,
      },
      {
        id: "inhouse-measurements-link",
        path: "/go/inhouse/measurements",
        activeClass: "",
        iconClass: "",
        label: "Measurements",
        hidden: true,
      },
      {
        id: "inhouse-releases-link",
        path: "/go/inhouse/releases",
        activeClass: "",
        iconClass: "",
        label: "Releases",
        hidden: true,
      },
      {
        id: "inhouse-inertia-fleet-dashboard-mock-link",
        path: "/go/inhouse/inertia-fleet-dashboard-mock",
        activeClass: "",
        iconClass: "",
        label: "Inertia Fleet Dashboard Mock",
        hidden: true,
      },
      {
        id: "inhouse-dashboard-api-test-link",
        path: "/go/inhouse/dashboard-api-test",
        activeClass: "",
        iconClass: "",
        label: "Dashboard API (C#) Test",
        hidden: true,
      },
      {
        id: "inhouse-scss-class-library-link",
        path: "/go/inhouse/scssClassLibrary",
        activeClass: "",
        iconClass: "",
        label: "SCSS Class Library",
        hidden: true,
      },
      {
        id: "inhouse-react-component-library-link",
        path: "/go/inhouse/reactComponentLibrary",
        activeClass: "",
        iconClass: "",
        label: "React Component Library",
        hidden: true,
      },
      {
        id: "inhouse-card-library-link",
        path: "/go/inhouse/cardLibrary/valuecards",
        activeClass: "",
        iconClass: "",
        label: "Card and FC Library",
        hidden: true,
      },
      {
        id: "inhouse-widget-library-link",
        path: "/go/inhouse/widgetLibrary/genericcurrentwidgets",
        activeClass: "",
        iconClass: "",
        label: "Widget Library",
        hidden: true,
      },
    ],
  },
];

interface Theme {
  orgName: string;
  navLogoUrl: string;
}

const THEME_INITIAL_STATE: Theme = {
  orgName: defaultBrandText,
  navLogoUrl: `${cfHost}${defaultBrandImageSrc}`,
};

export function Navbar() {
  const [hyperlinks, setHyperlinks] = useState<LinksModel[] | undefined>(undefined);
  const isInhouse = JwtTokenHelpers.isInhouse();
  const location = useLocation();

  const { branding } = useBranding();
  const [theme, setTheme] = useState<Theme>(THEME_INITIAL_STATE);

  const categoryAccess = JwtTokenHelpers.getRoleCategoryAccess();

  useEffect(() => {
    /** Only run update links when mounting component */
    updateLinks();
  }, [isInhouse, location]);

  useEffect(() => {
    setupSidebarEventhandlers();
    // The following line hides the scrollbar in the sidebar
    // @ts-expect-error
    $("body").Layout("fixLayoutHeight");
  }, []);

  useEffect(() => {
    const path = branding.iconImageUri
      ? branding.iconImageUri
      : branding.logoImageUri
        ? branding.logoImageUri
        : defaultBrandImageSrc;

    setTheme({
      navLogoUrl: `${cfHost}${path}`,
      orgName: branding.companyName
        ? branding.companyName
        : branding.organizationName
          ? branding.organizationName
          : defaultBrandText,
    });
  }, [branding]);

  /**
   * This sets up the admin-lte event handlers for the sidebar. admin-lte is loaded before this page
   * as a result of including the login screen as part of this app
   */
  const setupSidebarEventhandlers = () => {
    $('[data-widget="treeview"]').each(function () {
      // @ts-expect-error
      $.fn["Treeview"].call($(this), "init");
    });
  };
  const updateLinks = () => {
    const userRole = JwtTokenHelpers.getUserRole();
    // Clone initialLinks to prevent making changes to initialLinks
    const linksTemp: LinksModel[] = _.cloneDeep(initialLinks);

    for (let i = 0; i < linksTemp.length; i++) {
      const element = linksTemp[i];

      if (element.path === location.pathname) {
        element.activeClass = "bg-color-primary-theme-transparent active";
      } else {
        element.activeClass = "";
      }

      /*Dashboard visibility*/
      if (element.id === "dashboard-link") {
        element.hidden = true;
        if (userRole.permissionsDashboard) {
          element.hidden = false;
          const dashboardItems = element.innerLinks;
          if (dashboardItems != undefined) {
            for (let j = 0; j < dashboardItems.length; j++) {
              const innerItem = dashboardItems[j];
              if (innerItem.id === "dashboard-custom-link") {
                innerItem.hidden = !userRole.permissionsCustomDashboard;
              }
            }
          }
        }
      }

      /*Device visibility*/
      if (element.id === "devices-link") {
        element.hidden = !categoryAccess.deviceCategoryAccess;
      }

      /*Graphing visibility*/
      if (element.id === "graphing-link") {
        element.hidden = !userRole.permissionsDashboard;
      }

      /*Admin visibility*/
      if (userRole.permissionsAdminMenu) {
        /* admin menu visible or not */
        if (element.id === "admin-link") {
          // check if user has access
          element.hidden = false;
          const adminItems = element.innerLinks;
          if (adminItems != undefined) {
            for (let j = 0; j < adminItems.length; j++) {
              const innerItem = adminItems[j];
              innerItem.hidden = false;
            }
          }
        }
      }

      /*Inhouse visibility*/
      if (isInhouse) {
        /* inhouse menu visible or not */
        if (element.id === "inhouse-link") {
          // check if user has access
          element.hidden = false;
          const inhouseItems = element.innerLinks;
          if (inhouseItems != undefined) {
            for (let j = 0; j < inhouseItems.length; j++) {
              const innerItem = inhouseItems[j];
              innerItem.hidden = false;
            }
          }
        }
      }

      /*Alerts visibility*/
      if (element.id === "alert-link") {
        if (!userRole.permissionsAlert) {
          element.hidden = true;
        }
      }

      if (element.innerLinks) {
        const innerItems = element.innerLinks;
        for (let j = 0; j < innerItems.length; j++) {
          const innerItem = innerItems[j];

          /*Alert sublink visibility*/
          if (innerItem.id === "configure-alerts-link") {
            if (!hasWriteFlag(userRole.permissionsAlert)) {
              innerItem.hidden = true;
            }
          }

          if (innerItem.path === location.pathname) {
            element.activeClass = "menu-is-opening menu-open";
            innerItem.activeClass = "bg-color-primary-theme-transparent active";
          } else if (
            innerItem.hasChildPath &&
            innerItem.childrenPaths?.includes(location.pathname)
          ) {
            element.activeClass = "menu-is-opening menu-open";
            innerItem.activeClass = "bg-color-primary-theme-transparent active";
          } else {
            element.activeClass = "";
            innerItem.activeClass = "";
          }
        }
      }
    }
    setHyperlinks([...linksTemp]);
  };

  const orgNameClick = () => {
    const mobileOrgName = document.getElementById("navbar-org-name-mobile");

    if (mobileOrgName) {
      mobileOrgName.classList.add("show");

      setTimeout(() => {
        mobileOrgName.classList.remove("show");
      }, 3000);
    }
  };

  return (
    <aside id="main-sidebar" className="main-sidebar">
      <div className="aside-header-content">
        <img
          id="default-logo-menu"
          alt={`${theme.orgName}'s Logo`}
          className="aside-header-image"
          src={theme.navLogoUrl}
        />
        <span
          id="navbar-org-name"
          className="aside-header-org-title"
          title={theme.orgName}
          onClick={orgNameClick}
        >
          {theme.orgName}
          <small id="navbar-org-name-mobile" className="aside-header-org-title-mobile">
            {theme.orgName}
          </small>
        </span>
      </div>
      <div className="sidebar no-margin-top" style={{ overflowY: "scroll" }}>
        <nav>
          <ul
            className="nav nav-pills nav-sidebar flex-column mb-4"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {hyperlinks &&
              hyperlinks.map(
                (item) =>
                  !item.hidden && (
                    <li
                      key={item.id}
                      className={`nav-item ${!item.innerLinks ? "" : item.activeClass}`}
                    >
                      {item.path == "#" && (
                        <a href={item.path} className={`nav-link ${item.activeClass}`} id={item.id}>
                          <i className={item.iconClass} />
                          <p>
                            {item.label} {item.innerLinks && <i className="fas fa-angle-right" />}{" "}
                          </p>
                        </a>
                      )}
                      {item.path != "#" && (
                        <Link
                          to={item.path}
                          className={`nav-link ${item.activeClass}`}
                          id={item.id}
                        >
                          <i className={item.iconClass} />
                          <p>
                            {item.label} {item.innerLinks && <i className="fas fa-angle-right" />}
                          </p>
                        </Link>
                      )}
                      {item.innerLinks && (
                        <ul className="nav nav-treeview">
                          {item.innerLinks.map(
                            (innerItem) =>
                              !innerItem.hidden && (
                                <li key={innerItem.id} className="nav-item">
                                  <Link
                                    to={innerItem.path}
                                    className={`nav-link ${innerItem.activeClass}`}
                                    id={innerItem.id}
                                  >
                                    <p>{innerItem.label}</p>
                                  </Link>
                                </li>
                              ),
                          )}
                        </ul>
                      )}
                    </li>
                  ),
              )}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
