import { Component, ReactChild } from "react";
import { Card } from "../../../components/Card";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { GraphWidgetMenu } from "./GraphWidgetMenu";
import { Dialog } from "../../../components/Dialog";
import { MeasurementField } from "../../../models/api/MeasurementField";
import { HistoricalMeasurementDataResponse } from "../../../models/api/MeasurementDataResponses";
import { ChartData, TooltipItem } from "chart.js";
import { ColorMap } from "./DefaultDashboard";
import { Measurement } from "../../../models/api/Measurement";
import { Range } from "react-date-range";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import MeasurementHelpers from "../../../helpers/MeasurementHelpers";
import GraphHelpers from "../../../helpers/GraphHelpers";
import { UnitPreference } from "../../../models/api/UnitPreference";

interface IProps {
  deviceId: string;
  deviceName: string;
  title: string;
  iconClass: string;
  measurement: Measurement;
  measurementField: MeasurementField;
  unitPreferences: UnitPreference[];
  data?: HistoricalMeasurementDataResponse[];
  colorMap: ColorMap;
  range: Range;
  noCard?: boolean;
}

interface IState {
  openDialog: () => void;
  closeDialog: () => void;
}

export class GraphWidget extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openDialog: () => {},
      closeDialog: () => {},
    };
  }

  componentDidMount() {}

  getValidData(): ChartData | undefined {
    if (!this.props.data) return undefined;
    const chartData: ChartData<"line"> = {
      labels: this.props.data.map((dr) => dr.name),
      // @ts-expect-error
      datasets: this.props.data.map((dr) => ({
        data: dr.data
          .filter((datum) => {
            const value = datum[this.props.measurementField.fieldKey];
            const valid = MeasurementHelpers.validateValue(value, this.props.measurementField);
            return valid;
          })
          .map((datum) => {
            const value = datum[this.props.measurementField.fieldKey];
            return {
              x: datum.time.toDate(),
              y: value,
            };
          })
          .reverse(),
        label: dr.name,
        backgroundColor: this.props.colorMap[GraphHelpers.getSeriesDistinctionKey(dr)],
        borderColor: this.props.colorMap[GraphHelpers.getSeriesDistinctionKey(dr)],
        parsing: false,
        normalized: true,
      })),
    };
    return chartData;
  }

  generateGraph(data: ChartData): ReactChild {
    return (
      <>
        <div className="graph-wrapper">
          <Line
            // @ts-expect-error
            data={data}
            options={{
              interaction: {
                intersect: false,
                mode: "nearest",
                axis: "x",
              },
              elements: {
                point: {
                  radius: 0,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "bottom",
                  display: this.props.data && this.props.data.length > 1,
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem: TooltipItem<"line">) => {
                      const displayUnit = MeasurementHelpers.getPreferedDisplayUnit(
                        this.props.unitPreferences,
                        this.props.measurementField!,
                      );
                      return `${tooltipItem.dataset.label}: ${MeasurementHelpers.formatValue(
                        (tooltipItem.raw as any).y,
                        this.props.measurementField,
                        displayUnit,
                      )}`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  // @ts-expect-error
                  type: "time",
                  // @ts-expect-error
                  min: this.props.range.startDate,
                  // @ts-expect-error
                  max: this.props.range.endDate,
                  time: {
                    displayFormats: {
                      hour: "Do MMM, hA",
                    },
                  },
                },
                y: {
                  ticks: {
                    callback: (tickValue) => {
                      const displayUnit = MeasurementHelpers.getPreferedDisplayUnit(
                        this.props.unitPreferences,
                        this.props.measurementField!,
                      );
                      return MeasurementHelpers.formatValue(
                        tickValue,
                        this.props.measurementField,
                        displayUnit,
                      );
                    },
                  },
                },
              },
            }}
          />
        </div>
      </>
    );
  }

  hasData(): boolean {
    return true;
  }

  generateHeader(): ReactChild {
    return (
      <span>
        <i className={`${this.props.iconClass} mr-3 text-muted`}></i>
        {this.props.title}
      </span>
    );
  }

  render() {
    const validData = this.getValidData();

    if (!this.props.noCard) {
      return (
        <>
          <Card
            header={
              <>
                {this.generateHeader()}
                <span className="flex-grow-1"></span>
                <GraphWidgetMenu
                  deviceId={this.props.deviceId}
                  deviceName={this.props.deviceName}
                  measurement={this.props.measurement.name}
                  measurementName={
                    this.props.measurement.displayName ?? this.props.measurement.name
                  }
                  onExpandGraph={this.state.openDialog}
                  expandGraphDisabled={!validData}
                />
              </>
            }
            body={
              <>
                {validData &&
                  !!validData.datasets.find((ds) => ds.data.length > 0) &&
                  this.generateGraph(validData)}
                {validData && !validData.datasets.find((ds) => ds.data.length > 0) && (
                  <p className="text-muted">No data for this time range.</p>
                )}
                {!validData && <LoadingSkeleton aspectRatio="3/1" width="100%" />}
              </>
            }
          />
          <Dialog
            header={this.generateHeader()}
            body={
              <div style={{ width: "80vw", height: "80vh" }}>
                {validData &&
                  !!validData.datasets.find((ds) => ds.data.length > 0) &&
                  this.generateGraph(validData)}
                {validData && !validData.datasets.find((ds) => ds.data.length > 0) && (
                  <p className="text-muted">No data for this time range.</p>
                )}
                {!validData && <LoadingSkeleton aspectRatio="3/1" width="100%" />}
              </div>
            }
            setOpen={(open) => this.setState({ openDialog: open })}
            setClose={(close) => this.setState({ closeDialog: close })}
          />
        </>
      );
    } else {
      return (
        <>
          {validData &&
            !!validData.datasets.find((ds) => ds.data.length > 0) &&
            this.generateGraph(validData)}
          {validData && !validData.datasets.find((ds) => ds.data.length > 0) && (
            <p className="text-muted">No data for this time range.</p>
          )}
          {!validData && <LoadingSkeleton aspectRatio="3/1" width="100%" />}
        </>
      );
    }
  }
}
