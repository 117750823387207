import { FC, PropsWithChildren } from "react";

import "./alerts-widget-card-container-styles.scss";
import { Checkbox } from "../../../../components/inputs";

type Props = {
  totalAlerts: string;
  iconClass?: string;
  switchGroupAlerts: () => void;
} & PropsWithChildren;

const AlertsWidgetCardContainer: FC<Props> = ({
  totalAlerts,
  iconClass,
  switchGroupAlerts,
  children,
}) => {
  return (
    <div className="alerts-widget-card-container">
      <div className="alerts-widget-card-container-title-wrapper">
        <div className="alerts-widget-card-container-title">
          {!!iconClass && <i className={iconClass} />}
          <div className="alerts-widget-card-container-alerts-count">{totalAlerts}</div>
          <p className="alerts-widget-card-container-text">Alerts</p>
        </div>
        <div className="alerts-widget-card-container-see-all">
          <a className="widget-table-item-row" href="/go/manage/alerts" title="Alerts Feed">
            See All
          </a>
          <i className="fas fa-arrow-right" />
        </div>
      </div>
      <div className="alerts-widget-card-container-group-alerts">
        <Checkbox labelsize="small" text="Group alerts" onChange={() => switchGroupAlerts()} />
      </div>

      <div className="alerts-widget-card-container-content">{children}</div>
    </div>
  );
};

export default AlertsWidgetCardContainer;
