import { FC, createContext, useCallback, useState, useContext, PropsWithChildren } from "react";

import { Button, ButtonType } from "../../../../../components/Button";
import { ReactSelect } from "../../../../../components/inputs/ReactSelect";

import "./historical-charts-filter-styles.scss";
import "./flat-picker-styles.scss";
import CalendarFilter from "./CalendarFIlter/calendar-filter";

type HistoricalChartsFilterOptionsType = { label: string; value: string };
type HistoricalChartsFilterLabelType = { [key: string]: string };

const DURATION_OPTIONS: HistoricalChartsFilterOptionsType[] = [
  { value: "5", label: "5 minutes" },
  { value: "10", label: "10 minutes" },
  { value: "15", label: "15 minutes" },
  { value: "20", label: "20 minutes" },
  { value: "30", label: "30 minutes" },
  { value: "60", label: "60 minutes" },
  { value: "120", label: "2 hours" },
  { value: "180", label: "3 hours" },
  { value: "240", label: "4 hours" },
  { value: "360", label: "6 hours" },
  { value: "720", label: "12 hours" },
  { value: "1440", label: "24 hours" },
  { value: "2880", label: "2 days" },
  { value: "4320", label: "3 days" },
  { value: "10080", label: "7 days" },
  { value: "14400", label: "10 days" },
  { value: "20160", label: "14 days" },
  { value: "28800", label: "20 days" },
  { value: "43200", label: "30 days" },
  { value: "64800", label: "45 days" },
  { value: "86400", label: "60 days" },
  { value: "129600", label: "90 days" },
  { value: "172800", label: "120 days" },
  { value: "259200", label: "180 days" },
];

const RESOLUTION_OPTIONS: HistoricalChartsFilterOptionsType[] = [
  { value: "5", label: "5 minutes" },
  { value: "10", label: "10 minutes" },
  { value: "15", label: "15 minutes" },
  { value: "20", label: "20 minutes" },
  { value: "30", label: "30 minutes" },
  { value: "60", label: "60 minutes" },
  { value: "120", label: "2 hours" },
  { value: "180", label: "3 hours" },
  { value: "240", label: "4 hours" },
  { value: "360", label: "6 hours" },
  { value: "720", label: "12 hours" },
];

const DURATION_LABEL: HistoricalChartsFilterLabelType = {
  ["5"]: "Last 5 mins",
  ["10"]: "Last 10 mins",
  ["15"]: "Last 15 mins",
  ["20"]: "Last 20 mins",
  ["30"]: "Last 30 mins",
  ["60"]: "Last 60 mins",
  ["120"]: "Last 2 hours",
  ["180"]: "Last 3 hours",
  ["240"]: "Last 4 hours",
  ["360"]: "Last 6 hours",
  ["720"]: "Last 12 hours",
  ["1440"]: "Last 24 hours",
  ["2880"]: "Last 2 days",
  ["4320"]: "Last 3 days",
  ["10080"]: "Last 7 days",
  ["14400"]: "Last 10 days",
  ["20160"]: "Last 14 days",
  ["28800"]: "Last 20 days",
  ["43200"]: "Last 30 days",
  ["64800"]: "Last 45 days",
  ["86400"]: "Last 60 days",
  ["129600"]: "Last 90 days",
  ["172800"]: "Last 120 days",
  ["259200"]: "Last 180 days",
};

const RESOLUTION_LABEL: HistoricalChartsFilterLabelType = {
  ["5"]: "5 mins",
  ["10"]: "10 mins",
  ["15"]: "15 mins",
  ["20"]: "20 mins",
  ["30"]: "30 mins",
  ["60"]: "60 mins",
  ["120"]: "2 hours",
  ["180"]: "3 hours",
  ["240"]: "4 hours",
  ["360"]: "6 hours",
  ["720"]: "12 hours",
};

const DURATION_IN_MINUTES_DEFAULT_VALUE = 1440;
const RESOLUTION_IN_MINUTES_DEFAULT_VALUE = 15;

const getFromSpecificDateFormatted = (): string => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = `${new Date().getDate().toString().padStart(2, "0")}`;
  const month = `${months[new Date().getMonth()]}`;
  const year = `${new Date().getFullYear()}`;
  const hours = `${new Date().getHours().toString().padStart(2, "0")}`;
  const minutes = `${new Date().getMinutes().toString().padStart(2, "0")}`;
  const seconds = `${new Date().getSeconds().toString().padStart(2, "0")}`;

  // "M d, Y H:i:S"
  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
};

const InitialState = {
  duration: DURATION_IN_MINUTES_DEFAULT_VALUE,
  resolution: RESOLUTION_IN_MINUTES_DEFAULT_VALUE,
  isFilterCollapsed: true,
  durationOptions: DURATION_OPTIONS,
  resolutionOptions: RESOLUTION_OPTIONS,
  durationOptionSelected: DURATION_IN_MINUTES_DEFAULT_VALUE.toString(),
  resolutionOptionSelected: RESOLUTION_IN_MINUTES_DEFAULT_VALUE.toString(),
  timeLastRefresh: new Date().getTime(),
  fromSpecificDateFilter: new Date(),
  fromSpecificDateFormatted: getFromSpecificDateFormatted(),
  customEndDate: undefined,
};

type HistoricalChartsFilterType = {
  toggleFilter(): void;
  filterButton: JSX.Element;
  duration: number;
  resolution: number;
  timeLastRefresh: number;
  customEndDate?: number;
};

type HistoricalChartsFilterState = {
  duration: number;
  resolution: number;
  isFilterCollapsed: boolean;
  durationOptions: HistoricalChartsFilterOptionsType[];
  resolutionOptions: HistoricalChartsFilterOptionsType[];
  durationOptionSelected: string;
  resolutionOptionSelected: string;
  timeLastRefresh: number;
  fromSpecificDateFilter: Date;
  fromSpecificDateFormatted: string;
  customEndDate?: number;
};

const HistoricalChartsFilterContext = createContext<HistoricalChartsFilterType>(
  {} as HistoricalChartsFilterType,
);

const HistoricalChartsFilterProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<HistoricalChartsFilterState>(InitialState);

  const toggleFilter = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isFilterCollapsed: !prevState.isFilterCollapsed,
    }));
  }, []);

  const FilterButton = () => {
    return (
      <button type="button" className="historical-charts-filter-button" onClick={toggleFilter}>
        <label className="historical-charts-filter-button-label">
          {/* Resolution */}
          Res
          <span className="historical-charts-filter-button-value">
            {RESOLUTION_LABEL[state.resolutionOptionSelected]}
          </span>
        </label>
        <label className="historical-charts-filter-button-label">
          {/* Date Range */}
          Dur
          <span className="historical-charts-filter-button-value">
            {DURATION_LABEL[state.durationOptionSelected]}
          </span>
        </label>
        <label className="historical-charts-filter-button-label">
          From
          <span className="historical-charts-filter-button-value">
            {state.fromSpecificDateFormatted}
          </span>
        </label>
        <i className="far fa-chevron-down"></i>
      </button>
    );
  };

  const updateTimeLastRefresh = async (duration: number, resolution: number): Promise<void> => {
    setState((prevState) => ({
      ...prevState,
      duration,
      resolution,
      durationOptionSelected: duration.toString(),
      resolutionOptionSelected: resolution.toString(),
      isFilterCollapsed: !prevState.isFilterCollapsed,
      timeLastRefresh: new Date().getTime(),
    }));
  };

  const callbackFnCustomEndDateToUpdate = (
    fromSpecificDateFormatted: string,
    customEndDate?: number,
  ) => {
    setState((prevState) => ({
      ...prevState,
      fromSpecificDateFormatted,
      customEndDate,
    }));
  };

  return (
    <HistoricalChartsFilterContext.Provider
      value={{
        toggleFilter,
        filterButton: FilterButton(),
        duration: state.duration,
        resolution: state.resolution,
        timeLastRefresh: state.timeLastRefresh,
        customEndDate: state.customEndDate,
      }}
    >
      <div className="historical-charts-filter-container">
        {children}

        {!state.isFilterCollapsed && (
          <div className="historical-charts-filter-options-container">
            <div className="historical-charts-filter-options-wrapper">
              <div className="historical-charts-filter-options-input-container">
                <ReactSelect
                  idName="resolution-filter-options"
                  labelString="Resolution"
                  placeholder=""
                  options={state.resolutionOptions}
                  inputValue={state.resolutionOptionSelected}
                  onChange={(opt: any) => {
                    if (opt != null) {
                      setState((prevState) => ({
                        ...prevState,
                        resolutionOptionSelected: opt.value,
                      }));
                    } else {
                      setState((prevState) => ({
                        ...prevState,
                        resolutionOptionSelected: RESOLUTION_IN_MINUTES_DEFAULT_VALUE.toString(),
                      }));
                    }
                  }}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
              <div className="historical-charts-filter-options-input-container">
                <ReactSelect
                  idName="duration-filter-options"
                  labelString="Date Range"
                  placeholder=""
                  options={state.durationOptions}
                  inputValue={state.durationOptionSelected}
                  onChange={(opt: any) => {
                    if (opt != null) {
                      setState((prevState) => ({
                        ...prevState,
                        durationOptionSelected: opt.value,
                      }));
                    } else {
                      setState((prevState) => ({
                        ...prevState,
                        durationOptionSelected: DURATION_IN_MINUTES_DEFAULT_VALUE.toString(),
                      }));
                    }
                  }}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
              <div className="historical-charts-filter-options-input-container">
                <CalendarFilter
                  startDate={state.fromSpecificDateFilter}
                  callbackFnCustomEndDateToUpdate={callbackFnCustomEndDateToUpdate}
                />
              </div>
              <div className="historical-charts-filter-options-buttons-container">
                <Button
                  content="Reset"
                  buttonType={ButtonType.White}
                  onClick={() =>
                    updateTimeLastRefresh(
                      DURATION_IN_MINUTES_DEFAULT_VALUE,
                      RESOLUTION_IN_MINUTES_DEFAULT_VALUE,
                    )
                  }
                />
                <Button
                  content="Set Filter"
                  onClick={() =>
                    updateTimeLastRefresh(
                      Number(state.durationOptionSelected),
                      Number(state.resolutionOptionSelected),
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </HistoricalChartsFilterContext.Provider>
  );
};

function useHistoricalChartsFilter(): HistoricalChartsFilterType {
  const context = useContext(HistoricalChartsFilterContext);

  if (!context) {
    throw new Error(
      "useHistoricalChartsFilter must be used within an HistoricalChartsFilterProvider",
    );
  }

  return context;
}

export { HistoricalChartsFilterProvider, useHistoricalChartsFilter };
