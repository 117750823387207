import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Moment } from "moment";

import AlertFeedList from "./components/AlertFeedList";
import { BellIcon } from "../../components/createdIcons";
import { AlertApiService } from "../../services/api/AlertApiService";
import { Button, ButtonType } from "../../components/Button";
import PageContainerWrapper from "../../components/layout/layout-wrapper/PageContainerWrapper";
import PageContentWrapper from "../../components/layout/layout-wrapper/PageContentWrapper";
import { Alert } from "../../models/api/Alert";

import "./alerts-feed-styles.scss";
import { Guid } from "js-guid";
import { AlertPriority } from "../../components/layout/components/alertNotifications/AlertNotificationHelper";

export type AlertFeed = {
  alertMessage: string;
  alertName: string;
  date: Moment;
  dateString: string;
  deviceAlertInfoId: Guid;
  deviceAlertId: Guid;
  deviceId: Guid;
  deviceName: string;
  isActioned: boolean;
  isSeen: boolean;
  measurementValueCaused: string;
  priority: AlertPriority;
  tagNames?: string;
  snoozeUntil?: string;
};

export type AlertsFeedSortColumn =
  | "device"
  | "priorityLevel"
  | "alertMessage"
  | "timestamp"
  | "alertTriggered";

type AlertsFeedSortDirection = "asc" | "desc";

type State = {
  isLoading: boolean;
  hasNextPage: boolean;
  forceLoadMore: boolean;
  page: number;
  alerts: AlertFeed[];
  searchText: string;
  sortColumn: AlertsFeedSortColumn;
  sortDirection: AlertsFeedSortDirection;
  filterAlertsByName: Alert[];
  alertNameFromAlertWidget: string;
  alertNameFilter: string;
};

type LocationState = {
  groupedAlertName: string;
};

const ROWS_PER_PAGE = 10;

export const AlertsFeedPage: FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const observerRowTarget = useRef<HTMLTableRowElement>(null);

  const [state, setState] = useState<State>({
    isLoading: true,
    hasNextPage: false,
    forceLoadMore: false,
    page: 1,
    alerts: [],
    searchText: "",
    sortColumn: "timestamp",
    sortDirection: "desc",
    filterAlertsByName: [],
    alertNameFromAlertWidget: "",
    alertNameFilter: "",
  });

  useEffect(() => {
    listAlertsFeed();
  }, [state.page, state.alertNameFilter]);

  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries;

      if (target.isIntersecting && !state.isLoading && state.hasNextPage) {
        setState((prevState) => ({
          ...prevState,
          page: prevState.page + 1,
        }));
      }
    },
    [setState],
  );

  useEffect(() => {
    const element: HTMLDivElement | undefined | null = observerRowTarget?.current;
    const option = { threshold: 0 };

    if (element && !state.isLoading) {
      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [handleObserver]);

  async function listAlertsFeed() {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const result = await new AlertApiService().listAlertsFeed(state.alertNameFilter);
    const tempAlerts: AlertFeed[] = [];

    for (let index = 0; index < result.length; index++) {
      const alert = result[index];

      tempAlerts.push({
        alertMessage: alert.AlertMessage,
        alertName: alert.AlertName,
        date: alert.LastTriggered,
        dateString: alert.LastTriggered.format("Do MMM. YYYY, h:mm a"),
        deviceAlertInfoId: alert.DeviceAlertInfoId,
        deviceAlertId: alert.DeviceAlertId,
        deviceId: alert.DeviceId,
        deviceName: alert.DeviceName,
        isActioned: alert.IsActioned ?? false,
        isSeen: alert.IsSeen,
        measurementValueCaused: alert.MeasurementValueCaused,
        priority: alert.Priority,
        tagNames: alert.TagNames,
        snoozeUntil: alert.SnoozeUntil,
      });

      if (state.page * ROWS_PER_PAGE === tempAlerts.length) break;
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      hasNextPage: true,
      alerts: tempAlerts,
    }));
  }

  const handleSort = (sortColumn: AlertsFeedSortColumn) => {
    let sortDirection: AlertsFeedSortDirection = "asc";

    if (sortColumn === state.sortColumn) {
      sortDirection = state.sortDirection === "asc" ? "desc" : "asc";
    }

    setState((prevState) => ({
      ...prevState,
      sortColumn,
      sortDirection,
    }));
  };

  const handleSearch = (searchText: string) => {
    setState((prevState) => ({
      ...prevState,
      searchText,
    }));
  };

  const searchByText = (tempAlertsFeed: AlertFeed[]): AlertFeed[] => {
    const searchTextLowerCase = state.searchText.toLocaleLowerCase().replace(/\s/g, "");

    if (state.filterAlertsByName.length > 0) {
      return tempAlertsFeed.filter(
        (a) =>
          a.deviceName.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase) ||
          a.alertMessage.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase) ||
          a.dateString.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase) ||
          a.tagNames?.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase),
      );
    }

    return tempAlertsFeed.filter(
      (a) =>
        a.deviceName.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase) ||
        a.alertMessage.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase) ||
        a.dateString.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase) ||
        a.tagNames?.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase) ||
        a.alertName.toLocaleLowerCase().replace(/\s/g, "").includes(searchTextLowerCase),
    );
  };

  const handleFilterAlerts = (alerts: Alert[]): void => {
    let alertNameFilter = "";

    if (alerts.length > 0) {
      alertNameFilter = `'${alerts.map((a) => a.name).join("', '")}'`;
      alertNameFilter = alertNameFilter.replaceAll("'", "%27").replaceAll(" ", "%20");
    }

    setState((prevState) => ({
      ...prevState,
      filterAlertsByName: alerts,
      alertNameFilter,
    }));
  };

  const fetchNextPage = () => {
    setState((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  };

  const getAlertsFeedList = useMemo(() => {
    let tempAlertsFeed = state.alerts;
    let alertsOrdered: AlertFeed[];

    if (state.searchText !== "") {
      tempAlertsFeed = searchByText(tempAlertsFeed);
    }

    if (!!state.alertNameFromAlertWidget && tempAlertsFeed.length === 0) {
      fetchNextPage();
    }

    tempAlertsFeed = tempAlertsFeed.sort((a, b) =>
      a.deviceAlertInfoId > b.deviceAlertInfoId ? 1 : -1,
    );

    switch (state.sortColumn) {
      case "device":
        if (state.sortDirection === "asc") {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.deviceName > b.deviceName ? 1 : -1));
        } else {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.deviceName > b.deviceName ? -1 : 1));
        }
        break;
      case "priorityLevel":
        if (state.sortDirection === "asc") {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.priority > b.priority ? 1 : -1));
        } else {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.priority > b.priority ? -1 : 1));
        }
        break;
      case "alertMessage":
        if (state.sortDirection === "asc") {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.alertMessage > b.alertMessage ? 1 : -1));
        } else {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.alertMessage > b.alertMessage ? -1 : 1));
        }
        break;
      case "alertTriggered":
        if (state.sortDirection === "asc") {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.alertName > b.alertName ? 1 : -1));
        } else {
          alertsOrdered = tempAlertsFeed.sort((a, b) => (a.alertName > b.alertName ? -1 : 1));
        }
        break;
      default:
        if (state.sortDirection === "asc") {
          alertsOrdered = tempAlertsFeed.sort((a, b) =>
            a.date.valueOf() > b.date.valueOf() ? 1 : -1,
          );
        } else {
          alertsOrdered = tempAlertsFeed.sort((a, b) =>
            a.date.valueOf() > b.date.valueOf() ? -1 : 1,
          );
        }
        break;
    }

    return alertsOrdered;
  }, [
    state.alerts,
    state.sortColumn,
    state.sortDirection,
    state.searchText,
    state.filterAlertsByName.length,
  ]);

  return (
    <PageContainerWrapper>
      <PageContentWrapper>
        <div className="page-wrapper">
          <div className="title-container">
            <div className="title-main">
              <BellIcon />
              <div className="title-name">Active Alerts</div>
            </div>
            <div className="title-link">
              <Button
                content="Configure Alerts"
                buttonType={ButtonType.Transparent}
                small={true}
                iconClass="fas fa-cog"
                rightIconClass="fas fa-long-arrow-right"
                onClick={() => {
                  history.push(`/go/manage/config/alerts`);
                }}
              />
            </div>
          </div>

          <div className="table-container">
            <AlertFeedList
              isLoading={state.isLoading}
              alerts={getAlertsFeedList}
              sortColumn={state.sortColumn}
              sortDirection={state.sortDirection}
              searchText={state.searchText}
              handleSort={handleSort}
              handleSearch={handleSearch}
              observerRowTarget={observerRowTarget}
              handleFilterAlerts={handleFilterAlerts}
              alertNameFromAlertWidget={
                location.state != undefined ? location.state.groupedAlertName : ""
              }
            />
          </div>
        </div>
      </PageContentWrapper>
    </PageContainerWrapper>
  );
};
