import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import {
  ConfigState,
  INITIAL_CONFIG_STATE,
  CurrentWidgetMeasurementConfig,
  WidgetIcons,
  DASHBOARD_WIDGET_CLASS,
} from "../../../SimpleAppTemplate";
import { ErrorNoData } from "../../index";
import { ValueCard } from "../../../../../../components/cards";
import { Dialog, DialogSize } from "../../../../../../components/Dialog";
import { Button, ButtonType } from "../../../../../../components/Button";
import DevicePowerPerformanceWidget from "../../historic/DevicePowerPerformance/DevicePowerPerformanceWidget";
import useCurrentBatCharge from "../hooks/useCurrentBatCharge";
import { LoadingSkeleton } from "../../../../../../components/LoadingSkeleton";
import { IconButton } from "../../../../../../components/IconButton";
import MeasurementHelpers from "../../../../../../helpers/MeasurementHelpers";
import DateHelpers from "../../../../../../helpers/DateHelpers";
import SupportLink from "../../../../../../helpers/SupportLink";
import { HistoricalChartsFilterProvider } from "../../context/historical-charts-filter";
import { getBatteryIconClass, getWidgetIcons } from "../../WidgetHelper";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  measurement: CurrentWidgetMeasurementConfig;
};

type DppModalState = {
  openDialog: () => void;
  closeDialog: () => void;
  dateLastRefresh: string;
};

// TODO: Informative Comment
const BatteryWidget: FC<Props> = ({ device, orgId, measurement }) => {
  const [icons, setIcons] = useState<WidgetIcons>({
    icon: "",
    errorIcon: "fa-regular fa-battery-exclamation",
  });
  const [label, setLabel] = useState<string>("Battery Charge Level");
  const [hideIfNoData, setHideIfNoData] = useState<boolean>(false);
  const [configError, setConfigError] = useState<ConfigState>(INITIAL_CONFIG_STATE);
  const { fetchData, data, isLoading, hasError, errorMessage } = useCurrentBatCharge(device.id);

  const [dppModal, setDppModal] = useState<DppModalState>({
    openDialog: () => {},
    closeDialog: () => {},
    dateLastRefresh: "",
  });

  useEffect(() => {
    if (!!measurement && !!device.batteryMaximum && !!device.batteryMinimum) {
      // Required
      const name = measurement.name;
      const fieldKey = measurement.fieldKey;
      const maximumBattery = device.batteryMaximum;
      const minimumBattery = device.batteryMinimum;

      // Optional
      const displayName = measurement.displayName;
      const fieldName = measurement.fieldName;
      const label = measurement.label;

      let hasConfigError = !name || !fieldKey;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Measurement Name and/or FieldKey configured for this widget",
        });
      }

      hasConfigError = !maximumBattery || !minimumBattery;
      if (hasConfigError) {
        setConfigError({
          hasConfigError: true,
          errorMsg: "No Battery Min/Max Information for this device",
        });
      }

      if (measurement.hideIfNoData) setHideIfNoData(measurement.hideIfNoData);
      if (measurement.icon) setIcons(getWidgetIcons(measurement.icon));
      if (label) setLabel(label);

      hasConfigError = !name || !fieldKey || !maximumBattery || !minimumBattery;
      if (!hasConfigError) {
        const getData = async () => {
          await fetchData({
            name,
            fieldKey,
            maximumBattery,
            minimumBattery,
            hoursToAdd: MeasurementHelpers.getDifferenceInHoursFromUTCTime(),
            displayName,
            fieldName,
            orgId,
          });
        };

        getData();
      }
    } else {
      setConfigError({
        hasConfigError: true,
        errorMsg:
          "No Measurement configured for this widget or No Battery Information for this device",
      });
    }
  }, [measurement, orgId, device.batteryMinimum, device.batteryMaximum]);

  const GetDisplayCard = useMemo(() => {
    //If there is config error
    if (configError.hasConfigError) {
      return (
        <ErrorNoData
          cardCaption={`${configError.errorMsg}.`}
          supportQuestion={
            <p>
              Configured correctly?
              <span>&nbsp;</span>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    // If it is still loading
    if (isLoading) {
      return <LoadingSkeleton width="100%" height="100%" />;
    }

    // If there is an error after loading
    if (hasError) {
      return (
        <ErrorNoData
          cardCaption={`${errorMessage}`}
          supportQuestion={
            <p>
              <SupportLink />
            </p>
          }
          iconClass={icons.errorIcon}
          iconPos="left"
        />
      );
    }

    if (data.hasData) {
      if (data.hasMaxBattery && data.hasMinBattery) {
        return (
          <ValueCard
            cardValue={data.currentData.value}
            cardValueDisplay={data.currentData.value
              .toFixed(data.currentData.decimalPlaces)
              .concat(data.currentData.valueDisplayUnit)}
            isCritical={data.currentData.value < 25}
            cardCaption={label}
            iconPos="left"
            iconClass={icons.icon != "" ? icons.icon : getBatteryIconClass(data.currentData.value)}
            lastSeen={DateHelpers.getLastSeenBadge(data.currentData.time)}
            callbackButton={
              <IconButton
                iconClass="fa-regular fa-chart-line"
                isDark={false}
                onClick={() => {
                  setDppModal((prevState) => ({
                    ...prevState,
                    dateLastRefresh: Date.now().toString(),
                  }));

                  dppModal.openDialog();
                }}
              />
            }
          />
        );
      } else {
        return (
          <ErrorNoData
            cardCaption={`No Proper ${label} data for ${device.name}`}
            supportQuestion={
              <p>
                Expecting data?
                <span>&nbsp;</span>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }
    } else {
      if (data.errorMessage) {
        return (
          <ErrorNoData
            cardCaption={`${data.errorMessage}.`}
            supportQuestion={
              <p>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      if (!hideIfNoData) {
        return (
          <ErrorNoData
            cardCaption={`No ${label} data for ${device.name} in the last 30 Days`}
            // This could also be wrong configuration - Either wrong measurement name or field
            supportQuestion={
              <p>
                Expecting data?
                <span>&nbsp;</span>
                <SupportLink />
              </p>
            }
            iconClass={icons.errorIcon}
            iconPos="left"
          />
        );
      }

      return <></>;
    }
  }, [data, configError, icons]);

  const displayCard: JSX.Element = hideIfNoData ? (
    <></>
  ) : (
    <div className={DASHBOARD_WIDGET_CLASS}> {GetDisplayCard} </div>
  );
  return (
    <>
      {displayCard}
      <Dialog
        size={DialogSize.Large}
        setOpen={(open) =>
          setDppModal((prevState) => ({
            ...prevState,
            openDialog: open,
          }))
        }
        setClose={(close) =>
          setDppModal((prevState) => ({
            ...prevState,
            closeDialog: close,
          }))
        }
        header="Device Power Performance"
        body={
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <HistoricalChartsFilterProvider>
                <DevicePowerPerformanceWidget
                  device={device}
                  orgId={orgId}
                  measurement={measurement}
                />
              </HistoricalChartsFilterProvider>
            </div>
          </div>
        }
        footer={
          <>
            <span className="flex-grow-1" />
            <Button
              buttonType={ButtonType.Transparent}
              content="Close"
              onClick={dppModal.closeDialog}
            />
          </>
        }
      />
    </>
  );
};

export default BatteryWidget;
