import { FC, useEffect, useRef } from "react";
import * as d3 from "d3";
import { Guid } from "js-guid";

type Props = {
  width: number;
  height: number;
  x: number;
  y: number;
  axisLabel: string;
  measurementSuffix: string;
  marginFromTopParentDiv: number;
  batteryScale: d3.ScaleLinear<number, number, never>;
  chargingScale: d3.ScaleLinear<number, number, never>;
  chartId: Guid;
};

const RightAxis: FC<Props> = ({
  width,
  height,
  x,
  y,
  axisLabel,
  measurementSuffix,
  marginFromTopParentDiv,
  batteryScale,
  chartId,
}) => {
  const groupRef = useRef<SVGGElement>(null);

  const plotChart = (groupId: string) => {
    const group = d3.select(`#${groupId}`);

    group.selectAll("g").remove();
    group.selectAll("text").remove();

    const xPosition = 1;

    // Axis Tickers
    group
      .append("g")
      .attr("transform", `translate(${0.5}, ${marginFromTopParentDiv})`)
      .call(
        d3
          .axisRight(batteryScale)
          .ticks(5)
          .tickFormat((d) => `${d} ${measurementSuffix}`),
      )
      .attr("class", "dpp-right-axis-battery-text");

    // Axis Title
    group
      .append("text")
      .attr("transform", `translate(${xPosition}, ${marginFromTopParentDiv})`)
      .attr("x", `${100 + marginFromTopParentDiv}`)
      .attr("y", `-${55}`)
      .text(axisLabel);
  };

  useEffect(() => {
    plotChart(`right-axis-dpp-group-${chartId}`);
  }, [width]);

  return (
    <svg width="100%" height="100%" x={x} y={y} style={{ overflow: "initial" }}>
      <g
        ref={groupRef}
        id={`right-axis-dpp-group-${chartId}`}
        width={width}
        height={height}
        x={x}
        y={y}
      />
    </svg>
  );
};

export default RightAxis;
