import { FC, useEffect, useMemo, useState } from "react";
import { DashboardDevice } from "../../../../../../models/api/DashboardDevice";
import { CurrentWidgetMeasurementConfig } from "../../../SimpleAppTemplate";
import useCurrentWaterVolumeIndicator from "../hooks/useCurrentWaterVolumeIndicator";
import { getIndicator } from "../../WidgetHelper";

type Props = {
  device: DashboardDevice;
  orgId?: string;
  measurement: CurrentWidgetMeasurementConfig;
};

// TODO: Informative Comment
const CurrentWaterVolumeIndicator: FC<Props> = ({ device, orgId, measurement }) => {
  const [valueLabel, setValueLabel] = useState<string>("Tank Volume");
  const { fetchData, data: currentData, isLoading } = useCurrentWaterVolumeIndicator(device.id);

  useEffect(() => {
    if (measurement) {
      // Required
      const name = measurement.name;
      const fieldKey = measurement.fieldKey;
      const tankArea = device.tankArea;

      // Optional
      const displayName = measurement.displayName;
      const fieldName = measurement.fieldName;
      const portNumber = parseInt(measurement.port ?? "0");
      const label = measurement.label;
      if (label) setValueLabel(label);

      const hasError = !name || !fieldKey || !tankArea;
      if (!hasError) {
        const getData = async () => {
          await fetchData({
            name,
            fieldKey,
            tankArea,
            displayName,
            fieldName,
            portNumber,
            orgId,
          });
        };

        getData();
      }
    }
  }, [measurement, orgId]);

  const GetCurrentIndicator = useMemo(() => {
    return getIndicator(currentData, valueLabel, isLoading);
  }, [currentData, valueLabel, isLoading]);

  return <>{GetCurrentIndicator}</>;
};

export default CurrentWaterVolumeIndicator;
