import { HistoricalMeasurementDataResponse } from "../models/api/MeasurementDataResponses";

const GraphColors: string[] = [
  "#88BF40",
  "#40BFB7",
  "#4C40BF",
  "#A240BF",
  "#BF4048",
  "#4088BF",
  "#40BF77",
  "#F3D51E",
  "#FC8F29",
];

export default {
  getGraphColor(index: number): string {
    return GraphColors[index % GraphColors.length];
  },

  getSeriesDistinctionKey(md: HistoricalMeasurementDataResponse): string {
    //TODO: ADD PORT TO THE LOGIC // let port = md.port ?? "";
    return `${md.bluetoothAddress ?? ""}:${md.deviceId ?? ""}:${md.measurementId ?? ""}:${
      md.name ?? ""
    }`;
  },
};
