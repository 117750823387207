import { createContext, FC, useContext, useEffect, useState } from "react";
import { OrganizationTheme } from "../../models/api/OrganizationTheme";
import { BrandingApiService } from "../../services/api/BrandingApiService";
import { useAuth } from "../auth/auth.context";

export interface BrandingContextInterface {
  branding: OrganizationTheme;
}

export const BrandingContext = createContext<BrandingContextInterface>(
  {} as BrandingContextInterface,
);

interface IProps {
  children: any;
}

const INITIAL_BRANDING_STATE: OrganizationTheme = {
  companyName: "",
  domain: "",
  iconImageUri: "",
  logoImageUri: "",
  organizationId: "",
  privacyPolicyUrl: "",
  supportEmail: "",
  supportPh: "",
  supportUrl: "",
  termsOfServiceUrl: "",
  themeColor: "",
  useDarkText: false,
  id: "",
  portalName: "",
};

export const BrandingProvider: FC<IProps> = ({ children }) => {
  const auth = useAuth();
  const brandingApiServices = new BrandingApiService();
  const [branding, setBranding] = useState<OrganizationTheme>(INITIAL_BRANDING_STATE);

  useEffect(() => {
    brandingApiServices
      .getBrandingCSharp()
      .then((res) => {
        if (res != null) {
          setBranding(res);
        } else {
          setBranding(INITIAL_BRANDING_STATE);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [auth.token]);

  const value = { branding };

  return <BrandingContext.Provider value={value}>{children}</BrandingContext.Provider>;
};

export const useBranding = () => {
  const context = useContext(BrandingContext);
  if (context === undefined) {
    throw new Error(`useBranding must be used within a BrandingProvider`);
  }
  return context;
};
