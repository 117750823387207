import React from "react";
import { RouteConfig } from "../../AuthenticatedApp";

const DocumentationPage = React.lazy(() =>
  import("./DocumentationPage").then(({ DocumentationPage }) => ({ default: DocumentationPage })),
);

export const DocumentationRoutes: RouteConfig[] = [
  {
    path: "/go/documentation",
    title: "Documentation",
    component: () => <DocumentationPage />,
  },
];
