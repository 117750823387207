import { Fragment, ReactChild, useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { RouteConfig } from "../AuthenticatedApp";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Http404Page } from "../pages/error/Http404Page";
import { IconButton } from "./IconButton";
import { useLayout } from "../contexts/layout/layout-context";

interface IProps {
  title?: string;
  tabs: RouteConfig[];
  extraRoutes?: RouteConfig[];
}
export interface TabDefinition {
  url: string;
  page: ReactChild;
  title: string;
}
export function Tabs(props: IProps) {
  const location = useLocation();
  const { updatePageTitle } = useLayout();
  const [title, setTitle] = useState("");

  useEffect(() => {
    updatePageTitle(title);
  }, [title]);

  return (
    <div key={props.title} className="h-100 d-flex flex-column">
      <nav className="page-tabs">
        {props.title && (
          <Fragment key={props.title}>
            <h3 className="page-tab-title">{props.title}</h3>
          </Fragment>
        )}

        <ul className="page-tabs-wrapper">
          {props.tabs.map((tab, index) => (
            <li
              key={index}
              className={`page-tab-item ${
                window.location.pathname.includes(tab.path) ? "selected" : ""
              }`}
            >
              <Link key={index} to={tab.path} className="page-tab-item-link">
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>

        <span className="flex-grow-1 d-block d-md-none" />
        <span className="d-block d-md-none" data-toggle="dropdown" data-boundary="window">
          <IconButton
            className="mr-2 ml-n2 mb-1 flex-shrink-0"
            isDark={false}
            iconClass="fas fa-ellipsis-v"
          />
        </span>
        <div className="dropdown-menu">
          <div className="d-flex flex-column">
            {props.tabs.map((tab) => (
              <Link
                key={tab.path}
                to={tab.path}
                className={
                  "dropdown-item " +
                  (window.location.pathname.includes(tab.path) ? "selected" : undefined)
                }
              >
                {tab.title}
              </Link>
            ))}
          </div>
        </div>
      </nav>

      <div className="flex-grow-1">
        <TransitionGroup className="h-100">
          <CSSTransition timeout={200} classNames="fade" key={location.key}>
            <Switch location={location}>
              {[...props.tabs, ...(props.extraRoutes ?? [])].map((tab, index) => (
                <Route
                  exact={true}
                  path={tab.path}
                  key={tab.path}
                  render={(props) => {
                    setTitle(tab.title ?? "");
                    return (
                      <div key={index} className="h-100">
                        {tab.component(props)}
                      </div>
                    );
                  }}
                />
              ))}
              <Route path="*">
                <Http404Page />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}
