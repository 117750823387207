import "./inputStyles.scss";
import { XButton, XButtonState } from "./XButton";

export enum PillStateType {
  Default,
  Critical,
  Warning,
  Disabled,
}

interface IProps {
  pillState?: PillStateType;
  pillValue: string;
  onDelete?: (...args: any) => void;
}

export function Pill(props: IProps) {
  const xButtonState: XButtonState = mapState(props.pillState);
  const containerClasses: string[] = ["pill-container"];
  containerClasses.push(generateStateClass(props.pillState));

  const valueClass: string = props.onDelete != null ? "pill-value-with-close" : "pill-value";
  const _value = <span className={valueClass}> {props.pillValue} </span>;
  // let _xButton = props.onDelete != null ? <i className="fa-sharp fa-solid fa-circle-xmark pill-indicators" onClick={props.onDelete} /> : <></>;
  const _xButton = props.onDelete ? (
    <XButton xButtonState={xButtonState} isOnPill={true} onDelete={props.onDelete} />
  ) : (
    <></>
  );

  return (
    <div className={containerClasses.join(" ")}>
      {_value}
      {_xButton}
    </div>
  );
}

function mapState(pillState?: PillStateType) {
  if (!pillState) pillState = PillStateType.Default;
  let xButtonState: XButtonState = XButtonState.Default;

  switch (pillState) {
    case PillStateType.Default:
      xButtonState = XButtonState.Default;
      break;
    case PillStateType.Critical:
      xButtonState = XButtonState.Critical;
      break;
    case PillStateType.Warning:
      xButtonState = XButtonState.Warning;
      break;
    case PillStateType.Disabled:
      xButtonState = XButtonState.Disabled;
      break;
  }

  return xButtonState;
}

function generateStateClass(pillState?: PillStateType) {
  if (!pillState) pillState = PillStateType.Default;
  let stateClass: string = "pillState-";

  switch (pillState) {
    case PillStateType.Default:
      stateClass += "default";
      break;
    case PillStateType.Critical:
      stateClass += "critical";
      break;
    case PillStateType.Warning:
      stateClass += "warning";
      break;
    case PillStateType.Disabled:
      stateClass += "disabled";
      break;
  }

  return stateClass;
}
