import { useState, useCallback } from "react";
import axios from "axios";
import moment, { Moment } from "moment";
import cSharpApi from "../../../../../../services/api/CSharpApi";

type CurrentDatum = {
  time: Moment;
  latitude: number;
  longitude: number;
};

type CurrentData = {
  currentData: CurrentDatum;
  hasData: boolean;
  errorMessage: string;
};

type CurrentDataState = {
  data: CurrentData;
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  message?: string;
};

type FetchParams = {
  mapLocationName: string;
  latitudeFieldKey: string;
  longitudeFieldKey: string;
  hoursToAdd: number;
  orgId?: string;
};

const INITIAL_STATE = {
  data: {
    currentData: {
      time: moment(),
      latitude: 0,
      longitude: 0,
    },
    hasData: false,
    errorMessage: "",
  },
  isLoading: false,
  hasError: false,
  errorMessage: "",
  message: "",
};

const useCurrentMapLocation = (deviceId: string) => {
  const [fetchedData, setFetchedData] = useState<CurrentDataState>(INITIAL_STATE);

  const cancelTokenSource = axios.CancelToken.source();

  const fetchData = useCallback(
    async (params: FetchParams) => {
      setFetchedData({
        ...INITIAL_STATE,
        isLoading: true,
      });

      try {
        const response = await cSharpApi.post(
          `charts/get-current-map-location/${deviceId}`,
          JSON.stringify(params),
          {
            cancelToken: cancelTokenSource.token,
          },
        );

        const data = await response.data.data;

        if (data) {
          setFetchedData({
            data,
            isLoading: false,
            hasError: false,
            errorMessage: "",
            message: response.data.message,
          });
        }
      } catch (error) {
        let errorMessage = "fetching data aborted";

        if (axios.isCancel(error)) {
          console.log("fetching data aborted");
        } else {
          errorMessage = error instanceof Error ? error.message : "Unknown error";
          console.log("An error occurred", error);
        }

        setFetchedData({
          ...INITIAL_STATE,
          errorMessage,
        });
      }
      return () => cancelTokenSource.cancel();
    },
    [deviceId],
  );

  const { data, isLoading, hasError, errorMessage, message } = fetchedData;

  return { fetchData, data, isLoading, hasError, errorMessage, message };
};

export default useCurrentMapLocation;
